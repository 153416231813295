<template>
  <div class="w-full">
    <div v-if="!isEditMode" class="shadow-none bg-transparent">
      <div class="bg-white px-[24px] py-[32px] mt-[16px] rounded-[4px]">
        <div class="flex justify-between items-center">
          <h2
            class="font-bold text-[20px] leading-[20px] text-[#2463EB] w-full"
          >
            Sales Order {{ purchaseOrder.orderNumber }}
          </h2>
          <button
            v-if="purchaseOrder.status === 'submitted'"
            class="border-[1px] border-[#96a0b5] rounded-[8px] text-[#96a0b5] font-normal text-[14px] border-solid px-[24px] py-[12px] flex items-center gap-[5px] flex items-center gap-[5px]"
            @click="toggleEditMode('edit')"
          >
            <img src="/assets/icon/edit-2.svg" alt="" />
            Edit
          </button>
        </div>
        <div class="flex items-center gap-4 mt-2">
          <h2
            class="font-normal text-[16px] text-[#96a0b5] max-w-[170px] w-full"
          >
            Order for
          </h2>
          <span
            class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
            >{{ purchaseOrder.parent.name }}</span
          >
        </div>
        <div class="flex items-center gap-4 mt-2">
          <h2
            class="font-normal text-[16px] text-[#96a0b5] max-w-[170px] w-full"
          >
            Phone Number
          </h2>
          <span
            class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
            >{{
              purchaseOrder.contact.phoneNumber
                ? purchaseOrder.contact.phoneNumber
                : "Not Available"
            }}</span
          >
        </div>
        <div class="flex items-center gap-4 mt-2">
          <h2
            class="font-normal text-[16px] text-[#96a0b5] max-w-[170px] w-full"
          >
            Delivery Address
          </h2>
          <span
            class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
            >{{
              purchaseOrder.deliveryAddress
                ? purchaseOrder.deliveryAddress
                : "Not Available"
            }}</span
          >
        </div>
        <div class="flex items-center gap-4 mt-2">
          <h2
            class="font-normal text-[16px] text-[#96a0b5] max-w-[170px] w-full"
          >
            Total Order Amount
          </h2>
          <span
            class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
            >{{ formatMoney(total) }}</span
          >
        </div>
        <div class="flex items-center gap-4 mt-2">
          <h2
            class="font-normal text-[16px] text-[#96a0b5] max-w-[170px] w-full"
          >
            Creation Date
          </h2>
          <span
            class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
            >{{ formatDate(purchaseOrder.created) }}</span
          >
        </div>
        <div class="flex items-center gap-4 mt-2">
          <h2
            class="font-normal text-[16px] text-[#96a0b5] max-w-[170px] w-full"
          >
            Order Stage
          </h2>
          <span
            class="flex items-center gap-[4px] max-w-max w-full"
            :class="[
              'badge',
              {
                'badge-success': purchaseOrder.status === 'confirmed',
              },
              {
                'badge-danger': purchaseOrder.status === 'rejected',
              },
              {
                'badge-secondary': purchaseOrder.status === 'close',
              },
              {
                'badge-warning': purchaseOrder.status === 'submitted',
              },
            ]"
          >
            <span
              class="w-[10px] h-[10px] rounded-[1px]"
              :style="{
                backgroundColor:
                  purchaseOrder.status === 'confirmed'
                    ? '#16a249'
                    : purchaseOrder.status === 'submitted'
                    ? '#FFA500'
                    : purchaseOrder.status === 'closed'
                    ? '#ccc'
                    : purchaseOrder.status === 'rejected'
                    ? '#e42728'
                    : '',
              }"
            ></span>
            <span>
              {{ purchaseOrder.status ? purchaseOrder.status : "" }}
            </span>
          </span>
        </div>
        <div
          class="flex items-center gap-[8px] mt-[16px]"
          v-if="purchaseOrder.status === 'submitted'"
        >
          <button
            @click="approveOrder"
            type="button"
            class="border-[1px] bg-[#2463eb] rounded-[8px] text-white border-solid px-[16px] sm:px-[24px] text-[12px] md:text-[14px] py-[12px] outline-none focus:outline-none"
          >
            <i v-if="loader.approve" class="fas fa-spinner fa-spin mr-2"></i>

            {{ loader.approve ? "Busy..." : "Approve Order" }}
          </button>
          <button
            type="button"
            class="border border-solid border-[#7367F0] bg-[#7367F0] rounded-[8px] text-white px-[16px] sm:px-[24px] text-[12px] md:text-[14px] py-[12px] outline-none focus:outline-none"
            @click="updateOrder"
          >
            <i v-if="loader.update" class="fas fa-spinner fa-spin mr-2"></i>

            {{ loader.update ? "Busy..." : "Update Order" }}
          </button>
          <button
            @click="rejectOrder"
            type="button"
            class="border border-solid border-[#7367F0] bg-[#7367F0] rounded-[8px] text-white px-[16px] sm:px-[24px] text-[12px] md:text-[14px] py-[12px] outline-none focus:outline-none"
          >
            <i v-if="loader.reject" class="fas fa-spinner fa-spin mr-2"></i>

            {{ loader.reject ? "Busy..." : "Reject Order" }}
          </button>
        </div>
      </div>
      <div class="bg-white px-[24px] py-[32px] mt-[16px] rounded-[4px]">
        <ItemDetails
          :items="addedOrderDetails"
          :isView="true"
          :closeModal="closeModal"
        />
      </div>
      <SalesOrderItem
        v-if="addedItems.length > 0"
        :items="addedItems"
        :isView="true"
      />
    </div>

    <div v-if="isEditMode">
      <EditPurchaseOrder
        :orderId="orderId"
        :toggleEditMode="toggleEditMode"
        :closeModal="closeModal"
      />
    </div>
    <ApproveModal
      :orderId="orderId"
      :purchaseOrder="purchaseOrder"
      :isBusy="isBusy"
      :closeModal="closeModal"
    />
  </div>
</template>

<script>
import moment from "moment";
import { helper } from "@/helper";
import Amount from "../../components/SettingsPurchaseOrder/Amount";
import Quantity from "../../components/SettingsPurchaseOrder/Quantity";
import ItemDetails from "../../components/SettingsPurchaseOrder/PurchaseOrderItemDetails";
import SalesOrderItem from "../../components/SettingsPurchaseOrder/SalesOrderItem.vue";
import { mapActions } from "vuex";
import EditPurchaseOrder from "./EditPurchaseOrder.vue";
import ApproveModal from "./ApproveModal.vue";

export default {
  components: {
    Amount,
    Quantity,
    ItemDetails,
    SalesOrderItem,
    EditPurchaseOrder,
    ApproveModal,
  },
  props: ["purchaseId", "closeModal", "type", "toggleEditMode"],
  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      loader: { approve: false, reject: false, update: false },
      isActivityLoading: false,
      activities: [],
      orderId: "",
      manufacturerProducts: [],
      filteredProducts: [],
      purchaseOrder: {
        parent: {},
        contact: {},
      },
      addedOrderDetails: [],
      stageCautionData: {},
      orderItems: [],
      addedItems: [],
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
    isEditMode() {
      if (this.type == "edit") {
        return true;
      }
      return false;
    },
    subTotal() {
      let result = 0;
      this.addedOrderDetails.forEach((item) => {
        result += item.quantity * item.price;
      });
      return result;
    },

    total() {
      let result = this.subTotal;
      this.addedItems.forEach((item) => {
        if (item.type === 0) {
          result -=
            item.subType === 0
              ? item.amount
              : this.calculatePercentageAmount(item.percentage, this.subTotal);
        }

        if (item.type === 1) {
          result += this.calculatePercentageAmount(
            this.percentage,
            this.subTotal
          );
        }

        if (item.type === 2) {
          if (item.method === 0) {
            result +=
              item.subType === 0
                ? item.amount
                : this.calculatePercentageAmount(
                    item.percentage,
                    this.subTotal
                  );
          }
          if (item.method === 1) {
            result -=
              item.subType === 0
                ? item.amount
                : this.calculatePercentageAmount(
                    item.percentage,
                    this.subTotal
                  );
          }
        }
      });
      return this.formatMoney(result);
    },
  },

  created() {
    this.orderId = this.purchaseId;
    this.getOrderItems();
    this.editPurchaseOrder();
    this.getActivies();
  },

  methods: {
    async updateOrder() {
      // eslint-disable-next-line no-undef
      $("#mod-update-status").modal("show");
      this.closeModal();
    },
    async approveOrder() {
      // eslint-disable-next-line no-undef
      $("#mod-approve").modal("show");
    },
    async rejectOrder() {
      try {
        this.loader.reject = true;

        const response = await this.$http.get(
          `/Activity/${this.purchaseOrder.id}/${1}`
        );

        if (response.ok) {
          this.activities = response.body;
        }

        this.isActivityLoading = false;
      } catch (error) {
        this.loader.reject = false;
        console.log(error);
      }
    },
    async getActivies() {
      this.isActivityLoading = true;
      try {
        const response = await this.$http.get(
          `/Activity/${this.purchaseOrder.id}/${1}`
        );

        if (response.ok) {
          this.activities = response.body;
        }

        this.isActivityLoading = false;
      } catch (error) {
        this.isActivityLoading = false;
      }
    },
    editOrder() {
      this.$emit("toggleEditMode", true);
      this.isEditMode = !this.isEditMode;
    },
    editorder() {
      this.isEditMode = !this.isEditMode;
    },
    async editPurchaseOrder() {
      this.isBusy = true;
      try {
        const response = await this.$http.get(
          "/PurchaseOrders/edit/" + this.orderId
        );

        if (response.ok) {
          this.purchaseOrder = response.body;
          this.prepareDetailSet(this.purchaseOrder.details);
          this.prepareItemSet(this.purchaseOrder.orderItems);
          this.getManufacturerProducts();
        }

        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    calculatePercentageAmount(percent, amount) {
      return (percent / 100) * amount;
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    formatDate(date) {
      if (date) return moment(date).format("ll");
      else return "";
    },

    async getManufacturerProducts() {
      this.isBusyModal = true;
      try {
        const response = await this.$http.get(
          "/Products/getListNoPagingForManufacturer?active=true" +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "")
        );
        if (response.ok) {
          this.manufacturerProducts = this.filteredProducts = response.body;
          this.performFilter();
        }

        this.isBusyModal = false;
      } catch (error) {
        this.isBusyModal = false;
      }
    },

    performFilter() {
      this.addedOrderDetails.forEach((element) => {
        this.filteredProducts = this.filteredProducts.filter(
          (product) => product.id !== element.id
        );
      });
    },

    prepareItem(product, newItem) {
      const preparedItem = {
        quantity: newItem.quantity,
        description: `${product.name} (${product.code}) - ${product.retailPrice}/${product.unit}`,
        price: product.retailPrice,
        id: product.id,
      };

      this.addedOrderDetails.push(preparedItem);

      // eslint-disable-next-line no-undef
      $("#mod-product-add").modal("hide");
    },

    prepareItemSet(orderItems) {
      this.addedItems = [];
      orderItems.forEach((element) => {
        this.orderItems.forEach((sub) => {
          if (element.orderItemId === sub.id) this.addedItems.push(sub);
        });
      });
    },

    prepareDetailSet(productsDetails) {
      this.addedOrderDetails = [];
      productsDetails.forEach((element) => {
        const preparedItem = {
          quantity: element.quantity,
          price: element.price,
          id: element.productId,
          description: element.description,
        };

        this.addedOrderDetails.push(preparedItem);
      });
    },

    async getOrderItems() {
      this.isBusyModal = true;
      try {
        const response = await this.$http.get("/PurchaseOrders/orderItem/list");
        if (response.ok) {
          this.orderItems = response.body;
        }

        this.isBusyModal = false;
      } catch (error) {
        this.isBusyModal = false;
      }
    },
  },
};
</script>
