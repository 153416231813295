<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <!-- <button class="btn btn-secondary btn-rounded btn-space" @click="viewFilter">
                      <span class="icon icon-left mdi mdi-tune text-primary"></span> Filter
                    </button>-->
          <span>&nbsp;</span>
          <button
            class="btn btn-primary btn-rounded btn-space float-right"
            @click="viewAdd"
            v-if="(hasPermission('Group_Create') && user.type === 'Distributor') || user.role != 'View Only'"
          >
            <span class="icon icon-left mdi mdi-plus text-white"></span> New
            group
          </button>
        </div>
        <div class="card-body">
          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer table-striped table-hover-animation"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th style="width: 30%">Name</th>
                      <th style="width: 10%">Status</th>
                      <th style="width: 25%">Parent</th>
                      <th style="width: 15%">Created</th>
                      <th style="width: 20%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="g in Groups" :key="g.id">
                      <td>{{ g.name }}</td>
                      <td>
                        <span
                          :class="[
                            'badge',
                            ' badge-pill',
                            { 'badge-success': g.active },
                            { 'badge-danger': !g.active },
                          ]"
                          >{{ g.active ? "Active" : "Inactive" }}</span
                        >
                      </td>
                      <td>{{ g.parent ? g.parent.name : "System" }}</td>
                      <td>{{ formatDate(g.created) }}</td>
                      <td>
                        <div class="text-right">
                          <button
                            v-if="hasPermission('Group_Update')"
                            class="btn btn-success btn-sm btn-rounded btn-space"
                            @click.prevent="viewEdit(g)"
                          >
                            Edit</button
                          >&nbsp;
                          <button
                            v-if="hasPermission('Group_View_Members')"
                            class="btn btn-primary btn-sm btn-rounded btn-space"
                            @click.prevent="viewMembers(g)"
                          >
                            {{ g.memberCount }} member{{
                              g.memberCount != 1 ? "s" : ""
                            }}
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!Groups.length">
                      <td colspan="5">
                        <div class="text-center">No matching records</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }} group{{ count != 1 ? "s" : "" }}
                </div>
              </div>
              <div class="col-sm-7">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination">
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasPrev },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="prev"
                        >Previous</a
                      >
                    </li>
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasNext },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="next"
                        >Next</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-add"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Create new group</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Name</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.name }]"
                  type="text"
                  v-model="createGroup.name"
                />
                <ul v-if="v1.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Requireg.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Active</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <label
                  class="custom-control custom-checkbox custom-control-inline"
                >
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    v-model="createGroup.active"
                  />
                  <span class="custom-control-label">This group is active</span>
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 120px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="create"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Add group</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Edit group</h3>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Name</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.name }]"
                  type="text"
                  v-model="updateGroup.name"
                />
                <ul v-if="v2.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Requireg.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Active</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <label
                  class="custom-control custom-checkbox custom-control-inline"
                >
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    v-model="updateGroup.active"
                  />
                  <span class="custom-control-label">This group is active</span>
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="update"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-filter"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Filter</h3>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body"></div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
            >
              Reset
            </button>
            <button
              style="width: 100px"
              class="btn btn-primary md-close"
              type="button"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import SystemPermissions from "@/permissions";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsGroups",
  created() {
    if (!this.hasPermission("Group_View")) {
      this.$router.push("/restricted");
    }
    this.getGroups();
  },
  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      createGroup: {
        name: null,
        active: false,
      },
      updateGroup: {
        name: null,
        active: false,
        id: null,
      },
      v1: {
        name: false,
      },
      v2: {
        name: false,
      },
      Groups: [],
      types: [],
      count: 0,
      page: 0,
      pageSize: 10,
      hasNext: false,
      hasPrev: false,
    };
  },
  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    viewAdd() {
      this.createGroup = {
        name: null,
        active: false,
      };
      this.v1 = {
        name: false,
      };

      $("#mod-add").modal("show");
    },
    viewEdit(g) {
      this.updateGroup = g;
      $("#mod-edit").modal("show");
    },
    viewFilter() {
      $("#mod-filter").modal("show");
    },
    viewMembers(g) {
      this.$router.push("/settings/groups/" + g.id + "/members");
    },
    create() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        name: this.createGroup.name,
        active: this.createGroup.active,
        parentId: this.user.parentId,
        type: "Customer",
      };

      if (this.validateCreate()) {
        $("#mod-add").modal("hide");

        this.$http
          .post("/Groups/create", data)
          .then(function (res) {
            this.isBusyModal = false;
            this.getGroups();
          })
          .catch(function () {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },
    validateCreate() {
      this.v1 = {
        name: false,
      };
      var isValid = true;

      if (!this.createGroup.name) {
        this.v1.name = true;
        isValid = false;
      }

      return isValid;
    },
    update() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        name: this.updateGroup.name,
        active: this.updateGroup.active,
        id: this.updateGroup.id,
        type: "Customer",
      };

      if (this.validateUpdate()) {
        $("#mod-edit").modal("hide");

        this.$http
          .put("/Groups/update/" + data.id, data)
          .then(function (res) {
            this.isBusyModal = false;
            this.getGroup();
          })
          .catch(function () {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },
    validateUpdate() {
      this.v2 = {
        name: false,
      };
      var isValid = true;

      if (!this.updateGroup.name) {
        this.v2.name = true;
        isValid = false;
      }

      return isValid;
    },
    getGroups() {
      this.isBusy = true;

      this.$http
        .get(
          "/Groups/getList?page=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "")
        )
        .then(function (res) {
          this.Groups = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function () {
          this.isBusy = false;
        });
    },
    formatDate(date) {
      return moment(date).format("lll");
    },
    prev() {
      this.page -= 1;
      this.getGroups();
    },
    next() {
      this.page += 1;
      this.getGroups();
    },
  },
};
</script>