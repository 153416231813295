<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <h3>Draft</h3>
        </div>
        <div class="card-body">
          <div v-if="draftMessages.length > 0">
            <div
              class="row mb-2"
              v-for="m in draftMessages"
              :key="m.id"
              style="border-bottom: 0.5px solid gray"
            >
              <div
                class="col col-md-10"
                @click.prevent="openBroadcastPreview(m)"
                style="cursor: pointer"
              >
                <h4 class="text-primary">{{ m.title }} (Draft)</h4>
                <p>{{ m.message.substring(0, 100) + " ..." }}</p>
              </div>
              <div class="col col-md-2">
                <button
                  class="btn btn-success btn-sm dropdown-toggle"
                  type="button"
                  id="dropdownMenu2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Actions
                </button>
                <div class="dropdown-menu px-1" aria-labelledby="dropdownMenu2">
                  <a
                    class="dropdown-item"
                    href="#"
                    v-if="hasPermission('Broadcast_Message_Update')"
                    @click.prevent="editNewBrod(m.id)"
                  >
                    Edit
                  </a>
                  <a
                    class="dropdown-item"
                    href="#"
                    @click.prevent="openBroadcastPreview(m)"
                  >
                    Preview
                  </a>
                  <a
                    class="dropdown-item"
                    href="#"
                    v-if="hasPermission('Broadcast_Message_Create')"
                    @click.prevent="sendBroadcastMessage(m, 1)"
                  >
                    Broadcast Now
                  </a>
                  <a
                    class="dropdown-item"
                    href="#"
                    v-if="hasPermission('Broadcast_Message_Create')"
                    @click.prevent="openSchedule(m)"
                  >
                    Broadcast Later
                  </a>
                  <a
                    class="dropdown-item text-danger"
                    href="#"
                    v-if="hasPermission('Broadcast_Message_Delete')"
                    @click.prevent="deleteDraft(m.id)"
                  >
                    Delete
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="w-100 text-center">
            <p>You have no message</p>
          </div>
        </div>
        <div class="card-footer">
          <Pagination
            :limit="pageSize"
            :count="pageCount"
            :page="page"
            :itemsCount="count"
            @changePage="gotoPage"
            @changeLimit="changeLimit"
          />
        </div>
      </div>
    </div>

    <!-- <div class="modal fade colored-header colored-header-primary" id="mod-update" role="dialog">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-body p-3">
              <div class="form-group row">
                <label class="col-12 col-md-3 col-form-label"
                  >Broadcast Title*</label
                >
                <div class="col-12 col-md-9">
                  <input
                    :class="['form-control', { 'is-invalid': v1.title }]"
                    type="text"
                    v-model="content.title"
                  />
                  <ul v-if="v1.name" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-md-3 col-form-label"
                  >Broadcast Message*</label
                >
                <div class="col-12 col-md-9">
                  <textarea
                    :class="['form-control', { 'is-invalid': v1.message }]"
                    rows="6"
                    v-model="content.message"
                  ></textarea>
                  <ul v-if="v1.message" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>

              <div class="my-3">
                  <p> Select Your Recipients </p>

                 <form>
                  <div class="d-flex mb-2">
                    <div class="mr-sm-2 mr-md-5">
                      <label class="radioLabel" for="dist">Distributors only</label>
                      <input 
                        type="radio" 
                        class="radioInput"
                        id="dist" 
                        name="usertype"
                        v-model="content.userType" 
                        value="0"
                      >
                    </div>
                    <div>
                      <label class="radioLabel" for="merc">Merchants only</label>
                      <input type="radio" class="radioInput" id="merc" name="usertype" v-model="content.userType"  value="1">

                    </div>
                  </div>
                
                  <label class="radioLabel" for="both">Both</label>
                  <input type="radio" class="radioInput" id="both" name="usertype" v-model="content.userType" value="2">
                </form>
              </div>
					</div>
					<div class="modal-footer">
            <div class="w-100 d-flex flex-row justify-content-between">
             <button
                style="width: 100px;"
                class="btn btn-secondary"
                type="button"
                data-dismiss="modal"
                @click.prevent="cancelBroadcast"
              >
                Cancel
              </button>

              <div>
              

                <div>
                  <button
                    style="width: 100px;"
                    :disabled="isBusyModal"
                    class="btn btn-primary"
                    type="button"
                    @click.prevent="updateBroadcastDraft(1)"
                  >
                    <span v-if="isBusyModal">Busy...</span>
                    <span v-else>Send Nessage</span>
                  </button>

                  <button
                    style="width: 100px;"
                    :disabled="isBusyModal"
                    class="btn btn-secondary ml-1"
                    type="button"
                    @click.prevent="updateBroadcastDraft(0)"
                  >
                    <span v-if="isBusyModal">Busy...</span>
                    <span v-else>Update Draft</span>
                  </button>
                </div>
              </div>
					  </div>

					</div>
				</div>
			</div>
		</div>

    <div class="modal fade colored-header colored-header-primary" id="mod-read" role="dialog">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body p-3">
               <div class="mb-2">
                <h4 class="text-primary">{{selectedMsg.title}}</h4>
                <p> {{selectedMsg.message}}</p>
              </div>
            </div>
            <div class="modal-footer">
              <button
                style="width: 100px;"
                class="btn btn-secondary"
                type="button"
                data-dismiss="modal"
                @click.prevent="closeSelectMsg"
              >
                Close
              </button>

            </div>
          </div>
        </div>
      </div> -->

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-schedule"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div>
              <h4 class="modal-title"><b>Schedule Broadcast</b></h4>
              <span>You can only schedule 90days ahead</span>
            </div>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              @click.prevent="closeSchedule"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row mt-2">
              <label class="col-12 col-sm-3 col-form-label">Select Date*</label>
              <div class="col-12 col-sm-8">
                <input
                  :class="['form-control', { 'is-invalid': isScheduleInvalid }]"
                  type="date"
                  :min="minDate"
                  :max="maxDate"
                  placeholder="Select a date"
                  v-model="scheduleDate"
                />
                <ul
                  v-if="isScheduleInvalid"
                  class="parsley-errors-list filled mt-1"
                >
                  <li class="parsley-required">
                    You must select a valid date.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              style="width: 100px;"
              class="btn btn-text btn-space"
              type="button"
              data-dismiss="modal"
              @click.prevent="closeSchedule"
            >
              <span class="text-danger"> cancel </span>
            </button>

            <button
              style="width: 100px;"
              class="btn btn-primary"
              type="button"
              :disabled="isBusy"
              @click.prevent="sendBroadcastMessage(updatingMessage, 2)"
            >
              <span v-if="!isBusy">Schedule</span>
              <span v-else>Busy...</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <BroadcastPreview
      @closeModal="closeBroadcastPreview"
      :details="previewDetails"
    ></BroadcastPreview>
  </div>
</template>

<script>
/* eslint-disable */
import SystemPermissions from "@/permissions";
import { helper } from "@/helper";
import Pagination from "../../components/Pagination.vue";
import moment from "moment";
import BroadcastPreview from "../../components/Broadcast/BroadcastPreview.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "Enterprise-Broadcast",
  components: {
    Pagination,
    BroadcastPreview,
  },

  mounted: function() {
    this.fetchDraftMessages();
    this.minDate = helper.preventFutureDateSelection();
    this.maxDate = moment(this.minDate, "DD-MM-YYYY").add(90, "days");
  },

  data() {
    return {
      isBusy: false,
      isBusyDeleting: false,
      isBusyModal: false,
      draftMessages: [],
      updatingMessage: null,
      count: 0,
      page: 1,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,

      minDate: "",
      maxDate: "",

      scheduleDate: null,
      isScheduleInvalid: false,
      previewDetails: {
        title: "",
        sender: "",
        date: "",
        message: "",
        imagePosition: "",
        infoUrl: "",
        imageAttach: "",
      },

      content: {
        title: "",
        message: "",
        userType: "",
        id: "",
      },
      v1: {
        title: false,
        message: false,
      },
    };
  },

  methods: {
    updateDraft(m) {
      this.content = {
        message: m.message,
        title: m.title,
        userType: m.recipentType,
        id: m.id,
      };
      $("#mod-update").modal("show");
    },

    openSchedule(m) {
      this.updatingMessage = m;
      $("#mod-schedule").modal("show");
    },

    closeSchedule() {
      $("#mod-schedule").modal("hide");
      this.scheduleDate = "";
      this.isScheduleInvalid = false;
    },

    openBroadcastPreview(m) {
      this.previewDetails = {
        title: m.title,
        message: m.message,
        sender: m.sender,
        date: m.date
          ? moment(m.date).format("dddd, MMM DD, hh:mma")
          : moment(new Date()).format("dddd, MMM DD, hh:mma"),
        imagePosition: m.imagePosition,
        infoUrl: m.link,
        imageAttach: m.imageUrl,
      };
      $("#mod-read-broadcast").modal("show");
    },

    closeBroadcastPreview() {
      $("#mod-read-broadcast").modal("hide");
    },

    editNewBrod(id) {
      if (id) this.$router.push(`/manufacturer-broadcast-new/${id}`);
    },

    sendBroadcastMessage(m, type) {
      if (type == 2 && !this.scheduleDate) {
        return (this.isScheduleInvalid = true);
      }
      let payload = {
        Id: m.id,
        Title: m.title,
        Message: m.message,
        Status: type,
        RecipentType: m.recipentType,
        ScheduledTime: m.broadCastTime,
        Image: m.imageUrl,
        DeleteImage: m.imageUrl ? false : true,
        ImagePosition: m.imagePosition,
        Link: m.link,
      };

      const formData = new FormData();
      for (const [key, value] of Object.entries(payload)) {
        formData.append(`${key}`, value);
      }

      this.$http
        .put(`/BroadcastMessages/update/${m.id}`, formData)
        .then(function(res) {
          this.$toast.success(res.body, {
            icon: true,
            rtl: false,
          });
          this.content = {
            id: "",
            title: "",
            message: "",
            imagePosition: "",
            infoUrl: "",
            userType: "",
          };
          this.closeSchedule();
          // this.fetchSentMessages();
          this.isBusy = false;
          this.$router.push("/manufacturer-broadcast");
        })
        .catch(function() {
          this.isBusy = false;
        });
    },

    validateContent() {
      this.v1 = {
        title: false,
        message: false,
      };

      var isValid = true;

      if (!this.content.title) {
        this.v1.title = true;
        isValid = false;
      }

      if (!this.content.message) {
        this.v1.message = true;
        isValid = false;
      }

      return isValid;
    },

    cancelBroadcast() {
      this.content = {
        title: "",
        message: "",
        userType: "",
        id: "",
      };
    },

    updateBroadcastDraft(status) {
      this.isBusyModal = false;

      if (this.validateContent()) {
        let payload = {
          title: this.content.title,
          message: this.content.message,
          status: status,
          recipentType: this.content.userType,
          id: this.content.id,
        };
        this.$http
          .put(`/BroadcastMessages/update/${this.content.id}`, payload)
          .then(function(res) {
            $("#mod-update").modal("hide");
            this.$toast.success(res.body, {
              icon: true,
              rtl: false,
            });
            this.content = {
              title: "",
              message: "",
              userType: "",
              id: "",
            };
            this.fetchDraftMessages();
            this.isBusyModal = false;
          })
          .catch(function() {
            this.isBusyModal = false;
          });
      }
    },

    deleteDraft(id) {
      this.isBusyDeleting = true;

      this.$http
        .delete(`/BroadcastMessages/${id}`)
        .then(function(res) {
          this.isBusyDeleting = false;
          this.$toast.success(res.body, {
            icon: true,
            rtl: false,
          });
          this.fetchDraftMessages();
        })
        .catch(function() {
          this.isBusyDeleting = false;
        });
    },

    fetchDraftMessages() {
      this.isBusy = true;

      this.$http
        .get(
          `/BroadcastMessages?Status=0&PageSize=${this.pageSize}&PageNumber=${this.page}`
        )
        .then(function(res) {
          this.draftMessages = res.body.data;
          this.page = res.body.pageNumber;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function() {
          this.isBusy = false;
        });
    },

    prev() {
      this.page -= 1;
      this.fetchDraftMessages();
    },
    next() {
      this.page += 1;
      this.fetchDraftMessages();
    },

    gotoPage(page) {
      this.page = page;
      this.fetchDraftMessages();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.fetchDraftMessages();
    },
  },
};
</script>

<style scoped>
.radioLabel {
  font-size: 16px;
  margin-right: 20px;
}

.radioInput {
  width: 18px;
  height: 18px;
}
</style>
