<template>
	<div class="onboarding-body">
		<div class="onboarding-card">
			<img src="/assets/img/growtrade-logo.png" alt="logo" width="175" />
			<p class="onboarding-header">Glad to have you onboard!</p>
			<p class="onboarding-desc mb-2">
				Use Growtrade to digitize and grow your business, while accessing financial benefits
				and much more. Choose the proper category below to sign-up
			</p>
			<hr class="mb-2" />
			<div>
				<div class="onboarding-text-div mb-2">
					<div class="form-check">
						<input
							class="form-check-input"
							type="radio"
							name="usertype"
							value="distributor"
							v-model="usertype"
						/>
						<label class="form-check-label onboarding-d ml-2" for="exampleRadios1">
							<p class="onboarding-title">Distributor</p>
							<p class="onboarding-text">
								Choose this category if you buy large volumes directly from a
								manufacturer or from another distributor, and mostly resell in bulk.
							</p>
						</label>
					</div>
				</div>

				<div class="onboarding-text-div">
					<div class="form-check">
						<!-- <router-link
							class="onboarding-div"
							to="/growtrade-register?client=merchant"
						> -->
						<input
							class="form-check-input"
							type="radio"
							name="usertype"
							value="merchant"
							v-model="usertype"
						/>
						<label class="form-check-label onboarding-d ml-2" for="exampleRadios1">
							<p class="onboarding-title">Merchant</p>
							<p class="onboarding-text">
								Choose this category if you buy from a distributor or wholesaler and
								resell in smaller quantities to retail consumers or businesses.
							</p>
						</label>
						<!-- </router-link> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	/* eslint-disable */

	export default {
		name: "growtrade-onboarding",
		components: {},

		data() {
			return {
				usertype: "",
			};
		},
		watch: {
			usertype: function(newValue, oldValue) {
				this.$router.push(`growtrade-register?client=${newValue}`);
			},
		},
	};
</script>
<style scoped>
	.form-check input {
		height: 25px;
		width: 25px;
		cursor: pointer;
	}
	.onboarding-body {
		background-color: #f6f6f6;
		min-width: 100vw;
		min-height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.onboarding-card {
		width: 524px;
		background-color: #fff;
		padding: 48px;
	}

	@media screen and (max-width: 767px) {
		.onboarding-card {
			width: 90%;
			padding: 24px;
		}
	}

	.onboarding-header {
		font-weight: bold;
		font-size: 32px;
		line-height: 39px;
		color: #373737;
		margin: 32px 0 8px;
	}

	.onboarding-desc {
		font-size: 16px;
		line-height: 24px;
		font-weight: 400;
		color: #a0a0a0;
		margin-bottom: 16px;
	}

	.onboarding-div {
		padding: 24px 7px 24px 0;
		cursor: pointer;
		display: flex;
		border-top: 1px solid #f5f5f5;
	}

	.onboarding-title {
		font-weight: 400;
		font-size: 14px;
		color: #a0a0a0;
		margin-bottom: 12px;
		text-transform: uppercase;
	}

	.onboarding-text {
		font-size: 13px;
		font-weight: 400;
		color: rgba(24, 25, 31, 0.6);
	}
</style>
