<template>
  <nav
    class="header-navbar navbar-expand-lg navbar navbar-with-menu floating-nav navbar-light border-b-[1px] border-solid shadow-none"
  >
    <div class="navbar-wrapper">
      <div class="navbar-container content">
        <div class="navbar-collapse" id="navbar-mobile">
          <div
            class="mr-auto float-left bookmark-wrapper d-flex align-items-center"
          >
            <ul class="nav navbar-nav">
              <li class="nav-item mobile-menu d-xl-none mr-auto">
                <a class="nav-link nav-menu-main menu-toggle hidden-xs" href="#"
                  ><i class="fa fa-2x fa-bars"></i
                ></a>
              </li>
            </ul>
            <h4 class="ml-1 no-hidden-xs font-bold" style="margin-top: 7px">
              <span class="text-[20px] text-[#353535] font-medium">
                {{ title }}</span
              >
            </h4>
          </div>
          <ul class="nav navbar-nav float-right">
            <li v-if="user.type === 'Distributor'" class="nav-item">
              <router-link to="/broadcast" class="nav-link nav-icon iconClass">
                <i
                  class="feather text-dark icon-volume"
                  style="font-size: 26px"
                ></i>
                <span
                  v-if="unreadBroadcastCount > 0"
                  class="badge badge-danger"
                  style="border-radius: 40%; padding: 5px; font-size: 10px"
                  >{{ unreadBroadcastCount }}</span
                >
              </router-link>
            </li>
            <Notification :parentId="user.parentId" />
            <li class="dropdown dropdown-user nav-item">
              <a
                class="dropdown-toggle nav-link dropdown-user-link"
                href="#"
                data-toggle="dropdown"
              >
                <span
                  ><img
                    class="round mr-1"
                    src="/assets/img/ava.png"
                    alt="avatar"
                    height="40"
                    width="40"
                /></span>
                <div class="user-nav gap-2 d-sm-flex d-none">
                  <span
                    v-if="user.isSalesUser"
                    class="user-name m-0 p-0 text-bold-500 text-[14px] text-[#353535]"
                  >
                    {{ user.lastName + " " + user.otherNames }}
                  </span>
                  <span v-else class="user-name p-0 m-0  text-bold-600">
                    {{ user.displayName }}
                  </span>
                  <span v-if="user.isSalesUser" class="user-status">{{
                    user.role.roleName
                  }}</span>
                </div>
                <i class="fa-solid fa-chevron-down text-xl"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <router-link class="dropdown-item" to="/account">
                  <i class="feather icon-user"></i> Manage Account
                </router-link>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#" @click.prevent="logout">
                  <i class="feather icon-log-out"></i> Logout
                </a>
              </div>
            </li>
          </ul>
        </div>
        <nav aria-label="breadcrumb" v-if="hasBreadCrumbs">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li
                class="breadcrumb-item"
                v-for="crumb of breadCrumbsRoutes"
                :key="crumb.route"
              >
                <router-link :to="crumb.route">{{ crumb.name }}</router-link>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ title }}
              </li>
            </ol>
          </nav>
        </nav>
      </div>
    </div>
  </nav>
</template>

<script>
import Notification from "../components/Notification";
export default {
  name: "navBar",

  components: {
    Notification,
  },

  data() {
    return {
      title: this.$route.meta.title,
      unreadBroadcastCount: "0",
    };
  },

  created() {
    this.checkRoute();
    this.fetchMessagesCount();
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
    hasBreadCrumbs: function() {
      return (
        Array.isArray(this.$route.meta.breadCrumbsRoutes) &&
        this.$route.meta.breadCrumbsRoutes.length > 0
      );
    },
    breadCrumbsRoutes: function() {
      return this.$route.meta.breadCrumbsRoutes;
    },
  },

  watch: {
    $route: "checkRoute",
  },

  methods: {
    checkRoute() {
      this.title = this.$route.meta.title;
    },

    fetchMessagesCount() {
      this.$http
        .get(`/BroadCastMessages/recipientMessagesCount/false`)
        .then(function(res) {
          this.unreadBroadcastCount = res.body;
        })
        .catch(function() {});
    },

    logout: function() {
      this.$auth.destroyToken();
      this.$store.commit("clearCurrentUser");
      this.$store.replaceState({});
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.iconClass {
  position: relative;
  margin-top: -2px !important;
}
.iconClass span {
  position: absolute;
  top: 13px;
  right: 3px;
  display: block;
}
</style>
