var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-wrapper",staticStyle:{"height":"100vh","width":"100%","display":"flex","align-items":"center","justify-content":"center","padding":"auto"}},[_c('div',{staticClass:"card-wrapper"},[_c('div',[_c('div',[_c('div',[_c('div',{staticClass:"card card-border-color card-border-color-primary",staticStyle:{"width":"100%"}},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('form',[(_vm.user.usertype === 'merchant')?_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Mobile Number")]),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-9"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.user.phoneNumber.$model),expression:"$v.user.phoneNumber.$model",modifiers:{"trim":true}}],class:[
                          'form-control',
                          { 'is-invalid': _vm.$v.user.phoneNumber.$error } ],attrs:{"type":"text","placeholder":"Phone Number"},domProps:{"value":(_vm.$v.user.phoneNumber.$model)},on:{"blur":[_vm.confirmPhoneNumberUnique,function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.user.phoneNumber, "$model", $event.target.value.trim())}}})])]),(_vm.phoneNumberUnique === false)?_c('ul',{staticClass:"parsley-errors-list filled"},[_c('li',{staticClass:"parsley-required text-danger",staticStyle:{"font-size":"10px"}},[_vm._v(" The phone number provided has been used. ")])]):_vm._e(),(!_vm.$v.user.phoneNumber.numeric)?_c('ul',{staticClass:"parsley-errors-list filled"},[_c('li',{staticClass:"parsley-required"},[_vm._v(" Enter a valid phone number. ")])]):_vm._e(),(
                      !_vm.$v.user.phoneNumber.minLength ||
                        !_vm.$v.user.phoneNumber.maxLength
                    )?_c('ul',{staticClass:"parsley-errors-list filled"},[_c('li',{staticClass:"parsley-required"},[_vm._v(" Phone number must be 10 digits without leading zero. ")])]):_vm._e(),_vm._m(2)]):_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("User Name")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.user.username.$model),expression:"$v.user.username.$model",modifiers:{"trim":true}}],class:[
                      'form-control',
                      { 'is-invalid': _vm.$v.user.username.$error } ],attrs:{"id":"username","type":"text","placeholder":"Username","autocomplete":"off"},domProps:{"value":(_vm.$v.user.username.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.user.username, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.user.username.alphaNumAndDotValidator)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Username must not include space or special characters! ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Email Address")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.emailAddress),expression:"user.emailAddress"}],staticClass:"form-control",attrs:{"type":"text","readonly":"","placeholder":"Email Address"},domProps:{"value":(_vm.user.emailAddress)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "emailAddress", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.user.password.$model),expression:"$v.user.password.$model",modifiers:{"trim":true}}],class:[
                      'form-control',
                      { 'is-invalid': _vm.$v.user.password.$error } ],attrs:{"type":"password","placeholder":"Password"},domProps:{"value":(_vm.$v.user.password.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.user.password, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                      !_vm.$v.user.password.required && _vm.$v.user.password.$dirty
                    )?_c('ul',{staticClass:"parsley-errors-list filled"},[_c('li',{staticClass:"parsley-required"},[_vm._v("Required.")])]):_vm._e(),(!_vm.$v.user.password.minLength)?_c('ul',{staticClass:"parsley-errors-list filled"},[_c('li',{staticClass:"parsley-required"},[_vm._v(" Enter a password greater than or equal to 6 characters. ")])]):_vm._e()]),_c('div',{staticClass:"form-group login-submit"},[_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.isBusy ||
                        (_vm.user.usertype === 'merchant' &&
                          _vm.phoneNumberUnique === false)},on:{"click":function($event){$event.preventDefault();return _vm.update.apply(null, arguments)}}},[(_vm.isBusy)?_c('span',[_vm._v("Busy...")]):_c('span',[_vm._v("Create account")])])])])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('div',{staticStyle:{"width":"100%","display":"flex","flex-wrap":"wrap","flex-direction":"row","justify-content":"space-between"}},[_c('div',{staticStyle:{"width":"200px","height":"60px"}},[_c('img',{staticClass:"logo-img",attrs:{"src":"/assets/img/logo-xx.png","alt":"logo","width":"100%","height":"auto"}})])]),_c('div',{staticClass:"mt-2"},[_c('h4',[_vm._v("Register Your Account")]),_c('span',{staticClass:"splash-description"},[_vm._v("Please enter your user information to create your account. ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-3"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","readonly":"","placeholder":"+234"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticStyle:{"font-size":"10px"}},[_c('em',[_vm._v("This phone number will be used to log in to the platform.")])])])}]

export { render, staticRenderFns }