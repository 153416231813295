<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="['card', 'card-border-color', 'card-border-color-primary', ' card-table', 'be-loading', {'be-loading-active':isBusy}]"
      >
          <div class="card-header">
              <h4 class="p-1"><b>Manufacturers for {{ distributor.name }}</b></h4>
              <div class="bn-group">
                  <button class="btn btn-primary btn-rounded btn-space float-right"
                          @click="viewAdd">
                      <span class="icon icon-left mdi mdi-plus text-white"></span> Add manufacturer
                  </button>

                  <router-link class="btn btn-space btn-success btn-rounded float-right"
                               to="/distributors">
                      <span class="icon icon-left mdi mdi-arrow-left text-white"></span> Back
                  </router-link>
              </div>
          </div>
          <div class="table-responsive">
              <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                      <tr>
                          <th style="width:55%;">Name</th>
                          <th style="width:25%;">Date</th>
                          <th style="width:10%;"></th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="p in parents" :key="p.id">
                          <td>{{ p.parentName }}</td>
                          <td>{{ formatDate(p.date) }}</td>
                          <td>
                              <div class="text-right">
                                  <button class="btn btn-secondary btn-rounded btn-space"
                                          @click.prevent="remove(p.id)">
                                      Remove
                                  </button>
                              </div>
                          </td>
                      </tr>
                      <tr v-if="!parents.length">
                          <td colspan="3">
                              <div class="text-center">No manufacturers are attached to this distributor.</div>
                          </td>
                      </tr>
                  </tbody>
              </table>
          </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-add" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Add manufacturer</h3>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Manufacturer</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select class="form-control" placeholder="Select an option" v-model="manufacturerId">
                  <option v-for="m in manufacturers" :key="m.id" :value="m.id">{{ m.text }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px;"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >Cancel</button>
            <button
              style="width: 100px;"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="add"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import Select2 from "@/components/Select2";

export default {
  name: "distributorParents",
  components: {
    Select2
  },
  created() {
    this.id = this.$route.params.id;
    this.getMerchant();
    this.getManufacturers();
    this.getParents();
  },
  data() {
    return {
      id: null,
      distributor: {},
      isBusy: false,
      isBusyModal: false,
      parents: [],
      manufacturers: [],
      manufacturerId: ""
    };
  },
  computed: {
    user: function() {
      return this.$store.state.currentUser;
    }
  },
  methods: {
    async getMerchant() {
      await this.$http
        .get("/Accounts/getById/" + this.id)
        .then(function(res) {
          this.distributor = res.body;
        })
        .catch(function() {});
    },
    viewAdd() {
      this.manufacturerId = "";

      $("#mod-add").modal("show");
    },
    async add() {
      if (this.manufacturerId != "") {
        this.isBusyModal = true;
        this.isBusy = true;
        $("#mod-add").modal("hide");

        let data = {
          accountId: this.id,
          parentId: this.manufacturerId
        };

        await this.$http
          .post("/Accounts/addParent", data)
          .then(function(res) {
            this.getParents();
          })
          .catch(function() {});

        this.isBusyModal = false;
        this.isBusy = false;
      }
    },
    async remove(id) {
      this.isBusy = true;

      await this.$http
        .delete("/Accounts/deleteParent/" + id)
        .then(function(res) {
          this.getParents();
        })
        .catch(function() {});

      this.isBusy = false;
    },
    async getParents() {
      this.isBusy = true;

      await this.$http
        .get("/Accounts/getParents/" + this.id)
        .then(function(res) {
          this.parents = res.body;
        })
        .catch(function() {});

      this.isBusy = false;
    },
    async getManufacturers() {
      await this.$http
        .get("/Accounts/getItemList?accountType=Manufacturer")
        .then(function(res) {
          this.manufacturers = res.body;
        })
        .catch(function() {});
    },
    formatDate(date) {
      return moment(date).format("ll");
    }
  }
};
</script>