<!-- BusinessProfile.vue -->
<template>
  <div>
    <div v-if="!isEditing">
      <div class="flex items-center justify-between">
        <h2
          class="font-semibold text-[18px] leading-[24px] text-[#353535] max-w-max w-full"
        >
          Business Profile
        </h2>
        <button
          class="border-[1px] border-[#96a0b5] rounded-[8px] text-[#96a0b5] font-normal text-[14px] border-solid px-[24px] py-[12px] flex items-center gap-[5px]"
          @click="toggleEditMode"
        >
          <span><img src="/assets/icon/edit-2.svg" alt=""/></span>
          Edit
        </button>
      </div>
      <div class="flex items-center gap[-10px]">
        <div v-if="updatingLogo">
          <img
            alt="business logo"
            class="logoImage w-[85px] h-[78px]"
            :src="previewLogo"
          />
        </div>
        <div v-else @click="openPreview">
          <img
            v-if="businessLogoUrl"
            alt="business logo"
            class="logoImage w-[85px] h-[78px]"
            :src="`${businessLogoUrl}`"
          />
          <img
            v-else
            alt="logo placeholder"
            class="logoImage w-[85px] h-[78px]"
            src="/assets/img/logo-placeholder.png"
          />
        </div>
        <div class="">
          <input
            type="file"
            ref="file"
            accept="image/*"
            @change="previewFiles"
            style="display: none"
          />
          <button
            @click.prevent="$refs.file.click()"
            class="border-[1.5px] border-solid border-[#2463EB] bg-transparent px-[24px] py-[12px] rounded-[4px] text-[#2463EB] outline-none focus:outline-none flex items-center gap-[5px] ml-[5px]"
          >
            <img src="/assets/icon/document.svg" alt="" />

            Upload New Logo
          </button>
        </div>
      </div>
      <!-- profile information -->
      <div class="border-b-[1.5px] border-solid border-[#EAECF0] pb-[24px]">
        <div class="my-[24px]">
          <h3 class="text-[#353535] text-[14px] font-semibold leading-[24px]">
            Business information
          </h3>
        </div>
        <div
          class="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-4 mt-2"
        >
          <h2
            class="font-normal text-[16px] text-[#96a0b5] max-w-[250px] w-full"
          >
            Business Name
          </h2>
          <span
            class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
            >{{ account.name }}</span
          >
        </div>
        <div
          class="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-4 mt-2"
        >
          <h2
            class="font-normal text-[16px] text-[#96a0b5] max-w-[250px] w-full"
          >
            Code
          </h2>
          <span
            class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
            >{{ account.code }}</span
          >
        </div>
        <div
          class="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-4 mt-2"
        >
          <h2
            class="font-normal text-[16px] text-[#96a0b5] max-w-[250px] w-full"
          >
            Business Description
          </h2>
          <span
            class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
            >{{ account.description }}</span
          >
        </div>
        <div
          class="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-4 mt-2"
        >
          <h2
            class="font-normal text-[16px] text-[#96a0b5] max-w-[250px] w-full"
          >
            Email
          </h2>
          <span
            class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
            >{{ account.email ? account.email : "N/A" }}</span
          >
        </div>
        <div
          class="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-4 mt-2"
        >
          <h2
            class="font-normal text-[16px] text-[#96a0b5] max-w-[250px] w-full"
          >
            Business Regristration
          </h2>
          <span
            class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
            >{{ account.rcNumber }}</span
          >
        </div>
      </div>

      <!-- contact information -->
      <div class="pb-[24px]">
        <div class="my-[24px]">
          <h3 class="text-[#353535] text-[14px] font-semibold leading-[24px]">
            Contact information
          </h3>
        </div>
        <div
          class="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-4 mt-2"
        >
          <h2
            class="font-normal text-[16px] text-[#96a0b5] max-w-[250px] w-full"
          >
            Business Email Address
          </h2>
          <span
            class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
            >{{ account.emailAddress }}</span
          >
        </div>
        <div
          class="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-4 mt-2"
        >
          <h2
            class="font-normal text-[16px] text-[#96a0b5] max-w-[250px] w-full"
          >
            Business Phone Number
          </h2>
          <span
            class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
            >{{ account.phoneNumber }}</span
          >
        </div>
        <div
          class="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-4 mt-2"
        >
          <h2
            class="font-normal text-[16px] text-[#96a0b5] max-w-[250px] w-full"
          >
            Business State
          </h2>
          <span
            class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
            >{{ account.state }}</span
          >
        </div>
        <div
          class="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-4 mt-2"
        >
          <h2
            class="font-normal text-[16px] text-[#96a0b5] max-w-[250px] w-full"
          >
            Business LGA
          </h2>
          <span
            class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
            >{{ account.lga }}</span
          >
        </div>
        <div
          class="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-4 mt-2"
        >
          <h2
            class="font-normal text-[16px] text-[#96a0b5] max-w-[250px] w-full"
          >
            Business Address
          </h2>
          <span
            class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
            >{{ account.address1 }}</span
          >
        </div>
        <div
          class="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-4 mt-2"
        >
          <h2
            class="font-normal text-[16px] text-[#96a0b5] max-w-[250px] w-full"
          >
            Contact Person Name
          </h2>
          <span
            class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
            >{{ contact.name ? contact.name : "N/A" }}</span
          >
        </div>
        <div
          class="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-4 mt-2"
        >
          <h2
            class="font-normal text-[16px] text-[#96a0b5] max-w-[250px] w-full"
          >
            Contact Person Email
          </h2>
          <span
            class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
            >{{ contact.emailAddress ? contact.emailAddress : "N/A" }}</span
          >
        </div>
        <div
          class="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-4 mt-2"
        >
          <h2
            class="font-normal text-[16px] text-[#96a0b5] max-w-[250px] w-full"
          >
            Contact Person Phone Number
          </h2>
          <span
            class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
            >{{ contact.phoneNumber ? contact.phoneNumber : "N/A" }}</span
          >
        </div>
      </div>
    </div>

    <div v-if="isEditing">
      <h2
        class="font-semibold text-[18px] leading-[24px] text-[#353535] max-w-max w-full"
      >
        Business Profile
      </h2>
      <div
        class="flex gap-[20px] justify-center flex-col items-center mb-[16px]"
      >
        <div v-if="updatingLogo">
          <img alt="business logo" class="logoImage" :src="previewLogo" />
        </div>
        <div v-else @click="openPreview">
          <img
            v-if="businessLogoUrl"
            alt="business logo"
            class="logoImage"
            :src="`${businessLogoUrl}`"
          />
          <img
            v-else
            alt="logo placeholder"
            class="logoImage"
            src="/assets/img/logo-placeholder.png"
          />
        </div>
        <div>
          <input
            type="file"
            ref="file"
            accept="image/*"
            @change="previewFiles"
            style="display: none"
          />
          <button
            @click.prevent="$refs.file.click()"
            class="border-[1.5px] border-solid border-[#2463EB] bg-transparent px-[24px] py-[12px] rounded-[4px] text-[#2463EB] outline-none focus:outline-none flex items-center gap-[5px]"
          >
            <img src="/assets/icon/document.svg" alt="" />

            Upload New Logo
          </button>
        </div>
      </div>
      <div class=" w-full grid md:grid-cols-2 gap-[24px] max-w-[1000px] w-full">
        <div class="">
          <label class="text=[#353535] font-bold text-[14px] leading-[20px]"
            >Business Name</label
          >
          <div class="">
            <input
              :class="[{ 'is-invalid': v1.name }]"
              type="text"
              v-model="account.name"
              class="border-[#d0d5dd] border-solid border-[1.5px] py-[12px] px-[14px] rounded-[8px] w-full outline-none"
            />
            <ul v-if="v1.name" class="parsley-errors-list filled">
              <li class="parsley-required">Required.</li>
            </ul>
          </div>
        </div>
        <div class="">
          <label class="text=[#353535] font-bold text-[14px] leading-[20px]"
            >Code</label
          >
          <div class="">
            <input
              type="text"
              v-model="account.code"
              readonly
              class="border-[#d0d5dd] border-solid border-[1.5px] py-[12px] px-[14px] rounded-[8px] w-full outline-none"
            />
          </div>
        </div>
      </div>
      <div class=" w-full max-w-[1000px] w-full my-[16px]">
        <div class="">
          <label class="text=[#353535] font-bold text-[14px] leading-[20px]"
            >Business Description</label
          >
          <div class="">
            <textarea
              :class="[{ 'is-invalid': v1.description }]"
              v-model="account.description"
              class="border-[#d0d5dd] border-solid border-[1.5px] py-[12px] px-[14px] rounded-[8px] w-full outline-none"
            ></textarea>
            <ul v-if="v1.description" class="parsley-errors-list filled">
              <li class="parsley-required">Required.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class=" w-full grid md:grid-cols-2 gap-[24px] max-w-[1000px] w-full">
        <div class="">
          <label class="text=[#353535] font-bold text-[14px] leading-[20px]"
            >Business Registration</label
          >
          <div class="">
            <input
              type="text"
              v-model="account.rcNumber"
              placeholder="RC Number"
              readonly
              class="border-[#d0d5dd] border-solid border-[1.5px] py-[12px] px-[14px] rounded-[8px] w-full outline-none"
            />
          </div>
        </div>
        <div class="">
          <label class="text=[#353535] font-bold text-[14px] leading-[20px]"
            >TIN</label
          >
          <div class="">
            <input
              type="text"
              v-model="account.tin"
              placeholder="Tax Identification Number"
              readonly
              class="border-[#d0d5dd] border-solid border-[1.5px] py-[12px] px-[14px] rounded-[8px] w-full outline-none"
            />
          </div>
        </div>
      </div>
      <div
        class=" w-full grid md:grid-cols-2 gap-[24px] max-w-[1000px] w-full my-[16px]"
      >
        <div class="">
          <label class="text=[#353535] font-bold text-[14px] leading-[20px]"
            >Business Email Address</label
          >
          <div class="">
            <input
              :class="[
                { 'is-invalid': v1.emailAddress },
                { 'is-invalid': v1.emailAddressValid },
              ]"
              type="email"
              v-model="account.emailAddress"
              class="border-[#d0d5dd] border-solid border-[1.5px] py-[12px] px-[14px] rounded-[8px] w-full outline-none"
            />
            <ul v-if="v1.emailAddress" class="parsley-errors-list filled">
              <li class="parsley-required">Required.</li>
            </ul>
            <ul v-if="v1.emailAddressValid" class="parsley-errors-list filled">
              <li class="parsley-required">
                Enter a valid email address.
              </li>
            </ul>
          </div>
        </div>
        <div class="">
          <label class="text=[#353535] font-bold text-[14px] leading-[20px]"
            >Business Phone Number</label
          >
          <div class="">
            <input
              :class="[
                { 'is-invalid': v1.phoneNumber },
                { 'is-invalid': v1.phoneNumberValid },
              ]"
              type="text"
              v-model="account.phoneNumber"
              class="border-[#d0d5dd] border-solid border-[1.5px] py-[12px] px-[14px] rounded-[8px] w-full outline-none"
            />
            <ul v-if="v1.phoneNumber" class="parsley-errors-list filled">
              <li class="parsley-required">Required.</li>
            </ul>
            <ul v-if="v1.phoneNumberValid" class="parsley-errors-list filled">
              <li class="parsley-required">
                Enter a valid phone number.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class=" w-full grid md:grid-cols-3 gap-[24px] w-full">
        <div class="">
          <label class="text=[#353535] font-bold text-[14px] leading-[20px]"
            >Business Address</label
          >
          <div class="">
            <select
              placeholder="State"
              v-model="account.state"
              class="border border-[#D0D5DD] px-[14px] py-[12px] rounded-[8px] w-full text-[#96A0B5] outline-none custom-select custom-select-lg"
            >
              <option v-for="s in states" :key="s" :value="s">{{ s }}</option>
            </select>
            <ul v-if="v1.state" class="parsley-errors-list filled">
              <li class="parsley-required">Required.</li>
            </ul>
          </div>
        </div>
        <div>
          <label class="text=[#353535] font-bold text-[14px] leading-[20px]"
            >Local Government Area</label
          >
          <div>
            <select
              placeholder="Local Government Area"
              v-model="account.lga"
              :class="[{ 'is-invalid': v1.lga }]"
              class="border border-[#D0D5DD] px-[14px] py-[12px] rounded-[8px] w-full text-[#96A0B5] outline-none custom-select custom-select-lg"
            >
              <option v-for="(l, i) in lgas" :key="i" :value="l">{{
                l
              }}</option>
            </select>
            <ul v-if="v1.lga" class="parsley-errors-list filled">
              <li class="parsley-required">Required.</li>
            </ul>
          </div>
        </div>
        <div class="">
          <label
            class="text=[#353535] font-bold text-[14px] leading-[20px]"
          ></label>
          <div>
            <input
              :class="[{ 'is-invalid': v1.city }]"
              type="text"
              v-model="account.city"
              placeholder="City"
              class="border-[#d0d5dd] border-solid border-[1.5px] py-[12px] px-[14px] rounded-[8px] w-full outline-none"
            />
            <ul v-if="v1.city" class="parsley-errors-list filled">
              <li class="parsley-required">Required.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class=" w-full grid md:grid-cols-2 gap-[24px] w-full">
        <div class="">
          <label
            class="text=[#353535] font-bold text-[14px] leading-[20px]"
          ></label>
          <div>
            <input
              :class="[{ 'is-invalid': v1.address1 }]"
              type="text"
              v-model="account.address1"
              placeholder="Address Line 1"
              class="border-[#d0d5dd] border-solid border-[1.5px] py-[12px] px-[14px] rounded-[8px] w-full outline-none"
            />
            <ul v-if="v1.city" class="parsley-errors-list filled">
              <li class="parsley-required">Required.</li>
            </ul>
          </div>
        </div>
        <div class="">
          <label
            class="text=[#353535] font-bold text-[14px] leading-[20px]"
          ></label>
          <div>
            <input
              type="text"
              v-model="account.address2"
              placeholder="Address Line 2"
              class="border-[#d0d5dd] border-solid border-[1.5px] py-[12px] px-[14px] rounded-[8px] w-full outline-none"
            />
            <ul v-if="v1.city" class="parsley-errors-list filled">
              <li class="parsley-required">Required.</li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <h2
          class="font-semibold text-[18px] leading-[24px] text-[#353535] max-w-max w-full mt-[24px]"
        >
          Contact Information
        </h2>
        <div class=" w-full grid md:grid-cols-3 gap-[24px] w-full mt-[16px]">
          <div class="">
            <label class="text=[#353535] font-bold text-[14px] leading-[20px]"
              >Contact Person's Name</label
            >
            <div>
              <input
                :class="[{ 'is-invalid': v1.city }]"
                type="text"
                v-model="contact.name"
                placeholder=""
                class="border-[#d0d5dd] border-solid border-[1.5px] py-[12px] px-[14px] rounded-[8px] w-full outline-none"
              />
              <ul v-if="v1.city" class="parsley-errors-list filled">
                <li class="parsley-required">Required.</li>
              </ul>
            </div>
          </div>
          <div class="">
            <label class="text=[#353535] font-bold text-[14px] leading-[20px]"
              >Email Address</label
            >
            <div>
              <input
                :class="[{ 'is-invalid': v2.emailAddressValid }]"
                type="email"
                v-model="contact.emailAddress"
                class="border-[#d0d5dd] border-solid border-[1.5px] py-[12px] px-[14px] rounded-[8px] w-full outline-none"
              />
              <ul
                v-if="v2.emailAddressValid"
                class="parsley-errors-list filled"
              >
                <li class="parsley-required">
                  Enter a valid email address.
                </li>
              </ul>
            </div>
          </div>
          <div class="">
            <label class="text=[#353535] font-bold text-[14px] leading-[20px]"
              >Phone Number</label
            >
            <div>
              <input
                :class="[{ 'is-invalid': v2.phoneNumberValid }]"
                type="text"
                v-model="contact.phoneNumber"
                class="border-[#d0d5dd] border-solid border-[1.5px] py-[12px] px-[14px] rounded-[8px] w-full outline-none"
              />
              <ul v-if="v2.phoneNumberValid" class="parsley-errors-list filled">
                <li class="parsley-required">
                  Enter a valid phone number.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="" v-if="hasPermission('BusinessProfile_Update')">
        <div>
          <button
            class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] mt-[16px] outline-none focus:outline-none"
            @click.prevent="update"
          >
            <span v-if="isBusyUpdate">Busy...</span>
            <span v-else>Save changes</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SystemPermissions from "@/permissions";
import { helper } from "@/helper";

export default {
  mixins: [SystemPermissions.Mixin],

  components: {
    SystemPermissions,
  },
  props: {
    account: Object,
    contact: Object,
    businessLogoUrl: String,
    updatingLogo: Boolean,
    previewLogo: String,
    v1: Object,
    v2: Object,
  },
  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
    lgas: function() {
      return helper.getLgas(this.account.state);
    },
  },
  data() {
    return {
      isEditing: false,
      isBusy: false,
      isBusyUpdate: false,
      banks: helper.getBanks(),
      states: helper.getStates(),
    };
  },
  methods: {
    toggleEditMode() {
      this.isEditing = !this.isEditing;
    },
    openPreview() {
      this.$emit("openPreview");
    },
    previewFiles(event) {
      this.$emit("previewFiles", event);
    },
    async update() {
      this.isBusyUpdate = true;
      try {
        await this.$emit("update");
        this.isEditing = false;
      } catch (error) {
        console.error("Update failed:", error);
      } finally {
        this.isBusyUpdate = false;
      }
    },
  },
};
</script>

<style scoped>
.logoImage {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}
</style>
