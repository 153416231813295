<template>
  <div class="table-responsive mt-3">
    <table class="table table-hover table-sm">
      <thead class="thead-light">
        <tr>
          <th scope="col">Item #</th>
          <th scope="col">Document Name</th>
          <th scope="col">File Name</th>
          <th scope="col">Created</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ 1 }}</td>
          <td>{{ paymentDetail.title }}</td>
          <td>{{ paymentDetail.imageName }}</td>
          <td>{{ formatDate(paymentDetail.created) }}</td>
          <td>
            <button
              class="btn btn-primary btn-sm btn-rounded btn-space mr-1"
              @click.prevent="onView"
            >
              <i class="icon mdi mdi-edit text-white"></i>
              View
            </button>
            <button
              v-if="!paymentDetail.isSubmitted"
              class="btn btn-warning btn-sm btn-rounded btn-space mr-1"
              @click.prevent="onEdit"
            >
              <i class="icon mdi mdi-warning text-white"></i>
              Change
            </button>
            <button
              v-if="!paymentDetail.isSubmitted"
              class="btn btn-danger btn-sm btn-rounded btn-space mr-1"
              @click.prevent="removeItem"
            >
              <i class="icon mdi mdi-delete text-white"></i>
              Remove
            </button>
            <button
              v-if="!paymentDetail.isSubmitted"
              class="btn btn-success btn-sm btn-rounded btn-space mr-1"
              @click.prevent="submit"
            >
              <i class="icon mdi mdi-delete text-white"></i>
              Submit
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["paymentDetail", "purchaseOrderId"],

  data() {
    return {
      isLoading: false,
      isDowloading: false,
      isRemoving: false,
    };
  },

  methods: {
    async onView() {
      this.isDowloading = true;
      try {
        const response = await this.$http.get(
          "/PurchaseOrders/paymentDetail/download/" + this.purchaseOrderId,
          { responseType: "blob" }
        );

        if (response) {
          const fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/octet-stream" })
          );

          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "paymentdetail.jpg");
          document.body.appendChild(fileLink);

          fileLink.click();
        }

        this.isDowloading = false;
      } catch (error) {
        this.isDowloading = false;
      }
    },

    async onEdit() {},

    async removeItem() {
      this.isRemoving = true;
      try {
        const response = await this.$http.delete(
          "/PurchaseOrders/paymentDetail/" + this.purchaseOrderId
        );

        if (response.ok) this.$emit("onPaymentRemoveComplete");

        this.isRemoving = false;
      } catch (error) {
        this.isRemoving = false;
      }
    },

    async submit() {
      this.isRemoving = true;
      try {
        const response = await this.$http.put(
          "/PurchaseOrders/paymentDetail/" + this.purchaseOrderId
        );

        if (response.ok)
          this.paymentDetail = this.$emit("onPaymentSubmittedComplete");

        this.isRemoving = false;
      } catch (error) {
        this.isRemoving = false;
      }
    },

    formatDate(date) {
      if (date) return moment(date).format("lll");
      else return "";
    },
  },
};
</script>

<style>
</style>