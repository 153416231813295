<template>
  <div class="row">
    <div class="col-lg-12">
      <div :class="['be-loading', { 'be-loading-active': isBusy }]">
        <div class="max-w-[500px] w-full">
          <h2
            class="font-semibold text-[18px] leading-[24px] text-[#353535] max-w-max w-full mb-[24px]"
          >
            Global Distributor Settings
          </h2>

          <div class="mb-[24px]">
            <label class="text=[#353535] font-bold text-[14px] leading-[20px]"
              >Distributor Product Permission</label
            >
            <select
              v-model="selectedDistributorProductPermission"
              @change="update('product')"
              class="border border-[#D0D5DD] px-[14px] py-[12px] rounded-[8px] w-full text-[#96A0B5] outline-none custom-select custom-select-lg"
            >
              <option disabled value="default">Select permissions</option>
              <option value="noRestriction">No Restriction</option>
              <option value="onlySellMyProducts">Only Sell My Products</option>
              <option value="onlySellTheirProducts">
                Only Sell Their Products
              </option>
            </select>
          </div>

          <!-- <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >No Restriction:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              <span
                :class="
                  settings.distributorProductNoRestriction
                    ? 'badge badge-pill badge-success'
                    : 'badge badge-pill badge-secondary'
                "
              >
                {{ settings.distributorProductNoRestriction ? "YES" : "NO" }}
              </span>
            </div>
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Only Sell My Products:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              <span
                :class="
                  settings.distributorOnlySellMyProducts
                    ? 'badge badge-pill badge-success'
                    : 'badge badge-pill badge-secondary'
                "
              >
                {{ settings.distributorOnlySellMyProducts ? "YES" : "NO" }}
              </span>
            </div>
          </div> -->

          <!-- <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Only Sell Their Products:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              <span
                :class="
                  settings.distributorOnlySellTheirProducts
                    ? 'badge badge-pill badge-success'
                    : 'badge badge-pill badge-secondary'
                "
              >
                {{ settings.distributorOnlySellTheirProducts ? "YES" : "NO" }}
              </span>
            </div>
          </div> -->

          <div>
            <label class="text=[#353535] font-bold text-[14px] leading-[20px]"
              >Distributor Product Price Options View</label
            >
            <select
              v-model="selectedPriceOptionPermission"
              @change="update('price')"
              class="border border-[#D0D5DD] px-[14px] py-[12px] rounded-[8px] w-full text-[#96A0B5] outline-none custom-select custom-select-lg"
            >
              <option selected disabled value="default"
                >Select permissions</option
              >
              <option :value="true">Yes</option>
              <option :value="false">No</option>
            </select>
          </div>

          <button
            class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] mt-[16px]"
            :disabled="isBusyModal"
            type="button"
            @click.prevent="update('product')"
          >
            <span v-if="isBusyModal">Busy...</span>
            <span v-else>Save Changes</span>
          </button>
          <!-- <div>
                <label
                  class="block text-sm font-medium text-gray-700"
                  for="salesUserViewAllPriceOptions"
                  >Sales Team Sees All Product Price Options:</label
                >
                <select
                  v-model="selectedSlaesPriceOptionPermission"
                  @change="update('price')"
                  class="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                >
                  <option :value="true">Yes</option>
                  <option :value="false">No</option>
                </select>
              </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import Select2 from "@/components/Select2";
import SystemPermissions from "@/permissions";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "systemSettings",
  components: {
    Select2,
  },
  created() {
    if (!this.hasPermission("System_Settings_View")) {
      this.$router.push("/restricted");
    }
    this.getSystemSettings();
  },
  data() {
    return {
      filter: {
        status: "0",
        qry: "",
        showForMe: false,
      },
      isBusy: false,
      isBusyModal: false,
      updateSettings: {
        distributorProductNoRestriction: null,
        distributorOnlySellMyProducts: null,
        distributorOnlySellTheirProducts: null,
        distributorViewAllPriceOptions: null,
        salesUserViewAllPriceOptions: null,
        id: null,
      },
      settings: {},
      errorMessage: null,
      selectedDistributorProductPermission: null,
      selectedPriceOptionPermission: null,
      selectedSlaesPriceOptionPermission: null,
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  watch: {},

  methods: {
    reset() {
      this.filter = {
        status: "0",
        qry: "",
        showForMe: false,
      };
    },

    viewExport() {
      //   $("#mod-export").modal("show");
    },

    viewEdit() {
      if (this.settings.distributorOnlySellTheirProducts) {
        this.selectedDistributorProductPermission = "onlySellTheirProducts";
      } else if (this.settings.distributorOnlySellMyProducts) {
        this.selectedDistributorProductPermission = "onlySellMyProducts";
      } else if (this.settings.distributorProductNoRestriction) {
        this.selectedDistributorProductPermission = "noRestriction";
      }
      $("#mod-edit").modal("show");
    },

    viewEditPrice() {
      this.selectedPriceOptionPermission =
        this.settings.distributorViewAllPriceOptions || false;
      this.selectedSlaesPriceOptionPermission =
        this.settings.salesUserViewAllPriceOptions || false;
      $("#mod-edit-price").modal("show");
    },

    viewFilter() {
      $("#mod-filter").modal("show");
    },

    async update(type) {
      let data = {};
      if (type === "product") {
        data = {
          parentId: this.user.parentId,
          distributorProductNoRestriction:
            this.selectedDistributorProductPermission == "noRestriction",
          distributorOnlySellMyProducts:
            this.selectedDistributorProductPermission == "onlySellMyProducts",
          distributorOnlySellTheirProducts:
            this.selectedDistributorProductPermission ==
            "onlySellTheirProducts",
          id: this.updateSettings.id,
        };

        $("#mod-edit").modal("hide");
        await this.$http
          .put("/SystemSettings/updateManufacturerSettings/" + data.id, data)
          .then(function(res) {
            this.isBusyModal = false;
            this.$toast.success(res.body);
          })
          .catch(function() {
            this.$toast.error("Failed to update, try again");
          });
      } else if (type === "price") {
        data = {
          parentId: this.user.parentId,
          distributorViewAllPriceOptions: this.selectedPriceOptionPermission,
          salesUserViewAllPriceOptions: this.selectedSlaesPriceOptionPermission,
          id: this.updateSettings.id,
        };

        $("#mod-edit-price").modal("hide");

        await this.$http
          .put(
            "/SystemSettings/updateManufacturerPriceOptionSettings/" + data.id,
            data
          )
          .then(function(res) {
            this.isBusyModal = false;
            this.$toast.success(res.body);
          })
          .catch(function() {
            this.$toast.error("Failed to update, try again");
          });
      }

      this.reset();

      this.getSystemSettings();

      this.isBusy = false;
      this.isBusyModal = false;
    },

    async getSystemSettings() {
      this.isBusy = true;

      await this.$http
        .get(
          "/SystemSettings/getManufacturerSettingsByAccount?parentId=" +
            this.user.parentId
        )
        .then(function(res) {
          this.settings = { ...res.body };
          this.updateSettings = { ...res.body };
          if (this.settings.distributorOnlySellTheirProducts) {
            this.selectedDistributorProductPermission = "onlySellTheirProducts";
          } else if (this.settings.distributorOnlySellMyProducts) {
            this.selectedDistributorProductPermission = "onlySellMyProducts";
          } else if (this.settings.distributorProductNoRestriction) {
            this.selectedDistributorProductPermission = "noRestriction";
          } else {
            this.selectedDistributorProductPermission = "default";
          }
          this.selectedPriceOptionPermission =
            this.settings.distributorViewAllPriceOptions !== undefined
              ? this.settings.distributorViewAllPriceOptions
              : "default";
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },

    formatDate(date) {
      return moment(date).format("lll");
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    reset() {
      this.updateSettings = {
        distributorProductNoRestriction: null,
        onlySellMyProducts: null,
        onlySellTheirProducts: null,
        distributorViewAllPriceOptions: null,
        salesUserViewAllPriceOptions: null,
        id: null,
      };
    },
  },
};
</script>
