/* eslint-disable */

const SystemPermissions = {
	//BRANCH_CREATE: "Branch_Create",
	//WINTER: "winter",
	//SPRING: "spring",
	//AUTUMN: "autumn",
}

SystemPermissions.Mixin = {
	created() {
		//this.SystemPermissions = SystemPermissions
	},
	methods: {
		hasPermission(val) {
			var user = this.$store.state.currentUser;
			// if ((user.type != 'Distributor' && user.type != 'Manufacturer') || (user.type == 'Manufacturer' && user.isSalesUser))
			if ((user.type != 'Distributor' && user.type != 'Manufacturer') )
				return true;
			var userPermissions = user.permissions;
			var hasPermission = userPermissions.includes(val)
			return hasPermission;
		},
	}
}

export default SystemPermissions