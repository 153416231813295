<template>
  <div class="modal fade" id="mod-hide-order-stage" role="dialog">
    <div class="modal-dialog mx-auto max-w-[420px] w-full">
      <div class="modal-content p-[24px] bg-[#fff]">
        <div class="flex items-start justify-between p-0 m-0">
          <h4
            class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
          >
            Confirmation
          </h4>
          <button
            class="text-2xl text-[#353535] outline-none"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body m-0 p-0">
          <h4 class="text-[#96A0B5] text-[14px] font-normal leading-[16.94px]">
            {{ text }}
          </h4>
        </div>
        <div class="flex items-center gap-[8px] mt-[16px]">
          <button
            class="border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full outline-none focus:outline-none"
            type="button"
            data-dismiss="modal"
          >
            No
          </button>
          <button
            class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] w-full outline-none focus:outline-none"
            type="button"
            @click.prevent="onSubmitClick"
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HideStageCaution",
  props: ["text"],

  methods: {
    onSubmitClick() {
      console.log("clicked");
      this.$emit("proceed");
    },
  },
};
</script>

<style></style>
