<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <h4>
            <b>{{ groupName }} members</b>
          </h4>
          <div class="btn-group">
            <button
              class="btn btn-primary btn-rounded btn-space float-right"
              v-if="hasPermission('Group_Create_Members')"
              @click="viewAdd"
            >
              <span class="icon icon-left mdi mdi-plus text-white"></span> Add
              member
            </button>

            <router-link
              class="btn btn-space btn-success btn-rounded float-right"
              to="/settings/groups"
            >
              <span
                class="icon icon-left mdi mdi-arrow-left text-primary"
              ></span>
              Back
            </router-link>
          </div>
        </div>

        <div class="table-responsive">
          <table
            class="
              table table-fw-widget
              dataTable
              no-footer
              table-striped table-hover-animation table-bordered
            "
          >
            <thead>
              <tr>
                <th style="width: 55%">Name</th>
                <th style="width: 20%">Member Type</th>
                <th style="width: 15%">Joined</th>
                <th style="width: 10%"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="m in members" :key="m.id">
                <td>{{ m.memberName }}</td>
                <td>{{ m.type }}</td>
                <td>{{ formatDate(m.joined) }}</td>
                <td>
                  <div
                    class="text-right"
                    v-if="hasPermission('Group_Remove_Members')"
                  >
                    <button
                      class="btn btn-secondary btn-rounded btn-space"
                      @click.prevent="remove(m.memberId)"
                    >
                      Remove
                    </button>
                  </div>
                </td>
              </tr>
              <tr v-if="!members.length">
                <td colspan="4">
                  <div class="text-center">No members</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-add"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Add new member</h3>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Member Type</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="newMember.type"
                >
                  <option v-for="type in types" :key="type" :value="type">
                    {{ type }}
                  </option>
                </select>
                <ul v-if="v1.type" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Member Name</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select2
                  placeholder="Select an option"
                  v-model="newMember.memberId"
                >
                  <option v-for="m in newMembers" :key="m.id" :value="m.id">
                    {{ m.text }}
                  </option>
                </select2>
                <ul v-if="v1.memberId" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="add"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import Select2 from "@/components/Select2";
import SystemPermissions from "@/permissions";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsDiscountMembers",
  components: {
    Select2,
  },
  created() {
    if (!this.hasPermission("Group_View_Members")) {
      this.$router.push("/restricted");
    }
    this.groupId = this.$route.params.id;
    this.getGroup();
    this.getMembers();
    this.getTypes();
  },
  data() {
    return {
      groupId: null,
      groupName: "",
      isBusy: false,
      isBusyModal: false,
      newMember: {
        memberId: null,
        type: null,
        groupId: null,
      },
      v1: {
        memberId: false,
        type: false,
      },
      members: [],
      types: [],
      newMembers: [],
      count: 0,
      page: 0,
      pageSize: 10,
      hasNext: false,
      hasPrev: false,
    };
  },
  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },
  watch: {
    "newMember.type": function (newVal, oldVal) {
      this.onTypeChange(newVal);
    },
  },
  methods: {
    viewAdd() {
      this.newMember = {
        memberId: null,
        type: null,
        groupId: this.groupId,
      };
      this.v1 = {
        memberId: false,
        type: false,
      };

      $("#mod-add").modal("show");
    },
    add() {
      this.isBusyModal = true;
      this.isBusy = true;

      if (this.validate()) {
        $("#mod-add").modal("hide");

        this.$http
          .post("/Groups/addMember", this.newMember)
          .then(function (res) {
            this.isBusyModal = false;
            this.getMembers();
          })
          .catch(function () {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },
    validate() {
      this.v1 = {
        memberId: false,
        type: false,
      };
      var isValid = true;

      if (!this.newMember.memberId) {
        this.v1.memberId = true;
        isValid = false;
      }

      if (!this.newMember.type) {
        this.v1.type = true;
        isValid = false;
      }

      return isValid;
    },

    remove(id) {
      this.isBusy = true;

      this.$http
        .delete("/Groups/deleteMember/" + id)
        .then(function (res) {
          this.isBusy = false;
          this.getMembers();
        })
        .catch(function () {
          this.isBusy = false;
        });
    },

    getGroup() {
      this.isBusy = true;

      this.$http
        .get("/Groups/getById/" + this.groupId)
        .then(function (res) {
          this.groupName = res.body.name;
          this.isBusy = false;
        })
        .catch(function () {
          this.isBusy = false;
        });
    },
    getMembers() {
      this.isBusy = true;

      this.$http
        .get(
          "/Groups/getMembers/" +
            this.groupId +
            "/Customer" +
            "?PageNumber=" +
            this.page +
            "&PageSize=" +
            this.pageSize
        )
        .then(function (res) {
          this.members = res.body.data;
          this.isBusy = false;
        })
        .catch(function () {
          this.isBusy = false;
        });
    },
    getTypes() {
      this.types = ["Customer"];
    },
    onTypeChange(type) {
      this.newMembers = [];
      this.newMember.memberId = "";

      if (type) {
        switch (type) {
          case "Merchant":
            this.isBusyModal = true;

            this.$http
              .get(
                "/Accounts/getItemList?parentId=" +
                  this.user.parentId +
                  "&accountType=" +
                  this.user.type
              )
              .then(function (res) {
                this.newMembers = res.body;
                this.isBusyModal = false;
              })
              .catch(function () {
                this.isBusyModal = false;
              });
            break;
          case "Customer":
            this.isBusyModal = true;

            this.$http
              .get("/Users/getItemListOfCustomers/" + this.user.parentId)
              .then(function (res) {
                this.newMembers = res.body;
                this.isBusyModal = false;
              })
              .catch(function () {
                this.isBusyModal = false;
              });
            break;
          default:
            break;
        }
      }
    },
    formatDate(date) {
      return moment(date).format("ll");
    },
  },
};
</script>