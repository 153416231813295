<template>
	<div class="row">
		<div class="col-lg-12">
			<div
				:class="[
					'card',
					'card-border-color',
					'card-border-color-primary',
					' card-table',
					'be-loading',
					{ 'be-loading-active': isBusy },
				]"
			>
				<div class="card-header justify-content-end">
					<button
						v-if="hasPermission('Product_Category_Create')"
						class="btn btn-primary btn-rounded btn-space float-right"
						@click="viewAdd"
					>
						<i class="feather icon-plus text-white"></i> &nbsp;New Product Bundle
					</button>
				</div>

				<div class="card-body">
					<div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
						<div class="row be-datatable-body">
							<div class="col-sm-12 table-responsive">
								<table
									class="
                    table table-fw-widget
                    dataTable
                    no-footer
                    text-nowrap
                    table-hover-animation table-striped
                  "
								>
									<thead>
										<tr>
											<th>Name</th>
											<th>Status</th>
											<th>Category</th>
											<th>Inventory</th>
											<th>Reorder level</th>
											<th>Date Created</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="pb in productBundleList" :key="pb.id">
											<td>
												<div class="d-flex align-items-center">
													<img
														v-if="pb.imageUrl"
														class="image-preview mr-1"
														:src="pb.imageUrl"
														alt="logo"
														width="40"
														height="40"
													/>
													<img
														v-else
														class="image-preview mr-1"
														src="/assets/img/avatar.svg"
														alt="logo"
														width="40"
													/>
													<div>
														{{ pb.name }}
													</div>
												</div>
											</td>
											<td>
												<span
													:class="[
														'badge',
														' badge-pill',
														{ 'badge-success': pb.active },
														{ 'badge-danger': !pb.active },
													]"
													>{{ pb.active ? "Active" : "Inactive" }}</span
												>
											</td>
											<td>{{ pb.category }}</td>
											<td>{{ pb.inventory }}</td>
											<td>{{ pb.reOrderLevel }}</td>
											<td>{{ formatDate(pb.created) }}</td>
											<td>
												<button
													class="
                              btn btn-outline-primary btn-sm
                              dropdown-toggle
                            "
													type="button"
													id="dropdownMenu2"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
												>
													Actions
												</button>
												<div
													class="dropdown-menu px-1"
													aria-labelledby="dropdownMenu2"
												>
													<div>
														<a
															class="dropdown-item"
															href="#"
															@click.prevent="
																viewEditMyProductBundle(pb)
															"
														>
															<i class="feather icon-edit"></i>
															&nbsp;Edit
														</a>
													</div>

													<a
														class="dropdown-item"
														v-if="hasPermission('Product_Delete')"
														href="#"
														style="color: red"
														@click.prevent="deleteModal(pb.id)"
													>
														Delete Product
													</a>
												</div>
											</td>
										</tr>
										<tr v-if="productBundleList.length == 0">
											<td colspan="7">
												<div class="text-center" style="padding-top: 50px">
													<span
														style="font-size: 4.615rem"
														class="mdi mdi-tag-multiple"
													></span>
												</div>
												<h3
													class="text-center"
													style="padding-bottom: 100px"
												>
													You don't have any products Bundles.
												</h3>
											</td>
										</tr>
									</tbody>
								</table>
								<Pagination
									:limit="pageSize"
									:count="pageCount"
									:page="page"
									:itemsCount="count"
									@changePage="gotoPage"
									@changeLimit="changeLimit"
								/>
							</div>
						</div>
						<div class="row be-datatable-footer">
							<div class="col-sm-5">
								<div class="dataTables_info">
									{{ count }} product{{ count != 1 ? "s" : "" }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="be-spinner">
					<svg width="40px" height="40px" viewBox="0 0 66 66">
						<circle
							class="circle"
							fill="none"
							stroke-width="4"
							stroke-linecap="round"
							cx="33"
							cy="33"
							r="30"
						></circle>
					</svg>
				</div>
			</div>
		</div>
		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-add-product-bundle"
			role="dialog"
		>
			<div class="modal-dialog lg-modal">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<div>
							<h4 class="modal-title px-1 pt-1"><strong>Create Bundle</strong></h4>
							<small class="px-1">Merge multiple products to form a bundle</small>
						</div>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body my-2">
						<div class="form-group row justify-content-center ">
							<label class="col-12 col-sm-3 col-form-label"
								>Product Bundle Name</label
							>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<input
										:class="['form-control', { 'is-invalid': v1.name }]"
										class="col-12"
										placeholder="Enter name"
										type="text"
										v-model="createProductBundle.name"
									/>
									<div v-if="v1.name" class="parsley-errors-list filled">
										<p class="text-danger error-text ">Enter a valid name.</p>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group row justify-content-center ">
							<label class="col-12 col-sm-3 col-form-label">Categories</label>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<select
										:class="['form-control']"
										class="col-12"
										v-model="createProductBundle.categoryId"
									>
										<option :value="null" disabled selected
											>Select a Category</option
										>
										<option
											v-for="option in categoryList"
											:key="option.id"
											:value="option.id"
											>{{ option.name }}</option
										>
									</select>
									<div v-if="v1.categoryId" class="parsley-errors-list filled">
										<p class="text-danger error-text ">Enter a valid name.</p>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label">Create PB Code</label>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12 col-sm-10 col-lg-12">
									<input
										:class="['form-control', { 'is-invalid': v1.code }]"
										class="col-12"
										type="text"
										v-model="createProductBundle.code"
										@blur="
											validateProductCode(
												createProductBundle.code,
												user.parentId,
											)
										"
									/>
									<span
										v-if="isProductValid == true"
										class="text-danger error-text "
									>
										Product code already exists
									</span>
								</div>
								<i
									v-if="isProductValid == true"
									class="text-danger fa fa-times"
								></i>
								<i
									v-if="isProductValid == false"
									class="text-success fa fa-check"
								></i>
							</div>
						</div>
						<div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label">Add Description</label>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<textarea
										class="form-control"
										v-model="createProductBundle.description"
									></textarea>
									<div v-if="v1.description" class="parsley-errors-list filled">
										<p class="text-danger error-text ">
											Enter a valid Description.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label">Select Products</label>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<div
										:key="`input-${index}`"
										v-for="(input, index) in createProductBundle.products"
										class="d-flex align-items-center justify-content-between mb-2"
									>
										<div class="mr-1">
											<select
												:class="['form-control']"
												class="select2 form-control"
												v-model="input.productId"
												@change="getProductprice(input.productId)"
											>
												<option value="" selected disabled
													>Select Product</option
												>
												<option
													v-for="option in products"
													:key="option.id"
													:value="option.id"
													>{{ option.name }}</option
												>
											</select>
										</div>
										<div>
											<input
												:class="['form-control']"
												class="col-12"
												placeholder="Enter Quantity"
												type="number"
												v-model.number="input.quantity"
											/>
										</div>
										<div
											v-if="createProductBundle.products.length > 1"
											class="delete-list col-1"
										>
											<span
												@click="
													deleteProductList('createProductBundle', index)
												"
												style="color:red;cursor:pointer"
												class="mdi mdi-close"
											></span>
										</div>
									</div>

									<span
										class="text-primary mt-2"
										style="width:74%"
										@click.prevent="addFields()"
									>
										<i class="fa fa-plus "></i>
										<span>&nbsp; &nbsp;Add another product to bundle </span>
									</span>
								</div>
							</div>
						</div>
						<div class="form-group row justify-content-center ">
							<label class="col-12 col-sm-3 col-form-label">Unit</label>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<select
										:class="['form-control']"
										class="select2 form-control"
										v-model="createProductBundle.unit"
									>
										<option :value="null" disabled selected
											>Select a unit</option
										>
										<option
											v-for="unit in units"
											:key="unit.name"
											:value="unit.name"
										>
											{{ unit.name }}
										</option>
									</select>
									<div v-if="v1.unit" class="parsley-errors-list filled">
										<p class="text-danger error-text ">Required</p>
									</div>
								</div>
							</div>
						</div>
						<!-- <div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label"
								>Additionnal Cost</label
							>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<input
										:class="[
											'form-control',
											{ 'is-invalid': v1.additionalCost },
										]"
										class="col-12"
										type="text"
										v-model="createProductBundle.additionalCost"
									/>
									<div
										v-if="v1.additionalCost"
										class="parsley-errors-list filled"
									>
										<p class="text-danger error-text ">
											Enter a valid additional cost.
										</p>
									</div>
								</div>
							</div>
						</div> -->
						<!-- <div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label"
								>Add Product Unit</label
							>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<input
										:class="['form-control', { 'is-invalid': v1.productUnit }]"
										class="col-12"
										placeholder="Enter Unit"
										type="text"
										v-model="createProductBundle.productUnit"
									/>
									<div v-if="v1.productUnit" class="parsley-errors-list filled">
										<p class="text-danger error-text ">
											Enter a product unit.
										</p>
									</div>
								</div>
							</div>
						</div> -->
						<div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label">Add Retail Price</label>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<input
										:class="['form-control', { 'is-invalid': v1.retailPrice }]"
										class="col-12"
										placeholder="Enter Retail Price"
										type="text"
										v-model="createProductBundle.retailPrice"
									/>
									<div v-if="v1.retailPrice" class="parsley-errors-list filled">
										<p class="text-danger error-text ">
											Enter a retail price.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label">Add Reorder Level</label>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<input
										:class="['form-control', { 'is-invalid': v1.reOrderLevel }]"
										class="col-12"
										placeholder="Enter ReOrder Level"
										type="text"
										v-model="createProductBundle.reOrderLevel"
									/>
									<div v-if="v1.reOrderLevel" class="parsley-errors-list filled">
										<p class="text-danger error-text ">
											Enter a reorder level.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label">Available for sale</label>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<label
										class="custom-control custom-checkbox custom-control-inline"
									>
										<input
											class="custom-control-input"
											type="checkbox"
											v-model="createProductBundle.availability"
											false-value="0"
											true-value="1"
										/>
										<span class="custom-control-label"
											>This product is available for sale</span
										>
									</label>
								</div>
							</div>
						</div>
						<div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label">Active</label>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<label
										class="custom-control custom-checkbox custom-control-inline"
									>
										<input
											class="custom-control-input"
											type="checkbox"
											v-model="createProductBundle.active"
										/>
										<span class="custom-control-label"
											>This product is active</span
										>
									</label>
								</div>
							</div>
						</div>
						<div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label">Product Image</label>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<div class="d-flex align-items-center">
										<div>
											<img
												v-if="previewUrl"
												class="image-preview"
												:src="previewUrl"
												alt="logo"
												width="40"
												height="40"
											/>
										</div>

										<input
											id="file"
											type="file"
											class="custom-file-input"
											@change="uploadFile"
											ref="file"
											hidden
										/>
										<div class="d-flex align-items-center" v-if="previewUrl">
											<button
												class="btn btn-custom btn-primary btn-md ml-2"
												@click="onPickFile"
											>
												Upload New Image
											</button>
											<button
												v-if="previewUrl"
												class="btn btn-custom btn-outline-danger btn-nobordered btn-md ml-2"
												@click="cancelUpload"
											>
												Delete image
											</button>
										</div>
										<button
											v-else
											class="btn btn-custom btn-outline-primary btn-md ml-2"
											@click="onPickFile"
										>
											Upload Image
										</button>
									</div>

									<small class="image-size-text">(Max 1MB)</small>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
							@click="cancelUpload"
						>
							Cancel
						</button>
						<button
							style="width: 160px"
							:disabled="isBusy || isProductValid == true"
							class="btn btn-primary"
							type="button"
							@click.prevent="confirmModal"
						>
							<span v-if="isBusy">Busy...</span>
							<span v-else>Add Product Bundle</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-edit-product-bundle"
			role="dialog"
		>
			<div class="modal-dialog lg-modal">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<div>
							<h4 class="modal-title px-1 pt-1">
								<strong>Edit Product Bundle</strong>
							</h4>
							<small class="px-1">Merge multiple products to form a bundle</small>
						</div>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body my-2">
						<div class="form-group row justify-content-center ">
							<label class="col-12 col-sm-3 col-form-label"
								>Product Bundle Name</label
							>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<input
										:class="['form-control', { 'is-invalid': v1.name }]"
										class="col-12"
										placeholder="Enter name"
										type="text"
										v-model="updateProductBundle.name"
									/>
									<div v-if="v2.name" class="parsley-errors-list filled">
										<p class="text-danger error-text ">Enter a valid name.</p>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group row justify-content-center ">
							<label class="col-12 col-sm-3 col-form-label">Categories</label>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<select
										:class="['form-control']"
										class="select2 form-control"
										v-model="updateProductBundle.categoryId"
									>
										<option
											v-for="option in categoryList"
											:key="option.id"
											:value="option.id"
											>{{ option.name }}</option
										>
									</select>
									<div v-if="v2.categoryId" class="parsley-errors-list filled">
										<p class="text-danger error-text ">Enter a valid name.</p>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label">Create PB Code</label>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<input
										:class="['form-control', { 'is-invalid': v2.code }]"
										class="col-12"
										placeholder="Create Code"
										type="text"
										v-model="updateProductBundle.code"
									/>
									<div v-if="v2.code" class="parsley-errors-list filled">
										<p class="text-danger error-text ">Enter a valid Code.</p>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label">Add Description</label>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<textarea
										class="form-control"
										v-model="updateProductBundle.description"
									></textarea>
									<div v-if="v2.description" class="parsley-errors-list filled">
										<p class="text-danger error-text ">
											Enter a valid Description.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label">Select Products</label>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<div
										:key="`input-${index}`"
										v-for="(input, index) in updateProductBundle.products"
										class="d-flex align-items-center justify-content-between mb-2"
									>
										<div class="mr-1">
											<select
												:class="['form-control']"
												class="select2 form-control"
												v-model="input.productId"
												@change="getProductprice(input.productId)"
											>
												<option value="" selected disabled
													>Select Product</option
												>
												<option
													v-for="option in products"
													:key="option.id"
													:value="option.id"
													>{{ option.name }}</option
												>
											</select>
										</div>
										<div>
											<input
												:class="['form-control']"
												class="col-12"
												placeholder="Enter Quantity"
												type="number"
												v-model.number="input.quantity"
											/>
										</div>
										<div
											v-if="updateProductBundle.products.length > 1"
											class="delete-list col-1"
										>
											<span
												@click="
													deleteProductList('updateProductBundle', index)
												"
												style="color:red;cursor:pointer"
												class="mdi mdi-close"
											></span>
										</div>
									</div>

									<span
										class="text-primary mt-2"
										style="width:74%"
										@click.prevent="addFields()"
									>
										<i class="fa fa-plus "></i>
										<span>&nbsp; &nbsp;Add another product to bundle </span>
									</span>
								</div>
							</div>
						</div>
						<div class="form-group row justify-content-center ">
							<label class="col-12 col-sm-3 col-form-label">Unit</label>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<select
										:class="['form-control']"
										class="select2 form-control"
										v-model="updateProductBundle.unit"
									>
										<option :value="null" disabled selected
											>Select a unit</option
										>
										<option
											v-for="unit in units"
											:key="unit.name"
											:value="unit.name"
										>
											{{ unit.name }}
										</option>
									</select>
									<div v-if="v2.unit" class="parsley-errors-list filled">
										<p class="text-danger error-text ">Required</p>
									</div>
								</div>
							</div>
						</div>

						<!-- <div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label"
								>Additionnal Cost</label
							>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<input
										:class="[
											'form-control',
											{ 'is-invalid': v2.additionalCost },
										]"
										class="col-12"
										type="text"
										v-model="updateProductBundle.additionalCost"
									/>
									<div
										v-if="v2.additionalCost"
										class="parsley-errors-list filled"
									>
										<p class="text-danger error-text ">
											Enter a valid additional cost.
										</p>
									</div>
								</div>
							</div>
						</div> -->
						<!-- <div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label"
								>Add Product Unit</label
							>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<input
										:class="['form-control', { 'is-invalid': v2.productUnit }]"
										class="col-12"
										placeholder="Enter Unit"
										type="text"
										v-model="updateProductBundle.productUnit"
									/>
									<div v-if="v2.productUnit" class="parsley-errors-list filled">
										<p class="text-danger error-text ">
											Enter a product unit.
										</p>
									</div>
								</div>
							</div>
						</div> -->
						<div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label">Add Retail Price</label>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<input
										:class="['form-control', { 'is-invalid': v2.retailPrice }]"
										class="col-12"
										placeholder="Enter Retail Price"
										type="text"
										v-model="updateProductBundle.retailPrice"
									/>
									<div v-if="v2.retailPrice" class="parsley-errors-list filled">
										<p class="text-danger error-text ">
											Enter a retail price.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Add Reorder Level</label
							>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<input
										:class="['form-control', { 'is-invalid': v2.reOrderLevel }]"
										class="col-12"
										placeholder="Enter ReOrder Level"
										type="text"
										v-model="updateProductBundle.reOrderLevel"
									/>
									<div v-if="v2.reOrderLevel" class="parsley-errors-list filled">
										<p class="text-danger error-text ">
											Enter a reorder level.
										</p>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label">Available for sale</label>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<label
										class="custom-control custom-checkbox custom-control-inline"
									>
										<input
											class="custom-control-input"
											type="checkbox"
											v-model="updateProductBundle.availability"
											false-value="0"
											true-value="1"
										/>
										<span class="custom-control-label"
											>This product is available for sale</span
										>
									</label>
								</div>
							</div>
						</div>
						<div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Active</label
							>
							<div class="col-12 col-sm-8 col-lg-8 d-flex align-items-center ">
								<div class="col-12">
									<label
										class="custom-control custom-checkbox custom-control-inline"
									>
										<input
											class="custom-control-input"
											type="checkbox"
											v-model="updateProductBundle.active"
										/>
										<span class="custom-control-label"
											>This product is active</span
										>
									</label>
								</div>
							</div>
						</div>
						<div class="form-group row justify-content-center">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Product Image</label
							>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<div class="d-flex align-items-center">
										<div>
											<img
												v-if="previewUrl"
												class="image-preview"
												:src="previewUrl"
												alt="logo"
												width="40"
												height="40"
											/>
										</div>

										<input
											id="file"
											type="file"
											class="custom-file-input"
											@change="updateuploadFile"
											ref="file"
											hidden
										/>
										<div class="d-flex align-items-center" v-if="previewUrl">
											<button
												class="btn btn-custom btn-primary btn-md ml-2"
												@click="onPickFile"
											>
												Upload New Image
											</button>
											<button
												v-if="previewUrl"
												class="btn btn-custom btn-outline-danger btn-nobordered btn-md ml-2"
												@click="cancelUpload"
											>
												Delete image
											</button>
										</div>
										<button
											v-else
											class="btn btn-custom btn-outline-primary btn-md ml-2"
											@click="onPickFile"
										>
											Upload Image
										</button>
									</div>

									<small class="image-size-text">(Max 1MB)</small>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
							@click="cancelUpload"
						>
							Cancel
						</button>
						<button
							style="width: 160px"
							:disabled="isBusy"
							class="btn btn-primary"
							type="button"
							@click.prevent="updateMyProductBundle"
						>
							<span v-if="isBusy">Busy...</span>
							<span v-else>Update</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade" id="mod-confirm" role="dialog">
			<div class="modal-dialog modal-dialog-centered modal-sm">
				<div class="modal-content">
					<div class="modal-body p-2">
						<p class="text-center">
							Are you sure you want to create this <br />
							Product Bundle?
						</p>
						<div class="mt-2 d-flex justify-content-between align-items-center">
							<button
								class="btn btn-outline-danger btn-nobordered"
								type="button"
								data-dismiss="modal"
								@click.prevent="cancelConfirm"
							>
								No, I don't
							</button>
							<button
								:disabled="isBusy"
								class="btn btn-outline-primary btn-nobordered"
								type="button"
								@click.prevent="createMyProductBundle"
							>
								<span v-if="isBusy">Busy...</span>
								<span v-else>Yes, I do</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade" id="mod-delete" role="dialog">
			<div class="modal-dialog modal-dialog-centered modal-sm">
				<div class="modal-content">
					<div class="modal-body p-2">
						<p class="text-center">
							Are you sure you want to delete this <br />
							Product Bundle?
						</p>
						<div class="mt-2 d-flex justify-content-between align-items-center">
							<button
								class="btn btn-outline-danger btn-nobordered"
								type="button"
								data-dismiss="modal"
								@click.prevent="cancelConfirm"
							>
								No, I don't
							</button>
							<button
								:disabled="isBusy"
								class="btn btn-outline-primary btn-nobordered"
								type="button"
								@click.prevent="confirmDelete"
							>
								<span v-if="isBusy">Busy...</span>
								<span v-else>Yes, I do</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	/* eslint-disable */

	import moment from "moment";
	import { helper } from "@/helper";
	import Select2 from "@/components/Select2";
	import GeneralDeleteCaution from "../components/GeneralDeleteCaution.vue";
	import GeneralDeleteMessage from "../components/GeneralDeleteMessage.vue";
	import SystemPermissions from "@/permissions";
	import Pagination from "../components/Pagination.vue";

	export default {
		mixins: [SystemPermissions.Mixin],
		name: "ProductsBundle",
		components: {
			Select2,
			GeneralDeleteCaution,
			GeneralDeleteMessage,
			Pagination,
		},
		data() {
			return {
				filter: {
					status: "0",
					categoryId: "0",
					qry: "",
				},
				isBusy: false,
				isBusy: false,
				count: 0,
				page: 0,
				pageNumber: 1,
				pageSize: 10,
				pageCount: 0,
				hasNext: false,
				hasPrev: false,
				productBundleList: [],
				previewUrl: "",
				products: [],
				categoryList: [],
				createProductBundle: {
					name: "",
					code: "",
					description: "",
					products: [{}],
					// additionalCost: "",
					// productUnit: 0,
					retailPrice: "",
					reOrderLevel: 0,
					availability: null,
					image: null,
					active: false,
					categoryId: null,
					units: null,
				},
				updateProductBundle: {
					name: null,
					code: null,
					description: null,
					products: [{}],
					additionalCost: null,
					// productUnit: 0,
					retailPrice: null,
					id: null,
					reOrderLevel: 0,
					availability: null,
					image: null,
					active: false,
					categoryId: 0,
					units: null,
				},
				editProductBundle: {
					name: null,
					code: "",
					description: "",
					products: [{ productId: "", quantity: 0 }],
					// additionalCost: "",
					// productUnit: 0,
					retailPrice: "",
					id: "",
					reOrderLevel: 0,
					units: null,
					availability: null,
					image: null,
					active: false,
					categoryId: null,
				},
				v1: {
					name: false,
					code: false,
					description: false,
					// additionalCost: false,
					// productUnit: false,
					retailPrice: false,
					reOrderLevel: false,
					availability: null,
					units: null,
					active: false,
				},
				v2: {
					name: false,
					code: false,
					description: false,
					// additionalCost: false,
					// productUnit: false,
					retailPrice: false,
					reOrderLevel: false,
					availability: null,
					units: null,
					active: false,
				},
				productsItem: [],
				DeleteId: "",
				isProductValid: null,
				deleteId: null,
			};
		},
		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},
		},
		created() {
			this.getUnits();
			this.getTypes();
			this.getProducts();
			this.getCategories();
			this.getproductBundleList();
		},

		// mounted() {
		// 	this.confirmModal();
		// },

		methods: {
			viewAdd() {
				$("#mod-add-product-bundle").modal("show");
				this.previewUrl = "";
				this.createProductBundle.image = null;
				this.updateProductBundle.image = null;
			},
			getProductprice(val) {
				let productCost = this.products.filter(x => x.id == val)[0].retailPrice;
				let productName = this.products.filter(x => x.id == val)[0].name;
				this.productsItem.push({ name: productName, cost: productCost });
			},

			deleteProductList(mode, index) {
				if (this.createProductBundle.products.length > 1 && mode == "createProductBundle") {
					this.createProductBundle.products.splice(index, 1);
				} else if (
					this.updateProductBundle.products.length > 1 &&
					mode == "updateProductBundle"
				) {
					this.updateProductBundle.products.splice(index, 1);
				}
			},
			// createMyProductBundle() {
			// 	console.log("hello");
			// },
			// viewFilter() {
			// 	console.log("filter");
			// },
			confirmModal() {
				$("#mod-confirm").modal("show");
			},
			deleteModal(id) {
				$("#mod-delete").modal("show");
				this.deleteId = id;
			},
			addFields() {
				this.createProductBundle.products.push({});
			},
			removeFields(index, products) {
				products.splice(index, 1);
			},
			onPickFile() {
				this.$refs.file.click();
			},
			cancelUpload() {
				this.previewUrl = "";
				this.createProductBundle.image = null;
				this.updateProductBundle.image = null;
			},
			uploadFile() {
				this.createProductBundle.image = this.$refs.file.files[0];
				this.previewUrl = URL.createObjectURL(this.createProductBundle.image);
			},
			updateuploadFile() {
				this.updateProductBundle.image = this.$refs.file.files[0];
				this.previewUrl = URL.createObjectURL(this.updateProductBundle.image);
			},

			async validateProductCode(code, parentId) {
				this.isBusy = true;
				try {
					const response = await this.$http.get(
						`/Products/distributorCodeExist/${parentId}/${code}`,
					);
					if (response.ok) {
						this.isBusy = false;
						this.isProductValid = response.data;
						return;
					}
				} catch (err) {
					this.$toast.info(err, {
						icon: true,
						rtl: false,
					});
				}
			},

			// async getProducts() {
			// 	this.isBusy = true;
			// 	await this.$http
			// 		.get(
			// 			"/Products/getListForDistributor?pageNumber=" +
			// 				this.pageNumber +
			// 				"&pageSize=" +
			// 				this.pageSize +
			// 				(this.filter.status != "0" ? "&active=" + this.filter.status : "") +
			// 				(this.filter.categoryId != "0"
			// 					? "&categoryId=" + this.filter.categoryId
			// 					: "") +
			// 				(this.filter.qry != "" ? "&query=" + this.filter.qry : ""),
			// 		)
			// 		.then(function(res) {
			// 			this.products = res.body.data;
			// 			this.pageNumber = res.body.pageNumber;
			// 			this.pageSize = res.body.pageSize;
			// 			this.count = res.body.count;
			// 			this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
			// 			this.hasPrev = res.body.hasPrev;
			// 			this.hasNext = res.body.hasNext;
			// 			this.isBusy = false;
			// 		})
			// 		.catch(function() {});

			// 	$("#mod-filter").modal("hide");
			// 	this.isBusy = false;
			// },

			getUnits() {
				var t = "Product Unit";
				var a = true;

				this.$http
					.get(
						"/Categories/getListNoPaggingForDistributor?type=" +
							t +
							"&active=" +
							a +
							(this.user.parentId
								? "&parentId=" + this.user.parentId
								: "Product Category"),
					)
					.then(function(res) {
						this.units = res.body;
					})
					.catch(function() {});
			},

			async getProducts() {
				this.isBusy = true;
				await this.$http
					.get(
						"/Products/getListNoPagingForDistributor?" +
							(this.user.parentId ? "parentId=" + this.user.parentId : "") +
							"&active=true",
					)
					.then(function(res) {
						this.products = res.body;
						this.isBusy = false;
					})
					.catch(function() {});

				$("#mod-filter").modal("hide");
				this.isBusy = false;
			},

			getTypes() {
				this.types = ["Finite", "Infinite"];
			},

			getCategories() {
				var t = "Product Category";
				this.$http
					.get(
						"/Categories/getListNoPaggingForDistributor?type=" +
							t +
							(this.user.parentId ? "&parentId=" + this.user.parentId : "") +
							"&active=true",
					)
					.then(function(res) {
						this.categoryList = res.body;
					})
					.catch(function() {});
			},
			async getproductBundleList() {
				this.isBusy = true;
				await this.$http
					.get(`/ProductBundle?pageNumber=${this.pageNumber}&pageSize=${this.pageSize}`)
					.then(function(res) {
						this.productBundleList = res.data.data;
						this.pageNumber = res.data.pageNumber;
						this.pageSize = res.data.pageSize;
						this.count = res.data.count;
						this.pageCount = Math.ceil(res.data.count / res.data.pageSize);
						this.hasPrev = res.data.hasPrev;
						this.hasNext = res.data.hasNext;
						this.isBusy = false;
						this.createProductBundle.image = "";
						this.updateProductBundle.image = "";
						this.previewUrl = "";
					})
					.catch(function(e) {
						
					});
			},
			async createMyProductBundle() {
				this.isBusy = true;
				const formData = new FormData();
				formData.append("name", this.createProductBundle.name);
				formData.append("code", this.createProductBundle.code);
				formData.append("Description", this.createProductBundle.description);
				for (let i = 0; i < this.createProductBundle.products.length; i++) {
					formData.append(
						`products[${i}].productId`,
						this.createProductBundle.products[i].productId,
					);
					formData.append(
						`products[${i}].quantity`,
						this.createProductBundle.products[i].quantity,
					);
				}
				// formData.append("additionalCost", this.createProductBundle.additionalCost);
				// formData.append("Unit", this.createProductBundle.productUnit);
				formData.append("RetailPrice", this.createProductBundle.retailPrice);
				formData.append("ReOrderLevel", this.createProductBundle.reOrderLevel);
				formData.append("Active", this.createProductBundle.active);
				formData.append("unit", this.createProductBundle.unit);
				formData.append("categoryId", this.createProductBundle.categoryId);
				formData.append("SaleStatus", this.createProductBundle.availability);
				formData.append("Image", this.createProductBundle.image);
				if (this.validateProductBundle(this.createProductBundle)) {
					await this.$http
						.post("/ProductBundle", formData)
						.then(function(res) {
							if (res.ok) {
								this.$toast.success("Product created successfuly", {
									icon: false,
									rtl: false,
								});
								this.isBusy = false;
								this.cancelUpload();
								this.updateState(this.createProductBundle);
								$("#mod-add-product-bundle").modal("hide");
								$("#mod-confirm").modal("hide");
								this.getproductBundleList();
							}
						})
						.catch(function(err) {
							if (err) {
								this.isBusy = false;
								this.updateState(this.createProductBundle);
							}
						});
				}
			},
			async updateMyProductBundle() {
				this.isBusy = true;
				const formData = new FormData();
				formData.append("name", this.updateProductBundle.name);
				formData.append("code", this.updateProductBundle.code);
				formData.append("Description", this.updateProductBundle.description);
				for (let i = 0; i < this.updateProductBundle.products.length; i++) {
					formData.append(
						`products[${i}].productId`,
						this.updateProductBundle.products[i].productId,
					);
					formData.append(
						`products[${i}].quantity`,
						this.updateProductBundle.products[i].quantity,
					);
				}
				formData.append("RetailPrice", this.updateProductBundle.retailPrice);
				formData.append("ReOrderLevel", this.updateProductBundle.reOrderLevel);
				formData.append("Active", this.updateProductBundle.active);
				formData.append("unit", this.updateProductBundle.unit);
				formData.append("id", this.updateProductBundle.id);
				formData.append("categoryId", this.updateProductBundle.categoryId);
				formData.append("SaleStatus", this.updateProductBundle.availability);
				formData.append("Image", this.updateProductBundle.image);
				await this.$http
					.put(`/ProductBundle/update/${this.updateProductBundle.id}`, formData)
					.then(function(res) {
						if (res.ok) {
							this.$toast.success("Product Updated successfuly", {
								icon: false,
								rtl: false,
							});

							this.isBusy = false;
							this.cancelUpload();
							this.updateState(this.updateProductBundle);
							$("#mod-edit-product-bundle").modal("hide");
							this.getproductBundleList();
						}
					})
					.catch(function(err) {
						if (err) {
							this.isBusy = false;
							this.updateState(this.updateProductBundle);
						}
				});
			},

			validateProductBundle(formType) {
				this.v1 = {
					name: false,
					code: false,
					description: false,
					productUnit: false,
					retailPrice: false,
					reOrderLevel: false,
					unit: false,
					active: false,
				};
				var isValid = true;
				if (!formType.name) {
					this.v1.name = true;
					isValid = false;
					this.isBusy = true;
				}

				if (!formType.code) {
					this.v1.code = true;
					isValid = false;
					this.isBusy = true;
				}

				if (!formType.description) {
					this.v1.description = true;
					isValid = false;
					this.isBusy = true;
				}
				if (!formType.unit) {
					this.v1.unit = true;
					isValid = false;
					this.isBusy = true;
				}

				// if (!formType.additionalCost) {
				// 	this.v1.additionalCost = true;
				// 	isValid = false;
				// 	this.isBusy = true;
				// }

				// if (Number(formType.productUnit) <= 0) {
				// 	this.v1.productUnit = true;
				// 	isValid = false;
				// 	this.isBusy = true;
				// }
				if (!formType.retailPrice) {
					this.v1.retailPrice = true;
					isValid = false;
					this.isBusy = true;
				}

				if (Number(formType.reOrderLevel) < 0) {
					this.v1.reOrderLevel = true;
					isValid = false;
					this.isBusy = true;
				}

				if (!formType.active) {
					this.v1.active = true;
					isValid = false;
					this.isBusy = true;
				}

				return isValid;
			},

			updateState(formType) {
				(formType.name = null),
					(formType.code = null),
					(formType.description = null),
					(formType.products = [{ product: null, quantity: 0 }]),
					(formType.additionalCost = null),
					// (formType.productUnit = 0),
					(formType.categoryId = null),
					(formType.retailPrice = null),
					(formType.reOrderLevel = 0),
					(formType.active = false);
				formType.image = false;
			},

			viewEditMyProductBundle(c) {
				var editValue = {
					code: c.code,
					name: c.name,
					description: c.description,
					// quantity: c.quantity,
					type: c.type,
					retailPrice: c.retailPrice,
					unit: c.unit,
					id: c.id,
					reOrderLevel: c.restockLevel,
					availability: c.saleStatus,
					categoryId: c.categoryId,
					additionalCost: c.categoryId,
					active: c.active,
				};
				(this.previewUrl = c.imageUrl), (this.editProductBundle = editValue);
				this.updateProductBundle = c;
				this.updateProductBundle.categoryId = c.categoryId;
				this.updateProductBundle.id = c.id;
				this.updateProductBundle.availability = c.saleStatus;
				this.previewUrl = c.imageUrl;
				$("#mod-edit-product-bundle").modal("show");
			},

			async confirmDelete() {
				this.isBusy = true;
				await this.$http
					.delete(`/ProductBundle/${this.deleteId}`)
					.then(function(res) {
						if (res.ok) {
							this.$toast.success("Product Deleted successfuly", {
								icon: false,
								rtl: false,
							});

							this.isBusy = false;
							$("#mod-delete").modal("hide");
							this.getproductBundleList();
						}
					})
					.catch(function() {});
			},

			cancelConfirm() {
				this.DeleteId = "";
				$("#mod-confirm-delete").modal("hide");
			},
			showDeleteMsg() {
				$("#mod-delete-msg").modal("show");
			},

			async deleteProduct() {
				this.isBusy = true;
				try {
					const res = await this.$http.delete(`/ProductBundle/${this.DeleteId}`);
					if (res.ok) {
						this.$toast.success("Product deleted successfully", {
							icon: true,
							rtl: false,
						});
						this.getproductBundleList();
						this.cancelConfirm();
					}
				} catch (error) {
					this.cancelConfirm();
					this.showDeleteMsg();
				}
				this.isBusyModal = false;
			},

			gotoPage(page) {
				this.pageNumber = page;
				this.getproductBundleList();
			},
			changeLimit(limit) {
				this.pageSize = limit;
				this.getProducts();
			},
			prev() {
				this.pageNumber -= 1;
				this.getproductBundleList();
			},
			next() {
				this.pageNumber += 1;
				this.getproductBundleList();
			},
			formatDate(date) {
				return moment(date).format("lll");
			},
			formatMoney(val) {
				return helper.formatMoney(val);
			},
			formatNumber(val) {
				return helper.formatNumber(val);
			},
		},
	};
</script>

<style scoped>
	.lg-modal {
		max-width: 600px !important;
		width: 100%;
	}

	.sm-modal {
		max-width: 300px !important;
		width: 100%;
	}
	.error-text {
		font-size: 10px;
	}
	.modal-body {
		max-height: 500px;
		overflow-y: auto;
	}
	.custom-file-input {
		color: transparent;
		opacity: 1 !important;
		margin-left: 10px !important;
		height: fit-content !important;
	}
	.custom-file-input::-webkit-file-upload-button {
		visibility: hidden;
	}
	.custom-file-input::before {
		content: "Upload Image";
		color: #7367f0;
		display: inline-block;
		background-color: #ffffff !important;
		border: 1px solid #7367f0;
		border-radius: 5px;
		padding: 4px 14px;
		outline: none;
		white-space: nowrap;
		-webkit-user-select: none;
		cursor: pointer;
		font-weight: 300;
		font-size: 10pt;
	}
	.custom-file-input:hover::before {
		border-color: #7367f0;
	}
	.custom-file-input:active {
		outline: 0;
	}
	.custom-file-input:active::before {
		background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
	}
	.image-size-text {
		color: Gray;
		font-size: 10px;
		font-weight: 200;
		height: auto !important;
	}
	.image-preview {
		border-radius: 50%;
	}
	.btn-custom {
		padding: 8px 18px !important;
	}
	.btn-nobordered {
		border: none;
	}
</style>
