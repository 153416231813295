<template>
  <div class="w-full pb-2 flex flex-col justify-start items-start gap-[15px]">
    <div class="w-full flex justify-start items-start gap-[11px]">
      <!-- Product Tab Button -->
      <button
        :class="[
          'text-[14px] font-semibold leading-[20px] cursor-pointer focus:outline-none py-[5px]',
          activeTab === 'product'
            ? 'border-b-2 border-[#2463EB] text-[#2463EB]'
            : 'text-[#96a0b5]',
        ]"
        @click="switchTab('product')"
      >
        Product
      </button>

      <!-- Category Tab Button -->
      <button
        :class="[
          'text-[14px] font-semibold leading-[20px] cursor-pointer focus:outline-none py-[5px]',
          activeTab === 'category'
            ? 'border-b-2 border-[#2463EB] text-[#2463EB]'
            : 'text-[#96a0b5]',
        ]"
        @click="switchTab('category')"
      >
        Product categories
      </button>

      <!-- Units Tab Button -->
      <button
        :class="[
          'text-[14px] font-semibold leading-[20px] cursor-pointer focus:outline-none py-[5px]',
          activeTab === 'units'
            ? 'border-b-2 border-[#2463EB] text-[#2463EB]'
            : 'text-[#96a0b5]',
        ]"
        @click="switchTab('units')"
      >
        Product Units
      </button>
    </div>

    <!-- product -->
    <div
      v-if="activeTab === 'product'"
      class="flex xs:flex-col lg:flex-row xs:justify-start lg:justify-between w-full xs:items-start xs:gap-4 lg-gap-2 lg:items-center"
    >
      <p class="text-[#353535] text-[14px] leading-[20px] font-semibold">
        List of all product created/on the system
      </p>
      <div
        class="flex gap-1 md:gap-3 flex-wrap xs:justify-start lg:justify-center xs:items-start lg:items-center"
      >
        <button
          class="border-[1px] border-[#96a0b5] rounded-[8px] text-[#96a0b5] font-normal border-solid text-[12px] sm:text-[14px] border-solid px-[12px] sm:px-[24px] py-[12px] flex items-center gap-[5px] outline-none focus:outline-none"
          @click="viewFilter"
        >
          <span><img src="/assets/icon/sort.svg" alt=""/></span>
          Filters
        </button>
        <button
          v-if="hasPermission('Product_Export')"
          class="border-[1px] bg-[#7367F0] rounded-[8px] text-white font-normal border-solid text-[12px] sm:text-[14px] border-solid px-[12px] sm:px-[24px] py-[12px] flex items-center gap-[5px] outline-none focus:outline-none"
          @click="openConfirmDownload"
        >
          <span><img src="/assets/icon/export.svg" alt=""/></span>
          Export Data
        </button>
        <div v-if="hasPermission('Product_Bulk_Upload')" class="relative">
          <button
            class="border-[1px] bg-[#AF52DE] rounded-[8px] text-white font-normal border-solid text-[12px] sm:text-[14px] border-solid px-[12px] sm:px-[24px] py-[12px] flex items-center gap-[5px] outline-none focus:outline-none"
            @click="toggleDropdown"
          >
            <span><img src="/assets/icon/document-upload.svg" alt=""/></span>
            Bulk Uploads
            <i class="fa-solid fa-chevron-down"></i>
          </button>
          <div
            v-if="showDropdown"
            class="absolute right-0 w-[150px] border border-[#d0d5dd] rounded-b-md shadow-md z-10 bg-[#fcfcfd] p-[4px]"
          >
            <ul>
              <li
                class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer border-b border-[#e5e7eb] py-[12px] px-[16px]"
                @click="openProductUploadModal"
              >
                Product
              </li>
              <li
                class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer py-[12px] px-[16px]"
                @click="openPriceUploadModal"
              >
                Price
              </li>
            </ul>
          </div>
        </div>
        <button
          class="border-[1px] bg-[#2463eb] rounded-[8px] text-white font-normal border-solid text-[12px] sm:text-[14px] border-solid px-[12px] sm:px-[24px] py-[12px] flex items-center gap-[5px] outline-none focus:outline-none"
          v-if="hasPermission('Product_Create')"
          @click="openCreateModal"
        >
          <i class="fa-solid fa-plus"></i> New Product
        </button>
      </div>
    </div>

    <!-- category -->
    <div
      v-if="activeTab === 'category'"
      class="flex xs:flex-col lg:flex-row xs:justify-start lg:justify-between w-full xs:items-start xs:gap-4 lg-gap-2 lg:items-center"
    >
      <p class="text-[#353535] text-[14px] leading-[20px] font-semibold">
        List all Product categories created/on the system
      </p>
      <div
        class="flex gap-3 flex-wrap xs:justify-start lg:justify-center xs:items-start lg:items-center"
      >
        <button
          class="border-[1px] border-[#96a0b5] rounded-[8px] text-[#96a0b5] font-normal border-solid text-[12px] sm:text-[14px] border-solid px-[12px] sm:px-[24px] py-[12px] flex items-center gap-[5px] outline-none focus:outline-none"
          @click="viewFilterCategory"
        >
          <span><img src="/assets/icon/sort.svg" alt=""/></span>
          Filter
        </button>
        <button
          v-if="hasPermission('Product_Category_Create')"
          class="border-[1px] bg-[#2463eb] rounded-[8px] text-white font-normal border-solid text-[12px] sm:text-[14px] border-solid px-[12px] sm:px-[24px] py-[12px] flex items-center gap-[5px] outline-none focus:outline-none"
          @click="viewAdd"
        >
          <i class="fa-solid fa-plus"></i> New Items
        </button>
      </div>
    </div>

    <!-- units -->
    <div
      v-if="activeTab === 'units'"
      class="flex xs:flex-col lg:flex-row xs:justify-start lg:justify-between w-full xs:items-start xs:gap-4 lg-gap-2 lg:items-center"
    >
      <p class="text-[#353535] text-[14px] leading-[20px] font-semibold">
        Product Unit
      </p>
      <div
        class="flex gap-3 flex-wrap xs:justify-start lg:justify-center xs:items-start lg:items-center"
      >
        <button
          class="border-[1px] border-[#96a0b5] rounded-[8px] text-[#96a0b5] font-normal border-solid text-[12px] sm:text-[14px] border-solid px-[12px] sm:px-[24px] py-[12px] flex items-center gap-[5px] outline-none focus:outline-none"
          @click="viewUnitFilter"
        >
          <span><img src="/assets/icon/sort.svg" alt=""/></span>
          Filter
        </button>
        <button
          v-if="hasPermission('Product_Unit_Create')"
          class="border-[1px] bg-[#2463eb] rounded-[8px] text-white font-normal border-solid text-[12px] sm:text-[14px] border-solid px-[12px] sm:px-[24px] py-[12px] flex items-center gap-[5px] outline-none focus:outline-none"
          @click="viewProductUnit"
        >
          <i class="fa-solid fa-plus"></i> New Items
        </button>
      </div>
    </div>

    <ProductBulkUpload
      v-if="isProductUploadModalVisible"
      @close="isProductUploadModalVisible = false"
    />
    <PriceBulkUpload
      v-if="isPriceUploadModalVisible"
      @close="isPriceUploadModalVisible = false"
    />
  </div>
</template>
<script>
import ProductBulkUpload from "./ProductBulkUpload.vue";
import PriceBulkUpload from "./PriceBulkUpload.vue";
export default {
  components: {
    ProductBulkUpload,
    PriceBulkUpload,
  },
  props: {
    activeTab: {
      type: String,
      default: "product",
    },
    hasPermission: Function,
  },
  data() {
    return {
      showDropdown: null,
      isProductUploadModalVisible: false,
      isPriceUploadModalVisible: false,
    };
  },
  methods: {
    switchTab(tab) {
      this.$emit("tabSwitched", tab);
    },
    openCreateModal() {
      this.$emit("openCreateModal");
    },
    openConfirmDownload() {
      this.$emit("openConfirmDownload");
    },
    viewFilter() {
      this.$emit("viewFilter");
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    openProductUploadModal() {
      this.isProductUploadModalVisible = true;
      this.showDropdown = false;
    },
    openPriceUploadModal() {
      this.isPriceUploadModalVisible = true;
      this.showDropdown = false;
    },
    viewAdd() {
      $("#mod-add-category").modal("show");
    },
    viewFilterCategory() {
      $("#mod-filter-category").modal("show");
    },
    viewProductUnit() {
      $("#mod-add-units").modal("show");
    },
    viewUnitFilter() {
      $("#mod-filter-units").modal("show");
    },
  },
};
</script>
