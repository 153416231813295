<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
           <div class="col-12 col-md-6">
            <button v-if="isSelectAll" class="btn btn-warning btn-rounded btn-space" @click="deSelectAll">
						  Unselect All
					  </button>
            <button v-else class="btn btn-rounded btn-space" style="background-color: #134d84; color: white" @click="selectAll">
						  Select All
					  </button>
          	<button id="markAsRead" @click="markAsRead" :disabled="selectedIds < 1" class="btn btn-primary btn-rounded btn-space ml-2">
						  Mark As Read
					  </button>
          </div>
          <div>
            <button class="btn btn-success btn-rounded btn-space text-white" @click="openFilter">
              <i class="feather icon-filter text-white"></i> Filter
            </button>
          </div>
        </div>
        <div class="card-body d-flex flex-column align-items-center">
          <div class="w-100">
            <NotificationCard
              v-for="n in altNotifications"
              :key="n.id"
              :notification="n"
              @onDeleteComplete="getNotifications"
              @onCardClicked="submitRead"
              @checkMessage="selectMessage"
            />
          </div>
    
        </div>
          <div class="p-1">
            <Pagination
                :limit="pageSize"
                :count="pageCount"
                :page="pageNumber"
                :itemsCount="count"
                @changePage="gotoPage"
                @changeLimit="changeLimit"
            />
          </div>
         
      </div>
    </div>

     <div class="modal fade colored-header colored-header-primary" id="mod-filter" role="dialog">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                  <div>
                      <h4 class="modal-title"><b>Filter</b></h4>
                  </div>
                  <button
                      class="close md-close"
                      type="button"
                      data-dismiss="modal"
                      aria-hidden="true"
                  >
                      <span class="mdi mdi-close"></span>
                  </button>
              </div>
              <div class="modal-body">
                <div class="form-group row">
                  <label class="col-12">Read Status: </label>
                  <div class="col-12">
                       <select
                        class="form-control"
                        placeholder="Select notification type"
                        v-model="filter.status"
                      >
                        <option value="true">Read</option>
                        <option value="false">Unread</option>
                      </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-12">Type: </label>
                  <div class="col-12">
                       <select
                        class="form-control"
                        placeholder="Select notification type"
                        v-model="filter.type"
                      >
                        <option  v-for="item of notificationTypes" :key="item" :value="item">{{item}}</option>
                      </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-12">Start Date:</label>
                  <div class="col-12">
                    <input
                        :class="['form-control']"
                        type="date"
                        placeholder="Select a date"
                        v-model="filter.startDate"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-12">End Date:</label>
                  <div class="col-12">
                    <input
                        :class="['form-control']"
                        type="date"
                        placeholder="Select a date"
                        v-model="filter.endDate"
                    />
                  </div>
                </div>

              </div>

              <div class="modal-footer">
                <button
                  style="width: 100px;"
                  class="btn btn-text btn-space"
                  type="button"
                  data-dismiss="modal"
                  @click="resetFilter"
                >
                  <span class="text-danger"> Reset </span>
                </button>

                <button
                  style="width: 100px;"
                  class="btn btn-primary"
                  type="button"
                  data-dismiss="modal"
                  :disabled="isBusy"
                  @click="getNotifications"
                >
                  <span v-if="!isBusy">Filter</span>
                  <span v-else>Busy...</span>
                </button>

              </div>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import moment from "moment";
import NotificationCard from "../components/NotificationCard.vue";
import Pagination from "../components/Pagination.vue";

export default {
  components: { NotificationCard, Pagination, },

  data() {
    return {
      search: "",
      isBusy: false,
      notifications: [],
      filteredNotifications: [],
      altNotifications: [],
      notificationTypes: [],
      isSelectAll: false,
      selectedIds: [],
      filter: {
        status: "",
        type: "",
        startDate: "",
        endDate: ""
      },
      status: "",
      pageSize: 10,
      pageNumber: 0,
      count: 0,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  mounted() {
    this.getNotifications();
    this.getNotificationTypes();
  },

  methods: {
    async getNotifications() {
      this.altNotifications = [];
      this.selectedIds = [];
      try {
        this.isBusy = true;
        const response = await this.$http.get(
          `/Notification?type=${this.filter.type}&Read=${this.filter.status}&StartDate=${this.filter.startDate}&EndDate=${this.filter.endDate}&PageNumber=${this.pageNumber}&PageSize=${this.pageSize}`
        );

        if (response.ok) {
          this.filteredNotifications = this.notifications = response.body.data;
          this.formatNotificationArray(response.body.data);
          this.pageNumber = response.body.pageNumber;
          this.pageSize = response.body.pageSize;
          this.count = response.body.count;
          this.pageCount = Math.ceil(response.body.count / response.body.pageSize);
          this.hasPrev = response.body.hasPrev;
          this.hasNext = response.body.hasNext;
        }

        this.isBusy = false;
        this.closeFilter();
      } catch (error) {
        this.isBusy = false;
      }
    },

    async getNotificationTypes() {
      try {
        this.isBusy = true;
        const response = await this.$http.get(`/Notification/types`);
        if (response.ok) {
          this.notificationTypes = response.body;
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    async submitRead(id) {
      let body = {
        ids: [id].toString()
      }
      const response = await this.$http.put("/notification/read", body);
      if (response.body) {
        this.getNotifications();
        this.getUnreadCount();
      }
    },

    async getUnreadCount() {
      const response = await this.$http.get("/Notification/count?isRead=false");
      this.$store.commit("setNotificationCount", response.body);
    },

    formatDate(date) {
      if (date) return moment(date).format("lll");
      else return "";
    },

    prev() {
      this.pageNumber -= 1;
      this.getNotifications();
    },
    next() {
      this.pageNumber += 1;
      this.getNotifications();
    },

    gotoPage(pageNumber) {
      this.pageNumber = pageNumber;
      this.getNotifications();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getNotifications();
    },

    selectMessage(id){
      if(this.selectedIds.includes(id)){
       let filterSelected = this.selectedIds.filter(item => item != id);
       this.selectedIds = filterSelected;
      }else{
        this.selectedIds.push(id);
      }
    },

    async markAsRead(){
      this.isBusy = true;
      let body = {
        ids: this.selectedIds.toString()
      }
      if(this.selectedIds.length < 1){
        return this.$toast.warning("You must select notification.", {
              icon: true,
              rtl: false,
            });
      }
      await this.$http
          .put("/notification/read",body)
          .then(function (res) {
            this.altNotifications = [];
            this.isSelectAll = false;
            this.getNotifications();
            this.getUnreadCount();
          })
          .catch(function () {
            this.isBusy = false;
          });
    },

    formatNotificationArray(arr){
      for(var i = 0; i < arr.length; i++){
        let obj = {
          isChecked: false,
          id: arr[i].id,
          isRead: arr[i].isRead,
          date: arr[i].date,
          title: arr[i].title,
          statement: arr[i].statement,
          focalPoint: arr[i].focalPoint,
          secondary: arr[i].secondary,
          ctaStatus: arr[i].ctaStatus,
          ctaLabel: arr[i].ctaLabel,
          url: arr[i].url,
          recipientId: arr[i].recipientId
        }
        this.altNotifications.push(obj);
      }
    },

    selectAll(){
      for(var i = 0; i < this.altNotifications.length; i++){
        this.altNotifications[i].isChecked = true;
        this.selectedIds.push(this.altNotifications[i].id);
      }
      this.isSelectAll = true;
    },

    deSelectAll(){
      for(var i = 0; i < this.altNotifications.length; i++){
        this.altNotifications[i].isChecked = false;
      }
      this.isSelectAll = false;
      this.selectedIds = [];
    },

    openFilter(){
      $("#mod-filter").modal("show");
    },

    closeFilter(){
      $("#mod-filter").modal("hide");
    },

    resetFilter(){
      this.filter = {
        status: "",
        type: "",
        startDate: "",
        endDate: ""
      }
      this.getNotifications();
    },
  },
};
</script>

<style>
.card-body {
  margin-top: 20px;
  background-color: #f8f8f8;
}
</style>
