<template>
    <div class="row">
        <div class="col-lg-12">
            <div
                :class="[
                'card',
                'card-border-color',
                'card-border-color-primary',
                ' card-table',
                'be-loading',
                { 'be-loading-active': isBusy },
                ]"
            >
                <br />
                <br />
                <br />

                <div class="card-header">
                <div class="col-lg-3">
                    <button
                    class="btn btn-flat-dark"
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    @click="viewFilter"
                    >
                    <span class="feather icon-filter"></span> &nbsp; Format Results
                    </button>
                </div>

                <div class="col-lg-6 text-center">
                    <p class="text-center">
                    <span>
                        <h2>Stock Purchase (Consolidated) Report</h2>
                        <br />
                        &nbsp;</span
                    >

                    <span
                        v-if="
                        this.filter.dateStart == '' || this.filter.dateStart == null
                        "
                    >
                        <small> <b>As at:</b> {{ this.filter.dateEnd }}</small>
                    </span>
                    <span
                        v-if="
                        this.filter.dateStart != '' &&
                        this.filter.dateStart != null &&
                        this.filter.dateEnd != '' &&
                        this.filter.dateEnd != null
                        "
                    >
                        <span
                        v-if="
                            this.filter.dateStart != '' && this.filter.dateStart != null
                        "
                        ><small
                            ><b>From:</b> {{ this.filter.dateStart }}
                        </small></span
                        >
                        &nbsp; <small> <b>To:</b> {{ this.filter.dateEnd }}</small>
                    </span>
                    </p>
                </div>

                <div class="col-lg-3" style="text-align: right">
                    <div class="btn-group">
                    <div class="dropdown">
                        <button
                        class="btn btn-flat-primary dropdown-toggle mr-1 mb-1"
                        type="button"
                        id="dropdownMenuButton100"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        >
                        <span class="feather icon-download"></span> &nbsp; Export
                        Report
                        </button>
                        <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton100"
                        >
                        <a class="dropdown-item" @click="csvExport(csvData)" href="#">
                            Current View (CSV)</a
                        >
                        <a class="dropdown-item" @click="excelData(exportItems)" href="#">
                            Current View (Excel)</a >
                        <a
                            class="dropdown-item"
                            @click="csvExport(csvDataAll)"
                            href="#"
                            >All Records (CSV)</a
                        > <a
                            class="dropdown-item"
                            @click="excelData(exportItemsAll)"
                            href="#"
                            >All Records (Excel)</a >
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <br />
                <br />
                <div class="col-sm-3">
                <small>
                    Show
                    <select
                    @change="
                        pageSize = Number($event.target.value);
                        getStockPurchaseReportConsolidated();
                    "
                    class="select2 form-control col-sm-2"
                    style="display: inline"
                    >
                    <option
                        v-for="item of [10, 25, 50, 100]"
                        :key="item"
                        :value="item"
                        :selected="page == item"
                    >
                        {{ item }}
                    </option>
                    </select>
                    entries
                </small>
                </div>
                <br />
                <div class="">
                    <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                        <div class="row be-datatable-body">
                            <div class="col-sm-12 table-responsive">
                                <table class="table table-striped dataex-html5-selectors">
                                    <thead>
                                        <tr>
                                            <th style="width:10%;">Product Code</th>
                                            <th style="width:10%;">Product Name</th>
                                            <th style="width:10%;">Product Unit</th>
                                            <th class="text-right" style="width:15%;">Weighted Avg Cost (₦)</th>
                                            <th class="text-center" style="width:15%;">Total Quantity</th>
                                            <th class="text-right" style="width:15%;">Total Cost Of Purchase (₦)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in ReportItems" :key="item.rowNum">
                                            <td>{{ item.productCode }}</td>
                                            <td>{{ item.productName }}</td>
                                            <td>{{ item.productUnit }}</td>
                                            <td class="text-right">{{ formatMoney(item.weightedAvgCost) }}</td>
                                            <td class="text-center">{{ formatNumber(item.totalQuantity) }}</td>
                                            <td class="text-right">{{ formatMoney(item.totalCostOfPurchase) }}</td>
                                        </tr>
                                        <tr v-if="!ReportItems.length">
                                            <td colspan="7">
                                                <div class="text-center">No matching records</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                         <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div
                  class="dataTables_info"
                  v-if="ReportItems != null && ReportItems.length > 0"
                >
                  <small>
                    Showing
                    {{ page * pageSize + 1 }}
                    -
                    {{ page * pageSize + ReportItems.length }}
                    of
                    {{ count }} record{{ count != 1 ? "s" : "" }}
                  </small>
                </div>
              </div>

              <div class="col-sm-4">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination">
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasPrev },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="prev"
                        >Previous</a
                      >
                    </li>
                    <li :class="['paginate_button', 'page-item', 'previous']">
                      <a class="page-link"
                        >Page {{ page + 1 }} of {{ maxPage }}</a
                      >
                    </li>
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasNext },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="next"
                        >Next</a
                      >
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-sm-3" style="text-align: right">
                <div
                  class="dataTables_info"
                  v-if="ReportItems != null && ReportItems.length > 0"
                >
                  <small>
                    Jump to page:
                    <select
                      @change="
                        page = Number($event.target.value);
                        getStockPurchaseReportConsolidated();
                      "
                      class="form-control col-sm-2"
                      style="display: inline"
                    >
                      <option
                        v-for="item of pageArray"
                        :key="item"
                        :value="item"
                        :selected="page == item"
                      >
                        {{ item + 1 }}
                      </option>
                    </select>
                  </small>
                </div>
              </div>
            </div>
                    </div>
                </div>
                <div class="be-spinner">
                    <svg width="40px" height="40px" viewBox="0 0 66 66">
                        <circle class="circle"
                                fill="none"
                                stroke-width="4"
                                stroke-linecap="round"
                                cx="33"
                                cy="33"
                                r="30"></circle>
                    </svg>
                </div>
            </div>
        </div>

        <div
            class="modal fade colored-header colored-header-primary"
            id="mod-filter"
            role="dialog"
            >
            <ReportFilterModal
                @onsearch="page = 0"
                v-model="filter"
                extraDataType="TEXT"
                extraDataPlaceholder="Filter by product name..."
                :searchFn="getStockPurchaseReportConsolidated"
            ></ReportFilterModal>
        </div>
    </div>
</template>

<script>
    /* eslint-disable */

    import moment from "moment";
    import { helper } from "@/helper";
    import Select2 from "@/components/Select2";
    import ReportFilterModal from "@/components/Common/ReportFilterModal";
    import {exportExcel } from "@/excel";

    export default {
        name: "StockPurchaseReportConsolidatedReport",
        components: {
            Select2,
            ReportFilterModal,
        },
        created() {
            this.getStockPurchaseReportConsolidated();
            this.getStockPurchaseReportConsolidatedExportItemsAll();
        },
        data() {
            return {
                filter: {
                    qry: "",
                    dateStart: moment()
                        .subtract(1, "years")
                        .format("YYYY-MM-DD"),
                    dateEnd: moment().format("YYYY-MM-DD")
                },
                isBusy: false,
                isBusyModal: false,
                ReportItems: [],
                ExportReportItemsAll: [],
                count: 0,
                page: 0,
                pageSize: 10,
                pageSizeExport: 999999999,
                hasNext: false,
                hasPrev: false,
                maxPage: 0,
                pageArray: [],
                options: {
                    format: "YYYY-MM-DD",
                    icons: {
                        time: "mdi mdi-time",
                        date: "mdi mdi-calendar",
                        up: "mdi mdi-chevron-up",
                        down: "mdi mdi-chevron-down",
                        previous: "mdi mdi-chevron-left",
                        next: "mdi mdi-chevron-right",
                        today: "mdi mdi-screenshot",
                        clear: "mdi mdi-trash",
                        close: "mdi mdi-remove"
                    }
                },
                local: {
                    dow: 0, // Sunday is the first day of the week
                    hourTip: "Select Hour", // tip of select hour
                    minuteTip: "Select Minute", // tip of select minute
                    secondTip: "Select Second", // tip of select second
                    yearSuffix: "", // suffix of head year
                    monthsHead: "January_February_March_April_May_June_July_August_September_October_November_December".split(
                        "_"
                    ), // months of head
                    months: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"), // months of panel
                    weeks: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"), // weeks
                    cancelTip: "cancel",
                    submitTip: "confirm"
                }
            };
        },
        computed: {
            user: function () {
                return this.$store.state.currentUser;
            },
            csvData() {
                return this.exportItems.map(item => ({
                    ...item
                }));
            },
            exportItems() {
                return this.ReportItems.map(function(item) {
                    return {
                        "Product Code": item.productCode,
                        "Product Name": item.productName,
                        "Product Unit": item.productUnit,
                        "Weighted Avg Cost (NGN)": item.weightedAvgCost,
                        "Total Quantity": item.totalQuantity,
                        "Total Cost Of Purchase (NGN)": item.totalCostOfPurchase
                    }
                });
            }, 
            csvDataAll() {
                return this.exportItemsAll.map(item => ({
                    ...item
                }));
            },
            exportItemsAll() {
                this.getStockPurchaseReportConsolidatedExportItemsAll();
                return this.ExportReportItemsAll.map(function (item) {
                    return {
                        "Product Code": item.productCode,
                        "Product Name": item.productName,
                        "Product Unit": item.productUnit,
                        "Weighted Avg Cost (NGN)": item.weightedAvgCost,
                        "Total Quantity": item.totalQuantity,
                        "Total Cost Of Purchase (NGN)": item.totalCostOfPurchase
                    }
                });
            }
        },
        methods: {
            reset() {
                this.filter = {
                    qry: ""
                };
            },
            viewExport() {

            },
            viewFilter() {
                $("#mod-filter").modal("show");
            },
            getStockPurchaseReportConsolidated() {
                this.isBusy = true;

                this.$http
                    .get(
                        "/Reports/getStockPurchaseReportConsolidatedReport?page=" +
                        this.page +
                        "&pageSize=" +
                        this.pageSize +
                        (this.filter.qry != ""
                            ? "&query=" + this.filter.qry
                            : "") +
                        (this.filter.dateStart != ""
                            ? "&startDate=" +
                            moment(this.filter.dateStart).format("YYYY-MM-DD 00:00:00")
                            : "") +
                        (this.filter.dateEnd != ""
                            ? "&endDate=" +
                            moment(this.filter.dateEnd).format("YYYY-MM-DD 23:59:59")
                            : "") +
                        (this.user.parentId ? "&parentId=" + this.user.parentId : "")
                    )
                    .then(function (res) {
                        this.ReportItems = res.body.data;
                        this.page = res.body.page;
                        this.pageSize = res.body.pageSize;
                        this.count = res.body.count;
                        this.hasPrev = res.body.hasPrev;
                        this.hasNext = res.body.hasNext;
                        this.maxPage = Math.ceil(this.count / this.pageSize);
                        this.pageArray = [...Array(this.maxPage).keys()];

                        $("#mod-filter").modal("hide");
                        this.isBusy = false;
                    })
                    .catch(function () {
                        $("#mod-filter").modal("hide");
                        this.isBusy = false;
                    });
            },
            getStockPurchaseReportConsolidatedExportItemsAll() {
                this.isBusy = true;

                this.$http
                    .get(
                        "/Reports/getStockPurchaseReportConsolidatedReport?page=" +
                        this.page +
                        "&pageSize=" +
                        this.pageSizeExport +
                        (this.filter.qry != ""
                            ? "&query=" + this.filter.qry
                            : "") +
                        (this.filter.dateStart != ""
                            ? "&startDate=" +
                            moment(this.filter.dateStart).format("YYYY-MM-DD 00:00:00")
                            : "") +
                        (this.filter.dateEnd != ""
                            ? "&endDate=" +
                            moment(this.filter.dateEnd).format("YYYY-MM-DD 23:59:59")
                            : "") +
                        (this.user.parentId ? "&parentId=" + this.user.parentId : "")
                    )
                    .then(function (res) {
                        this.ExportReportItemsAll = res.body.data;

                        $("#mod-filter").modal("hide");
                        this.isBusy = false;
                    })
                    .catch(function () {
                        $("#mod-filter").modal("hide");
                        this.isBusy = false;
                    });
            },
            formatDate(date) {
                return moment(date).format("lll");
            },
            formatMoney(val) {
                return helper.formatMoney(val);
            },
            formatNumber(val) {
                return helper.formatNumber(val);
            },
            prev() {
                this.page -= 1;
                this.getStockPurchaseReportConsolidated();
            },
            next() {
                this.page += 1;
                 this.getStockPurchaseReportConsolidated();
            },
            csvExport(arrData) {
                let csvContent = "data:text/csv;charset=utf-8,";
                csvContent += [
                    Object.keys(arrData[0]).join(","),
                    ...arrData.map(item => Object.values(item).join(","))
                ]
                    .join("\n")
                    .replace(/(^\[)|(\]$)/gm, "");

                const data = encodeURI(csvContent);
                const link = document.createElement("a");
                link.setAttribute("href", data);
                link.setAttribute("download", "stock_purchase_consolidated_report.csv");
                link.click();
            },
            excelData(exportData) {
            const exportAble = exportData;
            const options = {
                headers: Object.keys(exportAble[0])
            };
            const numericHeaders = [
                "Weighted Avg Cost (NGN)",
                "Total Cost Of Purchase (NGN)"
            ];
            var titleName = "Stock Purchase (Consolidated) Report_";
            var titleFullname =
                this.filter.dateStart === "" || this.filter.dateStart == null
                ? titleName + "As At_" + this.filter.dateEnd
                : titleName + this.filter.dateStart + "_to_" + this.filter.dateEnd;
            const excelData = {
                title: titleFullname,
                headers: options.headers,
                data: exportAble,
            };
            const businessName = this.$store.getters.getCurrentUser.parent.name;
            const businessAddress = this.$store.getters.getCurrentUser.parent.address;
            exportExcel(excelData, businessName, businessAddress, numericHeaders);
            }
        }
    };
</script>