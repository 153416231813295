<template>
  <li class="nav-item dropdown dropdown-notification">
    <a
      role="button"
      href="#"
      target="_self"
      data-toggle="dropdown"
      class="nav-link dropdown-toggle"
      aria-expanded="false"
      id="__BVID__105__BV_toggle_"
    >
      <span class="feather-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21px"
          height="21px"
          viewBox="0 0 24 24"
          fill="#2463eb"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="text-body feather text-[#2463eb] feather-bell"
        >
          <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
          <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
        </svg>
        <span class="badge badge-up badge-pill bg-danger">{{
          notificationCount
        }}</span>
      </span>
    </a>
    <ul
      tabindex="-1"
      class="dropdown-menu dropdown-menu-media dropdown-menu-right notif-dropdown bg-[#fcfcfd] max-w-[450px] w-full"
      aria-labelledby="__BVID__105__BV_toggle_"
    >
      <li>
        <div class="dropdown-header d-flex">
          <h4
            class="font-bold text-[16px] leading-[24px] capitalize text-[#353535] pb-[16px]"
          >
            Notifications
          </h4>
          <!-- <button class="btn btn-text btn-sm" @click.prevent="markAllAsRead()"><span class="text-primary font-weight-bold">Mark all as read</span></button> -->
        </div>
        <!-- <hr style="background: #ccc" /> -->
      </li>
      <li class="px-1">
        <div v-for="notification in notifications" :key="notification.id">
          <div class="row">
            <div class="col-12 col-sm-9">
              <div
                class="notif-hover d-inline"
                @click="readNotification(notification.id)"
              >
                <p class="title-text font-weight-bold">
                  {{ notification.title }}
                </p>
                <p class="notif-body" v-html="returnSpan(notification)" />
              </div>
              <button
                v-if="notification.ctaStatus === 'Available'"
                class="btn btn-sm btn-primary"
                @click.prevent="goToUrl(notification.url)"
              >
                {{ notification.ctaLabel }}
              </button>
            </div>
            <div class="col-12 col-sm-3">
              <div class="notif-dot"></div>
              <p>{{ days(notification) }}</p>
            </div>
          </div>
          <hr />
        </div>

        <div v-if="notifications.length <= 0" class="media">
          <div class="media-body d-flex justify-content-center pb-[16px]">
            <span>
              <i class="mdi mdi-email-open" />
              You're up to date.</span
            >
          </div>
        </div>
        <div class="media border-t pt-[10px]">
          <div class="media-body d-flex justify-content-center">
            <router-link to="/notifications" class="btn btn-text mb-1"
              ><span class="text-[#2463EB] text-[14px] font-normal"
                >View All Notifications</span
              ></router-link
            >
          </div>
        </div>
      </li>
    </ul>
  </li>
</template>

<script>
import moment from "moment";
import { HubConnectionBuilder, LogLevel } from "@aspnet/signalr";

const url = process.env.VUE_APP_BASE_API_URL;

export default {
  name: "notification",
  props: ["parentId"],
  data() {
    return {
      notifications: [],
    };
  },

  watch: {
    parentId: "getNotifications",
  },

  mounted: function() {
    this.getUnreadCount();
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
    notificationCount: function() {
      return this.$store.state.notificationCount;
    },
  },

  updated() {
    if (this.$auth.getToken()) {
      let connection = new HubConnectionBuilder()
        .withUrl(`${url}/hubs/notifications`, {
          accessTokenFactory: () => this.$auth.getToken(),
        })
        .configureLogging(LogLevel.None)
        .build();

      connection.on("displayNotification", (notification) => {
        this.$toast.info(notification.title, {
          icon: true,
          rtl: false,
        });
        this.notifications.unshift(notification);
      });
      Object.defineProperty(WebSocket, "OPEN", { value: 1 });
      connection.start();
    }
  },

  methods: {
    returnSpan(notif) {
      let secondaryArr = notif.secondary ? notif.secondary.split("*") : [];

      let newStatementRep = "";

      newStatementRep = notif.statement.replace(
        "[FocalPoint]",
        `<span class='font-weight-bold text-primary'> ${notif.focalPoint} </span>`
      );

      for (let i = 0; i < secondaryArr.length; i++) {
        newStatementRep = newStatementRep.replace(
          `[${i}]`,
          `<span class='font-weight-bold'> ${secondaryArr[i]} </span>`
        );
      }
      return `<span> ${newStatementRep} </span>`;
    },

    goToUrl(url) {
      if (url !== "") {
        this.$router.push(`${url}`);
      }
    },

    days(notif) {
      const days = moment(new Date()).diff(notif.date, "days");
      return days > 0 ? `${days} day${days > 1 ? "s" : ""} ago` : "Today";
    },

    async getNotifications() {
      const response = await this.$http.get(
        "/Notification?read=false&pageSize=4"
      );
      this.notifications = response.body.data;
    },

    async getUnreadCount() {
      const response = await this.$http.get("/Notification/count?isRead=false");
      this.$store.commit("setNotificationCount", response.body);
    },

    async submitRead(id) {
      let body = {
        ids: [id].toString(),
      };
      const response = await this.$http.put("/Notification/read", body);
      if (response.body) {
        this.getNotifications();
        this.getUnreadCount();
      }
    },

    async markAllAsRead() {
      let selectedIds = [];
      for (var i = 0; i < this.notifications.length; i++) {
        selectedIds.push(this.notifications[i].id);
      }

      let body = {
        ids: selectedIds.toString(),
      };

      await this.$http
        .put("/notification/read", body)
        .then(function(res) {
          this.getNotifications();
          this.getUnreadCount();
        })
        .catch(function() {});
    },

    readNotification(id) {
      this.submitRead(id);
      const notificationIndex = this.notifications.findIndex(
        (x) => x.id === id
      );
      this.notifications[notificationIndex].read = true;
    },

    formatDate(date) {
      if (date) return moment(date).format("lll");
      else return "";
    },
  },
};
</script>

<style scoped>
.notif-dropdown {
  height: auto !important;
  min-width: 350px;
}

.dropdown .dropdown-menu.dropdown-menu-right::before {
  right: 5.8rem;
  left: auto;
}

.notif-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: greenyellow;
}

.title-text {
  font-size: 15px;
  margin-bottom: 0px !important;
}

.notif-body {
  font-size: 12px;
  margin-bottom: 5px !important;
}

@media only screen and (min-width: 768px) {
  .notif-dropdown {
    height: auto !important;
    min-width: 450px;
    position: absolute !important;
    right: -200px !important;
  }

  .dropdown .dropdown-menu.dropdown-menu-right::before {
    right: 14.6rem;
    left: auto;
  }
}
</style>
