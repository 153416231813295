<template>
	<div class="row">
		<div class="col-lg-12">
			<div
				:class="[
					'card',
					'card-border-color',
					'card-border-color-primary',
					' card-table',
					'be-loading',
					{ 'be-loading-active': isBusy },
				]"
			>
				<div class="card-header">
					<button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
						<i class="feather icon-filter text-white"></i> Filter
					</button>

					<router-link
						class="btn btn-space btn-primary btn-rounded float-right"
						to="/sales-user-distributors"
					>
						<span class="icon icon-left mdi mdi-arrow-left text-white"></span>
						Back
					</router-link>
				</div>
				<div class="card-body">
					<div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
						<div class="row be-datatable-body">
							<div class="col-sm-12 table-responsive">
								<table
									class="
                    table table-fw-widget
                    dataTable
                    no-footer
                    table-striped table-hover-animation
                  "
								>
									<thead>
										<tr>
											<th style="width: 20%">Name</th>
											<th style="width: 10%">Status</th>
											<th style="width: 15%">Category</th>
											<th style="width: 15%">Inventory</th>
											<th style="width: 10%">Minimum Stock Level</th>
											<th style="width: 15%">Created</th>
											<th style="width: 15%"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="p in Products" :key="p.id">
											<td>
												<div class="d-flex align-items-center">
													<img
														v-if="p.imageUrl"
														class="image-preview mr-1"
														:src="p.imageUrl"
														alt="logo"
														width="40"
														height="40"
													/>
													<img
														v-else
														class="image-preview mr-1"
														src="/assets/img/avatar.svg"
														alt="logo"
														width="40"
													/>
													<div>
														{{ p.name }} ({{ p.code }})
														<br />
														<b>
															{{
																`&#8358;${formatMoney(
																	p.retailPrice,
																)}`
															}}
															/
															{{ p.unit }}
														</b>
													</div>
												</div>
											</td>
											<!-- <td>
												{{ p.name }} ({{ p.code }})
												<br />
												<b>
													{{ `&#8358;${formatMoney(p.retailPrice)}` }} /
													{{ p.unit }}
												</b>
											</td> -->
											<td>
												<span
													:class="[
														'badge',
														' badge-pill',
														{ 'badge-success': p.active },
														{ 'badge-danger': !p.active },
													]"
												>
													{{ p.active ? "Active" : "Inactive" }}
												</span>
											</td>
											<td>
												{{ p.category.name }} /
												{{ p.name }}
											</td>
											<td>
												{{
													p.type == "Infinite"
														? p.type
														: formatNumber(p.quantity)
												}}
											</td>
											<td>{{ p.minimumStockLevel }}</td>
											<td>{{ formatDate(p.created) }}</td>
											<td>
												<div
													class="text-right"
													v-if="hasPermission('Product_Update')"
												>
													<button
														v-if="p.parentId == user.parentId"
														class="btn btn-success btn-sm btn-rounded btn-space"
														@click.prevent="viewEdit(p)"
													>
														<i class="feather icon-edit"></i> &nbsp;Edit
													</button>
												</div>
											</td>
										</tr>
										<tr v-if="!Products.length">
											<td colspan="7">
												<div class="text-center" style="padding-top: 50px">
													<span
														style="font-size: 4.615rem"
														class="mdi mdi-tag-multiple"
													></span>
												</div>
												<h3
													class="text-center"
													style="padding-bottom: 100px"
												>
													You don't have any products.
												</h3>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="row be-datatable-footer">
							<div class="col-sm-5">
								<div class="dataTables_info">
									{{ count }} product{{ count != 1 ? "s" : "" }}
								</div>
							</div>
							<div class="col-sm-7">
								<div class="dataTables_paginate paging_simple_numbers">
									<ul class="pagination">
										<li
											:class="[
												'paginate_button',
												'page-item',
												'previous',
												{ disabled: !hasPrev },
											]"
										>
											<a href="#" class="page-link" @click.prevent="prev"
												>Previous</a
											>
										</li>
										<li
											:class="[
												'paginate_button',
												'page-item',
												'previous',
												{ disabled: !hasNext },
											]"
										>
											<a href="#" class="page-link" @click.prevent="next"
												>Next</a
											>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="be-spinner">
					<svg width="40px" height="40px" viewBox="0 0 66 66">
						<circle
							class="circle"
							fill="none"
							stroke-width="4"
							stroke-linecap="round"
							cx="33"
							cy="33"
							r="30"
						></circle>
					</svg>
				</div>
			</div>
		</div>

		<div class="modal fade colored-header colored-header-primary" id="mod-filter" role="dialog">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><strong>Filter</strong></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Status</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="select2 form-control"
									placeholder="Select an option"
									v-model="filter.status"
								>
									<option value="0">All Products</option>
									<option value="True">Active</option>
									<option value="False">Disabled</option>
								</select>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Category</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="filter.categoryId"
								>
									<option value="0">All Categories</option>
									<option v-for="cat in categories" :key="cat.id" :value="cat.id">
										{{ cat.name }}
									</option>
								</select>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Other filters</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="text"
									placeholder="Filter by code or name..."
									v-model="filter.qry"
								/>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary md-close"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 100px"
							class="btn btn-secondary md-close"
							type="button"
							@click="reset"
						>
							Reset
						</button>
						<button
							style="width: 100px"
							class="btn btn-primary md-close"
							type="button"
							@click="getProducts"
						>
							Search
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	/* eslint-disable */

	import moment from "moment";
	import { helper } from "@/helper";
	import Select2 from "@/components/Select2";
	import SystemPermissions from "@/permissions";

	export default {
		mixins: [SystemPermissions.Mixin],
		name: "settingsProducts",
		components: {
			Select2,
		},

		created() {
			this.getGroup();
			this.getProducts();
			this.getTypes();
			this.getCategories();
			this.getUnits();

			this.originalProducts = this.Products;

			console.log(this.Products);
		},

		watch: {
			"createProduct.type": function(newType, oldType) {
				if (newType == "Infinite") {
					this.createProduct.quantity = 0;
				}
			},

			"updateProduct.type": function(newType, oldType) {
				if (newType == "Infinite") {
					this.updateProduct.quantity = 0;
				}
			},
		},

		data() {
			return {
				groupId: "",
				filter: {
					status: "0",
					categoryId: "0",
					qry: "",
				},
				isBusy: false,
				isBusyModal: false,
				createProduct: {
					code: null,
					name: null,
					description: null,
					volume: null,
					group: null,
					groupSize: null,
					quantity: 99999999,
					type: "Finite",
					retailPrice: null,
					active: null,
					restockLevel: 0,
					manufacturerProductId: null,
					msl: 0,
				},
				updateProduct: {
					code: null,
					name: null,
					description: null,
					volume: null,
					group: null,
					groupSize: null,
					quantity: 99999999,
					type: "Finite",
					retailPrice: null,
					active: true,
					id: null,
					restockLevel: 0,
					manufacturerProductId: null,
					minimumStockLevel: 0,
				},
				editProduct: {
					code: null,
					name: null,
					description: null,
					volume: null,
					group: null,
					groupSize: null,
					quantity: 99999999,
					type: "Finite",
					retailPrice: null,
					active: true,
					id: null,
					restockLevel: 0,
					manufacturerProductId: null,
					msl: 0,
				},
				v1: {
					code: false,
					name: false,
					group: false,
					groupSize: false,
					quantity: false,
					type: false,
					retailPrice: false,
					restockLevel: false,
					manufacturerProductId: false,
				},
				v2: {
					code: false,
					name: false,
					group: false,
					groupSize: false,
					quantity: false,
					type: false,
					retailPrice: false,
					restockLevel: false,
					manufacturerProductId: false,
					msl: 0,
				},
				Products: [],
				originalProducts: [],
				manufacturerProducts: [],
				types: [],
				categories: [],
				units: [],
				manufacturerPrice: null,
				manufacturerCategory: null,
				manufacturerUnit: null,
				allowPriceOverride: true,
				count: 0,
				page: 0,
				pageSize: 10,
				hasNext: false,
				hasPrev: false,
			};
		},

		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},
		},

		methods: {
			async getGroup() {
				this.isBusy = true;
				try {
					const response = await this.$http.get(`/Groups/getGroup/`);
					if (response.ok) {
						this.groupId = response.body;
						this.getManufacturerProducts(this.groupId);
					}

					this.isBusy = true;
				} catch (error) {
					this.isBusy = false;
				}
			},

			async getPrice(id, isEdit) {
				this.isBusy = true;
				this.createProduct.manufacturerProductId = id;

				try {
					if (this.groupId) {
						const response = await this.$http.get(
							`/Groups/price/${this.groupId}/${id}`,
						);

						if (response.status === 200) {
							this.prepareProduct(id, response.body, isEdit);
						}
					} else {
						this.prepareProduct(id, { distributorPrice: 0, retailPrice: 0 }, isEdit);
					}
					this.isBusy = false;
				} catch (error) {
					this.isBusy = false;
				}
			},

			prepareProduct(id, price, isEdit) {
				const product = this.manufacturerProducts.find(pr => pr.id === id);

				this.manufacturerCategory = product.category.name;
				this.manufacturerUnit = product.unit;
				this.allowPriceOverride = !product.retailPrice === 0 || !product.allowPriceOverride;
				this.manufacturerPrice =
					price.distributorPrice > 0 ? price.distributorPrice : product.distributorPrice;
				if (isEdit) {
					this.updateProduct.retailPrice =
						price.retailPrice > 0 ? price.retailPrice : product.retailPrice;
				} else {
					this.createProduct.retailPrice =
						price.retailPrice > 0 ? price.retailPrice : product.retailPrice;
				}
			},

			reset() {
				this.filter = {
					status: "0",
					categoryId: "0",
					qry: "",
				};
			},

			cancel() {
				const currentProduct = this.Products.find(p => p.id === this.editProduct.id);
				this.allowPriceOverride = true;
				currentProduct.code = this.editProduct.code;
				currentProduct.name = this.editProduct.name;
				currentProduct.description = this.editProduct.description;
				currentProduct.volume = null;
				currentProduct.group = null;
				currentProduct.groupSize = null;
				currentProduct.quantity = this.editProduct.quantity;
				currentProduct.type = this.editProduct.type;
				currentProduct.retailPrice = this.editProduct.retailPrice;
				currentProduct.active = this.editProduct.active;
				currentProduct.id = this.editProduct.id;
				currentProduct.restockLevel = this.editProduct.restockLevel;
				currentProduct.manufacturerProductId = this.editProduct.manufacturerProductId;
				currentProduct.msl = this.editProduct.msl;
				$("#mod-edit").modal("hide");
			},

			viewExport() {
				//   $("#mod-export").modal("show");
			},

			viewAdd() {
				this.getManufacturerProducts(this.groupId);
				this.manufacturerPrice = null;
				this.manufacturerCategory = null;
				this.manufacturerUnit = null;
				this.allowPriceOverride = null;
				this.createProduct = {
					code: null,
					name: null,
					description: null,
					unit: null,
					volume: null,
					group: null,
					groupSize: null,
					quantity: 99999999,
					type: "Finite",
					retailPrice: null,
					active: false,
					categoryId: null,
					restockLevel: 0,
					manufacturerProductId: null,
				};
				this.v1 = {
					code: false,
					name: false,
					unit: false,
					group: false,
					groupSize: false,
					quantity: false,
					type: false,
					categoryId: false,
					restockLevel: 0,
					manufacturerProductId: false,
					msl: 0,
				};
				$("#mod-add").modal("show");
			},

			viewUploadProducts() {
				this.$router.push("/products-upload");
			},

			viewEdit(c) {
				this.getManufacturerProducts();
				var editValues = {
					code: c.code,
					name: c.name,
					description: c.description,
					volume: null,
					group: null,
					groupSize: null,
					quantity: c.quantity,
					type: c.type,
					retailPrice: c.retailPrice,
					active: c.active,
					id: c.id,
					restockLevel: c.restockLevel,
					manufacturerProductId: c.manufacturerProductId,
					msl: c.minimumStockLevel ? c.minimumStockLevel : 0,
				};
				this.editProduct = editValues;
				this.updateProduct = c;
				this.allowPriceOverride = !editValues.retailPrice === 0;
				this.manufacturerPrice = c.manufacturerProduct.distributorPrice;
				$("#mod-edit").modal("show");
			},

			viewFilter() {
				$("#mod-filter").modal("show");
			},

			async create() {
				this.isBusyModal = true;
				this.isBusy = true;

				var data = {
					code: this.createProduct.code,
					name: this.createProduct.name,
					description: this.createProduct.description,
					volume: "N/A", // this.createProduct.volume,
					group: "N/A", // this.createProduct.group,
					groupSize: 0, // this.createProduct.groupSize,
					quantity: 99999999,
					type: "Finite",
					retailPrice: this.createProduct.retailPrice,
					active: this.createProduct.active,
					parentId: this.user.parentId,
					restockLevel: this.createProduct.restockLevel,
					manufacturerProductId: this.createProduct.manufacturerProductId,
					msl: this.createProduct.msl,
				};

				if (this.validateCreate()) {
					$("#mod-add").modal("hide");

					await this.$http
						.post("/Products/createForDistributor", data)
						.then(function(res) {
							this.isBusyModal = false;
						})
						.catch(function() {});

					this.getProducts();
				}

				this.isBusy = false;
				this.isBusyModal = false;
			},

			validateCreate() {
				this.v1 = {
					code: false,
					name: false,
					quantity: false,
					type: false,
					restockLevel: false,
					retailPrice: false,
					manufacturerProductId: false,
				};
				var isValid = true;

				if (!this.createProduct.code) {
					this.v1.code = true;
					isValid = false;
				}

				if (!this.createProduct.name) {
					this.v1.name = true;
					isValid = false;
				}

				if (!this.createProduct.manufacturerProductId) {
					this.v1.manufacturerProductId = true;
					isValid = false;
				}

				if (Number(this.createProduct.retailPrice) <= 0) {
					this.v1.retailPrice = true;
					isValid = false;
				}

				if (Number(this.createProduct.restockLevel) < 0) {
					this.v1.restockLevel = true;
					isValid = false;
				}

				return isValid;
			},

			async update() {
				this.isBusyModal = true;
				this.isBusy = true;

				var data = {
					code: this.updateProduct.code,
					name: this.updateProduct.name,
					description: this.updateProduct.description,
					volume: this.updateProduct.volume,
					group: this.updateProduct.group,
					groupSize: this.updateProduct.groupSize,
					quantity: 99999999,
					type: "Finite",
					retailPrice: this.updateProduct.retailPrice,
					active: this.updateProduct.active,
					id: this.updateProduct.id,
					restockLevel: 0,
					manufacturerProductId: this.updateProduct.manufacturerProductId,
					msl: this.updateProduct.minimumStockLevel,
				};

				if (this.validateUpdate()) {
					$("#mod-edit").modal("hide");

					await this.$http
						.put("/Products/updateForDistributor/" + data.id, data)
						.then(function(res) {
							this.isBusyModal = false;
							if (this.$route.params.id) this.$router.push("/products");
						})
						.catch(function() {});

					this.getProducts();
				}

				this.isBusy = false;
				this.isBusyModal = false;
			},

			validateUpdate() {
				this.v2 = {
					code: false,
					name: false,
					quantity: false,
					type: false,
					retailPrice: false,
					restockLevel: false,
					manufacturerProductId: false,
				};
				var isValid = true;

				if (!this.updateProduct.code) {
					this.v2.code = true;
					isValid = false;
				}

				if (!this.updateProduct.name) {
					this.v2.name = true;
					isValid = false;
				}

				if (!this.updateProduct.manufacturerProductId) {
					this.v2.manufacturerProductId = true;
					isValid = false;
				}

				if (Number(this.updateProduct.retailPrice) <= 0) {
					this.v2.retailPrice = true;
					isValid = false;
				}

				if (Number(this.updateProduct.restockLevel) < 0) {
					this.v2.restockLevel = true;
					isValid = false;
				}

				return isValid;
			},

			async getProducts() {
				this.isBusy = true;
				let id = this.$route.params.id;

				await this.$http
					.get(
						"/SalesUsers/getDistributorProductList?page=" +
							this.page +
							"&pageSize=" +
							this.pageSize +
							(this.filter.status != "0" ? "&active=" + this.filter.status : "") +
							(this.filter.categoryId != "0"
								? "&categoryId=" + this.filter.categoryId
								: "") +
							(this.filter.qry != "" ? "&query=" + this.filter.qry : "") +
							("&parentId=" + id),
					)
					.then(function(res) {
						this.Products = res.body.data;
						this.page = res.body.page;
						this.pageSize = res.body.pageSize;
						this.count = res.body.count;
						this.hasPrev = res.body.hasPrev;
						this.hasNext = res.body.hasNext;
						this.isBusy = false;
					})
					.catch(function() {});

				$("#mod-filter").modal("hide");
				this.isBusy = false;
			},

			async getManufacturerProducts(group) {
				this.isBusy = true;
				await this.$http
					.get(
						"/Products/getListNoPagingForManufacturer?active=true" +
							(this.user.parentId ? "&parentId=" + this.user.parentId : "") +
							(group ? "&groupId=" + group : ""),
					)
					.then(function(res) {
						this.manufacturerProducts = res.body;
						this.isBusy = false;
					})
					.catch(function() {});

				$("#mod-filter").modal("hide");
				this.isBusy = false;
			},

			getTypes() {
				this.types = ["Finite", "Infinite"];
			},

			getCategories() {
				var t = "Product Category";

				this.$http
					.get(
						"/Categories/getListNoPagging?type=" +
							t +
							(this.user.parentId
								? "&parentId=" + this.user.parentId
								: "Product Category"),
					)
					.then(function(res) {
						this.categories = res.body;
					})
					.catch(function() {});
			},
			getUnits() {
				var t = "Product Unit";
				var a = true;

				this.$http
					.get(
						"/Categories/getListNoPagging?type=" +
							t +
							"&active=" +
							a +
							(this.user.parentId
								? "&parentId=" + this.user.parentId
								: "Product Category"),
					)
					.then(function(res) {
						this.units = res.body;
					})
					.catch(function() {});
			},
			formatDate(date) {
				return moment(date).format("lll");
			},
			formatMoney(val) {
				return helper.formatMoney(val);
			},
			formatNumber(val) {
				return helper.formatNumber(val);
			},
			prev() {
				this.page -= 1;
				this.getProducts();
			},
			next() {
				this.page += 1;
				this.getProducts();
			},
		},
	};
</script>

<style scoped>
	.image-preview {
		border-radius: 50%;
	}
</style>
