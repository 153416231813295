<template>
  <div>
    <div
      class="row px-[15px] py-[20px] md:px-[24px] md:py-[32px] rounded-[4px] mx-[4px] bg-white"
    >
      <div class="">
        <!-- view mode -->
        <div v-if="!isEditing">
          <h4
            class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535]"
          >
            Personal Information
          </h4>
          <div class="flex flex-col-reverse items-start justify-between">
            <div class="w-full">
              <div
                class="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-4 mt-[24px] w-full"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] max-w-[150px] w-full"
                >
                  Username
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >{{ user.username }}</span
                >
              </div>
              <div
                class="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-4 mt-[24px] w-full"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] max-w-[150px] w-full"
                >
                  Name
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >{{ user.displayName }}</span
                >
              </div>
              <div
                class="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-4 mt-[24px] w-full"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] max-w-[150px] w-full"
                >
                  Email
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >{{ user.emailAddress }}</span
                >
              </div>
              <div
                class="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-4 mt-[24px] w-full"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] max-w-[150px] w-full"
                >
                  Phone Number
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >{{ user.phoneNumber }}</span
                >
              </div>
            </div>
            <div class="w-full flex mt-[10px] md:mt-0 md:justify-end">
              <button
                class="border-[1px] border-[#96a0b5] rounded-[8px] text-[#96a0b5] font-normal text-[14px] border-solid px-[24px] py-[12px] flex items-center gap-[5px] outline-none focus:outlin-none"
                @click="toggleEditMode"
              >
                <span><img src="/assets/icon/edit-2.svg" alt=""/></span>
                Edit
              </button>
            </div>
          </div>
          <h4
            class="font-semibold text-[18px] leading-[24px] capitalize text-[#353535] mt-[16px]"
          >
            Password
          </h4>

          <p
            class="text-[14px] font-normal text-[#96A0B5] leading-[20px] mt-[8px] mb-[24px]"
          >
            A strong password contains a mix of numbers, letters, and symbols.
            It is hard to guess, does not resemble a real word, and is only used
            for this account.
          </p>

          <div>
            <div>
              <button
                class="bg-[#2463eb] border-[##2463eb] border-solid border py-[12px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] outline-none focus:outline-none"
                @click.prevent="showUpdatePassword"
              >
                <span>Update Password</span>
              </button>
            </div>
          </div>
        </div>

        <!-- update form -->
        <div v-if="isEditing">
          <div class="">
            <div
              v-if="user.role === 'Customer' && !user.hasSecurityPin"
              class="py-1"
            >
              <span style="font-size: 12px">
                <em
                  >Before you can edit details on this page, please set up a
                  Security PIN. We will ask you for this PIN when you are making
                  sensitive updates to your profile.</em
                >
              </span>
            </div>

            <form>
              <h4
                class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
              >
                Personal Information
              </h4>
              <div class="max-w-[900px] w-full flex flex-col gap-[16px]">
                <div class="grid md:grid-cols-2 gap-[24px] items-center">
                  <!-- username -->
                  <div class="">
                    <label
                      class="text=[#353535] font-bold text-[14px] leading-[20px]"
                      >Username</label
                    >
                    <div>
                      <input
                        class=" border-[#d0d5dd] bg-transparent border-solid border-[1.5px] p-[14px] rounded-[8px] w-full outline-none"
                        type="text"
                        v-model="user.username"
                        readonly
                      />
                    </div>
                  </div>
                  <!-- name -->
                  <div class="my-[16px]">
                    <label
                      class="text=[#353535] font-bold text-[14px] leading-[20px]"
                      >Name</label
                    >
                    <div>
                      <input
                        :readonly="
                          user.role === 'Customer' &&
                            user.hasSecurityPin === false
                        "
                        :class="[{ 'is-invalid': userValidation.displayName }]"
                        type="text"
                        v-model="user.displayName"
                        class=" border-[#d0d5dd] bg-transparent border-solid border-[1.5px] p-[14px] rounded-[8px] w-full outline-none"
                      />
                      <ul
                        v-if="userValidation.displayName"
                        class="parsley-errors-list filled"
                      >
                        <li class="parsley-required">Required.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="grid md:grid-cols-2 gap-[24px] items-center">
                  <!-- Email address -->
                  <div>
                    <label
                      class="text=[#353535] font-bold text-[14px] leading-[20px]"
                      >Email Address</label
                    >
                    <div>
                      <input
                        :readonly="user.role === 'Customer'"
                        class=" border-[#d0d5dd] bg-transparent border-solid border-[1.5px] p-[14px] rounded-[8px] w-full outline-none"
                        :class="[
                          { 'is-invalid': userValidation.emailAddress },
                          { 'is-invalid': userValidation.emailAddressValid },
                        ]"
                        type="email"
                        v-model="user.emailAddress"
                      />
                      <ul
                        v-if="userValidation.emailAddress"
                        class="parsley-errors-list filled"
                      >
                        <li class="parsley-required">Required.</li>
                      </ul>
                      <ul
                        v-if="userValidation.emailAddressValid"
                        class="parsley-errors-list filled"
                      >
                        <li class="parsley-required">
                          Enter a valid email address.
                        </li>
                      </ul>
                    </div>
                    <div
                      v-if="user.role === 'Customer' && user.hasSecurityPin"
                      class="col-12 col-sm-2"
                    >
                      <button
                        class="btn btn-md btn-text"
                        @click.prevent="openUpdateEmailPhone('Email')"
                      >
                        <span class="text-primary font-weight-bold"
                          >Update</span
                        >
                      </button>
                    </div>
                  </div>
                  <!-- Phone Number -->
                  <div>
                    <label
                      class="text=[#353535] font-bold text-[14px] leading-[20px]"
                      >Phone Number</label
                    >
                    <div>
                      <input
                        :readonly="user.role === 'Customer'"
                        class=" border-[#d0d5dd] bg-transparent border-solid border-[1.5px] p-[14px] rounded-[8px] w-full outline-none"
                        :class="[
                          { 'is-invalid': userValidation.phoneNumber },
                          { 'is-invalid': userValidation.phoneNumberValid },
                        ]"
                        type="text"
                        v-model="user.phoneNumber"
                      />
                      <ul
                        v-if="userValidation.phoneNumber"
                        class="parsley-errors-list filled"
                      >
                        <li class="parsley-required">Required.</li>
                      </ul>
                      <ul
                        v-if="userValidation.phoneNumberValid"
                        class="parsley-errors-list filled"
                      >
                        <li class="parsley-required">
                          Enter a valid phone number.
                        </li>
                      </ul>
                    </div>
                    <div
                      v-if="user.role === 'Customer' && user.hasSecurityPin"
                      class="col-12 col-sm-2"
                    >
                      <button
                        class="btn btn-md btn-text"
                        @click.prevent="openUpdateEmailPhone('Phone')"
                      >
                        <span class="text-primary font-weight-bold"
                          >Update</span
                        >
                      </button>
                    </div>
                  </div>
                </div>
                <div class="grid md:grid-cols-2 gap-[24px] items-center">
                  <!-- state -->
                  <div v-if="user.role == 'Customer'">
                    <label
                      class="text=[#353535] font-bold text-[14px] leading-[20px]"
                      >State</label
                    >
                    <div>
                      <!-- <input
                    type="text"
                    :class="['form-control', { 'is-invalid': userValidation.state }]"
                    v-model="user.state"
                  /> -->
                      <select
                        :readonly="
                          user.role === 'Customer' &&
                            user.hasSecurityPin === false
                        "
                        :class="[{ 'is-invalid': userValidation.state }]"
                        class=" border-[#d0d5dd] bg-transparent border-solid border-[1.5px] p-[14px] rounded-[8px] w-full outline-none"
                        placeholder="State"
                        v-model="userState"
                      >
                        <option v-for="(s, i) in states" :key="i" :value="s">
                          {{ s }}
                        </option>
                      </select>
                      <ul
                        v-if="userValidation.state"
                        class="parsley-errors-list filled"
                      >
                        <li class="parsley-required">Required.</li>
                      </ul>
                    </div>
                  </div>
                  <!-- LGA -->
                  <div v-if="user.role == 'Customer'">
                    <label
                      class="text=[#353535] font-bold text-[14px] leading-[20px]"
                      >Local Government</label
                    >
                    <div>
                      <!-- <input
                    type="text"
                    :class="['form-control', { 'is-invalid': userValidation.lga }]"
                    v-model="user.lga"
                 /> -->
                      <select
                        :readonly="
                          user.role === 'Customer' &&
                            user.hasSecurityPin === false
                        "
                        :class="[{ 'is-invalid': userValidation.lga }]"
                        id="lga_create"
                        placeholder="Local Government"
                        v-model="userLga"
                        class=" border-[#d0d5dd] bg-transparent border-solid border-[1.5px] p-[14px] rounded-[8px] w-full outline-none"
                      >
                        <option v-for="(l, i) in lgas" :key="i" :value="l">
                          {{ l }}
                        </option>
                      </select>
                      <ul
                        v-if="userValidation.lga"
                        class="parsley-errors-list filled"
                      >
                        <li class="parsley-required">Required.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="grid md:grid-cols-2 gap-[24px] items-center">
                  <!-- Address -->
                  <div class="" v-if="user.role == 'Customer'">
                    <label
                      class="text=[#353535] font-bold text-[14px] leading-[20px]"
                      >Address</label
                    >
                    <div>
                      <textarea
                        :readonly="
                          user.role === 'Customer' &&
                            user.hasSecurityPin === false
                        "
                        :class="[
                          { 'is-invalid': userValidation.deliveryAddress },
                        ]"
                        v-model="user.deliveryAddress"
                        class=" border-[#d0d5dd] bg-transparent border-solid border-[1.5px] p-[14px] rounded-[8px] w-full outline-none"
                      ></textarea>
                      <ul
                        v-if="userValidation.deliveryAddress"
                        class="parsley-errors-list filled"
                      >
                        <li class="parsley-required">Required.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <!-- save button -->
              <div>
                <div class="mb-[24px]">
                  <button
                    :disabled="isBusyProfile"
                    class="bg-[#2463eb] border-[##2463eb] border-solid border py-[12px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px]"
                    @click.prevent="updateProfile"
                  >
                    <span v-if="isBusyProfile">Busy...</span>
                    <span v-else>Save changes</span>
                  </button>
                </div>
              </div>

              <div v-if="user.role === 'Customer'">
                <h4><strong>Security Pin</strong></h4>

                <p>
                  A strong password contains a mix of numbers, letters, and
                  symbols. It is hard to guess, does not resemble a real word,
                  and is only used for this account.
                </p>

                <div class="form-group row">
                  <div class="col-12 col-sm-8 col-lg-6">
                    <button
                      style="width: 190px"
                      class="btn btn-space btn-primary"
                      @click.prevent="gotoManagePin"
                    >
                      <span>Manage Pin</span>
                    </button>
                  </div>
                </div>
              </div>

              <h4><strong>Password</strong></h4>

              <p>
                A strong password contains a mix of numbers, letters, and
                symbols. It is hard to guess, does not resemble a real word, and
                is only used for this account.
              </p>

              <div>
                <div>
                  <button
                    class="bg-[#2463eb] border-[##2463eb] border-solid border py-[12px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] mt-[16px]"
                    @click.prevent="showUpdatePassword"
                  >
                    <span>Update Password</span>
                  </button>
                </div>
              </div>

              <!-- USSD settings is commented out, but may referenced later -->
              <!-- <h4 v-if="user.role == 'Customer' || user.role == 'Customer'">
								<strong>USSD Access</strong>
							</h4>

							<p v-if="user.role == 'Customer' || user.role == 'Customer'">
								Enables you access some features via our USSD short code.
							</p>

							<div
								v-if="user.role == 'Customer' || user.role == 'Customer'"
								class="form-group row"
							>
								<div class="col-12 col-sm-8 col-lg-6">
									<button
										style="width: 190px"
										class="btn btn-space btn-primary"
										@click.prevent="showUpdatePIN"
									>
										<span v-if="!user.hasPin">Create a new PIN</span>
										<span v-else>Update PIN</span>
									</button>
								</div>
							</div> -->
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade  py-1 colored-header colored-header-primary"
      id="mod-update-password"
      role="dialog"
    >
      <div class="modal-dialog mx-auto">
        <div class="modal-content p-[24px] bg-[#f8f8f8]">
          <div
            class="modal-header modal-header-colored  flex items-start justify-between p-0 m-0"
          >
            <h4
              class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
            >
              Manage password
            </h4>
            <button
              class="text-2xl text-[#353535]"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body m-0 p-0">
            <div class="mb-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Old password</label
              >
              <div>
                <input
                  :class="[{ 'is-invalid': passwordValidation.old }]"
                  type="password"
                  v-model="password.old"
                  class="bg-transparent border-[#d0d5dd] border-solid border-[1.5px] p-[14px] rounded-[8px] w-full"
                  placeholder="Enter your old password"
                />
                <ul
                  v-if="passwordValidation.old"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >New Password</label
              >
              <div>
                <input
                  :class="[
                    { 'is-invalid': passwordValidation.new },
                    { 'is-invalid': passwordValidation.newValid },
                  ]"
                  type="password"
                  v-model="password.new"
                  class="bg-transparent border-[#d0d5dd] border-solid border-[1.5px] p-[14px] rounded-[8px] w-full"
                  placeholder="Enter your new password"
                />
                <ul
                  v-if="passwordValidation.new"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Required.</li>
                </ul>
                <ul
                  v-if="passwordValidation.newValid"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">
                    Enter greater than or equal to 6 characters.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="flex items-center gap-[8px] mt-[16px]">
            <button
              class="border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] w-full"
              type="button"
              @click.prevent="updatePassword"
            >
              <span v-if="isBusyPassword">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-update-pin"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Manage PIN</h3>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >PIN</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': pinValidation.new },
                    { 'is-invalid': pinValidation.newValid },
                  ]"
                  type="password"
                  v-model="pin.new"
                />
                <ul v-if="pinValidation.new" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
                <ul
                  v-if="pinValidation.newValid"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Enter a 4 digit number.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-space btn-primary"
              @click.prevent="updatePIN"
            >
              <span v-if="isBusyPIN">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-success"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <strong>{{ successModalTitle }}</strong>
            </h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="parent-div success-div">
                <div class="first-child">
                  <div class="second-child">
                    <svg width="20" height="15" viewBox="0 0 20 15" fill="none">
                      <path
                        d="M19.599 0.410713C19.2129 0.0245541 18.5869 0.0245541 18.2007 0.410713L6.35464 12.2568L1.80123 7.70343C1.41511 7.31727 0.789098 7.31731 0.4029 7.70343C0.0167416 8.08955 0.0167416 8.71556 0.4029 9.10172L5.65548 14.3542C6.04148 14.7403 6.66796 14.7401 7.05381 14.3542L19.599 1.80904C19.9851 1.42292 19.9851 0.796872 19.599 0.410713Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <p class="text-center text-bold">
              {{ successModalBody }}
            </p>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-space btn-primary"
              data-dismiss="modal"
              @click="closeSuccessModal"
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-update-phone-email"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <div>
              <div v-if="updateStep === 1">
                <h4 class="modal-title"><strong>Security Pin</strong></h4>
                <span>
                  To edit details on this page you need to input your security
                  pin
                </span>
              </div>
              <div v-if="updateStep === 2">
                <h4 class="modal-title">
                  <strong>Update {{ updateStepType }}</strong>
                </h4>
                <!-- <span> To edit details on this page you need to input your security pin </span> -->
              </div>
              <div v-if="updateStep === 3">
                <h4 class="modal-title"><strong>OTP Verification</strong></h4>
                <span>
                  An OTP has been sent to
                  {{ updateStepType === "Phone" ? verifPhone : verifEmail }} for
                  security reasons
                </span>
              </div>
            </div>
          </div>

          <div class="modal-body">
            <div class="row py-2">
              <div v-if="updateStep === 1" class="form-group col-12">
                <label class="col-12 col-sm-4">Enter Security Pin</label>
                <div class="col-12 col-sm-8">
                  <otp-input
                    :isValid="verifPinValid"
                    :digits="6"
                    type="number"
                    @on-complete="onCompleteVerifPinHandler"
                    @on-changed="onChangedVerifPinHandler"
                    @on-paste="onPasteVerifPinHandler"
                  >
                    <template #errorMessage> Pin is not valid </template>
                  </otp-input>
                </div>
              </div>

              <div
                v-if="updateStep === 2 && updateStepType === 'Email'"
                class="form-group col-12"
              >
                <div class="row">
                  <label class="col-12 col-sm-4">Enter Email Address</label>
                  <div class="col-12 col-sm-8">
                    <input
                      :class="[
                        'form-control',
                        { 'is-invalid': verifValidation.emailAddress },
                        { 'is-invalid': verifValidation.emailAddressValid },
                      ]"
                      type="text"
                      v-model="verifEmail"
                    />
                    <ul
                      v-if="verifValidation.emailAddress"
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">Required.</li>
                    </ul>
                    <ul
                      v-if="verifValidation.emailAddressValid"
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">Enter valid address.</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div
                v-if="updateStep === 2 && updateStepType === 'Phone'"
                class="form-group col-12"
              >
                <div class="row">
                  <label class="col-12 col-sm-4">Enter Phone Number</label>
                  <div class="col-12 col-sm-8">
                    <input
                      :class="[
                        'form-control',
                        { 'is-invalid': verifValidation.phoneNumber },
                        { 'is-invalid': verifValidation.phoneNumberValid },
                      ]"
                      type="text"
                      v-model="verifPhone"
                    />
                    <ul
                      v-if="verifValidation.phoneNumber"
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">Required.</li>
                    </ul>
                    <ul
                      v-if="verifValidation.phoneNumberValid"
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">
                        Enter valid phone number.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div v-if="updateStep === 3" class="form-group col-12">
                <div class="row">
                  <label class="col-12 col-sm-3"
                    ><span class="font-weight-bold">Enter OTP</span></label
                  >
                  <div class="col-12 col-sm-9">
                    <otp-input
                      :isValid="verifOTPValid"
                      :digits="4"
                      type="number"
                      @on-complete="onCompleteOTPHandler"
                      @on-changed="onChangedOTPHandler"
                      @on-paste="onPasteOTPHandler"
                    >
                      <template #errorMessage> OTP is not valid </template>
                    </otp-input>
                  </div>
                  <div class="mt-2 px-2">
                    <span v-if="timerValue > 0"
                      >Resending in {{ prettyTime }}</span
                    >
                    <span v-else
                      >Didn't get OTP?
                      <button
                        @click.prevent="resendPhoneEmailOtp"
                        class="btn btn-text"
                      >
                        <span class="text-primary ml-1" style="cursor:pointer"
                          >Resend</span
                        >
                      </button></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="mt-2 p-1 d-flex flex-row justify-content-between"
            style="border-top: 0.3px solid grey"
          >
            <div>
              <div v-if="updateStep === 3 && updateStepType === 'Phone'">
                <span>Can't access this number?</span><br />
                <span class="font-weight-bold text-primary"
                  ><a href="tel:08183472000">Call us on 08183472000</a></span
                >
              </div>
            </div>

            <div>
              <button
                style="width: 100px"
                class="btn btn-space btn-text"
                data-dismiss="modal"
                @click.prevent="closeUpdateEmailPhoneModal"
              >
                <span class="text-danger">Cancel</span>
              </button>
              <button
                v-if="updateStep === 1"
                :disabled="isBusyStep1"
                style="width: 100px"
                class="btn btn-space btn-primary"
                @click.prevent="verifySecurityPin"
              >
                <span v-if="isBusyStep1">Busy...</span>
                <span v-else>Done</span>
              </button>

              <button
                v-if="updateStep === 2"
                :disabled="isBusyStep2"
                style="width: 100px"
                class="btn btn-space btn-primary"
                @click.prevent="sendPhoneEmailOtp"
              >
                <span v-if="isBusyStep2">Busy...</span>
                <span v-else>Get OTP</span>
              </button>
              <button
                v-if="updateStep === 3"
                :disabled="isBusyStep3"
                style="width: 100px"
                class="btn btn-space btn-primary"
                @click.prevent="SubmitOtp"
              >
                <span v-if="isBusyStep3">Busy...</span>
                <span v-else>Update</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import clone from "lodash/clone";
import { helper } from "@/helper";
import OtpInput from "otp-input-vue2";

export default {
  name: "account",
  components: {
    OtpInput,
  },
  data() {
    return {
      isEditing: false,
      timerSeconds: "00",
      isEditing: false,
      timerValue: 300,
      timer: null,
      timerStarted: false,
      updateStep: 1,
      updateStepType: "",
      verifPin: "",
      verifPinValid: true,
      verifEmail: "",
      verifPhone: "",
      verifValidation: {
        emailAddress: false,
        emailAddressValid: false,
        phoneNumber: false,
        phoneNumberValid: false,
      },
      verifOTP: "",
      verifOTPValid: true,
      isBusyStep1: false,
      isBusyStep2: false,
      isBusyStep3: false,
      isCodeValid: true,

      successModalTitle: "",
      successModalBody: "",

      password: {
        old: null,
        new: null,
      },

      pin: {
        new: null,
      },
      states: helper.getStates(),
      lgas: [],
      userState: "",
      userLga: "",
      userValidation: {
        displayName: false,
        emailAddress: false,
        emailAddressValid: false,
        phoneNumber: false,
        phoneNumberValid: false,
        deliveryAddress: false,
        state: false,
        lga: false,
      },

      passwordValidation: {
        old: false,
        new: false,
        newValid: false,
      },

      pinValidation: {
        newValid: false,
        new: false,
      },

      isBusy: false,
      isBusyProfile: false,
      isBusyPassword: false,
      isBusyPIN: false,
    };
  },

  watch: {
    // whenever state changes, this function will run
    userState(newState) {
      this.lgas = helper.getLgas(newState);
    },
  },

  computed: {
    user: function() {
      this.userState = this.$store.state.currentUser.state;
      this.userLga = this.$store.state.currentUser.lga;
      return clone(this.$store.state.currentUser);
    },

    // lgas: function () {
    //   debugger
    //   return helper.getLgas(this.user.state);
    // },

    lgaSelectUpdate: function() {
      const myLgas = this.lgas;
      $("#lga_create")
        .val(null)
        .trigger("change");
    },
    prettyTime() {
      let time = this.timerValue / 60;
      let minutes = parseInt(time);
      let minutesShow =
        minutes > 1 ? `${minutes} minutes` : `${minutes} minute`;
      let roundedSeconds = Math.round((time - minutes) * 60);
      let secondes =
        roundedSeconds > 1
          ? `${roundedSeconds} seconds`
          : `${roundedSeconds} second`;
      return minutesShow + " " + secondes;
    },
  },

  methods: {
    toggleEditMode() {
      this.isEditing = !this.isEditing;
    },
    start() {
      this.timerStarted = true;
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.timerValue > 0) {
            this.timerValue--;
          } else {
            clearInterval(this.timer);
            // this.reset()
          }
        }, 1000);
      }
    },
    stop() {
      this.timerStarted = false;
      clearInterval(this.timer);
      this.timer = null;
    },
    reset() {
      this.stop();
      this.timerValue = 300;
    },

    onCompletePinHandler(code) {
      this.security.pin = code;
    },

    onChangedPinHandler(lastEnteredCode) {
      // console.log("code changed",  lastEnteredCode);
    },

    onPastePinHandler(code) {
      this.security.pin = code;
    },

    onCompleteNewPinHandler(code) {
      this.security.newPin = code;
    },

    onChangedNewPinHandler(lastEnteredCode) {
      // console.log("code changed",  lastEnteredCode);
    },

    onPasteNewPinHandler(code) {
      this.security.newPin = code;
    },

    onCompleteConfirmPinHandler(code) {
      this.security.confirmPin = code;
    },

    onChangedConfirmPinHandler(lastEnteredCode) {
      // console.log("code changed",  lastEnteredCode);
    },

    onPasteConfirmPinHandler(code) {
      this.security.confirmPin = code;
    },

    gotoManagePin() {
      this.$router.push("/account/manage-pin");
    },

    openSuccessModal(title, message) {
      this.successModalTitle = title;
      this.successModalBody = message;
      $("#mod-success").modal("show");
    },

    closeSuccessModal() {
      $("#mod-success").modal("hide");
      this.successModalTitle = "";
      this.successModalBody = "";
      this.getUser();
    },

    openUpdateEmailPhone(type) {
      this.updateStepType = type;
      $("#mod-update-phone-email").modal("show");
    },

    closeUpdateEmailPhone() {
      this.updateStepType = "";
      this.updateStep = 1;
      $("#mod-update-phone-email").modal("hide");
      this.verifPin = "";
      this.verifPinValid = true;
      this.verifEmail = "";
      this.verifPhone = "";
      this.verifValidation = {
        emailAddress: false,
        emailAddressValid: false,
        phoneNumber: false,
        phoneNumberValid: false,
      };
      this.verifOTP = "";
      this.verifOTPValid = true;
      this.isBusyStep1 = false;
      this.isBusyStep2 = false;
      this.isBusyStep3 = false;
      this.reset();
    },

    closeUpdateEmailPhoneModal() {
      this.closeUpdateEmailPhone();
      this.getUser();
    },

    onCompleteVerifPinHandler(code) {
      this.verifPin = code;
    },

    onChangedVerifPinHandler(lastEnteredCode) {
      // console.log("code changed",  lastEnteredCode);
    },

    onPasteVerifPinHandler(code) {
      this.verifPin = code;
    },

    onCompleteOTPHandler(code) {
      this.verifOTP = code;
    },

    onChangedOTPHandler(lastEnteredCode) {
      // console.log("code changed",  lastEnteredCode);
    },

    onPasteOTPHandler(code) {
      this.verifOTP = code;
    },

    verifySecurityPin() {
      this.verifPinValid = true;

      if (this.verifPin && this.verifPin.length === 6) {
        this.isBusyStep1 = true;
        this.$http
          .get(`/Users/validateUserPin/${this.verifPin}`)
          .then(function(res) {
            this.isBusyStep1 = false;
            if (res.body) {
              this.updateStep++;
            } else {
              this.$toast.error("User pin is not valid", {
                icon: true,
                rtl: false,
              });
            }
          })
          .catch(function() {
            this.isBusyStep1 = false;
          });
      } else {
        this.verifPinValid = false;
      }
    },

    sendPhoneEmailOtp() {
      if (this.validatePhoneEmailUpdate()) {
        this.isBusyStep2 = true;

        if (this.updateStepType === "Email") {
          this.$http
            .get(`/Users/sendEmailOTP/${this.verifEmail}`)
            .then(function(res) {
              this.isBusyStep2 = false;
              if (res.body) {
                this.updateStep++;
                this.start();
              } else {
                this.$toast.error("Error sending OTP", {
                  icon: true,
                  rtl: false,
                });
              }
            })
            .catch(function() {
              this.isBusyStep2 = false;
            });
        }

        if (this.updateStepType === "Phone") {
          this.$http
            .get(`/Users/sendPhoneOTP/${this.verifPhone}`)
            .then(function(res) {
              this.isBusyStep2 = false;
              if (res.body) {
                this.updateStep++;
                this.start();
              } else {
                this.$toast.error("Error sending OTP", {
                  icon: true,
                  rtl: false,
                });
              }
            })
            .catch(function() {
              this.isBusyStep2 = false;
            });
        }
      }
    },

    resendPhoneEmailOtp() {
      if (this.updateStepType === "Email") {
        this.$http
          .get(`/Users/sendEmailOTP/${this.verifEmail}`)
          .then(function(res) {
            if (res.body) {
              this.reset();
              this.start();
            } else {
              this.$toast.error("Error resending OTP", {
                icon: true,
                rtl: false,
              });
            }
          })
          .catch(function() {
            // this.isBusyStep2 = false;
          });
      }

      if (this.updateStepType === "Phone") {
        this.$http
          .get(`/Users/sendPhoneOTP/${this.verifPhone}`)
          .then(function(res) {
            if (res.body) {
              this.reset();
              this.start();
            } else {
              this.$toast.error("Error resending OTP", {
                icon: true,
                rtl: false,
              });
            }
          })
          .catch(function() {
            // this.isBusyStep2 = false;
          });
      }
    },

    validatePhoneEmailUpdate() {
      this.verifValidation = {
        emailAddress: false,
        emailAddressValid: false,
        phoneNumber: false,
        phoneNumberValid: false,
      };
      var isValid = true;

      if (this.updateStepType === "Email") {
        if (!this.verifEmail) {
          this.verifValidation.emailAddress = true;
          isValid = false;
        }

        if (this.verifEmail && !helper.validateEmail(this.verifEmail)) {
          this.verifValidation.emailAddressValid = true;
          isValid = false;
        }

        return isValid;
      }

      if (this.updateStepType === "Phone") {
        if (!this.verifPhone) {
          this.verifValidation.phoneNumber = true;
          isValid = false;
        }

        if (this.verifPhone && !helper.validatePhone(this.verifPhone)) {
          this.verifValidation.phoneNumberValid = true;
          isValid = false;
        }

        return isValid;
      }
    },

    SubmitOtp() {
      if (this.verifOTP && this.verifOTP.length === 4) {
        this.isBusyStep3 = true;
        this.$http
          .post(`/Users/validateOTP?otp=${this.verifOTP}`)
          .then(function(res) {
            this.isBusyStep3 = false;

            if (res.body === "Successfully validated") {
              if (this.updateStepType === "Email") {
                this.updateUserEmail();
              }
              if (this.updateStepType === "Phone") {
                this.updateUserPhoneNumber();
              }
            }
          })
          .catch(function() {
            this.isBusyStep3 = false;
          });
      } else {
        this.verifOTPValid = false;
      }
    },

    async updateUserEmail() {
      let payload = {
        id: this.user.id,
        emailAddress: this.verifEmail,
      };
      await this.$http
        .put(`/Users/updateEmail/${this.user.id}`, payload)
        .then(function(res) {
          this.isBusyStep3 = false;
          if (res.body) {
            this.closeUpdateEmailPhone();
            this.openSuccessModal(
              "Success",
              "Email address updated successfully"
            );
          } else {
            this.$toast.error("Failed to update", {
              icon: true,
              rtl: false,
            });
          }
        })
        .catch(function() {
          this.isBusyStep3 = false;
        });
    },

    async updateUserPhoneNumber() {
      let payload = {
        id: this.user.id,
        phoneNumber: this.verifPhone,
      };
      await this.$http
        .put(`/Users/updateMerchantPhoneNumber/${this.user.id}`, payload)
        .then(function(res) {
          this.isBusyStep3 = false;
          if (res.body) {
            this.closeUpdateEmailPhone();
            this.openSuccessModal(
              "Success",
              "Phone number updated successfully"
            );
          } else {
            this.$toast.error("Failed to update", {
              icon: true,
              rtl: false,
            });
          }
        })
        .catch(function() {
          this.isBusyStep3 = false;
        });
    },

    showUpdatePassword() {
      this.password = {
        old: null,
        new: null,
      };
      this.passwordValidation = {
        old: false,
        new: false,
        newValid: false,
      };
      $("#mod-update-password").modal("show");
    },

    showUpdatePIN() {
      this.pin = { new: null };
      this.pinValidation = {
        new: false,
        newValid: false,
      };
      $("#mod-update-pin").modal("show");
    },

    getUser() {
      this.$store.commit("loadingStaus", true);
      this.$http
        .get("/Users/getCurrent")
        .then(function(res) {
          this.$store.commit("setCurrentUser", res.body);
          this.$store.commit("loadingStaus", false);
          this.loaded = true;
        })
        .catch(function() {
          this.loaded = false;
        });
    },

    async updateProfile() {
      this.isBusyProfile = true;

      if (this.validateUser()) {
        let data = {
          id: this.user.id,
          displayName: this.user.displayName,
          emailAddress: this.user.emailAddress,
          phoneNumber: this.user.phoneNumber,
          deliveryAddress: this.user.deliveryAddress,
          lga: this.userLga,
          state: this.userState,
        };

        await this.$http
          .put(
            `/${this.user.isSalesUser ? "SalesUsers" : "Users"}/update/` +
              data.id,
            data
          )
          .then(function(res) {
            this.$store.commit("setCurrentUser", this.user);
            this.getUser();
            this.$toast.success("Your profile details have been updated.", {
              icon: false,
              rtl: false,
            });

            this.isBusyProfile = false;
          })
          .catch(function() {
            var currentUser = clone(this.$store.state.currentUser);
            this.user.phoneNumber = currentUser.phoneNumber;
            this.user.emailAddress = currentUser.emailAddress;
            this.user.displayName = currentUser.displayName;
            this.userState = currentUser.state;
            this.userLga = currentUser.lga;
            this.isBusyProfile = false;
          });
      } else {
        this.isBusyProfile = false;
      }
    },

    updatePassword() {
      this.isBusyPassword = true;

      if (this.validatePassword()) {
        let data = {
          id: this.user.id,
          oldPassword: this.password.old,
          newPassword: this.password.new,
        };

        $("#mod-update-password").modal("hide");

        this.$http
          .put(
            `/${
              this.user.isSalesUser ? "SalesUsers" : "Users"
            }/updatePassword/` + data.id,
            data
          )
          .then(function(res) {
            this.$toast.success("Your password has been updated.", {
              icon: false,
              rtl: false,
            });

            this.isBusyPassword = false;
          })
          .catch(function() {
            this.isBusyPassword = false;
          });

        this.password = {
          old: null,
          new: null,
        };
      } else {
        this.isBusyPassword = false;
      }
    },

    updatePIN() {
      this.isBusyPIN = true;

      if (this.validatePIN()) {
        let data = {
          id: this.user.id,
          pin: this.pin.new,
        };

        $("#mod-update-pin").modal("hide");

        this.$http
          .put("/Users/updatePIN/" + data.id, data)
          .then(function(res) {
            this.$toast.success("Your PIN has been updated.", {
              icon: false,
              rtl: false,
            });

            this.user.hasPin = true;
            this.isBusyPIN = false;
          })
          .catch(function() {
            this.isBusyPIN = false;
          });

        this.pin = {
          new: null,
        };
      } else {
        this.isBusyPIN = false;
      }
    },

    validateUser() {
      this.userValidation = {
        displayName: false,
        emailAddress: false,
        emailAddressValid: false,
        phoneNumber: false,
        phoneNumberValid: false,
        deliveryAddress: false,
        state: false,
        lga: false,
      };
      let isValid = true;

      if (!this.user.displayName) {
        this.userValidation.displayName = true;
        isValid = false;
      }

      if (this.user.role == "Customer" && !this.user.deliveryAddress) {
        this.userValidation.deliveryAddress = true;
        isValid = false;
      }

      if (!this.user.emailAddress) {
        this.userValidation.emailAddress = true;
        isValid = false;
      }

      if (
        this.user.emailAddress &&
        !helper.validateEmail(this.user.emailAddress)
      ) {
        this.userValidation.emailAddressValid = true;
        isValid = false;
      }

      if (!this.user.phoneNumber) {
        this.userValidation.phoneNumber = true;
        isValid = false;
      }

      // if (!this.userState) {
      // 	this.userValidation.state = true;
      // 	isValid = false;
      // }

      // if (!this.userLga) {
      // 	this.userValidation.lga = true;
      // 	isValid = false;
      // }

      if (
        this.user.phoneNumber &&
        !helper.validatePhone(this.user.phoneNumber)
      ) {
        this.userValidation.phoneNumberValid = true;
        isValid = false;
      }

      return isValid;
    },

    validatePassword() {
      this.passwordValidation = {
        old: false,
        new: false,
        newValid: false,
      };
      let isValid = true;

      if (!this.password.old) {
        this.passwordValidation.old = true;
        isValid = false;
      }

      if (!this.password.new) {
        this.passwordValidation.new = true;
        isValid = false;
      }

      if (this.password.new && !helper.validateLength(this.password.new, 6)) {
        this.passwordValidation.newValid = true;
        isValid = false;
      }

      return isValid;
    },

    validatePIN() {
      this.pinValidation = {
        new: false,
        newValid: false,
      };
      let isValid = true;

      if (!this.pin.new) {
        this.pinValidation.new = true;
        isValid = false;
      }

      if (this.pin.new && !helper.validatePIN(this.pin.new)) {
        this.pinValidation.newValid = true;
        isValid = false;
      }

      return isValid;
    },

    async remove(id) {
      this.isBusy = true;

      await this.$http
        .delete("/Users/deleteParent/" + id)
        .then(function(res) {
          this.getParents();
        })
        .catch(function() {});

      this.isBusy = false;
    },
  },
};
</script>

<!-- <style scoped>
.parent-div {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px auto 34px;
  width: 110px;
  height: 110px;
}

.first-child {
  width: 86.52px;
  height: 86.52px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.second-child {
  width: 61.8px;
  height: 61.8px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.success-div {
  border: 1px solid rgba(182, 201, 121, 0.2);
}

.success-div .first-child {
  border: 1px solid rgba(182, 201, 121, 0.5);
}

.success-div .second-child {
  background: rgba(182, 201, 121, 1);
}

.text-bold {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}
</style> -->
