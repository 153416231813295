<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button class="btn btn-success btn-rounded btn-space" @click="goBack">
            <i class="mdi icon mdi-backburger text-white"></i>
            Back
          </button>
          <button
            class="btn btn-success btn-rounded btn-space"
            @click.prevent="add"
          >
            <i class="mdi icon mdi-plus text-white">&nbsp;</i>
            Add
          </button>
        </div>
        <div class="card-body">
          <div class="form-group row justify-content-between">
            <div class="input-group col-2">
              <div class="custom-file">
                <input
                  type="file"
                  class="custom-file-input"
                  id="salesorderuploader"
                  aria-describedby="UploadSalesOrder"
                  value="file"
                  ref="fileUpload"
                  @change="onFileSelected"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv"
                />
                <label class="custom-file-label" for="salesorderuploader">
                  {{ file ? file.name.substring(0, 20) : "Choose file" }}</label
                >
              </div>
            </div>
            <a
              download="salesorder-template.csv"
              href="/assets/templates/salesorder-template.csv"
              class="btn btn-primary text-white"
            >
              <i class="mdi mdi-cloud-download" />
              Download Template
            </a>
          </div>

          <div class="dataTables_wrapper container-fluid dt-bootstrap4">
            <div class="row be-datatable-body max-height">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th scope="col">Sales Order Number</th>
                      <th scope="col">Customer Code</th>
                      <th scope="col">Customer Full Name</th>
                      <th scope="col">Product Code</th>
                      <th scope="col">Product Price(NGN)</th>
                      <th scope="col">Quantity Ordered</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                      <th scope="col" style="width: 15%"></th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(s, index) in saleOrders" :key="s.id">
                      <td>{{ s.salesOrder }}</td>
                      <td>{{ s.customerCode }}</td>
                      <td>{{ s.customerFullName }}</td>
                      <td>{{ s.productCode }}</td>
                      <td>{{ s.productPrice }}</td>
                      <td>{{ s.quantityOrdered }}</td>
                      <td>
                        <StageOptionsDropdown
                          :orderStages="orderStages"
                          :salesOrderIndex="index"
                          :salesOrder="s"
                          @onStageSelectHandler="onStageSelectHandler"
                        />
                      </td>
                      <td>
                        <SalesOrderAction
                          :salesOrder="s"
                          :salesOrderIndex="index"
                          :actions="actions"
                          :isProductExist="s.isProductExist"
                          @onActionSelectHandler="onActionSelectHandler"
                        />
                      </td>
                      <td class="text-danger">
                        <small
                          style="
                            font-size: 0.6rem;
                            word-wrap: break-word;
                            white-space: normal;
                          "
                          v-if="s.errors"
                          class="feather icon-alert-triangletext-break"
                        ></small
                        >&nbsp;{{ s.errors }}
                      </td>
                      <td>
                        <div class="d-flex">
                          <button
                            class="btn btn-success btn-sm btn-rounded btn-space"
                            @click.prevent="viewEdit(s, index)"
                          >
                            <i class="feather icon-edit"></i>
                            Edit</button
                          >&nbsp;
                          <button
                            class="btn btn-danger btn-sm btn-rounded btn-space"
                            @click.prevent="onRemoveClick(index)"
                          >
                            <i class="feather icon-delete"></i> Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ saleOrders.length }} sales order{{
                    saleOrders.length != 1 ? "s" : ""
                  }}
                </div>
              </div>
              <div class="col-sm-7">
                <div class="dataTables_info text-danger">
                  {{
                    errorCount > 0
                      ? "Please correct all errors before proceeding"
                      : ""
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-12 col-sm-12 col-lg-12">
              <button
                style="width: 130px"
                :disabled="isBusy || saleOrders.length <= 0 || errorCount > 0"
                class="btn btn-primary"
                type="button"
                @click.prevent="onUploadClick"
              >
                <span v-if="isBusy">Busy...</span>
                <span v-else>Post</span>
              </button>

              <button
                v-if="saleOrders.length > 0"
                style="width: 130px"
                class="btn btn-danger pull-right"
                type="button"
                @click.prevent="reset"
              >
                <span>Clear All</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <UploadCaution
      :text="modalText"
      :action="action"
      @proceedToRemove="remove"
      @proceedToUpload="uploadSales"
    />
    <EditUploadSalesOrder
      :sales="saleOrder"
      @onUpdateHandler="onUpdateHandler"
      :isValidating="isValidating"
    />
    <AddProduct
      @onItemAdded="onItemAddHandler"
      :actions="actions"
      :orderStages="orderStages"
    />
  </div>
</template>

<script>
import XLSX from "xlsx";
import camelcaseKeys from "camelcase-keys";
import UploadCaution from "../../components/UploadCaution";
import StageOptionsDropdown from "../../components/SettingsPurchaseOrder/StageOptionsDropdown.vue";
import SalesOrderAction from "../../components/SettingsPurchaseOrder/SalesOrderAction.vue";
import EditUploadSalesOrder from "../../components/SettingsPurchaseOrder/EditUploadSalesOrder.vue";
import AddProduct from "../../components/SettingsPurchaseOrder/UploadSalesOrder/AddProduct.vue";
import { mapActions } from "vuex";

export default {
  name: "UploadSalesOrder",
  components: {
    UploadCaution,
    StageOptionsDropdown,
    EditUploadSalesOrder,
    SalesOrderAction,
    AddProduct,
  },

  data() {
    return {
      actions: [
        { value: 0, text: "Delete" },
        { value: 1, text: "Update" },
        { value: 2, text: "New" },
      ],
      errorCount: 0,
      isBusy: false,
      file: null,
      newSaleOrder: {},
      saleOrders: [],
      saleOrder: {},
      modalText: "",
      action: "",
      removeIndex: 0,
      orderStages: [],
      editIndex: 0,
      isValidating: false,
    };
  },

  mounted() {
    this.getOrderStages();
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  methods: {
    ...mapActions(["getPurchaseOrder"]),
    async getOrderStages() {
      try {
        this.isBusy = true;
        const response = await this.$http.get(
          "/PurchaseOrders/orderStage/getList?active=true"
        );

        if (response.ok && response.status === 200) {
          this.orderStages = response.body.sort(
            (firstItem, secondItem) => firstItem.stage - secondItem.stage
          );
        }

        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    checkFileExtension(file) {
      return file.split(".").pop();
    },

    onExcelSelected(file) {
      const reader = new FileReader();
      let self = this;
      reader.onload = function(e) {
        const data = e.target.result;
        const workbook = XLSX.read(data, {
          type: "binary",
        });

        workbook.SheetNames.forEach(function(sheetName) {
          const XL_row_object = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheetName]
          );

          self.saleOrders = camelcaseKeys(XL_row_object);
          self.processData();
        });
      };
      reader.readAsBinaryString(file);
    },

    onFileSelected(event) {
      this.errorCount = 0;
      const files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;
      this.file = null;
      this.file = files[0];

      let self = this;
      const ext = this.checkFileExtension(this.file.name);
      if (ext === "csv") {
        this.$papa.parse(this.file, {
          header: true,
          download: true,
          dynamicTyping: true,
          skipEmptyLines: true,

          complete: function(results) {
            self.saleOrders = results.data;
            self.processData();
          },
        });
      } else if (ext === "xlsx") {
        this.onExcelSelected(this.file);
      }
    },

    processData() {
      this.saleOrders.forEach(async (element) => {
        let errors = "";
        element.errors = "";

        if (!element.quantityOrdered) {
          errors += "Invalid quantity";
          element.errors = errors;
          this.errorCount++;
        }
        const stage = element.status
          ? this.orderStages.find(
              (x) => x.name.toLowerCase() === element.status.toLowerCase()
            )
          : null;

        if (stage) element.status = stage.id;
        else {
          errors += "Invalid Stage";
          element.errors = errors;
        }

        const action = element.action
          ? this.actions.find(
              (x) => x.text.toLowerCase() === element.action.toLowerCase()
            )
          : null;

        if (action) element.action = action.value;
        else {
          errors += " | Invalid Action";
          element.errors = errors;
          this.errorCount++;
        }

        const status = await this.validatePrice(
          element.salesOrder,
          element.productCode,
          element.productPrice
        );

        if (status) {
          if (status.isProductExist) {
            element.isProductExist = status.isProductExist;
            this.$forceUpdate();
          }

          if (!status.isPriceValid) {
            errors += " | Invalid Product price";
            element.errors = errors;
            this.errorCount++;
          }

          if (
            !status.isProductExist &&
            (element.action === 1 || element.action === 0)
          ) {
            errors += " | Product doesn't exist on PO";
            element.errors = errors;
            this.errorCount++;
          }
        }

        for (let k = 0; k < this.saleOrders.length; k++) {
          const saleOrder = this.saleOrders[k];
          if (
            element.productCode != saleOrder.productCode &&
            element.salesOrder == saleOrder.salesOrder &&
            element.status !== saleOrder.status
          ) {
            errors += " | Stage Should be the same";
            element.errors = errors;
            this.errorCount++;
          }
        }
        errors = "";
      });
    },

    onRemoveClick(index) {
      this.modalText = "Are you sure you want to remove this item?";
      this.action = "remove";
      this.removeIndex = index;
      this.cautionModalControl("show");
    },

    add() {
      // eslint-disable-next-line no-undef
      $("#mod-product-add").modal("show");
    },

    onItemAddHandler(data) {
      this.saleOrders.push(data);
      // eslint-disable-next-line no-undef
      $("#mod-product-add").modal("hide");
    },

    remove() {
      var expense = this.saleOrders[this.removeIndex];
      if (expense.errors) {
        this.errorCount--;
      }
      this.saleOrders.splice(this.removeIndex, 1);
      this.removeIndex = 0;
      this.cautionModalControl("hide");
    },

    cautionModalControl(state) {
      // eslint-disable-next-line no-undef
      $("#mod-expense-caution").modal(state);
    },

    onActionSelectHandler(data) {
      const salesOrder = this.saleOrders[data.salesOrderIndex];
      salesOrder.action = data.value;
      this.revalidateSalesOrder(data.salesOrderIndex, "Invalid Action", true);
      this.revalidateSalesOrder(
        data.salesOrderIndex,
        "Product doesn't exist on PO",
        true
      );
      this.revalidateSalesOrderAction(data.salesOrderIndex);
    },

    async revalidateSalesOrderAction(salesOrderIndex) {
      const salesOrder = this.saleOrders[salesOrderIndex];
      if (
        salesOrder.action < 2 &&
        !salesOrder.errors.includes("Product doesn't exist on PO")
      ) {
        let errors = "";
        const status = await this.validatePrice(
          salesOrder.salesOrder,
          salesOrder.productCode,
          salesOrder.productPrice
        );

        if (status) {
          salesOrder.isProductExist = status.isProductExist;

          if (
            !status.isProductExist &&
            (salesOrder.action === 1 || salesOrder.action === 0)
          ) {
            errors = "| Product doesn't exist on PO";
            salesOrder.errors += errors;
            this.errorCount++;
          }
        }
      }
    },

    revalidateSalesOrder(salesOrderIndex, error, isCompulsory) {
      const salesOrder = this.saleOrders[salesOrderIndex];
      const myArr = salesOrder.errors.trim(" ").split("|");

      myArr.forEach((element) => {
        if (element.trim(" ") === error) {
          const index = myArr.indexOf(element);
          if (error === "Product doesn't exist on PO") {
            if (salesOrder.action == 2 && index > -1) {
              myArr.splice(index, 1);
              salesOrder.errors = myArr.join("|");
              if (isCompulsory) this.errorCount--;
            }
          } else {
            if (index > -1) {
              myArr.splice(index, 1);
              salesOrder.errors = myArr.join("|");
              if (isCompulsory) this.errorCount--;
              else this.$forceUpdate();
            }
          }
        }
      });
    },

    onStageSelectHandler(data) {
      const salesOrder = this.saleOrders[data.salesOrderIndex];
      salesOrder.status = data.id;
      this.revalidateSalesOrder(data.salesOrderIndex, "Invalid Stage", true);
      this.validateSameStage(
        salesOrder.saleOrder,
        data.salesOrderIndex,
        salesOrder.status
      );
    },

    validateSameStage(salesOrder, currentIndex, status) {
      for (let index = 0; index < this.saleOrders.length; index++) {
        const element = this.saleOrders[index];
        if (
          element.saleOrder === salesOrder &&
          index != currentIndex &&
          status === element.status
        ) {
          this.revalidateSalesOrder(
            currentIndex,
            "Stage Should be the same",
            true
          );
          this.revalidateSalesOrder(index, "Stage Should be the same", false);
        }
      }
    },

    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },

    reset() {
      this.errorCount = 0;
      this.saleOrders.splice(0);
      this.$refs.fileUpload.value = null;
    },

    onUploadClick() {
      this.modalText = "Are you sure you want to submit sales order?";
      this.action = "upload";
      this.cautionModalControl("show");
    },

    viewEdit(s, index) {
      this.saleOrder = s;
      this.editIndex = index;
      // eslint-disable-next-line no-undef
      $("#mod-upload-sales-edit").modal("show");
    },

    async onUpdateHandler(value) {
      const salesOrder = this.saleOrders[this.editIndex];
      salesOrder.salesOrder = value.salesOrder;
      salesOrder.customerCode = value.customerCode;
      salesOrder.customerFullName = value.customerFullName;
      salesOrder.productCode = value.productCode;
      salesOrder.productPrice = value.productPrice;
      salesOrder.quantityOrdered = value.quantityOrdered;

      this.revalidateSalesOrder(
        this.editIndex,
        "Product doesn't exist on PO",
        true
      );
      this.revalidateSalesOrder(this.editIndex, "Invalid quantity", true);

      this.revalidateSalesOrderAction(this.editIndex);
      this.isValidating = true;
      const status = await this.validatePrice(
        salesOrder.salesOrder,
        salesOrder.productCode,
        salesOrder.productPrice
      );

      if (status) {
        if (status.isPriceValid) {
          this.revalidateSalesOrder(
            this.editIndex,
            "Invalid Product price",
            true
          );
        } else if (!salesOrder.errors.includes("Invalid Product price")) {
          salesOrder.errors += " | Invalid Product price";
          this.errorCount++;
        }
      }
      this.isValidating = false;
      this.editIndex = 0;
      // eslint-disable-next-line no-undef
      $("#mod-upload-sales-edit").modal("hide");
    },

    async uploadSales() {
      this.cautionModalControl("hide");
      this.isBusy = true;

      try {
        const response = await this.$http.post(
          `/PurchaseOrders/manufacturer/bulkupdate/${this.user.id}`,
          this.saleOrders
        );

        if (response.ok) {
          this.$toast.success(response.body, {
            icon: false,
            rtl: false,
          });
          this.$router.push(`/sales-order`);
        }

        this.this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    async validatePrice(salesOrderNumber, productCode, price) {
      this.isBusy = true;
      let status = {};

      try {
        const response = await this.$http.get(
          `/PurchaseOrders/manufacturer/validateprice?SalesOrderNumber=${salesOrderNumber}&ProductCode=${productCode}&Price=${price}`
        );

        if (response.ok) status = response.body;
      } catch (error) {
        this.isBusy = false;
      }

      this.isBusy = false;
      return status;
    },
  },
};
</script>
