<template>
  <section>
    <!-- Distributor Table -->
    <div class="table-responsive">
      <table class="table p-0 m-0 dataTable no-footer">
        <thead class="bg-white">
          <tr class="py-[5px]">
            <th
              class="text-[12px] leading-[18px] text-[#000000] font-semibold"
              width="22%"
            >
              Name
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Code
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Source ID
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              State
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Group
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Status
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Created
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Actions
            </th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="m in distributors" :key="m.id">
            <td
              class="text-[14px] font-normal leading-[16.94px] text-[#353535]"
            >
              {{ m.name }}
            </td>
            <td
              class="text-[14px] font-medium leading-[16.94px] text-[#353535]"
            >
              {{ m.code }}
            </td>
            <td
              class="text-[14px] font-normal leading-[16.94px] text-[#353535]"
            >
              {{ m.sourceId }}
            </td>
            <td
              class="text-[14px] font-normal leading-[16.94px] text-[#353535]"
            >
              {{ m.state }}
            </td>
            <td
              class="text-[14px] font-normal leading-[16.94px] text-[#353535]"
            >
              {{ m.group ? m.group.name : "" }}
            </td>
            <td>
              <span
                class="flex items-center gap-[4px] max-w-max w-full"
                :class="[
                  'badge',
                  { 'badge-success': m.active, 'badge-danger': !m.active },
                ]"
              >
                <span
                  class="w-[10px] h-[10px] rounded-[1px]"
                  :style="{
                    backgroundColor: m.active ? '#16a249' : '#EF5253',
                  }"
                ></span>
                <span class="text-[12px] font-normal leading-[16.94px]">{{
                  m.active ? "Active" : "Inactive"
                }}</span>
              </span>
            </td>

            <td
              class="text-[14px] font-normal leading-[16.94px] text-[#353535]"
            >
              {{ formatDate(m.created) }}
            </td>
            <td
              class="text-[14px] font-normal leading-[16.94px] text-[#353535]"
            >
              <button
                class="text-[14px] font-normal leading-[16.94px] outline-none focus:outline-none text-[#96A0B5]"
                v-if="
                  hasPermission('Distributor_Product_Permission_View') ||
                    hasPermission('Distributor_Product_permision_Update')
                "
                @click="viewDistributor(m)"
              >
                View
              </button>
            </td>
          </tr>
          <tr v-if="!distributors.length">
            <td colspan="12">
              <div class="text-center" style="padding-top: 50px">
                <span
                  style="font-size: 4.615rem"
                  class="fa-solid fa-truck text-[#2463EB]"
                ></span>
              </div>
              <h3
                class="text-center pt-[24px] text-[16px] font-medium leading-[16.94px]"
                style="padding-bottom: 100px"
              >
                You don't have any distributors.
              </h3>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- business popup -->
    <div
      v-if="isModalVisible"
      class="fixed inset-0 bg-[rgba(0,0,0,0.4)] z-[1111111] flex justify-end overflow-y-auto"
      id="popup-content"
      @click.self="closeModal"
    >
      <div
        class="relative w-full lg:w-[80%] max-h-screen bg-[#f8f8f8] px-[12px] py-[30px] md:p-[40px] overflow-auto"
      >
        <div class="shadow-none bg-transparent">
          <div class="flex items-start justify-between">
            <p class="text-[#353535] text-[20px] leading-[20px] font-semibold">
              {{ selectedDistributor.name }}
            </p>
            <button
              class="text-2xl text-[#353535] outline-none focus:outline-none"
              type="button"
              @click="closeModal"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div
            class="bg-white p-1 rounded-[4px] flex items-center gap-[16px] mt-[16px]"
          >
            <button
              :class="[
                'text-[14px] font-normal py-[6px] px-[24px] rounded-[8px] outline-none focus:outline-none',
                {
                  'bg-[#2463EB] text-white': activeTab === 'profile',
                  'bg-[#f8f8f8] text-[#96a0b5]': activeTab !== 'profile',
                },
              ]"
              @click="switchTab('profile')"
            >
              Profile
            </button>
            <div
              v-if="
                hasPermission('Distributor_Product_Permission_View') ||
                  hasPermission('Distributor_Product_Permission_Update')
              "
            >
              <button
                :class="[
                  'text-[14px] font-normal py-[6px] px-[24px] rounded-[8px] outline-none focus:outline-none',
                  {
                    'bg-[#2463EB] text-white': activeTab === 'settings',
                    'bg-[#f8f8f8] text-[#96a0b5]': activeTab !== 'settings',
                  },
                ]"
                @click="switchTab('settings')"
                v-if="
                  hasPermission('Distributor_Product_Permission_Update') &&
                    hasPermission('Price_Option_View')
                "
              >
                Additional Settings
              </button>
            </div>
          </div>

          <!-- Profile tab -->
          <div v-if="activeTab === 'profile'">
            <div
              class="bg-white px-[15px] py-[24px] sm:px-[24px] sm:py-[32px] mt-[16px] rounded-[4px]"
            >
              <div
                class="flex flex-col md:flex-row justify-between items-start gap-[5px] md:items-center"
              >
                <div class="flex items-center gap-2 flex-1">
                  <h2
                    class="font-semibold text-[20px] leading-[20px] text-[#353535] md:max-w-[170px] w-full"
                  >
                    Business Profile
                  </h2>
                  <span
                    class="flex items-center gap-[4px] max-w-max w-full"
                    :class="[
                      'badge',
                      {
                        'badge-success': selectedDistributor.active,
                        'badge-danger': !selectedDistributor.active,
                      },
                    ]"
                  >
                    <span
                      class="w-[10px] h-[10px] rounded-[1px]"
                      :style="{
                        backgroundColor: selectedDistributor.active
                          ? '#16a249'
                          : '#EF5253',
                      }"
                    ></span>
                    <span class="text-[12px] font-normal">{{
                      selectedDistributor.active ? "Active" : "Inactive"
                    }}</span>
                  </span>
                </div>
                <div class="flex flex-1 justify-end">
                  <button
                    v-if="selectedDistributor.active"
                    @click="refreshPage"
                    class="text-[#96a0b5] text-[14px] font-normal bg-[#2463EB] text-white py-[12px] px-[24px] rounded-[8px]"
                  >
                    Refresh Data
                  </button>
                  <button
                    v-else
                    class="text-[#96a0b5] text-[14px] font-normal bg-[#2463EB] text-white py-[12px] px-[24px] rounded-[8px]"
                  >
                    Send Registration Link
                  </button>
                </div>
              </div>
              <div
                class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] md:max-w-[170px] w-full"
                >
                  Business Name
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >{{ selectedDistributor.name }}</span
                >
              </div>
              <div
                class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] md:max-w-[170px] w-full"
                >
                  RC Number
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >{{ selectedDistributor.code }}</span
                >
              </div>
              <div
                class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] md:max-w-[170px] w-full"
                >
                  Business Description
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >{{
                    selectedDistributor.description
                      ? selectedDistributor.description
                      : "Not Available"
                  }}</span
                >
              </div>
              <div
                class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] md:max-w-[170px] w-full"
                >
                  Website
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >{{
                    selectedDistributor.website
                      ? selectedDistributor.website
                      : "Not Available"
                  }}</span
                >
              </div>
              <div
                class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] md:max-w-[170px] w-full"
                >
                  Business Phone Number
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >{{
                    selectedDistributor.phoneNumber
                      ? selectedDistributor.phoneNumber
                      : "Not Available"
                  }}</span
                >
              </div>
              <div
                class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] md:max-w-[170px] w-full"
                >
                  Parent
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >{{
                    selectedDistributor.parentId
                      ? selectedDistributor.parentId
                      : "Not Available"
                  }}</span
                >
              </div>
              <div
                class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] md:max-w-[170px] w-full"
                >
                  Tax Identification Number
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >{{
                    selectedDistributor.taxNumber
                      ? selectedDistributor.taxNumber
                      : "Not Available"
                  }}</span
                >
              </div>
              <div
                class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] md:max-w-[170px] w-full"
                >
                  Business Email Address
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >{{
                    selectedDistributor.email
                      ? selectedDistributor.email
                      : "Not Available"
                  }}</span
                >
              </div>
              <div
                class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] md:max-w-[170px] w-full"
                >
                  Business Address
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >{{
                    selectedDistributor.Address
                      ? selectedDistributor.Address
                      : "Not Available"
                  }}</span
                >
              </div>
            </div>
            <div
              class="bg-white px-[15px] py-[24px] sm:px-[24px] sm:py-[32px] mt-[16px] rounded-[4px]"
            >
              <h2
                class="font-semibold text-[20px] leading-[20px] text-[#353535]"
              >
                System Information
              </h2>
              <div
                class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] max-w-[170px] w-full"
                >
                  Code
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >{{ selectedDistributor.code }}</span
                >
              </div>
              <div
                class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] max-w-[170px] w-full"
                >
                  Created
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >{{ formatDate(selectedDistributor.created) }}</span
                >
              </div>
              <div
                class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] max-w-[170px] w-full"
                >
                  Primary Email
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >tggj@gmail.com</span
                >
              </div>
              <div
                class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] max-w-[170px] w-full"
                >
                  Source ID
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >{{ selectedDistributor.sourceId }}</span
                >
              </div>
              <div
                class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] max-w-[170px] w-full"
                >
                  Last Updated
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >{{ formatDate(selectedDistributor.updated) }}</span
                >
              </div>
            </div>
            <div
              class="bg-white px-[15px] py-[24px] sm:px-[24px] sm:py-[32px] mt-[16px] rounded-[4px]"
            >
              <h2
                class="font-semibold text-[20px] leading-[20px] text-[#353535]"
              >
                Contact Information
              </h2>
              <div
                class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] max-w-[200px] w-full"
                >
                  Contact Person Name
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >Emeka Guy</span
                >
              </div>
              <div
                class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] max-w-[200px] w-full"
                >
                  Email Address
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >emeka@abc.com</span
                >
              </div>
              <div
                class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] max-w-[200px] w-full"
                >
                  Phone Number
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >0808111111</span
                >
              </div>
            </div>
            <div
              class="bg-white px-[15px] py-[24px] sm:px-[24px] sm:py-[32px] mt-[16px] rounded-[4px]"
            >
              <h2
                class="font-semibold text-[20px] leading-[20px] text-[#353535]"
              >
                Bank Information
              </h2>
              <div
                class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] max-w-[200px] w-full"
                >
                  Bank
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >Access Bank</span
                >
              </div>
              <div
                class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] max-w-[200px] w-full"
                >
                  Account Number
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >1234567890</span
                >
              </div>
            </div>
          </div>

          <!-- advanced settings -->
          <div
            v-if="activeTab === 'settings'"
            class="bg-white px-[15px] py-[24px] sm:px-[24px] sm:py-[32px] mt-[16px] rounded-[4px]"
          >
            <div class="w-full flex justify-start items-start gap-[16px]">
              <h2
                :class="[
                  'text-[14px] font-semibold leading-[20px] cursor-pointer',
                  {
                    'border-b-2 border-[#2463EB] text-[#2463EB] pb-[5px]':
                      activeSubTab === 'priceAssociation',
                    'text-[#96a0b5]': activeSubTab !== 'priceAssociation',
                  },
                ]"
                @click="switchSubTab('priceAssociation')"
              >
                Price Association
              </h2>
              <h2
                :class="[
                  'text-[14px] font-semibold leading-[20px] cursor-pointer',
                  {
                    'border-b-2 border-[#2463EB] text-[#2463EB] pb-[5px]':
                      activeSubTab === 'productCatalogue',
                    'text-[#96a0b5]': activeSubTab !== 'productCatalogue',
                  },
                ]"
                @click="switchSubTab('productCatalogue')"
              >
                Product Catalogue Permisions
              </h2>
            </div>

            <div v-if="activeSubTab === 'priceAssociation'">
              <div class="table-responsive mt-6">
                <table class="table p-0 m-0 dataTable no-footer">
                  <thead class="bg-white">
                    <tr class="py-[5px]">
                      <th
                        class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                      >
                        Product Code
                      </th>
                      <th
                        class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                      >
                        Product Name
                      </th>
                      <th
                        class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                      >
                        Distributor Price
                      </th>
                      <th
                        class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                      >
                        Recommended Retail Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="op in priceOptions" :key="op.id">
                      <td class="text-[14px] font-normal leading-[16.94px]">
                        {{ op.product.code }}
                      </td>
                      <td class="text-[14px] font-normal leading-[16.94px]">
                        {{ op.product.name }}
                      </td>
                      <td class="text-[14px] font-normal leading-[16.94px]">
                        &#8358;
                        {{ formatMoney(op.product.distributorPrice) }}
                      </td>
                      <td class="text-[14px] font-normal leading-[16.94px]">
                        &#8358; {{ formatMoney(op.product.retailPrice) }}
                      </td>
                    </tr>
                    <tr v-if="!priceOptions.length">
                      <td colspan="7">
                        <div
                          class="text-center"
                          style="padding-top: 50px"
                        ></div>
                        <h3
                          class="text-center pt-[32px] text-[14px] font-normal leading-[16.94px]"
                          style="padding-bottom: 100px"
                        >
                          There are no price options associated with this
                          distributor
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div v-if="activeSubTab === 'productCatalogue'">
              <div class="mt-6">
                <div class="w-full pb-[24px] border-b border-[#EAECF0]">
                  <div
                    class="max-w-[500px] w-full flex items-center gap-[16px]"
                  >
                    <select
                      class="border border-[#D0D5DD] px-[14px] py-[12px] rounded-[8px] w-full text-[#96A0B5] outline-none custom-select custom-select-lg"
                    >
                      <option disabled selected>Select Permisions</option>
                      <option>No Restriction</option>
                      <option>Only Sell My Product</option>
                      <option>Only Sell Their Product</option>
                    </select>
                    <button
                      class="text-[#96a0b5] text-[14px] font-normal bg-[#2463EB] text-white py-[12px] px-[24px] rounded-[8px] cursor-pointer"
                    >
                      Update
                    </button>
                  </div>
                </div>
                <div class="mt-[24px] w-full">
                  <h2
                    class="font-semibold text-[18px] leading-[24px] text-[#353535] w-full"
                  >
                    Product Catalogue Settings
                  </h2>
                  <div class="max-w-[400px] w-full mt-[24px]">
                    <label
                      for="permission"
                      class="text-[16px] leading-[24px] font-medium text-[#353535]"
                      >Product Permision</label
                    >
                    <br />
                    <select
                      id="permission"
                      class="border border-[#D0D5DD] px-[14px] py-[12px] rounded-[8px] w-full text-[#96A0B5] outline-none custom-select custom-select-lg"
                    >
                      <option disabled selected>Select Permisions</option>
                      <option>Only Sell My Product</option>
                      <option>Only Sell Their Product</option>
                    </select>
                  </div>
                  <div class="max-w-[400px] w-full mt-[16px]">
                    <label
                      for="price"
                      class="text-[16px] leading-[24px] font-medium text-[#353535]"
                      >Product Price Options View</label
                    >
                    <br />
                    <select
                      id="price"
                      class="border border-[#D0D5DD] px-[14px] py-[12px] rounded-[8px] w-full text-[#96A0B5] outline-none custom-select custom-select-lg"
                    >
                      <option disabled selected>Select Permisions</option>
                      <option>No Restriction</option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                  </div>
                  <button
                    class="text-[#96a0b5] text-[14px] mt-[16px] font-normal bg-[#2463EB] text-white py-[12px] px-[24px] rounded-[8px] cursor-pointer"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";
import SystemPermissions from "@/permissions";
import { helper } from "@/helper";

export default {
  mixins: [SystemPermissions.Mixin],
  props: ["distributors", "viewDetails", "refreshPage"],
  data() {
    return {
      isModalVisible: false,
      selectedDistributor: {},
      activeTab: "profile",
      activeSubTab: "priceAssociation",
      priceOptions: [],
      pageNumber: 0,
      pageSize: 10,
      name: "",
      active: "",
      code: "",
    };
  },
  methods: {
    async getDistributor(id) {
      this.isBusy = true;
      try {
        const response = await this.$http.get("/Accounts/getById/" + id);

        this.isBusy = false;
        if (response.ok) {
          return response.body;
        } else {
          throw {};
        }
      } catch (error) {
        this.isBusy = false;
      }
    },
    async getGroupPriceOptions(id) {
      this.isBusy = true;
      try {
        const response = await this.$http.get("/Groups/getPriceOptions/" + id);

        if (response.ok && response.status === 200) {
          let body = await response.json();

          this.priceOptions = body.data;
          this.pageNumber = body.pageNumber;
          this.pageSize = body.pageSize;
          this.count = body.count;
          this.hasPrev = body.hasPrev;
          this.hasNext = body.hasNext;
        }
        this.isBusy = false;
      } catch (error) {
        // console.log(error);
        this.isBusy = false;
      }
    },
    async getGroup() {
      this.isBusy = true;

      try {
        const response = await this.$http.get(
          "/Groups/getById/" + "f42f4018-9d15-4633-a209-9f1b995dae3f"
        );
        if (response.ok) this.group = response.body;
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },
    formatDate(date) {
      return moment(date).format("ll");
    },
    formatMoney(val) {
      return helper.formatMoney(val);
    },
    async viewDistributor(distributor) {
      this.selectedDistributor = distributor;
      this.isModalVisible = true;
      this.name = this.selectedDistributor.name;
      this.code = this.selectedDistributor.code;
      this.active = this.selectedDistributor.active;
      const accountInfo = await this.getDistributor(
        this.selectedDistributor.id
      );
      let groupId = accountInfo.group.id;
      await this.getGroupPriceOptions(groupId);
    },
    closeModal() {
      this.isModalVisible = false;
      document.body.style.overflow = "auto";
      this.activeTab = "profile";
    },
    openModal() {
      this.isModalVisible = true;
      document.body.style.overflow = "hidden";
    },
    switchTab(tab) {
      this.activeTab = tab;
    },
    switchSubTab(subTab) {
      this.activeSubTab = subTab;
    },
  },
};
</script>

<style scoped>
#popup-content::-webkit-scrollbar {
  width: 0.1px;
  height: 5px;
}

#popup-content::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

#popup-content::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

#popup-content::-webkit-scrollbar-thumb:hover {
  background-color: #55555563;
}
</style>
