<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
            <span class="feather icon-filter text-white"></span> Filter
          </button>

          <!-- <button class="btn btn-secondary btn-rounded btn-space float-right" @click="viewExport">
                      <span class="icon icon-left mdi mdi-arrow-top-right text-primary"></span> Export
                    </button>-->

          <button
            class="btn btn-primary btn-rounded btn-space float-right"
            @click="viewAdd"
            v-if="hasPermission('Branch_Create')"
          >
            <span class="icon icon-left mdi mdi-plus text-white"></span> New Branch
          </button>
        </div>
        <div class="card-body">
          <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer text-nowrap">
                  <thead>
                    <tr>
                      <th style="width:20%;">Name</th>
                      <th style="width:30%;">Address</th>
                      <th style="width:10%;">Phone</th>
                      <th style="width:10%;">Email</th>
                      <th style="width:10%;">Status</th>
                      <!--<th style="width:20%;">Parent</th>-->
                      <th style="width:10%;">Created</th>
                      <th style="width:10%;"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="l in Branches" :key="l.id">
                      <td>
                        {{ l.name }}
                      </td>
                      <td>
                        {{ l.address }}
                      </td>
                      <td>
                        {{ l.phoneNumber }}
                      </td>
                      <td>
                        {{ l.email }}
                      </td>
                      <td>
                        <span
                          :class="[
                            'badge',
                            ' badge-pill',
                            { 'badge-success': l.active },
                            { 'badge-danger': !l.active },
                          ]"
                        >
                          {{ l.active ? "Active" : "Inactive" }}
                        </span>
                      </td>
                      <!--<td>{{ l.parent? l.parent.name : 'System' }}</td>-->
                      <td>{{ formatDate(l.created) }}</td>
                      <td>
                        <button
                          class="
                              btn btn-outline-primary btn-sm
                              dropdown-toggle
                            "
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Actions
                        </button>
                        <div class="dropdown-menu px-1" aria-labelledby="dropdownMenu2">
                          <div v-if="hasPermission('Branch_Update')">
                            <a
                              class="dropdown-item"
                              @click.prevent="viewEdit(l)"
                              v-if="l.parentId == user.parentId"
                            >
                              <i class="feather icon-edit"></i> Edit
                            </a>
                          </div>
                          <a
                            class="dropdown-item"
                            href="#"
                            v-if="hasPermission('Branch_Delete')"
                            style="color: red"
                            @click.prevent="confirmDelete(l.id)"
                          >
                            Delete Branch
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!Branches.length">
                      <td colspan="7">
                        <div class="text-center" style="padding-top:50px;">
                          <span style="font-size: 4.615rem;" class="mdi mdi-crosshairs-gps"></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom:100px;">
                          You don't have any branches.
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Pagination
                  :limit="pageSize"
                  :count="pageCount"
                  :page="page"
                  :itemsCount="count"
                  @changePage="gotoPage"
                  @changeLimit="changeLimit"
                />
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">{{ count }} branch{{ count != 1 ? "es" : "" }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-add" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Create new branch</b></h4>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.name }]"
                  type="text"
                  v-model="createBranch.name"
                />
                <ul v-if="v1.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Address</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.address }]"
                  type="text"
                  v-model="createBranch.address"
                />
                <ul v-if="v1.address" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Email</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.email }]"
                  type="text"
                  v-model="createBranch.email"
                />
                <ul v-if="v1.email" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
                <ul v-if="v1.emailAddressValid" class="parsley-errors-list filled">
                  <li class="parsley-required">Enter a valid email address.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Phone</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.phone }]"
                  type="text"
                  v-model="createBranch.phone"
                />
                <ul v-if="v1.phone" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
                <ul v-if="v1.phoneNumberValid" class="parsley-errors-list filled">
                  <li class="parsley-required">Enter a valid phone number.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Active</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <label class="custom-control custom-checkbox custom-control-inline">
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    v-model="createBranch.active"
                  />
                  <span class="custom-control-label">This branch is active</span>
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px;"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 130px;"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="create"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Add branch</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-edit" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Edit branch</b></h4>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.name }]"
                  type="text"
                  v-model="updateBranch.name"
                />
                <ul v-if="v2.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Address</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.address }]"
                  type="text"
                  v-model="updateBranch.address"
                />
                <ul v-if="v2.address" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Email</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.email }]"
                  type="text"
                  v-model="updateBranch.email"
                />
                <ul v-if="v2.email" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
                <ul v-if="v2.emailAddressValid" class="parsley-errors-list filled">
                  <li class="parsley-required">Enter a valid email address.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Phone</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.phone }]"
                  type="text"
                  v-model="updateBranch.phone"
                />
                <ul v-if="v2.phone" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
                <ul v-if="v2.phoneNumberValid" class="parsley-errors-list filled">
                  <li class="parsley-required">Enter a valid phone number.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Active</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <label class="custom-control custom-checkbox custom-control-inline">
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    v-model="updateBranch.active"
                  />
                  <span class="custom-control-label">This branch is active</span>
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px;"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px;"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="update"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-filter" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Filter</h3>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Status</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select class="form-control" placeholder="Select an option" v-model="filter.status">
                  <option value="0">All Branches</option>
                  <option value="True">Active</option>
                  <option value="False">Disabled</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Other filters</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Filter by name..."
                  v-model="filter.qry"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px;"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px;"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button>
            <button
              style="width: 100px;"
              class="btn btn-primary md-close"
              type="button"
              @click="getBranches"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
    <GeneralDeleteCaution
      :id="DeleteId"
      @proceed="deleteBranch"
      :isBusy="isBusyModal"
      text="branch"
    />
    <GeneralDeleteMessage
      message="You cannot delete this Branch because there are staff or 
      product orders associated with it. Consider disabling it instead."
    />
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import Select2 from "@/components/Select2";
import GeneralDeleteCaution from "../components/GeneralDeleteCaution.vue";
import GeneralDeleteMessage from "../components/GeneralDeleteMessage.vue";
import Pagination from "../components/Pagination.vue";
import SystemPermissions from "@/permissions";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsBranches",
  components: {
    Select2,
    GeneralDeleteCaution,
    GeneralDeleteMessage,
    Pagination,
  },
  created() {
    if (!this.hasPermission("Branch_View")) {
      this.$router.push("/restricted");
    }
    this.getBranches();
  },
  watch: {},
  data() {
    return {
      filter: {
        status: "0",
        qry: "",
      },
      isBusy: false,
      isBusyModal: false,
      createBranch: {
        name: null,
        address: null,
        phone: null,
        email: null,
        active: null,
      },
      updateBranch: {
        name: null,
        address: null,
        phone: null,
        email: null,
        active: true,
        id: null,
      },
      v1: {
        name: false,
        address: false,
        emailAddressValid: false,
        phoneNumberValid: false,
      },
      v2: {
        name: false,
        address: false,
        emailAddressValid: false,
        phoneNumberValid: false,
      },
      Branches: [],
      DeleteId: "",
      count: 0,
      page: 0,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
    };
  },
  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    reset() {
      this.filter = {
        status: "0",
        qry: "",
      };
    },
    confirmDelete(id) {
      this.DeleteId = id;
      $("#mod-confirm-delete").modal("show");
    },

    showDeleteMsg() {
      $("#mod-delete-msg").modal("show");
    },

    cancelConfirm() {
      this.DeleteId = "";
      $("#mod-confirm-delete").modal("hide");
    },
    async deleteBranch() {
      this.isBusyModal = true;
      try {
        const res = await this.$http.delete(`/Branches/${this.DeleteId}`);
        if (res.ok) {
          this.$toast.success("Branch deleted successfully", {
            icon: true,
            rtl: false,
          });
          this.getBranches();
          this.cancelConfirm();
        }
      } catch (error) {
        this.cancelConfirm();
        this.showDeleteMsg();
      }
      this.isBusyModal = false;
    },
    viewExport() {
      //   $("#mod-export").modal("show");
    },
    viewAdd() {
      this.createBranch = {
        name: null,
        address: null,
        phone: null,
        email: null,
        active: false,
      };
      this.v1 = {
        name: false,
        address: false,
      };
      $("#mod-add").modal("show");
    },
    viewEdit(c) {
      this.updateBranch = c;
      this.updateBranch.phone = c.phoneNumber;
      $("#mod-edit").modal("show");
    },
    viewFilter() {
      this.page = 0;
      $("#mod-filter").modal("show");
    },
    async create() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        name: this.createBranch.name,
        address: this.createBranch.address,
        email: this.createBranch.email,
        phone: this.createBranch.phone,
        active: this.createBranch.active,
        parentId: this.user.parentId,
      };

      if (this.validateCreate()) {
        $("#mod-add").modal("hide");

        await this.$http
          .post("/Branches/create", data)
          .then(function(res) {
            this.isBusyModal = false;
          })
          .catch(function() {});

        this.getBranches();
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },
    validateCreate() {
      this.v1 = {
        name: false,
        address: false,
      };
      var isValid = true;

      if (!this.createBranch.name) {
        this.v1.name = true;
        isValid = false;
      }

      if (!this.createBranch.address) {
        this.v1.address = true;
        isValid = false;
      }

      if (this.createBranch.email && !helper.validateEmail(this.createBranch.email)) {
        this.v1.emailAddressValid = true;
        isValid = false;
      }

      if (this.createBranch.phone && !helper.validatePhone(this.createBranch.phone)) {
        this.v1.phoneNumberValid = true;
        isValid = false;
      }

      return isValid;
    },
    async update() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        name: this.updateBranch.name,
        address: this.updateBranch.address,
        email: this.updateBranch.email,
        phone: this.updateBranch.phone,
        active: this.updateBranch.active,
        id: this.updateBranch.id,
      };

      if (this.validateUpdate()) {
        $("#mod-edit").modal("hide");

        await this.$http
          .put("/Branches/update/" + data.id, data)
          .then(function(res) {
            this.isBusyModal = false;
          })
          .catch(function() {});

        this.getBranches();
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },
    validateUpdate() {
      this.v2 = {
        name: false,
      };
      var isValid = true;

      if (!this.updateBranch.name) {
        this.v2.name = true;
        isValid = false;
      }

      if (!this.updateBranch.address) {
        this.v2.address = true;
        isValid = false;
      }

      if (this.updateBranch.email && !helper.validateEmail(this.updateBranch.email)) {
        this.v2.emailAddressValid = true;
        isValid = false;
      }

      if (this.updateBranch.phone && !helper.validatePhone(this.updateBranch.phone)) {
        this.v2.phoneNumberValid = true;
        isValid = false;
      }

      return isValid;
    },
    async getBranches() {
      this.isBusy = true;

      await this.$http
        .get(
          "/Branches/getList?page=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            (this.filter.status != "0" ? "&active=" + this.filter.status : "") +
            (this.filter.qry != "" ? "&query=" + this.filter.qry : "") +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "")
        )
        .then(function(res) {
          this.Branches = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },
    formatDate(date) {
      return moment(date).format("lll");
    },
    formatMoney(val) {
      return helper.formatMoney(val);
    },
    prev() {
      this.page -= 1;
      this.getBranches();
    },
    next() {
      this.page += 1;
      this.getBranches();
    },

    gotoPage(page) {
      this.page = page - 1;
      this.getBranches();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getBranches();
    },
  },
};
</script>
