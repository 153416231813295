<template>
  <div
    class="fixed inset-0 bg-white w-full min-h-screen flex items-center justify-center z-[10000000]"
  >
    <div class="flex flex-col gap-[5px] justify-center items-center">
      <span
        class="fa-solid fa-spinner fa-spin text-[#2463EB]"
        style="font-size: 8rem"
      ></span>
      <p
        class="mt-1 text-[#353535] text-[16px] text-center font-medium leading-[20px]"
      >
        Wait a moment while we load your page
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
