<template>
  <div class="row">
    <div class="col">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header d-flex justify-content-between">
          <p class="card-title">{{ group.name }} (Group)</p>
          <div class="btn-group" role="group" aria-label="Basic example">
            <button
              v-if="hasPermission('Price_Option_Create')"
              class="btn btn-primary btn-rounded btn-space"
              @click="addPriceOption"
            >
              <i class="icon icon-left mdi mdi-plus text-white"></i>
              Add Price Option
            </button>
            <button class="btn btn-info btn-rounded btn-space" @click="filter">
              <i class="feather icon-filter text-white"></i>
              Filter
            </button>
            <button
              class="btn btn-success btn-rounded btn-space"
              @click="goBack"
            >
              <i class="mdi icon mdi-backburger text-white"></i>
              Back
            </button>
          </div>
        </div>
        <div class="card-body">
          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Product Code</th>
                      <th>Applied Price Option</th>
                      <th>Distributor Price</th>
                      <th>Retail Price</th>
                      <th>Added</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="op in priceOptions" :key="op.id">
                      <td>{{ op.product.name }}</td>
                      <td>{{ op.product.code }}</td>
                      <td>{{ op.priceOption.name }}</td>
                      <td>
                        &#8358;
                        {{ formatMoney(op.priceOption.distributorPrice) }}
                      </td>
                      <td>
                        &#8358; {{ formatMoney(op.priceOption.retailPrice) }}
                      </td>
                      <td>{{ formatDate(op.created) }}</td>
                      <td>
                        <SwitchStatus
                          :distributorPrice="op"
                          @onComplete="onActionComplete"
                        />
                      </td>
                      <td>
                        <div class="d-flex">
                          <router-link
                            :to="{
                              path: `/manufacturer-products/${op.productId}/manage-price-option`,
                            }"
                            data-toggle="tooltip"
                            title="Edit"
                            class="
                              btn btn-primary btn-sm btn-rounded btn-space
                              mr-1
                            "
                          >
                            View
                          </router-link>
                          <DeleteAssociation
                            v-if="hasPermission('Price_Option_Delete')"
                            :id="op.id"
                            @onComplete="onActionComplete"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }} Price option{{ count > 1 ? "s" : "" }}
                </div>
              </div>
              <div class="col-sm-7">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination">
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasPrev },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="prev"
                        >Previous</a
                      >
                    </li>
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasNext },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="next"
                        >Next</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddPriceOption
      :groupId="groupId"
      @hide="hideModal"
      @onComplete="onComplete"
    />
    <FilterPriceOptionAssociation @onFilter="performFiltering" />
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import { helper } from "@/helper";
import AddPriceOption from "../../components/Group/PriceOption/AddPriceOption";
import DeleteAssociation from "../../components/Group/PriceOption/DeleteAssociation";
import SwitchStatus from "../../components/Group/PriceOption/SwitchAssociationStatus";
import FilterPriceOptionAssociation from "../../components/Group/PriceOption/FilterPriceOptionAssociation";
import SystemPermissions from "@/permissions";

export default {
  mixins: [SystemPermissions.Mixin],
  components: {
    AddPriceOption,
    DeleteAssociation,
    SwitchStatus,
    FilterPriceOptionAssociation,
  },

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      groupId: "",
      group: {},
      priceOptions: [],
      count: 0,
      pageNumber: 0,
      pageSize: 10,
      hasNext: false,
      hasPrev: false,
      name: "",
      active: "",
      code: "",
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  created() {
    if (!this.hasPermission("Price_Option_View")) {
      this.$router.push("/restricted");
    }
    this.groupId = this.$route.params.id;
    this.getGroup();
    this.getGroupPriceOptions();
  },

  methods: {
    async getGroup() {
      this.isBusy = true;

      try {
        const response = await this.$http.get(
          "/Groups/getById" + "?id=f42f4018-9d15-4633-a209-9f1b995dae3f"
        );
        if (response.ok) this.group = response.body;
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    hideModal() {
      $("#mod-add-price-option").modal("hide");
    },

    async getGroupPriceOptions() {
      this.isBusy = true;

      try {
        const response = await this.$http.get(
          "/Groups/getPriceOptions/" +
            this.groupId +
            "?pageNumber=" +
            this.pageNumber +
            "&pageSize=" +
            this.pageSize +
            "&name=" +
            this.name +
            "&code=" +
            this.code +
            "&active=" +
            this.active
        );

        if (response.ok && response.status === 200) {
          this.priceOptions = response.body.data;
          this.pageNumber = response.body.pageNumber;
          this.pageSize = response.body.pageSize;
          this.count = response.body.count;
          this.hasPrev = response.body.hasPrev;
          this.hasNext = response.body.hasNext;
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    onActionComplete() {
      this.getGroupPriceOptions();
    },

    onComplete() {
      this.hideModal();
      this.getGroupPriceOptions();
    },

    addPriceOption() {
      $("#mod-add-price-option").modal("show");
    },

    filter() {
      this.pageNumber = 0;
      $("#mod-rrp-filter").modal("show");
    },

    async performFiltering(filteringOptions) {
      this.name = filteringOptions.name;
      this.code = filteringOptions.code;
      this.active =
        filteringOptions.active == "empty"
          ? ""
          : filteringOptions.active == "true";

      this.getGroupPriceOptions();
      $("#mod-rrp-filter").modal("hide");
    },

    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },

    formatDate(date) {
      if (date) return moment(date).format("lll");
      else return "";
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    prev() {
      this.pageNumber -= 1;
      this.getGroupPriceOptions();
    },

    next() {
      this.pageNumber += 1;
      this.getGroupPriceOptions();
    },
  },
};
</script>

<style></style>
