<template>
  <div class="modal fade" id="mod-delete-msg" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content p-[24px] bg-[#f8f8f8]">
        <div class="modal-body p-0 m-0">
          <p class="text-center">{{ message }}</p>
          <div class="mt-[16px] d-flex justify-content-end align-items-center">
            <button
              style="width: 150px"
              class="border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full"
              type="button"
              data-dismiss="modal"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneralDeleteMessage",
  props: ["message"],
  methods: {},
};
</script>
