<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button
            class="btn btn-success btn-rounded btn-space"
            @click="viewFilter"
          >
            <i class="feather icon-filter text-white"></i> Filter
          </button>
          <span>&nbsp;</span>
          <button
            v-if="hasPermission('Product_Category_Create')"
            class="btn btn-primary btn-rounded btn-space float-right"
            @click="viewAdd"
          >
            <i class="feather icon-plus text-white"></i> &nbsp;New item
          </button>
        </div>
        <div class="card-body">
          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table
                  class="
                    table table-fw-widget
                    dataTable
                    no-footer
                    text-nowrap
                    table-hover-animation table-striped
                  "
                >
                  <thead>
                    <tr>
                      <th style="width: 30%">Name</th>
                      <th style="width: 10%">Status</th>
                      <th style="width: 25%">Item Type</th>
                      <th style="width: 25%">Created</th>
                      <th style="width: 10%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="cat in Categories" :key="cat.id">
                      <td>{{ cat.name }}</td>
                      <td>
                        <span
                          :class="[
                            'badge',
                            ' badge-pill',
                            { 'badge-success': cat.active },
                            { 'badge-danger': !cat.active },
                          ]"
                          >{{ cat.active ? "Active" : "Inactive" }}</span
                        >
                      </td>
                      <td>{{ cat.type }}</td>
                      <td>{{ formatDate(cat.created) }}</td>
                      <td>
                        <button
                          class="
                              btn btn-outline-primary btn-sm
                              dropdown-toggle
                            "
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Actions
                        </button>
                        <div
                          class="dropdown-menu px-1"
                          aria-labelledby="dropdownMenu2"
                        >
                          <a
                            class="dropdown-item"
                            v-if="
                              cat.parentId == user.parentId &&
                                hasPermission('Product_Category_Update')
                            "
                            @click.prevent="viewEdit(cat)"
                          >
                            <i class="feather icon-edit"></i> &nbsp;Edit
                          </a>
                          <a
                            class="dropdown-item"
                            v-if="hasPermission('Product_Unit_Delete')"
                            href="#"
                            style="color: red"
                            @click.prevent="confirmDelete(cat.id)"
                          >
                            Delete Category
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!Categories.length">
                      <td colspan="6">
                        <div class="text-center">No matching records</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Pagination
                  :limit="pageSize"
                  :count="pageCount"
                  :page="page"
                  :itemsCount="count"
                  @changePage="gotoPage"
                  @changeLimit="changeLimit"
                />
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }} item{{ count != 1 ? "s" : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-add"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><strong>Create new item</strong></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Name</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.name }]"
                  type="text"
                  v-model="createCategory.name"
                />
                <ul v-if="v1.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Item Type</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="createCategory.type"
                >
                  <!-- <select :class="['form-control', { 'is-invalid':v1.type }]" v-model="createCategory.type"> -->
                  <option v-for="type in types" :key="type" :value="type">
                    {{ type }}
                  </option>
                </select>
                <ul v-if="v1.type" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Active</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <label
                  class="custom-control custom-checkbox custom-control-inline"
                >
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    v-model="createCategory.active"
                  />
                  <span class="custom-control-label">This item is active</span>
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 130px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="create"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Add item</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><strong>Edit item</strong></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              @click="cancel"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Name</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.name }]"
                  type="text"
                  v-model="updateCategory.name"
                />
                <ul v-if="v2.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Item Type</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="updateCategory.type"
                >
                  <!-- <select :class="['form-control', { 'is-invalid':v2.type }]" v-model="updateCategory.type"> -->
                  <option v-for="type in types" :key="type" :value="type">
                    {{ type }}
                  </option>
                </select>
                <ul v-if="v2.type" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Active</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <label
                  class="custom-control custom-checkbox custom-control-inline"
                >
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    v-model="updateCategory.active"
                  />
                  <span class="custom-control-label">This item is active</span>
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
              @click="cancel"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="update"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-filter"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><strong>Filter</strong></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row align-items-center">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">
                Creation <br />
                Start Date:</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control']"
                  type="date"
                  :max="maxDate"
                  placeholder="Select a start date"
                  v-model="filter.startDate"
                />
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">
                Creation <br />
                End Date:</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control']"
                  type="date"
                  :max="maxDate"
                  placeholder="Select a end date"
                  v-model="filter.endDate"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Status</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="select2 form-control"
                  placeholder="Select an option"
                  v-model="filter.status"
                >
                  <option
                    v-for="opt in options"
                    :key="opt.text"
                    :value="opt.value"
                  >
                    {{ opt.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Name</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Filter by product name..."
                  v-model="filter.name"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="resetFilter"
            >
              Reset
            </button>
            <button
              style="width: 100px"
              class="btn btn-primary md-close"
              type="button"
              @click.prevent="getCategories"
            >
              <span v-if="isBusy">Busy...</span>
              <span v-else>Search</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <GeneralDeleteCaution
      :id="DeleteId"
      @proceed="deleteProductCategory"
      :isBusy="isBusyModal"
      text="product category"
    />
    <GeneralDeleteMessage
      message="You cannot delete this product category because there is a product history associated with it."
    />
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import Select2 from "@/components/Select2";
import GeneralDeleteCaution from "../components/GeneralDeleteCaution.vue";
import GeneralDeleteMessage from "../components/GeneralDeleteMessage.vue";
import SystemPermissions from "@/permissions";
import Pagination from "../components/Pagination.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsLists",
  components: {
    Select2,
    GeneralDeleteCaution,
    GeneralDeleteMessage,
    Pagination,
  },
  created() {
    if (!this.hasPermission("Product_Category_View")) {
      this.$router.push("/restricted");
    }
    this.getCategories();
    this.getTypes();
  },
  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      filter: {
        name: "",
        status: "",
        startDate: "",
        endDate: "",
      },
      createCategory: {
        name: null,
        type: null,
        active: false,
        parentId: null,
      },
      updateCategory: {
        name: null,
        type: null,
        active: false,
        id: null,
      },
      editCategory: {
        name: null,
        type: null,
        active: false,
        id: null,
      },
      v1: {
        name: false,
        type: false,
      },
      v2: {
        name: false,
        type: false,
      },
      Categories: [],
      DeleteId: "",
      types: [],
      count: 0,
      page: 1,
      pageSize: 10,
      pageNumber: 0,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
      maxDate: "",
      options: [
        { text: "All", value: "" },
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
    };
  },
  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },
  mounted() {
    this.maxDate = helper.preventFutureDateSelection();
  },
  methods: {
    resetFilter() {
      this.filter = {
        name: "",
        status: "",
        startDate: "",
        endDate: "",
      };
      this.getCategories();
    },

    viewFilter() {
      this.pageNumber = 0;
      $("#mod-filter").modal("show");
    },
    cancel() {
      const currentCategory = this.Categories.find(
        (c) => c.id === this.editCategory.id
      );
      currentCategory.name = this.editCategory.name;
      currentCategory.type = this.editCategory.type;
      currentCategory.active = this.editCategory.active;
      currentCategory.id = this.editCategory.id;
      $("#mod-edit").modal("hide");
    },

    confirmDelete(id) {
      this.DeleteId = id;
      $("#mod-confirm-delete").modal("show");
    },

    showDeleteMsg() {
      $("#mod-delete-msg").modal("show");
    },

    cancelConfirm() {
      this.DeleteId = "";
      $("#mod-confirm-delete").modal("hide");
    },
    async deleteProductCategory() {
      this.isBusyModal = true;
      try {
        const res = await this.$http.delete(`/Categories/${this.DeleteId}`);
        if (res.ok) {
          this.$toast.success("Product Category deleted successfully", {
            icon: true,
            rtl: false,
          });
          this.getCategories();
          this.cancelConfirm();
        }
      } catch (error) {
        this.cancelConfirm();
        this.showDeleteMsg();
      }
      this.isBusyModal = false;
    },

    viewAdd() {
      this.createCategory = {
        name: null,
        type: null,
        active: false,
        parentId: null,
      };
      this.v1 = {
        name: false,
        type: false,
      };

      $("#mod-add").modal("show");
    },

    viewEdit(c) {
      var editValues = {
        name: c.name,
        type: c.type,
        active: c.active,
        id: c.id,
      };
      this.editCategory = editValues;
      this.updateCategory = { ...c };
      $("#mod-edit").modal("show");
    },

    viewFilter() {
      $("#mod-filter").modal("show");
    },

    create() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        name: this.createCategory.name,
        type: this.createCategory.type,
        active: this.createCategory.active,
        parentId: this.user.parentId,
      };

      if (this.validateCreate()) {
        $("#mod-add").modal("hide");

        this.$http
          .post("/Categories/create", data)
          .then(function(res) {
            this.isBusyModal = false;
            this.$toast.success("Category created successfully.", {
              icon: true,
              rtl: false,
            });
            this.getCategories();
          })
          .catch(function() {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    validateCreate() {
      this.v1 = {
        name: false,
        type: false,
      };
      var isValid = true;

      if (!this.createCategory.name) {
        this.v1.name = true;
        isValid = false;
      }

      if (!this.createCategory.type) {
        this.v1.type = true;
        isValid = false;
      }
      return isValid;
    },

    update() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        name: this.updateCategory.name,
        type: this.updateCategory.type,
        active: this.updateCategory.active,
        id: this.updateCategory.id,
      };

      if (this.validateUpdate()) {
        $("#mod-edit").modal("hide");

        this.$http
          .put("/Categories/update/" + data.id, data)
          .then(function(res) {
            this.isBusyModal = false;
            this.getCategories();
          })
          .catch(function() {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    validateUpdate() {
      this.v2 = {
        name: false,
        type: false,
      };
      var isValid = true;

      if (!this.updateCategory.name) {
        this.v2.name = true;
        isValid = false;
      }

      if (!this.updateCategory.type) {
        this.v2.type = true;
        isValid = false;
      }

      return isValid;
    },

    getCategories() {
      this.isBusy = true;
      let type = "Product Category";
      var t = this.$route.query.t;
      var url = "/Categories/getList";
      if (t == "d") url = "/Categories/getListForDistributor";
      let pageParam = t == "d" ? "page" : "pageNumber";
      let pageValue = t == "d" ? this.page - 1 : this.page;
      this.$http
        .get(
          url +
            `?${pageParam}=` +
            pageValue +
            "&pageSize=" +
            this.pageSize +
            "&type=" +
            type +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "") +
            (this.filter.name !== " " ? "&Name=" + this.filter.name : "") +
            (this.filter.startDate !== " "
              ? "&StartDate=" + this.filter.startDate
              : "") +
            (this.filter.endDate !== " "
              ? "&EndDate=" + this.filter.endDate
              : "") +
            (this.filter.status !== "" ? "&Active=" + this.filter.status : "")
        )
        .then(function(res) {
          this.Categories = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
          $("#mod-filter").modal("hide");
          // this.filter = {
          //   name:'',
          //   status:"",
          //   startDate:'',
          //   endDate:''
          // }
        })
        .catch(function() {
          this.isBusy = false;
        });
    },

    getTypes() {
      this.types = ["Product Category"];
    },

    formatDate(date) {
      return moment(date).format("lll");
    },

    prev() {
      this.page -= 1;
      this.getCategories();
    },

    next() {
      this.page += 1;
      this.getCategories();
    },

    gotoPage(page) {
      this.page = page;
      this.getCategories();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getCategories();
    },
  },
};
</script>
