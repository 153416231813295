<template>
  <div
    id="sidebar"
    class="main-menu s menu-fixed menu-light menu-accordion menu-shadow"
    data-scroll-to-active="true"
  >
    <!-- lock button -->
    <div class="navbar-header">
      <ul class="nav navbar-nav flex-row justify-end">
        <li class="nav-item nav-toggle">
          <a class="nav-link modern-nav-toggle pr-0" data-toggle="collapse">
            <i
              class="feather icon-x d-block d-xl-none font-medium-4 primary toggle-icon"
            ></i
            ><i
              class="toggle-icon feather icon-disc font-medium-4 d-none d-xl-block collapse-toggle-icon primary"
              data-ticon="icon-disc"
            ></i>
          </a>
        </li>
      </ul>
    </div>
    <!-- lock button -->

    <div class="main-menu-content">
      <ul
        class="navigation navigation-main"
        id="main-menu-navigation"
        data-menu="menu-navigation"
      >
        <!-- start of customer nav -->
        <li
          :class="[
            { parent: user.role == 'Customer' },
            { active: isDashboardActive },
          ]"
        >
          <router-link to="/dashboard">
            <i
              ><img
                style="max-width: none !important"
                src="/assets/img/category.svg"
                alt=""
            /></i>
            <span
              class="text-[#353535] text-[16px] leading-[19.67px] font-medium"
              >Dashboard</span
            >
          </router-link>
        </li>
        <li
          v-show="user.role == 'Customer'"
          :class="[
            { parent: user.role == 'Customer' },
            { active: isOrdersParentActive },
            {
              open:
                isOrdersParentActive ||
                isOrdersSentActive ||
                isOrdersCreateActive ||
                isOrdersDraftActive,
            },
          ]"
        >
          <router-link
            to="#"
            @click.native.prevent="handleParentMenuClick('Orders')"
          >
            <i class="feather icon-shopping-cart"></i>
            <span
              class="text-[#353535] text-[16px] leading-[19.67px] font-medium"
              >Orders</span
            >
          </router-link>

          <ul class="menu-content">
            <li :class="{ active: isOrdersSentActive }">
              <router-link to="/merchant/orders">My Orders</router-link>
            </li>
            <li :class="{ active: isOrdersCreateActive }">
              <router-link to="/merchant/orders/create">New Order</router-link>
            </li>
            <li :class="{ active: isOrdersDraftActive }">
              <router-link to="/merchant/drafts">Drafts</router-link>
            </li>
          </ul>
        </li>
        <!-- <li
          v-if="user.role == 'Customer'"
          :class="[
            { parent: user.role == 'Customer' },
            { active: isDistributorsActive },
            {
              open:
                isDistributorsActive ||
                isMerchantAddDistributorActive ||
                isMerchantViewDistributorActive,
            },
          ]"
        >
          <router-link
            to="#"
            @click.native.prevent="
              handleParentMenuClick('MerchantDistributors')
            "
          >
            <i class="icon mdi mdi-truck-fast"></i>
            <span
              class="text-[#353535] text-[16px] leading-[19.67px] font-medium"
              >Distributors</span
            >
          </router-link>
          <ul c class="menu-content">
            <li :class="{ active: isMerchantViewDistributorActive }">
              <router-link to="/merchant/distributors/view"
                >My Distributors</router-link
              >
            </li>
            <li :class="{ active: isMerchantAddDistributorActive }">
              <router-link to="/merchant/distributors/add"
                >Add New Distributors</router-link
              >
            </li>
          </ul>
        </li> -->
        <li
          :class="{ active: isGrowTradeActive }"
          v-if="user.role == 'Customer'"
        >
          <router-link to="/merchant/growtrade">GrowTrade Account</router-link>
        </li>
        <li
          v-if="
            !(user.type != 'Distributor' || user.role == 'Customer') &&
              (hasPermission('Orders_View') || hasPermission('Orders_Create'))
          "
          :class="[
            {
              parent:
                user.role == 'Administrator' ||
                user.role == 'Support Specialist' ||
                user.role == 'Sales',
            },
            {
              active:
                isOrdersParentActive ||
                isOrdersActive ||
                isOrdersCreateAltActive ||
                isOrdersCreateAltPayActive ||
                isIssuedStock,
            },
            {
              open:
                isOrdersParentActive ||
                isOrdersActive ||
                isOrdersCreateAltActive ||
                isOrdersCreateAltPayActive ||
                isIssuedStock,
            },
          ]"
        >
          <router-link
            to="#"
            @click.native.prevent="handleParentMenuClick('Orders')"
          >
            <i class="feather icon-shopping-cart"></i>
            <span>Manage Orders</span>
          </router-link>
          <ul
            class="menu-content"
            v-if="
              hasPermission('Orders_View') || hasPermission('Orders_Create')
            "
          >
            <li
              v-if="hasPermission('Orders_View')"
              :class="{ active: isOrdersActive }"
            >
              <router-link to="/orders">
                <i class="feather icon-circle"></i> &nbsp;View Orders
              </router-link>
            </li>
            <li
              v-if="hasPermission('Orders_Create')"
              :class="{ active: isOrdersCreateAltActive }"
            >
              <router-link to="/orders/create">
                <i class="feather icon-circle"></i> &nbsp;New Order
              </router-link>
            </li>
            <li
              v-if="hasPermission('Orders_Create')"
              :class="{ active: isOrdersCreateAltPayActive }"
            >
              <router-link to="/orders/create?pay4me=true">
                <i class="feather icon-circle"></i> &nbsp;Pay4Me Order
              </router-link>
            </li>
            <li :class="{ active: isIssuedStock }">
              <router-link to="/orders/issue-stock">
                <i class="feather icon-circle"></i> &nbsp;Issue Stock
              </router-link>
            </li>
          </ul>
        </li>
        <li
          v-if="
            (user.type == 'Distributor' &&
              user.role != 'Customer' &&
              user.role != 'Sales' &&
              hasPermission('Account_Posting')) ||
              hasPermission('Payment_Posting')
          "
          :class="[
            'parent',
            { active: isManageCustomerAccountParentActive },
            {
              open:
                isManageCustomerAccountParentActive ||
                isManagePaymentsActive ||
                isManagePaymentsNotify ||
                isAccountPosting,
            },
          ]"
        >
          <a
            href="javascript:void"
            v-if="!user.isSalesUser"
            @click.prevent="handleParentMenuClick('CustomerAccount')"
          >
            <i class="feather icon-book-open"></i>
            <span>Manage Payment</span>
          </a>
          <ul class="sub-menu">
            <li
              v-if="hasPermission('Account_Posting')"
              :class="{ active: isAccountPosting }"
            >
              <router-link to="/accountposting">
                <i class="feather icon-user"></i>
                <span>Account Posting</span>
              </router-link>
            </li>
            <li
              v-if="hasPermission('Payment_Posting')"
              :class="{ active: isManagePaymentsActive }"
            >
              <router-link to="/managepayments">
                <i class="feather icon-credit-card"></i>
                <span>Post Payments</span>
              </router-link>
            </li>
            <li
              v-if="hasPermission('View_View_Payments_Notifications')"
              :class="{ active: isManagePaymentsNotify }"
            >
              <router-link to="/payment-notification">
                <i class="feather icon-credit-card"></i>
                <span
                  >Payments Notifica...
                  <span
                    v-if="PaymentNotificationCount > 0"
                    class="badge badge-danger"
                    style="border-radius:50%; padding:4px"
                    >{{
                      PaymentNotificationCount >= 10
                        ? "10+"
                        : PaymentNotificationCount
                    }}</span
                  ></span
                >
              </router-link>
            </li>
          </ul>
        </li>
        <!-- end of customer nav -->

        <!-- start of distributor nav -->

        <!-- end of distributor  -->

        <!-- start of system -->
        <li
          v-if="user.type == 'System'"
          :class="[
            'parent',
            { active: isManufacturersActive },
            { open: isManufacturersActive },
          ]"
        >
          <router-link to="/manufacturers">
            <i class="feather icon-user"></i>
            <span>Manufacturers</span>
          </router-link>
          <ul class="sub-menu">
            <li :class="{ active: isManufacturersCreateActive }">
              <router-link to="/manufacturers/create">
                <i class="feather icon-circle"></i> &nbsp; New Manufacturer
              </router-link>
            </li>
          </ul>
        </li>
        <li
          v-if="user.type == 'System'"
          :class="['parent', { active: isStatisticsActive }]"
        >
          <router-link to="/statistics">
            <i class="feather icon-bar-chart"></i>
            <span>Statistics</span>
          </router-link>
        </li>

        <li
          v-if="user.type == 'System'"
          :class="[
            'parent',
            { active: isDistributorsActive },
            { open: isDistributorsActive },
          ]"
        >
          <router-link to="/distributors">
            <i class="icon mdi mdi-truck-fast"></i>
            <span>Distributors</span>
          </router-link>
          <ul class="sub-menu">
            <li :class="{ active: isDistributorsCreateActive }">
              <router-link to="/distributors/create"
                >New Distributor</router-link
              >
            </li>
          </ul>
        </li>
        <!-- <li
          v-if="
            !user.isSalesUser &&
              user.type == 'Manufacturer' &&
              (hasPermission('Distributor_View') ||
                hasPermission('Distributor_Create'))
          "
          :class="[
            'parent',
            { active: isManufacturerDistributorsParentActive },
            {
              open:
                isManufacturerDistributorsParentActive ||
                isManufacturerDistributorsCreateActive ||
                isManufacturerDistributorsActive,
            },
          ]"
        >
          <a
            href="javascript:void"
            @click.prevent="handleParentMenuClick('ManufacturerDistributor')"
          >
            <i class="feather icon-book-open"></i>
            <span>Distributors</span>
          </a>
          <ul class="sub-menu">
            <li
              v-if="hasPermission('Distributor_View')"
              :class="{ active: isManufacturerDistributorsActive }"
            >
              <router-link to="/manufacturer-distributors">
                <i class="feather icon-circle"></i> &nbsp; Distributors
              </router-link>
            </li>
            <li
              v-if="hasPermission('Distributor_Create')"
              :class="{ active: isManufacturerDistributorsCreateActive }"
            >
              <router-link to="/manufacturer-distributors/create"
                ><i class="feather icon-circle"></i> &nbsp; New
                Distributor</router-link
              >
            </li>
          </ul>
        </li> -->

        <!-- end of system -->

        <!-- manifaturer nav start DONE! -->
        <li
          v-if="
            !user.isSalesUser &&
              user.type == 'Manufacturer' &&
              (hasPermission('Distributor_View') ||
                hasPermission('Distributor_Create'))
          "
          :class="[
            'parent',
            { active: isManufacturerDistributorsParentActive },
            {
              open:
                isManufacturerDistributorsParentActive ||
                isManufacturerDistributorsCreateActive ||
                isManufacturerDistributorsActive,
            },
          ]"
        >
          <router-link to="/manufacturer-distributors">
            <i class=""
              ><img
                style="max-width: none !important"
                src="/assets/img/profile-2user.svg"
                alt=""
            /></i>
            <span
              class="text-[#353535] text-[16px] leading-[19.67px] font-medium"
              >Distributors</span
            >
          </router-link>
        </li>
        <li
          v-if="
            !user.isSalesUser &&
              user.type == 'Manufacturer' &&
              hasPermission('Group_View')
          "
          :class="[
            'parent',
            { active: isManufacturerGroupActive },
            { open: isManufacturerGroupActive },
          ]"
        >
          <router-link to="/groups">
            <i class="">
              <img
                style="max-width: none !important"
                src="/assets/img/people.svg"
                alt=""
              />
            </i>
            <span
              class="text-[#353535] text-[16px] leading-[19.67px] font-medium"
              >Groups</span
            >
          </router-link>
        </li>
        <li
          v-if="
            !user.isSalesUser &&
              user.type == 'Manufacturer' &&
              hasPermission('Broadcast_Message_View')
          "
          :class="['parent', { active: isManufacturerBroadcastParentActive }]"
        >
          <router-link to="/manufacturer-broadcast">
            <i
              ><img
                style="max-width: none !important"
                src="/assets/img/volume-mute.svg"
                alt=""
            /></i>
            <span
              class="text-[#353535] text-[16px] leading-[19.67px] font-medium"
              >Broadcast</span
            >
          </router-link>
        </li>
        <li
          v-if="
            !user.isSalesUser &&
              user.type == 'Manufacturer' &&
              (hasPermission('Product_View') ||
                hasPermission('Product_Category_View') ||
                hasPermission('Product_Unit_View'))
          "
          :class="['parent', { active: isManufacturerProductParentActive }]"
        >
          <router-link to="/manufacturer-products">
            <i class=""
              ><img
                style="max-width: none !important"
                src="/assets/img/tag.svg"
                alt=""
            /></i>
            <span
              class="text-[#353535] text-[16px] leading-[19.67px] font-medium"
              >Products</span
            >
          </router-link>
        </li>
        <!-- manifaturer nav start DONE! -->

        <!-- distributor 2 start 1 -->

        <!-- distributor end 2 -->

        <!-- is sale user start DONE !! -->
        <li
          v-if="
            !user.isSalesUser &&
              !user.isSalesUser &&
              (hasPermission('User_View') || hasPermission('Role_View'))
          "
          :class="{ active: isPaymentActive }"
        >
          <router-link
            @click.native.prevent="handleParentMenuClick('Users')"
            to="/settings/team"
          >
            <i class=""
              ><img
                style="max-width: none !important"
                src="/assets/img/profile.svg"
                alt=""
            /></i>
            <span
              class="text-[#353535] text-[16px] leading-[19.67px]
              font-medium"
              >User Management</span
            >
          </router-link>
        </li>
        <li
          v-if="
            !user.isSalesUser &&
              !user.isSalesUser &&
              (hasPermission('User_View') || hasPermission('Role_View'))
          "
          :class="['parent', { active: isSettingsActive }]"
        >
          <router-link
            @click.native.prevent="handleParentMenuClick('Settings')"
            to="/settings/business"
          >
            <i class=""
              ><img
                style="max-width: none !important"
                src="/assets/img/setting-2.svg"
                alt=""
            /></i>
            <span
              class="text-[#353535] text-[16px] leading-[19.67px] font-medium"
              >Settings</span
            >
          </router-link>
        </li>
        <li
          v-if="
            !user.isSalesUser &&
              user.type == 'Manufacturer' &&
              hasPermission('Sales_Order_View')
          "
          :class="['parent', { active: isPurchaseOrderParentActive }]"
        >
          <router-link to="/sales-order">
            <i
              ><img
                style="max-width: none !important"
                src="/assets/img/category.svg"
                alt=""
            /></i>
            <span
              class="text-[#353535] text-[16px] leading-[19.67px] font-medium"
              >Sales Order</span
            >
          </router-link>
        </li>
        <li
          v-if="
            !user.isSalesUser &&
              user.role != 'Customer' &&
              hasPermission('Report_View')
          "
          :class="['parent', { active: isReportsActive }]"
        >
          <router-link
            :to="
              user.type == 'Manufacturer' && hasPermission('Report_View')
                ? `/reports/enterprise`
                : user.type == 'Distributor' && hasPermission('Report_View')
                ? `/reports/distributor`
                : `/`
            "
          >
            <i class=""
              ><img
                style="max-width: none !important"
                src="/assets/img/presention-chart.svg"
                alt=""
            /></i>
            <span
              class="text-[#353535] text-[16px] leading-[19.67px] font-medium"
              >Reports & Analytics</span
            >
          </router-link>
        </li>
        <li
          v-if="
            user.isSalesUser &&
              (user.role.isDistributorManager ||
                user.role.isStateManager ||
                user.role.isGroupManager)
          "
          :class="[
            'parent',
            { active: isSaleOrderParentActive },
            {
              open:
                isSaleOrderParentActive || isSaleOrderActive || isMySalesActive,
            },
          ]"
        >
          <router-link
            to="#"
            @click.native.prevent="handleParentMenuClick('SaleOrder')"
          >
            <i class="feather icon-list"></i>
            <span
              class="text-[#353535] text-[16px] leading-[19.67px] font-medium"
              >Sales Order</span
            >
          </router-link>
          <ul class="sub-menu">
            <li :class="{ active: isSaleOrderActive }">
              <router-link to="/salesOrder">
                <i class="feather icon-circle"></i>
                &nbsp;View Sales Orders
              </router-link>
            </li>
            <li :class="{ active: isMySalesActive }">
              <router-link to="/mySales">
                <i class="feather icon-circle"></i>
                &nbsp;My Sales
              </router-link>
            </li>
          </ul>
        </li>
        <li
          v-if="
            user.isSalesUser &&
              (user.role.isDistributorManager ||
                user.role.isStateManager ||
                user.role.isGroupManager)
          "
          :class="[
            'parent',
            { active: isManufacturerProductParentActive },
            {
              open:
                isManufacturerProductParentActive ||
                isManufacturerProductsActive,
            },
          ]"
        >
          <a
            href="javascript:void"
            @click.prevent="handleParentMenuClick('ManufacturerProduct')"
          >
            <i class="feather icon-tag"></i>
            <span
              class="text-[#353535] text-[16px] leading-[19.67px] font-medium"
              >Products</span
            >
          </a>
          <ul class="sub-menu">
            <li
              v-if="hasPermission('Product_View')"
              :class="{ active: isManufacturerProductsActive }"
            >
              <!-- <li
              :class="{ active: isManufacturerProductsActive }"
            > -->
              <router-link to="/manufacturer-products">
                <i class="feather icon-circle"></i> &nbsp; View Products
              </router-link>
            </li>
          </ul>
        </li>
        <li
          v-if="
            user.isSalesUser &&
              (user.role.isDistributorManager ||
                user.role.isStateManager ||
                user.role.isGroupManager)
          "
          :class="[
            'parent',
            { active: isDistributorDirectoryParentActive },
            {
              open:
                isDistributorDirectoryParentActive ||
                isDistributorDirectoryActive,
            },
          ]"
        >
          <router-link
            to="#"
            @click.native.prevent="
              handleParentMenuClick('DistributorDirectory')
            "
          >
            <i class="feather icon-speaker"></i>
            <span>Distributor Directory</span>
          </router-link>
          <ul class="sub-menu">
            <li :class="{ active: isDistributorDirectoryActive }">
              <router-link to="/sales-user-distributors">
                <i class="feather icon-circle"></i> &nbsp; View Distributors
              </router-link>
            </li>
          </ul>
        </li>
        <li class="md:mt-[70px] 3xl:mt-[150px]">
          <router-link to="#" @click.prevent="logout">
            <i class="fa-solid fa-right-from-bracket"></i>
            <span>Logout</span>
          </router-link>
        </li>
        <!-- is sale user end DONE !! -->
      </ul>
    </div>
  </div>
  <!-- END: Main Menu-->
</template>

<script>
/* eslint-disable */

import SystemPermissions from "@/permissions";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "sideBar",
  data() {
    return {
      toggle: false,
      distributorProductPermission: false,
      title: this.$route.meta.title,
      isDashboardActive: false,
      isOrdersActive: false,
      isOrdersSentActive: false,
      isMerchantViewDistributorActive: false,
      isMerchantAddDistributorActive: false,
      isOrdersDraftActive: false,
      isGrowTradeActive: false,
      isOrdersCreateActive: false,
      isOrdersCreateAltActive: false,
      isOrdersCreateAltPayActive: false,
      isIssuedStock: false,
      isDistributorListActive: false,
      isisManufacturerDistributorsActive: false,
      isDistributorsActive: false,
      isDistributorsCreateActive: false,
      isManufacturerDistributorsCreateActive: false,
      isManufacturerGroupActive: false,
      isManufacturersActive: false,
      isManufacturersCreateActive: false,
      isManufacturerProductsActive: false,
      isCustomerActive: false,
      isCampaignsActive: false,
      isCampaignsCreateActive: false,
      isReportsActive: false,
      isDistributorReportsActive: false,
      isManufacturerReportsActive: false,
      isAnalyticsActive: false,
      isDistributorAnalyticsActive: false,
      isManufacturerAnalyticsActive: false,
      isSalesAnalyticsActive: false,
      isSettingsActive: false,
      isBizProfileActive: false,
      isEODActive: false,
      isProductsActive: false,
      isProductCatActive: false,
      isProductUnitActive: false,
      isProductBundleActive: false,
      isLocationsActive: false,
      isSubLocationsActive: false,
      isManageStockActive: false,
      isStockTransfer: false,
      isPurchaseOrderActive: false,
      isReconcileOrdersToStockActive: false,
      isTeamActive: false,
      isPaymentActive: false,
      isGroupsActive: false,
      isBillingActive: false,
      isDiscountsActive: false,
      // isEODActive: false,
      isVolumeByCustomerReport: false,
      isVolumeByProductReport: false,
      isVolumeByProductCategoryReport: false,
      isDailySalesByOrderReport: false,
      isDailySalesByDateReport: false,
      isDailySalesByOrderStatusReport: false,
      isInventoryByBatchReport: false,
      isInventoryByProductReport: false,
      isInventoryByProductCategoryReport: false,
      isReconciliationByStatusReport: false,
      isReconciliationByOrderNumberReport: false,
      isCustomerReturnsActive: false,
      isBranchesActive: false,
      isAccountPosting: false,
      isManagePaymentsActive: false,
      isManagePaymentsNotify: false,
      isManageCashChequeTransactionsActive: false,
      isSystemSettingsActive: false,
      isExpenseCategoryActive: false,
      isPaymentChannelActive: false,
      isManageExpensesActive: false,
      isAdjustExpensesActive: false,
      isStatisticsActive: false,
      isRolesActive: false,
      isManagePaymentsNotify: false,
      isPurchaseOrderProcessActive: false,
      isPurchaseOrderItemSetupActive: false,
      isPurchaseOrderStageSetupActive: false,
      isManageCustomerAccountParentActive: false,
      isManageStockParentActive: false,
      isCampaignsParentActive: false,
      isOrdersParentActive: false,
      isSalesForceParentActive: false,
      isSalesRolesActive: false,
      isSalesTeamActive: false,
      isSaleOrderActive: false,
      isDistributorDirectoryActive: false,
      isManufacturerDistributorsParentActive: false,
      isManufacturerProductParentActive: false,
      isPurchaseOrderParentActive: false,
      isManageGeoFenceActive: false,
      isNotActive: false,
      isManufacturerBroadcastParentActive: false,
      isManufacturerBroadcastDraftActive: false,
    };
  },
  created() {
    this.checkRoute();
    this.getCount();
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
    PaymentNotificationCount: function() {
      return this.$store.state.paymentNotificationCount;
    },
  },

  watch: {
    $route: "checkRoute",
  },
  methods: {
    logout: function() {
      this.$auth.destroyToken();
      this.$store.commit("clearCurrentUser");
      this.$store.replaceState({});
      this.$router.push("/login");
    },
    handleParentMenuClick(parent) {
      this.title = this.$route.meta.title;
      this.isDashboardActive = false;
      this.isOrdersActive = false;
      this.isOrdersSentActive = false;
      this.isMerchantViewDistributorActive = false;
      this.isMerchantAddDistributorActive = false;
      this.isOrdersDraftActive = false;
      this.isGrowTradeActive = false;
      this.isOrdersCreateActive = false;
      this.isOrdersCreateAltActive = false;
      this.isOrdersCreateAltPayActive = false;
      this.isIssuedStock = false;
      this.isDistributorListActive = false;
      this.isDistributorsActive = false;
      this.isManufacturerDistributorsActive = false;
      this.isManufacturerDistributorsCreateActive = false;
      this.isManufacturerGroupActive = false;
      this.isDistributorsCreateActive = false;
      this.isManufacturersActive = false;
      this.isManufacturersCreateActive = false;
      this.isCustomerActive = false;
      this.isCampaignsActive = false;
      this.isCampaignsCreateActive = false;
      this.isReportsActive = false;
      this.isDistributorReportsActive = false;
      this.isManufacturerReportsActive = false;
      this.isAnalyticsActive = false;
      this.isDistributorAnalyticsActive = false;
      this.isManufacturerAnalyticsActive = false;
      this.isSalesAnalyticsActive = false;
      this.isSettingsActive = false;
      this.isEODActive = false;
      this.isBizProfileActive = false;
      this.isProductsActive = false;
      this.isManufacturerProductsActive = false;
      this.isProductCatActive = false;
      this.isProductBundleActive = false;
      this.isProductUnitActive = false;
      this.isLocationsActive = false;
      this.isSubLocationsActive = false;
      this.isManageStockActive = false;
      this.isStockTransferActive = false;
      this.isReconcileOrdersToStockActive = false;
      this.isPaymentActive = false;
      this.isManagePaymentsNotify = false;
      this.isTeamActive = false;
      this.isGroupsActive = false;
      // this.isEODActive = false;
      this.isBillingActive = false;
      this.isDiscountsActive = false;
      this.isVolumeByCustomerReport = false;
      this.isVolumeByProductCategoryReport = false;
      this.isVolumeByProductReport = false;
      this.isDailySalesByDateReport = false;
      this.isDailySalesByOrderReport = false;
      this.isDailySalesByOrderStatusReport = false;
      this.isInventoryByBatchReport = false;
      this.isInventoryByProductReport = false;
      this.isInventoryByProductCategoryReport = false;
      this.isReconciliationByStatusReport = false;
      this.isReconciliationByOrderNumberReport = false;
      this.isBranchesActive = false;
      this.isAccountPosting = false;
      this.isManagePaymentsActive = false;
      this.isManagePaymentsNotify = false;
      this.isManageCashChequeTransactionsActive = false;
      this.isSystemSettingsActive = false;
      this.isExpenseCategoryActive = false;
      this.isPaymentChannelActive = false;
      this.isManageExpensesActive = false;
      this.isAdjustExpensesActive = false;
      this.isStatisticsActive = false;
      this.isRolesActive = false;
      this.isPurchaseOrderProcessActive = false;
      this.isPurchaseOrderItemSetupActive = false;
      this.isPurchaseOrderStageSetupActive = false;
      this.isManageCustomerAccountParentActive = false;
      this.isManageStockParentActive = false;
      this.isCampaignsParentActive = false;
      this.isOrdersParentActive = false;
      this.isSalesRolesActive = false;
      this.isSalesTeamActive = false;
      this.isSaleOrderParentActive = false;
      this.isSaleOrderActive = false;
      this.isDistributorDirectoryParentActive = false;
      this.isDistributorDirectoryActive = false;
      this.isSalesForceParentActive = false;
      this.isManufacturerDistributorsParentActive = false;
      this.isManufacturerProductParentActive = false;
      this.isPurchaseOrderParentActive = false;
      this.isManageGeoFenceActive = false;
      this.isManufacturerBroadcastParentActive = false;

      if (parent === "Settings") this.isSettingsActive = true;
      if (parent === "Users") this.isPaymentActive = true;
      else if (parent === "Reports") this.isReportsActive = true;
      else if (parent === "Analytics") this.isAnalyticsActive = true;
      else if (parent === "Campaigns") this.isCampaignsParentActive = true;
      else if (parent === "CustomerAccount")
        this.isManageCustomerAccountParentActive = true;
      else if (parent === "Inventory") this.isManageStockParentActive = true;
      else if (parent === "Orders") this.isOrdersParentActive = true;
      else if (parent === "SaleOrder") this.isSaleOrderParentActive = true;
      else if (parent === "DistributorDirectory")
        this.isDistributorDirectoryParentActive = true;
      else if (parent === "salesorders")
        this.isPurchaseOrderProcessActive = true;
      else if (parent === "SalesForce") this.isSalesForceParentActive = true;
      else if (parent === "ManufacturerDistributor")
        this.isManufacturerDistributorsParentActive = true;
      else if (parent === "ManufacturerProduct")
        this.isManufacturerProductParentActive = true;
      else if (parent === "PurchaseOrder")
        this.isPurchaseOrderParentActive = true;
      else if (parent === "MerchantDistributors")
        this.isDistributorsActive = true;
      else if (parent === "ManufacturerBroadcast")
        this.isManufacturerBroadcastParentActive = true;
    },

    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },

    async getCount() {
      await this.$store.dispatch("getPaymentNotificationCount");
      this.notificationCount = this.PaymentNotificationCount;
    },
    checkRoute() {
      this.title = this.$route.meta.title;
      this.isDashboardActive = false;
      this.isOrdersActive = false;
      this.isOrdersSentActive = false;
      this.isMerchantViewDistributorActive = false;
      this.isMerchantAddDistributorActive = false;
      this.isOrdersDraftActive = false;
      this.isGrowTradeActive = false;
      this.isOrdersCreateActive = false;
      this.isOrdersCreateAltActive = false;
      this.isOrdersCreateAltPayActive = false;
      this.isIssuedStock = false;
      this.isDistributorListActive = false;
      this.isDistributorsActive = false;
      this.isManufacturerDistributorsActive = false;
      this.isManufacturerDistributorsCreateActive = false;
      this.isManufacturerGroupActive = false;
      this.isDistributorsCreateActive = false;
      this.isManufacturersActive = false;
      this.isManufacturersCreateActive = false;
      this.isCustomerActive = false;
      this.isCampaignsActive = false;
      this.isCampaignsCreateActive = false;
      this.isReportsActive = false;
      this.isDistributorReportsActive = false;
      this.isManufacturerReportsActive = false;
      this.isAnalyticsActive = false;
      this.isDistributorAnalyticsActive = false;
      this.isManufacturerAnalyticsActive = false;
      this.isSalesAnalyticsActive = false;
      this.isSettingsActive = false;
      // this.isEODActive = false;
      this.isBizProfileActive = false;
      this.isProductsActive = false;
      this.isManufacturerProductsActive = false;
      this.isProductCatActive = false;
      this.isProductBundleActive = false;
      this.isProductUnitActive = false;
      this.isLocationsActive = false;
      this.isSubLocationsActive = false;
      this.isManageStockActive = false;
      this.isStockTransferActive = false;
      this.isCustomerReturnsActive = false;
      (this.isPurchaseOrderActive = false),
        (this.isReconcileOrdersToStockActive = false);
      this.isEODActive = false;
      this.isPaymentActive = false;
      this.isTeamActive = false;
      this.isGroupsActive = false;
      this.isBillingActive = false;
      this.isDiscountsActive = false;
      this.isManagePaymentsNotify = false;
      this.isVolumeByCustomerReport = false;
      this.isVolumeByProductCategoryReport = false;
      this.isVolumeByProductReport = false;
      this.isDailySalesByDateReport = false;
      this.isDailySalesByOrderReport = false;
      this.isDailySalesByOrderStatusReport = false;
      this.isInventoryByBatchReport = false;
      this.isInventoryByProductReport = false;
      this.isInventoryByProductCategoryReport = false;
      this.isReconciliationByStatusReport = false;
      this.isReconciliationByOrderNumberReport = false;
      this.isBranchesActive = false;
      this.isAccountPosting = false;
      this.isManagePaymentsActive = false;
      this.isManagePaymentsNotify = false;
      this.isManageCashChequeTransactionsActive = false;
      this.isSystemSettingsActive = false;
      this.isExpenseCategoryActive = false;
      this.isPaymentChannelActive = false;
      this.isManageExpensesActive = false;
      this.isAdjustExpensesActive = false;
      this.isStatisticsActive = false;
      this.isRolesActive = false;
      this.isPurchaseOrderProcessActive = false;
      this.isPurchaseOrderItemSetupActive = false;
      this.isPurchaseOrderStageSetupActive = false;
      this.isManageCustomerAccountParentActive = false;
      this.isManageStockParentActive = false;
      this.isCampaignsParentActive = false;
      this.isOrdersParentActive = false;
      this.isSalesRolesActive = false;
      this.isSalesTeamActive = false;
      this.isSaleOrderParentActive = false;
      this.isMySalesActive = false;
      this.isDistributorDirectoryParentActive = false;
      this.isDistributorDirectoryActive = false;
      this.isSalesForceParentActive = false;
      this.isManufacturerDistributorsParentActive = false;
      this.isManufacturerProductParentActive = false;
      this.isPurchaseOrderParentActive = false;
      this.isManageGeoFenceActive = false;
      this.isManufacturerBroadcastParentActive = false;
      this.isManufacturerBroadcastDraftActive = false;

      switch (true) {
        case this.$route.path.startsWith("/dashboard"):
          this.isDashboardActive = true;

          break;

        case this.$route.path.startsWith("/merchant/orders"):
          this.isOrdersParentActive = true;

          if (
            this.$route.path.startsWith("/merchant/orders") &&
            this.$route.path != "/merchant/orders/create" &&
            !this.$route.path.startsWith("/merchant/orders/edit") &&
            !this.$route.query.isDraft
          ) {
            this.isOrdersSentActive = true;
          } else if (this.$route.path.startsWith("/merchant/orders/edit")) {
            this.isOrdersDraftActive = true;
          } else if (this.$route.path == "/merchant/orders/create") {
            this.isOrdersCreateActive = true;
          }

          break;

        case this.$route.path.startsWith("/merchant/drafts"):
          this.isOrdersParentActive = true;

          if (
            this.$route.path.startsWith("/merchant/drafts") ||
            this.$route.query.isDraft
          ) {
            this.isOrdersDraftActive = true;
          }

          break;
        case this.$route.path.startsWith("/merchant/distributors"):
          this.isDistributorsActive = true;

          if (this.$route.path.startsWith("/merchant/distributors/add")) {
            this.isMerchantAddDistributorActive = true;
          } else if (
            this.$route.path.startsWith("/merchant/distributors/view")
          ) {
            this.isMerchantViewDistributorActive = true;
          }

          break;

        case this.$route.path.startsWith("/merchant/growtrade"):
          this.isGrowTradeActive = true;
          break;

        case this.$route.path.startsWith("/payment-notification"):
          this.isManagePaymentsNotify = true;
          this.isManageCustomerAccountParentActive = true;
          break;

        case this.$route.path.startsWith("/orders"):
          this.isOrdersParentActive = true;

          if (this.$route.fullPath == "/orders/create?pay4me=true") {
            this.isOrdersCreateAltPayActive = true;
          } else if (this.$route.path.startsWith("/orders/create")) {
            this.isOrdersCreateAltActive = true;
          } else if (this.$route.path == "/orders/issue-stock") {
            this.isIssuedStock = true;
          } else {
            this.isOrdersActive = true;
          }
          break;
        case this.$route.path.startsWith("/manage-expenses"):
          this.isManageExpensesActive = true;
          break;
        case this.$route.path.startsWith("/managecashchequetransactions"):
          this.isManagePaymentsActive = true;
          this.isManageCashChequeTransactionsActive = true;
          break;

        case this.$route.path.startsWith("/adjust-expenses"):
          this.isManageExpensesActive = true;
          this.isAdjustExpensesActive = true;
          break;
        case this.$route.path.startsWith("/distributorlist"):
          this.isDistributorListActive = true;

          break;
        case this.$route.path.startsWith("/manufacturer-distributors"):
          this.isManufacturerDistributorsParentActive = true;

          if (this.$route.path == "/manufacturer-distributors/create") {
            this.isManufacturerDistributorsCreateActive = true;
          } else {
            this.isManufacturerDistributorsActive = true;
          }

          break;
        case this.$route.path.startsWith("/sales-user-distributor"):
          this.isDistributorDirectoryActive = true;

          break;

        case this.$route.path.startsWith("/groups"):
          this.isManufacturerGroupActive = true;
          break;
        case this.$route.path.startsWith("/distributors"):
          this.isDistributorsActive = true;

          if (this.$route.path == "/distributors/create") {
            this.isDistributorsCreateActive = true;
          }

          break;
        case this.$route.path.startsWith("/manufacturers"):
          this.isManufacturersActive = true;

          if (this.$route.path == "/manufacturers/create") {
            this.isManufacturersCreateActive = true;
          }

          break;
        case this.$route.path.startsWith("/customers"):
          this.isCustomerActive = true;
          break;
        case this.$route.path.startsWith("/manufacturer-products"):
          this.isManufacturerProductParentActive = true;

          if (this.$route.path == "/manufacturer-products") {
            this.isManufacturerProductsActive = true;
          }

          if (this.$route.path == "/manufacturer-products/cat/view") {
            this.isProductCatActive = true;
          }

          if (this.$route.path == "/manufacturer-products/bundle/view") {
            this.isProductBundleActive = true;
          }

          if (this.$route.path == "/manufacturer-products/units/view") {
            this.isProductUnitActive = true;
          }
          break;
        case this.$route.path.startsWith("/products"):
          this.isProductsActive = true;
          break;

        case this.$route.path.startsWith("/managepayments"):
          this.isManagePaymentsActive = true;
          this.isManageCustomerAccountParentActive = true;
          break;

        case this.$route.path.startsWith("/accountposting"):
          this.isManageCustomerAccountParentActive = true;
          this.isAccountPosting = true;
          break;
        case this.$route.path.startsWith("/payment-notification"):
          this.isManagePaymentsNotify = true;
          this.isManageCustomerAccountParentActive = true;
          break;
        case this.$route.path.startsWith("/warehouses"):
          this.isManageStockParentActive = true;
          this.isLocationsActive = true;

          break;
        case this.$route.path.startsWith("/storage-areas"):
          this.isManageStockParentActive = true;
          this.isSubLocationsActive = true;

          break;
        case this.$route.path.startsWith("/managestock"):
          this.isManageStockActive = true;
          this.isManageStockParentActive = true;

          break;
        case this.$route.path.startsWith("/stocktransfer"):
          this.isStockTransferActive = true;
          this.isManageStockParentActive = true;

          break;
        case this.$route.path.startsWith("/reconcileorderstostock"):
          this.isManageStockParentActive = true;
          this.isReconcileOrdersToStockActive = true;

          break;

        case this.$route.path.startsWith("/salesorders"):
          this.isPurchaseOrderActive = true;
          this.isManageStockParentActive = true;

          break;
        case this.$route.path.startsWith("/customer-returns"):
          this.isCustomerReturnsActive = true;
          this.isManageStockParentActive = true;

          break;
        case this.$route.path.startsWith("/campaigns"):
          this.isCampaignsParentActive = true;

          if (this.$route.path == "/campaigns/create") {
            this.isCampaignsCreateActive = true;
          } else {
            this.isCampaignsActive = true;
          }

          break;
        case this.$route.path.startsWith("/analytics"):
          this.isAnalyticsActive = true;

          if (this.$route.path == "/analytics/distributor") {
            this.isDistributorAnalyticsActive = true;
          }
          if (this.$route.path == "/analytics/manufacturer") {
            this.isManufacturerAnalyticsActive = true;
          }
          if (this.$route.path == "/analytics/sales") {
            this.isSalesAnalyticsActive = true;
          }

          break;
        case this.$route.path.startsWith("/reports"):
          this.isReportsActive = true;

          if (this.$route.path == "/reports/distributor") {
            this.isDistributorReportsActive = true;
          }
          if (this.$route.path == "/reports/manufacturer") {
            this.isManufacturerReportsActive = true;
          }

          break;
        case this.$route.path.startsWith("/volume-by-customer"):
          this.isReportsActive = true;
          this.isVolumeByCustomerReport = true;
          break;
        case this.$route.path.startsWith("/volume-by-product-category"):
          this.isReportsActive = true;
          this.isVolumeByProductCategoryReport = true;
          break;
        case this.$route.path.startsWith("/volume-by-product"):
          this.isReportsActive = true;
          this.isVolumeByProductReport = true;
          break;
        case this.$route.path.startsWith("/daily-sales-by-order-status"):
          this.isReportsActive = true;
          this.isDailySalesByOrderStatusReport = true;
          break;
        case this.$route.path.startsWith("/daily-sales-by-date"):
          this.isReportsActive = true;
          this.isDailySalesByDateReport = true;
          break;
        case this.$route.path.startsWith("/daily-sales-by-order"):
          this.isReportsActive = true;
          this.isDailySalesByOrderReport = true;
          break;

        case this.$route.path.startsWith("/inventory-by-batch"):
          this.isReportsActive = true;
          this.isInventoryByBatchReport = true;
          break;
        case this.$route.path.startsWith("/inventory-by-product-category"):
          this.isReportsActive = true;
          this.isInventoryByProductCategoryReport = true;
          break;
        case this.$route.path.startsWith("/inventory-by-product"):
          this.isReportsActive = true;
          this.isInventoryByProductReport = true;
          break;

        case this.$route.path.startsWith("/reconciliation-by-status"):
          this.isReportsActive = true;
          this.isReconciliationByStatusReport = true;
          break;
        case this.$route.path.startsWith("/reconciliation-by-order-number"):
          this.isReportsActive = true;
          this.isReconciliationByOrderNumberReport = true;
          break;

        case this.$route.path.startsWith("/settings"):
          this.isSettingsActive = true;

          if (this.$route.path == "/settings/payment") {
            this.isPaymentActive = true;
          }
          if (this.$route.path == "/settings/team") {
            this.isTeamActive = true;
          }

          if (this.$route.path == "/settings/business") {
            this.isBizProfileActive = true;
          }

          if (this.$route.path == "/settings/EODReport") {
            this.isEODActive = true;
          }

          if (this.$route.path.startsWith("/settings/groups")) {
            this.isGroupsActive = true;
          }

          if (this.$route.path.startsWith("/settings/billing")) {
            this.isBillingActive = true;
          }

          if (this.$route.path.startsWith("/settings/discounts")) {
            this.isDiscountsActive = true;
          }

          if (this.$route.path.startsWith("/settings/branch")) {
            this.isBranchesActive = true;
          }

          // if (this.$route.path == "/settings/EODReport") {
          // 	this.isEODActive = true;
          // }

          if (
            this.$route.path.startsWith("/settings/systemsettings") ||
            this.$route.path.startsWith("/settings/manufacturersystemsettings")
          ) {
            this.isSystemSettingsActive = true;
          }

          if (this.$route.path.startsWith("/settings/expenseCategory")) {
            this.isExpenseCategoryActive = true;
          }

          if (this.$route.path.startsWith("/settings/paymentChannel")) {
            this.isPaymentChannelActive = true;
          }

          if (this.$route.path.startsWith("/settings/roles")) {
            this.isRolesActive = true;
          }
          if (this.$route.path.startsWith("/settings/geofence")) {
            this.isManageGeoFenceActive = true;
          }

          break;

        case this.$route.path.startsWith("/sales-force"):
          this.isSalesForceParentActive = true;

          if (this.$route.path.startsWith("/sales-force/sales-roles")) {
            this.isSalesRolesActive = true;
          }
          if (this.$route.path.startsWith("/sales-force/sales-team")) {
            this.isSalesTeamActive = true;
          }

          break;

        case this.$route.path.startsWith("/sales-order"):
          this.isPurchaseOrderParentActive = true;

          if (this.$route.path === "/sales-order") {
            this.isPurchaseOrderProcessActive = true;
          }

          if (this.$route.path === "/sales-order/item/setup") {
            this.isPurchaseOrderItemSetupActive = true;
          }

          if (this.$route.path === "/sales-order/stage/setup") {
            this.isPurchaseOrderStageSetupActive = true;
          }

          break;

        case this.$route.path.startsWith("/account"):
          if (this.user.role === "Customer") this.isDashboardActive = true;

          break;
        case this.$route.path.startsWith("/statistics"):
          this.isStatisticsActive = true;
          break;
        case this.$route.path.startsWith("/manufacturer-broadcast"):
          this.isManufacturerBroadcastParentActive = true;

          if (this.$route.path == "/manufacturer-broadcast-draft") {
            this.isManufacturerBroadcastDraftActive = true;
          }
          break;
      }

      var isMenuExpanded = $.app.menu.expanded;
      var isMobile = this.isMobile();
      if (isMenuExpanded === true && isMobile === true) $.app.menu.toggle();
    },
  },
};
</script>

<style scoped>
#sidebar {
  overflow-y: scroll;
}

#sidebar::-webkit-scrollbar {
  width: 0.3px; /* Adjust the width of the scrollbar */
  height: 8px; /* Adjust the height of the scrollbar for horizontal scrolling */
}

#sidebar::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Background color of the scrollbar track */
}

#sidebar::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners for the scrollbar thumb */
}

#sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #55555563; /* Darker color when hovering over the scrollbar thumb */
}

i {
  color: #2463eb !important;
}

img {
  max-width: auto !important;
}
</style>
