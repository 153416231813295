<template>
    <div class="row match-height">
        <!--<span v-if="count > 0">-->
            <div class="col-lg-12">
                <p>
                    This analytics for business insights is aimed at helping distributors slice and dice data from ordering and payment activities around their business on the weKurnect platform to
                    inform insights that will help them derive immense value from their business.
                </p>
            </div>
            <div class="clearfix"></div>

            <div id="select-year" class="col-lg-2 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Select Year</h5></div>

            </div>
            <div id="select-half-year" class="col-lg-2 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Select Half-Year</h5></div>

            </div>
            <div id="select-quarter" class="col-lg-2 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Select Quarter</h5></div>

            </div>
            <div id="select-month" class="col-lg-2 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Select Month</h5></div>

            </div>
            <div id="select-week" class="col-lg-2 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Select Week</h5></div>

            </div>
            <div id="select-day" class="col-lg-2 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Select Day</h5></div>

            </div>


            <div class="text-black-50 col-lg-3 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Total Sales Count</h5></div>
                <div><h1 class="text-black-50 text-bold-700"><div id="total-sales-count"></div></h1></div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Total Sales Volume (₦)</h5></div>
                <div><h1 class="text-black-50 text-bold-700"><div id="total-sales-value"></div></h1></div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Total Payment (₦)</h5></div>
                <div><h1 class="text-black-50 text-bold-700"><div id="total-payment-value"></div></h1></div>

            </div>
            <div class="col-lg-3 col-sm-6 col-12 card-header">
                <div><h3 class="text-danger text-bold-700"><a id="all" href="#">Clear All Filters</a></h3></div>
            </div>

            <div class="clearfix"></div>

            <div id="chart-ring-channel" class="col-lg-3 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Quarterly Sales</h5></div>

            </div>
            <div id="chart-ring-order-status" class="col-lg-3 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Sales by Order Status</h5></div>

            </div>
            <div id="chart-ring-location" class="col-lg-3 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Sales By Location</h5></div>

            </div>
            <div id="chart-ring-payment-status" class="col-lg-3 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Sales By Payment Status</h5></div>

            </div>

            <div id="chart-top-retailer" class="col-lg-6 card-header">
                <div><h5 class="text-primary">Top 5 Merchants</h5></div>

            </div>
            <div id="chart-top-product" class="col-lg-6 card-header">
                <div>
                    <h5 class="text-primary">Top 5 Products</h5>
                </div>

            </div>

            <div id="chart-line-monthly-performance" class="col-lg-12 card-header">

                <div>
                    <h5 class="text-primary">Monthly Sales Performance</h5>
                </div>
            </div>
        <!--</span>
        <span v-else>
            <div class="col-lg-12">
                <p>
                    No Records exist to display analytics
                </p>
            </div>
        </span>-->

    </div>
   

</template>

<script>
       /* eslint-disable */
    import moment from "moment";
    import { helper } from "@/helper";
    import * as d3 from "d3";
    import * as dc from "dc";
    import crossfilter from "crossfilter2";
   
    export default {
        data() {
            return {
                isBusyModal: false,
                type: "distributor",
                count: 0,
                dateDim: null,
                YR1: "",
                YR2: "",
                minDate: null,
                maxDate: null,
                firstyear: 0,
                secondyear: 0

            };

        },
        computed: {
             user: function () {
                return this.$store.state.currentUser;
              }
        },
        methods: {
            generateChart(data) {

	               //const parseDate = d3.timeParse(d3.timeFormat("%m/%d/%Y"));
                   //const parseDate2 = d3.timeParse(d3.timeFormat("%m/%d"));

                    const dateFormatSpecifier = '%m/%d/%Y';
                    const dateFormatSpecifier2 = '%m/%d/';

                    const parseDate = d3.timeParse(dateFormatSpecifier);
                    const parseDate2 = d3.timeParse(dateFormatSpecifier2);

                  const monthNames = ["January", "February", "March", "April", "May", "June",
                                          "July", "August", "September", "October", "November", "December"
                                        ];

                data.forEach((item) => { 

                       var dt = new Date(item.ordeR_DATE);
                       item.ordeR_DATE = dt;
                       item.qtime = (dt.getMonth()+1)+"/"+dt.getDate();
                       item.Year = dt.getFullYear();
                       item.Month += monthNames[dt.getMonth()];
                    //    console.log(item.Year);
                        
                });

               

                   /***********************************************************
                     Facts through Crossfilter
                    ***********************************************************/ 

                     const facts = crossfilter(data);           

                   /***********************************************************
                     Charts Declaration and Tags Mapping 
                   ***********************************************************/

                    const selectquarter = new dc.SelectMenu('#select-quarter');
                    const selectyear = new dc.SelectMenu('#select-year');
                    const selecthalfyearly = new dc.SelectMenu('#select-half-year');
                    const selectmonth = new dc.SelectMenu('#select-month');
                    const selectweek = new dc.SelectMenu('#select-week');
                    const selectday = new dc.SelectMenu('#select-day');
                    const quarterlyPieChart = dc.pieChart("#chart-ring-channel");
                    const orderStatusPieChart = dc.pieChart("#chart-ring-order-status");
                    const paymentStatusBarChart = dc.barChart("#chart-ring-payment-status");
                    const totalCountChart = dc.numberDisplay("#total-sales-count");
                    const totalSalesChart = dc.numberDisplay("#total-sales-value");
                    const totalPaymentChart = dc.numberDisplay("#total-payment-value");


                    /***********************************************************
                     Dimensions  
                    ***********************************************************/

                //   this.dateDim = facts.dimension(item => item.ordeR_DATE );

                //    // Get Descreasing Year
                //   let now = new Date();
                //   this.YR1 = "YR-" + (now.getFullYear()-1);
                //   this.YR2 = "YR-" + now.getFullYear();


                ////    let minDate = dateDim.bottom(1)[0].ordeR_DATE;
                ////let maxDate = dateDim.top(1)[0].ordeR_DATE;

               

                //this.minDate = d3.timeMonth(this.dateDim.bottom(1)[0].ordeR_DATE);
                //this.maxDate = d3.timeMonth(this.dateDim.top(1)[0].ordeR_DATE);

                //console.log(this.dateDim.bottom(1)[0].ordeR_DATE);
                //console.log(this.minDate);
                //console.log(this.YR1);

                //  this.firstyear = this.dateDim.group().reduceSum(function(d) {if (d.ordeR_YEAR===this.YR1) {return d.ordeR_TOTAL;}else{return 0;}});
                //  this.secondyear = this.dateDim.group().reduceSum(function (d) { if (d.ordeR_YEAR===this.YR2) { return d.ordeR_TOTAL; } else { return 0; } });


                    let yearDim = facts.dimension(item => item.ordeR_YEAR);
                    let hyDim = facts.dimension(item => item.ordeR_HALF_YEALY);
                    let qaDim  = facts.dimension(item => item.ordeR_QUARTERLY);
                    let monthDim = facts.dimension(item => item.ordeR_MONTHLY);
                    let weekDim = facts.dimension(item => item.ordeR_WEEKLY);
                    let dayDim = facts.dimension(item => item.ordeR_DAILY);

                    let productDim = facts.dimension(item => item.distributoR_PRODUCT_NAME);
                    let quarterlyDim = facts.dimension(item => item.ordeR_QUARTERLY);
                    let locationDim = facts.dimension(item => item.brancH_NAME);
                    let orderStatusDim = facts.dimension(item => item.ordeR_STATUS);
                    let paymentStatusDim = facts.dimension(item => item.ordeR_PAYMENT_STATUS);
                    let merchantDim = facts.dimension(item => item.retaileR_NAME);
                    let allDim = facts.dimension(item => item);

                
                   /***********************************************************
                    Usable Functions and Filter Effects 
                    ***********************************************************/ 
                  function filter_self_group(chart, group) {
                        return {
                            all: function() {
                                var filters = chart.filters();
                                return group.all().filter(kv => !filters.includes(kv.key));
                            }
                        };
                    }
                  function all_is_nothing(N, filterHandler) {
                        return function(dimension, filters) {
                            // console.log(filters.length, N)
                            if(filters.length === N)
                                filters = [];
                            return filterHandler(dimension, filters);
                        }
                    }
                    function negate_filters(dimension, filters) {
                        if (filters.length === 0)
                            dimension.filter(null);
                        else
                            dimension.filterFunction(function(d) {
                                return !filters.includes(d);
                            })
                        return filters;
                   }

                
                   function arcGen(chart, offset) {
                        var maxRadius =  d3.min([chart.width(), chart.height()]) / 2;
                        var givenRadius = chart.radius();
                        var radius = givenRadius && givenRadius < maxRadius ? givenRadius : maxRadius;
                        return d3.svg.arc()
                            .outerRadius(radius - chart.externalRadiusPadding() + offset)
                            .innerRadius(chart.innerRadius() + offset);
                      }

                   //function add_x_axis(chartToUpdate, displayText) {
                   //   return chartToUpdate.svg()
                   //         .append("text")
                   //         .attr("class", "x-axis-label")
                   //         .attr("text-anchor", "middle")
                   //         .attr("x", chartToUpdate.width() / 2)
                   //         .attr("y", chartToUpdate.height())
                   //         .text(displayText);
                   // }
                   function remove_empty_bins(source_group) {
                            return {
                                all:function () {
                                    return source_group.all().filter(function(d) {
                                        //return Math.abs(d.value) > 0.00001; // if using floating-point numbers
                                        return d.value !== 0; // if integers only
                                    });
                                }
                            };
}

                   /***********************************************************
                    Filters: Dice and Slice 
                    ***********************************************************/ 
                
                     selectyear
                              .dimension(yearDim)
                              .group(yearDim.group())
                              .controlsUseVisibility(true);

                     selecthalfyearly
                              .dimension(hyDim)
                              .group(hyDim.group())
                              .controlsUseVisibility(true);

                     selectquarter
                              .dimension(qaDim)
                              .group(qaDim.group())
                              .controlsUseVisibility(true);

                     selectmonth
                              .dimension(monthDim)
                              .group(monthDim.group())
                              .controlsUseVisibility(true);

                     selectweek
                              .dimension(weekDim)
                              .group(weekDim.group())
                              .controlsUseVisibility(true);

                     selectday
                              .dimension(dayDim)
                              .group(dayDim.group())
                              .controlsUseVisibility(true);


                    /****************************************************************
                    Label: Total Sales Volume Display
                    *****************************************************************/
                   // allDim.filter("Distributor");
                    let totalSalesGroup = allDim.group().reduceSum(item => item.ordeR_TOTAL);
                    
                    totalSalesChart.group(totalSalesGroup);

                    /****************************************************************
                    Label: Total Sales Count Display
                    *****************************************************************/
                    totalCountChart.group(allDim.group().reduceCount());

                    /****************************************************************
                    Label: Total Payment Volume Display
                    *****************************************************************/              
                    let totalPaymentGroup = allDim.group().reduceSum(item => item.paiD_AMOUNT);
                    totalPaymentChart.group(totalPaymentGroup);

                   /*****************************************************************
                    PieChart: Quarterly Sales
                    *****************************************************************/              
                    let quarterlyGroup = quarterlyDim.group().reduceSum(item => item.ordeR_TOTAL);
                    let filteredQuarterlyGroup = remove_empty_bins(quarterlyGroup);

                    quarterlyPieChart
                       .width(300).height(300)
                       .legend(dc.legend().x(80).y(120).itemHeight(13).gap(7).highlightSelected(false))  
                       .dimension(quarterlyDim)
                       .group(filter_self_group(quarterlyPieChart, filteredQuarterlyGroup))
                        .innerRadius(100)
                        .ordering(d => {
                           const order = {
                            'Q-1': 0, 'Q-2': 1, 'Q-3': 2, 'Q-4': 3
                              }
                           return order[d.key];
                         }
                       )
                       .drawPaths(true)
                       .renderLabel(false)
                       .controlsUseVisibility(true)
                       .filterHandler(all_is_nothing(filteredQuarterlyGroup.all().length, negate_filters))
                        .renderTitle(false)
                       .on('pretransition', p => {
                           p.selectAll('.dc-legend-item text')
                                          .text('')
                                        .append('tspan')
                                          .text(d => d.name)
                                        .append('tspan')
                                          .attr('x', 140)
                                          .attr('text-anchor', 'end')
                                          .text(d => Math.round(d.data,2).toLocaleString());
                        })
                       .ordinalColors(["#56B2EA", "#00468C", "#00B22D", "#008C8C", "#DDDDDD"]);

                    quarterlyPieChart.on('renderlet', function(chart) {
                             var regArc = arcGen(chart, 0), bigArc = arcGen(chart, 40);
                             chart.selectAll('g.pie-slice path')
                            .attr('d', function(d, i) {
                                return d3.select(this.parentNode).classed('selected') ? bigArc(d, i) : regArc(d, i);
                            });
                    });

                    /*****************************************************************
                    BarChart: Order Status 
                    ******************************************************************/
                   // orderStatusDim.filter("Distributor");
                    let orderStatusGroup = orderStatusDim.group().reduceSum(item => item.ordeR_TOTAL);
                    let filteredStatusGroup = remove_empty_bins(orderStatusGroup);


                      orderStatusPieChart
                       .width(300).height(300)
                       .legend(dc.legend().x(60).y(120).itemHeight(13).gap(2).highlightSelected(true))  
                       .dimension(orderStatusDim)
                       .group(filter_self_group(quarterlyPieChart, filteredStatusGroup))
                        .innerRadius(100)
                       .drawPaths(true)
                       .renderLabel(false)
                       .controlsUseVisibility(true)
                       .filterHandler(all_is_nothing(filteredStatusGroup.all().length, negate_filters))
                        .renderTitle(false)
                       .on('pretransition', p => {
                           p.selectAll('.dc-legend-item text')
                                          .text('')
                                        .append('tspan')
                                          .text(d => d.name)
                                        .append('tspan')
                                          .attr('x', 180)
                                          .attr('text-anchor', 'end')
                                          .text(d => Math.round(d.data,2).toLocaleString());
                        })
                       .ordinalColors(["#56B2EA", "#00468C", "#00B22D", "#008C8C", "#DDDDDD"]);

                    orderStatusPieChart.on('renderlet', function(chart) {
                             var regArc = arcGen(chart, 0), bigArc = arcGen(chart, 40);
                             chart.selectAll('g.pie-slice path')
                            .attr('d', function(d, i) {
                                return d3.select(this.parentNode).classed('selected') ? bigArc(d, i) : regArc(d, i);
                            });
                    });


                    /******************************************************************
                    PIE CHART: Payment Status
                    *******************************************************************/
                    //let paymentStatusDim2 = paymentStatusDim.filter((item) => { if (item==="NOT PAID" || item==="PAID" || item==="PARTLY PAID") {return item;} });  
                    let paymentStatusGroup = paymentStatusDim.group().reduceSum(item => item.ordeR_TOTAL);


                     paymentStatusBarChart
		                    .width(300)
                            .height(300)
                            .x(d3.scaleOrdinal())
                            .margins({left: 75, top: 10, right: 10, bottom: 35})
                            .xUnits(dc.units.ordinal)
                            .xAxisLabel("Payment Status")
   	                        .yAxisLabel("Payment Amount")
                            .elasticY(true)
                            .dimension(paymentStatusDim)
                            .group(paymentStatusGroup)
                            .ordinalColors(["#56B2EA", "#00468C", "#00B22D", "#008C8C", "#DDDDDD"]);

                  

                    /*********************************************************************
                    MAP: Location 
                    **********************************************************************/
                    const locationRowChart   = dc.rowChart("#chart-ring-location");
                    let locationGroup = locationDim.group().reduceSum(item => item.ordeR_TOTAL);

                   locationRowChart
                    .width(300)
                    .height(300)
                    .dimension(locationDim)
                    .group(locationGroup)
                    .label((d) => {
                        return d.key;
                    })
                    .title((d) => {
                        return Math.round(d.value, 2).toLocaleString();
                    })
                    .renderTitleLabel(true)
                    .elasticX(true)
                    .xAxis().ticks(4);

                


                /*********************************************************************************
                ROW CHART: Top Performing Products
                **********************************************************************************/

                var productRowChart = dc.rowChart("#chart-top-product");   
                var productGroup = productDim.group().reduceSum(item => item.ordeR_TOTAL);
                  
                productRowChart
                    .width(650)
                    .height(300)
                    .dimension(productDim)
                    .group(productGroup)
                    .label((d) => {
                        return d.key;
                    })
                    .title((d) => {
                        return Math.round(d.value, 2).toLocaleString();
                    })
                    .renderTitleLabel(true)
                    .elasticX(true)
                    .xAxis().ticks(4);
                

                productRowChart.rowsCap(5);

                /***************************************************************************
                ROW CHART: Top Performing Merchants
                ****************************************************************************/

                var merchantRowChart = dc.rowChart("#chart-top-retailer");   
                var merchantGroup = merchantDim.group().reduceSum(item => item.ordeR_TOTAL);
                
                
                merchantRowChart
                    .width(650)
                    .height(300)
                    .group(merchantGroup)
                    .dimension(merchantDim)
                        .label((d) => {
                            return d.key;
                        })
                        .title((d) => {
                          return Math.round(d.value,2).toLocaleString(); 
                        })
                    .renderTitleLabel(true)
                        .elasticX(true)
                        .xAxis().ticks(4);

                merchantRowChart.rowsCap(5);

                //add_x_axis(merchantRowChart, "Total Order");

                /**********************************************************************************
                AREA CHART: Monthly Performance 
                ***********************************************************************************/

	               const monthlyPerformanceChart  = dc.lineChart("#chart-line-monthly-performance");
                   let dateDim = facts.dimension(item => item.ordeR_DATE );

                    // Get Descreasing Year
                   let now = new Date();
                   let YR1 = "YR-" + (now.getFullYear()-1);
                   let YR2 = "YR-" + now.getFullYear();


                //    let minDate = dateDim.bottom(1)[0].ordeR_DATE;
                //let maxDate = dateDim.top(1)[0].ordeR_DATE;


                //    var minDate = d3.timeMonth(dateDim.bottom(1)[0].ordeR_DATE);
                //var maxDate = d3.timeMonth(dateDim.top(1)[0].ordeR_DATE);

                 var minDate = new Date("01/01/1900");
	             var maxDate = new Date("12/30/1900");

                    let firstyear=dateDim.group().reduceSum(function(d) {if (d.ordeR_YEAR===YR1) {return d.ordeR_TOTAL;}else{return 0;}});
                    let secondyear=dateDim.group().reduceSum(function(d) {if (d.ordeR_YEAR===YR2) {return d.ordeR_TOTAL;}else{return 0;}});
                  

                  monthlyPerformanceChart
                    .width(1300).height(300)
                    .dimension(dateDim)
                    .group(firstyear, YR1)
                      .stack(secondyear, YR2)
                    .renderArea(true)
                    .x(d3.scaleTime().domain([minDate, maxDate]))
                    .margins({left: 75, top: 10, right: 10, bottom: 35})
                    .elasticX(true)
                    .brushOn(true)
                    .legend(dc.legend().x(100).y(10).itemHeight(16).gap(7))
                    .yAxisLabel("Monthly Order Per Year")
                    .ordinalColors(["#56B2EA", "#00468C", "#00B22D", "#008C8C", "#DDDDDD"]);

               // this.getMonthlyPerformanceReport();

                dc.renderAll();

                d3.selectAll('a#all').on('click', function() {
				    dc.filterAll();
				    dc.renderAll();
			    });
            
            },
            getMonthlyPerformanceReport() {

                 const monthlyPerformanceChart  = dc.lineChart("#chart-line-monthly-performance");
                   
                  monthlyPerformanceChart
                    .width(1300).height(300)
                    .dimension(this.dateDim)
                    .group(this.firstyear, this.YR1)
                      .stack(this.secondyear, this.YR2)
                    .renderArea(true)
                    .x(d3.scaleTime().domain([this.minDate, this.maxDate]))
                    .margins({left: 75, top: 10, right: 10, bottom: 35})
                    .elasticX(true)
                    .brushOn(true)
                    .legend(dc.legend().x(100).y(10).itemHeight(16).gap(7))
                    .yAxisLabel("Monthly Order Per Year")
                    .ordinalColors(["#56B2EA", "#00468C", "#00B22D", "#008C8C", "#DDDDDD"]);

            },
           async fetchData() {
             this.isBusy = true;
              await this.$http
                .get("/Analytics/getAnalyticsData?parentId=" + this.user.parentId + "&type=" +
                        this.type)
                .then((res) => {
                    this.generateChart(res.body.data);
                    this.count = res.body.count;
                    this.isBusy = false;
                })
                .catch((err) => {
                    // console.log(err);
                    this.isBusy = false;
                });
             this.isBusy = false;

            },
            printFilter(filter) {               
             return function print_filter(filter) {
                  var f=eval(filter);
                  if (typeof(f.length) != "undefined") {}else{}
                  if (typeof(f.top) != "undefined") {f=f.top(Infinity);}else{}
                  if (typeof(f.dimension) != "undefined") {f=f.dimension(function(d) { return "";}).top(Infinity);}else{}
                  console.log(filter+"("+f.length+") = "+JSON.stringify(f).replace("[","[\n\t").replace(/}\,/g,"},\n\t").replace("]","\n]"));
              }
            },
            formatMoney(val) {
                return helper.formatMoney(val);
            },
            formatNumber(val) {
                return helper.formatNumber(val);
            },
            formatDate(val) {
                return helper.formatDate(val);
            },
        },
        mounted() { 
            this.fetchData();
           }
    }
</script>
