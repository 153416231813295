<template>
  <div class="modal fade" id="mod-group-add" role="dialog">
    <div class="modal-dialog mx-auto">
      <form @submit.prevent="submit" class="modal-content p-[24px] bg-[#fff]">
        <div class="flex items-start justify-between p-0 m-0">
          <h4
            class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
          >
            <span v-if="showUpdate">Update group</span>
            <span v-else>Create new group</span>
          </h4>
          <button
            class="text-2xl text-[#353535] outline-none focus:outline-none"
            type="button"
            data-dismiss="modal"
            @click.prevent="cancel"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body m-0 p-0">
          <div class="mb-[16px]">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Name</label
            >
            <div>
              <input
                :class="{ 'is-invalid': $v.name.$error }"
                type="text"
                v-model.trim="$v.name.$model"
                placeholder="Enter group name"
                class="bg-transparent border-[#d0d5dd] border-solid border-[1.5px] p-[14px] rounded-[8px] w-full"
              />
              <div class="invalid-feedback" v-if="!$v.name.required">
                Field is required
              </div>
              <div class="invalid-feedback" v-if="!$v.name.minLength">
                Name must have at least
                {{ $v.name.$params.minLength.min }} letters.
              </div>
            </div>
          </div>
          <div class="mb-[16px]">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Group Code</label
            >
            <div>
              <input
                class="bg-transparent border-[#d0d5dd] border-solid border-[1.5px] p-[14px] rounded-[8px] w-full"
                :class="{ 'is-invalid': $v.code.$error }"
                type="text"
                v-model.trim="$v.code.$model"
                placeholder="Enter group code"
              />
              <div class="invalid-feedback" v-if="!$v.code.required">
                Field is required
              </div>
              <div class="invalid-feedback" v-if="!$v.code.minLength">
                Name must have at least
                {{ $v.code.$params.minLength.min }} letters.
              </div>
              <div
                class="invalid-feedback"
                v-if="!$v.code.alphaNumAndDotValidator"
              >
                Group code must be alpha numeric
              </div>
            </div>
          </div>
          <div class="mb-[16px]">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Description</label
            >
            <div>
              <p v-show="false">{{ groupData }}</p>
              <textarea
                :class="{ 'is-invalid': $v.description.$error }"
                v-model.trim="$v.description.$model"
                class="bg-transparent border-[#d0d5dd] border-solid border-[1.5px] p-[14px] rounded-[8px] w-full"
                placeholder="Enter group description"
              ></textarea>
              <div class="invalid-feedback" v-if="!$v.description.required">
                Field is required
              </div>
            </div>
          </div>
          <div>
            <div
              class="flex items-center gap-[10px] max-w-max"
              @click="active = !active"
            >
              <button
                type="button"
                class="relative inline-flex items-center flex-shrink-0 h-[24px] px-[3px] w-[46px] transition-all duration-200 ease-in-out border-[0.5px] border-[#E4E4E7] shadow-sm rounded-[10px] cursor-pointer w-11 focus:outline-none"
                role="switch"
                :aria-checked="active"
                :class="{
                  'bg-[#2463EB]': active,
                  'bg-[#E4E4E7]': !active,
                }"
              >
                <span
                  aria-hidden="true"
                  class="inline-block w-[21px] h-[16px] bg-white transition duration-200 ease-in-out transform bg-gray-400 rounded-full pointer-events-none"
                  :class="{
                    'translate-x-5 bg-white': active,
                    'translate-x-0 bg-gray-400': !active,
                  }"
                ></span>
              </button>
              <span class="cursor-pointer">
                <span
                  class="text-[#353535] text-[16px] font-normal leading-[20px]"
                >
                  This group is active
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="flex items-center gap-[8px] mt-[16px]">
          <button
            class="border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[16px] sm:px-[24px] text-[14px] md:text-[16px] rounded-[8px] font-medium leading-[20px] w-full outline-none focus:outline-none"
            type="button"
            @click.prevent="cancel"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[16px] sm:px-[24px] text-[14px] md:text-[16px] rounded-[8px] text-white font-medium leading-[20px] w-full outline-none focus:outline-none"
            :disabled="isBusyModal"
            type="submit"
          >
            <span class="text-[14px] md:text-[16px]" v-if="isBusyModal"
              >Busy...</span
            >
            <span class="text-[14px] md:text-[16px]" v-else-if="showUpdate">
              Update Group</span
            >
            <span class="text-[14px] md:text-[16px]" v-else>Add Group</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required, helpers, minLength } from "vuelidate/lib/validators";
const alphaNum = helpers.regex("alphaNum", /^[a-zA-Z0-9]+$/);

export default {
  name: "CreateDistributorGroup",
  props: ["group", "ActionComplete"],

  data() {
    return {
      showUpdate: false,
      isBusy: false,
      isBusyModal: false,
      id: this.group ? this.group.id : "",
      name: this.group ? this.group.name : "",
      code: this.group ? this.group.code : "",
      description: this.group ? this.group.description : "",
      active: this.group ? this.group.active : false,
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
    groupData() {
      if (this.group) {
        this.name = this.group.name;
        this.code = this.group.code;
        this.description = this.group.description;
        this.id = this.group.id;
        this.active = this.group.active;
        this.showUpdate = true;
        return this.group;
      }
      this.showUpdate = false;
      this.reset();
      return {};
    },
  },

  validations: {
    name: {
      required,
      minLength: minLength(3),
    },

    code: {
      required,
      minLength: minLength(3),
      alphaNum,
    },

    description: {
      required,
    },
  },

  methods: {
    async submit() {
      if (this.showUpdate) {
        await this.update();
      } else {
        await this.create();
      }
    },

    async create() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isBusyModal = false;
        this.isBusy = false;
      } else {
        try {
          this.isBusyModal = true;
          this.isBusy = true;
          const data = {
            name: this.name,
            Code: this.code,
            active: this.active,
            description: this.description,
            parentId: this.user.parentId,
            type: "Distributor",
          };
          const response = await this.$http.post("/Groups/create/", data);
          if (response.status === 201 && response.statusText === "Created") {
            this.reset();
            await this.ActionComplete();
            this.$toast.success("Group created successfully.", {
              icon: true,
              rtl: false,
            });
          }
          this.isBusyModal = false;
          this.isBusy = false;
        } catch (error) {
          console.error(error);
          this.isBusyModal = false;
          this.isBusy = false;
        }
      }
    },

    async update() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isBusyModal = false;
        this.isBusy = false;
      } else {
        try {
          this.isBusyModal = true;
          this.isBusy = true;

          var data = {
            name: this.name,
            code: this.code,
            active: this.active,
            id: this.id,
            description: this.description,
            type: "Distributor",
          };

          const response = await this.$http.put(
            "/Groups/update/" + data.id,
            data
          );

          if (response.status === 200) {
            this.reset();
            await this.ActionComplete();
          }

          this.isBusyModal = false;
          this.isBusy = false;
        } catch (error) {
          console.error(error);
          this.isBusy = false;
          this.isBusyModal = false;
        }
      }
    },

    reset() {
      this.name = "";
      this.code = "";
      this.description = "";
      this.active = false;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    cancel() {
      this.reset();
      this.$emit("hide");
      // this.showUpdate = false;
    },
  },
};
</script>
