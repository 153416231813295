<template>
	<div>
		<div>
			<label class="merchant-login-label"> BUSINESS NAME* </label>
			<div
				:class="['merchant-input-div', { 'is-invalid': credentialValidation.businessName }]"
			>
				<input
					type="text"
					class="input"
					placeholder="Enter business name"
					v-model="businessName"
				/>
			</div>
			<p v-if="credentialValidation.businessName">
				<img src="/assets/img/error-icon-new.svg" alt="error" />
				<span class="login-error">Business name cannot be empty</span>
			</p>
		</div>

		<div>
			<label class="merchant-login-label mt-2"> SELECT INDUSTRY </label>
			<div class="merchant-input-div">
				<select v-model="industry" placeholder="Select industry" class="input">
					<option v-for="(el, index) in industryOptions" :key="index" :value="el.id">{{
						el.name
					}}</option>
				</select>
			</div>
		</div>

		<div>
			<label class="merchant-login-label mt-2"> STATE* </label>
			<div :class="['merchant-input-div', { 'is-invalid': credentialValidation.state }]">
				<select class="input" v-model="state" placeholder="Select state">
					<option disabled selected>Select State</option>
					<option v-for="(el, index) in states" :key="index" :value="el">{{ el }}</option>
				</select>
			</div>
			<p v-if="credentialValidation.state">
				<img src="/assets/img/error-icon-new.svg" alt="error" />
				<span class="login-error">Please select a state</span>
			</p>
		</div>

		<div>
			<label class="merchant-login-label mt-2"> LOCAL GOVERNMENT AREA </label>
			<div :class="['merchant-input-div', { 'is-invalid': credentialValidation.lga }]">
				<select class="input" v-model="lga" placeholder="Select lga">
					<option v-for="(el, index) in lgas" :key="index" :value="el">{{ el }}</option>
				</select>
			</div>
			<p v-if="credentialValidation.lga">
				<img src="/assets/img/error-icon-new.svg" alt="error" />
				<span class="login-error">Please select a local government area</span>
			</p>
		</div>

		<div>
			<label class="merchant-login-label mt-2"> BUSINESS ADDRESS </label>
			<div
				:class="[
					'merchant-input-div',
					{ 'is-invalid': credentialValidation.businessAddress },
				]"
			>
				<input
					class="input"
					type="text"
					placeholder="Enter business address"
					v-model="businessAddress"
				/>
			</div>
			<p v-if="credentialValidation.businessAddress">
				<img src="/assets/img/error-icon-new.svg" alt="error" />
				<span class="login-error">Please input your business address</span>
			</p>
		</div>

		<p class="mt-2">
			By clicking the "Create My Account" button, you agree to
			<span style="font-weight: 700">GrowTrade's{{ " " }}</span>
			<span style="color:#38369a">Terms Of Acceptable Use</span>
		</p>

		<button @click="register" class="login-submit-btn" :disabled="isBusy">
			<span v-if="isBusy" class="spinner-border spinner-border-sm" role="status">
				<i class="sr-only"></i>
			</span>
			<span v-else> Create My Account </span>
		</button>
		<p class="signup-text">
			Already have an account?
			<button type="button" class="signup-btn" @click="goToLogin">Sign In</button>
		</p>
		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-contract"
			role="dialog"
		>
			<div class="modal-dialog  modal-lg">
				<div class="modal-content">
					<div class="modal-body">
						<div class="p-2">
							<h1 class="text-bold">Hi {{ firstName }},</h1>
							<p class="font-weight-light text-muted">
								One more thing... You need to sign a contract with Wekurnect limited
								on behalf of your business. Please read through carefully and sign
								using your name
							</p>
							<div class="mb-2" v-if="toggleForm">
								<div
									class="d-flex align-items-center pt-3 justify-content-between py-1"
								>
									<p class="text-muted font-weight-light">
										Please fill this information
									</p>
								</div>
								<div>
									<div class="form-row">
										<div class="form-group col-md-6">
											<label class="merchant-login-label">FIRST NAME</label>
											<input
												type="text"
												:class="[
													'form-control',
													{ 'is-invalid': v1.firstName },
												]"
												placeholder="Enter first name"
												v-model="contractOffer.firstName"
											/>
											<p
												v-if="v1.firstName"
												class="text-danger font-weight-light text-sm"
											>
												Enter a valid first name.
											</p>
										</div>
										<div class="form-group col-md-6">
											<label class="merchant-login-label">LAST NAME</label>
											<input
												type="text"
												:class="[
													'form-control',
													{ 'is-invalid': v1.lastName },
												]"
												placeholder="Enter last name"
												v-model="contractOffer.lastName"
											/>
											<p
												v-if="v1.lastName"
												class="text-danger font-weight-light text-sm"
											>
												Enter a valid last name.
											</p>
										</div>
									</div>
									<div class="form-row">
										<div class="form-group col-md-6">
											<label class="merchant-login-label"
												>EMAIL ADDRESS</label
											>
											<input
												type="email"
												:class="[
													'form-control',
													{ 'is-invalid': v1.email },
												]"
												placeholder="Enter email address"
												v-model="contractOffer.email"
											/>
											<p
												v-if="v1.email"
												class="text-danger font-weight-light text-sm"
											>
												Enter a valid email.
											</p>
										</div>
										<div class="form-group col-md-6">
											<label class="merchant-login-label"
												>BUSINESS NAME</label
											>
											<input
												type="text"
												:class="[
													'form-control',
													{ 'is-invalid': v1.businessName },
												]"
												placeholder="Enter business name"
												v-model="contractOffer.businessName"
											/>
											<p
												v-if="v1.businessName"
												class="text-danger font-weight-light text-sm"
											>
												Enter a valid business name.
											</p>
										</div>
									</div>
									<div class="form-row">
										<div class="form-group col-md-6">
											<label class="merchant-login-label"
												>BUSINESS ADDRESS</label
											>
											<input
												type="text"
												:class="[
													'form-control',
													{ 'is-invalid': v1.businessAddress },
												]"
												placeholder="Enter business address"
												v-model="contractOffer.businessAddress"
											/>
											<p
												v-if="v1.businessAddress"
												class="text-danger font-weight-light text-sm"
											>
												Enter a valid business address.
											</p>
										</div>
										<div class="form-group col-md-6">
											<label class="merchant-login-label"
												>REGISTRATION NUMBER</label
											>
											<input
												type="text"
												:class="['form-control']"
												placeholder="Enter registration number"
												v-model="contractOffer.registrationNumber"
											/>
										</div>
									</div>
									<div class="form-row">
										<div class="form-group col-md-6">
											<label class="merchant-login-label"
												>POSITION IN COMPANY</label
											>
											<input
												type="text"
												:class="[
													'form-control',
													{ 'is-invalid': v1.companyPosition },
												]"
												placeholder="Enter Position in companny"
												v-model="contractOffer.companyPosition"
											/>
											<p
												v-if="v1.companyPosition"
												class="text-danger font-weight-light text-sm"
											>
												Enter a valid company position.
											</p>
										</div>
										<div class="form-group col-md-6">
											<label class="merchant-login-label">PHONE</label>
											<input
												type="tel"
												:class="[
													'form-control',
													{ 'is-invalid': v1.phone },
												]"
												placeholder="Enter phone number"
												v-model="contractOffer.phone"
											/>
											<p
												v-if="v1.phone"
												class="text-danger font-weight-light text-sm"
											>
												Enter a valid phone number.
											</p>
										</div>
									</div>
									<div class="mb-3">
										<button
											class="btn btn-primary float-right"
											type="button"
											@click="previewContract"
										>
											<span v-if="isBusy">Busy...</span>
											<span v-else>Preview Contract</span>
										</button>
									</div>
								</div>
							</div>
							<div v-if="!toggleForm">
								<div
									@scroll="onScroll"
									class=""
									style="max-height:500px; overflow-y: scroll;"
								>
									<div v-html="contractString"></div>
								</div>
								<div class="col-12 pt-1">
									<label
										class="custom-control custom-checkbox custom-control-inline"
									>
										<input
											class="custom-control-input text-primary"
											type="checkbox"
											:disabled="!isChecked"
											v-model="contractOffer.isAccepted"
										/>
										<span class="custom-control-label text-muted"
											>I agree to the terms and conditions in this contract. I
											also accept that my name is used as digital
											signature</span
										>
									</label>
								</div>
							</div>
						</div>
					</div>
					<div v-if="!toggleForm" class="modal-footer">
						<button
							class="btn btn-primary"
							type="button"
							:disabled="!contractOffer.isAccepted || isBusy"
							@click.prevent="submitRegister"
						>
							<span v-if="isBusy">Busy...</span>
							<span v-else>Continue</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	/* eslint-disable */
	import { helper } from "@/helper";

	export default {
		name: "distributor-business-details",
		components: {},
		props: ["payload"],

		data() {
			return {
				checked: false,
				isbusy: false,
				passwordType: "password",
				industryOptions: null,
				states: helper.getStates(),
				businessName: null,
				businessAddress: null,
				industry: null,
				state: null,
				lga: null,
				isChecked: false,
				toggleContract: false,
				toggleForm: true,
				contractString: "",
				credentialValidation: {
					businessName: false,
					state: false,
					lga: null,
					businessAddress: null,
				},
				contractOffer: {
					firstName: null,
					lastNName: null,
					email: null,
					businessName: null,
					businessAddress: null,
					registrationNumber: null,
					companyPosition: null,
					phone: null,
					isAccepted: false,
				},
				v1: {
					firstName: null,
					lastName: null,
					email: null,
					businessName: null,
					businessAddress: null,
					companyPosition: null,
					phone: null,
					isAccepted: false,
				},
				isBusy: false,
				firstName: "",
			};
		},

		mounted() {
			this.getIndustries();
		},

		beforeCreate() {},

		created() {},

		computed: {
			lgas: function() {
				return helper.getLgas(this.state);
			},
		},

		methods: {
			showContract() {
				$("#mod-contract").modal("show");
			},

			onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
				if (scrollTop + clientHeight >= scrollHeight) {
					this.isChecked = true;
				}
			},

			showContractPreview() {
				this.toggleContract = !this.toggleContract;
				this.toggleForm = !this.toggleForm;
			},

			togglePassword() {
				if (this.passwordType === "password") {
					this.passwordType = "text";
				} else {
					this.passwordType = "password";
				}
			},

			async getIndustries() {
				await this.$http
					.get(`/Industries`)
					.then(function(res) {
						this.industryOptions = res.body;
						this.isBusy = false;
					})
					.catch(function() {
						this.isBusy = false;
					});
			},

			async previewContract() {
				this.isBusy = true;
				let data = {
					firstName: this.contractOffer.firstName,
					lastName: this.contractOffer.lastName,
					emailAddress: this.contractOffer.email,
					businessName: this.contractOffer.businessName,
					businessAddress: this.contractOffer.businessAddress,
					position: this.contractOffer.companyPosition,
					registrationNumber: this.contractOffer.registrationNumber,
					phoneNumber: this.contractOffer.phone,
				};
				if (this.validateContractInput()) {
					await this.$http
						.post(`/Distributor/SignUpContractTemplate`, data)
						.then(function(res) {
							if (res.ok) {
								this.contractString = res.body;
								this.toggleForm = !this.toggleForm;
								this.isBusy = false;
							}
						})
						.catch(function() {
							this.isBusy = false;
							this.contractOffer.firstName = null;
							this.contractOffer.lastName = null;
							this.contractOffer.email = null;
							this.contractOffer.businessName = null;
							this.contractOffer.businessAddress = null;
							this.contractOffer.companyPosition = null;
							this.contractOffer.phone = null;
							this.contractOffer.isAccepted = false;
						});
				}
			},

			async submitRegister() {
				this.isBusy = true;
				let data = {
					...this.payload,
					businessName: this.businessName,
					industryId: this.industry,
					state: this.state,
					lga: this.lga,
					businessAddress: this.businessAddress,
					contractDetail: {
						firstName: this.contractOffer.firstName,
						lastName: this.contractOffer.lastName,
						emailAddress: this.contractOffer.email,
						businessName: this.contractOffer.businessName,
						businessAddress: this.contractOffer.businessAddress,
						position: this.contractOffer.companyPosition,
						registrationNumber: this.contractOffer.registrationNumber,
						phoneNumber: this.contractOffer.phone,
						IsSigned: this.contractOffer.isAccepted,
					},
				};
				await this.$http
					.post(`/Distributor`, data)
					.then(function(res) {
						$("#mod-growtrade-onboarding-status").modal("show");
						this.isBusy = false;
						$("#mod-contract").modal("hide");
						window.open(res.body, "_blank");
						this.businessName = null;
						this.industry = null;
						this.state = null;
						this.lga = null;
						this.businessAddress = null;
						this.contractOffer.firstName = null;
						this.contractOffer.lastName = null;
						this.contractOffer.email = null;
						this.contractOffer.businessName = null;
						this.contractOffer.businessAddress = null;
						this.contractOffer.companyPosition = null;
						this.contractOffer.registrationNumber = null;
						this.contractOffer.phone = null;
						this.contractOffer.isAccepted = null;
					})
					.catch(function() {
						this.isBusy = false;
						this.businessName, (this.industry = null);
						this.state = null;
						this.lga = null;
						this.contractOffer.firstName = null;
						this.contractOffer.lastName = null;
						this.contractOffer.email = null;
						this.contractOffer.businessName = null;
						this.contractOffer.businessAddress = null;
						this.contractOffer.companyPosition = null;
						this.contractOffer.registrationNumber = null;
						this.contractOffer.phone = null;
						this.contractOffer.isAccepted = null;
					});
				// await this.$http
				// 	.put(
				// 		`/${this.user.isSalesUser ? "SalesUsers" : "Users"}/update/` + data.id,
				// 		data,
				// 	)
				// 	.then(function(res) {
				// 		this.$store.commit("setCurrentUser", this.user);

				// 		this.$toast.success("Your profile details have been updated.", {
				// 			icon: false,
				// 			rtl: false,
				// 		});
				// 		this.isBusy = false;

				// 		this.isBusyProfile = false;
				// 	})
				// 	.catch(function() {
				// 		var currentUser = clone(this.$store.state.currentUser);
				// 		this.user.phoneNumber = currentUser.phoneNumber;
				// 		this.user.emailAddress = currentUser.emailAddress;
				// 		this.user.displayName = currentUser.displayName;
				// 		this.isBusyProfile = false;
				// 		this.isBusy = false;
				// 	});
			},

			async register() {
				this.isBusy = true;
				this.$emit("changeStep", 2);
				this.firstName = this.payload.firstName;
				this.contractOffer.businessName = this.businessName;
				if (this.validateUserInfo()) {
					$("#mod-contract").modal("show");
					this.isBusy = false;
				} else {
					this.isBusy = false;
				}
			},

			validateUserInfo() {
				this.credentialValidation = {
					businessName: false,
					state: false,
					lga: false,
					businessAddress: false,
				};

				let isValid = true;

				if (!this.businessName) {
					this.credentialValidation.businessName = true;
					isValid = false;
				}

				if (!this.state) {
					this.credentialValidation.state = true;
					isValid = false;
				}

				if (!this.lga) {
					this.credentialValidation.lga = true;
					isValid = false;
				}

				if (!this.businessAddress) {
					this.credentialValidation.businessAddress = true;
					isValid = false;
				}

				return isValid;
			},
			validateContractInput() {
				this.v1 = {
					firstName: false,
					lastName: false,
					email: false,
					businessName: false,
					businessAddress: false,
					companyPosition: false,
					phone: false,
				};
				var isValid = true;

				if (!this.contractOffer.firstName) {
					this.v1.firstName = true;
					isValid = false;
				}

				if (!this.contractOffer.lastName) {
					this.v1.lastName = true;
					isValid = false;
				}
				if (!this.contractOffer.email && !helper.validateEmail(this.contractOffer.email)) {
					this.v1.email = true;
					isValid = false;
				}

				if (!this.contractOffer.businessName) {
					this.v1.businessName = true;
					isValid = false;
				}

				if (!this.contractOffer.businessAddress) {
					this.v1.businessAddress = true;
					isValid = false;
				}

				if (!this.contractOffer.companyPosition) {
					this.v1.companyPosition = true;
					isValid = false;
				}
				if (!this.contractOffer.phone && !helper.validatePhone(this.contractOffer.phone)) {
					this.v1.phone = true;
					isValid = false;
				}

				return isValid;
			},

			goToLogin() {
				this.$router.push(`/growtrade-login?client=distributor`);
			},
		},
	};
</script>

<style scoped>
	.merchant-login-label {
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		color: #18191f;
		padding: 0;
		margin-bottom: 16px;
	}

	.merchant-input-div {
		border: 1px solid #a6a6a6;
		box-sizing: border-box;
		border-radius: 8px;
		width: 426px;
		height: 64px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 32px;
	}

	.merchant-input-div:focus-within {
		border: 1px solid #18191f;
		box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
	}

	.togglePassword {
		border: none;
		outline: none;
		background: transparent;
		font-size: 14px;
		line-height: 17px;
		color: #38369a;
	}
	.input {
		border: none;
		outline: none;
		width: 100%;
		height: 80%;
	}

	.login-submit-btn {
		background: #675ce8;
		border-radius: 8px;
		width: 426px;
		height: 64px;
		outline: none;
		font-weight: bold;
		font-size: 18px;
		line-height: 22px;
		color: #ffffff;
		margin: 16px 0 8px;
		border: none;
	}

	.signup-btn {
		border: none;
		font-size: 18px;
		font-weight: 700;
		padding: 0;
		line-height: 28px;
		outline: none;
		background: transparent;
		color: #001688;
	}

	.signup-text {
		font-size: 18px;
		color: rgba(24, 25, 31, 0.5);
		margin-bottom: 60px;
	}

	.is-invalid {
		border: 1px solid #ff6767;
	}

	.text-sm {
		font-size: 10px;
	}

	.login-error {
		font-size: 12px;
		line-height: 15px;
		color: #ff6767;
		margin-left: 5px;
	}
	hr {
		margin: 0 !important;
	}
	.container {
		margin: 0 !important;
	}
	@media screen and (max-width: 767px) {
		.merchant-input-div,
		.login-submit-btn {
			width: 100%;
		}
	}
</style>
