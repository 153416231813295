<template>
  <div class="row min-h-screen shadow-none">
    <div class="col-lg-12">
      <div :class="['be-loading', { 'be-loading-active': isBusy }]">
        <div
          class="w-full pb-2 flex flex-col justify-start items-start gap-[15px]"
        >
          <div class="border-b-2 border-solid border-[#2463eb] pb-[8px]">
            <h2 class="text-[#2463eb] text-[14px] leading-[20px] font-semibold">
              All Groups
            </h2>
          </div>
          <div
            class="flex xs:flex-col lg:flex-row xs:justify-start lg:justify-between w-full xs:items-start xs:gap-4 lg-gap-2 lg:items-center"
          >
            <p class="text-[#353535] text-[14px] leading-[20px] font-semibold">
              Explore and manage your groups effortlessly with this
              comprehensive list
            </p>
            <div
              class="flex gap-1 sm:gap-3 flex-wrap xs:justify-start lg:justify-center xs:items-start lg:items-center"
            >
              <button
                @click="viewFilter"
                class="border-[1px] border-[#96a0b5] rounded-[8px] text-[#96a0b5] font-normal text-[14px] border-solid px-[12px] sm:px-[24px] py-[12px] flex items-center gap-[5px] outline-none focus:outline-none"
              >
                <span><img src="/assets/icon/sort.svg" alt=""/></span>
                Filters
              </button>
              <button
                class="border-[1px] bg-[#2463eb] rounded-[8px] text-white font-normal text-[14px] border-solid px-[12px] sm:px-[24px] py-[12px] outline-none focus:outline-none"
                v-if="hasPermission('Group_Create')"
                @click="addGroup"
              >
                <span class="feather icon-plus text-white"></span> New Group
              </button>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table p-0 m-0 dataTable no-footer">
            <thead class="bg-white">
              <tr class="py-[5px]">
                <th class="text-[12px] leading-[18px] text-[#000000]">Name</th>
                <th class="text-[12px] leading-[18px] text-[#000000]">
                  Description
                </th>
                <th class="text-[12px] leading-[18px] text-[#000000]">Code</th>
                <th class="text-[12px] leading-[18px] text-[#000000]">
                  Created
                </th>
                <th class="text-[12px] leading-[18px] text-[#000000]">
                  Status
                </th>
                <th class="text-[12px] leading-[18px] text-[#000000]">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr v-for="g in groups" :key="g.id">
                <td class="text-[14px] font-normal leading-[16.94px]">
                  {{ g.name }}
                </td>
                <td class="text-[14px] font-normal leading-[16.94px]">
                  {{ g.description.substring(0, 40) }}
                </td>
                <td class="text-[14px] font-normal leading-[16.94px]">
                  {{ g.code }}
                </td>
                <td class="text-[14px] font-normal leading-[16.94px]">
                  {{ formatDate(g.created) }}
                </td>
                <td>
                  <span
                    class="flex items-center gap-[4px] max-w-max w-full"
                    :class="[
                      'badge',
                      { 'badge-success': g.active, 'badge-danger': !g.active },
                    ]"
                  >
                    <span
                      class="w-[10px] h-[10px] rounded-[1px]"
                      :style="{
                        backgroundColor: g.active ? '#16a249' : '#EF5253',
                      }"
                    ></span>
                    <span class="text-[12px] font-normal leading-[16.94px]">{{
                      g.active ? "Active" : "Inactive"
                    }}</span>
                  </span>
                </td>
                <td class="text-[14px] font-normal leading-[16.94px]">
                  <button
                    class="text-[14px] font-normal leading-[16.94px] outline-none focus:outline-none"
                    v-if="hasPermission('Group_Update')"
                    @click="viewGroup(g)"
                  >
                    View
                  </button>
                </td>
                <!-- <td>
                  <button
                    class="
                              btn btn-outline-primary btn-sm
                              dropdown-toggle
                            "
                    type="button"
                    id="dropdownMenu2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Actions
                  </button>
                  <div
                    class="dropdown-menu px-1"
                    aria-labelledby="dropdownMenu2"
                  >
                    <a
                      class="dropdown-item"
                      v-if="hasPermission('Group_Update')"
                      href="#"
                      @click.prevent="editGroup(g)"
                    >
                      Edit
                    </a>
                    <div class="dropdown-divider"></div>
                    <a
                      class="dropdown-item"
                      v-if="hasPermission('Group_View_Members')"
                      :href="`groups/${g.id}/distributor-association`"
                    >
                      Distributor Association
                    </a>
                    <div class="dropdown-divider"></div>
                    <a
                      class="dropdown-item"
                      v-if="hasPermission('Price_Option_View')"
                      :href="`groups/${g.id}/price-option`"
                    >
                      Price Option
                    </a>
                    <div class="dropdown-divider"></div>
                    <a
                      class="dropdown-item"
                      v-if="hasPermission('Group_Delete')"
                      href="#"
                      style="color: red"
                      @click.prevent="onActionComplete"
                    >
                      Delete
                    </a>
                  </div>
                </td> -->
              </tr>
              <tr v-if="!groups.length" class="min-h-screen">
                <td colspan="12">
                  <div class="text-center" style="padding-top: 50px">
                    <span
                      style="font-size: 4.615rem"
                      class="fa-solid fa-spinner fa-spin text-[#2463EB]"
                    ></span>
                  </div>
                  <h3
                    class="text-center pt-[32px] text-[14px] font-normal leading-[16.94px]"
                    style="padding-bottom: 100px"
                  >
                    Please wait while we load your Groups.
                  </h3>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :limit="pageSize"
          :count="pageCount"
          :page="page"
          :itemsCount="count"
          @changePage="gotoPage"
          @changeLimit="changeLimit"
        />
      </div>
    </div>
    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-filter"
      role="dialog"
    >
      <div
        class="modal-dialog m-0 p-0 absolute top-0 right-0 flex justify-end items-end"
      >
        <div
          class="modal-content rounded-none bg-[#f8f8f8] p-[20px] md:p-[40px] md:min-h-screen"
        >
          <div
            class="modal-header modal-header-colored  flex items-start justify-between p-0 m-0"
          >
            <h4
              class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
            >
              Filter
            </h4>
            <button
              class="text-2xl text-[#353535] outline-none focus:outline-none"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body m-0 p-0">
            <div class="mb-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Start Date:</label
              >
              <div>
                <input
                  type="date"
                  :max="maxDate"
                  placeholder="Select a start date"
                  v-model="filter.startDate"
                  class="bg-transparent border-[#d0d5dd] border-solid border-[1.5px] py-[12px] px-[14px] rounded-[8px] w-full custom-select custom-select-lg"
                />
              </div>
            </div>
            <div class="mb-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >End Date:</label
              >
              <div>
                <input
                  type="date"
                  :max="maxDate"
                  placeholder="Select a end date"
                  v-model="filter.endDate"
                  class="bg-transparent border-[#d0d5dd] border-solid border-[1.5px] py-[12px] px-[14px] rounded-[8px] w-full custom-select custom-select-lg"
                />
              </div>
            </div>
            <div class="mb-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Status</label
              >
              <div>
                <select
                  class="bg-transparent border-[#d0d5dd] border-solid border-[1.5px] py-[12px] px-[14px] rounded-[8px] w-full select2 custom-select custom-select-lg"
                  placeholder="Select an option"
                  v-model="filter.status"
                >
                  <option
                    v-for="opt in options"
                    :key="opt.text"
                    :value="opt.value"
                  >
                    {{ opt.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="mb-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Name</label
              >
              <div>
                <input
                  class="bg-transparent border-[#d0d5dd] border-solid border-[1.5px] py-[12px] px-[14px] rounded-[8px] w-full"
                  type="text"
                  placeholder="Filter by product name..."
                  v-model="filter.name"
                />
              </div>
            </div>
          </div>
          <div class="flex items-center gap-[8px] mt-[16px] w-full">
            <button
              class="border-[#96a0b5] border-solid border-[1.5px] py-[16px] px-[16px] sm:px-[24px] text-[14px] md:text-[16px] rounded-[8px] font-medium leading-[20px] w-full"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <!-- <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="resetFilter"
            >
              Reset
            </button> -->
            <button
              class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[16px] sm:px-[24px] text-[14px] md:text-[16px] rounded-[8px] text-white font-medium leading-[20px] w-full"
              type="button"
              @click.prevent="getGroups(true)"
            >
              <span v-if="isBusy">Busy...</span>
              <span v-else>Search</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <AddGroupDistributor
      :groupId="groupId"
      @hide="hideModal"
      @onComplete="onComplete"
    />
    <Group
      :group="group"
      :showUpdate="update"
      :ActionComplete="ActionComplete"
    />
    <div
      v-if="isModalVisible"
      class="fixed inset-0 bg-[rgba(0,0,0,0.4)] z-[1111111] flex justify-end overflow-y-auto"
      id="popup-content"
      @click.self="closeModal"
    >
      <div
        class="relative w-full lg:w-[80%] max-h-screen bg-[#f8f8f8] px-[12px] py-[30px] md:p-[40px] overflow-auto"
      >
        <div class="shadow-none bg-transparent">
          <div class="flex items-start justify-between">
            <p class="text-[#353535] text-[20px] leading-[20px] font-semibold">
              Viewing: {{ selectedGroup.name }}
            </p>
            <button
              class="text-2xl text-[#353535]"
              type="button"
              @click="closeModal"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div
            class="bg-white p-1 rounded-[4px] flex items-center gap-[16px] mt-[16px]"
          >
            <button
              :class="[
                'text-[14px] font-normal py-[6px] px-[24px] rounded-[8px] outline-none focus:outline-none',
                {
                  'bg-[#2463EB] text-white': activeTab === 'profile',
                  'bg-[#f8f8f8] text-[#96a0b5]': activeTab !== 'profile',
                },
              ]"
              @click="switchTab('profile')"
            >
              Group Profile
            </button>
            <div>
              <button
                :class="[
                  'text-[14px] font-normal py-[6px] px-[24px] rounded-[8px] outline-none focus:outline-none',
                  {
                    'bg-[#2463EB] text-white': activeTab === 'settings',
                    'bg-[#f8f8f8] text-[#96a0b5]': activeTab !== 'settings',
                  },
                ]"
                @click="switchTab('settings')"
              >
                Linked Product Prices
              </button>
            </div>
          </div>
          <div v-if="activeTab === 'profile'">
            <div
              class="bg-white px-[15px] py-[24px] sm:px-[24px] sm:py-[32px] mt-[16px] rounded-[4px]"
            >
              <div
                class="flex flex-col md:flex-row justify-between items-start gap-[5px] md:items-center"
              >
                <div class="flex items-center gap-2 flex-1">
                  <h2
                    class="font-semibold text-[20px] leading-[20px] text-[#353535] max-w-[200px] w-full"
                  >
                    Group Information
                  </h2>
                  <span
                    class="flex items-center gap-[4px] max-w-max w-full"
                    :class="[
                      'badge',
                      {
                        'badge-success': selectedGroup.active,
                        'badge-danger': !selectedGroup.active,
                      },
                    ]"
                  >
                    <span
                      class="w-[10px] h-[10px] rounded-[1px]"
                      :style="{
                        backgroundColor: selectedGroup.active
                          ? '#16a249'
                          : '#EF5253',
                      }"
                    ></span>
                    <span class="text-[12px] font-normal">{{
                      selectedGroup.active ? "Active" : "Inactive"
                    }}</span>
                  </span>
                </div>
                <div class="flex flex-1 justify-end">
                  <button
                    class="border-[#96a0b5] text-[#96a0b5] border-solid border-[1.5px] py-[12px] px-[16px] sm:px-[24px] text-[14px] md:text-[16px] rounded-[8px] font-medium leading-[20px] outline-none focus:outline-none flex items-center gap-[5px]"
                    v-if="hasPermission('Group_Update')"
                    @click.prevent="editGroup"
                  >
                    <img src="/assets/icon/edit-2.svg" alt="" />
                    Edit
                  </button>
                </div>
              </div>
              <div
                class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] max-w-[170px] w-full"
                >
                  Name
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >{{ selectedGroup.name }}</span
                >
              </div>
              <div
                class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] max-w-[170px] w-full"
                >
                  Description
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >{{ selectedGroup.description }}</span
                >
              </div>
              <div
                class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] max-w-[170px] w-full"
                >
                  Created
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >{{ formatDate(selectedGroup.date) }}</span
                >
              </div>
              <div
                class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2"
              >
                <h2
                  class="font-normal text-[16px] text-[#96a0b5] max-w-[170px] w-full"
                >
                  Last Updated
                </h2>
                <span
                  class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                  >{{
                    formatDate(selectedGroup.updated)
                      ? formatDate(selectedGroup.updated)
                      : "N/A"
                  }}</span
                >
              </div>
            </div>
            <div
              class="bg-white px-[15px] py-[24px] sm:px-[24px] sm:py-[32px] mt-[16px] rounded-[4px]"
            >
              <div
                class="flex flex-col md:flex-row items-start md:items-center gap-2 flex- mb-[16px]"
              >
                <h2
                  class="font-semibold text-[20px] leading-[20px] text-[#353535] max-w-[200px] w-full"
                >
                  Distributor
                </h2>
                <div class="flex flex-1 justify-end mt-[5px] md:mt-0">
                  <button
                    class="border-[#96a0b5] text-[#96a0b5] border-solid border-[1.5px] py-[12px] px-[16px] sm:px-[24px] text-[14px] md:text-[16px] rounded-[8px] font-medium leading-[20px] outline-none focus:outline-none flex items-center gap-[5px]"
                    v-if="hasPermission('Distributor_Create')"
                    @click="addDistributor"
                  >
                    <span class="mdi mdi-plus"></span>
                    Add distributors
                  </button>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table p-0 m-0 dataTable no-footer">
                  <thead class="bg-white">
                    <tr class="py-[5px]">
                      <th class="text-[12px] leading-[18px] text-[#000000]">
                        Distributor Name
                      </th>
                      <th class="text-[12px] leading-[18px] text-[#000000]">
                        Created Date
                      </th>
                      <th
                        class="text-[12px] leading-[18px] text-[#000000]"
                      ></th>
                      <th
                        class="text-[12px] leading-[18px] text-[#000000]"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="d in distributors" :key="d.id">
                      <td class="text-[14px] font-medium leading-[16.94px]">
                        {{ d.memberName }}
                      </td>
                      <td class="text-[14px] font-medium leading-[16.94px]">
                        {{ formatDate(d.joined) }}
                      </td>
                      <td>
                        <button
                          class="text-[14px] leading-[16.96px] font-normal text-[#353535]"
                        >
                          View
                        </button>
                      </td>
                      <td v-if="hasPermission('Group_Delete')">
                        <DeleteAssociation
                          :id="d.memberId"
                          @onComplete="onActionComplete"
                        />
                      </td>
                    </tr>
                    <tr v-if="!distributors.length">
                      <td colspan="10">
                        <div class="text-center" style="padding-top: 50px">
                          <span
                            style="font-size: 4.615rem"
                            class="fa-solid fa-truck text-[#2463EB]"
                          ></span>
                        </div>
                        <h3
                          class="text-center pt-[32px] text-[14px] font-normal leading-[16.94px]"
                          style="padding-bottom: 100px"
                        >
                          There are no Distributor(s) Associated with this group
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Pagination
                  :limit="pageSize"
                  :count="distributorscount"
                  :page="distributorsNumber"
                  :itemsCount="count"
                  @changePage="distributorGotoPage"
                  @changeLimit="distributorChangeLimit"
                />
              </div>
            </div>
          </div>
          <div v-if="activeTab === 'settings'">
            <priceOptions
              ref="priceOptionsComponent"
              :groupId="selectedGroup.id"
              :closeModal="closeModal"
            />
          </div>
        </div>
      </div>
    </div>
    <AddPriceOption
      :groupId="groupId"
      @hide="hideModal"
      @onComplete="onComplete"
    />
  </div>
</template>

<script>
/* eslint-disable */
import Group from "../../components/Group/DistributorGroup.vue";
import DeleteGroup from "../../components/Group/DeleteGroup";
import { helper } from "@/helper";
import AddPriceOption from "../../components/Group/PriceOption/AddPriceOption.vue";
import moment from "moment";
import SystemPermissions from "@/permissions";
import Pagination from "../../components/Pagination.vue";
import AddGroupDistributor from "../../components/Group/DistributorAssociation/AddGroupDistributor.vue";
import DeleteAssociation from "../../components/Group/DistributorAssociation/DeleteAssociation";
import SwitchStatus from "../../components/Group/PriceOption/SwitchAssociationStatus";
import priceOptions from "../../components/Group/PriceOption/PriceOption.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  components: {
    Group,
    Pagination,
    DeleteGroup,
    AddGroupDistributor,
    DeleteAssociation,
    SwitchStatus,
    priceOptions,
    AddPriceOption,
  },

  created() {
    if (!this.hasPermission("Group_View")) {
      this.$router.push("/restricted");
    }
    if (!this.hasPermission("Group_View_Members")) {
      this.$router.push("/restricted");
    }
    this.getGroups();
  },

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      groupId: "",
      group: {},
      priceOptions: [],
      distributors: [],
      isModalVisible: false,
      activeTab: "profile",
      selectedGroup: {},
      distributorSize: 10,
      distributorscount: 0,
      distributorsNumber: 0,
      count: 0,
      page: 1,
      pageNumber: 0,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
      groups: [],
      group: null,
      showDeactivated: "",
      update: false,
      maxDate: "",
      name: "",
      active: "",
      code: "",
      filter: {
        name: "",
        status: "",
        startDate: "",
        endDate: "",
      },
      options: [
        { text: "All", value: "" },
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },
  mounted() {
    this.maxDate = helper.preventFutureDateSelection();
  },

  methods: {
    switchTab(tab) {
      this.activeTab = tab;
    },
    viewFilter() {
      this.pageNumber = 0;
      $("#mod-filter").modal("show");
    },
    resetFilter() {
      this.filter = {
        name: "",
        status: "",
        startDate: "",
        endDate: "",
      };
      this.getGroups();
    },

    editGroup(group) {
      this.group = this.selectedGroup;
      this.update = true;
      this.$nextTick(() => {
        $("#mod-group-add").modal("show");
      });
      this.isModalVisible = false;
      document.body.style.overflow = "auto";
    },

    hideModal() {
      $("#mod-group-add").modal("hide");
      this.update = false;
    },

    async ActionComplete() {
      // this.hideModal();
      $("#mod-group-add").modal("hide");
      await this.getGroups();
      document.body.style.overflow = "auto";
    },

    onComplete() {
      this.hideModal();
      this.getGroups();
    },

    async getGroups(isSearch) {
      try {
        this.groups = [];
        this.isBusy = true;
        this.page = isSearch ? 1 : this.page;
        const response = await this.$http.get(
          "/Groups/getList?PageNumber=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "") +
            (this.filter.name !== " " ? "&Name=" + this.filter.name : "") +
            (this.filter.startDate !== " "
              ? "&StartDate=" + this.filter.startDate
              : "") +
            (this.filter.endDate !== " "
              ? "&EndDate=" + this.filter.endDate
              : "") +
            (this.filter.status !== "" ? "&Active=" + this.filter.status : "")
        );
        if (response.ok && response.status === 200) {
          this.groups = response.body.data;
          this.page = response.body.pageNumber;
          this.pageSize = response.body.pageSize;
          this.count = response.body.count;
          this.hasPrev = response.body.hasPrev;
          this.hasNext = response.body.hasNext;
          this.pageCount = Math.ceil(
            response.body.count / response.body.pageSize
          );
          this.isBusy = false;
          $("#mod-filter").modal("hide");
          // this.filter = {
          // 	name:'',
          // 	status:"",
          // 	startDate:'',
          // 	endDate:''
          // }
        }
      } catch (error) {
        this.isBusy = false;
      }
    },

    async viewGroup(group) {
      this.selectedGroup = group;
      this.groupId = group.id;
      this.name = this.selectedGroup.name;
      this.status = this.selectedGroup.status;
      this.openModal();
      await this.getGroup();
      this.getDistributors();
      this.$nextTick(() => {
        if (this.$refs.priceOptionsComponent) {
          this.$refs.priceOptionsComponent.getGroupPriceOptions();
        }
      });
    },
    closeModal() {
      this.isModalVisible = false;
      document.body.style.overflow = "auto";
      this.activeTab = "profile";
    },
    openModal() {
      this.isModalVisible = true;
      document.body.style.overflow = "hidden";
    },
    addGroup() {
      this.group = null;
      this.showUpdate = false;
      $("#mod-group-add").modal("show");
    },

    // group distributors starts

    async getGroup() {
      this.isBusy = true;
      try {
        const response = await this.$http.get(
          "/Groups/getById/" + this.groupId
        );
        if (response.ok) this.group = response.body;
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },
    addDistributor() {
      $("#mod-add-distributor").modal("show");
      this.closeModal();
    },

    hideModal() {
      $("#mod-add-distributor").modal("hide");
    },

    onComplete() {
      this.hideModal();
      this.getDistributors();
    },

    onActionComplete() {
      this.getDistributors();
    },

    async getDistributors() {
      try {
        this.isBusyModal = true;
        this.isBusy = true;

        const response = await this.$http.get(
          "/Groups/getMembers/" +
            this.groupId +
            "/Distributor" +
            "?PageNumber=" +
            this.pageNumber +
            "&PageSize=" +
            this.pageSize
        );
        if (response.ok) {
          this.distributors = response.body.data;
          this.distributorsNumber = response.body.pageNumber;
          this.distributorsSize = response.body.pageSize;
          this.distributorscount = response.body.count;
          this.hasPrev = response.body.hasPrev;
          this.hasNext = response.body.hasNext;
        }
      } catch (error) {
        this.isBusyModal = true;
        this.isBusy = false;
      }
    },

    // group distributors ends

    formatDate(date) {
      return moment(date).format("ll");
    },

    prev() {
      this.page -= 1;
      this.getGroups();
    },
    next() {
      this.page += 1;
      this.getGroups();
    },

    gotoPage(page) {
      this.page = page;
      this.getGroups();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getGroups();
    },
    distributorPrev() {
      if (this.distributorPage > 1) {
        this.distributorPage -= 1;
        this.getDistributors();
      }
    },

    distributorNext() {
      if (this.distributorPage < this.distributorPageCount) {
        this.distributorPage += 1;
        this.getDistributors();
      }
    },

    distributorGotoPage(page) {
      if (page >= 1 && page <= this.distributorPageCount) {
        this.distributorPage = page;
        this.getDistributors();
      }
    },

    distributorChangeLimit(limit) {
      this.distributorPageSize = limit;
      this.getDistributors();
    },
  },
};
</script>

<style>
.dropdown-menu {
  width: 14.6rem;
}
</style>
