<template>
  <div class="row min-h-screen shadow-none">
    <div class="col-lg-12">
      <div :class="['be-loading', { 'be-loading-active': isBusy }]">
        <div
          class="w-full pb-2 flex flex-col justify-start items-start gap-[15px]"
        >
          <!-- Tabs Section -->
          <div class="w-full flex justify-start items-start gap-[11px]">
            <button
              :class="[
                'text-[14px] font-semibold leading-[20px] cursor-pointer focus:outline-none py-[5px]',
                {
                  'border-b-2 border-[#2463EB] text-[#2463EB]':
                    activeTab === 'business',
                  'text-[#96a0b5]': activeTab !== 'business',
                },
              ]"
              @click="switchTab('business')"
            >
              Business Profile
            </button>
            <button
              :class="[
                'text-[14px] font-semibold leading-[20px] cursor-pointer focus:outline-none py-[5px]',
                {
                  'border-b-2 border-[#2463EB] text-[#2463EB]':
                    activeTab === 'system',
                  'text-[#96a0b5]': activeTab !== 'system',
                },
              ]"
              @click="switchTab('system')"
            >
              System Settings
            </button>
          </div>
          <div
            class="relative w-full border-[1.5px] shadow-sm p-[20px] overflow-auto bg-white"
          >
            <BusinessProfile
              v-if="activeTab === 'business'"
              :account="account"
              :contact="contact"
              :businessLogoUrl="businessLogoUrl"
              :updatingLogo="updatingLogo"
              :previewLogo="previewLogo"
              @openPreview="openPreview"
              @previewFiles="previewFiles"
              @update="update"
              :v1="v1"
              :v2="v2"
            />
            <div v-if="activeTab === 'system'">
              <SystemSettings />
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade colored-header colored-header-primary"
        id="mod-logo-preview"
        role="dialog"
      >
        <div class="modal-dialog mx-auto">
          <div class="modal-content">
            <div class="modal-header modal-header-colored">
              <h4 class="modal-title p-1">
                <strong>Business Logo</strong>
              </h4>
              <div class="mt-1">
                <i
                  style="color:black; font-size:20px"
                  class="feather icon-x"
                  @click="closePreview"
                ></i>
              </div>
            </div>
            <div class="modal-body">
              <div v-if="updatingLogo" class="col-12 text-center p-5">
                <img
                  alt="business logo"
                  width="350px"
                  height="350px"
                  :src="previewLogo"
                />
              </div>
              <div v-else class="col-12 text-center p-5">
                <img
                  v-if="businessLogoUrl"
                  alt="business logo"
                  width="350px"
                  height="350px"
                  :src="`${businessLogoUrl}`"
                />
                <img
                  v-else
                  alt="logo placeholder"
                  class="logoImage"
                  src="/assets/img/logo-placeholder.png"
                />
              </div>
            </div>
            <div class="modal-footer">
              <button
                v-if="updatingLogo"
                class="btn btn-md btn-primary"
                color="blue"
                dark
                @click="updatingImage"
              >
                Save
              </button>
              <!-- 
                    <button class="btn btn-sm btn-error" @click="closePreview"
                      >Cancel</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { helper } from "@/helper";
import SystemPermissions from "@/permissions";
import Select2 from "@/components/Select2";
import CropImage from "@/components/LogoCropper/ImageCrop";
import BusinessProfile from "../components/Settings/BusinessProfile.vue";
import SystemSettings from "../components/Settings/SystemSettings.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsBusiness",

  components: {
    Select2,
    CropImage,
    BusinessProfile,
    SystemSettings,
  },
  data() {
    return {
      isBusy: false,
      isBusyUpdate: false,
      isEditing: false,
      activeTab: "business",
      account: {},
      contact: {},
      bank: {},
      banks: helper.getBanks(),
      states: helper.getStates(),
      businessLogo: {},
      businessLogoUrl: "",
      v1: {
        name: false,
        description: false,
        emailAddress: false,
        emailAddressValid: false,
        websiteValid: false,
        phoneNumber: false,
        phoneNumberValid: false,
        address1: false,
        city: false,
        state: false,
      },
      v2: {
        name: false,
        emailAddress: false,
        emailAddressValid: false,
        phoneNumber: false,
        phoneNumberValid: false,
      },
      v3: {
        name: false,
        accountNumber: false,
        accountNumberValid: false,
      },
      updatingLogo: false,
      previewLogo: null,
      croppedLogo: null,

      // //business logo cropper
      // imgSrc: "",
      // cropImg: null,
      // dialog: false,
      // file: null,
      // filename: null,
      // cropBlob: null,
    };
  },

  created() {
    if (!this.hasPermission("BusinessProfile_View")) {
      this.$router.push("/restricted");
    }
    this.getAccount();
  },
  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
    lgas: function() {
      return helper.getLgas(this.account.state);
    },

    //business logo cropper
    // croppedFile() {
    //   return new File([this.cropImg], this.file.name, { type: this.file.type });
    // },
    // cropSrc() {
    //   return this.cropImg || this.value;
    // },
  },
  methods: {
    switchTab(activeTab) {
      this.activeTab = activeTab;
    },
    cropImage(value) {
      this.croppedLogo = value;
    },
    previewFiles(event) {
      event.preventDefault();
      let file = event.target.files[0];
      this.businessLogo = event.target.files[0];
      this.previewLogo = URL.createObjectURL(file);
      this.updatingLogo = true;
      $("#mod-logo-preview").modal("show");
    },
    openPreview() {
      $("#mod-logo-preview").modal("show");
    },
    closePreview() {
      $("#mod-logo-preview").modal("hide");
    },
    updatingImage() {
      this.updatingLogo = true;
      $("#mod-logo-preview").modal("hide");
    },
    getAccount() {
      this.isBusy = true;

      this.$http
        .get("/Accounts/getCurrent")
        .then(function(res) {
          this.businessLogoUrl = res.body.logoURL;
          this.account = {
            id: res.body.id,
            name: res.body.name,
            description: res.body.description,
            emailAddress: res.body.emailAddress,
            phoneNumber: res.body.phoneNumber,
            website: res.body.website,
            address1: res.body.address1,
            address2: res.body.address2,
            city: res.body.city,
            lga: res.body.lga,
            state: res.body.state,
            rcNumber: res.body.rcNumber,
            tin: res.body.tin,
            code: res.body.code,
          };

          this.contact = {
            id: res.body.id,
            name: res.body.contact.name,
            emailAddress: res.body.contact.emailAddress,
            phoneNumber: res.body.contact.phoneNumber,
          };

          this.bank = {
            id: res.body.id,
            name: res.body.bank.name,
            bankCode: res.body.bank.bankCode,
            accountNumber: res.body.bank.accountNumber,
          };

          this.isBusy = false;
        })
        .catch(function() {
          this.isBusy = false;
        });
    },
    update() {
      this.isBusyUpdate = true;

      if (this.validateUpdate()) {
        // this.bank.bankCode = helper.getBankCode(this.bank.name);
        var data = {
          Id: this.account.id,
          Name: this.account.name,
          Description: this.account.description,
          EmailAddress: this.account.emailAddress,
          PhoneNumber: this.account.phoneNumber,
          // Website: this.account.website,
          Address1: this.account.address1,
          Address2: this.account.address2,
          City: this.account.city,
          Lga: this.account.lga,
          State: this.account.state,
          RcNumber: this.account.rcNumber,
          Tin: this.account.tin,
          // bank: this.bank,
          ContactId: this.contact.id,
          ContactName: this.contact.name,
          ContactEmailAddress: this.contact.emailAddress,
          ContactPhoneNumber: this.contact.phoneNumber,

          // LogoFile: this.croppedLogo ? this.croppedLogo : null,
          LogoFile: this.businessLogo.name ? this.businessLogo : null,
        };

        const formData = new FormData();

        for (var key in data) {
          formData.append(key, data[key]);
        }

        this.$http
          .put("/Accounts/updateAlt/" + data.Id, formData)
          .then(function(res) {
            $.gritter.add({
              title: "Business profile updated",
              text: "Your business profile has been updated.",
              class_name: "color success",
            });
            this.$toast.success("Profile updated successfully.", {
              icon: true,
              rtl: false,
            });
            this.isBusyUpdate = false;
            this.isEditing = false;
          })
          .catch(function() {
            this.isBusyUpdate = false;
          });
      } else {
        this.isBusyUpdate = false;
      }
    },
    validateUpdate() {
      this.v1 = {
        name: false,
        description: false,
        emailAddress: false,
        emailAddressValid: false,
        websiteValid: false,
        phoneNumber: false,
        phoneNumberValid: false,
        address1: false,
        city: false,
        state: false,
      };
      var isValid = true;

      if (!this.account.name) {
        this.v1.name = true;
        isValid = false;
      }

      if (!this.account.description) {
        this.v1.description = true;
        isValid = false;
      }

      if (this.account.website && !helper.validateUrl(this.account.website)) {
        this.v1.websiteValid = true;
        isValid = false;
      }

      if (!this.account.emailAddress) {
        this.v1.emailAddress = true;
        isValid = false;
      }

      if (
        this.account.emailAddress &&
        !helper.validateEmail(this.account.emailAddress)
      ) {
        this.v1.emailAddressValid = true;
        isValid = false;
      }

      if (!this.account.phoneNumber) {
        this.v1.phoneNumber = true;
        isValid = false;
      }

      if (
        this.account.phoneNumber &&
        !helper.validatePhone(this.account.phoneNumber)
      ) {
        this.v1.phoneNumberValid = true;
        isValid = false;
      }

      if (!this.account.address1) {
        this.v1.address1 = true;
        isValid = false;
      }

      if (!this.account.city) {
        this.v1.city = true;
        isValid = false;
      }

      if (!this.account.state) {
        this.v1.state = true;
        isValid = false;
      }

      this.v2 = {
        name: false,
        emailAddress: false,
        emailAddressValid: false,
        phoneNumber: false,
        phoneNumberValid: false,
      };

      if (!this.contact.name) {
        this.v2.name = false;
        isValid = true;
      }

      if (!this.contact.emailAddress) {
        this.v2.emailAddress = false;
        isValid = true;
      }

      if (
        this.contact.emailAddress &&
        !helper.validateEmail(this.contact.emailAddress)
      ) {
        this.v2.emailAddressValid = true;
        isValid = false;
      }

      if (!this.contact.phoneNumber) {
        this.v2.phoneNumber = false;
        isValid = true;
      }

      if (
        this.contact.phoneNumber &&
        !helper.validatePhone(this.contact.phoneNumber)
      ) {
        this.v2.phoneNumberValid = true;
        isValid = false;
      }

      // this.v3 = {
      //   name: false,
      //   accountNumber: false,
      // };

      // if (!this.bank.name) {
      //   this.v3.name = true;
      //   isValid = false;
      // }

      // if (!this.bank.accountNumber) {
      //   this.v3.accountNumber = true;
      //   isValid = false;
      // }

      // if (this.bank.accountNumber && !helper.validateNuban(this.bank.accountNumber)) {
      //   this.v3.accountNumberValid = true;
      //   isValid = false;
      // }

      return isValid;
    },

    // //business logo cropper
    // setImage(e) {
    //   // const file = e;
    //   const file = e.target.files[0];
    //   // this.file = file
    //   this.filename = file.name;

    //   if (!file.type.includes("image/")) {
    //     alert("Please select an image file");
    //     return;
    //   }

    //   if (typeof FileReader === "function") {
    //     const reader = new FileReader();

    //     reader.onload = (event) => {
    //       this.imgSrc = event.target.result;
    //       // rebuild cropperjs with the updated source
    //       this.$refs.cropper.replace(event.target.result);
    //       this.$emit("update:dataUrl", this.imgSrc);
    //     };

    //     reader.readAsDataURL(file);
    //     // this.dialog = true;
    //     $("#mod-logo").modal("show");

    //   } else {
    //     alert("Sorry, FileReader API not supported");
    //   }
    // },
    // cropImage() {
    //   debugger
    //   // get image data for post processing, e.g. upload or setting image src
    //   // this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
    //   this.$refs.cropper
    //     .getCroppedCanvas({
    //       maxWidth: this.maxWidth,
    //       maxHeight: this.maxHeight,
    //     })
    //     .toBlob(
    //       (blob) => {
    //         this.cropImg = URL.createObjectURL(blob);
    //         this.croppedBlob = blob;
    //         this.$emit("update:objectUrl", this.cropImg);
    //       },
    //       "image/jpeg",
    //       0.95
    //     );
    //   // this.dialog = false;
    //   $("#mod-logo").modal("hide");
    // },
    // rotate(dir) {
    //   if (dir === "r") {
    //     this.$refs.cropper.rotate(90);
    //   } else {
    //     this.$refs.cropper.rotate(-90);
    //   }
    // },
    // flip(vert) {
    //   let { scaleX, scaleY, rotate } = this.$refs.cropper.getData();
    //   // when image is rotated, direction must be flipped
    //   if (rotate === 90 || rotate === 270) {
    //     vert = !vert;
    //   }
    //   if (vert) {
    //     this.$refs.cropper.scale(scaleX, -1 * scaleY);
    //   } else {
    //     this.$refs.cropper.scale(-1 * scaleX, scaleY);
    //   }
    // },
  },
};
</script>

<style scoped>
.logoImage {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}
/* .v-icon.v-icon.v-icon--link {
  padding: 0 10px;
} */
</style>
