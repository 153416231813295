<template>
  <div class="be-wrapper">
    <NavBar></NavBar>
    <SideBar></SideBar>
    <!--<div class="be-content">
        <div class="main-content container-fluid">
            <router-view v-if="loaded"/>
        </div>
    </div>-->
    <div class="app-content content">
      <div class=""></div>
      <div class=""></div>
      <div class="content-wrapper">
        <div class="content-body">
          <section class="">
            <div class="row" v-if="loadingStatus && !loaded">
              <div class="col-lg-12">
                <div class="">
                  <div class="">
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <Loading />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-[10px] sm:p-[24px] bg-[#FBFBFB] shadow-none">
              <router-view v-if="!loadingStatus && loaded" />
            </div>
          </section>
        </div>
      </div>
      <div>
        <AddDistributorModal :name="user.displayName" />
      </div>

      <div
        v-if="
          showBillNotif &&
            user.freeTrialDaysRemaining > 0 &&
            user.freeTrialDaysRemaining <= 7
        "
        class="billing-notification"
      >
        <div class="card" style="width: 400px">
          <div class="card-header">
            <h4 class="text-danger">
              <span class="mr-1 mdi mdi-bullseye"></span>Setup billing details
            </h4>
            <button
              class="close md-close"
              type="button"
              @click.prevent="closeBillNotif"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="p-2 bg-white">
            <span
              >Your free mode will expire in {{ userSubTrialDays }},<br />
              Setup your billing information
              <router-link to="/settings/billings">here</router-link></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import NavBar from "@/components/NavBar";
import SideBar from "@/components/SideBar";
import Loading from "@/components/Loading";
import AddDistributorModal from "../components/GrowtradeOnboarding/AddDistributorModal.vue";

export default {
  name: "home",
  components: {
    NavBar,
    SideBar,
    AddDistributorModal,
    Loading,
  },

  created() {
    if (this.$auth.loggedIn()) {
      this.getUser();
    } else {
      this.$store.commit("clearCurrentUser");
    }
  },

  mounted() {
    // console.log(this.loadingStatus);
  },
  computed: {
    loadingStatus() {
      return this.$store.state.loadingStatus;
    },
    PaymentNotificationCounnt: function() {
      return this.$store.state.paymentNotification.count;
    },
    userSubTrialDays() {
      let suffix =
        this.user.freeTrialDaysRemaining === 7
          ? "week"
          : this.user.freeTrialDaysRemaining > 1
          ? "days"
          : "day";
      let trialDays = `${this.user.freeTrialDaysRemaining} ${suffix}`;
      return trialDays;
    },
  },
  updated() {
    const user = this.$store.state.currentUser;
    this.user = user;

    if (
      this.user.role.toLowerCase() === "customer" &&
      !this.user.hasDistributor
    ) {
      $("#mod-new-distributor").modal("show");
    }
    if (
      user.parent &&
      !user.parent.hasPreference &&
      user.parent.accountType === "Distributor" &&
      user.role.toLowerCase() === "administrator"
    ) {
      this.$router.push("/set-preference");
    }

    if (
      user.isSubscriptionActive === false &&
      user.type === "Distributor" &&
      user.role !== "Customer"
    ) {
      this.$router.push("/dashboard");
    }
  },
  data() {
    return {
      loaded: false,
      user: {},
      showFrame: false,
      showBillNotif: true,
    };
  },
  methods: {
    getUser() {
      this.$store.commit("loadingStaus", true);
      this.$http
        .get("/Users/getCurrent")
        .then(function(res) {
          this.$store.commit("setCurrentUser", res.body);
          this.$store.dispatch("getPaymentNotificationCount");
          this.$store.commit("loadingStaus", false);
          this.loaded = true;
        })
        .catch(function() {
          this.loaded = false;
        });
    },
    closeBillNotif() {
      this.showBillNotif = false;
    },
  },
};
</script>

<style scoped>
.w-vh-75 {
  min-height: 75vh !important;
}

.billing-notification {
  position: fixed;
  top: 100px;
  right: 30px;
}
</style>
