<template>
  <div class="row shadow-none">
    <div class="col">
      <div :class="['be-loading', { 'be-loading-active': isBusy }]">
        <div
          class="w-full pb-2 flex flex-col justify-start items-start gap-[15px]"
        >
          <!-- Tabs Section -->
          <div class="w-full flex justify-start items-start gap-[11px]">
            <button
              :class="[
                'text-[14px] font-semibold leading-[20px] cursor-pointer focus:outline-none py-[5px]',
                {
                  'border-b-2 border-[#2463EB] text-[#2463EB]':
                    activeTab === 'sales',
                  'text-[#96a0b5]': activeTab !== 'sales',
                },
              ]"
              @click="switchTab('sales')"
            >
              All Sales Order
            </button>
            <button
              :class="[
                'text-[14px] font-semibold leading-[20px] cursor-pointer focus:outline-none py-[5px]',
                {
                  'border-b-2 border-[#2463EB] text-[#2463EB]':
                    activeTab === 'order',
                  'text-[#96a0b5]': activeTab !== 'order',
                },
              ]"
              @click="switchTab('order')"
            >
              Sales Order Stages
            </button>
          </div>
          <div
            v-if="activeTab === 'sales'"
            class="flex xs:flex-col lg:flex-row xs:justify-start lg:justify-between w-full xs:items-start xs:gap-4 lg-gap-2 lg:items-center"
          >
            <p class="text-[#353535] text-[14px] leading-[20px] font-semibold">
              Sales Order
            </p>
            <div
              class="flex gap-3 flex-wrap xs:justify-start lg:justify-center xs:items-start lg:items-center"
            >
              <button
                @click="viewFilter"
                class="border-[1px] border-[#96a0b5] rounded-[8px] text-[#96a0b5] font-normal text-[14px] border-solid px-[24px] py-[12px] outline-none focus:outline-none"
              >
                <span class="feather icon-filter text-[#96a0b5]"></span> Filters
              </button>
            </div>
          </div>
          <div
            v-if="activeTab === 'order'"
            class="flex xs:flex-col lg:flex-row xs:justify-start lg:justify-between w-full xs:items-start xs:gap-4 lg-gap-2 lg:items-center"
          >
            <p class="text-[#353535] text-[14px] leading-[20px] font-semibold">
              Sales Order Status
            </p>
          </div>
        </div>

        <div v-if="activeTab === 'sales'">
          <div class="table-responsive relative">
            <table class="table p-0 m-0">
              <thead class="bg-white">
                <tr class="py-[5px]">
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Distributor Name
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    PO Number
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Order Amount
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Created
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Status
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white">
                <tr v-for="o in purchaseOrders.purchaseOrders" :key="o.id">
                  <td class="text-[14px] font-normal leading-[16.94px]">
                    {{ o.distributorName }}
                  </td>
                  <td class="text-[14px] font-normal leading-[16.94px]">
                    {{ o.orderNumber }}
                  </td>
                  <Amount
                    class="text-[14px] font-normal leading-[16.94px]"
                    :details="o.details"
                    :orderItems="o.orderItems"
                  />
                  <td class="text-[14px] font-normal leading-[16.94px]">
                    {{ formatDate(o.created) }}
                  </td>
                  <td>
                    <span
                      class="flex items-center gap-[4px] max-w-max w-full"
                      :class="[
                        'badge',
                        {
                          'badge-success':
                            o.status.toLowerCase() === 'confirmed',
                          'badge-secondary':
                            o.status.toLowerCase() === 'closed',
                          'badge-warning':
                            o.status.toLowerCase() === 'submitted',
                          'badge-danger': o.status.toLowerCase() === 'rejected',
                        },
                      ]"
                    >
                      <span
                        class="w-[10px] h-[10px] rounded-[1px]"
                        :style="{
                          backgroundColor:
                            o.status.toLowerCase() === 'confirmed'
                              ? '#16a249'
                              : o.status.toLowerCase() === 'submitted'
                              ? '#FFA500'
                              : o.status.toLowerCase() === 'closed'
                              ? '#ccc'
                              : '#e42728',
                        }"
                      ></span>

                      <span class="text-[12px] font-normal leading-[16.94px]">
                        {{
                          o.status.toLowerCase() === "confirmed"
                            ? "Approve"
                            : o.status.toLowerCase() === "closed"
                            ? "Closed"
                            : o.status.toLowerCase() === "submitted"
                            ? "Submitted"
                            : o.status.toLowerCase() === "rejected"
                            ? "Rejected"
                            : o.status
                        }}
                      </span>
                    </span>
                  </td>
                  <td @click="toggleDropdown(o.id)">
                    <div class="dropdown">
                      <button
                        class="btn-no-border"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span
                          class="text-[#98a2b3] dropdown text-2xl cursor-pointer relative outline-none active:outline-none shadow-none"
                        >
                          ...
                        </span>
                      </button>
                      <ul
                        class="dropdown-menu shadow-none"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <li
                          v-for="(action, index) in visibleActions(o)"
                          :key="index"
                          :class="{
                            'text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer py-[12px] px-[16px]': true,
                            'border-b border-[#e5e7eb]':
                              index !== visibleActions(o).length - 1,
                          }"
                          @click="action.onClick"
                        >
                          {{ action.label }}
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr v-if="!purchaseOrders.purchaseOrders.length">
                  <td colspan="10">
                    <div class="text-center" style="padding-top: 50px">
                      <span
                        style="font-size: 4.615rem"
                        class="fa-solid fa-spinner fa-spin text-[#2463EB]"
                      ></span>
                    </div>
                    <h3
                      class="text-center pt-[32px] text-[14px] font-normal leading-[16.94px]"
                      style="padding-bottom: 100px"
                    >
                      Please wait while we load your user Lists.
                    </h3>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Pagination
            :limit="purchaseOrders.pageSize"
            :count="purchaseOrders.pageCount"
            :page="purchaseOrders.page"
            :itemsCount="purchaseOrders.count"
            @changePage="gotoPage"
            @changeLimit="changeLimit"
          />
        </div>
        <div v-if="activeTab === 'order'">
          <StageOrder />
        </div>
      </div>

      <!-- modal popup -->
      <div
        v-if="isModalVisible"
        class="fixed inset-0 bg-[rgba(0,0,0,0.4)] z-[1111111] flex justify-end overflow-y-auto"
        id="popup-content"
        @click.self="closeModal"
      >
        <div
          class="relative w-full lg:w-[80%] max-h-screen bg-[#f8f8f8] p-[40px] overflow-auto"
        >
          <div class="flex items-center justify-between">
            <p class="text-[#353535] text-[20px] leading-[20px] font-semibold">
              {{
                PurchaseOrderType == "edit"
                  ? "Editing Sales Order"
                  : "Viewing Sales Order"
              }}
            </p>
            <button
              class="text-2xl text-[#353535] cursor-pointer outline-none focus:outline-none"
              type="button"
              @click="closeModal"
            >
              <span class="mdi text-xl mdi-close"></span>
            </button>
          </div>
          <div
            class="bg-white p-1 rounded-[4px] flex items-center gap-[16px] mt-[16px]"
          >
            <button
              class="outline-none focus:outline-none"
              :class="[
                'text-[14px] font-normal py-[6px] px-[24px] rounded-[8px]',
                {
                  'bg-[#2463EB] text-white': activeSubTab === 'salesOrder',
                  'bg-[#f8f8f8] text-[#96a0b5]': activeSubTab !== 'salesOrder',
                },
              ]"
              @click="switchSubTab('salesOrder')"
            >
              Sales Order
            </button>
            <div>
              <button
                class="outline-none focus:outline-none"
                :class="[
                  'text-[14px] font-normal py-[6px] px-[24px] rounded-[8px]',
                  {
                    'bg-[#2463EB] text-white': activeSubTab === 'activeLog',
                    'bg-[#f8f8f8] text-[#96a0b5]': activeSubTab !== 'activeLog',
                  },
                ]"
                @click="switchSubTab('activeLog')"
              >
                Activity Log
              </button>
            </div>
          </div>
          <div v-if="activeSubTab === 'salesOrder'">
            <ViewPurchaseOrder
              :type="PurchaseOrderType"
              :purchaseId="selectedPurchaseOrder.id"
              :closeModal="closeModal"
              :toggleEditMode="toggleEditMode"
            />
          </div>
          <div v-if="activeSubTab === 'activeLog'">
            <ActivityLog :purchaseId="selectedPurchaseOrder.id" />
          </div>
        </div>
      </div>
    </div>
    <ProcessModal :order="order" @onComplete="onComplete" />
    <FilterPurchaseOrder
      @onFilter="onFilterHandler"
      @onClear="onFilterHandler"
    />
    <div
      class="modal bg-[rgba(0, 0, 0, 0.5)] fade py-1 z-[11111111]"
      id="mod-update-status"
      role="dialog"
    >
      <div class="modal-dialog mx-auto">
        <div class="modal-content p-[24px] bg-[#f8f8f8]">
          <div class="modal-header flex items-start justify-between p-0 m-0">
            <h4
              class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
            >
              Update Sales Order Status
            </h4>
            <button
              class="text-2xl text-[#353535]"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body m-0 p-0">
            <div class="mb-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Update Status</label
              >
              <div>
                <select
                  name=""
                  id=""
                  class="bg-transparent border-[#d0d5dd] border-solid border-[1.5px] p-[14px] rounded-[8px] w-full outline-none focus:outline-none"
                >
                  <option value="">Select Status</option>
                  <option value="">Pending</option>
                  <option value="">Serviced</option>
                  <option value="">Dispatched</option>
                </select>
              </div>
            </div>
          </div>
          <div class="flex items-center gap-[8px] mt-[16px]">
            <button
              class="border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full outline-none focus:outline-none"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] w-full outline-none focus:outline-none"
              type="button"
              :disabled="isBusyModal"
              @click.prevent="create"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Amount from "../../components/SettingsPurchaseOrder/Amount";
import ProcessModal from "../../components/SettingsPurchaseOrder/ProcessModal";
import FilterPurchaseOrder from "../../components/SettingsPurchaseOrder/FilterPurchaseOrder.vue";
import PurchaseOrderCheckBox from "../../components/SettingsPurchaseOrder/PurchaseOrderCheckBox.vue";
import ViewPurchaseOrder from "../../components/SettingsPurchaseOrder/ViewPurchaseOrder.vue";
import { mapActions, mapGetters } from "vuex";
import SystemPermissions from "@/permissions";
import Pagination from "../../components/Pagination.vue";
import StageOrder from "../../components/SettingsPurchaseOrder/StageOrder.vue";
import ActivityLog from "../../components/SettingsPurchaseOrder/ActivityLog.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  components: {
    Amount,
    ProcessModal,
    FilterPurchaseOrder,
    PurchaseOrderCheckBox,
    Pagination,
    ViewPurchaseOrder,
    StageOrder,
    ActivityLog,
  },

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      activeSubTab: "salesOrder",

      order: {},
      selectedPurchaseOrders: [],
      selectedPurchaseOrder: [],
      exportAllLoading: false,
      exportSelectedLoading: false,
      activeTab: "sales",
      activeSubTab: "salesOrder",
      showDropdown: null,
      isModalVisible: false,
      PurchaseOrderType: null,
    };
  },

  async created() {
    if (!this.hasPermission("Sales_Order_View")) {
      this.$router.push("/restricted");
    }
    if (this.purchaseOrders.purchaseOrders.length <= 0)
      await this.getPurchaseOrder();
  },

  computed: {
    ...mapGetters(["purchaseOrders"]),
    filter: {
      get() {
        return this.$store.state.filter;
      },
    },
    dates: {
      get() {
        return this.$store.state.dates;
      },
    },
  },

  methods: {
    ...mapActions(["getPurchaseOrder"]),
    switchSubTab(subTab) {
      this.activeSubTab = subTab;
    },
    async approveOrder(id) {
      try {
        const response = await this.$http.put(
          `/PurchaseOrders/manufacturer/updateStatus/${id}`
        );
        if (response.ok) {
          // this.closeModal("reload");
          await this.getPurchaseOrder();
        }
      } catch (error) {
        this.loader.approve = false;
        console.log(error);
      }
    },
    onCompleteHideStage() {},
    getPurchaseOrder() {
      this.order = {};
      this.$store.dispatch("getPurchaseOrder", "enterprise");
    },

    setDate(date) {
      this.$store.commit("setDate", date);
    },
    toggleDropdown(userId) {
      this.showDropdown = this.showDropdown === userId ? null : userId;
    },
    toggleEditMode(type) {
      this.PurchaseOrderType = type;
    },
    onFilterHandler(dates) {
      if (dates) this.setDate(dates);
      this.getPurchaseOrder();
      // eslint-disable-next-line no-undef
      $("#mod-purchase-order-filter").modal("hide");
    },
    async viewPurchaseOrder(order, type) {
      this.selectedPurchaseOrder = order;
      this.name = this.selectedPurchaseOrder.name;
      this.code = this.selectedPurchaseOrder.code;
      this.active = this.selectedPurchaseOrder.active;
      this.PurchaseOrderType = type;
      this.isModalVisible = true;
      this.isEditMode = true;
    },
    closeModal() {
      this.isModalVisible = false;
      document.body.style.overflow = "auto";
      this.activeSubTab = "salesOrder";
      this.getPurchaseOrder();
    },
    openModal() {
      this.isModalVisible = true;
      document.body.style.overflow = "hidden";
    },
    viewFilter() {
      // eslint-disable-next-line no-undef
      this.purchaseOrders.pageNumber = 0;
      $("#mod-purchase-order-filter").modal("show");
    },

    startProcess(order) {
      if (order.status === "confirmed") {
        this.$router.push(`/sales-order/${order.id}/process`);
      } else if (order.status === "submitted") {
        this.order = order;

        // eslint-disable-next-line no-undef
        $("#mod-process").modal("show");
      }
    },

    onPurchaseAddHandler(value) {
      this.selectedPurchaseOrders.push(value);
    },

    onPurchaseRemoveHandler(value) {
      this.selectedPurchaseOrders = this.selectedPurchaseOrders.filter(
        (x) => x != value
      );
    },
    switchTab(tab) {
      this.activeTab = tab;
    },
    switchSubTab(subTab) {
      this.activeSubTab = subTab;
    },
    async downloadAll() {
      this.isBusy = true;
      this.exportAllLoading = true;

      try {
        const response = await this.$http.get(
          `/PurchaseOrders/download?CustomerName=${this.filter.customerName}&StartDate=${this.dates.startDate}&EndDate=${this.dates.endDate}&PageNumber=${this.purchaseOrders.pageNumber}&PageSize=${this.purchaseOrders.pageSize}`,
          { responseType: "arraybuffer" }
        );

        this.prepareDownload(response);
        this.isBusy = false;
        this.exportAllLoading = false;
      } catch (error) {
        this.isBusy = false;
        this.exportAllLoading = false;
      }
    },

    async downloadSelected() {
      this.isBusy = true;
      this.exportSelectedLoading = true;

      try {
        const response = await this.$http.post(
          "/PurchaseOrders/download/selected",
          this.selectedPurchaseOrders,
          { responseType: "arraybuffer" }
        );

        this.prepareDownload(response);

        this.isBusy = false;
        this.exportSelectedLoading = false;
      } catch (error) {
        this.isBusy = false;
        this.exportSelectedLoading = false;
      }
    },

    prepareDownload(response) {
      const url = URL.createObjectURL(
        new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "SalesOrder");
      document.body.appendChild(link);
      link.click();

      this.getPurchaseOrder();
    },
    visibleActions(order) {
      const actions = [
        { label: "View", onClick: () => this.viewPurchaseOrder(order, "view") },
        {
          label: "Edit",
          onClick: () => this.viewPurchaseOrder(order, "edit"),
          condition: order.status === "submitted",
        },
        {
          label: "Void",
          onClick: () => this.voidPurchaseOrder(order),
          condition: order.status === "submitted",
        },
        {
          label: "Approve",
          onClick: () => this.approveOrder(order.id),
          condition: order.status === "submitted",
        },
      ];
      return actions.filter((action) => action.condition !== false);
    },
    formatDate(date) {
      if (date) return moment(date).format("ll");
      else return "";
    },

    onComplete() {
      this.getPurchaseOrder();
      this.$router.push(`/sales-order/${this.order.id}/process`);
    },
    prev() {
      this.purchaseOrders.pageNumber -= 1;
      this.getPurchaseOrder();
    },
    next() {
      this.purchaseOrders.page += 1;
      this.getPurchaseOrder();
    },

    gotoPage(page) {
      this.purchaseOrders.page = page;
      this.getPurchaseOrder();
    },

    changeLimit(limit) {
      this.purchaseOrders.pageSize = limit;
      this.getPurchaseOrder();
    },
  },

  mounted() {
    this.$store.commit("clearFilter");
    this.getPurchaseOrder();
  },
};
</script>

<style></style>
