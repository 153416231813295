<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="bg-white p-2 d-flex flex-row justify-content-between">
        <h4>Outstanding Balance with {{distributorName}}</h4>
        <button class="btn btn-success btn-rounded btn-space" @click="goBack">
          <i class="mdi icon mdi-backburger text-white"></i>
          Back
        </button>
      </div>
      <div class="row p-2 mb-2">
        <div class="col col-md-8">
          <h1 class="balance-amount">{{`&#8358;${formatMoney(distributorBalance)}`}}</h1>
          <span> THIS IS YOUR BALANCE WITH THE DISTRIBUTOR. </span>
        </div>
        <div class="col col-md-4">
          <!-- Merchant pay distributor button hidden-->
          <!-- <div class="text-right">
            <button class="btn btn-md btn-primary" @click="makePayment">Pay Outstanding Balance</button>
          </div> -->
        </div>
      </div>


      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <h4>Payment History </h4>
          <button v-if="paymentHistories.length > 0" class="btn btn-light" @click="downloadStatementPdf">
            Download Statement
            <i class="icon icon-left mdi mdi-download"></i>
          </button>
        </div>
        <div class="card-body">
          <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th style="width: 25%">Transaction Date</th>
                      <th style="width: 25%">Transaction Type</th>
                      <th style="width: 15%">Reference ID</th>
                      <th style="width: 15%">Status</th>
                      <th style="width: 15%">Balance Impact</th>
                      <th style="width: 15%">Amount</th>
                      <th style="width: 15%">Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="o in paymentHistories" :key="o.id">
                      <td>
                        {{ formatDate(o.payment.created) }}
                      </td>
                      <td>
                        {{ o.payment.paymentType }}
                      </td>
                      <td>
                        {{ o.payment.referenceId }}
                      </td>
                       <td>
                        {{ o.payment.paymentStatus }}
                      </td>
                       <td>
                        {{ o.balanceImpact === 0 ? "Credit" : "Debit" }}
                      </td>
                      <td>
                        {{ `&#8358;${formatMoney(o.payment.paidAmount)}` }}
                      </td>
                       <td>
                        {{ `&#8358;${formatMoney(o.parentBalance)}` }}
                      </td>
                    </tr>
                    <tr v-if="!paymentHistories.length">
                      <td colspan="10">
                        <div class="text-center" style="padding-top: 50px">
                          <span style="font-size: 4.615rem" class="mdi mdi-basket"></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom: 100px">
                          No payment available
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Pagination
                  :limit="pageSize"
                  :count="pageCount"
                  :page="page"
                  :itemsCount="count"
                  @changePage="gotoPage"
                  @changeLimit="changeLimit"
                />
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5" v-if="paymentHistories.length">
                <div class="dataTables_info">{{ count }} payment{{ count != 1 ? "s" : "" }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            />
          </svg>
        </div>
      </div>
    </div>

      <div
        class="modal fade colored-header colored-header-primary"
        id="mod-pay"
        role="dialog"
        tabindex="-1"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header modal-header-colored">
              <h3 v-if="payStep === 1" class="modal-title">Paying this Business</h3>
              <div v-if="payStep === 2">
                <h3 class="modal-title">Transaction PIN Verification</h3>
                <p>Enter your secured <span class="font-weight-bold">Growtrade</span> transaction PIN</p>
              </div>
            </div>
            <div class="modal-body p-1">
              <div v-if="payStep === 1">
                <div class="row my-2">
                  <div class="col-12 col-sm-5">
                    <label class="mr-2">Amount you want to pay</label>
                  </div>
                  <div class="col-12 col-sm-7">
                    <input type="number" required class="form-control required" v-model="amountToPay" placeHolder="amount"/>
                  </div>
                </div>
                <div class="row my-2">
                  <div class="col-12 col-sm-5">
                    <label class="mr-2">Provide information to the business about this payment</label>
                  </div>
                  <div class="col-12 col-sm-7">
                    <textarea  v-model="paymentReason" class="form-control" rows="5"/>
                  </div>
                </div>

                <div class="my-3">
                  <span class="mdi mdi-information mr-1"></span>
                  <span>The payment amount will be deducted from your <span class="font-weight-bold">Growtrade</span> wallet</span>
                </div>
              </div>

              <div v-if="payStep === 2" class="my-3">
                <div class="row">
									<label class="col-12 col-sm-3"
										><span class="font-weight-bold">Enter PIN</span></label
									>
									<div class="col-12 col-sm-9">
										<otp-input
											:isValid="verifPinValid"
											:digits="6"
											type="number"
											@on-complete="onCompleteOTPHandler"
											@on-changed="onChangedOTPHandler"
											@on-paste="onPasteOTPHandler">
												<template #errorMessage> OTP is not valid </template>
										</otp-input>
									
									</div>
								</div>
              </div>
              

              <div class="text-right mt-2">
                  <button class="btn btn-default mr-1" type="button" data-dismiss="modal" aria-hidden="true">
                    <span class="text-danger">Cancel</span>
                  </button>
                   <button v-if="payStep === 1" class="btn btn-primary" @click="changeStep">
                    Done
                  </button>
                   <button :disabled="isBusy" v-if="payStep === 2" class="btn btn-primary" @click="payDistributor">
                    <span v-if="isBusy">Busy..</span>
                    <span v-else>Confirm</span>
                  </button>
              </div>
             
            </div>
          
          </div>
        </div>
      </div>

        <div
        class="modal fade colored-header colored-header-primary"
        id="mod-create-account"
        role="dialog"
        tabindex="-1"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header modal-header-colored">
              <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
                <span class="mdi mdi-close"></span>
              </button>
            </div>
            <div class="modal-body">
              <div class="p-2 text-center">
                <h4 class="font-weight-bold">Pay Outstanding Balance</h4>
                <p class="my-2"><span class="font-weight-bold">Hi there,</span> it seems you don't have a Growtrade Wallet account. 
                  Dont worry, it's quick and easy to get one.
                  <span>Click 'Create Account' to get started </span>
                </p>

                <div class="mt-3" style="width:100%">
                  <button class="btn btn-primary w-100" @click="goToCreateAccount">Create Account</button>
                  <button class="btn btn-text w-100" @click="closeCreateAcctMod">Cancel</button>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </div>


       <div
        class="modal fade colored-header colored-header-primary"
        id="mod-payInsufficient"
        role="dialog"
        tabindex="-1"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header modal-header-colored">
              <h3 class="modal-title">Pay Business</h3>
            </div>
            <div class="modal-body">
              <div class="p-2">
                <p>Insufficient balance, please fund your account</p>
              </div>

              <div class="d-flex flex-row justify-content-between p-1">
                <div></div>
                   <button class="btn btn-primary" @click="closeConfirmModal">
									  <span>Okay</span>
                  </button>
              </div>
             
            </div>
          
          </div>
        </div>
      </div>

      <div
        class="modal fade colored-header colored-header-primary"
        id="mod-response"
        role="dialog"
        tabindex="-1"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header modal-header-colored">
              <h3 class="modal-title">Payment</h3>
              <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
                <span class="mdi mdi-close"></span>
              </button>
            </div>
            <div class="modal-body">
              <div class="p-3 text-center">
                <div>
                  <span style="font-size: 100px" class="mdi mdi-check-circle text-success"></span>
                </div>
                <p>{{paymentResponse}}</p>
              </div>
            </div>
          
          </div>
        </div>
      </div>

      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1400"
        filename="payment-histories"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="1000px"
        ref="html2Pdf"
      >
        <section slot="pdf-content" class="card-body bg-white text-dark">
          <div class="container p-0">
            <div class="col-md-4 p-0" style="margin-top: 5px">
              <img
                class="logo-img"
                src="/assets/img/logo-xx.png"
                alt="logo"
                width="138"
                height="37"
              />
            </div>
            <div class="row justify-content-between align-items-center" style="margin-top: 32px">
              <div class="col-sm-12 col-md-6">
                <p style="color: #373737; margin-bottom: 0">Payment History with <span class="font-weight-bold">{{ distributorName }}</span></p>
              </div>
            </div>
            
          </div>

          <table class="table table-striped table-sm statement" style="margin-top: 32px">
            <thead style="background: rgba(221, 237, 170, 0.15); height: 75px">
              <tr>
                <th scope="col" style="vertical-align: middle; font-weight: 500; font-size: 14px">
                  Transaction Date
                </th>
                <th scope="col" style="vertical-align: middle; font-weight: 500; font-size: 14px">
                  Transaction Type
                </th>
                <th scope="col" style="vertical-align: middle; font-weight: 500; font-size: 14px; width:200px">
                  Reference ID
                </th>
                <th scope="col" style="vertical-align: middle; font-weight: 500; font-size: 14px">
                  Status
                </th>
                <th scope="col" style="vertical-align: middle; font-weight: 500; font-size: 14px">
                  Balance Impact
                </th>
                <th scope="col" style="vertical-align: middle; font-weight: 500; font-size: 14px">
                  Amount
                </th>
                <th scope="col" style="vertical-align: middle; font-weight: 500; font-size: 14px">
                  Balance
                </th>
              </tr>
            </thead>         
            <tbody>
              <tr v-for="t in paymentHistories" :key="t.id">
                <td class="light-td">{{ formatDate(t.payment.created) }}</td>
                <td class="light-td">{{ t.payment.paymentType }}</td>
                <td class="light-td" style="width:200px">{{ t.payment.referenceId }}</td>
                <td class="light-td">{{ t.payment.paymentStatus }}</td>
                <td class="light-td">{{ t.balanceImpact === 0 ? "Credit" : "Debit" }}</td>
                <td class="light-td">{{ `&#8358;${formatMoney(t.payment.paidAmount)}` }}</td>
                <td class="light-td">{{ `&#8358;${formatMoney(t.parentBalance)}` }}</td>
              </tr>
            </tbody>
          </table>
        </section>
      </vue-html2pdf>
  </div>
</template>

<script>
/* eslint-disable */

import { helper } from "@/helper";
import Pagination from "../components/Pagination.vue";
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";
import OtpInput from "otp-input-vue2";

export default {
  name: "DistributorBalance",
  components: {
    Pagination,
    VueHtml2pdf,
    OtpInput
  },

  mounted: function() {
    this.getAccountBalance();
  },

  created() {
    // if (!this.hasPermission("Orders_View")) {
    //   this.$router.push("/restricted");
    // }
    const distributorId = this.$route.params.id;
    const distributorName = this.$route.params.name;

    this.getMerchantDistributorPayments(distributorId);
    this.getMerchantDistributorBalance(distributorId);
    this.distributorId = distributorId;
    this.distributorName = distributorName;

  },

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      count: 0,
      page: 1,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
      distributorId: "",
      paymentHistories: [],
      amountToPay: "",
      paymentReason: "",
      distributorBalance: "",
      distributorName:"",
      paymentResponse: "",
      states: helper.getStates(),
      search: "",
      payStep: 1,
      accountBalance: 0,
      verifPin: "",
			verifPinValid: true,
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
    csvData() {
      return this.exportItems.map(item => ({
        ...item,
      }));
    },

    exportItems() {
      return this.paymentHistories.map(function(item) {
        return {
          "Payment Date": item.paidOn,
          "Amount": item.paidAmount,
        };
      });
    },
  },


  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },

    async getAccountBalance(){
      this.isBusy = true;
      await this.$http
        .post(`/Payments/getAccountBalance`)
        .then(function(res) {
          this.accountBalance = res.body.data.available_balance;
          this.isBusy = false;
        })
        .catch(function() {});

      this.isBusy = false;
    },

    changeStep(){
      if(this.accountBalance <  +this.amountToPay){
        this.openConfirmModal();
      }else if(!this.amountToPay || this.amountToPay <=0){
        return
      }else{
        this.payStep = 2;
      }
    },

    onCompleteOTPHandler(code){
      this.verifPin = code;
    },

    onChangedOTPHandler(lastEnteredCode){
      // console.log("code changed",  lastEnteredCode);
    },

    onPasteOTPHandler(code){
      this.verifPin = code;
    },

    makePayment(){
      if(!this.user.reservedAccountNumber || this.user.reservedAccountNumber === ""){
        this.openCreateAcctMod();
      }else{
        this.viewPayModal();
      }
    },

    openCreateAcctMod(){
      $("#mod-create-account").modal("show");
    },

    closeCreateAcctMod(){
      $("#mod-create-account").modal("hide");
    },

    goToCreateAccount(){
      setTimeout(()=> {
          this.$router.push("/merchant/growtrade/");
      }, 300);
    },

    viewPayModal() {
      this.amountToPay = "";
      this.paymentReason ="";
      $("#mod-pay").modal("show");
    },

    openConfirmModal(){
      $("#mod-pay").modal("hide");
      $("#mod-payInsufficient").modal("show");
    },

    closeConfirmModal(){
      $("#mod-payInsufficient").modal("hide");
    },

    prev() {
      this.page -= 1;
      this.getMerchantDistributorPayments();
    },

    next() {
      this.page += 1;
      this.getMerchantDistributorPayments();
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

     
    formatDate(date) {
        return moment(date).format("lll");
    },

    gotoPage(page) {
      this.page = page - 1;
      this.getMerchantDistributorPayments();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getMerchantDistributorPayments();
    },

    async getMerchantDistributorPayments(id) {
      this.isBusy = true;

      let body ={
        pageNumber: this.page,
        pageSize: this.pageSize
      }

      await this.$http
        .post(`/Merchant/merchantPaymentHistories?distributorId=${id}`, body)
        .then(function(res) {
          this.paymentHistories = res.body.data ? res.body.data : [];
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.isBusy = false;
        })
        .catch(function() {});

      this.isBusy = false;
    },

    async getMerchantDistributorBalance(id) {
      this.isBusy = true;
      await this.$http
        .get(`/Merchant/distributorBalance?distributorId=${id}`)
        .then(function(res) {
          this.distributorBalance = res.body.balance ? res.body.balance : "";
          this.isBusy = false;
        })
        .catch(function() {});

      this.isBusy = false;
    },

    async payDistributor() {
      if(this.verifPin.length < 6){
        return  this.$toast.error("Pin must be six digits", {
            icon: true,
            rtl: false,
          });
      }
      this.isBusy = true;
      this.paymentResponse = "";

      let payload = {
        distributorId: this.distributorId,
        amount: this.amountToPay,
        securityPin: this.verifPin,
        reason: this.paymentReason
      }
      await this.$http
        .post(`/Payments/transferToDistributor`, payload)
        .then(function(res) {
          this.isBusy = false;
          if(res.body){
            this.paymentResponse = "Payment successful";
          }else{
            this.paymentResponse = "Payment failed";
          }
          $("#mod-pay").modal("hide");
          $("#mod-response").modal("show");
          this.payStep = 1;
          this.verifPin = "";
        })
        .catch(function() {
          this.paymentResponse = "";
          this.payStep = 1;
          this.verifPin = "";
        });

        this.isBusy = false;
    },

    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      if(arrData.length === 0){
        this.$toast.error("No record.", {
            icon: true,
            rtl: false,
          });
        return
      }
      csvContent += [
        Object.keys(arrData[0]).join(","),
        ...arrData.map(item => Object.values(item).join(",")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "Merchant_Payment _History.csv");
      link.click();
    },

    downloadStatementPdf(){
      if(this.paymentHistories.length > 0){
          this.$refs.html2Pdf.generatePdf();
      }
    },

    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.search-distro {
  width: 238px;
  height: 38px;
  background: #fcfcfc;
  border: 1px solid #bebebe;
  border-radius: 5px;
  padding: 0 10px;
}
.balance-amount{
  font-size: 33px;
  font-weight: bold;
}
</style>
