<template>
  <div>
    <div class="table-responsive">
      <table class="table p-0 m-0 dataTable no-footer">
        <thead class="bg-white">
          <tr class="py-[5px]">
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Role Name
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Status
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Assigned Users
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Type
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Created
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Actions
            </th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="role in roles" :key="role.id">
            <td class="text-[14px] font-normal leading-[16.94px]">
              {{ role.roleName }}
            </td>
            <td>
              <span
                class="flex items-center gap-[4px] max-w-max w-full"
                :class="[
                  'badge',
                  {
                    'badge-success': role.active,
                    'badge-danger': !role.active,
                  },
                ]"
              >
                <span
                  class="w-[10px] h-[10px] rounded-[1px]"
                  :style="{
                    backgroundColor: role.active ? '#16a249' : '#EF5253',
                  }"
                ></span>
                <span class="text-[12px] font-normal leading-[16.94px]">{{
                  role.active ? "Assigned" : "Not Assigned"
                }}</span>
              </span>
            </td>
            <td class="text-[14px] font-normal leading-[16.94px]">
              {{ role.numberOfAssignedUsers }}
            </td>
            <td class="text-[14px] font-normal leading-[16.94px]">
              {{
                role.roleType === "MANUFACTURER"
                  ? "Business"
                  : role.roleType === "SalesForce"
                  ? "Sales"
                  : role.roleType
              }}
            </td>
            <td class="text-[14px] font-normal leading-[16.94px]">
              {{ formatDate(role.created) }}
            </td>
            <td @click="toggleDropdown(role.id)">
              <div class="dropdown">
                <button
                  class="btn-no-border"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span
                    class="text-[#98a2b3] dropdown text-2xl cursor-pointer relative outline-none active:outline-none shadow-none"
                  >
                    ...
                  </span>
                </button>
                <ul
                  class="dropdown-menu shadow-none"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li
                    @click="viewRoleUsers(role)"
                    v-if="hasPermission('Role_User_View')"
                    class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer border-b border-[#e5e7eb] py-[12px] px-[16px]"
                  >
                    View Assigned Roles
                  </li>

                  <li
                    @click="viewEditRole(role)"
                    v-if="hasPermission('Role_Update')"
                    :class="[
                      'text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer py-[12px] px-[16px]',
                      hasPermission('Role_Delete') &&
                      role.roleName.toLowerCase() != 'administrator' &&
                      role.roleName.toLowerCase() != 'support specialist' &&
                      role.roleName.toLowerCase() != 'view only' &&
                      role.roleName.toLowerCase() != 'sales'
                        ? 'border-b border-[#e5e7eb]'
                        : '',
                    ]"
                  >
                    Edit Role
                  </li>

                  <li
                    v-if="
                      hasPermission('Role_Delete') &&
                        role.roleName.toLowerCase() != 'administrator' &&
                        role.roleName.toLowerCase() != 'support specialist' &&
                        role.roleName.toLowerCase() != 'view only' &&
                        role.roleName.toLowerCase() != 'sales'
                    "
                    class="text-[14px] font-normal leading-[20px] text-[#DC2828] cursor-pointer py-[12px] px-[16px]"
                    @click="deleteRole(role)"
                  >
                    Delete Role
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr v-if="!roles.length" class="min-h-screen">
            <td colspan="10">
              <div class="text-center" style="padding-top: 50px">
                <span
                  style="font-size: 4.615rem"
                  class="fa-solid fa-spinner fa-spin text-[#2463EB]"
                ></span>
              </div>
              <h3
                class="text-center pt-[32px] text-[14px] font-normal leading-[16.94px]"
                style="padding-bottom: 100px"
              >
                Please wait while we load your Roles.
              </h3>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination
        :limit="pageSize"
        :count="pageCount"
        :pageNumber="pageNumber"
        :itemsCount="count"
        @changePage="gotoPage"
        @changeLimit="changeLimit"
      />
    </div>

    <!-- add role -->
    <div class="modal fade" id="mod-add-sales" role="dialog">
      <div
        class="modal-dialog p-0 m-0 fixed overflow-y-scroll top-0 right-0 flex justify-end items-end"
      >
        <div
          class="modal-content  h-full md:min-h-screen rounded-none bg-[#f8f8f8] p-[40px]"
        >
          <div class="modal-header flex items-start justify-between p-0 m-0">
            <h4
              class="font-semibold text-[20px] leading-[24px capitalize text-[#353535] pb-[16px]"
            >
              Create New Business Role
            </h4>
            <button
              class="text-2xl text-[#353535]"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body m-0 p-0">
            <div>
              <label
                class="text-[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Role Name</label
              >
              <div>
                <input
                  :class="[{ 'is-invalid': v1.name }]"
                  type="text"
                  v-model="createRole.name"
                  class="bg-[[#fcfcfd]] border-[##96a0b5] border-solid border-[1.5px] py-[14px] px-[16px] md:px-[24px] rounded-[8px] text-[14px] md:text-[16px] font-medium leading-[20px] w-full"
                  placeholder="Enter role name"
                />
                <ul v-if="v1.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="mt-[16px]">
              <div>
                <label
                  class="text-[#353535] font-medium text-[14px] leading-[20px]"
                  >Permissions</label
                >
                <div
                  class="bg-white w-full"
                  style="height: 380px; overflow-y: scroll"
                >
                  <ol
                    v-if="permissionsListCreate.length > 0"
                    class="flex flex-col gap-[5px] w-full"
                  >
                    <li v-for="item in permissionsListCreate" :key="item.id">
                      <div
                        class="flex items-center justify-between gap-[5px] py-[14px] px-[10px] border-b border-[#eee] cursor-pointer"
                        @click="item.state.opened = !item.state.opened"
                      >
                        <input
                          type="checkbox"
                          :disabled="item.state.disabled"
                          :name="item.text"
                          :checked="item.state.selected"
                          class="custom-checkbox"
                          @click.stop="
                            item.state.selected = !item.state.selected
                          "
                          @change="
                            onPermissionChange(item, item.state.selected)
                          "
                        />
                        <label
                          :for="item.text"
                          class="text-[#353535] font-medium text-[16px] m-0 w-full"
                          >{{ item.text }}</label
                        >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6 text-[#353535] transition-transform duration-300"
                          :class="item.state.opened ? 'rotate-180' : 'rotate-0'"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      </div>
                      <ol v-if="item.children.length != 0 && item.state.opened">
                        <li
                          v-for="children in item.children"
                          :data-id="children.id"
                          class="border-b border-[#eee]w-full py-[11px]"
                          :key="children.id"
                        >
                          <div class="px-[28px] flex items-center gap-[8px]">
                            <input
                              type="checkbox"
                              :name="children.text"
                              :checked="children.state.selected"
                              :disabled="children.state.disabled"
                              class="custom-checkbox"
                              @click="
                                children.state.selected = !children.state
                                  .selected
                              "
                            />
                            <label
                              :for="children.text"
                              class="text-[#353535] font-normal text-[14px] m-0 w-full"
                              >{{ children.text }}</label
                            >
                          </div>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center gap-[8px]">
            <button
              class="bg-[#fcfcfd] border-[##96a0b5] border-solid border py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full outline-none focus:outline-none"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] w-full outline-none focus:outline-none"
              :disabled="isBusyModal"
              type="button"
              @click.prevent="create"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Add Role</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- edit role modal -->
    <div class="modal fade" id="mod-edit-role" role="dialog">
      <div
        class="modal-dialog p-0 m-0 fixed overflow-y-scroll top-0 right-0 flex justify-end items-end"
      >
        <div
          class="modal-content h-full md:min-h-screen rounded-none bg-[#f8f8f8] p-[40px]"
        >
          <div class="modal-header flex items-start justify-between p-0 m-0">
            <h4
              class="font-semibold text-[20px] leading-[24px capitalize text-[#353535] pb-[16px]"
            >
              Editing Distributor Manager Role
            </h4>
            <button
              class="text-2xl text-[#353535]"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body m-0 p-0">
            <div>
              <label
                class="text-[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Role Name</label
              >
              <div>
                <input
                  :class="[{ 'is-invalid': v2.name }]"
                  type="text"
                  v-model="updateRole.name"
                  class="bg-[[#fcfcfd]] border-[##96a0b5] border-solid border-[1.5px] py-[14px] px-[16px] md:px-[24px] rounded-[8px] text-[14px] md:text-[16px] font-medium leading-[20px] w-full"
                />
                <ul v-if="v2.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="mt-[16px]">
              <div>
                <label
                  class="text-[#353535] font-medium text-[14px] leading-[20px]"
                  >Permissions</label
                >
                <div class="bg-white w-full">
                  <div style="height: 380px; overflow-y: scroll;">
                    <ol class="flex flex-col gap-[5px] w-full">
                      <li v-for="item in permissionsListUpdate" :key="item.id">
                        <div
                          class="flex items-center justify-between gap-[5px] py-[14px] px-[10px] border-b border-[#eee] cursor-pointer"
                          @click="item.state.opened = !item.state.opened"
                        >
                          <input
                            type="checkbox"
                            :disabled="item.state.disabled"
                            :name="item.text"
                            :checked="item.state.selected"
                            class="custom-checkbox"
                            @click.stop="
                              item.state.selected = !item.state.selected
                            "
                            @change="
                              onPermissionChange(item, item.state.selected)
                            "
                          />
                          <label
                            :for="item.text"
                            class="text-[#353535] font-medium text-[16px] m-0 w-full"
                          >
                            {{ item.text }}
                          </label>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6 text-[#353535] transition-transform duration-300"
                            :class="
                              item.state.opened ? 'rotate-180' : 'rotate-0'
                            "
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        </div>
                        <ol
                          v-if="item.children.length != 0 && item.state.opened"
                        >
                          <li
                            v-for="children in item.children"
                            :data-id="children.id"
                            class="border-b border-[#eee]w-full py-[11px]"
                            :key="children.id"
                          >
                            <div class="px-[28px] flex items-center gap-[8px]">
                              <input
                                type="checkbox"
                                :name="children.text"
                                :checked="children.state.selected"
                                :disabled="children.state.disabled"
                                class="custom-checkbox"
                                @click="
                                  children.state.selected = !children.state
                                    .selected
                                "
                              />
                              <label
                                class="text-[#353535] font-normal text-[14px] m-0 w-full"
                                :for="children.text"
                                >{{ children.text }}</label
                              >
                            </div>
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center gap-[8px]">
            <button
              class="bg-[#fcfcfd] border-[##96a0b5] border-solid border py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full outline-none focus:outline-none"
              type="button"
              data-dismiss="modal"
              @click="cancel"
            >
              Cancel
            </button>
            <button
              class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] w-full outline-none focus:outline-none"
              :disabled="isBusyModal"
              type="button"
              @click.prevent="update"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <ViewAsignedRole
      :roleUsers="roleUsers"
      :roleInfo="roleInfo"
      v-if="isModalVisible"
      :close="closeModal"
    />
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import Select2 from "../Select2";
import SystemPermissions from "@/permissions";
import Pagination from "../Pagination.vue";
import ViewAsignedRole from "../User/ViewAsignedRole.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsRoles",
  components: {
    Select2,
    Pagination,
    ViewAsignedRole,
  },
  created() {
    if (!this.hasPermission("Role_View")) {
      this.$router.push("/restricted");
    }
    this.getRoles();
    this.getPermissions();
  },
  watch: {},
  data() {
    return {
      filter: {
        status: "0",
        qry: "",
      },
      isBusy: false,
      isBusyModal: false,
      createRole: {
        name: null,
        permissions: [],
      },
      updateRole: {
        name: null,
        id: null,
        permissions: [],
      },
      editRole: {
        name: null,
        id: null,
        permissions: null,
      },
      v1: {
        name: false,
      },
      v2: {
        name: false,
      },
      roles: [],
      isModalVisible: false,
      showDropdown: null,
      permissions: [],
      count: 0,
      page: 1,
      pageNumber: 1,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
    };
  },
  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
    permissionsListCreate() {
      return this.createRole.permissions.map((b) => {
        return b;
      });
    },
    permissionsListUpdate() {
      return this.updateRole.permissions.map((b) => {
        return b;
      });
    },
  },
  // mounted() {
  //   console.log(this.roles);
  // },
  methods: {
    toggleDropdown(userId) {
      this.showDropdown = this.showDropdown === userId ? null : userId;
    },
    closeModal() {
      this.isModalVisible = !this.isModalVisible;
    },

    onPermissionChange(item, state) {
      for (let child of item.children) {
        child.state.selected = state;
      }
    },
    reset() {
      this.filter = {
        status: "0",
        qry: "",
      };
    },
    cancel() {
      // const currentRole = this.Roles.find((l) => l.id === this.editRole.id);
      // currentRole.name = this.editRole.name;
      // currentRole.id = this.editRole.id;
      $("#mod-edit-role").modal("hide");
    },
    async viewRoleUsers(r) {
      var roleId = r.id;
      this.isBusy = true;
      await this.$http
        .get(
          "/Roles/getAssignedUserList?page=" +
            0 +
            "&pageSize=" +
            10 +
            ("&roleId=" + roleId)
        )
        .then(function(res) {
          this.roleUsers = res.body.data;
          this.isBusy = false;
        })
        .catch(function(err) {
          console.log(err);
        });
      this.roleInfo = { id: r.id, name: r.roleName };
      this.isModalVisible = true;
      this.isBusy = false;
    },

    viewExport() {
      //   $("#mod-export").modal("show");
    },
    viewAdd() {
      this.createRole = {
        name: null,
        permissions: JSON.parse(JSON.stringify(this.permissions)),
      };
      this.v1 = {
        name: false,
      };
      $("#mod-add").modal("show");
    },
    viewEditRole(c) {
      var editValues = {
        name: c.roleName,
        id: c.id,
        permissions: JSON.parse(JSON.stringify(c.permissions)),
      };
      this.editRole = editValues;

      this.updateRole = {
        id: c.id,
        name: c.roleName,
        permissions: JSON.parse(JSON.stringify(c.permissions)),
      };

      $("#mod-edit-role").modal("show");
    },
    viewFilter() {
      $("#mod-filter").modal("show");
    },
    async deleteRole(c) {
      if (confirm("Are you sure you want to delete this role?")) {
        this.isBusyModal = true;
        this.isBusy = true;

        await this.$http
          .post("/Roles/delete/" + c.id, null)
          .then(function(res) {
            this.isBusyModal = false;
          })
          .catch(function() {});

        this.getRoles();

        this.isBusy = false;
      }
    },
    async create() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        name: this.createRole.name,
        parentId: this.user.parentId,
        roleType: this.user.type,
        permissions: this.createRole.permissions,
      };

      if (this.validateCreate()) {
        $("#mod-add-sales").modal("hide");

        await this.$http
          .post("/Roles/create", data)
          .then(function(res) {
            this.isBusyModal = false;
          })
          .catch(function() {});

        this.getRoles();
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },
    validateCreate() {
      this.v1 = {
        name: false,
      };
      var isValid = true;

      if (!this.createRole.name) {
        this.v1.name = true;
        isValid = false;
      }

      return isValid;
    },
    async update() {
      this.isBusyModal = true;
      this.isBusy = true;

      const data = {
        name: this.updateRole.name,
        permissions: this.updateRole.permissions,
        roleId: this.updateRole.id,
        parentId: this.user.parentId,
      };

      if (this.validateUpdate()) {
        $("mod-edit-role").modal("hide");

        await this.$http
          .put("/Roles/update/" + data.roleId, data)
          .then(function(res) {
            this.isBusyModal = false;
            $("#mod-edit-role").modal("hide");
          })
          .catch(function() {});
        this.getRoles();
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },
    validateUpdate() {
      this.v2 = {
        name: false,
      };
      var isValid = true;

      if (!this.updateRole.name) {
        this.v2.name = true;
        isValid = false;
      }

      return isValid;
    },
    async getRoles() {
      this.isBusy = true;

      await this.$http
        .get(
          // "/Roles/getRoleList?page=" +
          "/Roles/getAllEnterpriseRoles?pageNumber=" +
            this.pageNumber +
            "&pageSize=" +
            this.pageSize +
            "&roleType=" +
            this.user.type +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "") +
            (this.filter.status != "0" ? "&active=" + this.filter.status : "") +
            (this.filter.qry != "" ? "&query=" + this.filter.qry : "")
        )
        .then(function(res) {
          this.roles = res.body;
          this.page = res.body.pageNumber;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.isBusy = false;
        })
        .catch(function(err) {
          console.log(err);
        });

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },
    async getPermissions() {
      this.isBusy = true;

      await this.$http
        .get("/Roles/getPermissionList?roleType=" + this.user.type)
        .then(function(res) {
          // console.log(res);
          this.permissions = res.body;
          this.createRole.name = "";
          this.createRole.permissions = this.permissions;
          // console.log(this.permissions);
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function(err) {
          console.log(err);
        });

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },

    formatDate(date) {
      return moment(date).format("ll");
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    prev() {
      this.pageNumber -= 1;
      this.getRoles();
    },

    next() {
      this.pageNumber += 1;
      this.getRoles();
    },

    gotoPage(page) {
      this.pageNumber = page - 1;
      this.getRoles();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getRoles();
    },
  },
};
</script>

<style scoped>
.custom-checkbox {
  width: 24px;
  height: 18.9px;
  appearance: none;
  border: 1px solid #96a0b5;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  outline: none;
  transition: background-color 0.2s ease-in-out;
}
.custom-checkbox:checked {
  background-color: rgba(36, 99, 235, 0.5);
  border-color: rgba(36, 99, 235, 1);
}

.custom-checkbox:checked::after {
  content: "✓";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #2463eb;
  font-size: 14px;
}
</style>
