<template>
  <div class="modal fade" id="mod-confirm-delete" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content p-[24px] bg-[#f8f8f8]">
        <div
          class="modal-header modal-header-colored  flex items-start justify-between p-0 m-0"
        >
          <h4
            class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
          >
            Delete
          </h4>
          <button
            class="text-2xl text-[#353535] border-solid border-[#353535] border-[1.5px] rounded-full px-[4px]"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body p-0 m-0">
          <p
            class="text-[#96a0b5] text[14px] font-normal leading-[20px] mt-[8px]"
            v-if="!message"
          >
            Are you sure you want to delete this {{ text }}? This action can not
            be reversed.
          </p>
          <p
            class="text-[#96a0b5] text[14px] font-normal leading-[20px] mt-[8px]"
            v-if="message"
          >
            Deleting this warehouse will delete associated storage areas. Click
            DELETE to proceed.
          </p>
          <div class="mt-[32px] flex items-center gap-[8px]">
            <button
              class="border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full"
              type="button"
              data-dismiss="modal"
              @click.prevent="cancelConfirm"
            >
              Cancel
            </button>
            <button
              class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] w-full"
              :disabled="isBusy"
              type="button"
              @click.prevent="handleDelete"
            >
              <span v-if="isBusy">Busy...</span>
              <span v-else>{{ message ? "Delete" : "Confirm" }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneralDeleteCaution",
  props: ["id", "isBusy", "text", "message"],
  methods: {
    handleDelete() {
      this.$emit("proceed", this.id);
    },
    cancelConfirm() {
      this.$emit("cancel");
    },
  },
};
</script>
