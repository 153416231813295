<template>
  <section class="bg-white grid place-items-center h-[100dvh] md:h-screen">
    <div class="px-2 sm:px-6 lg:px-8 max-w-7xl">
      <div class="max-w-[400px] mx-auto">
        <div>
          <img
            class="w-auto h-20 mx-auto"
            src="/assets/img/uac-logo.png"
            alt="uac Logo"
          />
          <h1 class="mt-[10px] mb-[8px] text-[24px] font-bold text-black">
            Login
          </h1>
          <p
            class="text-[16px] sm:text-[18px] leading-[21.78px] text-[#81868c] mb-[20px] font-normal"
          >
            Welcome aboard! Let's set up your account to streamline your
            logistics needs.
          </p>
        </div>
        <form @submit.prevent="login">
          <div class="space-y-4">
            <div>
              <label
                for="username"
                class="text-[16px] leading-[19.36px] font-normal text-[#353535]"
              >
                Username
              </label>
              <div>
                <input
                  id="username"
                  class="w-full px-[16px] py-[12px] text-[14px] placeholder-gray-[#81868c] border-[0.5px] border-[rgba(0, 0, 0, 0.3)] outline-none rounded-[8px]"
                  :class="{ 'border-red-500': $v.user.username.$error }"
                  type="text"
                  placeholder="Username"
                  autocomplete="off"
                  v-model.trim="$v.user.username.$model"
                />
                <p
                  v-if="!$v.user.username.required && $v.user.username.$dirty"
                  class="text-red-500 text-[12px] mt-[5px]"
                >
                  * Required.
                </p>
              </div>
            </div>

            <div>
              <div class="flex items-center justify-between">
                <label
                  for="password"
                  class="text-[16px] leading-[19.36px] font-normal text-[#353535]"
                >
                  Password
                </label>
              </div>
              <div class="relative">
                <input
                  id="password"
                  :type="toggledViewPassword ? 'text' : 'password'"
                  placeholder="*********"
                  v-model.trim="$v.user.password.$model"
                  class="w-full px-[16px] py-[12px] text-[14px] placeholder-gray-[#81868c] border border-[rgba(0, 0, 0, 0.3)] outline-none rounded-[8px]"
                  :class="{ 'border-red-500': $v.user.password.$error }"
                />
              </div>
              <p
                v-if="!$v.user.password.required && $v.user.password.$dirty"
                class="text-red-500 text-[12px] mt-[5px]"
              >
                * Required.
              </p>
            </div>

            <div>
              <button
                type="submit"
                :disabled="isBusy"
                class="w-full px-6 py-[12px] text-lg font-normal leading-5 text-white transition-all duration-200 bg-[#2463EB] border-[0.5px] border-solid border-[#2463EB] outline-none focus-none rounded-[8px] disabled:opacity-50"
              >
                <i v-if="isBusy" class="fas fa-spinner fa-spin mr-2"></i>

                {{ isBusy ? "Busy..." : "Login" }}
              </button>
            </div>
          </div>
        </form>
        <div class="mt-[28px] text-center">
          <router-link
            to="/forgot-password"
            class="text-[#1c1c1c] hover:text-[#1c1c1c] decoration-none text-[16px] leading-[21.86px]"
          >
            Forgot Password?
          </router-link>
        </div>
      </div>
      <p class="text-md font-normal text-center mt-[62px]">
        Powered by
        <a class="font-bold" href="https://wekurnect.io" target="_blank"
          >Wekurnect</a
        >
      </p>
    </div>
  </section>
</template>

<script>
/* eslint-disable */

import { required } from "vuelidate/lib/validators";

export default {
  name: "login",
  data() {
    return {
      isBusy: false,
      type: "password",
      toggledViewPassword: false,
      user: {
        username: "",
        password: "",
      },
    };
  },
  validations: {
    user: {
      username: { required },
      password: { required },
    },
  },
  beforeMount() {
    let hostName = window.location.href;
    if (hostName.includes("app.growtrade.io/login")) {
      this.$router.replace("/growtrade-login");
    }
    if (hostName.includes("sandbox.growtrade.io/login")) {
      this.$router.replace("/growtrade-login");
    }
    if (hostName.includes("sandbox.growtrade.io/login")) {
      this.$router.replace("/growtrade-login");
    }
    if (hostName.includes("appqagrow.wekurnect.io/login")) {
      this.$router.replace("/growtrade-login");
    }
    // if (hostName.includes("localhost:8080/login")) {
    // 	this.$router.replace("/growtrade-login");
    // }
  },
  methods: {
    async login() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isBusy = true;
        this.user = { ...this.user, channelType: 1 };

        await this.$http
          .post("/Auth/generateToken", this.user)
          .then(function(res) {
            if (res.body.forceChangeOfPassword) {
              this.$store.commit("setUserId", res.body.id);
              this.$router.push("/change-password");
            } else {
              this.$auth.setToken(
                res.body.accessToken,
                Date.now() + res.body.expiresIn * 60000,
                res.body.refreshToken,
                res.body.role,
                res.body.userType,
                res.body.permissions,
                res.body.salesUserRole
              );

              this.$router.push("/");
            }
          })
          .catch(function() {});

        this.isBusy = false;
      }
    },
  },
};
</script>
