<template>
  <div>
    <div
      class="flex flex-col md:flex-row items-start md:items-center justify-between mt-[16px]"
    >
      <label for="file_input">
        <input class="hidden" id="file_input" type="file" />
        <div class="flex gap-[5px] items-center w-full bg-transparent max-w-md">
          <div
            class=" border-[#96A0B5] border-[1.5px] border-solid rounded-[8px] px-[12px] sm:px-[24px] py-[12px] cursor-pointer"
          >
            <span
              class="text-[12px] sm:text-[14px] font-medium text-[#96A0B5] leading-[17px]"
              >Chose file to upload</span
            >
          </div>
          <p class="text-[12px] font-medium">No File Chosen</p>
        </div>
      </label>
      <button
        v-if="hasPermission('Price_Option_Create')"
        class="
          'font-normal text-[12px] sm:text-[14px] border-solid px-[12px] sm:px-[24px] py-[12px] rounded-[8px] bg-[#2463EB] text-white flex gap-[10px] items-center"
        @click="addOption"
      >
        <span class="fa-solid fa-plus"></span>
        Add Price Option
      </button>
    </div>
    <div
      class="bg-white mt-[16px] rounded-[4px] border-[#DBDCE0] border-[1.5px] border-solid"
    >
      <div class="table-responsive">
        <table class="table p-0 m-0 dataTable no-footer">
          <thead class="bg-white">
            <tr class="py-[5px]">
              <th
                class="text-[12px] leading-[18px] text-[#000000] font-semibold"
              >
                Name
              </th>
              <th
                class="text-[12px] leading-[18px] text-[#000000] font-semibold"
              >
                Option ID
              </th>
              <th
                class="text-[12px] leading-[18px] text-[#000000] font-semibold"
              >
                Description
              </th>
              <th
                class="text-[12px] leading-[18px] text-[#000000] font-semibold"
              >
                Distributor Price
              </th>
              <th
                class="text-[12px] leading-[18px] text-[#000000] font-semibold"
              >
                Retail Price
              </th>
              <th
                class="text-[12px] leading-[18px] text-[#000000] font-semibold"
              >
                Last Modified
              </th>
              <th
                class="text-[12px] leading-[18px] text-[#000000] font-semibold"
              >
                Status
              </th>
              <th
                class="text-[12px] leading-[18px] text-[#000000] font-semibold"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="op in priceOptions" :key="op.id">
              <td class="text-[14px] font-normal leading-[16.94px]">
                {{ op.name }}
              </td>
              <td class="text-[14px] font-normal leading-[16.94px]">
                {{ op.optionId }}
              </td>
              <td class="text-[14px] font-normal leading-[16.94px]">
                {{ op.description }}
              </td>
              <td class="text-[14px] font-normal leading-[16.94px]">
                &#8358; {{ formatMoney(op.distributorPrice) }}
              </td>
              <td class="text-[14px] font-normal leading-[16.94px]">
                &#8358; {{ formatMoney(op.retailPrice) }}
              </td>
              <td class="text-[14px] font-normal leading-[16.94px]">
                {{ formatDate(op.updated) }}
              </td>
              <td>
                <SwitchStatus
                  :optionPrice="op"
                  @onComplete="onActionComplete"
                />
              </td>
              <td
                class="text-[#98a2b3] dropdown text-2xl cursor-pointer relative"
                @click="toggleDropdown(op.id)"
              >
                ...
                <div
                  v-if="showDropdown === op.id"
                  class="absolute left-0 max-w-max w-full border border-[#d0d5dd] rounded-md shadow-md z-10 bg-[#fcfcfd] p-[4px]"
                >
                  <ul>
                    <li
                      v-if="hasPermission('Price_Option_Update')"
                      class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer border-b border-[#e5e7eb] py-[12px] px-[16px]"
                      @click.prevent="editPriceOption(op)"
                    >
                      Edit
                    </li>
                    <li
                      v-if="hasPermission('Price_Option_Archive')"
                      class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer border-b border-[#e5e7eb] py-[12px] px-[16px]"
                      @click.prevent="openArchiveCaution(op.id)"
                    >
                      Archive
                    </li>
                    <DeletePriceOption
                      v-if="hasPermission('Price_Option_Delete')"
                      :id="op.id"
                      :isTextBtn="true"
                      @onComplete="onActionComplete"
                      @showCaution="onDelete"
                      :ref="op.id"
                    />
                  </ul>
                </div>
              </td>
            </tr>
            <tr v-if="!priceOptions.length">
              <td colspan="7">
                <div class="text-center" style="padding-top: 50px"></div>
                <h3
                  class="text-center pt-[32px] text-[14px] font-normal leading-[16.94px]"
                  style="padding-bottom: 100px"
                >
                  There are no price options associated with this Product
                </h3>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination
        :limit="pageSize"
        :count="pageCount"
        :page="page"
        :itemsCount="count"
        @changePage="gotoPage"
        @changeLimit="changeLimit"
      />
    </div>
    <AddEdit
      :priceOption="priceOption"
      :showUpdate="showUpdate"
      @hide="hideModal"
      @onComplete="onActionComplete"
      :productId="product.id"
    />
    <DeleteCaution :id="id" @proceed="deletePriceOption" entity="DP" />
    <ArchiveCaution :id="id" @proceed="archivePriceOption" entity="DP" />
    <PriceOptionFilter @onFilter="performFiltering" />
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import { helper } from "@/helper";
import AddEdit from "../PriceOption/AddEditPriceOption";
import DeletePriceOption from "../ManageOptionPrice/DeletePriceOption";
import DeleteCaution from "../DeleteCaution.vue";
import ArchiveCaution from "../ArchiveCaution.vue";
import PriceOptionFilter from "../PriceOption/Filter";
import SwitchStatus from "../ManageOptionPrice/SwitchPriceOptionStatus";
import SystemPermissions from "@/permissions";
import Pagination from "../Pagination.vue";

export default {
  props: ["productId"],
  mixins: [SystemPermissions.Mixin],
  components: {
    AddEdit,
    DeleteCaution,
    PriceOptionFilter,
    DeletePriceOption,
    SwitchStatus,
    Pagination,
    ArchiveCaution,
  },

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      name: "",
      active: "",
      showDropdown: null,
      count: 0,
      page: 1,
      pageNumber: 0,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
      showUpdate: false,
      priceOptions: [],
      priceOption: null,
      activeLoading: false,
      deleteLoading: false,
      id: null,
      product: {},
      archiveId: null,
    };
  },
  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
    // sortedArray: function() {
    // 	function compare(a, b) {
    // 		if (a.name < b.name) return -1;
    // 		if (a.name > b.name) return 1;
    // 		return 0;
    // 	}

    // 	return this.priceOptions.sort(compare);
    // },
  },

  created() {
    if (!this.hasPermission("Price_Option_View")) {
      this.$router.push("/restricted");
    }
    this.id = this.productId;
    this.getProduct();
    this.getPriceOptions();
  },

  methods: {
    async getProduct() {
      this.isBusy = true;
      try {
        const response = await this.$http.get(
          "/Products/getByIdForManufacturer/" + this.id
        );
        if (response.ok) this.product = response.body;
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },
    toggleDropdown(userId) {
      this.showDropdown = this.showDropdown === userId ? null : userId;
    },
    addOption() {
      $("#mod-price-option-add").modal("show");
    },

    editPriceOption(priceOption) {
      this.showUpdate = true;
      this.priceOption = priceOption;
      $("#mod-price-option-add").modal("show");
    },

    openArchiveCaution(id) {
      this.archiveId = id;
      $("#mod-archive-caution").modal("show");
    },

    async archivePriceOption() {
      this.isBusy = true;
      try {
        const response = await this.$http.put(
          "/priceoptions/archive/" + this.archiveId
        );
        if (response.ok) {
          this.getPriceOptions();
          $("#mod-archive-caution").modal("hide");
          this.$toast.success("Price option archived successfully");
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    onDelete(id) {
      this.id = id;
      $("#mod-delete-caution").modal("show");
    },

    onActionComplete() {
      this.getPriceOptions();
    },

    async deletePriceOption(id) {
      $("#mod-delete-caution").modal("hide");
      const componenet = this.$refs[id][0];
      componenet.deleteDistributorPrice();
    },

    filter() {
      $("#mod-dp-filter").modal("show");
    },

    hideModal() {
      $("#mod-price-option-add").modal("hide");
      this.showUpdate = false;
      this.priceOption = null;
    },

    onActionComplete() {
      this.hideModal();
      this.getPriceOptions();
    },

    // goBack() {
    //   window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    // },

    async getPriceOptions() {
      try {
        this.isBusy = true;

        const response = await this.$http.get(
          "/PriceOptions/getList?pageNumber=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            "&name=" +
            this.name +
            "&active=" +
            this.active +
            "&ProductId=" +
            this.productId
        );
        if (response.ok && response.status === 200) {
          this.priceOptions = response.body.data.sort(function(x, y) {
            let a = x.name.toUpperCase(),
              b = y.name.toUpperCase();
            return a == b ? 0 : a > b ? 1 : -1;
          });
          this.page = response.body.pageNumber;
          this.pageSize = response.body.pageSize;
          this.count = response.body.count;
          this.hasPrev = response.body.hasPrev;
          this.hasNext = response.body.hasNext;
          this.pageCount = Math.ceil(
            response.body.count / response.body.pageSize
          );
        }
      } catch (error) {
        this.isBusy = false;
      }
    },

    async performFiltering(filteringOptions) {
      this.name = filteringOptions.name;
      this.active =
        filteringOptions.active == "empty"
          ? ""
          : filteringOptions.active == "true";

      this.getPriceOptions();
      $("#mod-dp-filter").modal("hide");
    },

    formatDate(date) {
      if (date) return moment(date).format("ll");
      else return "";
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },
    prev() {
      this.page -= 1;
      this.getPriceOptions();
    },
    next() {
      this.page += 1;
      this.getPriceOptions();
    },

    gotoPage(page) {
      this.page = page;
      this.getPriceOptions();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getPriceOptions();
    },
  },
};
</script>
