<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-caution"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-warning">
          <h4 class="modal-title p-1">
            <i class="fa fa-warning"></i>
            <strong class="ml-1">Caution</strong>
          </h4>
        </div>
        <div class="modal-body">
          <p>
            The quantity in stock cannot complete the following product orders:
          </p>
          <ul>
            <li v-for="(product, index) in products" :key="index">
              {{ product.name }} - Need {{ product.neededQuantity }} more
              {{ product.unit }}
            </li>
          </ul>
        </div>
        <div
          class="modal-footer d-flex justify-content-center align-items-center"
        >
          <p>Do wish to continue?</p>
          <button class="btn btn-secondary" type="button" data-dismiss="modal">
            No
          </button>

          <button class="btn btn-success" type="button" @click.prevent="accept">
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderCautionModal",
  props: ["products"],

  methods: {
    accept() {
      this.$emit("makeOrder");
    },
  },
};
</script>

<style>
</style>