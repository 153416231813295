<template>
  <div>
    <div
      class="fixed inset-0 bg-[rgba(0,0,0,0.4)] z-[1111111] flex justify-end overflow-y-auto"
      id="popup-content"
      @click.self="closeModal"
    >
      <div
        class="relative w-full lg:w-[80%] max-h-screen bg-[#f8f8f8] p-[15px] md:p-[40px] overflow-auto"
      >
        <div
          class="shadow-none bg-transparent mb-[16px] flex items-center justify-between"
        >
          <p class="text-[#353535] text-[20px] leading-[20px] font-semibold">
            Price Bulk Upload
          </p>
          <button
            class="text-2xl text-[#353535] cursor-pointer outline-none focus:outline-none"
            type="button"
            @click="closeModal"
          >
            <span class="mdi text-xl mdi-close"></span>
          </button>
        </div>
        <div
          class="flex flex-col md:flex-row items-start md:items-center justify-between"
        >
          <div class="">
            <label for="file_input">
              <input
                class="hidden"
                id="file_input"
                type="file"
                ref="fileUpload"
                @change="onFileExcelChange"
              />
              <div
                class="flex gap-[5px] items-center w-full bg-transparent max-w-md"
              >
                <span
                  ref="fileUpload"
                  @change="onFileExcelChange"
                  class="text-[14px] font-medium"
                >
                  No File Chosen
                </span>
              </div>
            </label>
          </div>
          <a
            class="border-[1px] bg-[#2463eb] rounded-[8px] text-white font-normal text-[14px] border-solid px-[16px] md:px-[24px] py-[12px] flex items-center gap-[5px]"
            download="Price-Options-Upload-Template-Enterprise.xlsx"
            href="/assets/templates/Price-Options-Upload-Template-Enterprise.xlsx"
          >
            <span><img src="/assets/icon/document-upload.svg" alt=""/></span
            ><span>
              Download Template
            </span>
          </a>
        </div>
        <div
          class="bg-white mt-[16px] rounded-[4px] border-[#DBDCE0] border-[1.5px] border-solid"
        >
          <div class="table-responsive">
            <table class="table p-0 m-0">
              <thead class="bg-white">
                <tr>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Action
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Product Code
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Price Option Id
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Price Option Name
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Price Option Description
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Distributor Price
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Retail Price
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Group Code
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Active (Y/N)
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  ></th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                    width="5%"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(l, index) in priceUpload" :key="l.id">
                  <td>
                    <span v-if="l.action_Error" class="text-danger">
                      <i class="feather icon-alert-triangle"></i> Invalid</span
                    >
                    <span v-else>{{ l.action }} </span>
                  </td>
                  <td>
                    <span v-if="l.productCode_Error" class="text-danger">
                      <i class="feather icon-alert-triangle"></i> Invalid</span
                    >
                    <span v-else>{{ l.productCode }} </span>
                  </td>
                  <td>
                    <span v-if="l.optionId_Error" class="text-danger">
                      <i class="feather icon-alert-triangle"></i> Invalid</span
                    >
                    <span v-else>{{ l.optionId }} </span>
                  </td>
                  <td>
                    <span v-if="l.name_Error" class="text-danger">
                      <i class="feather icon-alert-triangle"></i> Invalid</span
                    >
                    <span v-else>{{ l.name }} </span>
                  </td>
                  <td>
                    <span v-if="l.description_Error" class="text-danger">
                      <i class="feather icon-alert-triangle"></i> Invalid</span
                    >
                    <span v-else>{{ l.description }} </span>
                  </td>
                  <td>
                    <span v-if="l.distributorPrice_Error" class="text-danger">
                      <i class="feather icon-alert-triangle"></i> Invalid</span
                    >
                    <span v-else>{{ l.distributorPrice }} </span>
                  </td>
                  <td>
                    <span v-if="l.retailPrice_Error" class="text-danger">
                      <i class="feather icon-alert-triangle"></i> Invalid</span
                    >
                    <span v-else>{{ l.retailPrice }} </span>
                  </td>
                  <td>
                    <span v-if="l.group_code_Error" class="text-danger">
                      <i class="feather icon-alert-triangle"></i> Invalid</span
                    >
                    <span v-else>{{ l.group_code }} </span>
                  </td>
                  <td>
                    <span v-if="l.active_Error" class="text-danger">
                      <i class="feather icon-alert-triangle"></i> Invalid</span
                    >
                    <span v-else>{{ l.activeCond }} </span>
                  </td>
                  <td class="text-danger">
                    <i v-if="l.errors" class="feather icon-alert-triangle"></i
                    >&nbsp;{{ l.errors }}
                  </td>
                  <td>
                    <div class="d-flex">
                      <button
                        class="btn btn-success btn-sm btn-rounded btn-space"
                        @click.prevent="viewEdit(l)"
                      >
                        <i class="feather icon-edit"></i>
                        Edit</button
                      >&nbsp;
                      <button
                        class="btn btn-danger btn-sm btn-rounded btn-space"
                        @click.prevent="onRemoveClick(index)"
                      >
                        <i class="feather icon-delete"></i> Delete
                      </button>
                    </div>
                  </td>
                </tr>
                <tr v-if="!priceUpload.length">
                  <td colspan="12">
                    <div
                      class="text-center flex flex-col gap-[16px] items-center justify-center"
                      style="padding-top: 100px; padding-bottom: 100px"
                    >
                      <div>
                        <img
                          src="../../../public/assets/img/document-upload.svg"
                          alt="Upload Icon"
                        />

                        <p
                          class="text-[14px] font-medium leading-[17px] text-center"
                        >
                          Kindly upload a file
                        </p>
                      </div>
                      <label for="file_input">
                        <input class="hidden" id="file_input" type="file" />
                        <div
                          class="flex gap-[5px] items-center w-full bg-transparent max-w-md"
                        >
                          <div
                            class=" border-[#96A0B5] border-[1.5px] border-solid rounded-[8px] px-[24px] py-[12px] cursor-pointer"
                          >
                            <span
                              class="text-[14px] font-medium text-[#96A0B5] leading-[17px]"
                              >Choose file to upload</span
                            >
                          </div>
                        </div>
                      </label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <button
          class="my-[16px] bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] outline-none focus:outline-none"
          type="button"
          @click.prevent="onUploadClick"
        >
          Post
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import datePicker from "vue-bootstrap-datetimepicker";
import { helper } from "@/helper";
import Select2 from "../Select2";
import SystemPermissions from "@/permissions";
import UploadCaution from "../UploadCaution";
import XLSX from "xlsx";

export default {
  mixins: [SystemPermissions.Mixin],

  name: "productsUpload",
  components: {
    Select2,
    UploadCaution,
    datePicker,
  },
  props: {
    isModalVisible: {
      type: Boolean,
      default: false,
    },
  },

  //   mounted(){
  //       window.addEventListener('load', this.onLoad());
  //   },

  created() {
    if (!this.hasPermission("Product_Create")) {
      this.$router.push("/restricted");
    }
    this.getGroup();
    this.getTypes();
    this.getCategories();
    this.getUnits();
    this.originalpriceOptions = this.priceOptions;
  },

  updated() {
    if (this.$route.params.id && this.priceOptions) {
      const product = this.priceOptions.find(
        (prod) => prod.id === this.$route.params.id
      );
      this.viewEdit(product);
    }
  },

  watch: {},

  data() {
    return {
      options: {
        format: "YYYY-MM-DD",
        useCurrent: false,
      },
      isBusy: false,
      isBusyModal: false,
      createPriceOption: {
        action: null,
        productCode: null,
        optionId: null,
        name: null,
        description: null,
        distributorPrice: 0,
        retailPrice: 0,
        group_code: null,
        active: null,
      },
      updatePriceOption: {
        action: null,
        productCode: null,
        optionId: null,
        name: null,
        description: null,
        distributorPrice: 0,
        retailPrice: 0,
        group_code: null,
        active: null,
        activeCond: null,
      },
      v1: {
        action: false,
        productCode: false,
        optionId: false,
        name: false,
        description: false,
        distributorPrice: false,
        retailPrice: false,
        group_code: false,
        active: false,
        is_valid: true,
      },
      v2: {
        action: false,
        productCode: false,
        optionId: false,
        name: false,
        description: false,
        distributorPrice: false,
        retailPrice: false,
        group_code: false,
        active: false,
        is_valid: true,
      },
      priceOptions: [],
      editPriceOption: {},
      originalpriceOptions: [],
      manufacturerpriceOptions: [],
      types: [],
      categories: [],
      units: [],
      manufacturerPrice: null,
      manufacturerCategory: null,
      manufacturerUnit: null,
      allowPriceOverride: true,
      priceUpload: [],
      errorCount: 0,
      modalText: "",
      action: "",
      removeIndex: 0,
      isProductExist: false,
      isNewPriceOption: false,
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  methods: {
    async getGroup() {
      this.isBusy = true;
      try {
        const response = await this.$http.get(`/Groups/getGroup/`);
        if (response.ok) {
          this.groupId = response.body;
          this.getManufacturerpriceOptions();
        }

        this.isBusy = true;
      } catch (error) {
        this.isBusy = false;
      }
    },
    openModal() {
      this.$emit("update:isModalVisible", true);
    },
    closeModal() {
      this.$emit("update:isModalVisible", false);
    },
    closeModal() {
      this.$emit("close");
    },
    async getPrice(code, isEdit) {
      var id = this.getManufacturerProductIdByCode(code);
      this.isBusy = true;
      try {
        if (this.groupId) {
          const response = await this.$http.get(
            `/Groups/price/${this.groupId}/${id}`
          );

          if (response.status === 200) {
            this.prepareProduct(id, response.body, isEdit);
          }
        } else {
          this.prepareProduct(
            id,
            { distributorPrice: 0, retailPrice: 0 },
            isEdit
          );
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    prepareProduct(id, price, isEdit) {
      const product = this.manufacturerpriceOptions.find((pr) => pr.id === id);

      this.manufacturerCategory = product.category.name;
      this.manufacturerUnit = product.unit;
      this.allowPriceOverride = !product.allowPriceOverride;
      this.manufacturerPrice =
        price.distributorPrice > 0
          ? price.distributorPrice
          : product.distributorPrice;
      if (isEdit) {
        this.updatePriceOption.retailPrice =
          price.retailPrice > 0 ? price.retailPrice : product.retailPrice;
      } else {
        this.createPriceOption.retailPrice =
          price.retailPrice > 0 ? price.retailPrice : product.retailPrice;
      }
    },

    onFileExcelChange(e) {
      this.errorCount = 0;
      const file = e.target.files[0];

      var reader = new FileReader(),
        self = this;
      reader.onload = (event) => {
        let arrayData = [];
        //parse data
        var bstr = event.target.result;
        const workBook = XLSX.read(bstr, { type: "binary" });
        //get first sheet
        const workSheetName = workBook.SheetNames[0];
        const workSheet = workBook.Sheets[workSheetName];
        //convert array
        const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
        const headers = fileData[0];
        fileData.splice(0, 1);

        for (var i = 0; i < fileData.length; i++) {
          if (fileData[i].length > 0) {
            let objectItem = {
              action: fileData[i][0],
              action_error: false,
              productCode: fileData[i][1],
              productCode_error: false,
              optionId: fileData[i][2] ? fileData[i][2] : "",
              optionId_error: false,
              name: fileData[i][3],
              name_error: false,
              description: fileData[i][4],
              description_error: false,
              distributorPrice: fileData[i][5],
              distributorPrice_error: false,
              retailPrice: fileData[i][6],
              retailPrice_error: false,
              group_code: fileData[i][7],
              group_code_error: false,
              active: fileData[i][8],
              active_error: false,
            };

            arrayData.push(objectItem);
          }
        }

        self.priceUpload = arrayData;

        for (var i = 0; i < self.priceUpload.length; i++) {
          var errors = "";
          var priceOpt = self.priceUpload[i];

          if (
            !priceOpt.action &&
            (priceOpt.action.toLowerCase() !== "new" ||
              priceOpt.action.toLowerCase() !== "update")
          ) {
            errors = errors + "|Invalid price action";
            self.v2.action = true;
            priceOpt.action_error = true;
          }

          if (self.validateProductCode(priceOpt.productCode) === true) {
            errors = errors + "|Product code already exist";
            self.v2.productCode = true;
            priceOpt.productCode_error = true;
          }

          if (!priceOpt.productCode) {
            errors = errors + "|Invalid product code";
            self.v2.productCode = true;
            priceOpt.productCode_error = true;
          }
          if (
            !priceOpt.optionId &&
            priceOpt.action.toLowerCase() === "update"
          ) {
            errors = errors + "|Invalid price option id";
            self.v2.optionId = true;
            priceOpt.optionId_error = true;
          }

          if (!priceOpt.name || priceOpt.name.length < 2) {
            errors = errors + "|Invalid price option name";
            self.v2.name = true;
            priceOpt.name_error = true;
          }

          if (!priceOpt.description) {
            errors = errors + "|Invalid price option description";
            self.v2.description = true;
            priceOpt.description_error = true;
          }

          if (!priceOpt.distributorPrice) {
            errors = errors + "|Invalid distributor price";
            self.v2.distributorPrice = true;
            priceOpt.distributorPrice_error = true;
          }

          if (!priceOpt.retailPrice) {
            errors = errors + "|Invalid retail price";
            self.v2.retailPrice = true;
            priceOpt.retailPrice_error = true;
          }

          if (!priceOpt.group_code) {
            errors = errors + "|Invalid group code";
            priceOpt.group_code_error = true;
          }

          if (
            !priceOpt.active &&
            (priceOpt.active !== "Y" || priceOpt.active !== "N")
          ) {
            errors =
              errors +
              "|Invalid price option active status, input Y for yes and N for No";
            priceOpt.active_error = true;
          } else if (priceOpt.active === "Y") {
            self.priceUpload[i].active = true;
            self.priceUpload[i].activeCond = "Y";
          } else {
            self.priceUpload[i].active = false;
            self.priceUpload[i].activeCond = "N";
          }

          if (
            isNaN(priceOpt.distributorPrice) &&
            Number(priceOpt.distributorPrice) <= 0
          ) {
            errors =
              errors + "| distributor price must be a number greater than 0";
            self.v2.distributorPrice = true;
            priceOpt.distributorPrice_error = true;
          }

          if (isNaN(priceOpt.retailPrice) && Number(priceOpt.retailPrice)) {
            errors = errors + "| retail price must be a number greater than 0";
            self.v2.retailPrice = true;
            priceOpt.retailPrice_error = true;
          }

          if (!errors) {
            priceOpt.name = priceOpt.name.trim();
            // priceOpt.productCode = priceOpt.productCode.trim();
            priceOpt.description = priceOpt.description.trim();
          } else self.errorCount++;

          self.priceUpload[i].errors = errors;
        }
      };

      reader.readAsBinaryString(file);
    },

    reset() {
      this.errorCount = 0;
      this.priceUpload.splice(0);
      this.$refs.fileUpload.value = null;
    },
    cancel() {
      const currentPriceOption = this.priceOptions.find(
        (p) => p.id === this.editPriceOption.id
      );
      this.allowPriceOverride = true;
      currentPriceOption.manufacturer_productId = this.editPriceOption.manufacturer_productId;
      currentPriceOption.distributor_productId = this.editPriceOption.distributor_productId;
      currentPriceOption.product_Name = this.editPriceOption.product_Name;
      currentPriceOption.product_Description = this.editPriceOption.product_Description;
      currentPriceOption.retailPrice = this.editPriceOption.retailPrice;
      currentPriceOption.minimum_stock_level = this.editPriceOption.minimum_stock_level;
      $("#mod-edit").modal("hide");
    },

    viewExport() {
      //   $("#mod-export").modal("show");
    },

    viewAdd() {
      this.manufacturerPrice = null;
      this.manufacturerCategory = null;
      this.manufacturerUnit = null;
      this.allowPriceOverride = null;
      this.createPriceOption = {
        action: null,
        productCode: null,
        optionId: null,
        name: null,
        description: null,
        distributorPrice: 0,
        retailPrice: 0,
        group_code: null,
        active: null,
      };
      this.v1 = {
        action: false,
        productCode: false,
        optionId: false,
        name: false,
        description: false,
        distributorPrice: false,
        retailPrice: false,
        group_code: false,
        active: false,
        is_valid: true,
      };
      $("#mod-add").modal("show");
    },
    viewEdit(c) {
      // var manufacturerProductId = this.getManufacturerProductIdByCode(
      //   c.manufacturer_productId
      // );
      var editValues = {
        action: c.action,
        productCode: c.productCode,
        optionId: c.optionId,
        name: c.name,
        description: c.description,
        distributorPrice: c.distributorPrice ? c.distributorPrice : 0,
        retailPrice: c.retailPrice ? c.retailPrice : 0,
        group_code: c.group_code,
        active: c.active,
      };
      this.editPriceOption = editValues;
      this.updatePriceOption = c;
      if (c.action.toLowerCase() === "new") {
        this.isNewPriceOption = true;
      } else {
        this.isNewPriceOption = false;
      }

      $("#mod-edit").modal("show");
    },
    viewUploadExpenses() {
      this.$router.push("/manage-expenses/upload");
    },

    onRemoveClick(index) {
      this.modalText = "Are you sure you want to remove this item?";
      this.action = "remove";
      this.removeIndex = index;
      $("#mod-expense-caution").modal("show");
    },

    remove() {
      var expense = this.priceUpload[this.removeIndex];
      if (expense.errors) {
        this.errorCount--;
      }
      this.priceUpload.splice(this.removeIndex, 1);
      this.removeIndex = 0;
      $("#mod-expense-caution").modal("hide");
    },

    onUploadClick() {
      this.modalText = "Are you sure?";
      this.action = "upload";
      $("#mod-expense-caution").modal("show");
    },

    async uploadPrices() {
      $("#mod-expense-caution").modal("hide");
      this.isBusyModal = true;
      this.isBusy = true;

      let priceUploadRequest = this.priceUpload;

      if (priceUploadRequest.length > 0) {
        priceUploadRequest.forEach((item) => {
          delete item.activeCond;
          delete item.group_code;
          delete item.errors;
          delete item.action;
          delete item.action_error;
          delete item.active_error;
          delete item.description_error;
          delete item.distributorPrice_error;
          delete item.group_code_error;
          delete item.name_error;
          delete item.optionId_error;
          delete item.productCode_error;
          delete item.retailPrice_error;
        });
      }

      var data = {
        priceOptions: priceUploadRequest,
      };

      await this.$http
        .post("/PriceOptions/CreatePriceOptionBulk", data)
        .then(function(res) {
          this.$router.push("/manufacturer-products");
        })
        .catch(function() {
          this.isBusyModal = false;
          this.isBusy = false;
        });
    },

    async create() {
      this.isBusyModal = true;
      this.isBusy = true;

      if (this.validateCreate()) {
        this.priceUpload.push(this.createPriceOption);
        $("#mod-add").modal("hide");
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },
    validateCreate() {
      this.v1 = {
        action: false,
        productCode: false,
        optionId: false,
        name: false,
        description: false,
        distributorPrice: false,
        retailPrice: false,
        group_code: false,
        active: false,
      };
      var isValid = true;

      if (!this.createPriceOption.action) {
        this.v1.action = true;
        isValid = false;
      }

      if (
        !this.createPriceOption.productCode &&
        !this.createPriceOption.action.toLowerCase() == "new"
      ) {
        this.v1.productCode = true;
        isValid = false;
      }

      if (
        !this.createPriceOption.optionId &&
        !this.createPriceOption.action.toLowerCase() == "update"
      ) {
        this.v1.product_Name = true;
        isValid = false;
      }

      if (
        !this.createPriceOption.name &&
        !this.createPriceOption.action.toLowerCase() == "new"
      ) {
        this.v1.productCode = true;
        isValid = false;
      }

      if (
        !this.createPriceOption.description &&
        !this.createPriceOption.action.toLowerCase() == "new"
      ) {
        this.v1.productCode = true;
        isValid = false;
      }

      if (Number(this.createPriceOption.retailPrice) <= 0) {
        this.v1.retailPrice = true;
        isValid = false;
      }

      if (Number(this.createPriceOption.distributorPrice) < 0) {
        this.v1.restockLevel = true;
        isValid = false;
      }

      if (!this.createPriceOption.active) {
        this.v1.action = true;
        isValid = false;
      }

      if (
        !this.createPriceOption.activeCond &&
        (this.createPriceOption.activeCond !== "Y" ||
          this.createPriceOption.activeCond !== "N")
      ) {
        this.v2.active = true;
        isValid = false;
      } else if (this.createPriceOption.activeCond === "Y") {
        this.createPriceOption.active = true;
        this.createPriceOption.activeCond = "Y";
      } else {
        this.createPriceOption.active = false;
        this.createPriceOption.activeCond = "N";
      }

      return isValid;
    },

    async update() {
      this.isBusyModal = true;
      this.isBusy = true;
      if (this.validateUpdate()) {
        // var productName = this.getManufacturerProductNameByCode(
        //   this.updatePriceOption.manufacturer_productId
        // );
        // this.updatePriceOption.manufacturer_product_Name = productName;
        if (this.updatePriceOption.errors) {
          this.updatePriceOption.errors = "";
          this.errorCount--;
        }
        $("#mod-edit").modal("hide");
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },

    validateUpdate() {
      this.v2 = {
        action: false,
        productCode: false,
        optionId: false,
        name: false,
        description: false,
        distributorPrice: false,
        retailPrice: false,
        group_code: false,
        active: false,

        is_valid: true,
      };
      var isValid = true;

      if (!this.updatePriceOption.action) {
        this.v1.action = true;
        isValid = false;
      } else {
        this.updatePriceOption.action_error = false;
      }

      if (
        !this.updatePriceOption.productCode &&
        !this.updatePriceOption.action.toLowerCase() == "new"
      ) {
        this.v1.productCode = true;
        isValid = false;
      } else {
        this.updatePriceOption.productCode_error = false;
      }

      if (
        !this.updatePriceOption.optionId &&
        !this.updatePriceOption.action.toLowerCase() == "update"
      ) {
        this.v1.product_Name = true;
        isValid = false;
      } else {
        this.updatePriceOption.optionId_error = false;
      }

      if (
        !this.updatePriceOption.name &&
        !this.updatePriceOption.action.toLowerCase() == "new"
      ) {
        this.v1.productCode = true;
        isValid = false;
      } else {
        this.updatePriceOption.name_error = false;
      }

      if (
        !this.updatePriceOption.description &&
        !this.updatePriceOption.action.toLowerCase() == "new"
      ) {
        this.v1.productCode = true;
        isValid = false;
      } else {
        this.updatePriceOption.description_error = false;
      }

      if (Number(this.updatePriceOption.retailPrice) <= 0) {
        this.v1.retailPrice = true;
        isValid = false;
      } else {
        this.updatePriceOption.retailPrice_error = false;
      }

      if (Number(this.updatePriceOption.distributorPrice) < 0) {
        this.v1.restockLevel = true;
        isValid = false;
      } else {
        this.updatePriceOption.distributorPrice_error;
      }

      if (!this.updatePriceOption.active) {
        this.v1.action = true;
        isValid = false;
      } else {
        this.updatePriceOption.active_error = false;
      }

      if (
        !this.updatePriceOption.activeCond &&
        (this.updatePriceOption.activeCond !== "Y" ||
          this.updatePriceOption.activeCond !== "N")
      ) {
        this.v2.active = true;
        isValid = false;
      } else if (this.updatePriceOption.activeCond === "Y") {
        this.updatePriceOption.active = true;
        this.updatePriceOption.activeCond = "Y";
        this.updatePriceOption.active_error = false;
      } else {
        this.updatePriceOption.active = false;
        this.updatePriceOption.activeCond = "N";
        this.updatePriceOption.active_error = false;
      }

      return isValid;
    },

    async getManufacturerpriceOptions() {
      this.isBusy = true;

      await this.$http
        .get(
          "/Products/getListNoPagingForManufacturer?active=true" +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "") +
            (this.groupId ? "&groupId=" + this.groupId : "")
        )
        .then(function(res) {
          this.manufacturerpriceOptions = res.body;
          this.isBusy = false;
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },

    getTypes() {
      this.types = ["Finite", "Infinite"];
    },

    getCategories() {
      var t = "Product Category";
      var a = true;

      this.$http
        .get(
          "/Categories/getListNoPagging?type=" +
            t +
            "&active=" +
            a +
            (this.user.parentId
              ? "&parentId=" + this.user.parentId
              : "Product Category")
        )
        .then(function(res) {
          this.categories = res.body;
        })
        .catch(function() {});
    },
    getUnits() {
      var t = "Product Unit";
      var a = true;

      this.$http
        .get(
          "/Categories/getListNoPagging?type=" +
            t +
            "&active=" +
            a +
            (this.user.parentId
              ? "&parentId=" + this.user.parentId
              : "Product Unit")
        )
        .then(function(res) {
          this.units = res.body;
        })
        .catch(function() {});
    },

    validateProductCode(code) {
      this.$http
        .get("/Products/isProductCodeExist/" + code)
        .then(function(res) {
          this.isProductExist = res.body;
          this.isBusy = false;
          return res.body;
        })
        .catch(function(res) {
          return res.body;
        });
    },

    getManufacturerProductNameByCode(code) {
      var product = this.manufacturerpriceOptions.find((x) => x.code === code);
      if (product == null || product == undefined) return "";
      else return product.name + " (" + code + ")";
    },

    getManufacturerProductNameById(id) {
      var product = this.manufacturerpriceOptions.find((x) => x.id === id);
      if (product == null || product == undefined) return "";
      else return product.name + " (" + product.code + ")";
    },

    getManufacturerProductIdByCode(code) {
      var product = this.manufacturerpriceOptions.find((x) => x.code === code);
      if (product == null || product == undefined) return "";
      else return product.id;
    },

    formatDate(date) {
      if (date) return moment(date).format("lll");
      else return "";
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },
  },
};
</script>
