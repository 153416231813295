<template>
  <div
    v-if="payment.paymentStatus != 'AUTHORIZED'"
    class="custom-control custom-checkbox"
  >
    <input
      type="checkbox"
      class="custom-control-input"
      :id="payment.id"
      :value="payment.id"
      :disabled="payment.paymentStatus === 'AUTHORIZED'"
      @change="onPaymentSelected($event.target.value)"
    />
    <label class="custom-control-label" :for="payment.id"></label>
  </div>
</template>

<script>
export default {
  name: "AccountPostinCheckBox",
  props: ["payment"],

  data() {
    return {
      isSelected: false,
    };
  },

  methods: {
    onPaymentSelected(value) {
      this.isSelected = !this.isSelected;
      if (this.isSelected) this.$emit("onPaymentAdd", value);
      else this.$emit("onPaymentRemove", value);
    },
  },
};
</script>
