<template>
    <div class="be-splash-screen">

        <div class="forny-container">
            <div class="forny-inner">
                <div class="forny-two-pane">
                    <div>
                        <div class="forny-form">
                            <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item">

                                </li>
                            </ul>

                            <div class="tab-content">
                                <div class="tab-pane fade show active" role="tabpanel" id="login">
                                    <a class="nav-link active bg-transparent" href="#login" data-toggle="tab" role="tab">
                                        <span>
                                            <img class="logo-img"
                                                 src="/assets/img/logo-xx.png"
                                                 alt="logo"
                                                 width="250">
                                        </span>
                                    </a>
                                    <h1 class="font-weight-bold mt-3">
                                        Change Password
                                    </h1>
                                    <p class="mt-4 mb-6 font-medium-2">
                                        Please change your password to continue.
                                    </p>
                                    <form>
                                        <div class="form-group ">
                                            <input :class="['form-control', { 'is-invalid':passwordValidation.password }, { 'is-invalid':passwordValidation.newValid }]"
                                                   type="password"
                                                   placeholder="New Password"
                                                   v-model="user.password">
                                            <ul v-if="passwordValidation.password" class="parsley-errors-list filled">
                                                <li class="parsley-required text-danger">Required.</li>
                                            </ul>
                                            <ul v-if="passwordValidation.newValid" class="parsley-errors-list filled">
                                                <li class="parsley-required text-danger">
                                                    Enter greater than or equal to 6
                                                    characters.
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="form-group ">
                                            <input :class="['form-control', { 'is-invalid':passwordValidation.confirmPassword }, { 'is-invalid':passwordValidation.confirmValid }]"
                                                   type="password"
                                                   placeholder="Confirm Password"
                                                   v-model="user.confirmPassword">
                                            <ul v-if="passwordValidation.confirmPassword" class="parsley-errors-list filled">
                                                <li class="parsley-required text-danger">Required.</li>
                                            </ul>
                                            <ul v-if="passwordValidation.confirmValid" class="parsley-errors-list filled">
                                                <li class="parsley-required text-danger">
                                                    Passwords do not match.
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="form-group">
                                            <button :disabled="isBusy"
                                                    class="btn btn-block btn-primary btn-lg p-1"
                                                    @click.prevent="changePassword">
                                                <i class="feather icon-lock"></i> &nbsp;
                                                <span v-if="isBusy">Busy...</span>
                                                <span v-else>Change password</span>
                                            </button>

                                        </div>
                                        <p class="text-center">
                                            <router-link to="/login">Back to Login</router-link>
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="right-pane">
                        <div class="text-center" style="width: 300px; margin-top:-140px">
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    /* eslint-disable */

    import { helper } from "@/helper";
    import { required, email } from "vuelidate/lib/validators";

    export default {
        name: "changePassword",
        data() {
            return {
                isBusy: false,
                user: {
                    emailAddress: "",
                    password: "",
                    confirmPassword: ""
                },
                passwordValidation: {
                    password: false,
                    confirmPassword: false,
                    newValid: false,
                    confirmValid: false
                }
            };
        },
        validations: {
            user: {
                password: { required },
                confirmPassword: { required }
            }
        },
        methods: {
            async changePassword() {
                this.$v.$touch();

                if (this.validatePassword()) {

                    this.isBusy = true;

                    let data = {
                        id: this.$store.state.userId,
                        password: this.user.password,
                        confirmPassword: this.user.confirmPassword
                    }

                    await this.$http
                        .post("/Auth/changePassword", data)
                        .then(function (res) {
                            $.gritter.add({
                                title: "Password change successful",
                                text: "Your password has been changed successfully. Please login to continue.",
                                class_name: "color success"
                            });

                            this.$router.push("/login");
                        })
                        .catch(function () { });

                    this.isBusy = false;
                }
            },
            validatePassword() {
                this.passwordValidation = {
                    password: false,
                    confirmPassword: false,
                    newValid: false,
                    confirmValid: false
                };
                let isValid = true;

                if (!this.user.password) {
                    this.passwordValidation.password = true;
                    isValid = false;
                }

                if (!this.user.confirmPassword) {
                    this.passwordValidation.confirmPassword = true;
                    isValid = false;
                }

                if (this.user.password && !helper.validateLength(this.user.password, 6)) {
                    this.passwordValidation.newValid = true;
                    isValid = false;
                }

                if (this.user.password !== this.user.confirmPassword) {
                    this.passwordValidation.confirmValid = true;
                    isValid = false;
                }

                return isValid;
            },
        }
    };
</script>