<template>
  <div class="row">
    <div class="col">
      <div class="d-flex justify-content-between mb-[16px]">
        <div></div>
        <button
          v-if="!isView"
          class="bg-[#2463EB] text-[14px] font-normal text-white p-[10px] rounded-[8px]"
          type="button"
          @click="addOrderDetails"
        >
          <i class="mdi mdi-plus text-white"></i>
          Add item
        </button>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead class="bg-[#ECF9FF]">
          <tr>
            <th
              class="text-[12px] leading-[18px] text-[#000000] font-semibold"
              scope="col"
            >
              #Item
            </th>
            <th
              class="text-[12px] leading-[18px] text-[#000000] font-semibold"
              scope="col"
            >
              Description
            </th>
            <th
              class="text-[12px] leading-[18px] text-[#000000] font-semibold"
              scope="col"
            >
              Quantity
            </th>
            <th
              class="text-[12px] leading-[18px] text-[#000000] font-semibold"
              scope="col"
            >
              Total
            </th>
            <th
              class="text-[12px] leading-[18px] text-[#000000] font-semibold"
              scope="col"
            ></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td
              class="text-[14px] font-normal leading-[16.94px] text-[#353535]"
            >
              {{ index + 1 }}
            </td>
            <td
              class="text-[14px] font-normal leading-[16.94px] text-[#353535]"
            >
              {{ item.description }}
            </td>
            <td
              class="text-[14px] font-normal leading-[16.94px] text-[#353535]"
            >
              {{ item.quantity }}
            </td>
            <td
              class="text-[14px] font-normal leading-[16.94px] text-[#353535]"
            >
              &#8358;
              <span class="font-semibold leading-[20px]">{{
                formatMoney(item.quantity * item.price)
              }}</span>
            </td>
            <td>
              <p
                v-if="!isView"
                class="text-[#96A0B5] text-[14px] font-normal leading-[20px] cursor-pointer"
                @click.prevent="onEdit(item)"
              >
                Edit
              </p>
            </td>
            <td>
              <RemoveItem
                v-if="!isView"
                :item="item"
                @onRemove="onRemoveHandler"
              />
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>Total:</td>
            <td class="text-[14px] font-semibold leading-[20px] text-[#353535]">
              &#8358;{{ formatMoney(totalAmount) }}
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { helper } from "@/helper";
import RemoveItem from "./ItemDetails/RemoveItemDetails";

export default {
  name: "ManufacturerPurchaseOrderDetail",

  props: ["items", "isView"],

  components: {
    RemoveItem,
  },

  computed: {
    totalAmount() {
      return this.items.reduce((total, item) => {
        return total + item.quantity * item.price;
      }, 0);
    },
  },

  methods: {
    addOrderDetails() {
      $("#mod-product-add").modal("show");
    },
    addItem() {
      $("#mod-apply-order-item").modal("show");
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    onEdit(item) {
      this.$emit("performEdit", {
        id: item.productId,
        productId: item.id,
        quantity: item.quantity,
      });
    },

    onRemoveHandler(itemId) {
      this.$emit("onRemoveHandler", itemId);
    },

    removeItem(id) {
      this.$emit("onDeleteItem", id);
    },
  },
};
</script>

<style></style>
