<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <!--<button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
                        <span class="feather icon-filter text-white"></span> Filter
                    </button>-->
          &nbsp;
          <button
            class="btn btn-primary btn-rounded btn-space float-right"
            @click="viewAdd"
            v-if="hasPermission('Role_Create')"
          >
            <span class="icon icon-left mdi mdi-plus text-white"></span> Create
            New Role
          </button>
        </div>
        <div class="card-body">
          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table
                  class="
                    table table-fw-widget
                    dataTable
                    no-footer
                  text-nowrap
                    table-responsiv table-striped
                  "
                >
                  <thead>
                    <tr>
                      <th style="width: 20%">Role Name</th>
                      <th style="width: 20%">Status</th>
                      <th style="width: 10%">Assigned Users</th>
                      <th style="width: 20%">Created</th>
                      <th style="width: 30%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="l in roles" :key="l.id">
                      <td>
                        {{ l.roleName }}
                      </td>
                      <td>
                        <span
                          :class="[
                            'badge',
                            ' badge-pill',
                            {
                              'badge-success': l.numberOfAssignedUsers > 0,
                            },
                            {
                              'badge-danger': l.numberOfAssignedUsers <= 0,
                            },
                          ]"
                        >
                          {{
                            l.numberOfAssignedUsers > 0
                              ? "Assigned"
                              : "Not Assigned"
                          }}
                        </span>
                      </td>
                      <td>{{ l.numberOfAssignedUsers }}</td>
                      <td>{{ formatDate(l.created) }}</td>
                      <td>
                        <div class="text-right">
                          <button
                            v-if="
                              l.parentId == user.parentId &&
                                hasPermission('Role_Update')
                            "
                            class="btn btn-success btn-sm btn-rounded btn-space"
                            @click.prevent="viewEdit(l)"
                          >
                            <i class="feather icon-edit"></i>
                            &nbsp;Manage Role</button
                          >&nbsp;
                          <!--<button v-if="l.parentId==user.parentId"
                                                            class="btn btn-secondary btn-sm btn-rounded btn-space dropdown-toggle " data-toggle="dropdown"
                                                            style="color:black">
                                                        More Actions
                                                    </button>-->
                          <button
                            class="
                              btn btn-outline-primary btn-sm
                              dropdown-toggle
                            "
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            More Actions
                          </button>
                          <div
                            class="dropdown-menu px-1"
                            aria-labelledby="dropdownMenu2"
                          >
                            <a
                              v-if="
                                l.numberOfAssignedUsers > 0 &&
                                  hasPermission('Role_User_View')
                              "
                              class="dropdown-item"
                              href="#"
                              @click.prevent="viewRoleUsers(l)"
                            >
                              View Role Users
                            </a>
                            <div
                              v-if="l.numberOfAssignedUsers > 0"
                              class="dropdown-divider"
                            ></div>
                            <a
                              v-if="
                                hasPermission('Role_Delete') &&
                                  l.roleName.toLowerCase() != 'administrator' &&
                                  l.roleName.toLowerCase() !=
                                    'support specialist' &&
                                  l.roleName.toLowerCase() != 'view only' &&
                                  l.roleName.toLowerCase() != 'sales'
                              "
                              class="dropdown-item"
                              href="#"
                              @click.prevent="deleteRole(l)"
                              style="color: red"
                            >
                              Delete Role
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!roles.length">
                      <td colspan="5">
                        <div class="text-center" style="padding-top: 50px">
                          <span
                            style="font-size: 4.615rem"
                            class="mdi mdi-crosshairs-gps"
                          ></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom: 100px">
                          You don't have any roles.
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Pagination
                  :limit="pageSize"
                  :count="pageCount"
                  :page="page"
                  :itemsCount="count"
                  @changePage="gotoPage"
                  @changeLimit="changeLimit"
                />
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }} role{{ count != 1 ? "s" : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-add"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Create new role</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Role Name</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.name }]"
                  type="text"
                  v-model="createRole.name"
                />
                <ul v-if="v1.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"
                  >Permissions</label
                >
                <div
                  class="dd col-12 col-sm-3 col-form-label"
                  style="height: 320px;overflow-y: scroll;border: 1px solid #eee;"
                >
                  <ol class="dd-list">
                    <li
                      v-for="item in permissionsListCreate"
                      v-bind:class="[
                        item.state.opened ? 'dd-item open' : 'dd-item',
                      ]"
                      :key="item.id"
                    >
                      <div
                        class="dd-handle"
                        @click="item.state.opened = !item.state.opened"
                      >
                        <input
                          type="checkbox"
                          :disabled="item.state.disabled"
                          :name="item.text"
                          :checked="item.state.selected"
                          @click="item.state.selected = !item.state.selected"
                          @change="
                            onPermissionChange(item, item.state.selected)
                          "
                        />
                        <label :for="item.text"
                          ><b>{{ item.text }}</b></label
                        >
                      </div>

                      <ol
                        v-if="item.children.length != 0"
                        class="dd-list-inner"
                      >
                        <li
                          v-for="children in item.children"
                          :data-id="children.id"
                          class="dd-item"
                          :key="children.id"
                        >
                          <div class="dd-handle">
                            <input
                              type="checkbox"
                              :name="children.text"
                              :checked="children.state.selected"
                              :disabled="children.state.disabled"
                              @click="
                                children.state.selected = !children.state
                                  .selected
                              "
                            />
                            <label :for="children.text">{{
                              children.text
                            }}</label>
                          </div>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 160px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="create"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Add Role</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Manage Role</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Role Name</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.name }]"
                  type="text"
                  v-model="updateRole.name"
                />
                <ul v-if="v2.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-12">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"
                  >Permissions</label
                >
                <div
                  class="dd col-12 col-sm-3 col-form-label"
                  style="height: 320px;overflow-y: scroll;border: 1px solid #eee;"
                >
                  <ol class="dd-list">
                    <li
                      v-for="item in permissionsListUpdate"
                      v-bind:class="[
                        item.state.opened ? 'dd-item open' : 'dd-item',
                      ]"
                      :key="item.id"
                    >
                      <div
                        class="dd-handle"
                        @click="item.state.opened = !item.state.opened"
                      >
                        <input
                          type="checkbox"
                          :disabled="item.state.disabled"
                          :name="item.text"
                          :checked="item.state.selected"
                          @click="item.state.selected = !item.state.selected"
                          @change="
                            onPermissionChange(item, item.state.selected)
                          "
                        />
                        <label :for="item.text"
                          ><b>{{ item.text }}</b></label
                        >
                      </div>

                      <ol
                        v-if="item.children.length != 0"
                        class="dd-list-inner"
                      >
                        <li
                          v-for="children in item.children"
                          :data-id="children.id"
                          class="dd-item"
                          :key="children.id"
                        >
                          <div class="dd-handle">
                            <input
                              type="checkbox"
                              :name="children.text"
                              :checked="children.state.selected"
                              :disabled="children.state.disabled"
                              @click="
                                children.state.selected = !children.state
                                  .selected
                              "
                            />
                            <label :for="children.text">{{
                              children.text
                            }}</label>
                          </div>
                        </li>
                      </ol>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
              @click="cancel"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="update"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-filter"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Filter</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Status</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="filter.status"
                >
                  <option value="0">All Roles</option>
                  <option value="True">Active</option>
                  <option value="False">Disabled</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Other filters</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Filter by code or name..."
                  v-model="filter.qry"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button>
            <button
              style="width: 100px"
              class="btn btn-primary md-close"
              type="button"
              @click="getRoles"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  padding-left: 20px;
  max-width: 600px;
  list-style: none;
  line-height: 20px;
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list-inner {
  padding-left: 30px;
}

.dd-item {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  line-height: 20px;
}

.dd-handle {
  display: block;
  margin: 5px 0;
  padding: 0px 10px;
  text-decoration: none;
  background: #fff;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
</style>
<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import Select2 from "@/components/Select2";
import SystemPermissions from "@/permissions";
import Pagination from "../components/Pagination.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsRoles",
  components: {
    Select2,
    Pagination,
  },
  created() {
    if (!this.hasPermission("Role_View")) {
      this.$router.push("/restricted");
    }
    this.getRoles();
    this.getPermissions();
  },
  watch: {},
  data() {
    return {
      filter: {
        status: "0",
        qry: "",
      },
      isBusy: false,
      isBusyModal: false,
      createRole: {
        name: null,
        permissions: [],
      },
      updateRole: {
        name: null,
        id: null,
        permissions: [],
      },
      editRole: {
        name: null,
        id: null,
        permissions: null,
      },
      v1: {
        name: false,
      },
      v2: {
        name: false,
      },
      roles: [],
      permissions: [],
      count: 0,
      page: 0,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
    };
  },
  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
    permissionsListCreate() {
      return this.createRole.permissions.map((b) => {
        return b;
      });
    },
    permissionsListUpdate() {
      return this.updateRole.permissions.map((b) => {
        return b;
      });
    },
  },
  mounted() {
    console.log(this.roles);
  },
  methods: {
    onPermissionChange(item, state) {
      for (let child of item.children) {
        child.state.selected = state;
      }
    },
    reset() {
      this.filter = {
        status: "0",
        qry: "",
      };
    },
    cancel() {
      const currentRole = this.Roles.find((l) => l.id === this.editRole.id);
      currentRole.name = this.editRole.name;
      currentRole.id = this.editRole.id;
      $("#mod-edit").modal("hide");
    },
    viewRoleUsers(r) {
      this.$router.push("/settings/roles/" + r.id + "/users?n=" + r.roleName);
    },
    viewExport() {
      //   $("#mod-export").modal("show");
    },
    viewAdd() {
      this.createRole = {
        name: null,
        permissions: JSON.parse(JSON.stringify(this.permissions)),
      };
      this.v1 = {
        name: false,
      };
      $("#mod-add").modal("show");
    },
    viewEdit(c) {
      var editValues = {
        name: c.roleName,
        id: c.id,
        permissions: JSON.parse(JSON.stringify(c.permissions)),
      };
      this.editRole = editValues;

      this.updateRole = {
        id: c.id,
        name: c.roleName,
        permissions: JSON.parse(JSON.stringify(c.permissions)),
      };

      $("#mod-edit").modal("show");
    },
    viewFilter() {
      $("#mod-filter").modal("show");
    },
    async deleteRole(c) {
      if (confirm("Are you sure you want to delete this role?")) {
        this.isBusyModal = true;
        this.isBusy = true;

        await this.$http
          .post("/Roles/delete/" + c.id, null)
          .then(function(res) {
            this.isBusyModal = false;
          })
          .catch(function() {});

        this.getRoles();

        this.isBusy = false;
      }
    },
    async create() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        name: this.createRole.name,
        parentId: this.user.parentId,
        roleType: this.user.type,
        permissions: this.createRole.permissions,
      };

      if (this.validateCreate()) {
        $("#mod-add").modal("hide");

        await this.$http
          .post("/Roles/create", data)
          .then(function(res) {
            this.isBusyModal = false;
          })
          .catch(function() {});

        this.getRoles();
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },
    validateCreate() {
      this.v1 = {
        name: false,
      };
      var isValid = true;

      if (!this.createRole.name) {
        this.v1.name = true;
        isValid = false;
      }

      return isValid;
    },
    async update() {
      this.isBusyModal = true;
      this.isBusy = true;

      const data = {
        name: this.updateRole.name,
        permissions: this.updateRole.permissions,
        roleId: this.updateRole.id,
        parentId: this.user.parentId,
      };

      if (this.validateUpdate()) {
        $("#mod-edit").modal("hide");

        await this.$http
          .put("/Roles/update/" + data.roleId, data)
          .then(function(res) {
            this.isBusyModal = false;
          })
          .catch(function() {});

        this.getRoles();
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },
    validateUpdate() {
      this.v2 = {
        name: false,
      };
      var isValid = true;

      if (!this.updateRole.name) {
        this.v2.name = true;
        isValid = false;
      }

      return isValid;
    },
    async getRoles() {
      this.isBusy = true;

      await this.$http
        .get(
          "/Roles/getRoleList?page=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            "&roleType=" +
            this.user.type +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "") +
            (this.filter.status != "0" ? "&active=" + this.filter.status : "") +
            (this.filter.qry != "" ? "&query=" + this.filter.qry : "")
        )
        .then(function(res) {
          this.roles = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },
    async getPermissions() {
      this.isBusy = true;

      await this.$http
        .get("/Roles/getPermissionList?roleType=" + this.user.type)
        .then(function(res) {
          this.permissions = res.body;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },

    formatDate(date) {
      return moment(date).format("lll");
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    prev() {
      this.page -= 1;
      this.getRoles();
    },

    next() {
      this.page += 1;
      this.getRoles();
    },

    gotoPage(page) {
      this.page = page - 1;
      this.getRoles();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getRoles();
    },
  },
};
</script>
