<template>
  <div class="row min-h-screen shadow-none">
    <div class="col-lg-12">
      <div :class="['be-loading', { 'be-loading-active': isBusy }]">
        <!-- tab main container start -->
        <div
          class="w-full pb-2 flex flex-col justify-start items-start gap-[15px]"
        >
          <div class="w-full flex justify-start items-start gap-[16px]">
            <button
              class="cursor-pointer focus:outline-none py-[5px]"
              :class="[
                'text-[14px] font-semibold leading-[20px] cursor-pointer',
                {
                  'border-b-2 border-[#2463EB] text-[#2463EB]':
                    activeSubTab === 'sent',
                  'text-[#96a0b5]': activeSubTab !== 'sent',
                },
              ]"
              @click="switchSubTab('sent')"
            >
              Sent Messages
            </button>
            <button
              class="cursor-pointer focus:outline-none py-[5px]"
              :class="[
                'text-[14px] font-semibold leading-[20px] cursor-pointer',
                {
                  'border-b-2 border-[#2463EB] text-[#2463EB]':
                    activeSubTab === 'schedule',
                  'text-[#96a0b5]': activeSubTab !== 'schedule',
                },
              ]"
              @click="switchSubTab('schedule')"
            >
              Schedule Messages
            </button>
            <button
              class="cursor-pointer focus:outline-none py-[5px]"
              :class="[
                'text-[14px] font-semibold leading-[20px] cursor-pointer',
                {
                  'border-b-2 border-[#2463EB] text-[#2463EB] pb-[5px]':
                    activeSubTab === 'draft',
                  'text-[#96a0b5]': activeSubTab !== 'draft',
                },
              ]"
              @click="switchSubTab('draft')"
            >
              Draft Messages
            </button>
          </div>

          <!-- tabs container button start -->
          <div
            class="flex xs:flex-col lg:flex-row xs:justify-start lg:justify-between w-full xs:items-start xs:gap-4 lg-gap-2 lg:items-center"
          >
            <p
              v-if="activeSubTab === 'sent'"
              class="text-[#353535] text-[14px] leading-[20px] font-semibold"
            >
              List of all send messages
            </p>
            <p
              v-if="activeSubTab === 'schedule'"
              class="text-[#353535] text-[14px] leading-[20px] font-semibold"
            >
              List of all scheduled messages
            </p>
            <p
              v-if="activeSubTab === 'draft'"
              class="text-[#353535] text-[14px] leading-[20px] font-semibold"
            >
              List of all draft messages
            </p>
            <div
              class="flex gap-1 md:gap-3 flex-wrap xs:justify-start lg:justify-center xs:items-start lg:items-center"
            >
              <div>
                <button
                  class="border-[1px] border-[#96a0b5] rounded-[8px] text-[#96a0b5] font-normal text-[12px] sm:text-[14px] border-solid px-[12px] sm:px-[24px] py-[12px] border-solid gap-[5px] outline-none focus:outline-none flex items-center gap-[5px]"
                  @click.prevent="openFilter"
                >
                  <span><img src="/assets/icon/sort.svg" alt=""/></span>
                  Filters
                </button>
              </div>
              <div v-if="hasPermission('Broadcast_Message_Create')">
                <button
                  class="border-[1px] bg-[#2463eb] rounded-[8px] text-white font-normal text-[12px] sm:text-[14px] border-solid px-[12px] sm:px-[24px] py-[12px] outline-none focus:outline-none"
                  @click="createNewBrod"
                >
                  <i class="fa-solid fa-plus"></i>
                  New broadcast
                </button>
              </div>
            </div>
          </div>
          <!-- tabs container button end -->
        </div>

        <!-- tab content for sent message start -->
        <div v-if="activeSubTab == 'sent'">
          <div class="table-responsive">
            <table class="table p-0 m-0 dataTable no-footer">
              <thead class="bg-white">
                <tr>
                  <th
                    width="40%"
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Message Title
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Broadcast Date
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Recipient
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Open Rate
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white">
                <tr v-for="m in sentMessages" :key="m.id">
                  <td class="text-[14px] font-normal leading-[16.94px]">
                    {{ m.title }}
                  </td>
                  <td class="text-[14px] font-normal leading-[16.94px]">
                    {{ formatDate(m.broadCastTime) }}
                  </td>
                  <td class="text-[14px] font-normal leading-[16.94px]">
                    {{ m.recipents }}
                  </td>
                  <td class="text-[14px] font-normal leading-[16.94px]">
                    {{ m.openRate }}
                  </td>
                  <td>
                    <span
                      :key="m.id"
                      @click.prevent="openBroadcastPreview(m)"
                      style="cursor: pointer"
                      class="text-[14px] text-[#96A0B5] font-normal leading-[16.94px]"
                      >View</span
                    >
                  </td>
                </tr>
                <tr v-if="!sentMessages.length" class="min-h-screen">
                  <td colspan="10">
                    <div class="text-center" style="padding-top: 50px">
                      <span
                        style="font-size: 4.615rem"
                        class="fa-solid fa-spinner fa-spin text-[#2463EB]"
                      ></span>
                    </div>
                    <h3
                      class="mt-1 text-[#353535] text-[16px] text-center font-medium leading-[20px]"
                      style="padding-bottom: 100px"
                    >
                      Please wait while we load your Sent Messages.
                    </h3>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Pagination
            :limit="pageSize"
            :count="pageCount"
            :page="page"
            :itemsCount="count"
            @changePage="gotoPage"
            @changeLimit="changeLimit"
          />
        </div>
        <!-- tab content for sent message stop -->

        <!-- tab content for schedule message start -->
        <div v-if="activeSubTab == 'schedule'">
          <table class="table p-0 m-0 dataTable no-footer">
            <thead class="bg-white">
              <tr>
                <th
                  width="35%"
                  class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                >
                  Message Title
                </th>
                <th
                  class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                >
                  Broadcast Date
                </th>
                <th
                  class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                >
                  Action
                </th>
                <th
                  class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                ></th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr v-for="m in scheduledMessages" :key="m.id">
                <td class="text-[14px] font-normal leading-[16.94px]">
                  {{ m.title }}
                </td>
                <td class="text-[14px] font-normal leading-[16.94px]">
                  {{ formatDate(m.broadCastTime) }}
                </td>
                <td>
                  <button
                    @click.prevent="openBroadcastPreview(m)"
                    type="button"
                    class="text-[14px] font-normal leading-[16.94px] outline-none focus:outline-none"
                  >
                    View
                  </button>
                </td>
                <td @click="dropdown(m.id)">
                  <div class="dropdown">
                    <button
                      class="btn-no-border"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span
                        class="text-[#98a2b3] dropdown text-2xl cursor-pointer relative outline-none active:outline-none shadow-none"
                      >
                        ...
                      </span>
                    </button>
                    <ul
                      class="dropdown-menu shadow-none"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <li
                        class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer border-b border-[#e5e7eb] py-[12px] px-[16px]"
                        v-if="hasPermission('Broadcast_Message_Update')"
                        @click.prevent="editNewBrod(m.id)"
                      >
                        Edit
                      </li>
                      <li
                        class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer py-[12px] px-[16px]"
                        v-if="hasPermission('Broadcast_Message_Delete')"
                        @click.prevent="deleteScheduledMessage(m.id)"
                      >
                        Delete
                      </li>
                    </ul>
                  </div>
                </td>
                <!-- <td class="text-[14px] font-normal leading-[16.94px]">
                  <button
                    class="btn btn-success btn-sm dropdown-toggle"
                    type="button"
                    id="dropdownMenu2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Actions
                  </button>
                  <div
                    class="dropdown-menu px-1"
                    aria-labelledby="dropdownMenu2"
                  >
                    <a
                      class="dropdown-item"
                      href="#"
                      @click.prevent="openBroadcastPreview(m)"
                    >
                      View
                    </a>
                    <a
                      class="dropdown-item"
                      href="#"
                      v-if="hasPermission('Broadcast_Message_Update')"
                      @click.prevent="editNewBrod(m.id)"
                    >
                      Edit
                    </a>
                    <a
                      class="dropdown-item text-danger"
                      href="#"
                      v-if="hasPermission('Broadcast_Message_Delete')"
                      @click.prevent="deleteScheduledMessage(m.id)"
                    >
                      Delete
                    </a>
                  </div>
                </td> -->
              </tr>
              <tr v-if="!scheduledMessages.length">
                <td colspan="10">
                  <div class="text-center" style="padding-top: 50px">
                    <span
                      style="font-size: 4.615rem"
                      class="mdi mdi-basket"
                    ></span>
                  </div>
                  <h3
                    class="mt-1 text-[#353535] text-[16px] text-center font-medium leading-[20px]"
                    style="padding-bottom: 50px"
                  >
                    You have not sent any scheduled message.
                  </h3>
                </td>
              </tr>
            </tbody>
          </table>
          <Pagination
            :limit="pageSizeSch"
            :count="pageCountSch"
            :page="pageSch"
            :itemsCount="countSch"
            @changePage="gotoPageSch"
            @changeLimit="changeLimitSch"
          />
        </div>
        <!-- tab content for schedule message start -->

        <!-- tab content for draft Message start -->
        <div v-if="activeSubTab == 'draft'"><BroadcastDraft /></div>
        <!-- tab content for draft Message stop -->
      </div>
    </div>
    <BroadcastPreview
      :title="bno"
      @closeModal="closeBroadcastPreview"
      :details="previewDetails"
    ></BroadcastPreview>

    <CreateBroadcast
      :isModalVisible="isCreateBroadcastVisible"
      @closeModal="closeCreateBroadcast"
      @refreshMessages="handleRefreshMessages"
    />

    <div class="modal fade" id="mod-filter" role="dialog">
      <div
        class="modal-dialog m-0 p-0 absolute top-0 right-0 flex justify-end items-end"
      >
        <div
          class="modal-content rounded-none bg-[#fff] py-[40px] px-[32px] md:min-h-screen"
        >
          <div class="modal-header- flex items-start justify-between p-0 m-0">
            <h4
              class="font-semibold text-[18px] leading-[21.6px] capitalize text-[#353535] pb-[16px]"
            >
              Filter
            </h4>
            <button
              class="text-2xl text-[#353535]"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body p-0 m-0">
            <div class="pb-[24px]">
              <label
                class="text=[#353535] font-medium text-[14px] leading-[20px]"
                >Sender:
              </label>
              <div>
                <input
                  class="bg-transparent border-[#d0d5dd] border-solid border py-[12px] px-[14px] rounded-[8px] w-full outline-none"
                  type="text"
                  v-model="filter.sender"
                  placeholder="Enter Sender Name"
                />
              </div>
            </div>

            <div class="pb-[24px]">
              <label
                class="text=[#353535] font-medium text-[14px] leading-[20px]"
                >Start Date:
              </label>
              <div>
                <input
                  class="bg-transparent border-[#d0d5dd] border-solid border py-[12px] px-[14px] rounded-[8px] w-full outline-none"
                  type="date"
                  placeholder="Select a date"
                  v-model="filter.startDate"
                />
              </div>
            </div>

            <div>
              <label
                class="text=[#353535] font-medium text-[14px] leading-[20px]"
                >End Date:
              </label>
              <div>
                <input
                  class="bg-transparent border-[#d0d5dd] border-solid border py-[12px] px-[14px] rounded-[8px] w-full outline-none"
                  type="date"
                  placeholder="Select a date"
                  v-model="filter.endDate"
                />
              </div>
            </div>
          </div>

          <div class="flex items-center gap-[8px] w-full mt-[16px]">
            <button
              class="bg-[#fcfcfd] border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[16px] sm:px-[24px] text-[14px] md:text-[16px] rounded-[8px] text-[#96A0B5] font-medium leading-[20px] w-full outline-none focus:outline-none"
              type="button"
              data-dismiss="modal"
              @click="resetFilter"
            >
              Reset
            </button>

            <button
              class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[16px] sm:px-[24px] text-[14px] md:text-[16px] rounded-[8px] text-white font-medium leading-[20px] w-full outline-none focus:outline-none"
              type="button"
              data-dismiss="modal"
              :disabled="isBusy"
              @click="fetchSentMessages"
            >
              <span v-if="!isBusy">Filter</span>
              <span v-else>Busy...</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-scheduled-filter"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div>
              <h4 class="modal-title"><b>Filter</b></h4>
            </div>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-4">Sender: </label>
              <div class="col-12 col-sm-8 ">
                <input
                  :class="['form-control']"
                  type="text"
                  v-model="scheduledFilter.sender"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-4">Start Date: </label>
              <div class="col-12 col-sm-8 ">
                <input
                  :class="['form-control']"
                  type="date"
                  placeholder="Select a date"
                  v-model="scheduledFilter.startDate"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-4">End Date: </label>
              <div class="col-12 col-sm-8 ">
                <input
                  :class="['form-control']"
                  type="date"
                  placeholder="Select a date"
                  v-model="scheduledFilter.endDate"
                />
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              style="width: 100px;"
              class="btn btn-text btn-space"
              type="button"
              data-dismiss="modal"
              @click="resetScheduledFilter"
            >
              <span class="text-danger"> Reset </span>
            </button>

            <button
              style="width: 100px;"
              class="btn btn-primary"
              type="button"
              data-dismiss="modal"
              :disabled="isBusy"
              @click="fetchScheduledMessages"
            >
              <span v-if="!isBusy">Filter</span>
              <span v-else>Busy...</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import { helper } from "@/helper";
import SystemPermissions from "@/permissions";
import Pagination from "../../components/Pagination.vue";
import BroadcastPreview from "../../components/Broadcast/BroadcastPreview.vue";
import CreateBroadcast from "../../components/Broadcast/CreateBroadcast.vue";
import BroadcastDraft from "../../components/Broadcast/BroadcastDraft.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "Enterprise-Broadcast",
  components: {
    Pagination,
    BroadcastPreview,
    CreateBroadcast,
    BroadcastDraft,
  },

  mounted: function() {
    this.fetchSentMessages();
    this.fetchScheduledMessages();
  },

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      activeSubTab: "sent",
      bno: 1,
      isCreateBroadcastVisible: false,
      showDropdown: null,
      sentMessages: [],
      scheduledMessages: [],
      activeSubTab: "sent",
      previewDetails: {
        title: "",
        sender: "",
        date: "",
        message: "",
        imagePosition: "",
        infoUrl: "",
        imageAttach: "",
      },
      filter: {
        sender: "",
        startDate: "",
        endDate: "",
      },

      scheduledFilter: {
        sender: "",
        startDate: "",
        endDate: "",
      },

      count: 0,
      page: 1,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,

      countSch: 0,
      pageSch: 1,
      pageSizeSch: 10,
      pageCountSch: 0,
      hasNextSch: false,
      hasPrevSch: false,
    };
  },
  mounted() {
    this.fetchSentMessages();
    this.fetchScheduledMessages();
  },
  methods: {
    handleRefreshMessages(tabType) {
      if (tabType === "sent") {
        this.fetchSentMessages();
      } else if (tabType === "scheduled") {
        this.fetchScheduledMessages();
      }
      //  else if (tabType === "draft") {

      // }
    },
    dropdown(userId) {
      this.showDropdown = this.showDropdown === userId ? null : userId;
    },
    switchSubTab(subTab) {
      this.activeSubTab = subTab;

      if (subTab === "sent") {
        if (!this.sentMessages.length) {
          this.fetchSentMessages();
        }
      } else if (subTab === "schedule") {
        if (!this.scheduledMessages.length) {
          this.fetchScheduledMessages();
        }
      }
      this.isCreateBroadcastVisible = false;
    },
    formatDate(date) {
      return moment(date).format("ll");
    },

    openBroadcastPreview(m) {
      this.previewDetails = {
        title: m.title,
        message: m.message,
        sender: m.sender,
        date: m.broadCastTime
          ? moment(m.broadCastTime).format("dddd, MMM DD, hh:mma")
          : moment(new Date()).format("dddd, MMM DD, hh:mma"),
        imagePosition: m.imagePosition,
        infoUrl: m.link,
        imageAttach: m.imageUrl,
      };
      $(`#mod-read-broadcast${this.bno}`).modal("show");
    },

    closeBroadcastPreview() {
      $(`#mod-read-broadcast${this.bno}`).modal("hide");
      this.previewDetails = {
        title: "",
        sender: "",
        date: "",
        message: "",
        imagePosition: "",
        infoUrl: "",
        imageAttach: "",
      };
    },

    openFilter() {
      $("#mod-filter").modal("show");
    },

    closeFilter() {
      $("#mod-filter").modal("hide");
    },

    resetFilter() {
      this.filter = {
        sender: "",
        startDate: "",
        endDate: "",
      };
      this.fetchSentMessages();
    },

    openScheduledFilter() {
      $("#mod-scheduled-filter").modal("show");
    },

    closeScheduledFilter() {
      $("#mod-scheduled-filter").modal("hide");
    },

    resetScheduledFilter() {
      this.scheduledFilter = {
        sender: "",
        startDate: "",
        endDate: "",
      };
      this.fetchScheduledMessages();
    },

    deleteScheduledMessage(id) {
      this.isBusyDeleting = true;

      this.$http
        .delete(`/BroadcastMessages/${id}`)
        .then(function(res) {
          this.isBusyDeleting = false;
          this.$toast.success(res.body, {
            icon: true,
            rtl: false,
          });
          this.fetchScheduledMessages();
        })
        .catch(function() {
          this.isBusyDeleting = false;
        });
    },

    fetchSentMessages() {
      this.sentMessages = [];
      this.isBusy = true;
      this.$http
        .get(
          `/BroadcastMessages?Status=1&PageSize=${this.pageSize}&PageNumber=${this.page}&Sender=${this.filter.sender}&StartDate=${this.filter.startDate}&EndDate=${this.filter.endDate}`
        )
        .then(function(res) {
          this.closeFilter();
          this.sentMessages = res.body.data;
          this.page = res.body.pageNumber;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function() {
          this.isBusy = false;
        });
    },

    fetchScheduledMessages() {
      this.scheduledMessages = [];
      this.isBusy = true;
      this.$http
        .get(
          `/BroadcastMessages?Status=2&PageSize=${this.pageSizeSch}&PageNumber=${this.pageSch}&Sender=${this.scheduledFilter.sender}&StartDate=${this.scheduledFilter.startDate}&EndDate=${this.scheduledFilter.endDate}`
        )
        .then(function(res) {
          this.closeScheduledFilter();
          this.scheduledMessages = res.body.data;
          this.pageSch = res.body.pageNumber;
          this.pageSizeSch = res.body.pageSize;
          this.countSch = res.body.count;
          this.pageCountSch = Math.ceil(res.body.count / res.body.pageSize);
          this.hasPrevSch = res.body.hasPrev;
          this.hasNextSch = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function() {
          this.isBusy = false;
        });
    },

    createNewBrod() {
      this.isCreateBroadcastVisible = true;
    },

    closeCreateBroadcast() {
      this.isCreateBroadcastVisible = false;
    },
    editNewBrod(id) {
      if (id) this.$router.push(`/manufacturer-broadcast-new/${id}`);
    },

    prev() {
      this.page -= 1;
      this.fetchSentMessages();
    },
    next() {
      this.page += 1;
      this.fetchSentMessages();
    },

    gotoPage(page) {
      this.page = page;
      this.fetchSentMessages();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.fetchSentMessages();
    },

    prevSch() {
      this.pageSch -= 1;
      this.fetchScheduledMessages();
    },
    nextSch() {
      this.pageSch += 1;
      this.fetchScheduledMessages();
    },

    gotoPageSch(page) {
      this.pageSch = page;
      this.fetchScheduledMessages();
    },

    changeLimitSch(limit) {
      this.pageSizeSch = limit;
      this.fetchScheduledMessages();
    },
  },
};
</script>
