<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-upload-details"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <form @submit.prevent="onSubmitClick" class="modal-content">
        <div class="modal-header bg-success">
          <h4 class="modal-title p-1">
            <strong class="ml-1">Add Payment Details</strong>
          </h4>
          <button
            type="button"
            class="close md-close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h5>Add Scanned File</h5>
          <p class="mb-1">
            (Document must be PDF or JPEG, and no more than 5mb)
          </p>
          <div class="form-row">
            <div class="form-group col-6">
              <div class="col-12">
                <input
                  class="form-control"
                  :class="{
                    'is-invalid': $v.title.$error,
                  }"
                  type="tet=xt"
                  placeholder="Enter the file name"
                  v-model.trim="title"
                  @input="setTitle($event.target.value)"
                />
                <div class="invalid-feedback" v-if="!$v.title.minLength">
                  Title must be greater than
                  {{ $v.title.$params.minLength.min - 1 }}.
                </div>
              </div>
            </div>

            <div class="input-group col-6">
              <div class="custom-file">
                <input
                  type="file"
                  class="custom-file-input"
                  id="inputGroupFile01"
                  accept="image/jpeg,image/pdf"
                  aria-describedby="inputGroupFileAddon01"
                  value="file"
                  @change="onFileSelected"
                />
                <label class="custom-file-label" for="inputGroupFile01">
                  {{ file ? file.name.substring(0, 20) : "Choose file" }}</label
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal-footer d-flex justify-content-center align-items-center"
        >
          <button
            style="width: 100px"
            class="btn btn-danger"
            type="button"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button :disabled="isBusyModal" class="btn btn-success" type="submit">
            <span v-if="isBusyModal">Uploading...</span>
            <span v-else>Submit</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { minLength, required } from "vuelidate/lib/validators";
export default {
  name: "AddPaymentDetailsModal",
  props: ["id"],

  data() {
    return {
      isBusyModal: false,
      title: "",
      file: null,
    };
  },

  validations: {
    title: {
      required,
      minLength: minLength(1),
    },

    file: {
      required,
    },
  },

  methods: {
    setTitle(value) {
      this.title = value;
      this.$v.title.$touch();
    },

    onFileSelected(event) {
      this.file = event.target.files[0];
    },

    async onSubmitClick() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isBusyModal = true;
        try {
          const data = new FormData();
          data.append("imageFile", this.file, this.file.name);
          data.append("purchaseOrderId", this.id);
          data.append("title", this.title);

          const response = await this.$http.post(
            "/PurchaseOrders/paymentDetail",
            data
          );

          if (response.ok) this.$emit("uploadComplete");
          this.isBusyModal = false;
        } catch (error) {
          this.isBusyModal = false;
        }
      }
    },
  },
};
</script>

<style>
</style>