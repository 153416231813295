<template>
  <div class="modal fade" id="mod-add-price-option" role="dialog">
    <div class="modal-dialog mx-auto">
      <form class="modal-content p-[24px] bg-[#fff]">
        <div class="flex items-start justify-between p-0 m-0">
          <h4
            class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
          >
            Add Price Option To Group
          </h4>
          <button
            class="text-2xl text-[#353535] outline-none focus:outline-none"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body m-0 p-0">
          <div class="mb-[16px]">
            <label class="text=[#353535] font-medium text-[14px] leading-[20px]"
              >Search for product</label
            >
            <div>
              <multiselect
                v-model="value"
                label="name"
                track-by="name"
                :preserve-search="true"
                :options="products"
                @select="onSelect"
              ></multiselect>
            </div>
          </div>
          <div class="pb-[16px]">
            <label class="text=[#353535] font-medium text-[14px] leading-[20px]"
              >Choose product price option to apply for group</label
            >
            <div v-if="productId && !isBusyModal">
              <select
                v-model="selectedOption"
                :disabled="options.length <= 0"
                class="bg-transparent border-[#d0d5dd] border-solid border py-[12px] px-[14px] rounded-[8px] w-full outline-none"
              >
                <option value="">Select option</option>
                <option
                  v-for="option in options"
                  :key="option.id"
                  :value="option.id"
                >
                  {{ option.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="flex items-center gap-[8px] mt-[16px]">
          <button
            class="border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[16px] sm:px-[24px] text-[14px] md:text-[16px] rounded-[8px] font-medium leading-[20px] w-[45%] sm:w-full"
            type="button"
            data-dismiss="modal"
            @click="reset"
          >
            Cancel
          </button>
          <button
            :disabled="isBusyModal || options.length <= 0"
            class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[16px] sm:px-[24px] text-[14px] md:text-[16px] rounded-[8px] text-white font-medium leading-[20px] w-[55%] sm:w-full"
            @click.prevent="submit"
            type="submit"
          >
            <span
              v-if="isBusyModal"
              class="fa-solid fa-spinner fa-spin"
              role="status"
            >
              <i class="sr-only">Loading...</i>
            </span>
            <span class="text-[14px] md:text-[16px]" v-else>
              Add Price Option
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  name: "AddPriceOption",
  components: { Multiselect },

  props: ["groupId"],

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      value: "",
      products: [],
      options: [],
      selectedOption: "",
      productId: "",
      isOptionApplied: false,
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  mounted() {
    this.getProducts();
  },

  methods: {
    async getProducts() {
      this.isBusy = true;
      this.isBusyModal = true;
      try {
        const response = await this.$http.get(
          "/products/getListNoPagingForManufacturer?parentId=" +
            this.user.parentId +
            "&active=true"
        );

        if (response.ok)
          this.products = response.body.sort(function(x, y) {
            let a = x.name.toUpperCase(),
              b = y.name.toUpperCase();
            return a == b ? 0 : a > b ? 1 : -1;
          });

        this.isBusy = false;
        this.isBusyModal = false;
      } catch (error) {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    async getOptions() {
      this.isBusy = true;
      this.isBusyModal = true;
      this.options = [];

      try {
        const response = await this.$http.get(
          "/PriceOptions/getListByProductId/" +
            this.productId +
            "/" +
            this.groupId
        );

        if (response.ok) {
          this.options = response.body.sort(function(x, y) {
            let a = x.name.toUpperCase(),
              b = y.name.toUpperCase();
            return a == b ? 0 : a > b ? 1 : -1;
          });
          this.checkExist();
        }

        this.isBusy = false;
        this.isBusyModal = false;
      } catch (error) {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    checkExist() {
      this.options.forEach((element) => {
        if (element.isApplied) {
          this.isOptionApplied = true;
        }
      });
    },

    onSelect(option) {
      this.productId = option.id;
      this.getOptions();
    },

    async submit() {
      this.isBusy = true;
      this.isBusyModal = true;

      try {
        const data = {
          groupId: this.groupId,
          optionId: this.selectedOption,
          productId: this.productId,
        };

        const response = await this.$http.post("/Groups/addPriceOption", data);
        if (response.ok) {
          this.$toast.success(
            this.isOptionApplied
              ? "Price option updated!"
              : "Price option added!",
            {
              icon: false,
              rtl: false,
            }
          );
          this.reset();
          this.$emit("onComplete");
        }

        this.isBusy = false;
        this.isBusyModal = false;
      } catch (error) {
        this.isBusy = false;
        this.isBusyModal = false;
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },

    reset() {
      this.options = [];
      this.selectedOption = "";
      this.productId = "";
      this.value = "";
      this.isOptionApplied = false;
    },
  },
};
</script>
