<template>
  <div class="modal fade" id="new-distributor" role="dialog">
    <div
      class="modal-dialog modal-lg m-0 p-0 absolute top-0 right-0 flex justify-end items-end"
    >
      <div
        class="modal-content rounded-none bg-[#FCFCFD] py-[40px] px-[20px] min-h-screen"
      >
        <div class="flex items-start justify-between p-0 m-0">
          <h4
            class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[24px]"
          >
            New Distributor
          </h4>
          <button
            class="text-2xl text-[#353535] outline-none focus:outline-none"
            type="button"
            @click="Close"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body p-0 m-0">
          <div class="pb-[16px]">
            <label class="text=[#353535] font-bold text-[14px] leading-[20px]"
              >Search for distributor</label
            >
            <div>
              <input
                type="text"
                v-model="customer_code"
                @input="resetErrMsg"
                class="border-[#d0d5dd] bg-transparent border-solid border-[1.5px] p-[14px] rounded-[8px] w-full outline-none"
                placeholder="Enter customer code here"
              />
            </div>
          </div>

          <div
            v-for="(i, index) in results"
            :key="index"
            class="w-full flex pb-[24px] justify-between items-center"
          >
            <p class="text-[14px] font-normal text-[#353535] leading-[24px]">
              {{ i.businessName }}
            </p>
            <i
              @click="ShowConfirmAddModal(i, index)"
              class="fa-regular cursor-pointer rounded-full hover:shadow-md font-bold text-[14px] text-[#353535] fa-plus border-[1.5px] border-[#353535] p-[4px]"
            ></i>
          </div>
          <div class="flex items-center gap-[8px] max-w-[500px] w-full">
            <button
              class="bg-transparent border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[16px] sm:px-[24px] text-[14px] md:text-[16px] rounded-[8px] font-medium leading-[20px] w-full outline-none focus:outline-none"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>

            <button
              @click="Search"
              :class="{ 'bg-[#75a0ff]': !customer_code }"
              class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[16px] sm:px-[24px] text-[14px] md:text-[16px] rounded-[8px] text-white font-medium leading-[20px] w-full outline-none focus:outline-none disabled:cursor-not-allowed"
              type="button"
              :disabled="!customer_code"
            >
              <i v-if="isSearching" class="fas fa-spinner fa-spin mr-2"></i>

              {{ isBusy ? "Busy..." : "Search" }}
            </button>
          </div>
          <div
            v-if="errorMessage.status"
            class="text-[#353535] text-center leading-[24px] text-[14px] font-normal my-[16px]"
          >
            {{ errorMessage.message }}
          </div>
          <!-- list of added distributors  to add-->
          <div v-if="addedDistributors.length > 0" class="w-full mt-[40px]">
            <div>
              <p
                class="text-[14px] font-semibold text-[#353535] leading-[24px]"
              >
                List Of Distributors To Add
              </p>
            </div>
            <div class="mt-6 ring-1 w-full ring-gray-300">
              <table class="w-full table h-auto">
                <thead class="lg:table-header-group border-b-2 border-solid">
                  <tr class="bg-[#fbfbfb] w-full">
                    <td class="px-6 py-[12px]">
                      <span
                        class="text-[14px] font-medium leading-[24px] text-[#353535]"
                      >
                        Distributor Name</span
                      >
                    </td>
                  </tr>
                </thead>

                <tbody class="w-full">
                  <tr
                    class="w-full"
                    v-for="(i, index) in addedDistributors"
                    :key="index"
                  >
                    <td class="py-[12px] w-full whitespace-nowrap border-b">
                      <span
                        class="text-[14px] font-medium leading-[24px] text-[#353535]"
                        >{{ i.businessName }}</span
                      >
                    </td>

                    <!-- <td class="px-6 py-2 text-sm font-medium lg:table-cell">
                      <button
                        @click="removeItem(index)"
                        class="h-4 p-[10px] flex block justify-center items-center rounded-full w-4 bg-red-400 text-white hover:shadow-md"
                      >
                        <i class="fa-solid fa-trash"></i>
                      </button>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="flex mt-[16px] items-center gap-[8px] w-full">
              <button
                @click="inviteDistributors"
                class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[16px] sm:px-[24px] text-[14px] md:text-[16px] rounded-[8px] text-white font-medium leading-[20px]  w-[50%] md:w-[30%]  outline-none focus:outline-none"
                type="button"
              >
                <i v-if="isInviting" class="fas fa-spinner fa-spin mr-2"></i>

                {{
                  isBusy
                    ? "Busy..."
                    : `Invite ${addedDistributors.length > 1 ? "All" : ""}`
                }}
              </button>
            </div>
          </div>

          <div v-if="allDistributors.length > 0" class="w-full mt-[40px]">
            <div>
              <p
                class="text-[14px] font-semibold text-[#353535] leading-[24px]"
              >
                List Of Added Distributors
              </p>
            </div>
            <div class="mt-6 ring-1 w-full ring-gray-300">
              <table class="w-full">
                <thead class="lg:table-header-group border-b-2 border-solid">
                  <tr class="bg-[#fbfbfb] w-full">
                    <td class="px-6 py-[12px]">
                      <span
                        class="text-[14px] font-medium leading-[24px] text-[#353535]"
                      >
                        Distributor Name</span
                      >
                    </td>
                  </tr>
                </thead>

                <tbody class="divide-y divide-gray-200">
                  <tr v-for="(i, index) in allDistributors" :key="index">
                    <td
                      class="py-[12px] whitespace-nowrap flex items-center justify-between border-b px-6"
                    >
                      <span
                        class="text-[14px] font-medium leading-[24px] text-[#353535]"
                        >{{
                          i.businessName ? i.businessName : i.customerCode
                        }}</span
                      >
                      <div
                        class="flex items-center gap-2"
                        :class="{
                          'bg-[rgba(0, 216, 162, 0.1)]':
                            i.isSuccessful && i.isRegistered,
                          'bg-[rgba(239, 82, 83, 0.1)]': !i.isSuccessful,
                          'bg-[rgba(255, 150, 0, 0.1)]':
                            i.isSuccessful && !i.isRegistered,
                        }"
                      >
                        <div
                          class="h-3 w-3 rounded-[2px]"
                          :style="{
                            backgroundColor:
                              i.isSuccessful && i.isRegistered
                                ? '#16a249'
                                : !i.isSuccessful
                                ? '#EF5253'
                                : '#FD881E',
                          }"
                        ></div>
                        <span
                          class="text-[12px] font-normal leading-[16.94px]"
                          :class="{
                            'text-[#16a249]': i.isSuccessful && i.isRegistered,
                            'text-[#EF5253]': !i.isSuccessful,
                            'text-[#FD881E]': i.isSuccessful && !i.isRegistered,
                          }"
                        >
                          {{
                            i.isSuccessful && i.isRegistered
                              ? "Added"
                              : !i.isSuccessful
                              ? "Failed"
                              : "Pending Invite"
                          }}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button
              @click="Close"
              class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[16px] sm:px-[24px] text-[14px] md:text-[16px] rounded-[8px] text-white font-medium leading-[20px]  w-[50%] md:w-[30%]  mt-[16px]"
              type="button"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "ShowConfirmAddModal",
    "SearchCustomer",
    "isBusy",
    "isSearching",
    "isInviting",
    "allDistributors",
    "addedDistributors",
    "distributors",
    "results",
    "inviteDistributors",
    "removeItem",
    "ClolseAddModal",
    "errorMessage",
    "resetErrMsg",
  ],
  data: () => ({
    customer_code: "",
  }),
  methods: {
    async Search() {
      await this.SearchCustomer(this.customer_code);
    },
    Close() {
      this.customer_code = "";
      this.ClolseAddModal();
    },
  },
  computed: {},
};
</script>

<style scoped>
td,
tr {
  color: black !important;
}
</style>
