<template>
  <div class="bg-white px-[24px] py-[32px] mt-[16px] rounded-[4px]">
    <h1 class="text-[16px] font-normal leading-[19.36px] text-[#353535]">
      All Activities
    </h1>
    <div class="row">
      <div class="col-sm-12">
        <div>
          <span
            v-if="isActivityLoading"
            class="spinner-border text-success spinner-border-sm"
            role="status"
          >
            <i class="sr-only">Loading...</i>
          </span>
          <div v-else class="space-y-4 mt-4">
            <div
              v-for="(activity, index) in activities"
              :key="activity.id"
              class="flex items-start relative"
            >
              <div class="mr-3 relative">
                <div
                  class="w-2 h-2 rounded-full bg-blue-500 z-10 relative mt-2"
                ></div>
                <div
                  v-if="index < activities.length - 1"
                  class="absolute top-2 left-1 w-px h-full bg-blue-200"
                ></div>
              </div>
              <p class="text-sm text-gray-600 pb-4">
                {{ activity.performedBy }} {{ activity.actionName }} on
                {{
                  activity.actionName.toLowerCase().includes("stage")
                    ? formatDate(activity.actionDate)
                    : formatDate(activity.date)
                }}.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["purchaseId"],
  data() {
    return {
      isActivityLoading: false,
      activities: [],
      orderId: "",
    };
  },
  mounted() {
    this.orderId = this.purchaseId;
    console.log(this.orderId);
  },
  methods: {
    async getActivies() {
      this.isActivityLoading = true;
      try {
        const response = await this.$http.get(`/Activity/${this.orderId}/${1}`);
        console.log(response);
        if (response.ok) {
          this.activities = response.body;
        }
        this.isActivityLoading = false;
      } catch (error) {
        this.isActivityLoading = false;
      }
    },
  },
};
</script>
