<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-add-onepipe-account"
    data-backdrop="static"
    data-keyboard="false"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1">
            <strong>Create GrowTrade Account</strong>
          </h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
            @click="cancel"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right">Phone Number</label>
            <div class="col-12 col-sm-8 col-lg-8">
              <input
                :class="[
                  'form-control',
                  { 'is-invalid': v1.phoneNumber },
                  { 'is-invalid': v1.phoneNumberValid },
                ]"
                type="text"
                v-model.trim="phoneNumber"
                placeholder="Enter number linked with BVN"
              />
              <ul v-if="v1.phoneNumber" class="parsley-errors-list filled">
                <li class="parsley-required">Phone number is required.</li>
              </ul>
              <ul v-if="v1.phoneNumberValid" class="parsley-errors-list filled">
                <li class="parsley-required">Enter a valid phone number.</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-primary"
            @click.prevent="createAccount"
            type="button"
            style="width: 130px"
          >
            <span v-if="isPosting" class="spinner-border spinner-border-sm" role="status">
              <i class="sr-only">Loading...</i>
            </span>
            <span v-else> Create Account </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { helper } from "@/helper";
import datePicker from "vue-bootstrap-datetimepicker";
export default {
  name: "add-bank-account",
  // props: ["value", "isEdit", "editDetails"],
  components: { datePicker },

  data() {
    return {
      isPosting: false,
      phoneNumber: "",
      v1: {
        phoneNumber: false,
        phoneNumberValid: false,
      },

      options: {
        useCurrent: true,
        format: "YYYY-MM-DD",
      },
    };
  },

  validations: {
    payment: {
      customerId: { required },
      paymentChannelId: { required },
      amount: { required },
      paymentDate: { required },
    },
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  async mounted() {
    this.v1 = {
      phoneNumber: false,
      phoneNumberValid: false,
    };
  },

  methods: {
    formatMoney(val) {
      return helper.formatMoney(val);
    },

    cancel() {
      this.v1 = {
        phoneNumber: false,
        phoneNumberValid: false,
      };

      this.phoneNumber = "";
      this.$emit("closeModal");
      this.$router.push("/dashboard");
    },

    validatePhoneNumber() {
      this.v1 = {
        phoneNumber: false,
        phoneNumberValid: false,
      };
      var isValid = true;

      if (!this.phoneNumber) {
        this.v1.phoneNumber = true;
        isValid = false;
      }

      if (this.phoneNumber && !helper.validatePhone(this.phoneNumber)) {
        this.v1.phoneNumberValid = true;
        isValid = false;
      }

      return isValid;
    },

    getUser() {
      this.$http
        .get("/Users/getCurrent")
        .then(function(res) {
          this.$store.commit("setCurrentUser", res.body);
          // this.loaded = true;
        })
        .catch(function() {
          this.isPosting = false;
          // this.loaded = false;
        });
    },

    async createAccount() {
      this.isPosting = true;
      if (this.validatePhoneNumber()) {
        await this.$http
          .post(`/Users/bankaccount/${this.phoneNumber}`)
          .then(function(res) {
            // console.log(res);
            this.$toast.success("Account created successfully", {
              icon: true,
              rtl: false,
            });
            this.getUser();
            this.isPosting = false;
            this.cancel();
          })
          .catch(function() {
            this.isPosting = false;
          });
      } else {
        this.isPosting = false;
      }
    },
  },
};
</script>

<style scoped>
.text-disabled {
  cursor: not-allowed;
  background-color: #f5f5f0;
}
</style>
