<template>
  <div
    class="modal fade colored-header colored-header-primary z-[111114]"
    :id="`mod-read-broadcast${title}`"
    role="dialog"
  >
    <div
      class="modal-dialog m-0 p-0 absolute top-0 right-0 flex justify-end items-end"
      role="document"
    >
      <div
        class="modal-content rounded-none bg-[#f8f8f8] p-[40px] min-h-screen"
      >
        <div class="modal-header flex items-start justify-between p-0 m-0">
          <h4
            class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
          >
            Message Preview
          </h4>
          <button
            class="text-2xl text-[#353535]"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body p-0 m-0">
          <div class="pb-[16px]">
            <span class="font-normal text-[14px] leading-[24px] text-[#96A0B5]"
              >{{ details.sender }} - {{ details.date }}</span
            >
          </div>
          <div class="pb-[16px]">
            <h4 class="text-[#353535] text-[14px] fomt-semibold leading-[24px]">
              <b>{{ details.title }}</b>
            </h4>
          </div>
          <div v-if="details.imagePosition === 'top'" class="pb-[16px]">
            <img
              alt="attachment"
              class="logoImage w-full h-auto"
              :src="`${details.imageAttach}`"
            />
          </div>
          <div
            class="text-[#96A0B5] text-[14px] font-normal leading-[24px] pb-[16px]"
          >
            <p>{{ details.message }}</p>
          </div>
          <div v-if="details.imagePosition === 'bottom'" class="pb-[16px]">
            <img
              alt="attachment"
              class="logoImage w-full h-auto"
              :src="`${details.imageAttach}`"
            />
          </div>
          <div v-if="details.infoUrl" class="pb-[16px]">
            <p>
              <span class="font-medium text-[#353535]">Useful link: </span
              ><a :href="details.infoUrl">{{ details.infoUrl }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "view-broadcast",
  props: ["details", "title"],
  data() {
    return {
      isPosting: false,
    };
  },

  methods: {
    closeDialog() {
      this.$emit("closeModal");
    },
  },
};
</script>
