<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-border-color card-border-color-primary">
          <div class="card-header">
            <router-link
              class="btn btn-space btn-success btn-rounded float-right"
              :to="'/manufacturers/' + account.id"
            >
              <span class="icon icon-left mdi mdi-arrow-left text-white"></span> Back
            </router-link>
          </div>
          <div class="card-body">
            <form>
              <h4>Business profile</h4>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right">Business Name</label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <input
                    :class="['form-control', { 'is-invalid':v1.name }]"
                    type="text"
                    v-model="account.name"
                  >
                  <ul v-if="v1.name" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right">Business Description</label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <textarea
                    :class="['form-control', { 'is-invalid':v1.description }]"
                    v-model="account.description"
                  ></textarea>
                  <ul v-if="v1.description" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right">Business Registration</label>
                <div class="col-12 col-sm-4 col-lg-3">
                  <input
                    :class="['form-control', { 'is-invalid':v1.rcNumber }]"
                    type="text"
                    v-model="account.rcNumber"
                    placeholder="RC Number"
                  >
                  <ul v-if="v1.rcNumber" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
                <div class="col-12 col-sm-4 col-lg-3">
                  <input
                    class="form-control"
                    type="text"
                    v-model="account.tin"
                    placeholder="Tax Identification Number"
                  >
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right">Website</label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <input
                    :class="['form-control', { 'is-invalid':v1.websiteValid }]"
                    type="text"
                    v-model="account.website"
                  >
                  <ul v-if="v1.websiteValid" class="parsley-errors-list filled">
                    <li class="parsley-required">Enter a valid url.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right">Business Email Address</label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <input
                    :class="['form-control', { 'is-invalid':v1.emailAddress }, { 'is-invalid':v1.emailAddressValid }]"
                    type="email"
                    v-model="account.emailAddress"
                  >
                  <ul v-if="v1.emailAddress" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                  <ul v-if="v1.emailAddressValid" class="parsley-errors-list filled">
                    <li class="parsley-required">Enter a valid email address.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right">Business Phone Number</label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <input
                    :class="['form-control', { 'is-invalid':v1.phoneNumber }, { 'is-invalid':v1.phoneNumberValid }]"
                    type="text"
                    v-model="account.phoneNumber"
                  >
                  <ul v-if="v1.phoneNumber" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                  <ul v-if="v1.phoneNumberValid" class="parsley-errors-list filled">
                    <li class="parsley-required">Enter a valid phone number.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right">Business Address</label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <select class="form-control" placeholder="State" v-model="account.state">
                    <option v-for="(s, i) in states" :key="i" :value="s">{{ s }}</option>
                  </select>
                  <ul v-if="v1.state" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <select
                    placeholder="Local Government Area"
                    v-model="account.lga"
                    :class="['form-control', { 'is-invalid':v1.lga }]"
                  >
                    <option v-for="(l, i) in lgas" :key="i" :value="l">{{ l }}</option>
                  </select>
                  <ul v-if="v1.lga" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <input
                    :class="['form-control', { 'is-invalid':v1.city }]"
                    type="text"
                    v-model="account.city"
                    placeholder="City"
                  >
                  <ul v-if="v1.city" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <input
                    :class="['form-control', { 'is-invalid':v1.address1 }]"
                    type="text"
                    v-model="account.address1"
                    placeholder="Address Line 1"
                  >
                  <ul v-if="v1.address1" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <input
                    class="form-control"
                    type="text"
                    v-model="account.address2"
                    placeholder="Address Line 2"
                  >
                </div>
              </div>

              <hr>

              <h4>Contact information</h4>

              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right">Contact Person's Name</label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <input
                    :class="['form-control', { 'is-invalid':v2.name }]"
                    type="text"
                    v-model="contact.name"
                  >
                  <ul v-if="v2.name" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right">Email Address</label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <input
                    :class="['form-control', { 'is-invalid':v2.emailAddress }, { 'is-invalid':v2.emailAddressValid }]"
                    type="email"
                    v-model="contact.emailAddress"
                  >
                  <ul v-if="v2.emailAddress" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                  <ul v-if="v2.emailAddressValid" class="parsley-errors-list filled">
                    <li class="parsley-required">Enter a valid email address.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right">Phone Number</label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <input
                    :class="['form-control', { 'is-invalid':v2.phoneNumber }, { 'is-invalid':v2.phoneNumberValid }]"
                    type="text"
                    v-model="contact.phoneNumber"
                  >
                  <ul v-if="v2.phoneNumber" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                  <ul v-if="v2.phoneNumberValid" class="parsley-errors-list filled">
                    <li class="parsley-required">Enter a valid phone number.</li>
                  </ul>
                </div>
              </div>

              <hr>
              <h4>Bank information</h4>

              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right">Bank</label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <select class="form-control" placeholder="Select an option" v-model="bank.name">
                    <option v-for="b in banks" :key="b" :value="b">{{ b }}</option>
                  </select>
                  <ul v-if="v3.name" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right">Account Number</label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <input
                    :class="['form-control', { 'is-invalid':v3.accountNumber }, { 'is-invalid':v3.accountNumberValid }]"
                    type="text"
                    v-model="bank.accountNumber"
                  >
                  <ul v-if="v3.accountNumber" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                  <ul v-if="v3.accountNumberValid" class="parsley-errors-list filled">
                    <li class="parsley-required">Enter a valid account number.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <button
                    :disabled="isBusy"
                    style="width: 150px;"
                    class="btn btn-space btn-success btn-lg"
                    @click.prevent="update"
                  >
                    <span v-if="isBusy">Busy...</span>
                    <span v-else>Save changes</span>
                  </button>

                  <router-link
                    class="btn btn-space btn-secondary btn-lg"
                    :to="'/manufacturers/' + account.id"
                  >Cancel</router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { helper } from "@/helper";
import Select2 from "@/components/Select2";

export default {
  name: "manufacturerEdit",
  components: {
    Select2
  },
  data() {
    return {
      isBusy: false,
      account: {},
      contact: {},
      bank: {},
      banks: helper.getBanks(),
      states: helper.getStates(),
      v1: {
        rcNumber: false,
        name: false,
        description: false,
        emailAddress: false,
        emailAddressValid: false,
        websiteValid: false,
        phoneNumber: false,
        phoneNumberValid: false,
        address1: false,
        city: false,
        lga: false,
        state: false
      },
      v2: {
        name: false,
        emailAddress: false,
        emailAddressValid: false,
        phoneNumber: false,
        phoneNumberValid: false
      },
      v3: {
        name: false,
        accountNumber: false,
        accountNumberValid: false
      }
    };
  },
  created() {
    this.getAccount();
  },
  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
    lgas: function() {
      return helper.getLgas(this.account.state);
    }
  },
  methods: {
    getAccount() {
      var id = this.$route.params.id;

      this.$http
        .get("/Accounts/getById/" + id)
        .then(function(res) {
          this.account = {
            id: res.body.id,
            name: res.body.name,
            description: res.body.description,
            emailAddress: res.body.emailAddress,
            phoneNumber: res.body.phoneNumber,
            website: res.body.website,
            address1: res.body.address1,
            address2: res.body.address2,
            city: res.body.city,
            state: res.body.state,
            rcNumber: res.body.rcNumber,
            tin: res.body.tin
          };

          this.contact = {
            id: res.body.id,
            name: res.body.contact.name,
            emailAddress: res.body.contact.emailAddress,
            phoneNumber: res.body.contact.phoneNumber
          };

          this.bank = {
            id: res.body.id,
            name: res.body.bank.name,
            accountNumber: res.body.bank.accountNumber
          };

          this.account.lga = res.body.lga;

          // console.log(res.body.lga);
        })
        .catch(function() {});
    },
    update() {
      this.isBusy = true;

      if (this.validateUpdate()) {
        var data = {
          id: this.account.id,
          name: this.account.name,
          description: this.account.description,
          emailAddress: this.account.emailAddress,
          phoneNumber: this.account.phoneNumber,
          website: this.account.website,
          address1: this.account.address1,
          address2: this.account.address2,
          city: this.account.city,
          lga: this.account.lga,
          state: this.account.state,
          rcNumber: this.account.rcNumber,
          tin: this.account.tin,
          bank: this.bank,
          contact: this.contact
        };

        this.$http
          .put("/Accounts/updateAlt/" + data.id, data)
          .then(function(res) {
            this.$router.push("/manufacturers/" + data.id);
          })
          .catch(function() {
            this.isBusy = false;
          });
      } else {
        this.isBusy = false;
      }
    },
    validateUpdate() {
      this.v1 = {
        rcNumber: false,
        name: false,
        description: false,
        emailAddress: false,
        emailAddressValid: false,
        websiteValid: false,
        phoneNumber: false,
        phoneNumberValid: false,
        address1: false,
        city: false,
        lga: false,
        state: false
      };
      var isValid = true;

      if (!this.account.rcNumber) {
        this.v1.rcNumber = true;
        isValid = false;
      }

      if (!this.account.name) {
        this.v1.name = true;
        isValid = false;
      }

      if (!this.account.description) {
        this.v1.description = true;
        isValid = false;
      }

      if (this.account.website && !helper.validateUrl(this.account.website)) {
        this.v1.websiteValid = true;
        isValid = false;
      }

      if (!this.account.emailAddress) {
        this.v1.emailAddress = true;
        isValid = false;
      }

      if (
        this.account.emailAddress &&
        !helper.validateEmail(this.account.emailAddress)
      ) {
        this.v1.emailAddressValid = true;
        isValid = false;
      }

      if (!this.account.phoneNumber) {
        this.v1.phoneNumber = true;
        isValid = false;
      }

      if (
        this.account.phoneNumber &&
        !helper.validatePhone(this.account.phoneNumber)
      ) {
        this.v1.phoneNumberValid = true;
        isValid = false;
      }

      if (!this.account.address1) {
        this.v1.address1 = true;
        isValid = false;
      }

      if (!this.account.city) {
        this.v1.city = true;
        isValid = false;
      }

      if (!this.account.lga) {
        this.v1.lga = true;
        isValid = false;
      }

      if (!this.account.state) {
        this.v1.state = true;
        isValid = false;
      }

      this.v2 = {
        name: false,
        emailAddress: false,
        emailAddressValid: false,
        phoneNumber: false,
        phoneNumberValid: false
      };

      if (!this.contact.name) {
        this.v2.name = true;
        isValid = false;
      }

      if (!this.contact.emailAddress) {
        this.v2.emailAddress = true;
        isValid = false;
      }

      if (
        this.contact.emailAddress &&
        !helper.validateEmail(this.contact.emailAddress)
      ) {
        this.v2.emailAddressValid = true;
        isValid = false;
      }

      if (!this.contact.phoneNumber) {
        this.v2.phoneNumber = true;
        isValid = false;
      }

      if (
        this.contact.phoneNumber &&
        !helper.validatePhone(this.contact.phoneNumber)
      ) {
        this.v2.phoneNumberValid = true;
        isValid = false;
      }

      this.v3 = {
        name: false,
        accountNumber: false
      };

      if (!this.bank.name) {
        this.v3.name = true;
        isValid = false;
      }

      if (!this.bank.accountNumber) {
        this.v3.accountNumber = true;
        isValid = false;
      }

      if (
        this.bank.accountNumber &&
        !helper.validateNuban(this.bank.accountNumber)
      ) {
        this.v3.accountNumberValid = true;
        isValid = false;
      }

      return isValid;
    }
  }
};
</script>
