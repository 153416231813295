<template>
  <div class="row min-h-screen shadow-none">
    <div class="col-lg-12">
      <div :class="[, 'be-loading', { 'be-loading-active': isBusy }]">
        <!-- {{ categories }} -->
        <ProductsHeader
          :activeTab="activeTab"
          @openCreateModal="openCreateModal"
          @openConfirmDownload="openConfirmDownload"
          @tabSwitched="switchTab"
          @viewFilter="viewFilter"
          :hasPermission="hasPermission"
        />
      </div>
      <div v-if="activeTab === 'product'">
        <ProductsTable
          :products="Products"
          :pageSize="pageSize"
          :pageCount="pageCount"
          @viewEdit="viewEdit"
          :page="page"
          :count="count"
          :user="user"
          :hasPermission="hasPermission"
          @gotoPage="gotoPage"
          @changeLimit="changeLimit"
        />
      </div>
      <div v-if="activeTab === 'category'">
        <ProductCategories />
      </div>
      <div v-if="activeTab === 'units'">
        <ProductUnits />
      </div>
    </div>

    <!-- add product modal -->
    <!-- <div
      class="modal fade colored-header colored-header-primary"
      id="mod-add"
      role="dialog"
    >
      <div
        class="modal-dialog m-0 p-0 absolute top-0 right-0 flex justify-end items-end"
      >
        <div
          class="modal-content rounded-none bg-[#f8f8f8] p-[20px] md:p-[40px] min-h-screen"
        >
          <div
            class="modal-header modal-header-colored  flex items-start justify-between p-0 m-0"
          >
            <h4
              class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
            >
              Create new products
            </h4>
            <button
              class="text-2xl text-[#353535]"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body p-0 m-0">
            <div class="pb-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Category</label
              >
              <div>
                <select
                  class="bg-[#fff] border-[#d0d5dd] border-solid border py-[12px] px-[14px] rounded-[8px] w-full"
                  placeholder="Select product category"
                  v-model="createProduct.categoryId"
                  ><option disabled value="">
                    Select product category
                  </option>
                  <option
                    v-for="cat in filteredCategory"
                    :key="cat.id"
                    :value="cat.id"
                  >
                    {{ cat.name }}
                  </option>
                </select>
                <ul v-if="v1.categoryId" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="mb-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Code</label
              >
              <div class="">
                <input
                  :class="[{ 'is-invalid': v1.code }]"
                  class="bg-[#fff] border-[#d0d5dd] border-solid border py-[12px] px-[14px] rounded-[8px] w-full"
                  type="text"
                  placeholder="Enter product code"
                  v-model="createProduct.code"
                  @blur="validateProductCode(createProduct.code)"
                />
                <ul v-if="v1.code" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
                <span
                  v-if="isProductValid == true"
                  class="text-danger error-text "
                >
                  Product code already exists
                </span>
                <i
                  v-if="isProductValid == true"
                  class="text-danger fa fa-times"
                ></i>
                <i
                  v-if="isProductValid == false"
                  class="text-success fa fa-check"
                ></i>
              </div>
            </div>
            <div class="mb-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Name</label
              >
              <div>
                <input
                  :class="[{ 'is-invalid': v1.name }]"
                  type="text"
                  placeholder="Enter product name"
                  v-model="createProduct.name"
                  class="bg-[#fff] border-[#d0d5dd] border-solid border py-[12px] px-[14px] rounded-[8px] w-full"
                />
                <ul v-if="v1.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="mb-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Description</label
              >
              <div>
                <textarea
                  class="bg-[#fff] border-[#d0d5dd] border-solid border rounded-[8px] w-full py-[12px] px-[14px]"
                  placeholder="Enter product description"
                  v-model="createProduct.description"
                ></textarea>
              </div>
            </div>
            <div>
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Unit</label
              >
              <div class="mb-[16px]">
                <select
                  class="bg-[#fff] border-[#d0d5dd] border-solid border py-[12px] px-[14px] rounded-[8px] w-full"
                  placeholder="Select an option"
                  v-model="createProduct.unit"
                >
                  <option value="">Select product unit</option>
                  <option
                    v-for="unit in units"
                    :key="unit.name"
                    :value="unit.name"
                  >
                    {{ unit.name }}
                  </option>
                </select>
                <ul v-if="v1.unit" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="mb-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Default Retail Price (RRP)</label
              >
              <div>
                <input
                  :class="[{ 'is-invalid': v1.retailPrice }]"
                  class="bg-[#fff] border-[#d0d5dd] border-solid border py-[12px] px-[14px] rounded-[8px] w-full"
                  placeholder="Enter product retail price"
                  type="number"
                  v-model="createProduct.retailPrice"
                />
                <ul v-if="v1.retailPrice" class="parsley-errors-list filled">
                  <li class="parsley-required">Enter a valid number.</li>
                </ul>
              </div>
            </div>
            <div>
              <div class="flex flex-col gap-1 w-full">

                <label
                  class="text-[#353535] text-[14px] font-medium leading-[20px]"
                >
                  Upload Image
                </label>

                <input
                  type="file"
                  ref="file"
                  accept="image/*"
                  @change="uploadFile"
                  class="hidden"
                />

                <div
                  @click.prevent="$refs.file.click()"
                  class="border-[1.5px] border-solid border-[#D0D5DD] py-[12px] px-[14px] rounded-[8px] text-[#96A0B5] text-[14px] font-normal outline-none cursor-pointer"
                >
                  <div class="flex items-center justify-between">
                    <span>Select image</span>
                    <i class="fa-regular fa-file"></i>
                  </div>
                </div>

                <ul
                  v-if="v1.name"
                  class="parsley-errors-list filled text-red-500 text-xs"
                >
                  <li class="parsley-required">Required.</li>
                </ul>

  
                <div v-if="previewUrl" class="col-12 col-sm-8 mt-6 relative">
                  <img
                    alt="business logo"
                    height="180px"
                    width="240px"
                    class="logoImage"
                    :src="previewUrl"
                  />
                  <i
                    class="feather icon-x text-danger"
                    @click="previewUrl = ''"
                    style="font-weight: bold; font-size: 18px; position: absolute; top: -5px; right: -5px; cursor: pointer"
                  ></i>
                </div>
              </div>

              <div
                class="flex items-center gap-[10px] max-w-max mt-[16px]"
                @click="createProduct.active = !createProduct.active"
              >
                <button
                  type="button"
                  class="relative inline-flex items-center flex-shrink-0 h-[24px] px-[3px] w-[46px] transition-all duration-200 ease-in-out border-[0.5px] border-[#E4E4E7] shadow-sm rounded-[10px] cursor-pointer w-11 focus:outline-none"
                  role="switch"
                  :aria-checked="createProduct.active"
                  :class="{
                    'bg-green-800': createProduct.active,
                    'bg-[#E4E4E7]': !createProduct.active,
                  }"
                >
                  <span
                    aria-hidden="true"
                    class="inline-block w-[21px] h-[16px] bg-white transition duration-200 ease-in-out transform bg-gray-400 rounded-full pointer-events-none"
                    :class="{
                      'translate-x-5 bg-white': createProduct.active,
                      'translate-x-0 bg-gray-400': !createProduct.active,
                    }"
                  ></span>
                </button>
                <span class="cursor-pointer">
                  <span
                    class="text-[#353535] text-[16px] font-normal leading-[20px]"
                  >
                    Product is active
                  </span>
                </span>
              </div>
              <div
                class="flex items-center gap-[10px] max-w-max my-[24px]"
                @click="
                  createProduct.availability =
                    createProduct.availability === 1 ? 0 : 1
                "
              >
                <button
                  type="button"
                  class="relative inline-flex items-center flex-shrink-0 h-[24px] px-[3px] w-[46px] transition-all duration-200 ease-in-out border-[0.5px] border-[#E4E4E7] shadow-sm rounded-[10px] cursor-pointer w-11 focus:outline-none"
                  role="switch"
                  :aria-checked="createProduct.availability === 1"
                  :class="{
                    'bg-green-800': createProduct.availability === 1,
                    'bg-[#E4E4E7]': createProduct.availability === 0,
                  }"
                >
                  <span
                    aria-hidden="true"
                    class="inline-block w-[21px] h-[16px] bg-white transition duration-200 ease-in-out transform bg-gray-400 rounded-full pointer-events-none"
                    :class="{
                      'translate-x-5 bg-white':
                        createProduct.availability === 1,
                      'translate-x-0 bg-gray-400':
                        createProduct.availability === 0,
                    }"
                  ></span>
                </button>
                <span class="cursor-pointer">
                  <span
                    class="text-[#353535] text-[16px] font-normal leading-[20px]"
                  >
                    This product is available for sale
                  </span>
                </span>
              </div>

              <div
                class="flex items-center gap-[10px] max-w-max mb-[16px]"
                @click="
                  createProduct.allowPriceOverride = !createProduct.allowPriceOverride
                "
              >
                <button
                  type="button"
                  class="relative inline-flex items-center flex-shrink-0 h-[24px] px-[3px] w-[46px] transition-all duration-200 ease-in-out border-[0.5px] border-[#E4E4E7] shadow-sm rounded-[10px] cursor-pointer w-11 focus:outline-none"
                  role="switch"
                  :aria-checked="createProduct.allowPriceOverride"
                  :class="{
                    'bg-green-800': createProduct.allowPriceOverride,
                    'bg-[#E4E4E7]': !createProduct.allowPriceOverride,
                  }"
                >
                  <span
                    aria-hidden="true"
                    class="inline-block w-[21px] h-[16px] bg-white transition duration-200 ease-in-out transform bg-gray-400 rounded-full pointer-events-none"
                    :class="{
                      'translate-x-5 bg-white':
                        createProduct.allowPriceOverride,
                      'translate-x-0 bg-gray-400': !createProduct.allowPriceOverride,
                    }"
                  ></span>
                </button>
                <span class="cursor-pointer">
                  <span
                    class="text-[#353535] text-[16px] font-normal leading-[20px]"
                  >
                    Price can be overridden by distributor
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="flex items-center gap-[8px]">
            <button
              class="border-[1px] border-[#96a0b5] rounded-[8px] text-[#96a0b5] font-normal text-[14px] border-solid px-[24px] py-[12px] w-full"
              type="button"
              data-dismiss="modal"
              @click="previewUrl = ''"
            >
              Cancel
            </button>
            <button
              :disabled="isBusyModal || isProductValid == true"
              class="border-[1px] bg-[#2463eb] rounded-[8px] text-white font-normal text-[14px] border-solid px-[24px] py-[12px] w-full"
              type="button"
              @click.prevent="create"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Add product</span>
            </button>
          </div>
        </div>
      </div>
    </div> -->

    <!-- edit product modal -->
    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit"
      role="dialog"
    >
      <div
        class="modal-dialog m-0 p-0 absolute top-0 right-0 flex justify-end items-end"
      >
        <div
          class="modal-content rounded-none bg-[#f8f8f8] p-[20px] md:p-[40px] min-h-screen"
        >
          <div
            class="modal-header modal-header-colored  flex items-start justify-between p-0 m-0"
          >
            <h4
              class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
            >
              Edit product
            </h4>
            <button
              class="text-2xl text-[#353535]"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              @click="previewUrl = ''"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body m-0 p-0">
            <div class="flex flex-col items-center w-full">
              <div>
                <div class="flex flex-col items-center">
                  <div>
                    <img
                      v-if="previewUrl"
                      class="image-preview"
                      :src="previewUrl"
                      alt="logo"
                      width="80"
                      height="80"
                    />
                  </div>

                  <input
                    id="file"
                    type="file"
                    class="custom-file-input"
                    @change="uploadFile"
                    ref="file"
                    hidden
                  />
                  <button
                    v-if="previewUrl"
                    class="p-[8px] border-[1.5px] border-[#DC2828] border-solid text-[#DC2828] text-[14px] leading-[20px] rounded-[8px] mt-[6px] mb-[16px]"
                    @click="cancelUpload"
                  >
                    Delete image
                  </button>
                  <button
                    v-else
                    class="btn btn-custom btn-outline-primary btn-md mb-[16px] mt-[6px] text-[14px] leading-[20px] p-[8px]"
                    @click="onPickFile"
                  >
                    Upload Image
                  </button>
                </div>
              </div>
            </div>
            <div>
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Category</label
              >
              <div>
                <select
                  class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full outline-none focus:outline-none"
                  placeholder="Select an option"
                  v-model="updateProduct.categoryId"
                >
                  <option
                    v-for="cat in filteredCategory"
                    :key="cat.id"
                    :value="cat.id"
                  >
                    {{ cat.name }}
                  </option>
                </select>
                <ul v-if="v2.categoryId" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="my-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Code</label
              >
              <div class="">
                <input
                  :class="[{ 'is-invalid': v2.code }]"
                  type="text"
                  class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full outline-none focus:outline-none"
                  v-model="updateProduct.code"
                />
                <ul v-if="v2.code" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Name</label
              >
              <div class="mb-[16px]">
                <input
                  :class="[{ 'is-invalid': v2.name }]"
                  class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full"
                  type="text"
                  v-model="updateProduct.name"
                />
                <ul v-if="v2.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="mb-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Description</label
              >
              <div>
                <textarea
                  class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full"
                  v-model="updateProduct.description"
                ></textarea>
              </div>
            </div>
            <div class="mb-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Unit</label
              >
              <div class="">
                <select
                  class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full"
                  placeholder="Select an option"
                  v-model="updateProduct.unit"
                >
                  <option
                    v-for="unit in units"
                    :key="unit.name"
                    :value="unit.name"
                  >
                    {{ unit.name }}
                  </option>
                </select>
                <ul v-if="v2.unit" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="mb-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Default Retail Price (RRP)</label
              >
              <div>
                <input
                  :class="[{ 'is-invalid': v2.retailPrice }]"
                  type="number"
                  class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full"
                  v-model="updateProduct.retailPrice"
                />
                <ul v-if="v2.retailPrice" class="parsley-errors-list filled">
                  <li class="parsley-required">Enter a valid number.</li>
                </ul>
              </div>
            </div>
            <div class="mb-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Default Distributor Price (DP)</label
              >
              <div class="">
                <input
                  :class="[{ 'is-invalid': v2.distributorPrice }]"
                  type="number"
                  v-model="updateProduct.distributorPrice"
                  class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full"
                />
                <ul
                  v-if="v2.distributorPrice"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Enter a valid number.</li>
                </ul>
              </div>
            </div>
            <div
              class="flex items-center gap-[10px] max-w-max mt-[16px]"
              @click="updateProduct.active = !updateProduct.active"
            >
              <button
                type="button"
                class="relative inline-flex items-center flex-shrink-0 h-[24px] px-[3px] w-[46px] transition-all duration-200 ease-in-out border-[0.5px] border-[#E4E4E7] shadow-sm rounded-[10px] cursor-pointer w-11 focus:outline-none"
                role="switch"
                :aria-checked="createProduct.active"
                :class="{
                  'bg-green-800': updateProduct.active,
                  'bg-[#E4E4E7]': !updateProduct.active,
                }"
              >
                <span
                  aria-hidden="true"
                  class="inline-block w-[21px] h-[16px] bg-white transition duration-200 ease-in-out transform bg-gray-400 rounded-full pointer-events-none"
                  :class="{
                    'translate-x-5 bg-white': updateProduct.active,
                    'translate-x-0 bg-gray-400': !updateProduct.active,
                  }"
                ></span>
              </button>
              <span class="cursor-pointer">
                <span
                  class="text-[#353535] text-[16px] font-normal leading-[20px]"
                >
                  This Product is active
                </span>
              </span>
            </div>

            <div
              class="flex items-center gap-[10px] max-w-max my-[24px]"
              @click="updateProduct.availability = !updateProduct.availability"
            >
              <button
                type="button"
                class="relative inline-flex items-center flex-shrink-0 h-[24px] px-[3px] w-[46px] transition-all duration-200 ease-in-out border-[0.5px] border-[#E4E4E7] shadow-sm rounded-[10px] cursor-pointer w-11 focus:outline-none"
                role="switch"
                :aria-checked="updateProduct.availability"
                :class="{
                  'bg-green-800': updateProduct.availability,
                  'bg-[#E4E4E7]': !updateProduct.availability,
                }"
              >
                <span
                  aria-hidden="true"
                  class="inline-block w-[21px] h-[16px] bg-white transition duration-200 ease-in-out transform bg-gray-400 rounded-full pointer-events-none"
                  :class="{
                    'translate-x-5 bg-white': updateProduct.availability,
                    'translate-x-0 bg-gray-400': !updateProduct.availability,
                  }"
                ></span>
              </button>
              <span class="cursor-pointer">
                <span
                  class="text-[#353535] text-[16px] font-normal leading-[20px]"
                >
                  This product is available for sale
                </span>
              </span>
            </div>

            <div
              class="flex items-center gap-[10px] max-w-max mb-[16px]"
              @click="
                updateProduct.allowPriceOverride = !updateProduct.allowPriceOverride
              "
            >
              <button
                type="button"
                class="relative inline-flex items-center flex-shrink-0 h-[24px] px-[3px] w-[46px] transition-all duration-200 ease-in-out border-[0.5px] border-[#E4E4E7] shadow-sm rounded-[10px] cursor-pointer w-11 focus:outline-none"
                role="switch"
                :aria-checked="updateProduct.allowPriceOverride"
                :class="{
                  'bg-green-800': updateProduct.allowPriceOverride,
                  'bg-[#E4E4E7]': !updateProduct.allowPriceOverride,
                }"
              >
                <span
                  aria-hidden="true"
                  class="inline-block w-[21px] h-[16px] bg-white transition duration-200 ease-in-out transform bg-gray-400 rounded-full pointer-events-none"
                  :class="{
                    'translate-x-5 bg-white': updateProduct.allowPriceOverride,
                    'translate-x-0 bg-gray-400': !updateProduct.allowPriceOverride,
                  }"
                ></span>
              </button>
              <span class="cursor-pointer">
                <span
                  class="text-[#353535] text-[16px] font-normal leading-[20px]"
                >
                  Price can be overridden by distributor
                </span>
              </span>
            </div>
          </div>
          <div class="flex items-center gap-[8px]">
            <button
              class="border-[1px] border-[#96a0b5] rounded-[8px] text-[#96a0b5] font-normal text-[14px] border-solid px-[24px] py-[12px] w-full"
              type="button"
              data-dismiss="modal"
              @click="previewUrl = ''"
            >
              Cancel
            </button>
            <button
              :disabled="isBusyModal"
              class="border-[1px] bg-[#2463eb] rounded-[8px] text-white font-normal text-[14px] border-solid px-[24px] py-[12px] w-full"
              type="button"
              @click.prevent="update"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- filter modal -->
    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-filter"
      role="dialog"
    >
      <div
        class="modal-dialog m-0 p-0 absolute top-0 right-0 flex justify-end items-end"
      >
        <div
          class="modal-content rounded-none bg-[#f8f8f8] p-[20px] md:p-[40px] md:min-h-screen"
        >
          <div
            class="modal-header modal-header-colored  flex items-start justify-between p-0 m-0"
          >
            <h4
              class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
            >
              Filter
            </h4>
            <button
              class="text-2xl text-[#353535]"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body p-0 m-0">
            <div class="pb-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Status</label
              >
              <div>
                <select
                  class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full"
                  placeholder="Select an option"
                  v-model="filter.status"
                >
                  <option value="0">All Products</option>
                  <option value="True">Active</option>
                  <option value="False">Disabled</option>
                </select>
              </div>
            </div>
            <div class="pb-[16px]">
              <label
                cclass="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Category</label
              >
              <div>
                <select
                  class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full"
                  placeholder="Select an option"
                  v-model="filter.categoryId"
                >
                  <option value="0">All Categories</option>
                  <option
                    v-for="cat in categories"
                    :key="cat.id"
                    :value="cat.id"
                  >
                    {{ cat.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="pb-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Other filters</label
              >
              <div>
                <input
                  class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full"
                  type="text"
                  placeholder="Filter by code or name..."
                  v-model="filter.qry"
                />
              </div>
            </div>
          </div>
          <div class="flex items-center gap-[8px] w-full">
            <button
              class="bg-transparent border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <!-- <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button> -->
            <button
              class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] w-full"
              type="button"
              @click="getProducts"
            >
              <span v-if="isBusy">Busy...</span>
              <span v-else>Search</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- download modal -->
    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-download"
      role="dialog"
    >
      <div class="modal-dialog mx-auto">
        <div class="modal-content p-[24px] bg-[#f8f8f8]">
          <div class="modal-header flex items-start justify-between p-0 m-0">
            <h4
              class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
            >
              Export Data
            </h4>
            <button
              class="text-2xl text-[#353535]"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body p-0 m-0">
            <p class="text-[#353535] text-[16px] font-normal leading-[20px]">
              Are you sure you want to export data?
            </p>
          </div>
          <div class="flex items-center gap-[8px] mt-[16px]">
            <button
              class="border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full outline-none focus:outline-none"
              data-dismiss="modal"
              aria-hidden="true"
            >
              Cancel
            </button>
            <JsonExcel
              class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] w-full text-center cursor-pointer"
              :fields="Product_fields"
              name="product.csv"
              :fetch="downloadFile"
              type="csv"
              v-if="hasPermission('Product_Export')"
            >
              <button
                class="text-white text-[16px] font-medium leading-[20px] w-full"
              >
                <span>
                  Proceed
                </span>
              </button>
            </JsonExcel>
          </div>
        </div>
      </div>
    </div>

    <Modal
      @proceed="finishDownload($event)"
      :isBusy="modal.isLoading"
      :text="modal.actionText"
      :message="modal.message"
    />
    <AddProducts
      :SearchProduct="SearchProduct"
      :isSearching="isSearching"
      :addedProducts="addedProducts"
      :ShowConfirmAddModal="ShowConfirmAddModal"
      :currItem="currItem"
      :results="results"
      :allProducts="allProducts"
      :addProducts="addProducts"
      :Products="Products"
      :mappedResults="mappedResults"
      :errorMessage="errorMessage"
      :resetErrMsg="resetErrMsg"
      :isBusy="isBusy"
      @update-category="updateProductCategory"
      :categories="categories"
    />
    <div
      class="modal fade py-1 colored-header colored-header-primary"
      id="mod-confirm"
      role="dialog"
    >
      <div class="modal-dialog max-w-[500px] w-full relative">
        <div class="modal-content p-[24px] bg-[#f8f8f8]">
          <button
            class="absolute right-6 top-4 text-2xl"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
          <div class="text-center">
            <div class="flex justify-center items-center mt-[16px]">
              <img src="/assets/img/alarm.png" alt="" />
            </div>

            <p class="text-[16px] font-bold text-[#353535] mb-[5px]">
              Confirmation
            </p>
            <p class="text-[13px] font-normal text-[#96a0b5]">
              Are you sure you want to add customers to list?
            </p>
            <div
              class="mt-[15px] flex gap-2 items-center justify-center w-full"
            >
              <button
                type="button"
                data-dismiss="modal"
                aria-hidden="true"
                class="border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full"
              >
                Close
              </button>
              <button
                @click="AddProductToList"
                type="button"
                class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] w-full"
              >
                Yes, Procced
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import Select2 from "@/components/Select2";
import SystemPermissions from "@/permissions";
import Modal from "@/components/helpers/modal.vue";
import JsonExcel from "vue-json-excel";
import Pagination from "../components/Pagination.vue";
import ProductCategories from "../components/Products/ProductCategories.vue";
import ProductsHeader from "../components/Products/ProductsHeader.vue";
import ProductsTable from "../components/Products/ProductsTable.vue";
import ProductUnits from "../components/Products/ProductUnits.vue";
import AddProducts from "../components/Products/AddProducts.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsProducts",
  components: {
    Pagination,
    Modal,
    JsonExcel,
    ProductCategories,
    Select2,
    ProductsHeader,
    ProductsTable,
    ProductUnits,
    AddProducts,
  },

  created() {
    if (!this.hasPermission("Product_View")) {
      this.$router.push("/restricted");
    }
    this.getProducts();
    this.getTypes();
    this.getCategories();
    this.getUnits();
  },

  updated() {
    if (this.$route.params.id && this.Products) {
      const product = this.Products.find(
        (prod) => prod.id === this.$route.params.id
      );
      if (product) {
        this.viewEdit(product);
      }
    }
  },

  watch: {
    "createProduct.type": function(newType, oldType) {
      if (newType == "Infinite") {
        this.createProduct.quantity = 0;
      }
    },
    "updateProduct.type": function(newType, oldType) {
      if (newType == "Infinite") {
        this.updateProduct.quantity = 0;
      }
    },
  },
  mounted() {
    this.getCategories();
  },
  data() {
    return {
      isSearching: false,
      results: [],
      allResult: [],
      currItem: {
        data: {},
        index: null,
      },
      product_code: "",
      addedProducts: [],
      allProducts: [],
      exportAllLoading: false,
      errorMessage: {
        status: false,
        message: "",
      },
      filter: {
        status: "True",
        categoryId: "0",
        qry: "",
      },
      activeTab: "product",
      isBusy: false,
      isBusyModal: false,
      createProduct: {
        code: null,
        name: null,
        description: null,
        unit: null,
        volume: null,
        group: null,
        groupSize: null,
        quantity: 99999999,
        type: "Finite",
        retailPrice: null,
        distributorPrice: null,
        availability: null,
        active: null,
        categoryId: null,
        restockLevel: 0,
        allowPriceOverride: null,
      },
      updateProduct: {
        code: null,
        name: null,
        description: null,
        unit: null,
        volume: null,
        group: null,
        groupSize: null,
        quantity: 99999999,
        type: "Finite",
        retailPrice: null,
        distributorPrice: null,
        active: true,
        categoryId: null,
        id: null,
        restockLevel: 0,
        allowPriceOverride: null,
        availability: null,
      },
      editProduct: {
        code: null,
        name: null,
        description: null,
        unit: null,
        volume: null,
        group: null,
        groupSize: null,
        quantity: 99999999,
        type: "Finite",
        retailPrice: null,
        distributorPrice: null,

        active: true,
        categoryId: null,
        id: null,
        restockLevel: 0,
        allowPriceOverride: null,
        availability: null,
      },
      v1: {
        code: false,
        name: false,
        unit: false,
        group: false,
        groupSize: false,
        quantity: false,
        type: false,
        retailPrice: false,
        distributorPrice: false,
        categoryId: false,
        restockLevel: false,
        availability: false,
      },
      v2: {
        code: false,
        name: false,
        unit: false,
        group: false,
        groupSize: false,
        quantity: false,
        type: false,
        retailPrice: false,
        distributorPrice: false,
        categoryId: false,
        restockLevel: false,
        availability: false,
      },
      isProductValid: null,
      Products: [],
      Product_fields: {
        "Product Code": "code",
        "Product Name": "name",
        "Product Category": "category",
        "Product Unit": "unit",
        "Product Creation Date": "creationDate",
        "Product Description": "description",
        "Product Last Modified Date": "modifiedDate",
        "Price Option ID": "priceOptionId",
        "Price Option Name": "priceOptionName",
        "Price Option Description": "priceOptionDescription",
        "Distributor Price": "distributorPrice",
        "Retail Price": "retailPrice",
        "Price Option Creation Date": "priceOptionCreationDate",
        "Price Option Last Modified Date": "priceOptionLastModifiedDate",
      },
      types: [],
      categories: [],

      units: [],
      count: 0,
      page: 0,
      pageNumber: 0,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
      modal: {
        isLoading: false,
        actionMethod: "",
        actionText: "",
        message: "",
      },
      images: null,
      previewUrl: "",
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },

    filteredCategory: function() {
      return this.categories.filter((x) => x.active);
    },
    mappedResults() {
      return [];
    },
  },
  methods: {
    updateProductCategory({ index, categoryId }) {
      console.log(index);
      console.log(categoryId);
      this.addedProducts[index]["categoryId"] = categoryId;
    },
    resetErrMsg() {
      this.errorMessage.status = false;
    },
    AddProductToList() {
      $("#mod-confirm").modal("hide");
      // let tempDist =
      //   this.products.find((v) => v.name === this.currItem.data.productCode) ||
      //   {};

      // console.log(this.currItem.data);
      // console.log(tempDist);
      // if (tempDist.id) {
      //   this.errorMessage.status = true;
      //   this.errorMessage.message = "Distributor has already being invited!";
      //   return;
      // }
      this.addedProducts.push(this.currItem.data);
      this.results.splice(this.currItem.index, 1);
    },
    ShowConfirmAddModal(i, index) {
      $("#mod-confirm").modal("show");

      this.currItem.data = i;
      this.currItem.index = index;
    },
    async SearchProduct(product_code) {
      this.errorMessage.status = false;
      this.isSearching = true;
      try {
        const res = await this.$http.get(
          `/Products/searchSapProduct?productCode=${product_code}`
        );
        const data = await res.json();
        this.results.push(data);
        this.isSearching = false;
      } catch (error) {
        let message = error.message ? error.message : null;
        this.errorMessage.status = true;
        this.isSearching = false;
        let msgArray = error.body.errors
          ? error.body.errors
          : error.data.errors
          ? error.data.errors
          : [];
        let msg = msgArray.length > 0 ? msgArray[0].slice(0, 18) : null;

        this.errorMessage.message =
          msg == "Could not retrieve"
            ? "No product Found"
            : message == "Already Added"
            ? "Product aleady Added!"
            : "Error Getting product Info";
      }
    },
    async addProducts() {
      this.isBusy = true;
      try {
        let res = null;
        let body = {};
        if (this.addedProducts.length > 0) {
          body = this.addedProducts.map((v) => ({
            product_Code: v.productCode,
            active: true,
            categoryId: v.categoryId,
          }));
          let dForm = { products: body };
          res = await this.$http.post(
            "/Products/registerSapProductBulk",
            dForm
          );
          this.isBusy = false;
        }
        const results = await res.json();
        this.allProducts = results;
        this.addedProducts = [];
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        console.log(error);
        this.errorMessage =
          error.response.data.message ||
          "Failed to add products. Please try again.";
      }
    },
    switchTab(tab) {
      this.activeTab = tab;
    },
    openCreateModal() {
      $("#mod-add-product").modal("show");
    },
    onPickFile() {
      this.$refs.file.click();
    },
    cancelUpload() {
      this.previewUrl = "";
      this.images = null;
    },
    uploadFile() {
      this.images = this.$refs.file.files[0];
      this.previewUrl = URL.createObjectURL(this.images);
    },
    openModal(btnText, message) {
      this.modal.actionText = btnText;
      this.modal.message = message;
      $("#modal").modal("show");
    },

    closeModal() {
      this.modal.actionText = "";
      this.modal.message = "";
      $("#modal").modal("hide");
    },

    async validateProductCode(code) {
      this.isBusy = true;
      try {
        const response = await this.$http.get(
          `/Products/isProductCodeExist/${code}`
        );
        if (response.ok) {
          this.isBusy = false;
          this.isProductValid = response.data;
          return;
        }
      } catch (err) {
        this.$toast.info(err, {
          icon: true,
          rtl: false,
        });
      }
    },

    async downloadFile() {
      await this.$http
        .get("/Products/getAllProducts")
        .then(function(res) {
          this.products = res.body;
        })
        .catch(function() {});
      $("#mod-download").modal("hide");
      return this.products;
    },

    openConfirmDownload() {
      $("#mod-download").modal("show");
    },

    reset() {
      this.filter = {
        status: "True",
        categoryId: "0",
        qry: "",
      };

      this.getProducts();
    },

    cancel() {
      const currentProduct = this.Products.find(
        (p) => p.id === this.editProduct.id
      );
      currentProduct.name = this.editProduct.name;
      currentProduct.code = this.editProduct.code;
      currentProduct.retailPrice = this.editProduct.retailPrice;
      currentProduct.distributorPrice = this.editProduct.distributorPrice;
      currentProduct.unit = this.editProduct.unit;
      currentProduct.active = this.editProduct.active;
      currentProduct.categoryId = this.editProduct.categoryId;
      currentProduct.allowPriceOverride = this.editProduct.allowPriceOverride;
      currentProduct.quantity = this.editProduct.quantity;
      $("#mod-edit").modal("hide");
    },

    viewExport() {
      //   $("#mod-export").modal("show");
    },

    viewAdd() {
      this.createProduct = {
        code: null,
        name: null,
        description: null,
        unit: null,
        volume: null,
        group: null,
        groupSize: null,
        quantity: 99999999,
        type: "Finite",
        retailPrice: null,
        distributorPrice: null,
        active: false,
        categoryId: null,
        restockLevel: 0,
        allowPriceOverride: false,
      };
      this.v1 = {
        code: false,
        name: false,
        unit: false,
        group: false,
        groupSize: false,
        quantity: false,
        type: false,
        categoryId: false,
        restockLevel: 0,
      };
      $("#mod-add-product").modal("show");
    },

    viewEdit(c) {
      var editValue = {
        code: c.code,
        name: c.name,
        description: c.description,
        quantity: c.quantity,
        type: c.type,
        retailPrice: c.retailPrice,
        distributorPrice: c.distributorPrice,
        unit: c.unit,
        categoryId: c.category.id,
        active: c.active,
        restockLevel: c.restockLevel,
        allowPriceOverride: c.allowPriceOverride,
        id: c.id,
        availability: c.saleStatus,
      };
      this.editProduct = editValue;
      this.previewUrl = c.imageUrl;
      this.updateProduct = c;
      this.updateProduct.availability = c.saleStatus;
      $("#mod-edit").modal("show");
    },

    viewFilter() {
      this.page = 0;
      $("#mod-filter").modal("show");
    },

    async create() {
      this.isBusyModal = true;
      this.isBusy = true;
      const formData = new FormData();
      formData.append("code", this.createProduct.code);
      formData.append("name", this.createProduct.name);
      formData.append("description", this.createProduct.description);
      formData.append("unit", this.createProduct.unit);
      formData.append("volume", "N/A");
      formData.append("group", "N/A");
      formData.append("groupSize", 0);
      formData.append("quantity", 9999999);
      formData.append("type", "Finite");
      formData.append("categoryId", this.createProduct.categoryId);
      formData.append("distributorPrice", this.createProduct.distributorPrice);
      formData.append("retailPrice", this.createProduct.retailPrice);
      formData.append("active", this.createProduct.active);
      formData.append("parentId", this.user.parentId);
      formData.append("restockLevel", this.createProduct.restockLevel);
      formData.append("SaleStatus", this.createProduct.availability);
      formData.append(
        "allowPriceOverride",
        this.createProduct.allowPriceOverride
      );
      formData.append("image", this.images);

      if (this.validateCreate()) {
        await this.$http
          .post("/Products/createForManufacturer", formData)
          .then(function(res) {
            this.isBusyModal = false;
            this.$toast.success("Product created successfully.", {
              icon: true,
              rtl: false,
            });
            this.isBusy = false;
            (this.previewUrl = ""), (this.isBusyModal = false);
            $("#mod-add-product").modal("hide");
            this.getProducts();
          })
          .catch(function() {
            this.isBusy = false;
            this.isBusyModal = false;
            (this.previewUrl = ""), $("#mod-add-product").modal("hide");
          });
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },

    validateCreate() {
      this.v1 = {
        code: false,
        name: false,
        unit: false,
        quantity: false,
        type: false,
        categoryId: false,
        restockLevel: false,
      };
      var isValid = true;

      if (!this.createProduct.code) {
        this.v1.code = true;
        isValid = false;
      }

      if (!this.createProduct.name) {
        this.v1.name = true;
        isValid = false;
      }

      if (!this.createProduct.unit) {
        this.v1.unit = true;
        isValid = false;
      }

      if (!this.createProduct.categoryId) {
        this.v1.categoryId = true;
        isValid = false;
      }

      if (Number(this.createProduct.retailPrice) <= 0) {
        this.v1.retailPrice = true;
        isValid = false;
      }

      if (Number(this.createProduct.distributorPrice) <= 0) {
        this.v1.distributorPrice = true;
        isValid = false;
      }

      if (Number(this.createProduct.restockLevel) < 0) {
        this.v1.restockLevel = true;
        isValid = false;
      }

      return isValid;
    },

    async update() {
      this.isBusyModal = true;
      this.isBusy = true;
      const formData = new FormData();
      formData.append("code", this.updateProduct.code);
      formData.append("name", this.updateProduct.name);
      formData.append("description", this.updateProduct.description);
      formData.append("unit", this.updateProduct.unit);
      formData.append("volume", this.updateProduct.volume);
      formData.append("group", this.updateProduct.group);
      formData.append("groupSize", this.updateProduct.group);
      formData.append("quantity", 9999999);
      formData.append("type", "Finite");
      formData.append("categoryId", this.updateProduct.categoryId);
      formData.append("distributorPrice", this.updateProduct.distributorPrice);
      formData.append("retailPrice", this.updateProduct.retailPrice);
      formData.append("active", this.updateProduct.active);
      formData.append("id", this.updateProduct.id);
      formData.append("restockLevel", this.updateProduct.restockLevel);
      formData.append("SaleStatus", this.updateProduct.availability);
      formData.append(
        "allowPriceOverride",
        this.updateProduct.allowPriceOverride
      );
      formData.append("image", this.images);

      if (this.validateUpdate()) {
        await this.$http
          .put(
            "/Products/updateForManufacturer/" + this.updateProduct.id,
            formData
          )
          .then(function(res) {
            this.isBusyModal = false;
            // if (this.$route.params.id) this.$router.push("/products");
            this.$toast.success("Update successful", {
              icon: false,
              rtl: false,
            });
            this.isBusy = false;
            this.isBusyModal = false;
            $("#mod-edit").modal("hide");
            (this.previewUrl = ""), this.getProducts();
          })
          .catch(function() {
            this.isBusy = false;
            this.isBusyModal = false;
            (this.previewUrl = ""), this.getProducts();
          });
      }
    },
    validateUpdate() {
      this.v2 = {
        code: false,
        name: false,
        unit: false,
        // group: false,
        // groupSize: false,
        quantity: false,
        type: false,
        categoryId: false,
        restockLevel: false,
      };
      var isValid = true;

      if (!this.updateProduct.code) {
        this.v2.code = true;
        isValid = false;
      }

      if (!this.updateProduct.name) {
        this.v2.name = true;
        isValid = false;
      }

      if (!this.updateProduct.unit) {
        this.v2.unit = true;
        isValid = false;
      }

      if (!this.updateProduct.categoryId) {
        this.v2.categoryId = true;
        isValid = false;
      }

      if (Number(this.updateProduct.retailPrice) <= 0) {
        this.v2.retailPrice = true;
        isValid = false;
      }

      if (Number(this.updateProduct.distributorPrice) <= 0) {
        this.v2.distributorPrice = true;
        isValid = false;
      }

      if (Number(this.updateProduct.restockLevel) < 0) {
        this.v2.restockLevel = true;
        isValid = false;
      }

      return isValid;
    },

    async getProducts() {
      this.Products = [];
      this.isBusy = true;

      await this.$http
        .get(
          "/Products/getListForManufacturer?page=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            (this.filter.status != "0" ? "&active=" + this.filter.status : "") +
            (this.filter.categoryId != "0"
              ? "&categoryId=" + this.filter.categoryId
              : "") +
            (this.filter.qry != "" ? "&query=" + this.filter.qry : "") +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "")
        )
        .then(function(res) {
          this.Products = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.isBusy = false;
          this.previewUrl = "";
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },

    getTypes() {
      this.types = ["Finite", "Infinite"];
    },

    getCategories() {
      var t = "Product Category";

      this.$http
        .get(
          "/Categories/getListNoPagging?type=" +
            t +
            (this.user.parentId
              ? "&parentId=" + this.user.parentId
              : "Product Category")
        )
        .then(function(res) {
          this.categories = res.body;
          console.log(categories);
        })
        .catch(function() {});
    },

    getUnits() {
      var t = "Product Unit";
      var a = true;

      this.$http
        .get(
          "/Categories/getListNoPagging?type=" +
            t +
            "&active=" +
            a +
            (this.user.parentId
              ? "&parentId=" + this.user.parentId
              : "Product Category")
        )
        .then(function(res) {
          this.units = res.body;
        })
        .catch(function() {});
    },

    formatDate(date) {
      return moment(date).format("lll");
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    formatNumber(val) {
      return helper.formatNumber(val);
    },

    prev() {
      this.page -= 1;
      this.getProducts();
    },
    next() {
      this.page += 1;
      this.getProducts();
    },

    gotoPage(page) {
      this.page = page - 1;
      this.getProducts();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getProducts();
    },
    viewUploadProducts() {
      this.$router.push("/manufacturer-products-upload");
    },

    viewUploadPrice() {
      this.$router.push("/manufacturer-price-upload");
    },
  },
};
</script>
