var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row min-h-screen shadow-none"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{class:['be-loading', { 'be-loading-active': _vm.isBusy }]},[_c('div',{staticClass:"w-full pb-2 flex flex-col justify-start items-start gap-[15px]"},[_c('div',{staticClass:"w-full flex justify-start items-start gap-[11px]"},[_c('button',{class:[
              'text-[14px] font-semibold leading-[20px] cursor-pointer focus:outline-none py-[5px]',
              {
                'border-b-2 border-[#2463EB] text-[#2463EB]':
                  _vm.activeTab === 'business',
                'text-[#96a0b5]': _vm.activeTab !== 'business',
              } ],on:{"click":function($event){return _vm.switchTab('business')}}},[_vm._v(" Business Profile ")]),_c('button',{class:[
              'text-[14px] font-semibold leading-[20px] cursor-pointer focus:outline-none py-[5px]',
              {
                'border-b-2 border-[#2463EB] text-[#2463EB]':
                  _vm.activeTab === 'system',
                'text-[#96a0b5]': _vm.activeTab !== 'system',
              } ],on:{"click":function($event){return _vm.switchTab('system')}}},[_vm._v(" System Settings ")])]),_c('div',{staticClass:"relative w-full border-[1.5px] shadow-sm p-[20px] overflow-auto bg-white"},[(_vm.activeTab === 'business')?_c('BusinessProfile',{attrs:{"account":_vm.account,"contact":_vm.contact,"businessLogoUrl":_vm.businessLogoUrl,"updatingLogo":_vm.updatingLogo,"previewLogo":_vm.previewLogo,"v1":_vm.v1,"v2":_vm.v2},on:{"openPreview":_vm.openPreview,"previewFiles":_vm.previewFiles,"update":_vm.update}}):_vm._e(),(_vm.activeTab === 'system')?_c('div',[_c('SystemSettings')],1):_vm._e()],1)])]),_c('div',{staticClass:"modal fade colored-header colored-header-primary",attrs:{"id":"mod-logo-preview","role":"dialog"}},[_c('div',{staticClass:"modal-dialog mx-auto"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header modal-header-colored"},[_vm._m(0),_c('div',{staticClass:"mt-1"},[_c('i',{staticClass:"feather icon-x",staticStyle:{"color":"black","font-size":"20px"},on:{"click":_vm.closePreview}})])]),_c('div',{staticClass:"modal-body"},[(_vm.updatingLogo)?_c('div',{staticClass:"col-12 text-center p-5"},[_c('img',{attrs:{"alt":"business logo","width":"350px","height":"350px","src":_vm.previewLogo}})]):_c('div',{staticClass:"col-12 text-center p-5"},[(_vm.businessLogoUrl)?_c('img',{attrs:{"alt":"business logo","width":"350px","height":"350px","src":("" + _vm.businessLogoUrl)}}):_c('img',{staticClass:"logoImage",attrs:{"alt":"logo placeholder","src":"/assets/img/logo-placeholder.png"}})])]),_c('div',{staticClass:"modal-footer"},[(_vm.updatingLogo)?_c('button',{staticClass:"btn btn-md btn-primary",attrs:{"color":"blue","dark":""},on:{"click":_vm.updatingImage}},[_vm._v(" Save ")]):_vm._e()])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',{staticClass:"modal-title p-1"},[_c('strong',[_vm._v("Business Logo")])])}]

export { render, staticRenderFns }