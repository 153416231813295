<template>
	<div class="modal fade" id="modal" role="dialog">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-body p-4">
					<p class="text-center">
						Are your sure you want to
						<span class="font-weight-bold">{{ message }}</span>
					</p>
					<div class="mt-3 d-flex justify-content-between align-items-center">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
							@click.prevent="cancel"
						>
							Cancel
						</button>
						<button
							:disabled="isBusy"
							class="btn btn-primary"
							type="button"
							@click.prevent="ok"
						>
							<span v-if="isBusy">Busy...</span>
							<span v-else>{{ text }}</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Modal",
		props: ["isBusy", "text", "message", "orderData"],
		methods: {
			ok() {
				// console.log("ok");
				this.$emit("proceed", this.orderData);
			},
			cancel() {
				this.$emit("cancel");
			},
		},
	};
</script>
