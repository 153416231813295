<template>
    <div class="row match-height">
        <!--Statistics Start-->
                <div class="col-lg-3 col-sm-6 col-12">
                    <div :class="['card', 'be-loading', 'gradGree', 'text-whit', {'be-loading-active':isBusy}]">
                        <div class="card-content p-2">
                            <i class="icon mdi mdi-truck-fast font-large-3 pull-left text-success mt-1"></i>
                            <div class="ml-3 pull-left">
                                <h1 class="text-bold-700 text-whit font-big-2">{{ statistics.distributorsCount }}</h1>
                                <p class="mt-0 mb-0">Total Distributors</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                    <div :class="['card', 'be-loading', 'gradGree', 'text-whit', {'be-loading-active':isBusy}]">
                        <div class="card-content p-2">
                            <i class="feather icon-shopping-bag font-large-3 pull-left text-success mt-1"></i>
                            <div class="ml-3 pull-left">
                                <h1 class="text-bold-700 text-whit font-big-2">{{ statistics.retailersCount }}</h1>
                                <p class="mt-0 mb-0">Total Retailers</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                    <div :class="['card', 'be-loading', 'gradGree', 'text-whit', {'be-loading-active':isBusy}]">
                        <div class="card-content p-2">
                            <i class="feather icon-shopping-cart font-large-3 pull-left text-success mt-1"></i>
                            <div class="ml-3 pull-left">
                                <h1 class="text-bold-700 text-whit font-big-2">{{ statistics.totalOrdersCount }}</h1>
                                <p class="mt-0 mb-0">Total Orders</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                    <div :class="['card', 'be-loading', 'gradGree', 'text-whit', {'be-loading-active':isBusy}]">
                        <div class="card-content p-2">
                            <i class="feather icon-dollar-sign font-large-3 pull-left text-success mt-1"></i>
                            <div class="ml-3 pull-left">
                                <h1 class="text-bold-700 text-whit font-big-2">{{ statistics.totalOrdersVolume.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') }}</h1>
                                <p class="mt-0 mb-0">Total Orders Volume</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="card-header"><div class="card-content p-2"><h4 class="text-primary" style="text-align: center;">User Statistics</h4></div></div>
                    <div class="row match-height">
                        <div class="col-lg-3 col-sm-6 col-12">
                            <div :class="['card', 'be-loading', 'gradGree', 'text-whit', {'be-loading-active':isBusy}]">
                                <div class="card-content p-2">
                                    <i class="feather icon-bar-chart font-large-3 pull-left text-success mt-1"></i>
                                    <div class="ml-3 pull-left">
                                        <h1 class="text-bold-700 text-whit font-large-2">{{ statistics.userStatistics.totalUsersCount }}</h1>
                                        <p class="mt-0 mb-0">Total Users</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <div :class="['card', 'be-loading', 'gradGree', 'text-whit', {'be-loading-active':isBusy}]">
                                <div class="card-content p-2">
                                    <i class="feather icon-bar-chart font-large-3 pull-left text-success mt-1"></i>
                                    <div class="ml-3 pull-left">
                                        <h1 class="text-bold-700 text-whit font-large-2">{{ statistics.userStatistics.totalNewUsersCount }}</h1>
                                        <p class="mt-0 mb-0">Total New Users( Now / Day before)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <div :class="['card', 'be-loading', 'gradGree', 'text-whit', {'be-loading-active':isBusy}]">
                                <div class="card-content p-2">
                                    <i class="feather icon-bar-chart font-large-3 pull-left text-success mt-1"></i>
                                    <div class="ml-3 pull-left">
                                        <h1 class="text-bold-700 text-whit font-large-2">{{ statistics.userStatistics.totalNewUsersWeekCount }}</h1>
                                        <p class="mt-0 mb-0">Total New Users( Week )</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <div :class="['card', 'be-loading', 'gradGree', 'text-whit', {'be-loading-active':isBusy}]">
                                <div class="card-content p-2">
                                    <i class="feather icon-bar-chart font-large-3 pull-left text-success mt-1"></i>
                                    <div class="ml-3 pull-left">
                                        <h1 class="text-bold-700 text-whit font-large-2">{{ statistics.userStatistics.totalActiveUserPerDay }}</h1>
                                        <p class="mt-0 mb-0">Total Active User( Per Day )</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <div :class="['card', 'be-loading', 'gradGree', 'text-whit', {'be-loading-active':isBusy}]">
                                <div class="card-content p-2">
                                    <i class="feather icon-bar-chart font-large-3 pull-left text-success mt-1"></i>
                                    <div class="ml-3 pull-left">
                                        <h1 class="text-bold-700 text-whit font-large-2">{{ statistics.userStatistics.totalActiveUserWeek1 }}</h1>
                                        <p class="mt-0 mb-0">Total Active User Week 1( 1st - 7th )</p>
                                    </div>
                                </div>
                            </div>
                        </div><div class="col-lg-3 col-sm-6 col-12">
                            <div :class="['card', 'be-loading', 'gradGree', 'text-whit', {'be-loading-active':isBusy}]">
                                <div class="card-content p-2">
                                    <i class="feather icon-bar-chart font-large-3 pull-left text-success mt-1"></i>
                                    <div class="ml-3 pull-left">
                                        <h1 class="text-bold-700 text-whit font-large-2">{{ statistics.userStatistics.totalActiveUserWeek2 }}</h1>
                                        <p class="mt-0 mb-0">Total Active Week 2( 8th - 14th )</p>
                                    </div>
                                </div>
                            </div>
                        </div><div class="col-lg-3 col-sm-6 col-12">
                            <div :class="['card', 'be-loading', 'gradGree', 'text-whit', {'be-loading-active':isBusy}]">
                                <div class="card-content p-2">
                                    <i class="feather icon-bar-chart font-large-3 pull-left text-success mt-1"></i>
                                    <div class="ml-3 pull-left">
                                        <h1 class="text-bold-700 text-whit font-large-2">{{ statistics.userStatistics.totalActiveUserWeek3 }}</h1>
                                        <p class="mt-0 mb-0">Total Active Week 3( 15th - 21th )</p>
                                    </div>
                                </div>
                            </div>
                        </div><div class="col-lg-3 col-sm-6 col-12">
                            <div :class="['card', 'be-loading', 'gradGree', 'text-whit', {'be-loading-active':isBusy}]">
                                <div class="card-content p-2">
                                    <i class="feather icon-bar-chart font-large-3 pull-left text-success mt-1"></i>
                                    <div class="ml-3 pull-left">
                                        <h1 class="text-bold-700 text-whit font-large-2">{{ statistics.userStatistics.totalActiveUserWeek4 }}</h1>
                                        <p class="mt-0 mb-0">Total Active Week 4( 22th - Last day )</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 col-12">
                            <div :class="['card', 'be-loading', 'gradGree', 'text-whit', {'be-loading-active':isBusy}]">
                                <div class="card-content p-2">
                                    <i class="feather icon-bar-chart font-large-3 pull-left text-success mt-1"></i>
                                    <div class="ml-3 pull-left">
                                        <h1 class="text-bold-700 text-whit font-large-2">{{ statistics.userStatistics.totalActiveUserPerMonth }}</h1>
                                        <p class="mt-0 mb-0">Total Active User( Per Month )</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        <!--Statistics End-->
    </div>
</template>
<script>
    export default {
        name: "statistics",
        created() {
            this.getStatistics();
        },
        data() {
            return {
                isBusy: true,
                statistics: {},
            }
        },
        methods: {
            async getStatistics() {
                this.busy = true;
                await this.$http
                    .get("/Statistics/getStatistics")
                    .then(function (res) {
                        this.statistics = res.body;  
                    })
                    .catch(function () {});
                this.busy = false;
            },
        },
    }
</script>