<template>
  <div id="paymentReceipt" style="display:none" class="card-body bg-white text-dark">
    <div class="container my-3">
      <div class="row">
        <div class="col-md-12">
          <div class="invoice-title">
            <div class="row">
              <div class="col-md-4">
                <img
                  class="logo-img"
                  src="https://app.wekurnect.ng/assets/img/logo.png"
                  alt="logo"
                  width="138"
                  height="37"
                />
              </div>
              <div class="col-md-8 text-right">
                <address>
                  <p style="font-size: 14px; margin: 0 0 8px 0">
                    <span style="color: #9c9c9c">Printing Date:</span> {{ formatDate(new Date()) }}
                  </p>
                </address>
              </div>
            </div>
            <hr />
          </div>
          <div class="row mt-3 mb-4">
            <div class="col-md-6">
              <address style="width: 500px">
                <p><strong>Customer Details</strong></p>
                {{ value && value.owner ? value.owner.displayName : "" }}<br />
                {{
                  value && value.owner
                    ? value.owner.phoneNumber
                      ? value.owner.phoneNumber
                      : value.owner.emailAddress
                    : ""
                }}
                <br />
                {{ value && value.owner ? value.owner.deliveryAddress : "" }}
              </address>
            </div>
            <div class="col-md-6 text-right">
              <address>
                <p style="font-size: 14px; margin: 0 0 8px 0">
                  <span style="color: #9c9c9c">Reference ID:</span>
                  {{ value ? value.referenceId : "N/A" }}
                </p>
              </address>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default">
            <div
              class="panel-heading pl-2 mb-2"
              style="height: 71px; background: rgba(221, 221, 221, 0.15);display: flex; align-items: center"
            >
              <h3 class="panel-title">
                <strong>Summary</strong>
              </h3>
            </div>
            <div class="panel-body">
              <div class="row mt-3 mb-4">
                <div class="col-md-6">
                  <p>
                    <strong>Payment Amount:</strong> &#8358;{{
                      value && formatMoney(value.paidAmount)
                    }}
                  </p>
                  <p>
                    <strong>Payment Channel:</strong>
                    {{
                      value && value.paymentChannel ? prepareDetail(value.paymentChannel) : "N/A"
                    }}
                  </p>
                  <p>
                    <strong>Cashier/Sales Person:</strong>
                    {{ (value && value.maker && value.maker.displayName) || "N/A" }}
                  </p>
                </div>
                <div class="col-md-6 text-right" v-if="!showId">
                  <p>
                    <strong>Payment Type:</strong>
                    {{ value && value.paymentType }}
                  </p>
                </div>
              </div>
              <div class="py-2" style="border-top: 1px solid rgba(55, 55, 55, 0.5);">
                <p style="font-size: 14px; margin: 0">Notes or Comments</p>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-6">
                  <img src="https://app.wekurnect.ng/assets/img/logo.png" alt="img" />
                </div>
                <div class="col-md-6 text-right">
                  Receipt generated from weKurnect
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { helper } from "@/helper";
export default {
  name: "posting-receipt",
  props: ["receiptData", "isPostPaymentPage"],
  components: {},

  data() {
    return {
      value: this.receiptData,
      showId: this.isPostPaymentPage,
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  watch: {
    receiptData: function(value) {
      this.value = this.receiptData;
      this.showId = this.isPostPaymentPage;
    },
  },

  mounted() {
    this.value = this.receiptData;
    this.showId = this.isPostPaymentPage;
  },

  methods: {
    formatMoney(val) {
      return helper.formatMoney(val);
    },

    formatDate(date) {
      return moment(date).format("ll");
    },

    prepareDetail(value) {
      const name = value.paymentChannelTypeName;
      let result = "";
      if (name) {
        result += name;
        if (name.toLowerCase() === "bank") {
          result += "/ " + value.bankName + "/ " + value.accountNumber;
        } else if (name.toLowerCase() === "pos") {
          result += "/ " + value.bankName + "/ " + value.terminalId;
        } else if (name.toLowerCase() === "cryptocurrency") {
          result += "/ " + value.bankName + "/ " + value.walletAddress;
        }
      }
      return result;
    },
  },
};
</script>
