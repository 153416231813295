<template>
  <div class="row">
    <div class="col">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header d-flex justify-content-between">
          <p class="card-title">
            Manage Group Options for {{ distributor.name }}
          </p>
          <div class="btn-group" role="group" aria-label="Basic example">
            <button
                    v-if="hasPermission('Group_Create')"
              class="btn btn-primary btn-rounded btn-space"
              @click="addRrp"
            >
              <i class="icon icon-left mdi mdi-plus text-white"></i>
              Add Group
            </button>
            <button class="btn btn-info btn-rounded btn-space" @click="filter">
              <i class="feather icon-filter text-white"></i>
              Filter
            </button>
            <button
              class="btn btn-success btn-rounded btn-space"
              @click="goBack"
            >
              <i class="mdi icon mdi-backburger text-white"></i>
              Back
            </button>
          </div>
        </div>
        <div class="card-body">
          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Added</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="group.id">
                      <td>{{ group.name }}</td>
                      <td>{{ group.description }}</td>
                      <td>{{ formatDate(group.created) }}</td>
                      <td>
                        <span
                          :class="[
                            'badge',
                            ' badge-pill',
                            { 'badge-success': group.active },
                            { 'badge-danger': !group.active },
                          ]"
                        >
                          {{ group.active ? "Active" : "Inactive" }}
                        </span>
                      </td>
                      <td>
                        <div class="d-flex">
                          <button
                            v-if="hasPermission('Group_View_Members')"
                            data-toggle="tooltip"
                            title="Edit"
                            class="
                              btn btn-success btn-sm btn-rounded btn-space
                              mr-1
                            "
                            @click.prevent="view(group.id)"
                          >
                            View Association
                          </button>
                          <DeleteMember v-if="hasPermission('Group_Remove_Members')"
                            :id="distributorId"
                            @onComplete="onActionComplete"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddDistributorGroup :distributorId="distributorId" @hide="onComplete" />
    <FilterGroup @onFilter="performFiltering" />
    <ViewAssociation :groupId="groupId" />
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import AddDistributorGroup from "../components/ManageGroup/AddDistributorGroup";
import DeleteMember from "../components/ManageGroup/DeleteMember";
import FilterGroup from "../components/ManageGroup/FilterGroup";
    import ViewAssociation from "../components/ManageGroup/ViewAssociation";
    import SystemPermissions from "@/permissions";

    export default {
        mixins: [SystemPermissions.Mixin],
  components: {
    AddDistributorGroup,
    DeleteMember,
    FilterGroup,
    ViewAssociation,
  },

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      distributorId: "",
      groupId: "",
      distributor: {},
      group: {},
      name: "",
      active: "",
      showDeactivated: true,
    };
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },

        created() {
            if (!this.hasPermission("Group_View")) {
                this.$router.push("/restricted");
            }
    this.distributorId = this.$route.params.id;
    this.getDistributor();
    this.getGroup();
  },

  methods: {
    async getDistributor() {
      this.isBusy = true;
      try {
        const response = await this.$http.get(
          "/Accounts/getById/" + this.distributorId
        );
        if (response.ok) this.distributor = response.body;
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    async getGroup() {
      try {
        this.isBusy = true;
        this.group = {};
        const response = await this.$http.get(
          "/Groups/getMemberGroup/" + this.distributorId
        );

        if (response.ok && response.status === 200) {
          this.group = response.body;
        }
      } catch (error) {
        this.isBusy = false;
      }
    },

    hideModal() {
      $("#mod-add-group").modal("hide");
    },

    onActionComplete() {
      this.getGroup();
    },

    onComplete() {
      this.hideModal();
      this.getGroup();
    },

    addRrp() {
      $("#mod-add-group").modal("show");
    },

    filter() {
      $("#mod-gp-filter").modal("show");
    },

    view(id) {
      $("#mod-view-association").modal("show");
      this.groupId = id;
    },

    async performFiltering(filteringOptions) {
      // console.log(filteringOptions);
      this.name = filteringOptions.name;
      this.active =
        filteringOptions.active == "empty"
          ? ""
          : filteringOptions.active == "true";

      this.getGroup();
      $("#mod-gp-filter").modal("hide");
    },

    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },

    formatDate(date) {
      if (date) return moment(date).format("lll");
      else return "";
    },
  },
};
</script>

<style>
</style>
