<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-expense-caution"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-warning">
          <h4 class="modal-title p-1">
            <i class="fa fa-warning"></i>
            <strong class="ml-1">Caution</strong>
          </h4>
          <button
            type="button"
            class="close md-close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p class="font-weight-bold text-center">
            {{ text }}
          </p>
        </div>
        <div
          class="modal-footer d-flex justify-content-center align-items-center"
        >
          <button
            class="btn btn-secondary"
            data-dismiss="modal"
            aria-label="Close"
          >
            No
          </button>
          <button class="btn btn-success" type="button" @click.prevent="accept">
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadCautionModal",
  props: ["text", "action"],

  methods: {
    accept() {
      if (this.action === "remove") this.$emit("proceedToRemove");
      if (this.action === "upload") this.$emit("proceedToUpload");
    },
  },
};
</script>

<style>
</style>