<template>
  <div
    v-if="isModalVisible"
    class="fixed inset-0 bg-[rgba(0,0,0,0.4)] z-[1111111] flex justify-end overflow-y-auto"
    id="popup-content"
    @click.self="close"
  >
    <div
      class="relative w-full lg:w-[80%] max-h-screen bg-[#f8f8f8] p-[40px] overflow-auto"
    >
      <button
        class="absolute right-12 top-12 cursor-pointer z-10"
        type="button"
        @click="close"
      >
        <span class="mdi text-xl mdi-close"></span>
      </button>
      <p
        class="text-[#353535] text-[20px] leading-[20px] font-semibold capitalize"
      >
        Role Users
      </p>
      <div class="flex items-center justify-between w-full my-[16px]">
        <div>
          <div class="text-[14px] leading-[20px] font-medium text-[#353535]">
            {{
              count === 0 ? "0 user" : `${count} user${count !== 1 ? "s" : ""}`
            }}
          </div>
        </div>
        <button
          class="bg-[#DC2828] px-[16px] md:px-[24px] py-[12px] font-medium text-[14px] leading-[16.94px] text-white rounded-[4px]"
          @click="removeAll"
          v-if="hasPermission('Role_User_Delete')"
        >
          Remove All Users
        </button>
      </div>
      <div class="table-responsive">
        <table class="table p-0 m-0 dataTable no-footer">
          <thead class="bg-white">
            <tr class="py-[5px]">
              <th
                class="text-[12px] leading-[18px] text-[#000000] font-semibold"
              >
                Name
              </th>
              <th
                class="text-[12px] leading-[18px] text-[#000000] font-semibold"
              >
                Email
              </th>
              <th
                class="text-[12px] leading-[18px] text-[#000000] font-semibold"
              >
                Phone Number
              </th>
              <th
                class="text-[12px] leading-[18px] text-[#000000] font-semibold"
              >
                Status
              </th>
              <th
                class="text-[12px] leading-[18px] text-[#000000] font-semibold"
              >
                Created
              </th>
              <th
                class="text-[12px] leading-[18px] text-[#000000] font-semibold"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr v-for="u in roleUsers" :key="u.id">
              <td>
                <span
                  class="text-[14px] font-medium leading-[16.94px] text-[#353535]"
                  v-if="u.displayName"
                >
                  {{ u.displayName }}
                </span>
              </td>
              <td
                class="text-[14px] font-medium leading-[16.94px] text-[#353535]"
              >
                {{ u.emailAddress }}
              </td>
              <td
                class="text-[14px] font-medium leading-[16.94px] text-[#353535]"
              >
                {{ u.phoneNumber }}
              </td>
              <td>
                <span
                  class="flex items-center gap-[4px] max-w-max w-full"
                  :class="[
                    'badge',
                    {
                      'badge-success': u.status === 'Active',
                      'badge-danger': u.status === 'Disabled',
                      'badge-primary': u.status === 'Pending',
                    },
                  ]"
                >
                  <span
                    class="w-[10px] h-[10px] rounded-[1px]"
                    :style="{
                      backgroundColor:
                        u.status === 'Active'
                          ? '#16a249'
                          : u.status === 'Disabled'
                          ? '#EF5253'
                          : '#7367F0',
                    }"
                  ></span>

                  <span class="text-[12px] font-normal leading-[16.94px]">
                    {{ u.status === "Pending" ? "Invitation sent" : u.status }}
                  </span>
                </span>
              </td>
              <td class="text-[14px] font-normal leading-[16.94px]">
                {{ formatDate(u.created) }}
              </td>
              <td>
                <div v-if="hasPermission('Role_User_Delete')">
                  <span
                    v-if="u.parentId == user.parentId"
                    class="cursor-pointer text-[14px] font-normal leading-[16.94px] text-[#DC2828]"
                    @click.prevent="removeModal(u)"
                    >Remove</span
                  >
                </div>
              </td>
            </tr>
            <tr v-if="!roleUsers.length">
              <td colspan="5">
                <div class="text-center" style="padding-top: 50px">
                  <span
                    style="font-size: 4.615rem"
                    class="mdi mdi-crosshairs-gps"
                  ></span>
                </div>
                <h3 class="text-center" style="padding-bottom: 100px">
                  You don't have any users assigned to this role.
                </h3>
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination
          :limit="pageSize"
          :count="pageCount"
          :pageNumber="pageNumber"
          :itemsCount="count"
          @changePage="gotoPage"
          @changeLimit="changeLimit"
        />
      </div>
    </div>

    <div
      class="modal bg-[rgba(0, 0, 0, 1)] fade py-1 z-[111111111]"
      id="mod-remove"
      role="dialog"
    >
      <div class="modal-dialog mx-auto">
        <div class="modal-content p-[24px] bg-[#f8f8f8]">
          <div class="modal-header flex items-start justify-between p-0 m-0">
            <h4
              class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
            >
              Remove user
            </h4>
            <button
              class="text-2xl text-[#353535]"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body m-0 p-0">
            <p class="text-[14px] leading-[20px] font-normal text-[#353535]">
              Are you sure you want to remove this user from this role?
              <span class="font-semibold"></span>
            </p>
          </div>
          <div class="flex items-center gap-[8px] mt-[16px]">
            <button
              class="border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full outline-none focus:outline-none"
              type="button"
              data-dismiss="modal"
              @click="selectedUser = null"
            >
              Cancel
            </button>
            <button
              class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] w-full outline-none focus:outline-none"
              type="button"
              :disabled="isSearching"
              @click="confirmRemove"
            >
              <i v-if="isSearching" class="fas fa-spinner fa-spin mr-2"></i>

              {{ isSearching ? "Busy..." : "Yes" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import Select2 from "@/components/Select2";
import SystemPermissions from "@/permissions";
import Pagination from "../Pagination.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsRoleUsers",
  props: ["roleInfo", "roleUsers", "close"],
  components: {
    Select2,
    Pagination,
  },
  created() {
    if (!this.hasPermission("Role_User_View")) {
      this.$router.push("/restricted");
    }
    // this.getRoleUsers();
  },
  watch: {},
  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      isSearching: false,
      isModalVisible: true,
      selectedUser: null,
      count: 0,
      page: 0,
      pageNumber: 0,
      pageSize: 10,
      hasNext: false,
      hasPrev: false,
    };
  },
  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
    backPath: function() {
      return this.$store.state.prevRoute;
    },
    // checkRoleUser() {
    //   this.getRoleUsers();
    //   return "";
    // },
  },
  methods: {
    removeModal(user) {
      this.selectedUser = user;
      $("#mod-remove").modal("show");
    },
    async confirmRemove() {
      if (!this.selectedUser) return;

      var roleId = this.roleInfo.id;
      this.isBusyModal = true;
      this.isBusy = true;

      await this.$http
        .put("/Roles/unassign/" + roleId + "/" + this.selectedUser.id, null)
        .then(() => {
          this.isBusyModal = false;
          this.getRoleUsers();
          this.selectedUser = null;
        })
        .catch(() => {
          this.isBusyModal = false;
        });

      this.isBusy = false;
      $("#mod-remove").modal("hide");
    },
    closeModal() {
      this.isModalVisible = false;
    },
    async removeAll() {
      var roleId = this.roleInfo.id;
      //var roleId = this.$route.query.r
      if (
        confirm("Are you sure you want to remove all users from this role?")
      ) {
        this.isBusyModal = true;
        this.isBusy = true;

        await this.$http
          .put("/Roles/unassignAll/" + roleId, null)
          .then(function(res) {
            this.isBusyModal = false;
          })
          .catch(function() {});

        this.getRoleUsers();

        this.isBusy = false;
      }
    },
    async getRoleUsers() {
      var roleId = this.roleInfo.id;
      //var roleId = this.$route.query.r
      this.isBusy = true;

      await this.$http
        .get(
          "/Roles/getAssignedUserList?page=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            ("&roleId=" + roleId)
        )
        .then(function(res) {
          this.roleUsers = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },
    formatDate(date) {
      return moment(date).format("ll");
    },
    formatMoney(val) {
      return helper.formatMoney(val);
    },
    prev() {
      this.page -= 1;
      this.getRoleUsers();
    },
    next() {
      this.page += 1;
      this.getRoleUsers();
    },
    gotoPage(page) {
      this.page = page - 1;
      this.getUsers();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getUsers();
    },
  },
};
</script>
