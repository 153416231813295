<template>
  <div
    class="bg-[#FBFBFB] border py-[12px] px-[1rem] flex items-center justify-between flex-wrap gap-[5px]"
    v-if="itemsCount > 0"
  >
    <div class="flex items-center space-x-2 mb-[5px] md:mb-0">
      <p>Showing</p>
      <select
        class="border border-gray-800 p-[2px] rounded-[4px]"
        v-model="mutateLimit"
        @change="onChange($event)"
      >
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
      <p>records</p>
    </div>

    <paginate
      v-model="mutatePage"
      v-if="pageCount > 1"
      :page-count="pageCount"
      :page-range="3"
      :margin-pages="1"
      :click-handler="clickCallback"
      :container-class="'pagination flex space-x-2'"
      :page-class="
        'page-item flex items-center justify-center font-normal text-[#353535] px-[6px]'
      "
      :active-class="'font-bold border border-solid border-black'"
      prev-text=""
      next-text=""
    >
    </paginate>

    <div class="flex items-center space-x-1">
      <button
        class="disabled:opacity-50 disabled:cursor-not-allowed text-[#344054] bg-white border border-solid border-[#D0D5DD] rounded-[6px] px-[10px] sm:px-[17px] py-[4px] shadow-sm"
        @click="prevPage"
        :disabled="mutatePage <= 1"
      >
        Previous
      </button>
      <button
        class="disabled:opacity-50 disabled:cursor-not-allowed text-[#344054] bg-white border border-solid border-[#D0D5DD] rounded-[6px] px-[10px] sm:px-[17px] py-[4px] shadow-sm"
        @click="nextPage"
        :disabled="mutatePage >= pageCount"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";

export default {
  name: "Pagination",
  props: ["limit", "count", "page", "itemsCount"],
  components: {
    Paginate,
  },

  data() {
    return {
      mutateLimit: this.limit,
      mutatePage: this.page,
      pageCount: this.count,
    };
  },

  watch: {
    count: {
      immediate: true,
      handler() {
        this.pageCount = this.count;
      },
    },
  },

  methods: {
    onChange(event) {
      this.$emit("changeLimit", event.target.value);
    },

    clickCallback(pageNum) {
      this.$emit("changePage", pageNum);
    },

    nextPage() {
      if (this.mutatePage < this.pageCount) {
        this.mutatePage++;
        this.$emit("changePage", this.mutatePage);
      }
    },

    prevPage() {
      if (this.mutatePage > 1) {
        this.mutatePage--;
        this.$emit("changePage", this.mutatePage);
      }
    },
  },
};
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
}
.page-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: 1px solid #ddd;
}

.page-item.active {
  background-color: #3b82f6;
  color: white;
}
</style>
