var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu s menu-fixed menu-light menu-accordion menu-shadow",attrs:{"id":"sidebar","data-scroll-to-active":"true"}},[_vm._m(0),_c('div',{staticClass:"main-menu-content"},[_c('ul',{staticClass:"navigation navigation-main",attrs:{"id":"main-menu-navigation","data-menu":"menu-navigation"}},[_c('li',{class:[
          { parent: _vm.user.role == 'Customer' },
          { active: _vm.isDashboardActive } ]},[_c('router-link',{attrs:{"to":"/dashboard"}},[_c('i',[_c('img',{staticStyle:{"max-width":"none !important"},attrs:{"src":"/assets/img/category.svg","alt":""}})]),_c('span',{staticClass:"text-[#353535] text-[16px] leading-[19.67px] font-medium"},[_vm._v("Dashboard")])])],1),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.user.role == 'Customer'),expression:"user.role == 'Customer'"}],class:[
          { parent: _vm.user.role == 'Customer' },
          { active: _vm.isOrdersParentActive },
          {
            open:
              _vm.isOrdersParentActive ||
              _vm.isOrdersSentActive ||
              _vm.isOrdersCreateActive ||
              _vm.isOrdersDraftActive,
          } ]},[_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.handleParentMenuClick('Orders')}}},[_c('i',{staticClass:"feather icon-shopping-cart"}),_c('span',{staticClass:"text-[#353535] text-[16px] leading-[19.67px] font-medium"},[_vm._v("Orders")])]),_c('ul',{staticClass:"menu-content"},[_c('li',{class:{ active: _vm.isOrdersSentActive }},[_c('router-link',{attrs:{"to":"/merchant/orders"}},[_vm._v("My Orders")])],1),_c('li',{class:{ active: _vm.isOrdersCreateActive }},[_c('router-link',{attrs:{"to":"/merchant/orders/create"}},[_vm._v("New Order")])],1),_c('li',{class:{ active: _vm.isOrdersDraftActive }},[_c('router-link',{attrs:{"to":"/merchant/drafts"}},[_vm._v("Drafts")])],1)])],1),(_vm.user.role == 'Customer')?_c('li',{class:{ active: _vm.isGrowTradeActive }},[_c('router-link',{attrs:{"to":"/merchant/growtrade"}},[_vm._v("GrowTrade Account")])],1):_vm._e(),(
          !(_vm.user.type != 'Distributor' || _vm.user.role == 'Customer') &&
            (_vm.hasPermission('Orders_View') || _vm.hasPermission('Orders_Create'))
        )?_c('li',{class:[
          {
            parent:
              _vm.user.role == 'Administrator' ||
              _vm.user.role == 'Support Specialist' ||
              _vm.user.role == 'Sales',
          },
          {
            active:
              _vm.isOrdersParentActive ||
              _vm.isOrdersActive ||
              _vm.isOrdersCreateAltActive ||
              _vm.isOrdersCreateAltPayActive ||
              _vm.isIssuedStock,
          },
          {
            open:
              _vm.isOrdersParentActive ||
              _vm.isOrdersActive ||
              _vm.isOrdersCreateAltActive ||
              _vm.isOrdersCreateAltPayActive ||
              _vm.isIssuedStock,
          } ]},[_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.handleParentMenuClick('Orders')}}},[_c('i',{staticClass:"feather icon-shopping-cart"}),_c('span',[_vm._v("Manage Orders")])]),(
            _vm.hasPermission('Orders_View') || _vm.hasPermission('Orders_Create')
          )?_c('ul',{staticClass:"menu-content"},[(_vm.hasPermission('Orders_View'))?_c('li',{class:{ active: _vm.isOrdersActive }},[_c('router-link',{attrs:{"to":"/orders"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("  View Orders ")])],1):_vm._e(),(_vm.hasPermission('Orders_Create'))?_c('li',{class:{ active: _vm.isOrdersCreateAltActive }},[_c('router-link',{attrs:{"to":"/orders/create"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("  New Order ")])],1):_vm._e(),(_vm.hasPermission('Orders_Create'))?_c('li',{class:{ active: _vm.isOrdersCreateAltPayActive }},[_c('router-link',{attrs:{"to":"/orders/create?pay4me=true"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("  Pay4Me Order ")])],1):_vm._e(),_c('li',{class:{ active: _vm.isIssuedStock }},[_c('router-link',{attrs:{"to":"/orders/issue-stock"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("  Issue Stock ")])],1)]):_vm._e()],1):_vm._e(),(
          (_vm.user.type == 'Distributor' &&
            _vm.user.role != 'Customer' &&
            _vm.user.role != 'Sales' &&
            _vm.hasPermission('Account_Posting')) ||
            _vm.hasPermission('Payment_Posting')
        )?_c('li',{class:[
          'parent',
          { active: _vm.isManageCustomerAccountParentActive },
          {
            open:
              _vm.isManageCustomerAccountParentActive ||
              _vm.isManagePaymentsActive ||
              _vm.isManagePaymentsNotify ||
              _vm.isAccountPosting,
          } ]},[(!_vm.user.isSalesUser)?_c('a',{attrs:{"href":"javascript:void"},on:{"click":function($event){$event.preventDefault();return _vm.handleParentMenuClick('CustomerAccount')}}},[_c('i',{staticClass:"feather icon-book-open"}),_c('span',[_vm._v("Manage Payment")])]):_vm._e(),_c('ul',{staticClass:"sub-menu"},[(_vm.hasPermission('Account_Posting'))?_c('li',{class:{ active: _vm.isAccountPosting }},[_c('router-link',{attrs:{"to":"/accountposting"}},[_c('i',{staticClass:"feather icon-user"}),_c('span',[_vm._v("Account Posting")])])],1):_vm._e(),(_vm.hasPermission('Payment_Posting'))?_c('li',{class:{ active: _vm.isManagePaymentsActive }},[_c('router-link',{attrs:{"to":"/managepayments"}},[_c('i',{staticClass:"feather icon-credit-card"}),_c('span',[_vm._v("Post Payments")])])],1):_vm._e(),(_vm.hasPermission('View_View_Payments_Notifications'))?_c('li',{class:{ active: _vm.isManagePaymentsNotify }},[_c('router-link',{attrs:{"to":"/payment-notification"}},[_c('i',{staticClass:"feather icon-credit-card"}),_c('span',[_vm._v("Payments Notifica... "),(_vm.PaymentNotificationCount > 0)?_c('span',{staticClass:"badge badge-danger",staticStyle:{"border-radius":"50%","padding":"4px"}},[_vm._v(_vm._s(_vm.PaymentNotificationCount >= 10 ? "10+" : _vm.PaymentNotificationCount))]):_vm._e()])])],1):_vm._e()])]):_vm._e(),(_vm.user.type == 'System')?_c('li',{class:[
          'parent',
          { active: _vm.isManufacturersActive },
          { open: _vm.isManufacturersActive } ]},[_c('router-link',{attrs:{"to":"/manufacturers"}},[_c('i',{staticClass:"feather icon-user"}),_c('span',[_vm._v("Manufacturers")])]),_c('ul',{staticClass:"sub-menu"},[_c('li',{class:{ active: _vm.isManufacturersCreateActive }},[_c('router-link',{attrs:{"to":"/manufacturers/create"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("   New Manufacturer ")])],1)])],1):_vm._e(),(_vm.user.type == 'System')?_c('li',{class:['parent', { active: _vm.isStatisticsActive }]},[_c('router-link',{attrs:{"to":"/statistics"}},[_c('i',{staticClass:"feather icon-bar-chart"}),_c('span',[_vm._v("Statistics")])])],1):_vm._e(),(_vm.user.type == 'System')?_c('li',{class:[
          'parent',
          { active: _vm.isDistributorsActive },
          { open: _vm.isDistributorsActive } ]},[_c('router-link',{attrs:{"to":"/distributors"}},[_c('i',{staticClass:"icon mdi mdi-truck-fast"}),_c('span',[_vm._v("Distributors")])]),_c('ul',{staticClass:"sub-menu"},[_c('li',{class:{ active: _vm.isDistributorsCreateActive }},[_c('router-link',{attrs:{"to":"/distributors/create"}},[_vm._v("New Distributor")])],1)])],1):_vm._e(),(
          !_vm.user.isSalesUser &&
            _vm.user.type == 'Manufacturer' &&
            (_vm.hasPermission('Distributor_View') ||
              _vm.hasPermission('Distributor_Create'))
        )?_c('li',{class:[
          'parent',
          { active: _vm.isManufacturerDistributorsParentActive },
          {
            open:
              _vm.isManufacturerDistributorsParentActive ||
              _vm.isManufacturerDistributorsCreateActive ||
              _vm.isManufacturerDistributorsActive,
          } ]},[_c('router-link',{attrs:{"to":"/manufacturer-distributors"}},[_c('i',{},[_c('img',{staticStyle:{"max-width":"none !important"},attrs:{"src":"/assets/img/profile-2user.svg","alt":""}})]),_c('span',{staticClass:"text-[#353535] text-[16px] leading-[19.67px] font-medium"},[_vm._v("Distributors")])])],1):_vm._e(),(
          !_vm.user.isSalesUser &&
            _vm.user.type == 'Manufacturer' &&
            _vm.hasPermission('Group_View')
        )?_c('li',{class:[
          'parent',
          { active: _vm.isManufacturerGroupActive },
          { open: _vm.isManufacturerGroupActive } ]},[_c('router-link',{attrs:{"to":"/groups"}},[_c('i',{},[_c('img',{staticStyle:{"max-width":"none !important"},attrs:{"src":"/assets/img/people.svg","alt":""}})]),_c('span',{staticClass:"text-[#353535] text-[16px] leading-[19.67px] font-medium"},[_vm._v("Groups")])])],1):_vm._e(),(
          !_vm.user.isSalesUser &&
            _vm.user.type == 'Manufacturer' &&
            _vm.hasPermission('Broadcast_Message_View')
        )?_c('li',{class:['parent', { active: _vm.isManufacturerBroadcastParentActive }]},[_c('router-link',{attrs:{"to":"/manufacturer-broadcast"}},[_c('i',[_c('img',{staticStyle:{"max-width":"none !important"},attrs:{"src":"/assets/img/volume-mute.svg","alt":""}})]),_c('span',{staticClass:"text-[#353535] text-[16px] leading-[19.67px] font-medium"},[_vm._v("Broadcast")])])],1):_vm._e(),(
          !_vm.user.isSalesUser &&
            _vm.user.type == 'Manufacturer' &&
            (_vm.hasPermission('Product_View') ||
              _vm.hasPermission('Product_Category_View') ||
              _vm.hasPermission('Product_Unit_View'))
        )?_c('li',{class:['parent', { active: _vm.isManufacturerProductParentActive }]},[_c('router-link',{attrs:{"to":"/manufacturer-products"}},[_c('i',{},[_c('img',{staticStyle:{"max-width":"none !important"},attrs:{"src":"/assets/img/tag.svg","alt":""}})]),_c('span',{staticClass:"text-[#353535] text-[16px] leading-[19.67px] font-medium"},[_vm._v("Products")])])],1):_vm._e(),(
          !_vm.user.isSalesUser &&
            !_vm.user.isSalesUser &&
            (_vm.hasPermission('User_View') || _vm.hasPermission('Role_View'))
        )?_c('li',{class:{ active: _vm.isPaymentActive }},[_c('router-link',{attrs:{"to":"/settings/team"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.handleParentMenuClick('Users')}}},[_c('i',{},[_c('img',{staticStyle:{"max-width":"none !important"},attrs:{"src":"/assets/img/profile.svg","alt":""}})]),_c('span',{staticClass:"text-[#353535] text-[16px] leading-[19.67px]\n            font-medium"},[_vm._v("User Management")])])],1):_vm._e(),(
          !_vm.user.isSalesUser &&
            !_vm.user.isSalesUser &&
            (_vm.hasPermission('User_View') || _vm.hasPermission('Role_View'))
        )?_c('li',{class:['parent', { active: _vm.isSettingsActive }]},[_c('router-link',{attrs:{"to":"/settings/business"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.handleParentMenuClick('Settings')}}},[_c('i',{},[_c('img',{staticStyle:{"max-width":"none !important"},attrs:{"src":"/assets/img/setting-2.svg","alt":""}})]),_c('span',{staticClass:"text-[#353535] text-[16px] leading-[19.67px] font-medium"},[_vm._v("Settings")])])],1):_vm._e(),(
          !_vm.user.isSalesUser &&
            _vm.user.type == 'Manufacturer' &&
            _vm.hasPermission('Sales_Order_View')
        )?_c('li',{class:['parent', { active: _vm.isPurchaseOrderParentActive }]},[_c('router-link',{attrs:{"to":"/sales-order"}},[_c('i',[_c('img',{staticStyle:{"max-width":"none !important"},attrs:{"src":"/assets/img/category.svg","alt":""}})]),_c('span',{staticClass:"text-[#353535] text-[16px] leading-[19.67px] font-medium"},[_vm._v("Sales Order")])])],1):_vm._e(),(
          !_vm.user.isSalesUser &&
            _vm.user.role != 'Customer' &&
            _vm.hasPermission('Report_View')
        )?_c('li',{class:['parent', { active: _vm.isReportsActive }]},[_c('router-link',{attrs:{"to":_vm.user.type == 'Manufacturer' && _vm.hasPermission('Report_View')
              ? "/reports/enterprise"
              : _vm.user.type == 'Distributor' && _vm.hasPermission('Report_View')
              ? "/reports/distributor"
              : "/"}},[_c('i',{},[_c('img',{staticStyle:{"max-width":"none !important"},attrs:{"src":"/assets/img/presention-chart.svg","alt":""}})]),_c('span',{staticClass:"text-[#353535] text-[16px] leading-[19.67px] font-medium"},[_vm._v("Reports & Analytics")])])],1):_vm._e(),(
          _vm.user.isSalesUser &&
            (_vm.user.role.isDistributorManager ||
              _vm.user.role.isStateManager ||
              _vm.user.role.isGroupManager)
        )?_c('li',{class:[
          'parent',
          { active: _vm.isSaleOrderParentActive },
          {
            open:
              _vm.isSaleOrderParentActive || _vm.isSaleOrderActive || _vm.isMySalesActive,
          } ]},[_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.handleParentMenuClick('SaleOrder')}}},[_c('i',{staticClass:"feather icon-list"}),_c('span',{staticClass:"text-[#353535] text-[16px] leading-[19.67px] font-medium"},[_vm._v("Sales Order")])]),_c('ul',{staticClass:"sub-menu"},[_c('li',{class:{ active: _vm.isSaleOrderActive }},[_c('router-link',{attrs:{"to":"/salesOrder"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("  View Sales Orders ")])],1),_c('li',{class:{ active: _vm.isMySalesActive }},[_c('router-link',{attrs:{"to":"/mySales"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("  My Sales ")])],1)])],1):_vm._e(),(
          _vm.user.isSalesUser &&
            (_vm.user.role.isDistributorManager ||
              _vm.user.role.isStateManager ||
              _vm.user.role.isGroupManager)
        )?_c('li',{class:[
          'parent',
          { active: _vm.isManufacturerProductParentActive },
          {
            open:
              _vm.isManufacturerProductParentActive ||
              _vm.isManufacturerProductsActive,
          } ]},[_c('a',{attrs:{"href":"javascript:void"},on:{"click":function($event){$event.preventDefault();return _vm.handleParentMenuClick('ManufacturerProduct')}}},[_c('i',{staticClass:"feather icon-tag"}),_c('span',{staticClass:"text-[#353535] text-[16px] leading-[19.67px] font-medium"},[_vm._v("Products")])]),_c('ul',{staticClass:"sub-menu"},[(_vm.hasPermission('Product_View'))?_c('li',{class:{ active: _vm.isManufacturerProductsActive }},[_c('router-link',{attrs:{"to":"/manufacturer-products"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("   View Products ")])],1):_vm._e()])]):_vm._e(),(
          _vm.user.isSalesUser &&
            (_vm.user.role.isDistributorManager ||
              _vm.user.role.isStateManager ||
              _vm.user.role.isGroupManager)
        )?_c('li',{class:[
          'parent',
          { active: _vm.isDistributorDirectoryParentActive },
          {
            open:
              _vm.isDistributorDirectoryParentActive ||
              _vm.isDistributorDirectoryActive,
          } ]},[_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.handleParentMenuClick('DistributorDirectory')}}},[_c('i',{staticClass:"feather icon-speaker"}),_c('span',[_vm._v("Distributor Directory")])]),_c('ul',{staticClass:"sub-menu"},[_c('li',{class:{ active: _vm.isDistributorDirectoryActive }},[_c('router-link',{attrs:{"to":"/sales-user-distributors"}},[_c('i',{staticClass:"feather icon-circle"}),_vm._v("   View Distributors ")])],1)])],1):_vm._e(),_c('li',{staticClass:"md:mt-[70px] 3xl:mt-[150px]"},[_c('router-link',{attrs:{"to":"#"},on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_c('i',{staticClass:"fa-solid fa-right-from-bracket"}),_c('span',[_vm._v("Logout")])])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar-header"},[_c('ul',{staticClass:"nav navbar-nav flex-row justify-end"},[_c('li',{staticClass:"nav-item nav-toggle"},[_c('a',{staticClass:"nav-link modern-nav-toggle pr-0",attrs:{"data-toggle":"collapse"}},[_c('i',{staticClass:"feather icon-x d-block d-xl-none font-medium-4 primary toggle-icon"}),_c('i',{staticClass:"toggle-icon feather icon-disc font-medium-4 d-none d-xl-block collapse-toggle-icon primary",attrs:{"data-ticon":"icon-disc"}})])])])])}]

export { render, staticRenderFns }