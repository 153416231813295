<template>
  <div
    v-if="isModalVisible"
    class="fixed inset-0 bg-[rgba(0,0,0,0.4)] z-[11111] flex justify-end overflow-y-auto"
    id="popup-content"
    @click.self="closeModal"
  >
    <div
      class="relative w-full lg:w-[80%] max-h-screen bg-[#f8f8f8] p-[12px] md:p-[40px] overflow-auto"
    >
      <div class="shadow-none bg-transparent">
        <div class="flex items-center justify-between">
          <p class="text-[#353535] text-[20px] leading-[20px] font-semibold">
            Create Broadcast Message
          </p>
          <button
            class="text-[#353535] text-2xl cursor-pointer"
            type="button"
            @click="closeModal"
          >
            <span class="mdi text-xl mdi-close"></span>
          </button>
        </div>
        <div
          class="w-full bg-white rounded-[8px] mt-[16px] px-[24px] py-[32px]"
        >
          <h3 class="text-[#353535] text-[14px] leading-[20px] font-semibold">
            Create Broadcast Message
          </h3>
          <p
            class="text-[#353535] text-[14px] my-[24px] leading-[20px] font-semibold"
          >
            Send To
          </p>
          <div>
            <div class="md:w-[80%]">
              <div class="grid md:grid-cols-2 gap-[24px]">
                <div class="flex flex-col gap-[4px] w-full">
                  <label
                    class="text-[#353535] text-[14px] font-medium leading-[20px]"
                    >Recipients</label
                  >
                  <select
                    name="userType"
                    v-model="content.userType"
                    class="border border-[#D0D5DD] px-[14px] py-[12px] rounded-[8px] w-full text-[#96A0B5] outline-none custom-select custom-select-lg"
                  >
                    <option value="" disabled selected>
                      Select Recipients
                    </option>
                    <option value="0">Distributors only</option>
                    <option value="1">Merchant only</option>
                    <option value="2">Sales Team</option>
                  </select>
                </div>
                <div class="flex flex-col gap-[4px] w-full">
                  <label
                    class="text-[#353535] text-[14px] font-medium leading-[20px]"
                    >Title</label
                  >
                  <input
                    type="text"
                    v-model="content.title"
                    :class="[{ 'is-invalid': v1.title }]"
                    placeholder="Enter broadcast title"
                    class="border border-solid border-[#D0D5DD] py-[12px] px-[14px] rounded-[8px] text-[#96A0B5] text-[14px] font-normal outline-none"
                  />
                  <ul
                    v-if="v1.name"
                    class="parsley-errors-list filled text-red-500 text-xs"
                  >
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="flex flex-col gap-[4px] w-full mt-[24px]">
                <label
                  class="text-[#353535] text-[14px] font-medium leading-[20px]"
                  >Message</label
                >
                <textarea
                  :class="[{ 'is-invalid': v1.message }]"
                  class="border border-solid border-[#D0D5DD] py-[12px] px-[14px] rounded-[8px] text-[#96A0B5] text-[14px] leading-[24px] font-normal outline-none"
                  rows="6"
                  maxlength="1000"
                  v-model="content.message"
                  placeholder="Enter broadcast message"
                ></textarea>
                <span
                  class="text-[#E7B008] text-[14px] leading-[20px] font-medium mt-[6px]"
                  >{{ 1000 - content.message.length }} character(s)
                  remaining.</span
                >
                <ul
                  v-if="v1.message"
                  class="parsley-errors-list filled text-red-500 text-sm"
                >
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="md:w-[40%] md:pr-[24px] mt-[24px]">
              <div class="flex flex-col gap-[4px] w-full">
                <label
                  class="text-[#353535] text-[14px] font-medium leading-[20px]"
                  >External Link (Optional)</label
                >
                <input
                  :class="[{ 'is-invalid': v1.infoUrl }]"
                  type="text"
                  v-model="content.infoUrl"
                  placeholder="www.example.com"
                  class="border border-solid border-[#D0D5DD] py-[12px] px-[14px] rounded-[8px] text-[#96A0B5] text-[14px] font-normal outline-none"
                />
                <ul
                  v-if="v1.infoUrl"
                  class="parsley-errors-list filled text-red-500 text-xs"
                >
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="md:w-[80%]">
              <div class="grid md:grid-cols-2 gap-[24px] items-start mt-[24px]">
                <div class="flex flex-col gap-[4px] w-full">
                  <label
                    class="text-[#353535] text-[14px] font-medium leading-[20px]"
                    >Upload Image (Optional)</label
                  >
                  <input
                    type="file"
                    ref="file"
                    accept="image/*"
                    @change="previewFiles"
                    class="hidden"
                  />
                  <div
                    @click.prevent="$refs.file.click()"
                    class="border border-solid border-[#D0D5DD] py-[12px] px-[14px] rounded-[8px] text-[#96A0B5] text-[14px] font-normal outline-none"
                  >
                    <div class="flex items-center justify-between">
                      <span>Select image</span>
                      <i class="fa-regular fa-file"></i>
                    </div>
                  </div>
                  <ul
                    v-if="v1.name"
                    class="parsley-errors-list filled text-red-500 text-xs"
                  >
                    <li class="parsley-required">Required.</li>
                  </ul>
                  <div
                    v-if="attachedImageUrl"
                    class="col-12 col-sm-8 mt-[24px]"
                  >
                    <img
                      alt="business logo"
                      height="180px"
                      width="240px"
                      class="logoImage"
                      :src="`${attachedImageUrl}`"
                    />
                    <i
                      class="feather icon-x text-danger"
                      @click.prevent="removeAttachedImage"
                      style="font-weight:bold; font-size: 18px; position: absolute; top: -5px; cursor: pointer"
                    ></i>
                  </div>
                </div>
                <div
                  v-if="attachedImageUrl"
                  class="flex flex-col gap-[4px] w-full"
                >
                  <label
                    class="text-[#353535] text-[14px] font-medium leading-[20px]"
                    >Image Position</label
                  >
                  <select
                    v-model="content.imagePosition"
                    name="imagePosition"
                    class="border border-solid border-[#D0D5DD] py-[12px] px-[14px] rounded-[8px] text-[#96A0B5] text-[14px] leading-[24px] font-normal outline-none"
                  >
                    <option value="top">Above Message</option>
                    <option value="bottom">Below Message</option>
                  </select>
                  <ul
                    v-if="v1.imagePosition"
                    class="parsley-errors-list filled"
                  >
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div
            class="flex items-center justify-end gap-[8px] flex-wrap mt-[24px]"
          >
            <button
              class="border-[1px] border-[#F2F2F2] bg-[#F2F2F2] rounded-[8px] text-[#353535] font-normal border-solid px-[16px] sm:px-[24px] text-[14px] md:text-[14px] py-[12px] outline-none focus:outline-none"
              @click.prevent="sendBroadcast(0)"
            >
              Save as Draft
            </button>
            <button
              class="border-[1px] border-[#AF52DE] bg-[#AF52DE] rounded-[8px] text-white font-normal border-solid px-[16px] sm:px-[24px] text-[14px] md:text-[14px] py-[12px] outline-none focus:outline-none"
              @click.prevent="openBroadcastPreview"
            >
              Preview
            </button>
            <button
              class="border-[1px] border-[#7367F0] bg-[#7367F0] rounded-[8px] text-white font-normal border-solid px-[16px] sm:px-[24px] text-[14px] md:text-[14px] py-[12px] outline-none focus:outline-none"
              @click.prevent="openSchedule"
            >
              Broadcast Later
            </button>
            <button
              class="border-[1px] border-[#2463EB] bg-[#2463EB] rounded-[8px] text-white font-normal border-solid px-[16px] sm:px-[24px] text-[14px] md:text-[14px] px-[24px] py-[12px] outline-none focus:outline-none"
              @click.prevent="sendBroadcast(1)"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>

    <BroadcastPreview
      @closeModal="closeBroadcastPreview"
      :title="bno"
      :details="previewDetails"
      :v="previewDetails"
    ></BroadcastPreview>

    <div
      class="modal bg-[rgba(0,0,0,0.5)] fade colored-header colored-header-primary"
      id="mod-schedule"
      role="dialog"
    >
      <div class="modal-dialog max-w-[421px] w-full relative">
        <div class="modal-content p-[24px] rounded-[8px] shadow-none">
          <div class="flex justify-between items-start">
            <div class="flex flex-col gap-[16px]">
              <h4
                class="font-semibold text-[18px] leading-[21.34px] text-[#353535]"
              >
                Schedule Broadcast
              </h4>
              <span
                class="font-normal text-[16px] text-[#353535] leading-[19.36px]"
                >You can only schedule 90days ahead</span
              >
            </div>
            <button
              class="text-2xl"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              @click.prevent="closeSchedule"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="mt-[16px]">
            <div class="flex flex-col gap-[1px]">
              <label
                class="text-[14px] leading-[20px] text-[#353535] font-medium"
                >Date</label
              >
              <input
                :class="[{ 'is-invalid': isScheduleInvalid }]"
                type="date"
                :min="minDate"
                :max="maxDate"
                placeholder="Select a date"
                v-model="scheduleDate"
                class="border border-solid border-[#D0D5DD] py-[12px] px-[14px] rounded-[8px] text-[#96A0B5] text-[14px] leading-[24px] font-normal outline-none uppercase"
              />
              <ul
                v-if="isScheduleInvalid"
                class="parsley-errors-list filled mt-1"
              >
                <li class="parsley-required">
                  You must select a valid date.
                </li>
              </ul>
            </div>
          </div>

          <div class="flex items-center gap-[8px] mt-[32px] w-full">
            <button
              class="border-[1.5px] rounded-[8px] border-solid border-[#96a0b5] text-[#96a0b5] px-[24px] bg-[#FCFCFD] py-[16px] w-full text-[16px] font-medium"
              type="button"
              data-dismiss="modal"
              @click.prevent="closeSchedule"
            >
              cancel
            </button>

            <button
              class="border rounded-[8px] border-solid border-[#2463EB] bg-[#2463EB] text-white px-[24px] py-[16px] w-full text-[16px] font-medium"
              type="button"
              :disabled="isBusy"
              @click.prevent="sendBroadcast(2)"
            >
              <span v-if="!isBusy">Schedule</span>
              <span v-else>Busy...</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-cancel"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content p-2">
          <div class="modal-body">
            <p class="text-center">Do you want to stop sending this message?</p>
          </div>

          <div class="mt-2 d-flex flex-row justify-content-between">
            <button
              style="width: 100px;"
              class="btn btn-text btn-space"
              type="button"
              data-dismiss="modal"
            >
              <span class="text-danger"> No, I don't </span>
            </button>

            <button
              style="width: 100px;"
              class="btn btn-text btn-space"
              type="button"
              @click.prevent="cancelBroadcast"
            >
              <span>Yes, I do</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { helper } from "@/helper";
import moment from "moment";
import Pagination from "../../components/Pagination.vue";
import BroadcastPreview from "../../components/Broadcast/BroadcastPreview.vue";

export default {
  name: "Enterprise-Broadcast",
  components: {
    Pagination,
    BroadcastPreview,
  },
  props: {
    isModalVisible: {
      type: Boolean,
      default: false,
    },
  },
  mounted: function() {
    this.minDate = helper.preventFutureDateSelection();
    let calMaxDate = moment(this.minDate)
      .add(90, "days")
      .toDate();
    this.maxDate = moment(calMaxDate).format("YYYY-MM-DD");

    let messageId = this.$route.params.id;
    if (messageId) {
      this.fetchBroadcastById(messageId);
      this.isUpdating = true;
    }
  },

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      isUpdating: false,
      minDate: "",
      maxDate: "",
      bno: "2",
      content: {
        id: "",
        title: "",
        message: "",
        imagePosition: "",
        infoUrl: "",
        userType: "",
      },
      v1: {
        title: false,
        message: false,
        userType: false,
        imagePosition: false,
        infoUrl: false,
      },
      attachedImage: null,
      attachedImageUrl: "",
      scheduleDate: "",
      isScheduleInvalid: false,
      previewDetails: {
        title: "",
        sender: "",
        date: "",
        message: "",
        imagePosition: "",
        infoUrl: "",
        imageAttach: "",
      },
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    validateContent() {
      this.v1 = {
        title: false,
        message: false,
        userType: false,
        imagePosition: false,
        infoUrl: false,
      };

      var isValid = true;

      if (!this.content.title) {
        this.v1.title = true;
        isValid = false;
      }

      if (!this.content.message) {
        this.v1.message = true;
        isValid = false;
      }

      if (!this.content.imagePosition && this.attachedImage) {
        this.v1.imagePosition = true;
        isValid = false;
      }

      return isValid;
    },

    openCancelCaution() {
      $("#mod-cancel").modal("show");
    },

    cancelBroadcast() {
      this.content = {
        title: "",
        message: "",
        imagePosition: "",
        infoUrl: "",
        userType: "",
      };
      $("#mod-cancel").modal("hide");
      this.$emit("closeModal");
    },

    openSchedule() {
      $("#mod-schedule").modal("show");
    },

    closeSchedule() {
      $("#mod-schedule").modal("hide");
      this.scheduleDate = "";
      this.isScheduleInvalid = false;
    },

    openBroadcastPreview() {
      this.previewDetails = {
        title: this.content.title,
        message: this.content.message,
        sender: this.user.parent.name,
        date: this.scheduleDate
          ? moment(this.scheduleDate).format("dddd, MMM DD, hh:mma")
          : moment(new Date()).format("dddd, MMM DD, hh:mma"),
        imagePosition: this.content.imagePosition,
        infoUrl: this.content.infoUrl,
        imageAttach: this.attachedImageUrl,
      };
      $(`#mod-read-broadcast${this.bno}`).modal("show");
    },

    closeBroadcastPreview() {
      $(`#mod-read-broadcast${this.bno}`).modal("hide");
    },

    removeAttachedImage() {
      this.attachedImage = null;
      this.attachedImageUrl = "";
    },

    previewFiles(event) {
      event.preventDefault();
      let file = event.target.files[0];
      this.attachedImage = event.target.files[0];
      this.attachedImageUrl = URL.createObjectURL(file);
    },

    async fetchBroadcastById(id) {
      this.isBusy = true;

      await this.$http
        .get(`/BroadcastMessages/${id}`)
        .then(function(res) {
          this.content = {
            id: res.body.id,
            title: res.body.title,
            message: res.body.message,
            imagePosition: res.body.imagePosition,
            infoUrl: res.body.link,
            userType: res.body.recipentType,
          };
          this.scheduleDate = moment(res.body.broadCastTime).format(
            "MM/DD/YYYY"
          );
          this.attachedImageUrl = res.body.imageUrl;
          this.isBusy = false;
        })
        .catch(function() {
          this.isBusy = false;
        });
    },

    sendBroadcast(type) {
      this.isBusy = true;

      if (this.validateContent()) {
        if (type === 2 && !this.scheduleDate) {
          return (this.isScheduleInvalid = true);
        }

        if (!this.isUpdating) {
          let payload = {
            Title: this.content.title,
            Message: this.content.message,
            Status: type,
            RecipentType: this.content.userType,
            ScheduledTime: this.scheduleDate,
            Image: this.attachedImage,
            ImagePosition: this.content.imagePosition,
            Link: this.content.infoUrl,
          };

          const formData = new FormData();
          for (const [key, value] of Object.entries(payload)) {
            formData.append(`${key}`, value);
          }

          this.$http
            .post("/BroadcastMessages", formData)
            .then(function(res) {
              this.$toast.success(res.body, {
                icon: true,
                rtl: false,
              });
              this.content = {
                id: "",
                title: "",
                message: "",
                imagePosition: "",
                infoUrl: "",
                userType: "",
                attachedImageUrl: "",
              };
              this.$emit("closeModal");
              this.closeSchedule();
              this.isBusy = false;
              if (type === 1) {
                this.$emit("refreshMessages", "sent");
              } else if (type === 2) {
                this.$emit("refreshMessages", "scheduled");
              } else {
                this.$emit("refreshMessages", "draft");
              }
            })
            .catch(function() {
              this.isBusy = false;
            });
        } else {
          let payload = {
            Id: this.content.id,
            Title: this.content.title,
            Message: this.content.message,
            Status: type,
            RecipentType: this.content.userType,
            ScheduledTime: this.scheduleDate,
            Image: this.attachedImage,
            DeleteImage: this.attachedImageUrl ? false : true,
            ImagePosition: this.content.imagePosition,
            Link: this.content.infoUrl,
          };

          const formData = new FormData();
          for (const [key, value] of Object.entries(payload)) {
            formData.append(`${key}`, value);
          }

          this.$http
            .put(`/BroadcastMessages/update/${this.content.id}`, formData)
            .then(function(res) {
              this.$toast.success(res.body, {
                icon: true,
                rtl: false,
              });
              this.content = {
                id: "",
                title: "",
                message: "",
                imagePosition: "",
                infoUrl: "",
                userType: "",
                attachedImageUrl: "",
              };
              this.$emit("closeModal");
              this.closeSchedule();
              this.isBusy = false;
              if (type === 1) {
                this.fetchSentMessages();
              } else if (type === 2) {
                this.fetchScheduledMessages();
              }
            })
            .catch(function() {
              this.isBusy = false;
            });
        }
      }
    },
  },
};
</script>
