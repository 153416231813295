<template>
	<div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card card-border-color card-border-color-primary">
                    <div class="card-header">
                        <div>
                            <h4 v-if="changeMode === 'default'"><strong>Manage Pin</strong></h4>
                            <h4 v-else><strong>Reset Pin</strong></h4>
                        </div>
                        <div  v-if="changeMode === 'reset'">
                            <button class="btn btn-danger btn-space" @click="switchChangeMode('default')"> &#8592; Back </button>
                        </div>
                        <div  v-if="changeMode === 'default'">
                            <button class="btn btn-danger btn-space" @click="()=> this.$router.push('/account')"> &#8592; Back </button>
                        </div>
                    </div>
					<div class="card-body py-4">
                        <div v-if="changeMode === 'default'">
                            <div>
                                <h4><strong>Change Pin</strong></h4>
                                <p>Update your pin and keep your account safe</p>
                                    <button
                                        v-if="user.hasSecurityPin"
                                        style="width: 190px"
                                        class="btn btn-space btn-primary"
                                        @click.prevent="showUpdateSecurityPin('update')"
                                    >
                                        <span>Update Pin</span>
                                    </button>
                                    <button
                                        v-else
                                        style="width: 190px"
                                        class="btn btn-space btn-primary"
                                        @click.prevent="showUpdateSecurityPin('new')"
                                    >
                                        <span>Create Pin</span>
                                    </button>
                            </div>

                            <div class="mt-5">
                                <h4><strong>Reset Pin</strong></h4>
                                <p>Forgot your pin? click here to reset</p>

                                <button style="width: 190px" class="btn btn-space btn-primary" @click="switchChangeMode('reset')">
                                    <span>Reset Pin</span>
                                </button>
                            </div>
                        </div>

                        <div v-else style="width: 500px">
                            <p>
                                To reset your PIN, we need to authenticate your identity. Please select where you would like to receive the One-Time Password (OTP) to complete this process.
                            </p>
                            <button style="width: 300px" class="btn btn-space btn-primary mt-2" @click="showResetPin('phone')" :disabled="isBusyBtn">
                                <span>Send OTP to my phone number</span>
                            </button>
                            <button style="width: 300px" class="btn btn-space btn-primary mt-2" @click="showResetPin('email')" :disabled="isBusyBtn">
                                <span>Send OTP to my email address</span>
                            </button>
                            <p class="text-primary mt-3"> Why do we need this?</p>
                            <p>
                                We take the security of your account seriously, which is why we require an OTP to verify your identity. By sending the OTP to your phone number or email address, we can ensure that it's really you who is resetting your PIN.
                            </p>

                        </div>
                      
                    </div>
                    <div class="card-footer bg-white">
                    </div>
                </div>
            </div>
        </div>


        <div
			class="modal fade colored-header colored-header-primary"
			id="mod-update-security-pin"
			role="dialog"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<div v-if="updateSecurityStep === 1">
							<h4 class="modal-title p-1"><strong>{{securityPinType === 'new' ? "Create" : "Update"}} Security Pin</strong></h4>
						</div>
						<!-- <button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button> -->

						<div v-if="updateSecurityStep === 2">
							<h4 class="modal-title"><strong>OTP Verification</strong></h4>
							<span> An OTP has been sent to registered email for security reasons </span>
						</div>
					</div>
					<div class="modal-body p-2">
						<div v-if="updateSecurityStep === 1">
							<div class="form-group row">
								<label class="col-12"
									>Enter Security Pin</label
								>
								<div class="col-12">
									<otp-input
										:isValid="securityValidation.pin"
										:digits="6"
										type="number"
										@on-complete="onCompletePinHandler"
										@on-changed="onChangedPinHandler"
										@on-paste="onPastePinHandler">
											<template #errorMessage> There is an error </template>
									</otp-input>
									<!-- <ul
										v-if="passwordValidation.new"
										class="parsley-errors-list filled"
									>
										<li class="parsley-required">Required.</li>
									</ul> -->
								</div>
							</div>
							<div v-if="securityPinType === 'update'" class="form-group row">
								<label class="col-12"
									>Enter New Security Pin</label
								>
								<div class="col-12">
									<otp-input
										:isValid="securityValidation.newPin"
										:digits="6"
										type="number"
										@on-complete="onCompleteNewPinHandler"
										@on-changed="onChangedNewPinHandler"
										@on-paste="onPasteNewPinHandler">
											<template #errorMessage> There is an error </template>
									</otp-input>
									<!-- <ul
										v-if="passwordValidation.new"
										class="parsley-errors-list filled"
									>
										<li class="parsley-required">Required.</li>
									</ul> -->
								</div>
							</div>
							<div class="form-group row">
								<label class="col-12"
									>Confirm Security Pin</label
								>
								<div class="col-12">
									<otp-input
										:isValid="securityValidation.confirmPin"
										:digits="6"
										type="number"
										@on-complete="onCompleteConfirmPinHandler"
										@on-changed="onChangedConfirmPinHandler"
										@on-paste="onPasteConfirmPinHandler">
											<template #errorMessage> Pin must match </template>
									</otp-input>
									<!-- <ul
										v-if="passwordValidation.new"
										class="parsley-errors-list filled"
									>
										<li class="parsley-required">Required.</li>
									</ul> -->
								</div>
							</div>
						</div>
						

						<div v-if="updateSecurityStep === 2" class="form-group row">
							<label class="col-12 col-sm-4"
								><span class="font-weight-bold">Enter OTP</span></label
							>
							<div class="col-12 col-sm-8">
								<otp-input
									:isValid="verifSecurityOTPValid"
									:digits="4"
									type="number"
									@on-complete="onCompleteSecurityOTPHandler"
									@on-changed="onChangedSecurityOTPHandler"
									@on-paste="onPasteSecurityOTPHandler">
										<template #errorMessage> OTP is not valid </template>
								</otp-input>
							</div>
						</div>
                       <div class="mt-2 px-2">
                            <span v-if="timerValue > 0">Resending in {{prettyTime}}</span>
                            <span v-else>Having trouble receiving your OTP? <button class="btn btn-text" @click.prevent="resendSecurityPinUpdateOTP"> <span class="text-primary ml-1" style="cursor:pointer">Resend</span> </button></span>
                        </div>
					</div>
					<div class="mt-2 p-1 d-flex flex-row justify-content-between" style="border-top: 0.3px solid #d7d7d7">
						<div>
							<div v-if="updateSecurityStep === 2" style="font-size:10px">
								<span>Can't access this number?</span><br/>
								<span class="font-weight-bold text-primary"><a href="tel:08183472000">Call us on 08183472000</a></span>
							</div>
						</div>
						<div>
							<button
								style="width: 100px"
								class="btn btn-text"
								type="button"
								data-dismiss="modal"
								@click.prevent ="closeUpdateSecurityPinModal"
							>
								<span class="text-danger">Cancel</span>
							</button>
							<button
								v-if="updateSecurityStep === 1"
								style="width: 100px"
								class="btn btn-space btn-primary"
								@click="updateSecurityPin"
							>
								<span v-if="isBusySecurityPin">Busy...</span>
								<span v-else>Done</span>
							</button>

							<button
								v-if="updateSecurityStep === 2"
								:disabled="isBusySecurityPin2"
								style="width: 100px"
								class="btn btn-space btn-primary"
								@click.prevent="validateSecurityPinOtp"
							>
								<span v-if="isBusySecurityPin2">Busy...</span>
								<span v-else>Update</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

        <div
			class="modal fade colored-header colored-header-primary"
			id="mod-reset-pin"
			role="dialog"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
                        <div v-if="resetStep === 1">
							<h4 class="modal-title"><strong>OTP Verification</strong></h4>
							<span> An OTP has been sent to <strong>{{resetType === 'phone' ? `+${user.phoneNumber}` : user.emailAddress}}</strong> for security reasons </span>
						</div>
						<div v-if="resetStep === 2">
							<h4 class="modal-title p-1"><strong>Update Security Pin</strong></h4>
						</div>

					</div>

					<div class="modal-body p-2">
                        <div v-if="resetStep === 1" class="form-group row mt-2">
							<label class="col-12 col-sm-4"
								><span class="font-weight-bold">Enter OTP</span></label
							>
							<div class="col-12 col-sm-8">
								<otp-input
									:isValid="verifResetOTPValid"
									:digits="4"
									type="number"
									@on-complete="onCompleteResetOTPHandler"
									@on-paste="onPasteResetOTPHandler">
										<template #errorMessage> OTP is not valid </template>
								</otp-input>
							</div>
                            <div class="mt-2 px-2">
                                <span v-if="timerValue > 0">Resending in {{prettyTime}}</span>
                                <span v-else>Having trouble receiving your OTP? <button class="btn btn-text" @click.prevent="sendResetOTP"> <span class="text-primary ml-1" style="cursor:pointer">Resend</span> </button></span>
                            </div>
						</div>

                        <div v-if="resetStep === 2" class="form-group row mt-2 px-2">
                            <div class="form-group row">
								<label class="col-12"
									>Enter New Security Pin</label
								>
								<div class="col-12">
									<otp-input
										:isValid="securityValidation.newPin"
										:digits="6"
										type="number"
										@on-complete="onCompleteNewPinHandler"
										@on-changed="onChangedNewPinHandler"
										@on-paste="onPasteNewPinHandler">
											<template #errorMessage> There is an error </template>
									</otp-input>
								</div>
							</div>
							<div class="form-group row">
								<label class="col-12"
									>Confirm Security Pin</label
								>
								<div class="col-12">
									<otp-input
										:isValid="securityValidation.confirmPin"
										:digits="6"
										type="number"
										@on-complete="onCompleteConfirmPinHandler"
										@on-changed="onChangedConfirmPinHandler"
										@on-paste="onPasteConfirmPinHandler">
											<template #errorMessage> Pin must match </template>
									</otp-input>
								</div>
							</div>
                        </div>

                        <div class="mt-4 pt-1 d-flex flex-row justify-content-between">
                            <div>
                                <div v-if="resetStep === 1">
                                    <span>Can't access this {{resetType === 'phone' ? 'number' : 'email'}}?</span><br/>
                                    <span class="font-weight-bold text-primary"><a href="tel:08183472000">Call us on 08183472000</a></span>
                                </div>
                            </div>

                            <div>						
                                <button
                                    style="width: 100px"
                                    class="btn btn-space btn-text"
                                    data-dismiss="modal"
                                    @click.prevent="closeResetPin"
                                >
                                    <span class="text-danger">Cancel</span>
                                </button>
                                <button
                                    v-if="resetStep === 1"
                                    :disabled="isBusyResetPin"
                                    style="width: 100px"
                                    class="btn btn-space btn-primary"
                                    @click.prevent="validateResetOTP"
                                >
                                    <span v-if="isBusyResetPin">Busy...</span>
                                    <span v-else>Confirm</span>
                                </button>

                                <button
                                    v-if="resetStep === 2"
                                    :disabled="isBusyResetPin2"
                                    style="width: 100px"
                                    class="btn btn-space btn-primary"
                                    @click.prevent="resetUserPin"
                                >
                                    <span v-if="isBusyResetPin2">Busy...</span>
                                    <span v-else>Done</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
			class="modal fade colored-header colored-header-primary"
			id="mod-success"
			role="dialog"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><strong>{{successModalTitle}}</strong></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="parent-div success-div">
							<div class="first-child">
								<div class="second-child">
								<svg width="20" height="15" viewBox="0 0 20 15" fill="none">
									<path
									d="M19.599 0.410713C19.2129 0.0245541 18.5869 0.0245541 18.2007 0.410713L6.35464 12.2568L1.80123 7.70343C1.41511 7.31727 0.789098 7.31731 0.4029 7.70343C0.0167416 8.08955 0.0167416 8.71556 0.4029 9.10172L5.65548 14.3542C6.04148 14.7403 6.66796 14.7401 7.05381 14.3542L19.599 1.80904C19.9851 1.42292 19.9851 0.796872 19.599 0.410713Z"
									fill="white"
									/>
								</svg>
								</div>
							</div>
							</div>
						</div>
						<p class="text-center text-bold">
							{{successModalBody}}
						</p>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-space btn-primary"
							data-dismiss="modal"
							@click="closeSuccessModal"
						>
							Done
						</button>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
	/* eslint-disable */
	import clone from "lodash/clone";
	import OtpInput from "otp-input-vue2";

	export default {
		name: "account-pin",
		components: {
			OtpInput,
		},
		data() {
			return {
                timerSeconds: "00",
				timerValue: 300,
				timer: null,
				timerStarted: false,

                updateSecurityStep: 1,
				verifSecurityOTP: "",
				verifSecurityOTPValid: true,
				isBusySecurityPin: false,
				isBusySecurityPin2: false,

                isCodeValid: true,
				securityPinType: "",
				security: {
					pin: null,
					newPin: null,
					confirmPin: null
				},

				securityValidation: {
					pin: true,
					newPin: true,
					confirmPin: true
				},

                successModalTitle: "Update Pin",
                successModalBody: "Success",

                changeMode: "default",
                resetStep: 1,
                resetType: 'phone',
                verifResetOTP: "",
				verifResetOTPValid: true,
				isBusyResetPin: false,
				isBusyResetPin2: false,
                isBusyBtn: false,
            }
        },

        computed: {
			user: function() {
				this.userState = this.$store.state.currentUser.state;
				this.userLga = this.$store.state.currentUser.lga;
				return clone(this.$store.state.currentUser);
			},
			prettyTime () {
				let time = this.timerValue / 60
				let minutes = parseInt(time);
				let minutesShow = minutes > 1 ? `${minutes} minutes` :  `${minutes} minute`;
				let roundedSeconds = Math.round((time - minutes) * 60);
				let secondes = roundedSeconds > 1 ? `${roundedSeconds} seconds` : `${roundedSeconds} second`;
				return minutesShow+" "+secondes
			}
		},

        methods: {
			start () {
                this.timerValue = 300;
				this.timerStarted = true;
				if (!this.timer) {
					this.timer = setInterval( () => {
							if (this.timerValue > 0) {
								this.timerValue--
							} else {
								clearInterval(this.timer)
								// this.reset()
							}
					}, 1000 )
				}
			},
			stop () {
				this.timerStarted = false
				clearInterval(this.timer)
				this.timer = null
			},
			reset () {
				this.stop()
				this.timerValue = 300
			},

            switchChangeMode(type){
                this.changeMode = type;
            },


//update pin
            onCompletePinHandler(code){
				this.security.pin = code;
			},

			onChangedPinHandler(lastEnteredCode){
				// console.log("code changed",  lastEnteredCode);
			},

			onPastePinHandler(code){
				this.security.pin = code;
			},

			onCompleteNewPinHandler(code){
				this.security.newPin = code;
			},

			onChangedNewPinHandler(lastEnteredCode){
				// console.log("code changed",  lastEnteredCode);
			},

			onPasteNewPinHandler(code){
				this.security.newPin = code;
			},

			onCompleteConfirmPinHandler(code){
				this.security.confirmPin = code;
			},

			onChangedConfirmPinHandler(lastEnteredCode){
				// console.log("code changed",  lastEnteredCode);
			},

			onPasteConfirmPinHandler(code){
				this.security.confirmPin = code;
			},

			showUpdateSecurityPin(type){
				this.securityPinType = type;
				$("#mod-update-security-pin").modal("show");
			},

			closeUpdateSecurityPin(){
				this.securityPinType = "";
				$("#mod-update-security-pin").modal("hide");
				this.updateSecurityStep = 1;
				this.verifSecurityOTP = "";
				this.verifSecurityOTPValid = true;
				this.isBusySecurityPin = false;
				this.isBusySecurityPin2 = false;
			},

			closeUpdateSecurityPinModal(){
				this.closeUpdateSecurityPin();
                this.security= {
					pin: null,
					newPin: null,
					confirmPin: null
				};
                this.securityValidation= {
					pin: true,
					newPin: true,
					confirmPin: true
				};
				// this.getUser();
			},

            openSuccessModal(title, message){
				this.successModalTitle = title;
				this.successModalBody = message;
				$("#mod-success").modal("show");
			},

			closeSuccessModal(){
				$("#mod-success").modal("hide");
				this.successModalTitle = "";
				this.successModalBody = "";
				this.getUser();
                this.$router.push("/account");
			},

            updateSecurityPin(){
				if(this.validateSecurityPin()){
					if(this.securityPinType === 'new'){
						this.isBusySecurityPin = true;
						let payload = {
							pin: this.security.pin,
							confirmPIN: this.security.confirmPin
						};
						this.$http.post("/Users/createUserPin", payload)
							.then(function(res) {
								this.isBusySecurityPin = false;
								if(res.body.status === "00"){
									this.start();
									this.updateSecurityStep++;
								}
							})
							.catch(function() {
								this.isBusySecurityPin = false;
							});
					}

					if(this.securityPinType === 'update'){
						this.isBusySecurityPin = true;
						let payload = {
							oldPIN: this.security.pin,
							newPIN: this.security.newPin,
							confirmNewPIN: this.security.confirmPin
						};
						this.$http.post("/Users/updateUserPin", payload)
							.then(function(res) {
								this.isBusySecurityPin = false;
								if(res.body.status === "00"){
									this.start();
									this.updateSecurityStep++;
								}
								
							})
							.catch(function() {
								this.isBusySecurityPin = false;
							});
					}
				}
			},

			validateSecurityPin(){
				this.securityValidation = {
					pin: true,
					newPin: true,
					confirmPin: true
				};
				var isValid =  true;

				if(this.securityPinType === 'new'){
                     if(!this.security.pin || this.security.pin.length !== 6){
						isValid = false;
                        this.securityValidation.pin = false;
                    }else if(!this.security.confirmPin || this.security.confirmPin.length !== 6){
						isValid = false;
                        this.securityValidation.confirmPin = false;
                    }

					if(this.security.pin !== this.security.confirmPin){
						this.securityValidation.confirmPin = false;
						isValid = false;
					}
					return isValid;
				}

				if(this.securityPinType === 'update'){
                    if(!this.security.pin || this.security.pin.length !== 6){
						isValid = false;
                        this.securityValidation.pin = false;
                    }else if(!this.security.newPin || this.security.newPin.length !== 6){
						isValid = false;
                        this.securityValidation.newPin = false;
                    }else if(!this.security.confirmPin || this.security.confirmPin.length !== 6){
						isValid = false;
                        this.securityValidation.confirmPin = false;
                    }

					if(this.security.newPin !== this.security.confirmPin){
						this.securityValidation.confirmPin = false;
						isValid = false;
					}
					return isValid;
				}
			},

			onCompleteSecurityOTPHandler(code){
				this.verifSecurityOTP = code;
			},

			onChangedSecurityOTPHandler(lastEnteredCode){
				// console.log("code changed",  lastEnteredCode);
			},

			onPasteSecurityOTPHandler(code){
				this.verifSecurityOTP = code;
			},

			resendSecurityPinUpdateOTP(){
					if(this.securityPinType === 'new'){
						let payload = {
							pin: this.security.pin,
							confirmPIN: this.security.confirmPin
						};
						this.$http.post("/Users/createUserPin", payload)
							.then(function(res) {
								if(res.body.status === "00"){
									this.reset();
									this.start();
								}else{
									this.$toast.error(res.body.message, {
										icon: true,
										rtl: false,
									});
								}
							})
							.catch(function() {
								// this.isBusySecurityPin = false;
							});
					}

					if(this.securityPinType === 'update'){
						let payload = {
							oldPIN: this.security.pin,
							newPIN: this.security.newPin,
							confirmNewPIN: this.security.confirmPin
						};
						this.$http.post("/Users/updateUserPin", payload)
							.then(function(res) {
								if(res.body.status === "00"){
									this.reset();
									this.start();
								}else{
									this.$toast.error(res.body.message, {
										icon: true,
										rtl: false,
									});
								}
							})
							.catch(function() {
								// this.isBusySecurityPin = false;
							});
					}
			},

			validateSecurityPinOtp(){
				this.verifSecurityOTPValid = true;

				if(this.verifSecurityOTP && this.verifSecurityOTP.length === 4){
					this.isBusySecurityPin2 = true;
					let theSecurityType = this.securityPinType === 'new' ? 'Create' : 'Update';
					this.$http.get(`/Users/validateSecurityCode/${this.verifSecurityOTP}?type=${theSecurityType}`)
					.then(function(res) {
						this.isBusySecurityPin2 = false;
						if(res.body.status === "00"){
							this.closeUpdateSecurityPin();
							this.openSuccessModal("Success", res.body.message);
						}else{
							this.$toast.error(res.body.message, {
								icon: true,
								rtl: false,
							});
						}
						
					})
					.catch(function() {
						this.isBusySecurityPin = false;
					});
				}else{
					this.verifSecurityOTPValid = false;
				}
			},

            //for reset
            showResetPin(type){
				this.resetType = type;
                this.sendResetOTP(type);
				// $("#mod-reset-pin").modal("show");
			},

			closeResetPin(){
				this.resetType = "";
				$("#mod-reset-pin").modal("hide");
				this.resetStep = 1;
				this.verifResetOTP = "";
				this.verifResetOTPValid = true;
				this.isBusyResetPin = false;
				this.isBusyResetPin2 = false;
			},

            onCompleteResetOTPHandler(code){
				this.verifResetOTP = code;
			},

			onPasteResetOTPHandler(code){
				this.verifResetOTP = code;
			},

            sendResetOTP(channel){
                this.isBusyBtn = true;
                this.$http.get(`/Users/sendOtpViaChannel?channel=${channel}`)
                .then(function(res) {
                    if(res.body){
                        this.reset();
                        $("#mod-reset-pin").modal("show");
                        this.isBusyBtn = false;
                        this.start();
                        this.$toast.success(res.body, {
                            icon: true,
                            rtl: false,
                        });;
                    }
                })
                .catch(function() {
                    this.isBusyBtn = false;
                });
            },

            validateResetOTP(){
                this.verifResetOTPValid = true;
                if(this.verifResetOTP && this.verifResetOTP.length === 4){
                    this.isBusyResetPin = true;
                    this.$http.post(`/Users/validateOTP?otp=${this.verifResetOTP}`)
                    .then(function(res) {
                        if(res){
                        this.resetStep = 2;
                        this.isBusyResetPin = false;
                        }
                    })
                    .catch(function() {
                        this.isBusyResetPin = false;
                    });
                }else{
                    this.verifResetOTPValid = false
                }
            },

            resetUserPin(){
                if(this.validateResetUserPin()){
                    this.isBusyResetPin2 = true;
						let payload = {
							pin: this.security.newPin,
							confirmPIN: this.security.confirmPin
						};
						this.$http.post("/Users/resetSecurityPin", payload)
							.then(function(res) {
								this.isBusyResetPin2 = false;
								if(res.body && res.body.status === "00"){
                                    this.closeResetPin();
                                    this.openSuccessModal("Update Pin", "Your pin has been successfully updated");
								}
							})
							.catch(function() {
								this.isBusyResetPin2 = false;
							});
                }
            },

            validateResetUserPin(){
                this.securityValidation = {
					newPin: true,
					confirmPin: true
				};
				var isValidPin =  true;
                if(!this.security.newPin || this.security.newPin.length !== 6){
                    isValidPin = false;
                    this.securityValidation.newPin = false;
                }else if(!this.security.confirmPin || this.security.confirmPin.length !== 6){
                    isValidPin = false;
                    this.securityValidation.confirmPin = false;
                }

                if(this.security.newPin !== this.security.confirmPin){
                    this.securityValidation.confirmPin = false;
                    isValidPin = false;
                }

                return isValidPin;
            },

            getUser() {
				this.$store.commit("loadingStaus", true);
				this.$http
					.get("/Users/getCurrent")
					.then(function(res) {
						this.$store.commit("setCurrentUser", res.body);
						this.$store.commit("loadingStaus", false);
					})
					.catch(function() {
					});
			},
        },
    }
</script>

<style scoped>
.parent-div {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px auto 34px;
  width: 110px;
  height: 110px;
}

.first-child {
  width: 86.52px;
  height: 86.52px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.second-child {
  width: 61.8px;
  height: 61.8px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.success-div {
  border: 1px solid rgba(182, 201, 121, 0.2);
}

.success-div .first-child {
  border: 1px solid rgba(182, 201, 121, 0.5);
}

.success-div .second-child {
  background: rgba(182, 201, 121, 1);
}

.text-bold {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}
</style>
