<template>
	<div class="row">
		<div class="col-lg-12">
			<div
				:class="[
					'card',
					'card-border-color',
					'card-border-color-primary',
					' card-table',
					'be-loading',
					{'be-loading-active': isBusy},
				]"
			>
				<div class="card-header">
					<button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
						<i class="feather icon-filter text-white"></i> Filters
					</button>

					<!-- <button class="btn btn-secondary btn-rounded btn-space float-right" @click="viewExport">
                      <span class="icon icon-left mdi mdi-arrow-top-right text-primary"></span> Export
                    </button>-->
				</div>
				<div class="card-body">
					<div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
						<div class="row be-datatable-body">
							<div class="col-sm-12 table-responsive">
								<table class="table table-fw-widget dataTable no-footer">
									<thead>
										<tr>
											<th style="width:10%;">Order Date</th>
											<th style="width:10%;">Order Number</th>
											<th style="width:10%;">Order Amount (₦)</th>
											<th style="width:10%;">Paid Amount (₦)</th>
											<th style="width:10%;">Payment Type</th>
											<th style="width:10%;">Amount Collected (₦)</th>
											<th style="width:15%;">Collection Date</th>
											<th style="width:15%;">Is Posted</th>
											<th style="width:10%;"></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="o in orders" :key="o.id">
											<td>{{ formatDate(o.order.created) }}</td>
											<td>
												<a href="#" @click.prevent="details(o.order.id)">{{
													o.order.orderNumber
												}}</a>
											</td>
											<td>{{ formatMoney(o.order.payment.orderAmount) }}</td>
											<td>{{ formatMoney(o.order.payment.paidAmount) }}</td>
											<td>{{ o.paymentType }}</td>
											<td>
												{{
													o.paymentType == "Credit"
														? "N/A"
														: formatMoney(o.amountCollected)
												}}
											</td>
											<td>{{ formatDate(o.collectionDate) }}</td>
											<td>
												{{ o.isCollectionPosted == true ? "Yes" : "No" }}
											</td>
											<td>
												<div class="text-right">
													<button
														v-if="
															(o.isCollectionPosted != true ||
																(o.paymentType == 'Credit' &&
																	o.order.payment.paidAmount <
																		o.order.payment
																			.orderAmount)) &&
																o.paymentType &&
																hasPermission('Payment_Post')
														"
														class="btn btn-secondary btn-rounded btn-space"
														@click.prevent="viewPostPayment(o)"
													>
														Post Payment
													</button>
												</div>
											</td>
										</tr>
										<tr v-if="!orders.length">
											<td colspan="9">
												<div class="text-center" style="padding-top:50px;">
													<span
														style="font-size: 4.615rem;"
														class="mdi mdi-basket"
													></span>
												</div>
												<h3
													class="text-center"
													style="padding-bottom:100px;"
												>
													You have no payments.
												</h3>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="row be-datatable-footer">
							<div class="col-sm-5">
								<div class="dataTables_info">
									{{ count }} order{{ count != 1 ? "s" : "" }}
								</div>
							</div>
							<div class="col-sm-7">
								<div class="dataTables_paginate paging_simple_numbers">
									<ul class="pagination">
										<li
											:class="[
												'paginate_button',
												'page-item',
												'previous',
												{disabled: !hasPrev},
											]"
										>
											<a href="#" class="page-link" @click.prevent="prev"
												>Previous</a
											>
										</li>
										<li
											:class="[
												'paginate_button',
												'page-item',
												'previous',
												{disabled: !hasNext},
											]"
										>
											<a href="#" class="page-link" @click.prevent="next"
												>Next</a
											>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="be-spinner">
					<svg width="40px" height="40px" viewBox="0 0 66 66">
						<circle
							class="circle"
							fill="none"
							stroke-width="4"
							stroke-linecap="round"
							cx="33"
							cy="33"
							r="30"
						/>
					</svg>
				</div>
			</div>
		</div>

		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-manage-payment-add"
			role="dialog"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1">
							<strong>Manage payment for order # {{ fOrder.orderNumber }}</strong>
						</h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row" v-if="!v1.is_valid">
							<div class="col-12 col-sm-12 col-lg-12">
								<label class="col-12 col-sm-12 text-sm-center alert alert-danger">{{
									v1.text
								}}</label>
							</div>
						</div>

						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Order Amount</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="number"
									placeholder="Enter amount paid"
									v-model="fOrder.orderAmount"
									disabled
								/>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Total Amount Paid</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="number"
									placeholder="Enter amount paid"
									v-model="fOrder.paidAmount"
									disabled
								/>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Amount Paid</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', {'is-invalid': v1.amountPaid}]"
									type="number"
									placeholder="Enter amount paid"
									v-model="fOrder.amountPaid"
									:disabled="fOrder.paymentType != 'Credit'"
								/>
								<ul v-if="v1.amountPaid" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Notes</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<textarea
									:class="['form-control', {'is-invalid': v1.notes}]"
									v-model="fOrder.notes"
								></textarea>
								<ul v-if="v1.notes" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px;"
							class="btn btn-secondary md-close"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 120px;"
							class="btn btn-primary"
							type="button"
							@click.prevent="managePayment_add"
						>
							Update
						</button>
					</div>
				</div>
			</div>
		</div>

		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-filter"
			role="dialog"
			tabindex="-1"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><strong>Apply Filter</strong></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Posted Status</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="filter.isCollectionPosted"
								>
									<option value="0">All Orders</option>
									<option value="true">Is Posted</option>
									<option value="false">Not Posted</option>
								</select>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Date range</label
							>
							<div class="col-12 col-sm-4 col-lg-4">
								<input
									type="date"
									:max="maxDate"
									class="form-control"
									placeholder="Select a date"
									v-model="filter.dateStart"
								/>
								<!-- <date-picker
                                  v-model="filter.dateStart"
                                  :config="options"
                                  placeholder="Select a date"
                                ></date-picker>-->
							</div>
							<div class="col-12 col-sm-4 col-lg-4">
								<input
									type="date"
									:max="maxDate"
									class="form-control"
									placeholder="Select a date"
									v-model="filter.dateEnd"
								/>
								<!-- <date-picker v-model="filter.dateEnd" :config="options" placeholder="Select a date"></date-picker> -->
							</div>
						</div>

						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Customer</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select a customer"
									v-model="filter.buyerId"
								>
									<option v-for="c in customers" :key="c.id" :value="c.id">{{
										c.text
									}}</option>
								</select>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Other filters</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="text"
									placeholder="Filter by order number..."
									v-model="filter.orderNumber"
								/>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px;"
							class="btn btn-secondary md-close"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 100px;"
							class="btn btn-secondary md-close"
							type="button"
							@click="reset"
						>
							Reset
						</button>
						<button
							style="width: 100px;"
							class="btn btn-primary md-close"
							type="button"
							@click="getOrders"
						>
							Search
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	/* eslint-disable */

	import moment from "moment";
	import {helper} from "@/helper";
	import Select2 from "@/components/Select2";
	import SystemPermissions from "@/permissions";
	// import DatePicker from "vue-bootstrap-datetimepicker";

	export default {
		mixins: [SystemPermissions.Mixin],
		name: "manageCashChequeTransactions",
		components: {
			Select2,
			// DatePicker
		},
		created() {
			if (!this.hasPermission("Payment_View")) {
				this.$router.push("/restricted");
			}
			this.getCustomers();
			this.getOrders();
		},
		data() {
			return {
				filter: {
					isCollectionPosted: "0",
					orderNumber: "",
					dateStart: moment()
						.subtract(6, "days")
						.format("YYYY-MM-DD"),
					dateEnd: moment().format("YYYY-MM-DD"),
					buyerId: "",
				},
				customers: [],
				isBusy: false,
				isBusyModal: false,
				fOrder: {},
				v1: {
					amountPaid: false,
					notes: false,
					is_valid: true,
					text: "",
				},
				orders: [],
				count: 0,
				page: 0,
				pageSize: 10,
				hasNext: false,
				hasPrev: false,
				options: {
					format: "YYYY-MM-DD",
					icons: {
						time: "mdi mdi-time",
						date: "mdi mdi-calendar",
						up: "mdi mdi-chevron-up",
						down: "mdi mdi-chevron-down",
						previous: "mdi mdi-chevron-left",
						next: "mdi mdi-chevron-right",
						today: "mdi mdi-screenshot",
						clear: "mdi mdi-trash",
						close: "mdi mdi-remove",
					},
				},
				local: {
					dow: 0, // Sunday is the first day of the week
					hourTip: "Select Hour", // tip of select hour
					minuteTip: "Select Minute", // tip of select minute
					secondTip: "Select Second", // tip of select second
					yearSuffix: "", // suffix of head year
					monthsHead: "January_February_March_April_May_June_July_August_September_October_November_December".split(
						"_",
					), // months of head
					months: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"), // months of panel
					weeks: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"), // weeks
					cancelTip: "cancel",
					submitTip: "confirm",
				},
				maxDate: "",
			};
		},
		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},
		},
		mounted() {
			this.maxDate = helper.preventFutureDateSelection();
		},
		methods: {
			async getCustomers() {
				await this.$http
					.get("/Users/getItemListOfCustomers/" + this.user.parentId)
					.then(function(res) {
						this.customers = res.body;
					})
					.catch(function() {});
			},
			reset() {
				this.filter = {
					isCollectionPosted: "0",
					orderNumber: "",
					dateStart: moment()
						.subtract(6, "days")
						.format("YYYY-MM-DD"),
					dateEnd: moment().format("YYYY-MM-DD"),
					buyerId: "",
				};
			},
			viewPostPayment(o) {
				this.v1 = {
					notes: false,
					amountPaid: false,
					is_valid: true,
					text: "",
				};

				this.fOrder = {
					paymentCollectionHistoryId: o.id,
					orderId: o.order.id,
					orderNumber: o.order.orderNumber,
					paymentType: o.paymentType,
					amountPaid: o.amountCollected,
					paidAmount: o.order.payment.paidAmount ? o.order.payment.paidAmount : 0,
					notes: null,
					orderAmount: o.order.payment.orderAmount,
					paidAmount: o.order.payment.paidAmount,
				};

				if (this.fOrder.paidAmount == null) this.fOrder.paidAmount = 0;

				$("#mod-manage-payment-add").modal("show");
			},
			viewExport() {
				// $("#mod-export").modal("show");
			},
			viewFilter() {
				$("#mod-filter").modal("show");
			},
			details(id) {
				this.$router.push("/orders/" + id + "/mc");
			},
			async getOrders() {
				this.isBusy = true;

				await this.$http
					.get(
						"/Orders/getCashChequePaymentList?page=" +
							this.page +
							"&pageSize=" +
							this.pageSize +
							(this.filter.orderNumber != ""
								? "&orderNumber=" + this.filter.orderNumber
								: "") +
							(this.filter.isCollectionPosted != "0"
								? "&isCollectionPosted=" + this.filter.isCollectionPosted
								: "") +
							(this.filter.buyerId != "0" ? "&makerId=" + this.filter.buyerId : "") +
							(this.filter.dateStart != ""
								? "&dateStart=" +
								  moment(this.filter.dateStart).format("YYYY-MM-DD 00:00:00")
								: "") +
							(this.filter.dateEnd != ""
								? "&dateEnd=" +
								  moment(this.filter.dateEnd).format("YYYY-MM-DD 23:59:59")
								: "") +
							"&sellerId=" +
							this.user.parentId,
					)
					.then(function(res) {
						this.orders = res.body.data;
						this.page = res.body.page;
						this.pageSize = res.body.pageSize;
						this.count = res.body.count;
						this.hasPrev = res.body.hasPrev;
						this.hasNext = res.body.hasNext;
						this.isBusy = false;
					})
					.catch(function() {});

				$("#mod-filter").modal("hide");
				this.isBusy = false;
			},
			async managePayment_add() {
				this.isBusyModal = true;
				this.isBusy = true;

				if (this.validatePayment_Add()) {
					$("#mod-manage-payment-add").modal("hide");

					await this.$http
						.post("/Orders/postCashChequeTransaction", this.fOrder)
						.then(function(res) {
							this.isBusyModal = false;
							this.getOrders();
						})
						.catch(function() {
							this.isBusy = false;
							this.isBusyModal = false;
						});
				} else {
					this.isBusy = false;
					this.isBusyModal = false;
				}
			},
			validatePayment_Add() {
				this.v1 = {
					notes: false,
					amountPaid: false,
					is_valid: true,
					text: "",
				};
				var isValid = true;

				if (!this.fOrder.amountPaid) {
					this.v1.amountPaid = true;
					isValid = false;
				}

				if (!this.fOrder.notes) {
					this.v1.notes = true;
					isValid = false;
				}

				if (this.fOrder.amountPaid <= 0) {
					this.v1.is_valid = false;
					this.v1.text = "Payment amount must be greater than 0";
					isValid = false;
				}

				if (
					this.fOrder.amountPaid > this.fOrder.orderAmount &&
					this.fOrder.paymentType == "Credit"
				) {
					this.v1.is_valid = false;
					this.v1.text = "Amount cannot be more than order amount";
					isValid = false;
				}

				if (
					Number(this.fOrder.amountPaid) + this.fOrder.paidAmount >
						this.fOrder.orderAmount &&
					this.fOrder.paymentType == "Credit"
				) {
					this.v1.is_valid = false;
					this.v1.text = "Amount paid cannot be more than order amount";
					isValid = false;
				}

				return isValid;
			},
			formatDate(date) {
				return moment(date).format("ll");
			},
			prev() {
				this.page -= 1;
				this.getOrders();
			},
			next() {
				this.page += 1;
				this.getOrders();
			},
			formatMoney(val) {
				return helper.formatMoney(val);
			},
			goToPayment(id) {
				this.$router.push("/payments/" + id);
			},
		},
	};
</script>
