<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-border-color card-border-color-primary">
          <div class="card-header">&nbsp;</div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6 col-sm col-lg-3 mb-2">
                <div
                  style="background-color: #f7f7f7; border-radius: 10px"
                  class="p-2"
                >
                  <i
                    class="feather icon-file-text font-large-2 float-left"
                    aria-hidden="true"
                    style="color: #d9a300"
                  ></i>
                  <span>
                    <strong>
                      <router-link to="/manufacturer-retailer-report">
                        Retailers Report</router-link
                      >
                    </strong>
                    <p>Click to generate Report</p>
                  </span>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="col-md-6 col-sm col-lg-3 mb-2">
                <div
                  style="background-color: #f7f7f7; border-radius: 10px"
                  class="p-2"
                >
                  <i
                    class="feather icon-file-text font-large-2 float-left"
                    aria-hidden="true"
                    style="color: #d9a300"
                  ></i>
                  <span>
                    <strong>
                      <router-link to="/manufacturer-sales-by-product">
                        Sales By Product</router-link
                      >
                    </strong>
                    <p>Click to generate Report</p>
                  </span>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="col-md-6 col-sm col-lg-3 mb-2">
                <div
                  style="background-color: #f7f7f7; border-radius: 10px"
                  class="p-2"
                >
                  <i
                    class="feather icon-file-text font-large-2 float-left"
                    aria-hidden="true"
                    style="color: #d9a300"
                  ></i>
                  <span>
                    <strong>
                      <router-link
                        to="/manufacturer-total-orders-by-distributor"
                      >
                        Total Orders By Distributor</router-link
                      >
                    </strong>
                    <p>Click to generate Report</p>
                  </span>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="col-md-6 col-sm col-lg-3 mb-2">
                <div
                  style="background-color: #f7f7f7; border-radius: 10px"
                  class="p-2"
                >
                  <i
                    class="feather icon-file-text font-large-2 float-left"
                    aria-hidden="true"
                    style="color: #d9a300"
                  ></i>
                  <span>
                    <strong>
                      <router-link to="/manufacturer-total-orders-by-state">
                        Total Orders By State</router-link
                      >
                    </strong>
                    <p>Click to generate Report</p>
                  </span>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="col-md-6 col-sm col-lg-3 mb-2">
                <div
                  style="background-color: #f7f7f7; border-radius: 10px"
                  class="p-2"
                >
                  <i
                    class="feather icon-file-text font-large-2 float-left"
                    aria-hidden="true"
                    style="color: #d9a300"
                  ></i>
                  <span>
                    <strong>
                      <router-link to="/manufacturer-total-orders-by-status">
                        Total Orders By Status</router-link
                      >
                    </strong>
                    <p>Click to generate Report</p>
                  </span>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="col-md-6 col-sm col-lg-3 mb-2">
                <div
                  style="background-color: #f7f7f7; border-radius: 10px"
                  class="p-2"
                >
                  <i
                    class="feather icon-file-text font-large-2 float-left"
                    aria-hidden="true"
                    style="color: #d9a300"
                  ></i>
                  <span class="pl-1 pull-left">
                    <strong>
                      <router-link to="/manufacturer-retailers-by-aging-credit">
                        Retailers By Aging Credit</router-link
                      >
                    </strong>
                    <p>Click to generate Report</p>
                  </span>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="col-md-6 col-sm col-lg-3 mb-2">
                <div
                  style="background-color: #f7f7f7; border-radius: 10px"
                  class="p-2"
                >
                  <i
                    class="feather icon-file-text font-large-2 float-left"
                    aria-hidden="true"
                    style="color: #d9a300"
                  ></i>
                  <span>
                    <strong>
                      <router-link to="/manufacturer-product-performance"
                        >Product Performance</router-link
                      >
                    </strong>
                    <p>Click to generate Report</p>
                  </span>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="col-md-6 col-sm col-lg-3 mb-2">
                <div
                  style="background-color: #f7f7f7; border-radius: 10px"
                  class="p-2"
                >
                  <i
                    class="feather icon-file-text font-large-2 float-left"
                    aria-hidden="true"
                    style="color: #d9a300"
                  ></i>
                  <span>
                    <strong>
                      <router-link
                        class="text-break"
                        to="/manufacturer-product-performance-distributor"
                        >Product Performance (Distributor)</router-link
                      >
                    </strong>
                    <p>Click to generate Report</p>
                  </span>
                  <div class="clearfix"></div>
                </div>
              </div>
              <div class="col-md-6 col-sm col-lg-3 mb-2">
                <div
                  style="background-color: #f7f7f7; border-radius: 10px"
                  class="p-2"
                >
                  <i
                    class="feather icon-file-text font-large-2 float-left"
                    aria-hidden="true"
                    style="color: #d9a300"
                  ></i>
                  <span>
                    <strong>
                      <router-link to="/manufacturer-product-performance-state"
                        >Product Performance By State</router-link
                      >
                    </strong>
                    <p>Click to generate Report</p>
                  </span>
                  <div class="clearfix"></div>
                </div>
              </div>

              <div class="col-md-6 col-sm col-lg-3 mb-2">
                <div
                  style="background-color: #f7f7f7; border-radius: 10px"
                  class="p-2"
                >
                  <i
                    class="feather icon-file-text font-large-2 float-left"
                    aria-hidden="true"
                    style="color: #d9a300"
                  ></i>
                  <span>
                    <strong>
                      <router-link to="/manufacturer-expenses-by-distributor"
                        >Expense By Distributor</router-link
                      >
                    </strong>
                    <p>Click to generate Report</p>
                  </span>
                  <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
          <h3 class="text-center" style="padding-bottom: 100px"></h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "manufacturerreports",
};
</script>