<template>
  <div>
    <div class="table-responsive">
      <table class="table p-0 m-0 dataTable no-footer">
        <thead class="bg-white w-full">
          <tr>
            <th
              width="30%"
              class="text-[12px] leading-[18px] text-[#000000] font-semibold"
            >
              Message Title
            </th>
            <th
              width="30%"
              class="text-[12px] leading-[18px] text-[#000000] font-semibold"
            >
              Message
            </th>
            <th width="100%" class="w-full"></th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="m in draftMessages" :key="m.id">
            <td>{{ m.title }} (Draft)</td>
            <td>{{ m.message.substring(0, 13) + " ..." }}</td>
            <td @click="toggleDropdown(m.id)">
              <div class="dropdown">
                <button
                  class="btn-no-border"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span
                    class="text-[#98a2b3] dropdown text-2xl cursor-pointer relative outline-none active:outline-none shadow-none"
                  >
                    ...
                  </span>
                </button>
                <ul
                  class="dropdown-menu shadow-none"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li
                    class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer border-b border-[#e5e7eb] py-[12px] px-[16px]"
                    v-if="hasPermission('Broadcast_Message_Update')"
                    @click.prevent="editNewBrod(m.id, m)"
                  >
                    Edit
                  </li>
                  <li
                    class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer border-b border-[#e5e7eb] py-[12px] px-[16px]"
                    @click="openBroadcastPreview(m)"
                  >
                    Preview
                  </li>
                  <li
                    class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer border-b border-[#e5e7eb] py-[12px] px-[16px]"
                    v-if="hasPermission('Broadcast_Message_Create')"
                    @click.prevent="sendBroadcastMessage(m, 1)"
                  >
                    Broadcast Now
                  </li>
                  <li
                    class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer border-b border-[#e5e7eb] py-[12px] px-[16px]"
                    v-if="hasPermission('Broadcast_Message_Create')"
                    @click.prevent="openSchedule(m)"
                  >
                    Broadcast Later
                  </li>
                  <li
                    class="text-[14px] font-normal leading-[20px] text-[#DC2828] cursor-pointer py-[12px] px-[16px]"
                    v-if="hasPermission('Broadcast_Message_Delete')"
                    @click.prevent="deleteDraft(m.id)"
                  >
                    Delete
                  </li>
                </ul>
              </div>
            </td>
            <td></td>
          </tr>
          <tr v-if="!draftMessages.length">
            <td colspan="10">
              <div class="text-center" style="padding-top: 50px">
                <span
                  style="font-size: 4.615rem"
                  class="fa-solid fa-envelope"
                ></span>
              </div>
              <p
                class="mt-1 text-[#353535] text-[16px] text-center font-medium leading-[20px]"
                style="padding-bottom: 50px"
              >
                You have no message
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination
        :limit="pageSize"
        :count="pageCount"
        :page="page"
        :itemsCount="count"
        @changePage="gotoPage"
        @changeLimit="changeLimit"
      />
    </div>
    <!-- <BroadcastEdit
      @closeModal="closeBroadcastEdit"
      :details="previewDetails"
      :title="bno"
    ></BroadcastEdit> -->
    <BroadcastPreview
      :title="bno"
      @closeModal="closeBroadcastPreview"
      :details="previewDetails"
    ></BroadcastPreview>
  </div>
</template>

<script>
/* eslint-disable */
import SystemPermissions from "@/permissions";
import { helper } from "@/helper";
import Pagination from "../../components/Pagination.vue";
import moment from "moment";
import BroadcastPreview from "../../components/Broadcast/BroadcastPreview.vue";
// import BroadcastEdit from "../../components/Broadcast/BroadcastEdit.vue";
export default {
  mixins: [SystemPermissions.Mixin],
  name: "Enterprise-Broadcast",
  components: {
    Pagination,
    BroadcastPreview,
    // BroadcastEdit,
  },

  mounted: function() {
    this.fetchDraftMessages();
    this.minDate = helper.preventFutureDateSelection();
    this.maxDate = moment(this.minDate, "DD-MM-YYYY").add(90, "days");
  },

  data() {
    return {
      showDropdown: null,
      isBusy: false,
      isBusyDeleting: false,
      isBusyModal: false,
      draftMessages: [],
      updatingMessage: null,
      count: 0,
      page: 1,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,

      minDate: "",
      maxDate: "",
      bno: 6,
      scheduleDate: null,
      isScheduleInvalid: false,
      previewDetails: {
        title: "",
        sender: "",
        date: "",
        message: "",
        imagePosition: "",
        infoUrl: "",
        imageAttach: "",
      },

      content: {
        title: "",
        message: "",
        userType: "",
        id: "",
      },
      v1: {
        title: false,
        message: false,
      },
    };
  },

  methods: {
    toggleDropdown(userId) {
      this.showDropdown = this.showDropdown === userId ? null : userId;
    },
    updateDraft(m) {
      this.content = {
        message: m.message,
        title: m.title,
        userType: m.recipentType,
        id: m.id,
      };
      $("#mod-update").modal("show");
    },

    openSchedule(m) {
      this.updatingMessage = m;
      $("#mod-schedule").modal("show");
    },

    closeSchedule() {
      $("#mod-schedule").modal("hide");
      this.scheduleDate = "";
      this.isScheduleInvalid = false;
    },

    openBroadcastPreview(m) {
      this.previewDetails = {
        title: m.title,
        message: m.message,
        sender: m.sender,
        date: m.date
          ? moment(m.date).format("dddd, MMM DD, hh:mma")
          : moment(new Date()).format("dddd, MMM DD, hh:mma"),
        imagePosition: m.imagePosition,
        infoUrl: m.link,
        imageAttach: m.imageUrl,
      };
      $(`#mod-read-broadcast${this.bno}`).modal("show");
    },

    closeBroadcastPreview() {
      $(`#mod-read-broadcast${this.bno}`).modal("hide");
    },

    closeBroadcastEdit() {
      $(`#mod-read-broadcast-edit${this.bno}`).modal("hide");
    },

    editNewBrod(id, m) {
      if (id) {
        this.previewDetails = {
          title: m.title,
          message: m.message,
          sender: m.sender,
          date: m.date
            ? moment(m.date).format("dddd, MMM DD, hh:mma")
            : moment(new Date()).format("dddd, MMM DD, hh:mma"),
          imagePosition: m.imagePosition,
          infoUrl: m.link,
          imageAttach: m.imageUrl,
          id: id,
        };
        $(`#mod-read-broadcast-edit${this.bno}`).modal("show");
      }
      //  this.$router.push(`/manufacturer-broadcast-new/${id}`);
    },

    sendBroadcastMessage(m, type) {
      if (type == 2 && !this.scheduleDate) {
        return (this.isScheduleInvalid = true);
      }
      let payload = {
        Id: m.id,
        Title: m.title,
        Message: m.message,
        Status: type,
        RecipentType: m.recipentType,
        ScheduledTime: m.broadCastTime,
        Image: m.imageUrl,
        DeleteImage: m.imageUrl ? false : true,
        ImagePosition: m.imagePosition,
        Link: m.link,
      };

      const formData = new FormData();
      for (const [key, value] of Object.entries(payload)) {
        formData.append(`${key}`, value);
      }

      this.$http
        .put(`/BroadcastMessages/update/${m.id}`, formData)
        .then(function(res) {
          this.$toast.success(res.body, {
            icon: true,
            rtl: false,
          });
          this.content = {
            id: "",
            title: "",
            message: "",
            imagePosition: "",
            infoUrl: "",
            userType: "",
          };
          this.closeSchedule();
          // this.fetchSentMessages();
          this.fetchDraftMessages();
          this.isBusy = false;
          // this.$router.push("/manufacturer-broadcast");
        })
        .catch(function() {
          this.isBusy = false;
        });
    },

    validateContent() {
      this.v1 = {
        title: false,
        message: false,
      };

      var isValid = true;

      if (!this.content.title) {
        this.v1.title = true;
        isValid = false;
      }

      if (!this.content.message) {
        this.v1.message = true;
        isValid = false;
      }

      return isValid;
    },

    cancelBroadcast() {
      this.content = {
        title: "",
        message: "",
        userType: "",
        id: "",
      };
    },

    updateBroadcastDraft(status) {
      this.isBusyModal = false;

      if (this.validateContent()) {
        let payload = {
          title: this.content.title,
          message: this.content.message,
          status: status,
          recipentType: this.content.userType,
          id: this.content.id,
        };
        this.$http
          .put(`/BroadcastMessages/update/${this.content.id}`, payload)
          .then(function(res) {
            $("#mod-update").modal("hide");
            this.$toast.success(res.body, {
              icon: true,
              rtl: false,
            });
            this.content = {
              title: "",
              message: "",
              userType: "",
              id: "",
            };
            this.fetchDraftMessages();
            this.isBusyModal = false;
          })
          .catch(function() {
            this.isBusyModal = false;
          });
      }
    },

    deleteDraft(id) {
      this.isBusyDeleting = true;

      this.$http
        .delete(`/BroadcastMessages/${id}`)
        .then(function(res) {
          this.isBusyDeleting = false;
          this.$toast.success(res.body, {
            icon: true,
            rtl: false,
          });
          this.fetchDraftMessages();
        })
        .catch(function() {
          this.isBusyDeleting = false;
        });
    },

    fetchDraftMessages() {
      this.draftMessages = [];
      this.isBusy = true;

      this.$http
        .get(
          `/BroadcastMessages?Status=0&PageSize=${this.pageSize}&PageNumber=${this.page}`
        )
        .then(function(res) {
          this.draftMessages = res.body.data;
          this.page = res.body.pageNumber;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function() {
          this.isBusy = false;
        });
    },

    prev() {
      this.page -= 1;
      this.fetchDraftMessages();
    },
    next() {
      this.page += 1;
      this.fetchDraftMessages();
    },

    gotoPage(page) {
      this.page = page;
      this.fetchDraftMessages();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.fetchDraftMessages();
    },
  },
};
</script>
