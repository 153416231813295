<template>
  <div
    class="modal bg-[rgba(0,0,0,.7)] fade colored-header colored-header-primary"
    id="mod-price-option-add"
    role="dialog"
  >
    <div class="modal-dialog">
      <form
        @submit.prevent="submit"
        class="modal-content modal-content p-[24px] bg-[#f8f8f8]"
      >
        <div
          class="modal-header modal-header-colored  flex items-start justify-between p-0 m-0"
        >
          <h4
            class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
          >
            <span v-if="showUpdate">Update Price Option</span>
            <span v-else>Create New Price Option</span>
          </h4>
          <button
            class="text-2xl text-[#353535]"
            type="button"
            data-dismiss="modal"
            @click.prevent="cancel"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body m-0 p-0">
          <div class="mb-[16px]">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Name</label
            >
            <div>
              <input
                class="bg-transparent border-[#d0d5dd] border-solid border-[1.5px] py-[12px] px-[14px] text-[#96A0B5] rounded-[8px] w-full"
                placeholder="Enter price name"
                :class="{ 'is-invalid': $v.name.$error }"
                type="text"
                v-model.trim="$v.name.$model"
              />
              <div class="invalid-feedback" v-if="!$v.name.required">
                Field is required
              </div>
              <div class="invalid-feedback" v-if="!$v.name.minLength">
                Name must have at least
                {{ $v.name.$params.minLength.min }} letters.
              </div>
            </div>
          </div>
          <div class="mb-[16px]" v-if="showUpdate">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Options ID</label
            >
            <div>
              <input
                class="bg-transparent border-[#d0d5dd] border-solid border-[1.5px] py-[12px] px-[14px] text-[#96A0B5] rounded-[8px] w-full"
                readonly
                type="text"
                v-model="optionId"
              />
            </div>
          </div>
          <div class="mb-[16px]">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Description</label
            >
            <div>
              <textarea
                class="bg-transparent border-[#d0d5dd] border-solid border-[1.5px] py-[12px] px-[14px] text-[#96A0B5] rounded-[8px] w-full"
                placeholder="Enter Description"
                :class="{ 'is-invalid': $v.description.$error }"
                v-model.trim="$v.description.$model"
              ></textarea>
              <div class="invalid-feedback" v-if="!$v.description.required">
                Field is required
              </div>
            </div>
          </div>
          <div class="mb-[16px]">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Distributor price</label
            >
            <div>
              <input
                class="bg-transparent border-[#d0d5dd] border-solid border-[1.5px] py-[12px] px-[14px] text-[#96A0B5] rounded-[8px] w-full"
                type="number"
                :class="{ 'is-invalid': $v.distributorPrice.$error }"
                v-model.trim="$v.distributorPrice.$model"
                step="0.01"
              />
              <div
                class="invalid-feedback"
                v-if="!$v.distributorPrice.required"
              >
                Field is required
              </div>
              <div
                class="invalid-feedback"
                v-if="!$v.distributorPrice.minValue"
              >
                Distributor price must be greater than
                {{ $v.distributorPrice.$params.minValue.min - 1 }}.
              </div>
            </div>
          </div>
          <div class="mb-[16px]">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Retail price</label
            >
            <div>
              <input
                class="bg-transparent border-[#d0d5dd] border-solid border-[1.5px] py-[12px] px-[14px] text-[#96A0B5] rounded-[8px] w-full"
                type="number"
                :class="{ 'is-invalid': $v.retailPrice.$error }"
                v-model.trim="$v.retailPrice.$model"
                step="0.01"
              />
              <div class="invalid-feedback" v-if="!$v.retailPrice.required">
                Field is required
              </div>
              <div class="invalid-feedback" v-if="!$v.retailPrice.minValue">
                Retail price must be greater than
                {{ $v.retailPrice.$params.minValue.min - 1 }}.
              </div>
            </div>
          </div>
          <div
            class="flex items-center gap-[10px] max-w-max mt-[16px]"
            @click="active = !active"
          >
            <button
              type="button"
              class="relative inline-flex items-center flex-shrink-0 h-[24px] px-[3px] w-[46px] transition-all duration-200 ease-in-out border-[0.5px] border-[#E4E4E7] shadow-sm rounded-[10px] cursor-pointer focus:outline-none"
              role="switch"
              :aria-checked="active"
              :class="{
                'bg-green-800': active,
                'bg-[#E4E4E7]': !active,
              }"
            >
              <span
                aria-hidden="true"
                class="inline-block w-[21px] h-[16px] transition duration-200 ease-in-out transform rounded-full pointer-events-none"
                :class="{
                  'translate-x-5 bg-white': active,
                  'translate-x-0 bg-gray-400': !active,
                }"
              ></span>
            </button>
            <span class="cursor-pointer">
              <span
                class="text-[#353535] text-[16px] font-normal leading-[20px]"
              >
                This item is active
              </span>
            </span>
          </div>
        </div>
        <div class="flex items-center gap-[8px] mt-[16px]">
          <button
            class="border-[##96a0b5] border-solid border-[1.5px] py-[16px] py-[12px] px-[14px] sm:px-[24px] text-[14px] md:text-[16px] rounded-[8px] font-medium leading-[20px] w-[40%] sm:w-full"
            type="button"
            @click.prevent="cancel"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            :disabled="isBusyModal"
            class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] py-[12px] px-[14px] sm:px-[24px] text-[14px] md:text-[16px] rounded-[8px] text-white font-medium leading-[20px] w-[60%] sm:w-full"
            type="submit"
          >
            <span
              v-if="isBusyModal"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <i class="sr-only">Loading...</i>
            </span>
            <span class="text-[14px] md:text-[16px]" v-else-if="showUpdate">
              Update Price Option
            </span>
            <span class="text-[14px] md:text-[16px]" v-else>
              Add Price Option
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required, minLength, minValue } from "vuelidate/lib/validators";
export default {
  name: "AddEditPriceOption",
  props: ["priceOption", "showUpdate", "productId"],

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      id: "",
      name: "",
      description: "",
      optionId: "",
      distributorPrice: 0,
      retailPrice: 0,
      active: false,
    };
  },

  watch: {
    priceOption: function(value) {
      if (value) {
        this.id = value.id;
        this.name = value.name;
        this.optionId = value.optionId ? value.optionId : "";
        this.description = value.description;
        this.distributorPrice = value.distributorPrice;
        this.retailPrice = value.retailPrice;
        this.active = value.active;
      }
    },
  },

  validations: {
    name: {
      required,
      minLength: minLength(3),
    },

    description: {
      required,
    },

    distributorPrice: {
      required,
      minValue: minValue(1),
    },

    retailPrice: {
      required,
      minValue: minValue(1),
    },
  },

  methods: {
    async submit() {
      if (this.showUpdate) {
        await this.update();
      } else {
        await this.create();
      }
    },

    async create() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isBusyModal = false;
        this.isBusy = false;
      } else {
        try {
          this.isBusyModal = true;
          this.isBusy = true;

          const data = {
            name: this.name,
            active: this.active,
            description: this.description,
            distributorPrice: this.distributorPrice,
            retailPrice: this.retailPrice,
            productId: this.productId,
          };

          const response = await this.$http.post("/PriceOptions/create/", data);
          if (response.status === 201 && response.statusText === "Created") {
            this.reset();
            this.$emit("onComplete");
          }

          this.isBusyModal = false;
          this.$toast.success("Price Option added successful", {
            icon: false,
            rtl: false,
          });
          this.isBusy = false;
        } catch (error) {
          this.isBusyModal = false;
          this.isBusy = false;
        }
      }
    },

    async update() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isBusyModal = false;
        this.isBusy = false;
      } else {
        try {
          this.isBusyModal = true;
          this.isBusy = true;

          var data = {
            name: this.name,
            active: this.active,
            description: this.description,
            distributorPrice: this.distributorPrice,
            retailPrice: this.retailPrice,
            productId: this.productId,
          };

          const response = await this.$http.put(
            "/PriceOptions/update/" + this.id,
            data
          );

          if (response.status === 200) {
            this.reset();
            this.$emit("onComplete");
          }

          this.isBusyModal = false;
          this.$toast.success("Price Option updated successful", {
            icon: false,
            rtl: false,
          });
          this.isBusy = false;
        } catch (error) {
          this.isBusy = false;
          this.isBusyModal = false;
        }
      }
    },

    async reset() {
      this.id = "";
      this.name = "";
      this.description = "";
      this.distributorPrice = 0;
      this.retailPrice = 0;
      this.active = false;
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
    },

    cancel() {
      this.reset();
      this.$emit("hide");
    },
  },
};
</script>

<style></style>
