<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div
          :class="[
            'card',
            'card-border-color',
            'card-border-color-primary',
            'be-loading',
            { 'be-loading-active': isBusy },
          ]"
        >
          <div class="card-header">Order # {{ order.orderNumber }}</div>

          <div class="card-body p-1">
            <div class="row">
              <div class="col-6">
                <form>
                  <div class="form-group row">
                    <label class="col-12 col-sm-3 col-form-label text-sm-right"
                      >Order For</label
                    >
                    <div class="col-12 col-sm-8 col-lg-6 col-form-label">
                      <h4 class="font-weight-bold">
                        {{ order.buyer ? order.buyer.displayName : 'N/A' }}
                      </h4>
                    </div>
                    <!-- <div class="pull-right right">
											Outstanding Balance:
											<span
												:style="[
													parseInt(customerOutstandingBalance) <= 0
														? {
																color: 'red',
																'font-size': '15px',
																'font-weight': 'bold',
														}
														: {
																color: '#28C76F',
																'font-size': '15px',
																'font-weight': 'bold',
														},
												]"
												># {{ formatMoney(customerOutstandingBalance) }}</span
											>
										</div> -->
                  </div>
                  <div class="form-group row">
                    <label class="col-12 col-sm-3 col-form-label text-sm-right"
                      >Delivery Address</label
                    >
                    <div class="col-12 col-sm-8 col-lg-6">
                      <textarea
                        class="form-control"
                        placeholder="Delivery address for this order"
                        v-model="deliveryAddress"
                      ></textarea>
                      <ul
                        v-if="v1.deliveryAddress"
                        class="parsley-errors-list filled"
                      >
                        <li class="parsley-required">Required.</li>
                      </ul>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-12 col-sm-3 col-form-label text-sm-right"
                      >Order Date</label
                    >
                    <div class="col-12 col-sm-8 col-lg-6">
                      <input
                        :class="[
                          'form-control',
                          { 'is-invalid': v1.orderDate },
                        ]"
                        type="date"
                        :max="maxDate"
                        placeholder="Select a date"
                        v-model="orderDate"
                      />
                      <ul
                        v-if="v1.orderDate"
                        class="parsley-errors-list filled"
                      >
                        <li class="parsley-required">Required.</li>
                      </ul>
                    </div>
                  </div>
                  <!-- <div v-if="user.role !== 'Sales'" class="form-group row">
										<label class="col-12 col-sm-3 col-form-label text-sm-right"
											>Account Manager</label
										>
										<div class="col-12 col-sm-8 col-lg-6">
											<select
												class="form-control"
												placeholder="Select an Account Manager"
												v-model="salesRepId"
												:disabled="hasManager"
											>
												<option v-for="c in salesReps" :key="c.id" :value="c.id">
													{{ c.text }}
												</option>
											</select>
										</div>

										<span
											v-if="isFetchingIntroducer"
											class="spinner-border text-success spinner-border-sm"
											role="status"
										>
											<i class="sr-only">Loading...</i>
										</span>
									</div> -->
                  <div class="form-group row">
                    <label class="col-12 col-sm-3 col-form-label text-sm-right"
                      >Custom Discount</label
                    >
                    <div class="col-12 col-sm-8 col-lg-6">
                      <input
                        :class="['form-control']"
                        :disabled="orderDetails.length == 0"
                        type="number"
                        :max="total"
                        step="0.01"
                        v-model="discountOnOrder"
                      />
                      <span class="text-danger" v-if="discount > subtotal"
                        >Discount cannot be more than total order amount</span
                      >
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-6" style="border-left:2px solid #e1e1de">
                <form>
                  <div class="form-group row">
                    <label class="col-12 col-sm-4 col-form-label"
                      >Phone Number</label
                    >
                    <div class="col-12 col-sm-8">
                      <input
                        :class="['form-control']"
                        disabled
                        type="number"
                        step="0.01"
                        :value="buyerPhone"
                      />
                      <!-- <span class="text-danger" v-if="discount > subtotal">Discount cannot be more than total order amount</span> -->
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-12 col-sm-4 col-form-label">Email</label>
                    <div class="col-12 col-sm-8 col-lg-6">
                      <input
                        :class="['form-control']"
                        disabled
                        :value="buyerEmailAddress"
                      />
                      <!-- <span class="text-danger" v-if="discount > subtotal">Discount cannot be more than total order amount</span> -->
                    </div>
                  </div>
                  <div v-if="user.role !== 'Sales'" class="form-group row">
                    <label class="col-12 col-sm-4 col-form-label "
                      >Account Manager</label
                    >
                    <div class="col-12 col-sm-8 col-lg-6">
                      <select
                        class="form-control"
                        placeholder="Select an Account Manager"
                        v-model="salesRepId"
                        :disabled="hasManager"
                      >
                        <option
                          v-for="c in salesReps"
                          :key="c.id"
                          :value="c.id"
                        >
                          {{ c.text }}
                        </option>
                      </select>
                    </div>

                    <span
                      v-if="isFetchingIntroducer"
                      class="spinner-border text-success spinner-border-sm"
                      role="status"
                    >
                      <i class="sr-only">Loading...</i>
                    </span>
                  </div>
                  <div class="form-group row">
                    <label class="col-12 col-sm-4 col-form-label"
                      >Ledger Balance</label
                    >
                    <div class="col-12 col-sm-8">
                      <p>
                        <span
                          :style="[
                            parseInt(customerOutstandingBalance) <= 0
                              ? {
                                  color: 'red',
                                  'font-size': '15px',
                                  'font-weight': 'bold',
                                }
                              : {
                                  color: '#28C76F',
                                  'font-size': '15px',
                                  'font-weight': 'bold',
                                },
                          ]"
                          ># {{ formatMoney(customerOutstandingBalance) }}</span
                        >
                      </p>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-12 col-sm-4 col-form-label"
                      >Pay4Me Credit Limit
                    </label>
                    <div class="col-12 col-sm-8">
                      <h3 class="font-weight-bold">
                        # {{ formatMoney(orderPay4MeCredit) }}
                      </h3>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="be-spinner">
            <svg width="40px" height="40px" viewBox="0 0 66 66">
              <circle
                class="circle"
                fill="none"
                stroke-width="4"
                stroke-linecap="round"
                cx="33"
                cy="33"
                r="30"
              ></circle>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="showDetails">
      <div class="col-lg-12">
        <div
          :class="[
            'card',
            'card-border-color',
            'card-border-color-primary',
            'be-loading',
            { 'be-loading-active': isBusy },
          ]"
        >
          <div class="card-heade p-2">
            <button
              class="btn btn-success btn-rounded btn-space float-right"
              @click="viewAdd"
            >
              <span class="icon icon-left mdi mdi-plus"></span>
              Add item
            </button>
            <h4><strong>Order Details</strong></h4>
          </div>
          <div class="col-sm-12 table-responsive">
            <table
              class="table table-fw-widget dataTable no-footer text-nowrap"
            >
              <thead>
                <tr>
                  <th style="width: 20%">Description</th>
                  <th style="width: 10%">Quantity</th>
                  <th style="width: 12%">Unit Price</th>
                  <th style="width: 13%">Sub Total</th>
                  <th style="width: 10%">Discount</th>
                  <th style="width: 25%">Total</th>
                  <th style="width: 10%"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in orderDetails" :key="index">
                  <td>
                    {{ item.name }} ({{ item.code }}) - N{{ item.unitPrice }}/{{
                      item.unit
                    }}
                  </td>
                  <td>{{ item.quantity }}</td>
                  <td>
                    &#8358;
                    {{ formatMoney(item.unitPrice) }}
                  </td>
                  <td>
                    &#8358;
                    {{ formatMoney(item.quantity * item.unitPrice) }}
                  </td>
                  <td>
                    <input type="number" step="0.01" v-model="item.discount" />
                  </td>
                  <td>
                    &#8358;
                    {{
                      formatMoney(
                        item.quantity * item.unitPrice - item.discount
                      )
                    }}
                  </td>
                  <td>
                    <button
                      class="btn btn-secondary btn-rounded btn-space"
                      @click.prevent="openUpdateQuantity(index)"
                    >
                      Edit Quantity
                    </button>
                  </td>
                  <td>
                    <button
                      class="
                        btn btn-danger btn-rounded btn-space
                      "
                      @click.prevent="remove(index)"
                    >
                      Remove
                    </button>
                  </td>
                </tr>
                <tr v-if="!orderDetails.length">
                  <td colspan="6">
                    <div class="text-center">No items in order</div>
                  </td>
                </tr>
                <tr>
                  <td colspan="5"></td>
                  <td class="text-right">
                    <b>SUBTOTAL</b>
                  </td>
                  <td>&#8358; {{ formatMoney(subtotal) }}</td>
                </tr>
                <tr>
                  <td colspan="5"></td>
                  <td class="text-right">
                    <b>DISCOUNT</b>
                  </td>
                  <td>&#8358; {{ formatMoney(discount) }}</td>
                </tr>

                <tr>
                  <td colspan="5"></td>
                  <td class="text-right">
                    <b>BALANCE</b>
                  </td>
                  <td>&#8358; {{ formatMoney(total) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-body" v-if="orderDetails.length">
            <div class="form-group row" v-if="order.creditLimit > 0">
              <div class="col-12 col-sm-12 col-lg-12">
                <label
                  class="custom-control custom-checkbox custom-control-inline"
                >
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    v-model="useCredit"
                  />
                  <span class="custom-control-label">
                    Use credit facilities. Your current balance is
                    <b>&#8358; {{ formatMoney(order.creditLimit) }}</b>
                  </span>
                </label>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12 col-sm-12 col-lg-12">
                <button
                  :disabled="isBusy"
                  class="btn btn-primary btn-xl btn-block"
                  @click.prevent="checkOrder"
                >
                  <span v-if="isBusy">Busy...</span>
                  <span v-else>Place Order</span>
                </button>
              </div>
            </div>
          </div>
          <div class="be-spinner">
            <svg width="40px" height="40px" viewBox="0 0 66 66">
              <circle
                class="circle"
                fill="none"
                stroke-width="4"
                stroke-linecap="round"
                cx="33"
                cy="33"
                r="30"
              ></circle>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-add"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <strong>Add item to list</strong>
            </h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              @click.prevent="onAddCancel"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div v-for="(list, index) in productList" :key="index" class="mt-2">
              <div class="row">
                <div class="col col-10">
                  <div class="form-group row">
                    <label class="col-12 col-sm-3 col-form-label text-sm-right"
                      >Product</label
                    >
                    <div class="col-12 col-sm-8 col-lg-8">
                      <select
                        placeholder="Select an option"
                        class="form-control"
                        v-model="list.productIndex"
                        @input.prevent="onProductSelectNew($event, index)"
                      >
                        <option
                          v-for="(p, index) in filteredProducts"
                          :key="p.id"
                          :value="index"
                        >
                          {{ p.name }} ({{ p.code }}) - N{{
                            formatMoney(p.retailPrice)
                          }}/{{ p.unit }}
                        </option>
                      </select>
                      <ul
                        v-if="list.piError"
                        class="parsley-errors-list filled"
                      >
                        <li class="parsley-required">Required.</li>
                      </ul>
                      <span
                        v-if="list.showCaution"
                        class="bg-warning text-white px-1 rounded"
                        style="padding: 0.2rem"
                      >
                        <i class="fa fa-warning mt-1"></i>
                        <small>Product is <b>Low</b> on stock.</small>
                      </span>

                      <!-- <select
												placeholder="Select an option"
												class="form-control"
												v-model="list.productIndex"
											>
												<option disabled v-if="!filteredProducts.length"
													>No product available</option
												>
												<option
													v-for="(p, index) in filteredProducts"
													:key="p.id"
													:value="index"
												>
													{{ p.name }} ({{ p.code }}) - N{{
														formatMoney(p.retailPrice)
													}}/{{ p.manufacturerProduct.unit }}
												</option>
											</select>
											<ul v-if="list.piError" class="parsley-errors-list filled">
												<li class="parsley-required">Required.</li>
											</ul> -->
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-12 col-sm-3 col-form-label text-sm-right"
                      >Quantity</label
                    >
                    <div class="col-12 col-sm-8 col-lg-8">
                      <input
                        :class="['form-control', { 'is-invalid': v2.quantity }]"
                        step=".01"
                        min="0"
                        max="10"
                        type="number"
                        v-model.number="list.quantity"
                        :disabled="isQuantityLoading"
                        @input="onQuantityChangeNew($event, index)"
                      />
                      <ul v-if="list.qError" class="parsley-errors-list filled">
                        <li class="parsley-required">
                          Enter a number > 0.
                        </li>
                      </ul>

                      <!-- <input
												:class="['form-control', { 'is-invalid': list.qError }]"
												type="number"
												v-model="list.quantity"
											/>
											<ul v-if="list.qError" class="parsley-errors-list filled">
												<li class="parsley-required">Enter a number > 0.</li>
											</ul> -->
                    </div>
                  </div>
                </div>
                <div class="col col-2">
                  <div class="delete-list">
                    <span
                      @click="deleteProductList(index)"
                      style="color:red;cursor:pointer"
                      class="mdi mdi-close"
                    ></span>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <button
                @click="addProductList"
                type="button"
                class="btn btn-text"
                style="color:#766cea"
              >
                <span class="mdi mdi-plus"></span>
                Add another item
              </button>
            </div>

            <!-- <div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Product</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									placeholder="Select an option"
									class="form-control"
									v-model="newItem.productIndex"
									@input="onProductSelect($event)"
								>
									<option
										v-for="(p, index) in filteredProducts"
										:key="p.id"
										:value="index"
									>
										{{ p.name }} ({{ p.code }}) - N{{
											formatMoney(p.retailPrice)
										}}/{{ p.manufacturerProduct.unit }}
									</option>
								</select>
								<ul v-if="v2.productId" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
								<span
									v-if="canShowCaution"
									class="bg-warning text-white px-1 rounded"
									style="padding: 0.2rem"
								>
									<i class="fa fa-warning mt-1"></i>
									<small>Product is <b>Low</b> on stock.</small>
								</span>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Quantity</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', {'is-invalid': v2.quantity}]"
									type="number"
									v-model.number="newItem.quantity"
									:disabled="isQuantityLoading"
									@input="onQuantityChange($event)"
								/>
								<ul v-if="v2.quantity" class="parsley-errors-list filled">
									<li class="parsley-required">Enter a number > 0.</li>
								</ul>
							</div>
						</div> -->
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
              @click.prevent="onAddCancel"
            >
              Cancel
            </button>
            <button
              style="width: 150px"
              :disabled="isBusyModal || isQuantityLoading"
              class="btn btn-primary"
              type="button"
              @click.prevent="add"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Done</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-update-quantity"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <div class="p-1">
              <h4 class="modal-title">
                <strong>Update Product Quantity</strong>
              </h4>
              <span v-if="selectedProductToEdit">{{
                selectedProductToEdit.description
              }}</span>
            </div>

            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              @click.prevent="closeUpdateQuantity"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="p-1">
              <div class="form-group row">
                <label class="col-12 col-sm-5 col-form-label font-weight-bold"
                  >Quantity</label
                >
                <div class="col-12 col-sm-7">
                  <input
                    :class="['form-control']"
                    step=".01"
                    min="0"
                    max="10"
                    type="number"
                    v-model.number="qtyUpdate"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-text"
              type="button"
              data-dismiss="modal"
              @click.prevent="closeUpdateQuantity"
            >
              <span class="text-danger">Cancel</span>
            </button>
            <button
              style="width: 150px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="updateProductQuantity"
            >
              <span>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-customer"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <strong>Select a customer</strong>
            </h4>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Customer</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <multiselect
                  v-model="value"
                  label="text"
                  track-by="id"
                  :options="customers"
                  @select="onSelect"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              @click.prevent="cancel"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="initialize"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Start</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- pay4me Modal -->
    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-pay4me"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <strong>Enter Customer/Merchant Phone Number</strong>
            </h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              @click="closePay4meMod"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body p-3">
            <div class="form-group row">
              <label class="col-12 font-weight-bold col-sm-4 col-form-label "
                >Phone Number</label
              >
              <div class="col-12 col-sm-6">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': payPhoneNumberInvalid },
                  ]"
                  step=".01"
                  min="0"
                  max="10"
                  type="number"
                  placeholder="11 digits"
                  v-model.trim="payPhoneNumber"
                  :disabled="pay4meStep > 1"
                />
                <ul
                  v-if="payPhoneNumberInvalid"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">
                    Number must be 11 digits.
                  </li>
                </ul>
              </div>
              <div class="col-12 col-sm-2">
                <button
                  v-if="pay4meStep === 2"
                  class="btn btn-text"
                  type="button"
                  @click="changePay4meStep(1)"
                >
                  <span class="text-primary" style="text-decoration:underline"
                    >Change</span
                  >
                </button>
              </div>
            </div>
            <div v-if="pay4meStep === 2" class="mt-2">
              <!-- <p>
								Great news! Your customer <strong>Rachael Flynn</strong> with phone number <strong>{{payPhoneNumber}}</strong> can get up to <strong>NGN450,000.00</strong> from Growtrade Pay4Me to buy goods from you.
								 Click the Continue button below to generate an order for your customer.
							</p> -->
              <p>
                {{ nextStepMsg }}
              </p>
            </div>
            <!-- <div v-if="pay4meStep === 3" class="mt-2">
							<p>
								We will need some more information from your customer before activating them on Pay4me. Our team will engage them in the next 2 days so that you can sell to them without risk and enjoy repeat business.
							</p>
						</div> -->
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
              @click="closePay4meMod"
            >
              Cancel
            </button>
            <button
              v-if="pay4meStep === 1"
              style="width: 100px"
              :disabled="isBusyPay4Me"
              class="btn btn-primary"
              type="button"
              @click="checkCustomerPay4Me"
            >
              <span v-if="isBusyPay4Me">Busy...</span>
              <span v-else>Check</span>
            </button>
            <button
              v-if="pay4meStep === 2 && pay4Status === true"
              style="width: 100px"
              :disabled="isBusyPay4Me"
              class="btn btn-primary"
              type="button"
              @click="gotoOrdersPage"
            >
              <span v-if="isBusyPay4Me">Busy...</span>
              <span v-else>Confirm</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <CautionModal :products="bridgedProducts" @makeOrder="makeOrder" />
  </div>
</template>

<script>
/* eslint-disable */

import moment from 'moment';
import { helper } from '@/helper';
import Select2 from '@/components/Select2';
import CautionModal from '../components/CautionModal';
import SystemPermissions from '@/permissions';
import Multiselect from 'vue-multiselect';

Array.prototype.sum = function(prop1, prop2) {
  var total = 0;
  for (var i = 0, _len = this.length; i < _len; i++) {
    total += this[i][prop1] * this[i][prop2];
  }
  return total;
};

export default {
  mixins: [SystemPermissions.Mixin],
  name: 'ordersCreateAlt',

  components: {
    Select2,
    CautionModal,
    Multiselect,
  },

  data() {
    return {
      value: '',
      canShowCaution: false,
      productInventoryTotal: 0,
      isQuantityLoading: false,
      customers: [],
      buyerId: null,
      showDetails: false,
      isBusy: false,
      isBusyModal: false,
      order: {},
      sellerId: null,
      deliveryAddress: null,
      products: [],
      filteredProducts: [],
      productList: [
        {
          productIndex: null,
          piError: false,
          quantity: 0,
          qError: false,
          inventoryTotal: 0,
          showCaution: false,
          allowDecimalQuantity: false,
        },
      ],
      bridgedProducts: [],
      orderDetails: [],
      discountOnOrder: 0.0,
      discount: 0.0,
      tax: 0.0,
      subtotal: 0.0,
      total: 0.0,
      useCredit: false,
      newItem: {},
      salesReps: [],
      salesRepId: null,
      orderDate: moment().format('YYYY-MM-DD'),
      customerOutstandingBalance: 0,
      v1: {
        sellerId: false,
        deliveryAddress: false,
        orderDate: false,
      },
      v2: {
        productId: false,
        quantity: false,
      },
      discountObj: {},
      isFetchingIntroducer: false,
      hasManager: false,
      maxDate: '',
      selectedProductToEdit: null,
      selectedProductIndex: null,
      qtyUpdate: '',
      orderPay4MeCredit: 0,
      pay4meStep: 1,
      nextStepMsg: '',
      pay4Status: null,
      pay4Limit: 0,
      payPhoneNumber: '',
      payUserId: '',
      isBusyPay4Me: false,
      payPhoneNumberInvalid: false,
    };
  },

  async created() {
    if (!this.hasPermission('Orders_Create')) {
      this.$router.push('/restricted');
    }

    this.getCustomers();
    this.getSalesReps();

    const id = this.$route.params.id;

    if (id) {
      this.buyerId = id;
      await this.initialize();
    }
  },

  mounted() {
    this.isBusy = true;
    if (!this.$route.params.id && !this.$route.query.pay4me) {
      let options = { backdrop: 'static', keyboard: false };
      $('#mod-customer').modal(options, 'show');
    }
    if (this.$route.query.pay4me) {
      let options = { backdrop: 'static', keyboard: false };
      $('#mod-pay4me').modal(options, 'show');
    }
    if (this.$route.query.credit) {
      this.orderPay4MeCredit = this.$route.query.credit;
    }
    this.maxDate = helper.preventFutureDateSelection();
    this.isBusy = false;
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
    buyerPhone: function() {
      if (this.order && this.order.buyer && this.order.buyer.phoneNumber) {
        return this.order.buyer.phoneNumber;
      }
      return '';
    },
    buyerEmailAddress: function() {
      if (this.order && this.order.buyer && this.order.buyer.emailAddress) {
        return this.order.buyer.emailAddress;
      }
      return '';
    },
  },

  watch: {
    orderDetails: {
      handler(newVal, oldVal) {
        this.subtotal = this.orderDetails.sum('quantity', 'unitPrice');
        this.discount = parseFloat(this.discountOnOrder);

        if (this.discountObj) {
          this.discount +=
            this.discountObj.type == 'Percent'
              ? this.subtotal * (this.discountObj.value / 100)
              : this.discountObj.value;
        }

        for (var i = 0, _len = this.orderDetails.length; i < _len; i++) {
          if (
            this.orderDetails[i].discount !== 0 &&
            this.orderDetails[i].discount !== ''
          )
            this.discount += parseFloat(this.orderDetails[i].discount);
        }

        this.tax = 0;
        this.total = this.subtotal - this.tax - this.discount;
      },
      deep: true,
    },

    discountOnOrder: function(newVal, oldVal) {
      if (oldVal) {
        this.discount -= parseFloat(oldVal);
      }
      if (newVal) {
        this.discount += parseFloat(newVal);
      }
      this.total = this.subtotal - this.tax - this.discount;
    },
  },

  methods: {
    openPay4meMod() {
      $('#mod-pay4me').modal('show');
    },
    closePay4meMod() {
      $('#mod-pay4me').modal('hide');
      this.pay4meStep = 1;
      this.nextStepMsg = '';
      this.pay4Status = null;
      this.pay4Limit = 0;
      this.payPhoneNumber = '';
      this.payUserId = '';
      this.payPhoneNumberInvalid = false;
      let options = { backdrop: 'static', keyboard: false };
    },
    changePay4meStep(step) {
      this.pay4meStep = step;
    },
    gotoOrdersPage() {
      $('#mod-pay4me').modal('hide');
      //initialize order with buyer id
      this.$router.push(
        `/orders/create/${this.payUserId}?credit=${this.pay4Limit}`
      );
      this.$router.go();
    },
    async checkCustomerPay4Me() {
      if (!this.validatePayNumber()) {
        return;
      }
      let phNo = this.payPhoneNumber.slice(1);
      this.isBusyPay4Me = true;
      await this.$http
        .get(`/Credits/check/234${phNo}`)
        .then(function(res) {
          this.pay4Limit = res.body.creditLimit;
          this.nextStepMsg = res.body.message;
          this.pay4Status = res.body.status;
          this.payUserId = res.body.userId;
          this.isBusyPay4Me = false;
          this.pay4meStep = 2;
        })
        .catch(function() {
          this.isBusyPay4Me = false;
        });
    },

    validatePayNumber() {
      if (!this.payPhoneNumber || this.payPhoneNumber.length !== 11) {
        this.payPhoneNumberInvalid = true;
        return false;
      }

      this.payPhoneNumberInvalid = false;
      return true;
    },
    async getCustomers() {
      await this.$http
        .get('/Users/getItemListOfCustomers/' + this.user.parentId)
        .then(function(res) {
          this.customers = res.body;
        })
        .catch(function() {});
    },

    async getSalesReps() {
      await this.$http
        .get('/Users/getItemListOfIntroducers/' + this.user.parentId)
        .then(function(res) {
          this.salesReps = res.body;
        })
        .catch(function() {});
    },

    // async getCustomerOutstandingBalance() {
    // 	await this.$http
    // 		.get("/Orders/getCustomerOutstandingBalance/" + this.buyerId)
    // 		.then(function(res) {
    // 			this.customerOutstandingBalance = res.body;
    // 		})
    // 		.catch(function() {});
    // },

    async getIntroducer() {
      this.isFetchingIntroducer = true;
      try {
        const response = await this.$http.get(
          `/Users/${this.buyerId}/introducer`
        );
        if (response.ok) {
          this.salesRepId = response.body;
          this.hasManager = this.salesRepId !== null && this.salesRepId !== '';
        }
      } catch (error) {}

      this.isFetchingIntroducer = false;
    },

    cancel() {
      $('#mod-customer').modal('hide');
      this.$router.push('/orders');
    },

    onSelect(option) {
      this.buyerId = option.id;
    },

    async initialize() {
      if (this.buyerId) {
        this.isBusy = true;
        this.isBusyModal = true;

        // this.getCustomerOutstandingBalance();

        await this.$http
          .get('/Orders/initialize/' + this.buyerId)
          .then(function(res) {
            this.order = res.body;
            this.deliveryAddress = res.body.buyer.deliveryAddress;
            this.customerOutstandingBalance = res.body.buyer.accountBalance;
          })
          .catch(function() {
            $('#mod-customer').modal('hide');
            this.$router.push('/orders');
          });

        this.onSellerChange(this.user.parentId);
        this.getIntroducer();

        this.isBusyModal = false;
        this.isBusy = false;

        $('#mod-customer').modal('hide');
      }
    },

    viewAdd() {
      (this.productList = [
        {
          productIndex: null,
          piError: false,
          quantity: 0,
          qError: false,
          inventoryTotal: 0,
          showCaution: false,
        },
      ]),
        (this.newItem = {
          productIndex: null,
          quantity: null,
        });

      this.v2 = {
        productId: false,
        quantity: false,
      };

      var fProducts = [];

      var details = this.orderDetails;

      this.products.forEach(function(e) {
        if (!details.some((s) => s.code == e.code)) {
          fProducts.push(e);
        }
      });

      this.filteredProducts = fProducts;
      $('#mod-add').modal('show');
    },

    add() {
      if (this.validateAdd()) {
        for (var i = 0; i < this.productList.length; i++) {
          var p = {
            name: this.filteredProducts[this.productList[i].productIndex].name,
            unit: this.filteredProducts[this.productList[i].productIndex].unit,
            code: this.filteredProducts[this.productList[i].productIndex].code,
            description: `${
              this.filteredProducts[this.productList[i].productIndex].name
            } (${
              this.filteredProducts[this.productList[i].productIndex].unit
            })`,
            unitPrice: this.filteredProducts[this.productList[i].productIndex]
              .retailPrice,
            quantity: this.productList[i].quantity,
            discount: 0,
          };
          this.orderDetails.push(p);
        }

        $('#mod-add').modal('hide');
        this.canShowCaution = false;
      }
    },

    addProductList() {
      this.productList.push({
        productIndex: null,
        piError: false,
        quantity: 0,
        qError: false,
        inventoryTotal: 0,
        showCaution: false,
      });
    },

    deleteProductList(index) {
      this.productList.splice(index, 1);
    },

    // validateAdd() {
    // 	this.v2 = {
    // 		productId: false,
    // 		quantity: false,
    // 	};

    // 	this.orderDetails.push(p);

    // 	$("#mod-add").modal("hide");
    // 	this.canShowCaution = false;
    // },

    validateAdd() {
      this.v2 = {
        productId: false,
        quantity: false,
      };
      var isValid = true;
      let firstProductName = '';

      for (var i = 0; i < this.productList.length; i++) {
        if (this.productList.length > 0)
          firstProductName = this.filteredProducts[
            this.productList[0].productIndex
          ].name;

        if (firstProductName !== '' && i > 0) {
          if (
            firstProductName ===
            this.filteredProducts[this.productList[i].productIndex].name
          ) {
            this.$toast.warning('You cannot add list of the same product', {
              icon: true,
              rtl: false,
            });
            return (isValid = false);
          }
        }

        console.log(this.productList[i].inventoryTotal);
        if (this.productList[i].productIndex === null) {
          this.productList[i].piError = true;
          isValid = false;
        }

        if (Number(this.productList[i].quantity) <= 0) {
          this.productList[i].qError = true;
          isValid = false;
        }
      }

      // if (this.newItem.productIndex < 0) {
      // 	this.v2.productId = true;
      // 	isValid = false;
      // }

      // if (Number(this.newItem.quantity) <= 0) {
      // 	this.v2.quantity = true;
      // 	isValid = false;
      // }

      return isValid;
    },

    onSellerChange(id) {
      this.sellerId = id;
      this.showDetails = true;
      this.orderDetails = [];
      this.getProducts(id);
      this.getDiscount();
    },

    async getProducts(parentId) {
      this.products = [];

      await this.$http
        .get(
          '/Products/getListNoPagingForDistributor?parentId=' +
            parentId +
            '&active=true'
        )
        .then(function(res) {
          this.products = res.body;
        })
        .catch(function() {});
    },

    remove(index) {
      this.orderDetails.splice(index, 1);
    },

    openUpdateQuantity(index) {
      this.selectedProductIndex = index;
      this.selectedProductToEdit = this.orderDetails[index];
      this.qtyUpdate = this.orderDetails[index].quantity;
      $('#mod-update-quantity').modal('show');
    },

    closeUpdateQuantity() {
      $('#mod-update-quantity').modal('hide');
      this.qtyUpdate = '';
      this.selectedProductIndex = null;
      this.selectedProductToEdit = null;
    },

    updateProductQuantity() {
      var tempProd = this.orderDetails;
      tempProd[this.selectedProductIndex].quantity = this.qtyUpdate;
      this.orderDetails = tempProd;
      this.closeUpdateQuantity();
    },

    updateStatus() {
      $('#mod-change').modal('hide');
    },

    checkOrder() {
      if (this.validateOrder()) {
        if (this.checkProductsStatus()) $('#mod-caution').modal('show');
        else this.makeOrder();
      }
    },

    async makeOrder() {
      $('#mod-caution').modal('hide');
      this.isBusy = true;
      this.bridgedProducts = [];

      if (this.validateOrder()) {
        this.orderDetails.forEach((element) => {
          if (element.discount === '') element.discount = 0;
        });

        const data = {
          orderNumber: this.order.orderNumber,
          subtotal: this.subtotal,
          discount: this.discount !== '' ? this.discount : 0,
          tax: this.tax,
          total: this.total,
          parentId: this.user.parentId,
          sellerId: this.sellerId,
          makerId: this.buyerId,
          details: this.orderDetails,
          useCredit: this.useCredit,
          deliveryAddress: this.deliveryAddress,
          introducerId: this.salesRepId,
          orderDate: this.orderDate,
        };

        await this.$http
          .post('/Orders/create', data)
          .then(function(res) {
            this.isBusy = false;
            this.$router.push('/orders');
          })
          .catch(function() {});

        this.isBusy = false;
      } else {
        this.isBusy = false;
      }
    },

    validateOrder() {
      this.v1 = {
        sellerId: false,
        deliveryAddress: false,
        orderDate: false,
      };
      var isValid = true;

      if (!this.sellerId) {
        this.v1.sellerIs = true;
        isValid = false;
      }

      if (!this.deliveryAddress) {
        this.v1.deliveryAddress = true;
        isValid = false;
      }

      if (!this.orderDate) {
        this.v1.orderDate = true;
        isValid = false;
      }

      return isValid;
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    async getDiscount() {
      await this.$http
        .get(
          '/Discounts/getForOrder?buyerId=' +
            this.buyerId +
            '&sellerId=' +
            this.sellerId
        )
        .then(function(res) {
          this.discountObj = res.body;
        })
        .catch(function() {});
    },

    // async onProductSelect(event) {
    // 	debugger
    // 	this.newItem.productIndex = event.target.value;
    // 	this.isQuantityLoading = true;
    // 	try {
    // 		const productId = this.filteredProducts[event.target.value].id;
    // 		const response = await this.$http.get(
    // 			"/Inventories/getProductInventoriesTotal/" + productId,
    // 		);

    // 		if (response.ok && response.status === 200)
    // 			this.productInventoryTotal = response.body;

    // 		this.isQuantityLoading = false;
    // 	} catch (error) {
    // 		this.isQuantityLoading = false;
    // 	}
    // },

    async onProductSelectNew(event, index) {
      this.productList[index].productIndex = event.target.value;

      this.isQuantityLoading = true;
      try {
        const productId = this.filteredProducts[event.target.value].id;
        const response = await this.$http.get(
          '/Inventories/getProductInventoriesTotal/' + productId
        );

        if (response.ok && response.status === 200) {
          this.productList[index].inventoryTotal = response.body;
          console.log('response', response);
        }
        this.isQuantityLoading = false;
      } catch (error) {
        this.isQuantityLoading = false;
      }
    },

    // onQuantityChange(event) {
    // 	const product = this.filteredProducts[this.newItem.productIndex];

    // 	this.canShowCaution =
    // 		this.productInventoryTotal - event.target.value <= product.minimumStockLevel;
    // },

    onQuantityChangeNew(event, index) {
      const product = this.filteredProducts[index];

      this.productList[index].showCaution =
        this.productList[index].inventoryTotal - event.target.value <=
        product.minimumStockLevel;
    },

    onAddCancel() {
      this.canShowCaution = false;
    },

    checkProductsStatus() {
      this.orderDetails.forEach((detail) => {
        for (let index = 0; index < this.products.length; index++) {
          const product = this.products[index];

          if (
            detail.code === product.code &&
            product.quantity - detail.quantity < 0
          ) {
            this.bridgedProducts.push({
              name: detail.name,
              neededQuantity: detail.quantity - product.quantity,
              unit: detail.unit,
            });
            break;
          }
        }
      });

      return this.bridgedProducts.length > 0;
    },
  },
};
</script>
