<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-growtrade-onboarding-status"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1">
            <strong>Register Your Account</strong>
          </h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
            @click="cancel"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="parent-div success-div">
              <div class="first-child">
                <div class="second-child">
                  <svg width="20" height="15" viewBox="0 0 20 15" fill="none">
                    <path
                      d="M19.599 0.410713C19.2129 0.0245541 18.5869 0.0245541 18.2007 0.410713L6.35464 12.2568L1.80123 7.70343C1.41511 7.31727 0.789098 7.31731 0.4029 7.70343C0.0167416 8.08955 0.0167416 8.71556 0.4029 9.10172L5.65548 14.3542C6.04148 14.7403 6.66796 14.7401 7.05381 14.3542L19.599 1.80904C19.9851 1.42292 19.9851 0.796872 19.599 0.410713Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <p class="text-center text-bold">
            Your account has been successfully created <br />
            Proceed to login
          </p>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" @click.prevent="goToLogin" type="button">
            <span> Log in </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "registration-status-modal",

  data() {
    return {
      // client: null,
    };
  },

  // created() {
  //   const client = this.$route.query.client;
  //   this.client = client;
  // },

  methods: {
    cancel() {
      $("#mod-growtrade-onboarding-status").modal("hide");
    },

    goToLogin() {
      $("#mod-growtrade-onboarding-status").modal("hide");
      this.$router.push(`/growtrade-login`);
    },
  },
};
</script>

<style scoped>
.parent-div {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px auto 34px;
  width: 110px;
  height: 110px;
}

.first-child {
  width: 86.52px;
  height: 86.52px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.second-child {
  width: 61.8px;
  height: 61.8px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.success-div {
  border: 1px solid rgba(182, 201, 121, 0.2);
}

.success-div .first-child {
  border: 1px solid rgba(182, 201, 121, 0.5);
}

.success-div .second-child {
  background: rgba(182, 201, 121, 1);
}

.text-bold {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}
</style>
