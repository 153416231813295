<template>
  <Distributor />
</template>
<script>
import Distributor from "@/components/Distributors/distributors.vue";
export default {
  name: "navBar",

  components: {
    Distributor,
  },
};
</script>
