<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <router-link
            class="btn btn-space btn-danger btn-rounded"
            :to="backPath"
          >
            <span class="icon icon-left mdi mdi-arrow-left text-white"></span>
            Back
          </router-link>

          <button
            class="btn btn-danger btn-rounded btn-space float-right"
            @click="removeAll"
            v-if="hasPermission('Role_User_Delete')"
          >
            Remove All Role Users
          </button>
        </div>
        <div class="card-body">
          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table
                  class="
                    table table-fw-widget
                    dataTable
                    no-footer
                    table-responsiv table-striped
                  "
                >
                  <thead>
                    <tr>
                      <th style="width: 20%">User</th>
                      <th style="width: 20%">Status</th>
                      <th style="width: 10%">Staff ID</th>
                      <th style="width: 20%">Created</th>
                      <th style="width: 30%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="u in roleUsers" :key="u.id">
                      <td>
                        <b>
                          <span v-if="u.displayName">
                            {{ u.displayName }}
                            <br />
                          </span>
                        </b>
                        {{ u.emailAddress ? u.emailAddress : u.phoneNumber }}
                        <br />
                        <span
                          style="
                            color: gray;
                            font-size: 11px;
                            font-weight: bold;
                          "
                          >{{ u.username }}</span
                        >
                      </td>
                      <td>
                        <span
                          :class="[
                            'badge',
                            ' badge-pill',
                            { 'badge-primary': u.status == 'Pending' },
                            { 'badge-success': u.status == 'Active' },
                            { 'badge-danger': u.status == 'Disabled' },
                          ]"
                          >{{
                            u.status == "Pending" ? "Invitation sent" : u.status
                          }}</span
                        >
                      </td>
                      <td>{{ u.staffId ? u.staffId : "N/A" }}</td>
                      <td>{{ formatDate(u.created) }}</td>
                      <td>
                        <div
                          class="text-right"
                          v-if="hasPermission('Role_User_Delete')"
                        >
                          <button
                            v-if="u.parentId == user.parentId"
                            class="btn btn-danger btn-sm btn-rounded btn-space"
                            @click.prevent="remove(u)"
                          >
                            <i class="feather icon-edit"></i> &nbsp;Remove
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!roleUsers.length">
                      <td colspan="5">
                        <div class="text-center" style="padding-top: 50px">
                          <span
                            style="font-size: 4.615rem"
                            class="mdi mdi-crosshairs-gps"
                          ></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom: 100px">
                          You don't have any users assigned to this role.
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }} user{{ count != 1 ? "s" : "" }}
                </div>
              </div>
              <div class="col-sm-7">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination">
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasPrev },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="prev"
                        >Previous</a
                      >
                    </li>
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasNext },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="next"
                        >Next</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import Select2 from "@/components/Select2";
import SystemPermissions from "@/permissions";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsRoleUsers",
  components: {
    Select2,
  },
  created() {
    if (!this.hasPermission("Role_User_View")) {
      this.$router.push("/restricted");
    }
    this.getRoleUsers();
  },
  watch: {},
  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      roleUsers: [],
      count: 0,
      page: 0,
      pageSize: 10,
      hasNext: false,
      hasPrev: false,
    };
  },
  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
    backPath: function () {
      return this.$store.state.prevRoute;
    },
  },
  methods: {
    async remove(c) {
      var roleId = this.$route.params.id;
      //var roleId = this.$route.query.r;
      if (
        confirm("Are you sure you want to remove this user from this role?")
      ) {
        this.isBusyModal = true;
        this.isBusy = true;

        await this.$http
          .put("/Roles/unassign/" + roleId + "/" + c.id, null)
          .then(function (res) {
            this.isBusyModal = false;
          })
          .catch(function () {});

        this.getRoleUsers();

        this.isBusy = false;
      }
    },
    async removeAll() {
      var roleId = this.$route.params.id;
      //var roleId = this.$route.query.r
      if (
        confirm("Are you sure you want to remove all users from this role?")
      ) {
        this.isBusyModal = true;
        this.isBusy = true;

        await this.$http
          .put("/Roles/unassignAll/" + roleId, null)
          .then(function (res) {
            this.isBusyModal = false;
          })
          .catch(function () {});

        this.getRoleUsers();

        this.isBusy = false;
      }
    },
    async getRoleUsers() {
      var roleId = this.$route.params.id;
      //var roleId = this.$route.query.r
      this.isBusy = true;

      await this.$http
        .get(
          "/Roles/getAssignedUserList?page=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            ("&roleId=" + roleId)
        )
        .then(function (res) {
          this.roleUsers = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function () {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },
    formatDate(date) {
      return moment(date).format("lll");
    },
    formatMoney(val) {
      return helper.formatMoney(val);
    },
    prev() {
      this.page -= 1;
      this.getRoleUsers();
    },
    next() {
      this.page += 1;
      this.getRoleUsers();
    },
  },
};
</script>