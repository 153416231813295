<template>
  <div class="row">
    <div class="col">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header d-flex justify-content-end">
          <div class="btn-group" role="group" aria-label="Basic example">
            <router-link
              class="btn btn-primary btn-rounded btn-space"
              :to="{
                path: `mySales/new`,
              }"
            >
              <i class="icon icon-left mdi mdi-plus text-white"></i>
              New Sales Order
            </router-link>

            <button class="btn btn-success btn-rounded btn-space" @click="filter">
              <i class="feather icon-filter text-white"></i>
              Filter
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="dataTables_wrapper container-fluid dt-bootstrap4">
            <div class="row be-datatable-body max-height">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer text-nowrap">
                  <thead>
                    <tr>
                      <th>Creation Date</th>
                      <th>PO Number</th>                      
                      <th>Order Total</th>
                      <th>Distributor NAme</th>
                      <th>Distributor Source Id</th>
                      <th>Status</th>
                      <th>Last Updated</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="po in purchaseOrders" :key="po.id">
                      <td>
                        {{ formatDate(po.created) }}
                      </td>
                      <td>{{ po.orderNumber }}</td>
                      <Amount :details="po.details" />
                      <td>{{ po.parent.name }}</td>
                      <td>{{ po.parent.sourceId }}</td>
                      <td>
                        <span
                          :class="[
                            'badge',
                            ' badge-pill',
                            {
                              'badge-success': po.status === 'confirmed',
                            },
                            {
                              'badge-danger': po.status === 'withdrawn',
                            },
                            {
                              'badge-secondary': po.status === 'draft',
                            },
                            {
                              'badge-warning': po.status === 'submitted',
                            },
                            {
                              'badge-dark': po.status === 'recalled',
                            },
                          ]"
                          >{{ po.stage ? po.stage.name : po.status }}</span
                        >
                      </td>
                      <td>
                        {{ formatDate(po.updated) }}
                      </td>
                      <td>
                        <div class="dropdown btn btn-default">
                            <button class="btn btn-primary btn-rounded btn-space float-right dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              Action
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                              <router-link
                                class=" dropdown-item
                                  btn btn-default btn-sm
                                "
                                v-if="po.status !== 'confirmed' && po.status !== 'CLOSED'"
                                :to="{
                                  path: `mySales/${po.id}/edit`,
                                }"
                              >
                                <i class="icon mdi mdi-circle-edit-outline"></i>
                                Edit
                              </router-link>
                              <router-link
                                v-if="
                                  po.status === 'submitted' ||
                                    po.status === 'confirmed' ||
                                    po.status === 'recalled' ||
                                    po.status === 'paid' ||
                                    po.status === 'withdrawn' ||
                                    po.status === 'CLOSED'
                                "
                                class="btn btn-default btn-sm btn-space dropdown-item"
                                :to="{
                                  path: `mySales/${po.id}/view`,
                                }"
                              >
                                <i class="icon mdi mdi-eye-outline"></i>
                                View
                              </router-link>
                              <SubmitPurchaseOrder
                                class="dropdown-item"
                                v-if="po.status === 'draft'"
                                :purchaseOrder="po"
                                @onComplete="onActionComplete"
                                @showAction="onShowAction"
                                :ref="po.id"
                                userType= "salesAgent"
                              />
                              <SwitchPurchaseOrder
                              class="dropdown-item"
                                v-if="po.status === 'submitted'"
                                :purchaseOrder="po"
                                @onComplete="onActionComplete"
                                @showAction="onShowAction"
                                :ref="po.id"
                                userType= "salesAgent"
                              />
                              <DeletePurchaseOrder
                              class="dropdown-item"
                                v-if="po.status === 'draft' || po.status === 'withdrawn'"
                                :purchaseOrder="po"
                                @onComplete="onActionComplete"
                                @showAction="onShowAction"
                                :ref="po.id"
                                userType= "salesAgent"
                              />
                            </div>
                          </div>
                        <!-- <router-link
                          class="
                            btn btn-success btn-sm btn-rounded btn-space
                            mr-1
                          "
                          v-if="po.status !== 'confirmed' && po.status !== 'CLOSED'"
                          :to="{
                            path: `mySales/${po.id}/edit`,
                          }"
                        >
                          <i class="icon mdi mdi-circle-edit-outline text-white"></i>
                        </router-link>
                        <router-link
                          v-if="
                            po.status === 'submitted' ||
                              po.status === 'confirmed' ||
                              po.status === 'recalled' ||
                              po.status === 'paid' ||
                              po.status === 'withdrawn' ||
                              po.status === 'CLOSED'
                          "
                          class="btn btn-info btn-sm btn-rounded btn-space mr-1"
                          :to="{
                            path: `mySales/${po.id}/view`,
                          }"
                        >
                          View
                        </router-link>
                        <SubmitPurchaseOrder
                          v-if="po.status === 'draft'"
                          :purchaseOrder="po"
                          @onComplete="onActionComplete"
                          @showAction="onShowAction"
                          :ref="po.id"
                          userType= "salesAgent"
                        />
                        <SwitchPurchaseOrder
                          v-if="po.status === 'submitted'"
                          :purchaseOrder="po"
                          @onComplete="onActionComplete"
                          @showAction="onShowAction"
                          :ref="po.id"
                          userType= "salesAgent"
                        />
                        <DeletePurchaseOrder
                          v-if="po.status === 'draft' || po.status === 'withdrawn'"
                          :purchaseOrder="po"
                          @onComplete="onActionComplete"
                          @showAction="onShowAction"
                          :ref="po.id"
                          userType= "salesAgent"
                        /> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Pagination
                  :limit="pageSize"
                  :count="pageCount"
                  :page="pageNumber"
                  :itemsCount="count"
                  @changePage="gotoPage"
                  @changeLimit="changeLimit"
                />
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }} Purchase order{{ count != 1 ? "s" : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ActionStatusModal
      :action="action"
      :purchaseNumber="purchaseNumber"
      @onDelete="perfomDelete"
      @onWithdraw="performWithdraw"
      @onSubmit="performSubmit"
      userType= "salesAgent"
    />
    <FilterPurchaseOrder @onFilter="performFiltering" />
  </div>
</template>

<script>
import moment from "moment";
import { helper } from "@/helper";

import DeletePurchaseOrder from "../../components/PurchaseOrder/DeletePurchaseOrder";
import SwitchPurchaseOrder from "../../components/PurchaseOrder/SwitchPurchaseOrder";
import SubmitPurchaseOrder from "../../components/PurchaseOrder/SubmitPurchaseOrder";
import ActionStatusModal from "../../components/PurchaseOrder/ActionStatusModal";
import FilterPurchaseOrder from "../../components/PurchaseOrder/FilterPurchaseOrder";
import Pagination from "../../components/Pagination.vue";
import Amount from "../../components/SettingsPurchaseOrder/Amount";

export default {
  components: {
    DeletePurchaseOrder,
    SwitchPurchaseOrder,
    SubmitPurchaseOrder,
    ActionStatusModal,
    FilterPurchaseOrder,
    Amount,
    Pagination,
  },

  data() {
    return {
      isBusy: false,
      count: 0,
      pageNumber: 0,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
      purchaseOrders: [],
      status: "",
      orderStages: [],
      orderNumber: "",
      purchaseNumber: "",
      action: "",
      actionOwner: "",
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  created() {
    this.getPurchaseOrders();
    this.getOrderStages();
  },

  methods: {
    async getOrderStages() {
      try {
        const response = await this.$http.get("/PurchaseOrders/orderStage/getList?active=true");

        if (response.ok && response.status === 200) {
          this.orderStages = response.body.sort(
            (firstItem, secondItem) => firstItem.stage - secondItem.stage
          );
        }
      } catch (error) {}
    },
    filter() {
      // eslint-disable-next-line no-undef
      this.pageNumber = 0;
      $("#mod-purchase-filter").modal("show");
    },

    onActionComplete() {
      this.getPurchaseOrders();
    },

    onShowAction(actionDetails) {
      this.purchaseNumber = actionDetails.purchaseNumber;
      this.action = actionDetails.action;
      this.actionOwner = actionDetails.owner;

      // eslint-disable-next-line no-undef
      $("#mod-action").modal("show");
    },

    hideAction() {
      // eslint-disable-next-line no-undef
      $("#mod-action").modal("hide");
    },

    async performFiltering(filteringOptions) {
      this.orderNumber = filteringOptions.orderNumber;
      this.status = filteringOptions.status;

      this.getPurchaseOrders();
      // eslint-disable-next-line no-undef
      $("#mod-purchase-filter").modal("hide");
    },

    async performSubmit() {
      this.hideAction();
      const component = this.$refs[this.actionOwner][0];
      component.switchPurchaseOrder();
      this.resetAction();
    },

    async performWithdraw() {
      this.hideAction();
      const component = this.$refs[this.actionOwner][0];
      component.withdrawPurchase();
      this.resetAction();
    },

    async perfomDelete() {
      this.hideAction();
      const component = this.$refs[this.actionOwner][1];
      component.deletePurchaseOrder();
      this.resetAction();
    },

    resetAction() {
      this.purchaseNumber = "";
      this.action = "";
      this.actionOwner = "";
    },

    async getPurchaseOrders() {
      try {
        this.isBusy = true;

        const response = await this.$http.get(
          "/PurchaseOrders/salesAgentgetList?OrderNumber=" +
            this.orderNumber +
            "&Status=" +
            this.status +
            "&PageNumber=" +
            this.pageNumber +
            "&PageSize=" +
            this.pageSize
        );

        if (response.ok && response.status === 200) {
          this.purchaseOrders = response.body.data;
          this.pageNumber = response.body.pageNumber;
          this.pageSize = response.body.pageSize;
          this.count = response.body.count;
          this.pageCount = Math.ceil(response.body.count / response.body.pageSize);
          this.hasPrev = response.body.hasPrev;
          this.hasNext = response.body.hasNext;
        }
      } catch (error) {
        this.isBusy = false;
      }
    },

    formatDate(date) {
      if (date) return moment(date).format("lll");
      else return "";
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    prev() {
      this.pageNumber -= 1;
      this.getPurchaseOrders();
    },

    next() {
      this.pageNumber += 1;
      this.getPurchaseOrders();
    },

    gotoPage(page) {
      this.pageNumber = page;
      this.getPurchaseOrders();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getPurchaseOrders();
    },
  },
};
</script>
