<template>
  <div>
    <div class="table-responsive">
      <table class="table p-0 m-0 dataTable no-footer">
        <thead class="bg-white">
          <tr class="py-[5px]">
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Name
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Status
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Role
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Branch
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Type
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Staff ID
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Actions
            </th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="u in users" :key="u.id">
            <td>
              <b>
                <span
                  class="text-[14px] font-medium leading-[16.94px] text-[#353535]"
                  v-if="u.displayName"
                >
                  {{ u.displayName }}
                  <br />
                </span>
              </b>
              <span
                class="text-[14px] font-medium leading-[16.94px] text-[#96a0b5]"
                >{{ u.emailAddress ? u.emailAddress : u.phoneNumber }}</span
              >
              <br />
              <span
                class="text-[14px] font-medium leading-[16.94px] text-[#96a0b5]"
                >{{ u.name }}</span
              >
            </td>
            <td>
              <span
                class="flex items-center gap-[4px] max-w-max w-full"
                :class="[
                  'badge',
                  {
                    'badge-success': u.status === 'Active',
                    'badge-danger': u.status === 'Disabled',
                    'badge-primary': u.status === 'Pending',
                  },
                ]"
              >
                <span
                  class="w-[10px] h-[10px] rounded-[1px]"
                  :style="{
                    backgroundColor:
                      u.status === 'Active'
                        ? '#16a249'
                        : u.status === 'Disabled'
                        ? '#EF5253'
                        : '#7367F0',
                  }"
                ></span>

                <span class="text-[12px] font-normal leading-[16.94px]">
                  {{ u.status === "Pending" ? "Invitation sent" : u.status }}
                </span>
              </span>
            </td>
            <td class="text-[14px] font-normal leading-[16.94px]">
              {{ u.type }}
            </td>
            <td class="text-[14px] font-normal leading-[16.94px]">
              {{ u.branch ? u.branch.name : "N/A" }}
            </td>
            <td class="text-[14px] font-normal leading-[16.94px]">
              {{
                u.userType === "Office"
                  ? "Business"
                  : u.userType === "SalesForce"
                  ? "Sales"
                  : u.userType
              }}
            </td>
            <td class="text-[14px] font-normal leading-[16.94px]">
              {{ u.staffId }}
            </td>
            <td @click="toggleDropdown(u.id)">
              <div class="dropdown">
                <button
                  class="btn-no-border"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span
                    class="text-[#98a2b3] dropdown text-2xl cursor-pointer relative outline-none active:outline-none shadow-none"
                  >
                    ...
                  </span>
                </button>
                <ul
                  class="dropdown-menu shadow-none"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li
                    class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer border-b border-[#e5e7eb] py-[12px] px-[16px]"
                    @click="viewUser(u)"
                  >
                    View
                  </li>
                  <li
                    class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer border-b border-[#e5e7eb] py-[12px] px-[16px]"
                    v-if="
                      u.status != 'Inactive' && hasPermission('User_Update')
                    "
                    @click="viewEditUser(u)"
                  >
                    Edit User
                  </li>
                  <li
                    class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer border-b border-[#e5e7eb] py-[12px] px-[16px]"
                  >
                    Reset Password
                  </li>

                  <li
                    @click="OpenDisable(u)"
                    class="text-[14px] font-normal leading-[20px] text-[#DC2828] cursor-pointer py-[12px] px-[16px]"
                  >
                    {{
                      u.status === "Active"
                        ? "Disable"
                        : u.status === "Pending"
                        ? "Disable"
                        : "Enable"
                    }}
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr v-if="!users.length">
            <td colspan="12">
              <div class="text-center" style="padding-top: 50px">
                <span
                  style="font-size: 4.615rem"
                  class="fa-solid fa-spinner fa-spin text-[#2463EB]"
                ></span>
              </div>
              <h3
                class="text-center pt-[32px] text-[14px] font-normal leading-[16.94px]"
                style="padding-bottom: 100px"
              >
                Please wait while we load your user Lists.
              </h3>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination
        :limit="pageSize"
        :count="pageCount"
        :pageNumber="pageNumber"
        :itemsCount="count"
        @changePage="$emit('changePage', $event)"
        @changeLimit="$emit('changeLimit', $event)"
      />
    </div>

    <!-- view user popup -->
    <div
      v-if="isModalVisible"
      class="fixed inset-0 bg-[rgba(0,0,0,0.4)] z-[1111111] flex justify-end overflow-y-auto"
      id="popup-content"
      @click.self="closeModal"
    >
      <div
        class="relative w-full lg:w-[80%] max-h-screen bg-[#f8f8f8] p-[40px] overflow-auto"
      >
        <button
          class="absolute right-12 top-12 cursor-pointer z-10"
          type="button"
          @click="closeModal"
        >
          <span class="mdi text-xl mdi-close"></span>
        </button>
        <p
          class="text-[#353535] text-[20px] leading-[20px] font-semibold capitalize"
        >
          Viewing
          {{ selecteduser.userType === "Office" ? "Business" : "Sales" }} User:
          {{ selecteduser.displayName || selecteduser.name }}
        </p>
        <div
          class="bg-white p-1 rounded-[4px] flex items-center gap-[16px] mt-[16px]"
        >
          <button
            :class="[
              'text-[14px] font-normal py-[6px] px-[24px] rounded-[8px]',
              {
                'bg-[#2463EB] text-white': activeTab === 'profile',
                'bg-[#f8f8f8] text-[#96a0b5]': activeTab !== 'profile',
              },
            ]"
            @click="switchTab('profile')"
          >
            User Profile
          </button>
          <button
            v-if="selecteduser.userType === 'SalesForce'"
            :class="[
              'text-[14px] font-normal py-[6px] px-[24px] rounded-[8px]',
              {
                'bg-[#2463EB] text-white': activeTab === 'distributor',
                'bg-[#f8f8f8] text-[#96a0b5]': activeTab !== 'distributor',
              },
            ]"
            @click="switchTab('distributor')"
          >
            Assigned Distributor
          </button>
          <button
            v-if="selecteduser.userType === 'SalesForce'"
            :class="[
              'text-[14px] font-normal py-[6px] px-[24px] rounded-[8px]',
              {
                'bg-[#2463EB] text-white': activeTab === 'groups',
                'bg-[#f8f8f8] text-[#96a0b5]': activeTab !== 'groups',
              },
            ]"
            @click="switchTab('groups')"
          >
            Assigned Groups
          </button>
          <button
            v-if="selecteduser.userType === 'SalesForce'"
            :class="[
              'text-[14px] font-normal py-[6px] px-[24px] rounded-[8px]',
              {
                'bg-[#2463EB] text-white': activeTab === 'state',
                'bg-[#f8f8f8] text-[#96a0b5]': activeTab !== 'state',
              },
            ]"
            @click="switchTab('state')"
          >
            Assigned State
          </button>
          <button
            v-if="selecteduser.userType === 'SalesForce'"
            :class="[
              'text-[14px] font-normal py-[6px] px-[24px] rounded-[8px]',
              {
                'bg-[#2463EB] text-white': activeTab === 'team',
                'bg-[#f8f8f8] text-[#96a0b5]': activeTab !== 'team',
              },
            ]"
            @click="switchTab('team')"
          >
            Assigned Teams
          </button>
        </div>
        <div v-if="activeTab === 'profile'">
          <div class="bg-white px-[24px] py-[32px] mt-[16px] rounded-[4px]">
            <div class="flex justify-between items-center">
              <div class="flex items-center gap-2 flex-1">
                <h2
                  class="font-semibold text-[20px] leading-[20px] text-[#353535] max-w-[170px] w-full"
                >
                  User Information
                </h2>
                <span
                  class="flex items-center gap-[4px] max-w-max w-full"
                  :class="[
                    'badge',
                    {
                      'badge-success': selecteduser.status,
                      'badge-danger': !selecteduser.status,
                    },
                  ]"
                >
                  <span
                    class="w-[10px] h-[10px] rounded-[1px]"
                    :style="{
                      backgroundColor: selecteduser.status
                        ? '#16a249'
                        : '#EF5253',
                    }"
                  ></span>
                  <span class="text-[12px] font-normal">{{
                    selecteduser.status ? "Active" : "Inactive"
                  }}</span>
                </span>
              </div>
              <button
                class="text-[#96a0b5] text-[14px] font-normal py-[12px] px-[24px] rounded-[8px] border-[#96A0B5] border-solid border outline-none font-semibold"
                @click="editingUsers"
              >
                <span class="mdi mdi-pencil text-2xl mr-[5px]"></span>
                Edit
              </button>
            </div>
            <div class="flex items-center gap-4 mt-2">
              <h2
                class="font-normal text-[16px] text-[#96a0b5] max-w-[170px] w-full"
              >
                Name
              </h2>
              <span
                class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                >{{ selecteduser.displayName || selecteduser.name }}</span
              >
            </div>
            <div class="flex items-center gap-4 mt-2">
              <h2
                class="font-normal text-[16px] text-[#96a0b5] max-w-[170px] w-full"
              >
                Role
              </h2>
              <span
                class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                >{{ selecteduser.role }}</span
              >
            </div>
            <div class="flex items-center gap-4 mt-2">
              <h2
                class="font-normal text-[16px] text-[#96a0b5] max-w-[170px] w-full"
              >
                User Name
              </h2>
              <span
                class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                >{{ selecteduser.displayName || selecteduser.name }}</span
              >
            </div>
            <div class="flex items-center gap-4 mt-2">
              <h2
                class="font-normal text-[16px] text-[#96a0b5] max-w-[170px] w-full"
              >
                Email
              </h2>
              <span
                class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                >{{ selecteduser.emailAddress }}</span
              >
            </div>
            <div class="flex items-center gap-4 mt-2">
              <h2
                class="font-normal text-[16px] text-[#96a0b5] max-w-[170px] w-full"
              >
                Phone Number
              </h2>
              <span
                class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                >{{ selecteduser.phoneNumber }}</span
              >
            </div>
            <div class="flex items-center gap-4 mt-2">
              <h2
                class="font-normal text-[16px] text-[#96a0b5] max-w-[170px] w-full"
              >
                Branch
              </h2>
              <span
                class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                >{{ selecteduser.branch ? selecteduser.branch : "N/A" }}
              </span>
            </div>
            <div class="flex items-center gap-4 mt-2">
              <h2
                class="font-normal text-[16px] text-[#96a0b5] max-w-[170px] w-full"
              >
                Staff ID
              </h2>
              <span
                class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                >{{ selecteduser.staffId }}
              </span>
            </div>
            <div class="flex items-center gap-4 mt-2">
              <h2
                class="font-normal text-[16px] text-[#96a0b5] max-w-[170px] w-full"
              >
                Created
              </h2>
              <span
                class="font-medium text-[16px] text-[#353535] leadin-[19.36px]"
                >{{ formatDate(selecteduser.created) }}
              </span>
            </div>
            <div
              v-if="selecteduser.userType === 'SalesForce'"
              class="mt-[16px] rounded-[4px] border-[1.5px] border-solid border-[#DBDCE0] w-full"
            >
              <div
                class="w-full bg-[#fbfbfb] py-[13px] px-[24px] border border-[#DBDCE0]"
              >
                <h2 class="font-bold text-[12px] leading-[18px] text-[#353535]">
                  Assigned Rights
                </h2>
              </div>
              <div
                class="w-full bg-white py-[13px] px-[24px] border border-[#DBDCE0]"
              >
                <h2
                  class="font-medium text-[14px] leading-[17px] text-[#353535]"
                >
                  Manage Distributors
                </h2>
              </div>
              <div
                class="w-full bg-white py-[13px] px-[24px] border border-[#DBDCE0]"
              >
                <h2
                  class="font-medium text-[14px] leading-[17px] text-[#353535]"
                >
                  Manage Groups
                </h2>
              </div>
              <div
                class="w-full bg-white py-[13px] px-[24px] border border-[#DBDCE0]"
              >
                <h2
                  class="font-medium text-[14px] leading-[17px] text-[#353535]"
                >
                  Manage State
                </h2>
              </div>
              <div
                class="w-full bg-white py-[13px] px-[24px] border border-[#DBDCE0]"
              >
                <h2
                  class="font-medium text-[14px] leading-[17px] text-[#353535]"
                >
                  Manage Distributor Managers
                </h2>
              </div>
              <div
                class="w-full bg-white py-[13px] px-[24px] border border-[#DBDCE0]"
              >
                <h2
                  class="font-medium text-[14px] leading-[17px] text-[#353535]"
                >
                  Manage Group Manager
                </h2>
              </div>
              <div
                class="w-full bg-white py-[13px] px-[24px] border border-[#DBDCE0]"
              >
                <h2
                  class="font-medium text-[14px] leading-[17px] text-[#353535]"
                >
                  Manage State Manager
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeTab === 'distributor'">
          <div
            class="px-[24px] bg-white py-[32px] mt-[16px] rounded-[4px] min-h-auto"
          >
            <div class="flex justify-between items-center">
              <h2
                class="font-semibold text-[20px] leading-[20px] text-[#353535]"
              >
                Assigned Distributors
              </h2>
              <button
                class="text-white bg-[#2463EB] text-[14px] font-normal py-[12px] px-[24px] rounded-[8px] border-[#2463EB] border-solid border-[1.6px] outline-none font-semibold"
                @click="togleAddModal"
              >
                Assign Distributor
              </button>
            </div>
            <div
              class="mt-[16px] rounded-[4px] border-[1.5px] border-solid border-[#DBDCE0]"
            >
              <div
                class="w-full bg-[#fbfbfb] py-[13px] px-[24px] border border-[#DBDCE0]"
              >
                <h2 class="font-bold text-[12px] leading-[18px] text-[#353535]">
                  Assigned Distributors
                </h2>
              </div>
              <div
                class="w-full bg-white py-[13px] px-[24px] border border-[#DBDCE0] flex items-center gap-[48px]"
              >
                <h2
                  class="font-medium text-[14px] leading-[17px] text-[#353535]"
                >
                  We Sell Neat Stuff
                </h2>
                <span
                  @click="toggleRemoveModal"
                  class="text-[#DC2828] font-normal text-[14px] leading-[20px] cursor-pointer"
                  >Remove</span
                >
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeTab === 'groups'">
          <div
            class="px-[24px] bg-white py-[32px] mt-[16px] rounded-[4px] min-h-auto"
          >
            <div class="flex justify-between items-center">
              <h2
                class="font-semibold text-[20px] leading-[20px] text-[#353535]"
              >
                Assigned Groups
              </h2>
              <button
                class="text-white bg-[#2463EB] text-[14px] font-normal py-[12px] px-[24px] rounded-[8px] border-[#2463EB] border-solid border-[1.6px] outline-none font-semibold"
                @click="toggleGroupAssign"
              >
                Assign Group
              </button>
            </div>
            <div
              class="mt-[16px] rounded-[4px] border-[1.5px] border-solid border-[#DBDCE0]"
            >
              <div
                class="w-full bg-[#fbfbfb] py-[13px] px-[24px] border border-[#DBDCE0]"
              >
                <h2 class="font-bold text-[12px] leading-[18px] text-[#353535]">
                  Assigned Groups
                </h2>
              </div>
              <div
                class="w-full bg-white py-[13px] px-[24px] border border-[#DBDCE0] flex items-center gap-[48px]"
              >
                <h2
                  class="font-medium text-[14px] leading-[17px] text-[#353535]"
                >
                  HFMP-LAG 2
                </h2>
                <span
                  class="text-[#DC2828] font-normal text-[14px] leading-[20px] cursor-pointer"
                  @click="toggleGroupRemoval"
                  >Remove</span
                >
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeTab === 'state'">
          <div
            class="px-[24px] bg-white py-[32px] mt-[16px] rounded-[4px] min-h-auto"
          >
            <div class="flex justify-between items-center">
              <h2
                class="font-semibold text-[20px] leading-[20px] text-[#353535]"
              >
                Assigned States
              </h2>
              <button
                class="text-white bg-[#2463EB] text-[14px] font-normal py-[12px] px-[24px] rounded-[8px] border-[#2463EB] border-solid border-[1.6px] outline-none font-semibold"
                @click="toggleStateAssign"
              >
                Assign state
              </button>
            </div>
            <div
              class="mt-[16px] rounded-[4px] border-[1.5px] border-solid border-[#DBDCE0]"
            >
              <div
                class="w-full bg-[#fbfbfb] py-[13px] px-[24px] border border-[#DBDCE0]"
              >
                <h2 class="font-bold text-[12px] leading-[18px] text-[#353535]">
                  Assigned states
                </h2>
              </div>
              <div
                class="w-full bg-white py-[13px] px-[24px] border border-[#DBDCE0] flex items-center gap-[48px]"
              >
                <h2
                  class="font-medium text-[14px] leading-[17px] text-[#353535]"
                >
                  Abia
                </h2>
                <span
                  @click="toggleStateRemoval"
                  class="text-[#DC2828] font-normal text-[14px] leading-[20px] cursor-pointer"
                  >Remove</span
                >
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeTab === 'team'">
          <div
            class="px-[24px] bg-white py-[32px] mt-[16px] rounded-[4px] min-h-auto"
          >
            <div class="flex justify-between items-center">
              <h2
                class="font-semibold text-[20px] leading-[20px] text-[#353535]"
              >
                Assigned Teams
              </h2>
              <button
                class="text-white bg-[#2463EB] text-[14px] font-normal py-[12px] px-[24px] rounded-[8px] border-[#2463EB] border-solid border-[1.6px] outline-none font-semibold"
                @click="toggleTeamAssign"
              >
                Assign team
              </button>
            </div>
            <div
              class="mt-[16px] rounded-[4px] border-[1.5px] border-solid border-[#DBDCE0]"
            >
              <div
                class="w-full bg-[#fbfbfb] py-[13px] px-[24px] border border-[#DBDCE0]"
              >
                <h2 class="font-bold text-[12px] leading-[18px] text-[#353535]">
                  Assigned teams
                </h2>
              </div>
              <div
                class="w-full bg-white py-[13px] px-[24px] border border-[#DBDCE0] flex items-center gap-[48px]"
              >
                <h2
                  class="font-medium text-[14px] leading-[17px] text-[#353535]"
                >
                  Ben Bruce
                </h2>
                <span
                  class="text-[#DC2828] font-normal text-[14px] leading-[20px] cursor-pointer"
                  @click="toggleTeamRemoval"
                  >Remove</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals in popup to be moved later -->
    <div
      class="modal fade bg-[rgba(0, 0, 0, 0.5)] colored-header colored-header-primary"
      id="mod-team-remove"
      role="dialog"
    >
      <div class="modal-dialog max-w-[500px] w-full relative">
        <div class="modal-content p-[24px]">
          <div class="flex items-start justify-between">
            <h2
              class="text-[18px] leading-[28px] font-bold text-[#353535] mb-[4px]"
            >
              Remove Distributor
            </h2>
            <button
              class="text-xl border-[1.5px] rounded-full border-[#292d32] px-[3.5px]"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="">
            <p class="text-[14px] font-normal leading-[20px] text-[#96a0b5]">
              Are you sure you want to remove this distributor from this user?
            </p>
            <div class="my-[15px] flex gap-2 items-center justify-center">
              <button
                type="button"
                data-dismiss="modal"
                aria-hidden="true"
                class="border border-solid border-[#96a0b5] text-[#96a0b5] px-[24px] py-[16px] w-full rounded-[10px]"
              >
                Cancel
              </button>
              <button
                type="button"
                class="border-[1px] bg-[#2463eb] text-white border-solid px-[24px] py-[16px] w-full rounded-[10px]"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade bg-[rgba(0, 0, 0, 0.5)] colored-header colored-header-primary"
      id="mod-team-assign"
      role="dialog"
    >
      <div class="modal-dialog max-w-[500px] w-full relative">
        <div class="modal-content p-[24px]">
          <div class="flex items-start justify-between">
            <h2
              class="text-[18px] leading-[28px] font-bold text-[#353535] mb-[4px]"
            >
              Assign Team
            </h2>
            <button
              class="text-2xl"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="flex flex-col gap-[16px] mt-[16px]">
            <label
              for="team"
              class="text-[16px] leading-[19.36px] font-normal text-[#353535]"
            >
              Teams
            </label>
            <select
              id="team"
              class="w-full px-[16px] py-[12px] text-[14px] placeholder-gray-[#81868c] border-[0.5px] border-[#d0d5dd] outline-none rounded-[8px]"
              type="text"
              placeholder="Select a criteria"
              autocomplete="off"
            >
              <option value="0" selected disabled
                >Select a criteria</option
              ></select
            >
          </div>
          <div
            class="mt-[16px] rounded-[8px] border-[1.5px] border-solid border-[#DBDCE0] w-full py-[10px]"
          ></div>
          <div class="my-[15px] flex gap-2 items-center justify-center">
            <button
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              class="border border-solid border-[#96a0b5] text-[#96a0b5] px-[24px] py-[16px] w-full rounded-[10px]"
            >
              Cancel
            </button>
            <button
              type="button"
              class="border-[1px] bg-[#2463eb] text-white border-solid px-[24px] py-[16px] w-full rounded-[10px]"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade bg-[rgba(0, 0, 0, 0.5)] colored-header colored-header-primary"
      id="mod-remove"
      role="dialog"
    >
      <div class="modal-dialog max-w-[500px] w-full relative">
        <div class="modal-content p-[24px]">
          <div class="flex items-start justify-between">
            <h2
              class="text-[18px] leading-[28px] font-bold text-[#353535] mb-[4px]"
            >
              Remove Distributor
            </h2>
            <button
              class="text-xl border-[1.5px] rounded-full border-[#292d32] px-[3.5px]"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="">
            <p class="text-[14px] font-normal leading-[20px] text-[#96a0b5]">
              Are you sure you want to remove this distributor from this user?
            </p>
            <div class="my-[15px] flex gap-2 items-center justify-center">
              <button
                type="button"
                data-dismiss="modal"
                aria-hidden="true"
                class="border border-solid border-[#96a0b5] text-[#96a0b5] px-[24px] py-[16px] w-full rounded-[10px]"
              >
                Cancel
              </button>
              <button
                type="button"
                class="border-[1px] bg-[#2463eb] text-white border-solid px-[24px] py-[16px] w-full rounded-[10px]"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade bg-[rgba(0, 0, 0, 0.5)] colored-header colored-header-primary"
      id="mod-assign"
      role="dialog"
    >
      <div class="modal-dialog max-w-[500px] w-full relative">
        <div class="modal-content p-[24px]">
          <div class="flex items-start justify-between">
            <h2
              class="text-[18px] leading-[28px] font-bold text-[#353535] mb-[4px]"
            >
              Assign Distributor
            </h2>
            <button
              class="text-2xl"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="flex flex-col gap-[16px] mt-[16px]">
            <label
              for="username"
              class="text-[16px] leading-[19.36px] font-normal text-[#353535]"
            >
              Distributors
            </label>
            <input
              id="username"
              class="w-full px-[16px] py-[12px] text-[14px] placeholder-gray-[#81868c] border-[0.5px] border-[#d0d5dd] outline-none rounded-[8px]"
              type="text"
              placeholder="Search for a distributor here"
              autocomplete="off"
            />
          </div>
          <div
            class="mt-[16px] rounded-[8px] border-[1.5px] border-solid border-[#DBDCE0] w-full py-[10px]"
          ></div>
          <div class="my-[15px] flex gap-2 items-center justify-center">
            <button
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              class="border border-solid border-[#96a0b5] text-[#96a0b5] px-[24px] py-[16px] w-full rounded-[10px]"
            >
              Cancel
            </button>
            <button
              type="button"
              class="border-[1px] bg-[#2463eb] text-white border-solid px-[24px] py-[16px] w-full rounded-[10px]"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade bg-[rgba(0, 0, 0, 0.5)] colored-header colored-header-primary"
      id="mod-group-remove"
      role="dialog"
    >
      <div class="modal-dialog max-w-[500px] w-full relative">
        <div class="modal-content p-[24px]">
          <div class="flex items-start justify-between">
            <h2
              class="text-[18px] leading-[28px] font-bold text-[#353535] mb-[4px]"
            >
              Remove Distributor
            </h2>
            <button
              class="text-xl border-[1.5px] rounded-full border-[#292d32] px-[3.5px]"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="">
            <p class="text-[14px] font-normal leading-[20px] text-[#96a0b5]">
              Are you sure you want to remove this distributor from this user?
            </p>
            <div class="my-[15px] flex gap-2 items-center justify-center">
              <button
                type="button"
                data-dismiss="modal"
                aria-hidden="true"
                class="border border-solid border-[#96a0b5] text-[#96a0b5] px-[24px] py-[16px] w-full rounded-[10px]"
              >
                Cancel
              </button>
              <button
                type="button"
                class="border-[1px] bg-[#2463eb] text-white border-solid px-[24px] py-[16px] w-full rounded-[10px]"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade bg-[rgba(0, 0, 0, 0.5)] colored-header colored-header-primary"
      id="mod-assign-group"
      role="dialog"
    >
      <div class="modal-dialog max-w-[500px] w-full relative">
        <div class="modal-content p-[24px]">
          <div class="flex items-start justify-between">
            <h2
              class="text-[18px] leading-[28px] font-bold text-[#353535] mb-[4px]"
            >
              Assign Group
            </h2>
            <button
              class="text-2xl"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="flex flex-col gap-[16px] mt-[16px]">
            <label
              for="username"
              class="text-[16px] leading-[19.36px] font-normal text-[#353535]"
            >
              Groups
            </label>
            <input
              id="username"
              class="w-full px-[16px] py-[12px] text-[14px] placeholder-gray-[#81868c] border-[0.5px] border-[#d0d5dd] outline-none rounded-[8px]"
              type="text"
              placeholder="Search for a Group here"
              autocomplete="off"
            />
          </div>
          <div
            class="mt-[16px] rounded-[8px] border-[1.5px] border-solid border-[#DBDCE0] w-full py-[10px]"
          ></div>
          <div class="my-[15px] flex gap-2 items-center justify-center">
            <button
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              class="border border-solid border-[#96a0b5] text-[#96a0b5] px-[24px] py-[16px] w-full rounded-[10px]"
            >
              Cancel
            </button>
            <button
              type="button"
              class="border-[1px] bg-[#2463eb] text-white border-solid px-[24px] py-[16px] w-full rounded-[10px]"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade bg-[rgba(0, 0, 0, 0.5)] colored-header colored-header-primary"
      id="mod-state-remove"
      role="dialog"
    >
      <div class="modal-dialog max-w-[500px] w-full relative">
        <div class="modal-content p-[24px]">
          <div class="flex items-start justify-between">
            <h2
              class="text-[18px] leading-[28px] font-bold text-[#353535] mb-[4px]"
            >
              Remove Distributor
            </h2>
            <button
              class="text-xl border-[1.5px] rounded-full border-[#292d32] px-[3.5px]"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="">
            <p class="text-[14px] font-normal leading-[20px] text-[#96a0b5]">
              Are you sure you want to remove this distributor from this user?
            </p>
            <div class="my-[15px] flex gap-2 items-center justify-center">
              <button
                type="button"
                data-dismiss="modal"
                aria-hidden="true"
                class="border border-solid border-[#96a0b5] text-[#96a0b5] px-[24px] py-[16px] w-full rounded-[10px]"
              >
                Cancel
              </button>
              <button
                type="button"
                class="border-[1px] bg-[#2463eb] text-white border-solid px-[24px] py-[16px] w-full rounded-[10px]"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade bg-[rgba(0, 0, 0, 0.5)] colored-header colored-header-primary"
      id="mod-state-assign"
      role="dialog"
    >
      <div class="modal-dialog max-w-[500px] w-full relative">
        <div class="modal-content p-[24px]">
          <div class="flex items-start justify-between">
            <h2
              class="text-[18px] leading-[28px] font-bold text-[#353535] mb-[4px]"
            >
              Assign State
            </h2>
            <button
              class="text-2xl"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="flex flex-col gap-[16px] mt-[16px]">
            <label
              for="state"
              class="text-[16px] leading-[19.36px] font-normal text-[#353535]"
            >
              States
            </label>
            <input
              id="state"
              class="w-full px-[16px] py-[12px] text-[14px] placeholder-gray-[#81868c] border-[0.5px] border-[#d0d5dd] outline-none rounded-[8px]"
              type="text"
              placeholder="Search for a state here"
              autocomplete="off"
            />
          </div>
          <div
            class="mt-[16px] rounded-[8px] border-[1.5px] border-solid border-[#DBDCE0] w-full py-[10px]"
          ></div>
          <div class="my-[15px] flex gap-2 items-center justify-center">
            <button
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              class="border border-solid border-[#96a0b5] text-[#96a0b5] px-[24px] py-[16px] w-full rounded-[10px]"
            >
              Cancel
            </button>
            <button
              type="button"
              class="border-[1px] bg-[#2463eb] text-white border-solid px-[24px] py-[16px] w-full rounded-[10px]"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals in popup to be moved later -->
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import SystemPermissions from "@/permissions";
import Select2 from "@/components/Select2";
import Pagination from "../Pagination.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  props: [
    "viewEditUser",
    "viewRemove",
    "users",
    "pageNumber",
    "pageSize",
    "count",
    "pageCount",
    "hasNext",
    "hasPrev",
  ],
  components: {
    Select2,
    Pagination,
  },

  created() {
    if (!this.hasPermission("User_View")) {
      this.$router.push("/restricted");
    }
  },

  data() {
    return {
      filter: {
        status: "0",
        role: "0",
        qry: "",
      },
      isBusy: false,
      isBusyModal: false,
      showDropdown: null,
      isModalVisible: false,
      selecteduser: {},
      activeTab: "profile",
      showRemoveModal: false,
      createUser: {
        username: null,
        password: null,
        phoneNumber: null,
        emailAddress: null,
        name: null,
        role: null,
        branch: null,
        staffId: null,
        salesAccessId: null,
      },

      editingUser: {
        emailAddress: null,
        phoneNumber: null,
        displayName: null,
        role: null,
        branchId: null,
      },

      modalUser: {
        emailAddress: null,
        displayName: null,
      },

      Branches: [],

      v2: {
        role: false,
      },

      v3: {
        branch: false,
      },

      v5: {
        name: false,
      },
      v6: {
        email: false,
      },
      v7: {
        phone: false,
      },

      roles: [],
      DeleteId: "",
      v8: {
        selectedOption: false,
      },

      selectedOption: "",
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },

    enableInviteOption: function() {
      return this.modalUser.emailAddress && this.modalUser.phoneNumber;
    },
  },

  methods: {
    reset() {
      this.filter = {
        status: "0",
        role: "0",
        qry: "",
      };
    },
    switchTab(tab) {
      this.activeTab = tab;
    },
    viewUser(user) {
      this.selecteduser = user;
      this.isModalVisible = true;
    },
    editingUsers(user) {
      this.user = this.editingUser;
      this.$nextTick(() => {
        $("#mod-edit").modal("show");
      });
      this.closeModal();
    },
    OpenDisable(u) {
      this.viewRemove(u);
    },

    closeModal() {
      this.isModalVisible = false;
      document.body.style.overflow = "auto";
    },
    openModal() {
      this.isModalVisible = true;
      document.body.style.overflow = "hidden";
    },
    toggleRemoveModal() {
      $("#mod-remove").modal("show");
      this.closeModal();
    },
    togleAddModal() {
      $("#mod-assign").modal("show");
      this.closeModal();
    },
    toggleGroupRemoval() {
      $("#mod-group-remove").modal("show");
      this.closeModal();
    },
    toggleGroupAssign() {
      $("#mod-assign-group").modal("show");
    },
    toggleStateAssign() {
      $("#mod-state-assign").modal("show");
      this.closeModal();
    },
    toggleStateRemoval() {
      $("#mod-state-remove").modal("show");
      this.closeModal();
    },
    toggleTeamAssign() {
      $("#mod-team-assign").modal("show");
      this.closeModal();
    },
    toggleTeamRemoval() {
      $("#mod-team-remove").modal("show");
      this.closeModal();
    },
    toggleDropdown(userId) {
      this.showDropdown = this.showDropdown === userId ? null : userId;
    },
    confirmDelete(id) {
      this.DeleteId = id;
      $("#mod-confirm-delete").modal("show");
    },

    showDeleteMsg() {
      $("#mod-delete-msg").modal("show");
    },

    cancelConfirm() {
      this.DeleteId = "";
      $("#mod-confirm-delete").modal("hide");
    },
    async deleteUser() {
      this.isBusyModal = true;
      try {
        const res = await this.$http.delete(
          `/Users/deleteUser/${this.DeleteId}`
        );
        if (res.ok) {
          this.$toast.success("User deleted successfully", {
            icon: true,
            rtl: false,
          });
          this.getUsers();
          this.cancelConfirm();
        }
      } catch (error) {
        this.cancelConfirm();
        this.showDeleteMsg();
      }
      this.isBusyModal = false;
    },

    viewAdd() {
      $("#mod-add").modal("show");
    },

    viewAddManual() {
      $("#mod-add-manual").modal("show");
    },

    viewEdit(u) {
      this.editingUser = u;
      $("#mod-edit").modal("show");
    },

    viewEditBranch(u) {
      this.editingUser = u;
      $("#mod-edit-branch").modal("show");
    },

    viewEditName(u) {
      this.editingUser = u;
      $("#mod-edit-name").modal("show");
    },

    viewEditPhone(u) {
      this.editingUser = u;
      $("#mod-edit-phone").modal("show");
    },

    viewEditEmail(u) {
      this.editingUser = u;
      $("#mod-edit-email").modal("show");
    },

    // viewRemove(u) {
    //   this.modalUser = u;
    //   $("#mod-delete").modal("show");
    // },

    viewResend(u) {
      this.modalUser = u;
      $("#mod-resend").modal("show");
    },

    viewFilter() {
      this.page = 0;
      $("#mod-filter").modal("show");
    },

    handleCreateUserComplete() {
      $("#mod-add-manual").modal("hide");
      this.getUsers();
    },

    onInviteCompleteHandler() {
      $("#mod-add").modal("hide");
      this.getUsers();
    },

    async attemptResendInvite() {
      if (this.enableInviteOption && this.validateResendInvite())
        this.resendInvite();

      if (!this.enableInviteOption) this.resendInvite();
    },

    async resendInvite() {
      this.isBusyModal = true;
      $("#mod-resend").modal("hide");

      await this.$http
        .get(
          "/Users/resendInvite/" +
            this.modalUser.id +
            "?type=" +
            this.selectedOption
        )
        .then(function(res) {
          this.isBusyModal = false;
          if (res.ok && res.body) {
            this.$toast.success("Invite sent successfully.", {
              icon: false,
              rtl: false,
            });
          }
        })
        .catch(function() {
          this.isBusyModal = false;
        });
    },

    validateResendInvite() {
      this.v8 = {
        selectedOption: false,
      };

      let isValid = true;

      if (!this.selectedOption) {
        this.v8.selectedOption = true;
        isValid = false;
      }

      return isValid;
    },

    updateRole() {
      this.isBusyModal = true;
      this.isBusy = true;

      let data = {
        id: this.editingUser.id,
        role: this.editingUser.role,
      };

      if (this.validateUpdateRole()) {
        $("#mod-edit").modal("hide");

        this.$http
          .put("/Users/updateRole/" + data.id, data)
          .then(function(res) {
            this.isBusyModal = false;
            this.getUsers();
          })
          .catch(function() {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    validateUpdateRole() {
      this.v2 = {
        role: false,
      };
      let isValid = true;

      if (!this.editingUser.role) {
        this.v2.role = true;
        isValid = false;
      }
      return isValid;
    },

    updateBranch() {
      this.isBusyModal = true;
      this.isBusy = true;

      let data = {
        id: this.editingUser.id,
        branchId: this.editingUser.branchId,
      };

      if (this.validateUpdateBranch()) {
        $("#mod-edit-branch").modal("hide");

        this.$http
          .put("/Users/updateBranch/" + data.id, data)
          .then(function(res) {
            this.isBusyModal = false;
            this.getUsers();
          })
          .catch(function() {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    validateUpdateBranch() {
      this.v3 = {
        branch: false,
      };
      let isValid = true;

      if (!this.editingUser.branchId) {
        this.v3.branch = true;
        isValid = false;
      }
      return isValid;
    },

    updateName() {
      this.isBusyModal = true;
      this.isBusy = true;

      let data = {
        id: this.editingUser.id,
        displayName: this.editingUser.displayName,
      };

      if (this.validateUpdateName()) {
        $("#mod-edit-name").modal("hide");

        this.$http
          .put("/Users/updateName/" + data.id, data)
          .then(function(res) {
            this.isBusyModal = false;
            this.getUsers();
          })
          .catch(function() {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    validateUpdateName() {
      this.v5 = {
        name: false,
      };
      let isValid = true;

      if (!this.editingUser.displayName) {
        this.v5.name = true;
        isValid = false;
      }
      return isValid;
    },

    updateEmail() {
      this.isBusyModal = true;
      this.isBusy = true;

      let data = {
        id: this.editingUser.id,
        emailAddress: this.editingUser.emailAddress,
      };

      if (this.validateUpdateEmail()) {
        $("#mod-edit-email").modal("hide");

        this.$http
          .put("/Users/updateEmail/" + data.id, data)
          .then(function(res) {
            this.isBusyModal = false;
            this.getUsers();
          })
          .catch(function() {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    validateUpdateEmail() {
      this.v6 = {
        email: false,
      };
      let isValid = true;

      if (!this.editingUser.emailAddress) {
        this.v6.email = true;
        isValid = false;
      }
      return isValid;
    },

    updatePhone() {
      this.isBusyModal = true;
      this.isBusy = true;

      let data = {
        id: this.editingUser.id,
        phoneNumber: this.editingUser.phoneNumber,
      };

      if (this.validateUpdatePhone()) {
        $("#mod-edit-phone").modal("hide");

        this.$http
          .put("/Users/updatePhoneNumber/" + data.id, data)
          .then(function(res) {
            this.isBusyModal = false;
            this.getUsers();
          })
          .catch(function() {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    validateUpdatePhone() {
      this.v7 = {
        phone: false,
      };
      let isValid = true;

      if (!this.editingUser.phoneNumber) {
        this.v7.phone = true;
        isValid = false;
      }
      return isValid;
    },

    updateStatus() {
      this.isBusyModal = true;
      let data = {
        id: this.modalUser.id,
        status:
          this.modalUser.status == "Inactive"
            ? this.modalUser.username
              ? "Active"
              : "Pending"
            : "Inactive",
      };

      $("#mod-delete").modal("hide");

      this.$http
        .put("/Users/updateStatus/" + data.id, data)
        .then(function(res) {
          this.isBusyModal = false;
          this.getUsers();
        })
        .catch(function() {
          this.isBusyModal = false;
        });
    },

    async getUsers() {
      this.isBusy = true;
      this.isBusyModal = true;
      this.users = [];

      await this.$http
        .get(
          "/Users/getAllEnterpriseUsers" +
            this.pageNumber +
            "&pageSize=" +
            this.pageSize +
            (this.filter.status != "0" ? "&status=" + this.filter.status : "") +
            (this.filter.role != "0" ? "&role=" + this.filter.role : "") +
            (this.filter.qry != "" ? "&query=" + this.filter.qry : "") +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "")
        )
        .then(function(res) {
          console.log(res);
          console.log(object);
          this.users = res.body;
          this.pageNumber = res.body.page;
          console.log(this.pageNumber);
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");

      this.isBusy = false;
      this.isBusyModal = false;
    },

    getBranches() {
      var a = true;

      this.$http
        .get(
          "/Branches/getListNoPaging?active=" +
            a +
            "&parentId=" +
            this.user.parentId
        )
        .then(function(res) {
          this.Branches = res.body;
        })
        .catch(function() {});
    },

    getRoles() {
      console.log(this.user.parentId);
      this.$http
        .get(
          "/roles/getRoleListNoPaging?parentId=" +
            this.user.parentId +
            "&roleType=" +
            this.user.type
        )
        .then(function(res) {
          console.log(res);
          this.roles = res.body;
        })
        .catch(function(err) {
          console.log(err);
        });
    },

    formatDate(date) {
      return moment(date).format("lll");
    },

    prev() {
      this.page -= 1;
      this.getUsers();
    },

    next() {
      this.page += 1;
      this.getUsers();
    },

    gotoPage(page) {
      this.page = page - 1;
      this.getUsers();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getUsers();
    },
  },
};
</script>
