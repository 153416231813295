<template>
	<div class="onboarding-body">
		<div class="onboarding-card">
			<img src="/assets/img/growtrade-logo.png" alt="logo" width="175" />
			<p class="onboarding-header">Login</p>
			<p class="onboarding-desc">Login with your username and password.</p>
			<div>
				<form @submit.prevent="login">
					<div>
						<label class="merchant-login-label"> USERNAME* </label>
						<div
							:class="[
								'merchant-input-div',
								{ 'is-invalid': credentialValidation.username },
							]"
						>
							<input type="text" placeholder="Enter Username" v-model="username" />
						</div>
						<p v-if="credentialValidation.username">
							<img src="/assets/img/error-icon-new.svg" alt="error" />
							<span class="login-error">Username cannot be empty</span>
						</p>
					</div>

					<div class="mt-2">
						<label class="merchant-login-label"> PASSWORD* </label>
						<div
							:class="[
								'merchant-input-div',
								{
									'is-invalid':
										credentialValidation.password ||
										credentialValidation.passwordLength,
								},
							]"
						>
							<input
								:type="passwordType"
								placeholder="Enter Password"
								v-model="password"
							/>
							<button
								type="button"
								class="togglePassword"
								@click.prevent="togglePassword"
							>
								Show
							</button>
						</div>
						<p v-if="credentialValidation.password">
							<img src="/assets/img/error-icon-new.svg" alt="error" />
							<span class="login-error">Password cannot be empty</span>
						</p>
						<p v-if="credentialValidation.passwordLength">
							<img src="/assets/img/error-icon-new.svg" alt="error" />
							<span class="login-error">Password must be at least 6 characters</span>
						</p>
					</div>
					<div class="forgot-password-div">
						<router-link to="/growtrade-forgotPassword">
							<button class="forgot-password-button">
								Reset password
							</button>
						</router-link>
					</div>
					<div class="remember-me" @click="toggleCheckbox">
						<div class="remember-me-div" :class="{ 'checked-green': checked }">
							<svg width="12" height="9" viewBox="0 0 12 9" fill="none">
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M10.9142 0.367188C11.3047 0.757712 11.3047 1.39088 10.9142 1.7814L4.70711 8.2814C4.31658 8.67192 3.68342 8.67192 3.29289 8.2814L0.292893 5.2814C-0.0976311 4.89088 -0.0976311 4.25771 0.292893 3.86719C0.683417 3.47666 1.31658 3.47666 1.70711 3.86719L4 6.16008L9.5 0.367188C9.89052 -0.0233368 10.5237 -0.0233368 10.9142 0.367188Z"
									fill="white"
								/>
							</svg>
						</div>
						<p class="remember-me-text">Keep me logged in</p>
					</div>
					<button type="submit" :disabled="isBusy" class="login-submit-btn">
						<span v-if="isBusy" class="spinner-border spinner-border-sm" role="status">
							<i class="sr-only"></i>
						</span>
						<span v-else>
							Log In
						</span>
					</button>
					<p class="signup-text">
						Don't have an account?
						<button type="button" class="signup-btn" @click="goToOnboarding">
							Sign Up
						</button>
					</p>
					<p class="text-center m-0">
						<svg width="13" height="16" viewBox="0 0 13 16" fill="none">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M10.25 5.44922H11C11.825 5.44922 12.5 6.12422 12.5 6.94922V14.4492C12.5 15.2742 11.825 15.9492 11 15.9492H2C1.175 15.9492 0.5 15.2742 0.5 14.4492V6.94922C0.5 6.12422 1.175 5.44922 2 5.44922H2.75V3.94922C2.75 1.87922 4.43 0.199219 6.5 0.199219C8.57 0.199219 10.25 1.87922 10.25 3.94922V5.44922ZM6.5 1.69922C5.255 1.69922 4.25 2.70422 4.25 3.94922V5.44922H8.75V3.94922C8.75 2.70422 7.745 1.69922 6.5 1.69922ZM2 14.4492V6.94922H11V14.4492H2ZM8 10.6992C8 11.5242 7.325 12.1992 6.5 12.1992C5.675 12.1992 5 11.5242 5 10.6992C5 9.87422 5.675 9.19922 6.5 9.19922C7.325 9.19922 8 9.87422 8 10.6992Z"
								fill="#A6A6A6"
							/>
						</svg>
						<span class="info-desc">Your Info is safely secured</span>
					</p>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
	/* eslint-disable */
	import { helper } from "@/helper";

	export default {
		name: "growtrade-login",
		components: {},

		data() {
			return {
				checked: false,
				passwordType: "password",
				username: null,
				password: null,
				credentialValidation: {
					username: false,
					password: false,
					passwordLength: false,
				},
				isBusy: false,
				countryCodes: ['234'],
				selectedCountryCode: '234',
				mobileNumber: '',
				validationError: '',
			};
		},

		// beforeCreate() {
		//   const client = this.$route.query.client;

		//   if (client !== "distributor" && client !== "merchant") {
		//     this.$router.push(`/growtrade-onboarding`);
		//   }
		// },

		created() {},

		computed: {
			// user: function() {
			//   return this.$store.state.currentUser;
			// },
			
		},

		methods: {
			
			togglePassword() {
				if (this.passwordType === "password") {
					this.passwordType = "text";
				} else {
					this.passwordType = "password";
				}
			},

			toggleCheckbox() {
				if (this.checked === true) {
					this.checked = false;
				} else {
					this.checked = true;
				}
			},

			async login() {
				this.isBusy = true;

				if (this.validateUserInfo()) {
					let data = {
						username: this.username,
						password: this.password,
						channelType: 1,
					};
					await this.$http
						.post("/Auth/generateToken", data)
						.then(function(res) {
							if (res.body.forceChangeOfPassword) {
								this.$store.commit("setUserId", res.body.id);
								this.$router.push("/change-password");
								this.isBusy = false;
							} else {
								this.$auth.setToken(
									res.body.accessToken,
									Date.now() + res.body.expiresIn * 60000,
									res.body.refreshToken,
									res.body.role,
									res.body.userType,
									res.body.permissions,
									res.body.salesUserRole,
								);
this.isBusy = false;
								this.$router.push("/");
							}
						})
						.catch(function() {
							this.isBusy = false;
						});
				} else {
					this.isBusy = false;
				}
			},

			validateUserInfo() {
				this.credentialValidation = {
					username: false,
					password: false,
					passwordLength: false,
				};

				let isValid = true;

				if (!this.username) {
					this.credentialValidation.username = true;
					isValid = false;
				}

				if (!this.password) {
					this.credentialValidation.password = true;
					isValid = false;
				}

				if (this.password && !helper.validateLength(this.password, 6)) {
					this.credentialValidation.passwordLength = true;
					isValid = false;
				}

				return isValid;
			},

			goToOnboarding() {
				this.$router.push(`/growtrade-onboarding`);
			},
		},
	};
</script>
<style scoped>
	.onboarding-body {
		background-color: #f6f6f6;
		min-width: 100vw;
		min-height: 100vh;
		padding: 48px 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.onboarding-card {
		width: 524px;
		background-color: #fff;
		padding: 48px;
	}

	.onboarding-header {
		font-weight: bold;
		font-size: 28px;
		line-height: 39px;
		color: #373737;
		margin: 20px 0 8px;
	}

	.onboarding-desc {
		font-size: 14px;
		display: flex;
		align-items: center;
		color: rgba(24, 25, 31, 0.5);
		padding-bottom: 16px;
		border-bottom: 1px solid #f5f5f5;
		margin-bottom: 24px;
	}

	.merchant-login-label {
		font-weight: 500;
		font-size: 12px;
		color: #39393e;
		padding: 0;
		margin-bottom: 16px;
	}

	.merchant-input-div {
		border: 1px solid #a6a6a6;
		box-sizing: border-box;
		border-radius: 8px;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 16px;
	}

	.merchant-input-div:focus-within {
		border: 1px solid #18191f;
		box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
	}

	.togglePassword {
		border: none;
		outline: none;
		background: transparent;
		font-size: 12px;
		line-height: 17px;
		color: #38369a;
	}
	input {
		border: none;
		outline: none;
		width: 100%;
		padding: 12px 0;
	}

	.forgot-password-div {
		display: flex;
		justify-content: flex-end;
	}

	.forgot-password-button {
		text-transform: capitalize;
		padding: 24px 0;
		border: none;
		font-size: 12px;
		outline: none;
		background: transparent;
		color: #38369a;
	}

	.remember-me {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.remember-me-text {
		font-size: 12px;
		color: rgba(24, 25, 31, 0.5);
		margin: 0 0 0 10px;
	}

	.remember-me-div {
		width: 15px;
		height: 15px;
		border-radius: 3px;
		border: 1px solid #000;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.checked-green {
		background: #b6c979;
		border: none;
	}

	.login-submit-btn {
		background: #675ce8;
		border-radius: 8px;
		width: 100%;
		padding: 12px 0px;
		outline: none;
		font-weight: bold;
		font-size: 16px;
		line-height: 22px;
		color: #ffffff;
		margin: 32px 0 8px;
		border: none;
	}

	.signup-btn {
		border: none;
		font-size: 14px;
		font-weight: 700;
		padding: 0;
		outline: none;
		background: transparent;
		color: #001688;
	}

	.signup-text {
		font-size: 12px;
		color: rgba(24, 25, 31, 0.5);
		margin-bottom: 30px;
	}

	.info-desc {
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		text-transform: uppercase;
		color: #a6a6a6;
		margin-left: 8.5px;
	}

	.is-invalid {
		border: 1px solid #ff6767;
	}

	.login-error {
		font-size: 12px;
		line-height: 15px;
		color: #ff6767;
		margin-left: 5px;
	}
	@media screen and (max-width: 767px) {
		.onboarding-card {
			width: 90%;
			padding: 24px;
		}

		.merchant-input-div,
		.login-submit-btn {
			width: 100%;
		}
	}
</style>
