<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-edit-user"
    role="dialog"
  >
    <div
      class="modal-dialog p-0 m-0 fixed overflow-y-scroll top-0 right-0 flex justify-end items-end"
    >
      <div
        class="modal-content min-h-screen rounded-none bg-[#f8f8f8] p-[40px]"
      >
        <div
          class="modal-header modal-header-colored flex items-start justify-between p-0 m-0"
        >
          <h4
            class="font-semibold text-[20px] leading-[24px capitalize text-[#353535] pb-[16px]"
          >
            Edit User
          </h4>
          <button
            class="text-2xl text-[#353535]"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
            @click="reset"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body p-0 m-0">
          <div>
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Email address</label
            >
            <div>
              <input
                type="email"
                placeholder="Email address of user"
                autocomplete="off"
                v-model.trim="udata.emailAddress"
                class="bg-[[#fcfcfd]] border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full"
              />
              <div class="invalid-feedback" v-if="!udata.emailAddress">
                Field is required.
              </div>
              <div class="invalid-feedback" v-if="!udata.emailAddress">
                Email is invalid.
              </div>
            </div>
          </div>
          <div class="pt-[16px]">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Name</label
            >
            <div>
              <input
                type="email"
                placeholder="Name of the user"
                autocomplete="off"
                v-model.trim="udata.displayName"
                class="bg-[[#fcfcfd]] border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full"
              />
              <div class="invalid-feedback" v-if="!udata.displayName">
                Field is required.
              </div>
              <div class="invalid-feedback" v-if="!udata.displayName">
                Name is invalid.
              </div>
            </div>
          </div>
          <div class="py-[16px]">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Phone Number</label
            >
            <div>
              <input
                type="email"
                placeholder="Phone"
                autocomplete="off"
                v-model.trim="udata.phoneNumber"
                class="bg-[[#fcfcfd]] border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full"
              />
              <div class="invalid-feedback" v-if="!udata.phoneNumber">
                Field is required.
              </div>
              <div class="invalid-feedback" v-if="!udata.phoneNumber">
                Phone Number is invalid.
              </div>
            </div>
          </div>
          <div class="pb-[16px]">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Role</label
            >
            <div class="">
              <select
                placeholder="Select an option"
                v-model="udata.role"
                class="bg-[[#fcfcfd]] border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full"
              >
                <option v-for="r in roles" :key="r.id" :value="r.text">
                  {{ r.text }}
                </option>
              </select>
              <div class="invalid-feedback" v-if="!udata.role">
                Field is required.
              </div>
            </div>
          </div>
          <div v-if="udata.userType == 'Office'" class="pb-[16px]">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Staff Id</label
            >
            <div>
              <input
                class="bg-[[#fcfcfd]] border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full"
                type="text"
                placeholder="Staff Id of user"
                v-model="udata.staffId"
              />
            </div>
          </div>
          <div class="pb-[16px]" v-if="udata.role == 'Sales'">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Sales Access Id</label
            >
            <div>
              <input
                type="text"
                readonly
                placeholder="Sales Access Id of user"
                v-model.trim="udata.salesAccessId"
                class="bg-[[#fcfcfd]] border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full"
              />
              <div
                class="invalid-feedback"
                v-if="invitedUser.role === 'Sales' && !udata.salesAccessId"
              >
                Field is required.
              </div>
            </div>
          </div>
          <div v-if="udata.userType != 'Office'" class="pb-[16px]">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Branch</label
            >
            <div>
              <select
                placeholder="Select an option"
                v-model.trim="udata.branch"
                class="bg-[[#fcfcfd]] border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full"
              >
                <option disabled value="">Select an option</option>
                <option v-for="br in branches" :key="br.id" :value="br.id">
                  {{ br.name }}
                </option>
              </select>
              <div class="invalid-feedback" v-if="!udata.branch">
                Field is required.
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center gap-[8px]">
          <button
            class="bg-[#fcfcfd] border-[##96a0b5] border-solid border py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full"
            type="button"
            data-dismiss="modal"
            @click="reset"
          >
            Cancel
          </button>
          <button
            :disabled="isInviting"
            class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] w-full"
            type="button"
            @click.prevent="Update"
          >
            <span
              v-if="isInviting"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <i class="sr-only">Loading...</i>
            </span>
            <span v-else>Update</span>
          </button>
          <!-- 
          <button @click="getBranches">mm</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "InviteUser",
  props: ["roles", "udata"],

  data() {
    return {
      isInviting: false,
      invitedUser: {},
      isBusy: false,
      isBusyModal: false,
      branches: [],
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  validations() {
    let invitedUser = {
      emailAddress: { required, email },
      role: { required },
    };

    if (this.user.type != "Manufacturer") {
      invitedUser = { ...invitedUser, branch: { required } };
    }

    if (this.invitedUser.role === "Sales") {
      return {
        invitedUser: { ...invitedUser, salesAccessId: { required } },
      };
    } else {
      return {
        invitedUser: invitedUser,
      };
    }
  },

  methods: {
    async invite() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isInviting = true;

        let data = {
          emailAddress: this.invitedUser.emailAddress,
          identifierType: this.invitedUser.type,
          userType: this.user.type,
          name: this.invitedUser.name,
          role: this.invitedUser.role,
          parentId: this.user.parentId,
          deliveryAddress: "N/A",
          branchId: this.invitedUser.branch,
          staffId: this.invitedUser.staffId,
          salesAccessId: this.invitedUser.salesAccessId,
          createdBy: this.user.displayName,
        };

        try {
          const response = await this.$http.post("/Users/invite", data);
          if (response.ok) {
            this.$toast.success("User invited successfully", {
              icon: true,
              rtl: false,
            });
            this.reset();
            this.$emit("onInviteComplete");
          }
        } catch (error) {}
        this.isInviting = false;
      }
    },

    reset() {
      this.invitedUser = {
        type: "email",
        emailAddress: "",
        role: "",
        branch: "",
        staffId: "",
        salesAccessId: "",
      };

      setTimeout(() => {
        this.$v.$reset();
      }, 0);
    },
    getBranches() {
      var a = true;
      this.$http
        .get(
          "/Branches/getListNoPaging?active=" +
            a +
            "&parentId=" +
            this.udata.parentId
        )
        .then(function(res) {
          this.Branches = res.body;
          // console.log(res.body);
          // console.log(this.Branches);
        })
        .catch(function() {});
    },

    async Update() {
      try {
        this.isBusyModal = true;
        this.isBusy = true;
        let data = {
          id: this.udata.id,
          phoneNumber: this.udata.phoneNumber,
          emailAddress: this.udata.emailAddress,
          displayName: this.udata.displayName,
          branchId: this.udata.branchId,
          role: this.udata.role,
        };
        if (data.branchId) {
          await this.$http.put("/Users/updateBranch/" + data.id, data);
        }
        if (data.role) {
          await this.$http.put("/Users/updateName/" + data.id, data);
        }

        await this.$http.put("/Users/updateEmail/" + data.id, data);
        await this.$http.put("/Users/updatePhoneNumber/" + data.id, data);
        await this.$http.put("/Users/updateRole/" + data.id, data);
      } catch (err) {
        console.log(err);
        this.isBusyModal = false;
        this.isBusy = false;
      }
    },

    updateStatus() {
      this.isBusyModal = true;
      let data = {
        id: this.modalUser.id,
        status:
          this.modalUser.status == "Inactive"
            ? this.modalUser.username
              ? "Active"
              : "Pending"
            : "Inactive",
      };

      $("#mod-delete").modal("hide");

      this.$http
        .put("/Users/updateStatus/" + data.id, data)
        .then(function(res) {
          this.isBusyModal = false;
          this.getUsers();
        })
        .catch(function() {
          this.isBusyModal = false;
        });
    },
  },
  mounted() {
    this.invitedUser = this.udata;
    this.getBranches();
  },
};
</script>

<style scoped>
input,
select {
  padding: 12px 14px !important;
  font-size: 14px !important;
  outline: none !important;
}
::placeholder {
  color: #96a0b5 !important;
  font-weight: 400 !important;
}
</style>
