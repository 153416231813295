<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <router-link
            class="btn btn-space btn-success btn-rounded float-right"
            to="/manage-expenses"
          >
            <span class="icon icon-left mdi mdi-arrow-left"></span>
            Back
          </router-link>
          <button
            class="btn btn-primary btn-rounded btn-space float-right"
            @click="viewAdd"
          >
            <span class="icon icon-left mdi mdi-plus text-white"></span> Add
            Expense
          </button>
        </div>
        <div class="card-body">
          <div class="form-group row">
            <div class="col-7 col-sm-7 col-lg-7">
              <input ref="fileUpload" type="file" @change="onFileChange" />
            </div>

            <div class="col-5 col-sm-5 col-lg-5">
              <a
                download="expense_template.csv"
                href="/assets/templates/expense-template.csv"
                class="float-right"
                >Download Template
              </a>
            </div>
          </div>

          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th style="width: 17%">Category</th>
                      <th style="width: 15%">Amount</th>
                      <th style="width: 15%">Date</th>
                      <th style="width: 15%">Status</th>
                      <th style="width: 15%">Notes</th>
                      <th style="width: 11%"></th>
                      <th style="width: 12%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(l, index) in expenseUpload" :key="l.id">
                      <td>
                        {{ l.expense_category_name }}
                      </td>
                      <td>{{ formatMoney(l.amount) }}</td>
                      <td>{{ formatDate(l.expense_date) }}</td>
                      <td>{{ l.status }}</td>
                      <td>{{ l.notes }}</td>
                      <td class="text-danger">
                        <i
                          v-if="l.errors"
                          class="feather icon-alert-triangle"
                        ></i
                        >&nbsp;{{ l.errors }}
                      </td>
                      <td>
                        <div class="d-flex">
                          <button
                            class="btn btn-success btn-sm btn-rounded btn-space"
                            @click.prevent="viewEdit(l)"
                          >
                            <i class="feather icon-edit"></i>
                            Edit</button
                          >&nbsp;
                          <button
                            class="btn btn-danger btn-sm btn-rounded btn-space"
                            @click.prevent="onRemoveClick(index)"
                          >
                            <i class="feather icon-delete"></i> Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!expenseUpload.length">
                      <td colspan="7">
                        <div class="text-center" style="padding-top: 50px">
                          <span
                            style="font-size: 4.615rem"
                            class="mdi mdi-crosshairs-gps"
                          ></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom: 100px">
                          You don't have any expenses.
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ expenseUpload.length }} expense{{
                    expenseUpload.length != 1 ? "s" : ""
                  }}
                </div>
              </div>
              <div class="col-sm-7">
                <div class="dataTables_info text-danger">
                  {{
                    errorCount > 0
                      ? "Please correct all errors before proceeding"
                      : ""
                  }}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-12 col-sm-12 col-lg-12">
              <br />
              <br />&nbsp;
              <button
                style="width: 130px"
                :disabled="
                  isBusyModal || expenseUpload.length <= 0 || errorCount > 0
                "
                class="btn btn-primary"
                type="button"
                @click.prevent="onUploadClick"
              >
                <span v-if="isBusyModal">Busy...</span>
                <span v-else>Post</span>
              </button>

              <button
                v-if="expenseUpload.length > 0"
                style="width: 130px"
                class="btn btn-danger pull-right"
                type="button"
                @click.prevent="reset"
              >
                <span>Clear All</span>
              </button>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-add"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Add Expense</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row" v-if="!v1.is_valid">
              <div class="col-12 col-sm-12 col-lg-12">
                <label
                  class="col-12 col-sm-12 text-sm-center alert alert-danger"
                  >{{ v1.text }}</label
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Expense Category</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="postExpense.expense_category_id"
                >
                  <option
                    v-for="br in expenseCategories"
                    :key="br.id"
                    :value="br.id"
                  >
                    {{ br.name }}
                  </option>
                </select>
                <ul
                  v-if="v1.expense_category_id"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Amount</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.amount }]"
                  type="number"
                  placeholder="Enter amount"
                  v-model="postExpense.amount"
                />
                <ul v-if="v1.amount" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Notes</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <textarea
                  :class="['form-control', { 'is-invalid': v1.notes }]"
                  v-model="postExpense.notes"
                ></textarea>
                <ul v-if="v1.notes" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Expense Date</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <date-picker
                  :class="['form-control', { 'is-invalid': v1.expense_date }]"
                  v-model="postExpense.expense_date"
                  :config="options"
                ></date-picker>
                <ul v-if="v1.expense_date" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Status</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="postExpense.status"
                >
                  <option value="SAVED">SAVED</option>
                  <option value="SUBMITTED">SUBMITTED</option>
                </select>
                <ul v-if="v1.status" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 130px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="create"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Post</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Edit Expense</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row" v-if="!v2.is_valid">
              <div class="col-12 col-sm-12 col-lg-12">
                <label
                  class="col-12 col-sm-12 text-sm-center alert alert-danger"
                  >{{ v2.text }}</label
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Expense Category</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="updateExpense.expense_category_id"
                >
                  <option
                    v-for="br in expenseCategories"
                    :key="br.id"
                    :value="br.id"
                  >
                    {{ br.name }}
                  </option>
                </select>
                <ul
                  v-if="v2.expense_category_id"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Amount</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.amount }]"
                  type="number"
                  placeholder="Enter amount"
                  v-model="updateExpense.amount"
                />
                <ul v-if="v2.amount" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Notes</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <textarea
                  :class="['form-control', { 'is-invalid': v1.notes }]"
                  v-model="updateExpense.notes"
                ></textarea>
                <ul v-if="v2.notes" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Expense Date</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <date-picker
                  :class="['form-control', { 'is-invalid': v2.expense_date }]"
                  v-model="updateExpense.expense_date"
                  :config="options"
                />
                <ul v-if="v2.expense_date" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Status</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="updateExpense.status"
                >
                  <option value="SAVED">SAVED</option>
                  <option value="SUBMITTED">SUBMITTED</option>
                </select>
                <ul v-if="v2.status" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="update"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <UploadCaution
      :text="modalText"
      :action="action"
      @proceedToRemove="remove"
      @proceedToUpload="uploadExpenses"
    />
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import datePicker from "vue-bootstrap-datetimepicker";
import { helper } from "@/helper";
import Select2 from "@/components/Select2";
import SystemPermissions from "@/permissions";
import UploadCaution from "../components/UploadCaution";

export default {
  mixins: [SystemPermissions.Mixin],

  name: "settingsExpensesUpload",
  components: {
    Select2,
    UploadCaution,
    datePicker,
  },

  created() {
    if (!this.hasPermission("Expense_Post")) {
      this.$router.push("/restricted");
    }
    this.getExpenseCategories();
  },

  watch: {},

  data() {
    return {
      options: {
        format: "YYYY-MM-DD",
        useCurrent: false,
      },
      isBusy: false,
      isBusyModal: false,
      postExpense: {
        expense_date: new Date(),
      },
      updateExpense: {},
      v1: {
        status: false,
        amount: false,
        notes: false,
        expense_category_id: false,
        expense_category_name: false,
        expense_date: false,
        is_valid: true,
        text: "",
      },
      v2: {
        status: false,
        amount: false,
        notes: false,
        expense_category_id: false,
        expense_category_name: false,
        expense_date: false,
        is_valid: true,
        text: "",
      },
      expenseCategories: [],
      expenseUpload: [],
      errorCount: 0,
      modalText: "",
      action: "",
      removeIndex: 0,
    };
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },

  methods: {
    onFileChange(e) {
      this.errorCount = 0;
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      var file = files[0];

      let self = this;

      this.$papa.parse(file, {
        header: true,
        download: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: function (results) {
          self.expenseUpload = results.data;

          for (var i = 0; i < self.expenseUpload.length; i++) {
            var errors = "";
            var category = self.expenseUpload[i];
            var expenseCategoryId = self.getExpenseCategoryIdByName(
              self.expenseUpload[i].expense_category_name
            );

            if (expenseCategoryId === "") {
              errors = "Invalid category";
            } else {
              self.expenseUpload[i].expense_category_id =
                self.getExpenseCategoryIdByName(
                  self.expenseUpload[i].expense_category_name
                );
            }

            if (!category.amount) {
              errors = errors + "|Invalid amount";
            }

            if (!category.expense_date) {
              errors = errors + "|Invalid expense date";
            }

            if (!category.status) {
              errors = errors + "|Invalid status";
            }

            if (!errors) {
              category.expense_category_name =
                category.expense_category_name.trim();
              category.expense_date = new Date(
                category.expense_date
              ).toLocaleString();
              category.notes = category.notes.trim();
            } else self.errorCount++;

            self.expenseUpload[i].errors = errors;
          }
        },
      });
    },

    reset() {
      this.errorCount = 0;
      this.expenseUpload.splice(0);
      this.$refs.fileUpload.value = null;
    },

    viewExport() {
      //   $("#mod-export").modal("show");
    },

    viewAdd() {
      this.v1 = {
        status: false,
        amount: false,
        notes: false,
        expense_category_id: false,
        expense_category_name: false,
        expense_date: false,
        is_valid: true,
        text: "",
      };

      this.postExpense = {
        expense_category_id: null,
        expense_category_name: null,
        status: null,
        amount: null,
        notes: null,
        expense_date: moment().format("YYYY-MM-DD"),
        parentId: null,
      };

      $("#mod-add").modal("show");
    },

    viewEdit(c) {
      this.updateExpense = c;
      this.updateExpense.expense_date = moment(String(c.expense_date)).format(
        "YYYY-MM-DD"
      );
      $("#mod-edit").modal("show");
    },

    viewUploadExpenses() {
      this.$router.push("/manage-expenses/upload");
    },

    onRemoveClick(index) {
      this.modalText = "Are you sure you want to remove this item?";
      this.action = "remove";
      this.removeIndex = index;
      $("#mod-expense-caution").modal("show");
    },

    remove() {
      var expense = this.expenseUpload[this.removeIndex];
      if (expense.errors) {
        this.errorCount--;
      }
      this.expenseUpload.splice(this.removeIndex, 1);
      this.removeIndex = 0;
      $("#mod-expense-caution").modal("hide");
    },

    onUploadClick() {
      this.modalText = "Are you sure you want to submit expenses?";
      this.action = "upload";
      $("#mod-expense-caution").modal("show");
    },

    async uploadExpenses() {
      $("#mod-expense-caution").modal("hide");
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        parentId: this.user.parentId,
        expenses: this.expenseUpload,
      };

      await this.$http
        .post("/Expenses/createBulk", data)
        .then(function (res) {
          this.$router.push("/manage-expenses");
        })
        .catch(function () {});

      this.isBusyModal = false;
      this.isBusy = false;
    },

    async create() {
      this.isBusyModal = true;
      this.isBusy = true;

      if (this.validateCreate()) {
        this.postExpense.expense_category_name =
          this.getExpenseCategoryNameById(this.postExpense.expense_category_id);
        this.expenseUpload.push(this.postExpense);
        $("#mod-add").modal("hide");
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },

    validateCreate() {
      this.v1 = {
        status: false,
        amount: false,
        notes: false,
        expense_category_name: false,
        expense_category_id: false,
        expense_date: false,
        is_valid: true,
        text: "",
      };
      var isValid = true;

      if (!this.postExpense.amount) {
        this.v1.amount = true;
        isValid = false;
      }

      if (!this.postExpense.notes) {
        this.v1.notes = true;
        isValid = false;
      }

      if (!this.postExpense.expense_date) {
        this.v1.expense_date = true;
        isValid = false;
      }

      if (!this.postExpense.expense_category_id) {
        this.v1.expense_category_id = true;
        isValid = false;
      }

      if (!this.postExpense.status) {
        this.v1.status = true;
        isValid = false;
      }

      if (this.postExpense.amount <= 0) {
        this.v1.is_valid = false;
        this.v1.text = "Amount must be greater than 0";
        isValid = false;
      }

      return isValid;
    },

    async update() {
      this.isBusyModal = true;
      this.isBusy = true;

      if (this.validateUpdate()) {
        this.updateExpense.expense_category_name =
          this.getExpenseCategoryNameById(
            this.updateExpense.expense_category_id
          );
        if (this.updateExpense.errors) {
          this.updateExpense.errors = "";
          this.errorCount--;
        }
        $("#mod-edit").modal("hide");
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },

    validateUpdate() {
      this.v2 = {
        status: false,
        amount: false,
        notes: false,
        expense_category_id: false,
        expense_category_name: false,
        expense_date: false,
        is_valid: true,
        text: "",
      };
      var isValid = true;

      if (!this.updateExpense.amount) {
        this.v2.amount = true;
        isValid = false;
      }

      if (!this.updateExpense.notes) {
        this.v2.notes = true;
        isValid = false;
      }

      if (!this.updateExpense.expense_date) {
        this.v2.expense_date = true;
        isValid = false;
      }

      if (!this.updateExpense.expense_category_id) {
        this.v2.expense_category_id = true;
        isValid = false;
      }

      if (!this.updateExpense.status) {
        this.v2.status = true;
        isValid = false;
      }

      if (this.updateExpense.amount <= 0) {
        this.v2.is_valid = false;
        this.v2.text = "Amount must be greater than 0";
        isValid = false;
      }

      return isValid;
    },

    async getExpenseCategories() {
      this.isBusy = true;

      await this.$http
        .get(
          "/ExpenseCategories/getListNoPaging?active=true" +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "")
        )
        .then(function (res) {
          this.expenseCategories = res.body;
          this.isBusy = false;
        })
        .catch(function () {});

      this.isBusy = false;
    },

    getExpenseCategoryNameById(id) {
      var category = this.expenseCategories.find((x) => x.id === id);
      if (category == null || category == undefined) return "";
      else return category.name;
    },

    getExpenseCategoryIdByName(name) {
      var category = this.expenseCategories.find((x) => x.name === name);
      if (category == null || category == undefined) return "";
      else return category.id;
    },

    formatDate(date) {
      if (date) return moment(date).format("lll");
      else return "";
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },
  },
};
</script>