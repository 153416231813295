<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-view-association"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1">
            <b>View Association for this Group </b>
          </h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <h6>Distributor Price (DP) Association</h6>
            <span
              v-if="distributorPriceLoading"
              class="spinner-border text-success spinner-border-sm"
              role="status"
            >
              <i class="sr-only">Loading...</i>
            </span>
            <ul v-else class="list-group">
              <li
                v-for="g in prices.distributorPrices"
                :key="g.id"
                class="list-group-item"
              >
                {{ g.productName + "/" + g.amount }}
              </li>
            </ul>
          </div>
          <div class="mt-2">
            <h6>Recommended Retail Price (RRP) Association</h6>
            <span
              v-if="retailPriceLoading"
              class="spinner-border text-success spinner-border-sm"
              role="status"
            >
              <i class="sr-only">Loading...</i>
            </span>

            <ul v-else class="list-group">
              <li
                v-for="g in prices.retailPrices"
                :key="g.id"
                class="list-group-item"
              >
                {{ g.productName + "/" + g.amount }}
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            data-dismiss="modal"
            aria-hidden="true"
            class="btn btn-primary"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewAssociation",
  props: ["groupId"],

  data() {
    return {
      groupPriceOptions: [],
      retailPriceLoading: false,
      distributorPriceLoading: false,
    };
  },

  watch: {
    groupId: function (value) {
      if (value) {
        this.getOptionPrices(value);
      }
    },
  },

  computed: {
    prices: function () {
      const distributorPrices = [];
      const retailPrices = [];

      this.groupPriceOptions.forEach((element) => {
        retailPrices.push({
          id: element.id,
          productName: element.product.name,
          amount: element.priceOption.retailPrice,
        });
        distributorPrices.push({
          id: element.id,
          productName: element.product.name,
          amount: element.priceOption.distributorPrice,
        });
      });
      return { distributorPrices, retailPrices };
    },
  },

  methods: {
    async getOptionPrices(id) {
      this.isBusy = true;
      this.distributorPriceLoading = true;

      try {
        const response = await this.$http.get(
          "/Groups/getPriceOptionsNoPaging/" + id
        );

        if (response.ok && response.status === 200) {
          this.groupPriceOptions = response.body;
        }

        this.isBusy = false;
        this.distributorPriceLoading = false;
      } catch (error) {
        this.isBusy = false;
        this.distributorPriceLoading = false;
      }
    },
  },
};
</script>

<style>
</style>