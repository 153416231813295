<template>
  <button
    v-if="user.role == 'Administrator' && !isTextBtn"
    class="bg-transparent hover:bg-transaprent border-none p-0 m-0 text-[14px] font-normal leading-[20px] text-[#DC2828] cursor-pointer py-[12px]"
    data-toggle="tooltip"
    title="Delete"
    :disabled="deleteLoading"
    @click.prevent="onDelete"
  >
    <span
      v-if="deleteLoading"
      class="spinner-border spinner-border-sm"
      role="status"
    >
      <i class="sr-only">Loading...</i>
    </span>
    <span v-else>
      <i class="icon mdi mdi-delete text-white"></i>
    </span>
  </button>
  <a v-else class="" :disabled="deleteLoading" @click.prevent="onDelete">
    <span
      class="text-[14px] font-normal leading-[20px] text-[#DC2828] cursor-pointer px-[16px]"
      >Delete</span
    >
  </a>
</template>

<script>
export default {
  name: "DeletePriceOption",
  props: ["id", "isTextBtn"],

  data() {
    return {
      deleteLoading: false,
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  methods: {
    onDelete() {
      this.$emit("showCaution", this.id);
    },

    async deleteDistributorPrice() {
      this.deleteLoading = true;
      this.isBusy = true;
      try {
        const response = await this.$http.delete(
          "/PriceOptions/delete/" + this.id
        );

        if (response.ok && response.status === 200) this.$emit("onComplete");
        this.deleteLoading = false;
        this.isBusy = true;
        this.$toast.success("Deleted successful", {
          icon: true,
          rtl: false,
        });
      } catch (error) {
        this.deleteLoading = false;
        this.isBusy = true;
      }
    },
  },
};
</script>
