<template>
  <div
    class="modal bg-[rgba(0, 0, 0, 1)] fade py-1 z-[111111111]"
    id="mod-approve"
    role="dialog"
  >
    <div class="modal-dialog mx-auto">
      <div class="modal-content p-[24px] bg-[#f8f8f8]">
        <div class="modal-header flex items-start justify-between p-0 m-0">
          <h4
            class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
          >
            Approve Order
          </h4>
          <button
            class="text-2xl text-[#353535]"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body m-0 p-0">
          <p class="text-[14px] leading-[20px] font-normal text-[#353535]">
            You are about to approve a purchase order
            {{ purchaseOrder.orderNumber }}
            <span class="font-semibold"></span>
          </p>
        </div>
        <div class="flex items-center gap-[8px] mt-[16px]">
          <button
            class="border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full outline-none focus:outline-none"
            type="button"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] w-full outline-none focus:outline-none"
            type="button"
            :disabled="isBusyModal"
            @click.prevent="confirmApproveOrder"
          >
            <i v-if="loader.approve" class="fas fa-spinner fa-spin mr-2"></i>

            {{ loader.approve ? "Busy..." : "Approve Order" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["orderId", "purchaseOrder", "isBusyModal", "isBusy", "closeModal"],
  data() {
    return {
      loader: { approve: false, reject: false, update: false },
    };
  },
  methods: {
    async confirmApproveOrder() {
      try {
        this.loader.approve = true;
        const response = await this.$http.put(
          `/PurchaseOrders/manufacturer/updateStatus/${this.purchaseOrder.id}`
        );
        if (response.ok) {
          this.loader.approve = false;
          // eslint-disable-next-line no-undef
          $("#mod-approve").modal("hide");
          this.closeModal();
        }
        // this.isActivityLoading = false;
      } catch (error) {
        this.loader.approve = false;
        console.log(error);
      }
    },
  },
};
</script>
