<template>
  <p
    class="text-[#DC2828] text-[14px] font-normal leading-[20px]"
    @click.prevent="deletePurchaseItemDetail"
  >
    Delete
  </p>
</template>

<script>
export default {
  name: "DeletePurchaseOrderItemDetail",
  props: ["item"],

  methods: {
    onDeleteClick() {
      this.$emit("showAction");
    },

    async deletePurchaseItemDetail() {
      this.$emit("onRemove", this.item.id);
    },
  },
};
</script>

<style></style>
