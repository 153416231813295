<template>
  <div class="row">
    <div class="col">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button class="btn btn-success btn-rounded btn-space" @click="goBack">
            <i class="mdi icon mdi-backburger text-white"></i>
            Back
          </button>
        </div>
        <div class="card-body">
          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="o in orderStages" :key="o.id">
                      <td>{{ o.name }}</td>
                      <td>
                        <SwitchStageStatus
                          v-if="
                            o.name !== 'Dispatched' &&
                            hasPermission('Sales_Order_Stages_Update')
                          "
                          :stage="o"
                          @onComplete="onComplete"
                        />
                        <span
                          v-else
                          :class="[
                            'badge',
                            ' badge-pill',
                            { 'badge-success': o.active },
                            { 'badge-danger': !o.active },
                          ]"
                        >
                          {{ o.active ? "Active" : "Inactive" }}
                        </span>
                      </td>
                      <td>
                        <div class="d-flex">
                          <button
                            v-if="
                              o.name !== 'Dispatched' &&
                              hasPermission('Sales_Order_Stages_Update')
                            "
                            class="
                              btn btn-success btn-sm btn-rounded btn-space
                              mr-1
                            "
                            @click.prevent="renameOrderItem(o)"
                          >
                            <i
                              class="
                                icon
                                mdi mdi-circle-edit-outline
                                text-white
                              "
                            ></i>
                            Rename
                          </button>
                          <HideStage
                            v-if="
                              o.name !== 'Dispatched' &&
                              hasPermission('Sales_Order_Stages_Update')
                            "
                            :stage="o"
                            @onComplete="onComplete"
                            :ref="o.id"
                            @showHideCaution="showCaution"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <RenameStageModal
      @onRenameComplete="onRenameCompleteHandler"
      :stage="orderStage"
    />
    <HideStageCaution @proceed="perfomHide" :text="text" />
  </div>
</template>

<script>
import SwitchStageStatus from "../../components/SettingsPurchaseOrder/SwitchStageStatus";
import HideStage from "../../components/SettingsPurchaseOrder/HideStage";
import HideStageCaution from "../../components/SettingsPurchaseOrder/HideStageCaution";
import RenameStageModal from "../../components/SettingsPurchaseOrder/RenameStageModal";
import SystemPermissions from "@/permissions";

export default {
  mixins: [SystemPermissions.Mixin],
  components: {
    SwitchStageStatus,
    HideStage,
    HideStageCaution,
    RenameStageModal,
  },

  data() {
    return {
      isBusy: false,
      orderStages: [],
      orderStage: {},
      id: "",
      text: "",
    };
  },
  async created() {
    if (!this.hasPermission("Sales_Order_Stages_View")) {
      this.$router.push("/restricted");
    }
  },

  mounted() {
    this.getOrderStages();
  },

  methods: {
    async getOrderStages() {
      this.isBusy = true;
      try {
        const response = await this.$http.get(
          "/PurchaseOrders/orderStage/getList"
        );

        if (response.ok && response.status === 200)
          this.orderStages = response.body.sort(
            (firstItem, secondItem) => firstItem.stage - secondItem.stage
          );

        this.isBusy = false;
      } catch (error) {
        this.isBusy = true;
      }
    },

    renameOrderItem(orderStage) {
      this.orderStage = orderStage;
      // eslint-disable-next-line no-undef
      $("#mod-rename-stage").modal("show");
    },

    onRenameCompleteHandler() {
      this.orderStage = {};
      this.getOrderStages();
      // eslint-disable-next-line no-undef
      $("#mod-rename-stage").modal("hide");
    },

    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },

    showCaution({ id, text }) {
      this.id = id;
      this.text = text;
      // eslint-disable-next-line no-undef
      $("#mod-hide-order-stage").modal("show");
    },

    async perfomHide() {
      // eslint-disable-next-line no-undef
      $("#mod-hide-order-stage").modal("hide");
      const component = this.$refs[this.id][0];
      component.hideStage();
    },

    onComplete() {
      this.getOrderStages();
    },
  },
};
</script>

<style>
</style>