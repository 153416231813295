<template>
  <div class="onboarding-body">
    <div class="onboarding-card">
      <img src="/assets/img/growtrade-logo.png" alt="logo" width="175" />
      <p class="onboarding-header">Reset Password</p>
      <p class="onboarding-desc">Please enter your new password</p>
      <form @submit.prevent="login">
        <div>
          <label class="merchant-login-label"> ENTER NEW PASSWORD* </label>
          <div
            :class="[
              'merchant-input-div',
              {
                'is-invalid': credentialValidation.password || credentialValidation.passwordValid,
              },
            ]"
          >
            <input :type="passwordType" placeholder="Enter Password" v-model="password" />
            <button type="button" class="togglePassword" @click.prevent="togglePassword">
              Show
            </button>
          </div>
          <p v-if="credentialValidation.password">
            <img src="/assets/img/error-icon-new.svg" alt="error" />
            <span class="login-error">Password cannot be empty</span>
          </p>
          <p v-if="credentialValidation.passwordValid">
            <img src="/assets/img/error-icon-new.svg" alt="error" />
            <span class="login-error">Password must be at least 6 characters</span>
          </p>
        </div>
        <div class="mt-2">
          <label class="merchant-login-label"> CONFIRM NEW PASSWORD* </label>
          <div
            :class="[
              'merchant-input-div',
              {
                'is-invalid':
                  credentialValidation.confirmPassword || credentialValidation.confirmPasswordValid,
              },
            ]"
          >
            <input
              :type="confirmPasswordType"
              placeholder="Enter Password"
              v-model="confirmPassword"
            />
            <button type="button" class="togglePassword" @click.prevent="toggleConfirmPassword">
              Show
            </button>
          </div>
          <p v-if="credentialValidation.confirmPassword">
            <img src="/assets/img/error-icon-new.svg" alt="error" />
            <span class="login-error">Confirm password cannot be empty</span>
          </p>
          <p v-if="credentialValidation.confirmPasswordLength">
            <img src="/assets/img/error-icon-new.svg" alt="error" />
            <span class="login-error">Confirm password must be at least 6 characters</span>
          </p>
          <p v-if="credentialValidation.noMatch">
            <img src="/assets/img/error-icon-new.svg" alt="error" />
            <span class="login-error">Passwords must match</span>
          </p>
        </div>

        <button type="submit" class="login-submit-btn">Submit</button>
      </form>
    </div>
    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-growtrade-resetPassword"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <strong>Reset password</strong>
            </h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              @click="cancel"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="parent-div success-div">
                <div class="first-child">
                  <div class="second-child">
                    <svg width="20" height="15" viewBox="0 0 20 15" fill="none">
                      <path
                        d="M19.599 0.410713C19.2129 0.0245541 18.5869 0.0245541 18.2007 0.410713L6.35464 12.2568L1.80123 7.70343C1.41511 7.31727 0.789098 7.31731 0.4029 7.70343C0.0167416 8.08955 0.0167416 8.71556 0.4029 9.10172L5.65548 14.3542C6.04148 14.7403 6.66796 14.7401 7.05381 14.3542L19.599 1.80904C19.9851 1.42292 19.9851 0.796872 19.599 0.410713Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <p class="text-center text-bold">
              Your password has been reset successfully <br />
              Proceed to login
            </p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" @click.prevent="goToLogin" type="button">
              <span> Log in </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { helper } from "@/helper";

export default {
  name: "distributor-reset-password",
  components: {},

  data() {
    return {
      password: null,
      confirmPassword: null,
      passwordType: "password",
      confirmPasswordType: "password",
      credentialValidation: {
        password: false,
        passwordValid: false,
        confirmPassword: false,
        confirmPasswordValid: false,
        noMatch: false,
      },
      isBusy: false,
    };
  },

  mounted() {},

  beforeCreate() {},

  created() {},

  computed: {
    // user: function() {
    //   return this.$store.state.currentUser;
    // },
  },

  methods: {
    goToLogin() {
      this.$router.push(`/growtrade-login?client=distributor`);
    },
    togglePassword() {
      if (this.passwordType === "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },

    toggleConfirmPassword() {
      if (this.confirmPasswordType === "password") {
        this.confirmPasswordType = "text";
      } else {
        this.confirmPasswordType = "password";
      }
    },

    cancel() {
      $("#mod-growtrade-resetPassword").modal("hide");
    },
    async login() {
      this.isBusy = true;

      if (this.validateUserInfo()) {
        let data = {
          password: this.password,
        };

        $("#mod-growtrade-resetPassword").modal("show");
        console.log(data);

        // await this.$http
        //   .put(`/${this.user.isSalesUser ? "SalesUsers" : "Users"}/update/` + data.id, data)
        //   .then(function(res) {
        //     this.$store.commit("setCurrentUser", this.user);

        //     this.$toast.success("Your profile details have been updated.", {
        //       icon: false,
        //       rtl: false,
        //     });

        //     this.isBusyProfile = false;
        //   })
        //   .catch(function() {
        //     var currentUser = clone(this.$store.state.currentUser);
        //     this.user.phoneNumber = currentUser.phoneNumber;
        //     this.user.emailAddress = currentUser.emailAddress;
        //     this.user.displayName = currentUser.displayName;
        //     this.isBusyProfile = false;
        //   });
      } else {
        this.isBusyProfile = false;
      }
    },

    validateUserInfo() {
      this.credentialValidation = {
        password: false,
        passwordValid: false,
        confirmPassword: false,
        confirmPasswordValid: false,
        noMatch: false,
      };

      let isValid = true;

      if (!this.password) {
        this.credentialValidation.password = true;
        isValid = false;
      }

      if (this.password && !helper.validateLength(this.password, 6)) {
        this.credentialValidation.passwordValid = true;
        isValid = false;
      }

      if (!this.confirmPassword) {
        this.credentialValidation.confirmPassword = true;
        isValid = false;
      }

      if (this.confirmPassword && !helper.validateLength(this.confirmPassword, 6)) {
        this.credentialValidation.confirmPasswordValid = true;
        isValid = false;
      }

      if (this.confirmPassword && this.confirmPassword !== this.password) {
        this.credentialValidation.noMatch = true;
        isValid = false;
      }

      return isValid;
    },
  },
};
</script>

<style scoped>
.resend-button {
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #38369a;
  background: transparent;
}

.onboarding-body {
  position: relative;
  background-color: #f6f6f6;
  min-width: 100vw;
  min-height: 100vh;
  padding: 48px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onboarding-card {
  width: 524px;
  background-color: #fff;
  padding: 48px;
}

.onboarding-header {
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: #373737;
  margin: 32px 0 8px;
}

.onboarding-desc {
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: rgba(24, 25, 31, 0.5);
  padding-bottom: 16px;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 24px;
}

.merchant-login-label {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #18191f;
  padding: 0;
  margin-bottom: 16px;
}

.merchant-input-div {
  border: 1px solid #a6a6a6;
  box-sizing: border-box;
  border-radius: 8px;
  width: 426px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
}

.merchant-input-div:focus-within {
  border: 1px solid #18191f;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
}

.togglePassword {
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  line-height: 17px;
  color: #38369a;
}
input {
  border: none;
  outline: none;
  width: 100%;
}

.login-submit-btn {
  background: #675ce8;
  border-radius: 8px;
  width: 426px;
  height: 64px;
  outline: none;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin: 32px 0 8px;
  border: none;
}

.signup-btn {
  border: none;
  font-size: 18px;
  font-weight: 700;
  padding: 0;
  line-height: 28px;
  outline: none;
  background: transparent;
  color: #001688;
}

.signup-text {
  font-size: 18px;
  color: rgba(24, 25, 31, 0.5);
  margin-bottom: 60px;
}

.onboarding-helper {
  font-size: 14px;
  line-height: 142.52%;
  color: rgba(24, 25, 31, 0.7);
  margin-top: 8px;
}

.onboarding-helper span {
  font-weight: 700;
}

.is-invalid {
  border: 1px solid #ff6767;
}

.login-error {
  font-size: 12px;
  line-height: 15px;
  color: #ff6767;
  margin-left: 5px;
}

.parent-div {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px auto 34px;
  width: 110px;
  height: 110px;
}

.first-child {
  width: 86.52px;
  height: 86.52px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.second-child {
  width: 61.8px;
  height: 61.8px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.success-div {
  border: 1px solid rgba(182, 201, 121, 0.2);
}

.success-div .first-child {
  border: 1px solid rgba(182, 201, 121, 0.5);
}

.success-div .second-child {
  background: rgba(182, 201, 121, 1);
}

.text-bold {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}
@media screen and (max-width: 767px) {
  .merchant-input-div,
  .login-submit-btn {
    width: 100%;
  }
}
</style>
