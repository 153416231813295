<template>
  <select
    class="custom-select custom-select-md"
    v-model="salesOrder.action"
    @input="onActionSelect($event.target.value)"
  >
    <option v-for="p in preparedActions" :key="p.value" :value="p.value">
      {{ p.text }}
    </option>
  </select>
</template>

<script>
export default {
  props: ["actions", "salesOrder", "salesOrderIndex", "isProductExist"],

  computed: {
    preparedActions: function () {
      return this.isProductExist
        ? this.actions.filter((x) => x.value !== 2)
        : this.actions;
    },
  },

  methods: {
    onActionSelect(value) {
      this.$emit("onActionSelectHandler", {
        value,
        salesOrderIndex: this.salesOrderIndex,
      });
    },
  },
};
</script>