<template>
  <section class="bg-white grid place-items-center h-[100dvh] md:h-screen">
    <div class="px-2 sm:px-6 lg:px-8 max-w-7xl">
      <div class="max-w-[500px] mx-auto">
        <div>
          <img
            class="w-auto h-20 mx-auto"
            src="/assets/img/uac-logo.png"
            alt="Logo"
          />
          <h1
            class="mt-[16px] mb-[8px] text-[20px] sm:text-[24px] font-bold text-black"
          >
            Reset Your Password
          </h1>
          <p
            class="text-[16px] sm:text-[18px] leading-[21.78px] text-[#81868c] mb-[20px] font-normal"
          >
            Forget your password? Please enter your registered email address.
          </p>
        </div>
        <form>
          <div v-if="mode == 'email'" class="space-y-4">
            <div>
              <label
                for="username"
                class="text-[16px] leading-[19.36px] font-normal text-[#353535]"
              >
                Mail
              </label>
              <div>
                <input
                  id="email"
                  class="w-full px-[16px] py-[12px] text-[14px] placeholder-gray-[#81868c] border-[0.5px] border-[rgba(0, 0, 0, 0.3)] outline-none rounded-[8px]"
                  type="text"
                  placeholder="Enter your email address"
                  autocomplete="off"
                />
              </div>
            </div>
            <!-- <div
              class="my-2"
              style="cursor: pointer"
              @click.prevent="changeMode('phone')"
            >
              <p
                class="text-[18px] leading-[21.78px] text-[#81868c] mb-[20px] font-normal"
              >
                Use Phone Number (Merchant Only)
              </p>
            </div> -->
            <div>
              <button
                type="submit"
                :disabled="isBusy"
                class="w-full px-6 py-[12px] text-lg font-normal leading-5 text-white transition-all duration-200 bg-[#2463EB] border border-solid border-[#2463EB] outline-none focus-none rounded-[8px] disabled:opacity-50"
              >
                <i v-if="isBusy" class="fas fa-spinner fa-spin mr-2"></i>
                {{ isBusy ? "Busy..." : "Reset Password" }}
              </button>
            </div>
            <div class="mt-6 text-center">
              <router-link
                to="/login"
                class="text-[#1c1c1c] hover:text-[#1c1c1c] decoration-none text-[16px] leading-[21.86px]"
              >
                Back to Login
              </router-link>
            </div>
          </div>

          <div v-if="mode == 'phone'">
            <!-- Step 1: Enter Phone Number -->
            <form v-if="phoneValStep === 1">
              <label class="block text-base font-medium text-gray-700"
                >Phone Number</label
              >
              <div class="mt-1">
                <div class="flex">
                  <span
                    class="inline-flex w-[15%] items-center px-1 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm font-semibold"
                  >
                    +234
                  </span>
                  <input
                    type="text"
                    class="w-[85%] px-1 py-1 text-md placeholder-gray-[#81868c] border border-gray-500 outline-none rounded-r-md"
                    placeholder="10 digits"
                    autocomplete="off"
                    v-model="user.phone"
                  />
                </div>
                <p
                  v-if="!phoneValidation.phoneValid"
                  class="mt-2 text-sm text-red-600"
                >
                  * 10 digit phone number required.
                </p>
              </div>

              <div
                class="my-2"
                style="cursor: pointer"
                @click.prevent="changeMode('email')"
              >
                <p class="text-lg text-[#81868c] mb-1 font-medium">
                  Use Email Address
                </p>
              </div>

              <div class="mt-6">
                <button
                  :disabled="isBusyStep1"
                  class="w-full px-6 py-1 text-base font-normal leading-5 text-white transition-all duration-200 bg-[#2463EB] border border-solid border-[#2463EB] outline-none rounded-md disabled:opacity-50"
                  @click.prevent="sendPhoneCode"
                >
                  <i class="feather icon-lock"></i> &nbsp;
                  <span v-if="isBusyStep1">Busy...</span>
                  <span v-else>Send Code</span>
                </button>
              </div>

              <div class="mt-6 text-center">
                <router-link
                  to="/login"
                  class="text-[#1c1c1c] hover:text-[#1c1c1c] decoration-none text-[16px] leading-[21.86px]"
                >
                  Back to Login
                </router-link>
              </div>
            </form>

            <!-- Step 2: Enter OTP -->
            <form v-if="phoneValStep === 2">
              <div class="mb-4">
                <label class="text-base font-normal text-[#353535]"
                  >Enter OTP</label
                >
                <div class="mt-1">
                  <otp-input
                    :isValid="verifOTPValid"
                    :digits="6"
                    type="number"
                    @on-complete="onCompleteOTPHandler"
                    @on-changed="onChangedOTPHandler"
                    @on-paste="onPasteOTPHandler"
                    class="w-full text-md px-1 py-1"
                  >
                    <template #errorMessage> OTP is not valid </template>
                  </otp-input>

                  <div v-if="isBusyOtp" class="mt-2 text-sm text-gray-600">
                    Resending OTP...
                  </div>
                  <div v-else class="mt-2 text-md text-gray-600">
                    <span v-if="timerValue > 0"
                      >Resending in {{ prettyTime }}</span
                    >
                    <button
                      v-else
                      @click.prevent="resendPhoneOtp"
                      class="text-indigo-600 hover:underline"
                    >
                      Didn't get OTP? Resend
                    </button>
                  </div>
                </div>
              </div>

              <div class="mt-6">
                <button
                  :disabled="isBusyStep2"
                  class="w-full px-6 py-1 text-base font-normal leading-5 text-white transition-all duration-200 bg-[#2463EB] border border-solid border-[#2463EB] outline-none rounded-md disabled:opacity-50"
                  @click.prevent="validatePhoneCode"
                >
                  <i class="feather icon-lock"></i> &nbsp;
                  <span v-if="isBusyStep2">Busy...</span>
                  <span v-else>Validate Code</span>
                </button>
              </div>

              <div class="mt-6 text-center">
                <router-link
                  to="/login"
                  class="text-[#1c1c1c] hover:text-[#1c1c1c] decoration-none text-md"
                >
                  Back to Login
                </router-link>
              </div>
            </form>

            <!-- Step 3: Enter New Password -->
            <form v-if="phoneValStep === 3">
              <div class="mb-4">
                <label class="text-base font-normal text-[#353535]"
                  >PLEASE ENTER YOUR NEW PASSWORD</label
                >
                <div class="mt-1 relative">
                  <input
                    :type="toggledViewPassword ? 'text' : 'password'"
                    class="w-full px-1 py-1 text-md placeholder-gray-[#81868c] border border-gray-500 outline-none rounded-md"
                    placeholder="Enter Password"
                    autocomplete="off"
                    v-model.trim="user.password"
                  />
                  <div
                    class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
                    @click="toggledViewPassword = !toggledViewPassword"
                  >
                    <i
                      class="fa"
                      :class="toggledViewPassword ? 'fa-eye' : 'fa-eye-slash'"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
                <p
                  v-if="!phoneValidation.passwordValid"
                  class="mt-2 text-sm text-red-600"
                >
                  * Minimum of 8 characters required.
                </p>
              </div>

              <div class="mb-4">
                <label class="text-base font-normal text-[#353535]"
                  >CONFIRM PASSWORD BY ENTERING IT AGAIN</label
                >
                <div class="mt-1 relative">
                  <input
                    :type="toggledViewPasswordConfirm ? 'text' : 'password'"
                    class="w-full px-1 py-1 text-md placeholder-gray-[#81868c] border border-gray-500 outline-none rounded-md"
                    placeholder="Enter Password"
                    autocomplete="off"
                    v-model.trim="user.confirmPassword"
                  />
                  <div
                    class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer"
                    @click="
                      toggledViewPasswordConfirm = !toggledViewPasswordConfirm
                    "
                  >
                    <i
                      class="fa"
                      :class="
                        toggledViewPasswordConfirm ? 'fa-eye' : 'fa-eye-slash'
                      "
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
                <p
                  v-if="!phoneValidation.confirmPasswordValid"
                  class="mt-2 text-sm text-red-600"
                >
                  * Minimum of 8 characters required.
                </p>
              </div>

              <p
                v-if="!phoneValidation.passwordMatch"
                class="mt-2 text-sm text-red-600"
              >
                * Password and Confirm password must match.
              </p>

              <div class="mt-6">
                <button
                  :disabled="isBusyStep3"
                  class="w-full px-6 py-1 text-base font-normal leading-5 text-white transition-all duration-200 bg-[#2463EB] border border-solid border-[#2463EB] outline-none rounded-md disabled:opacity-50"
                  @click.prevent="confirmNewPassword"
                >
                  <i class="feather icon-lock"></i> &nbsp;
                  <span v-if="isBusyStep3">Busy...</span>
                  <span v-else>Reset Password</span>
                </button>
              </div>

              <div class="mt-6 text-center">
                <router-link
                  to="/login"
                  class="text-[#1c1c1c] hover:text-[#1c1c1c] decoration-none text-[16px] leading-[21.86px]"
                >
                  Back to Login
                </router-link>
              </div>
            </form>
          </div>
        </form>
      </div>
      <p class="text-md font-normal text-center mt-[80px] md:mt-[100px]">
        Powered by
        <a class="font-bold" href="https://wekurnect.io" target="_blank"
          >Wekurnect</a
        >
      </p>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { helper } from "@/helper";
import { required, email } from "vuelidate/lib/validators";
import OtpInput from "otp-input-vue2";

export default {
  name: "forgotPassword",
  components: {
    OtpInput,
  },
  data() {
    return {
      isBusy: false,
      user: {
        emailAddress: "",
        phone: "",
        password: "",
        confirmPassword: "",
      },
      type: "password",
      toggledViewPassword: false,
      typeConfirm: "password",
      toggledViewPasswordConfirm: false,
      mode: "email",
      phoneValStep: 1,

      phoneValidation: {
        phoneValid: true,
        passwordValid: true,
        confirmPasswordValid: true,
        passwordMatch: true,
      },

      timerSeconds: "00",
      timerValue: 300,
      timer: null,
      timerStarted: false,

      verifOTP: "",
      verifOTPValid: true,
      isBusyStep1: false,
      isBusyStep2: false,
      isBusyStep3: false,
      isBusyOtp: false,
    };
  },
  validations: {
    user: {
      emailAddress: { required, email },
    },
  },

  computed: {
    prettyTime() {
      let time = this.timerValue / 60;
      let minutes = parseInt(time);
      let minutesShow =
        minutes > 1 ? `${minutes} minutes` : `${minutes} minute`;
      let roundedSeconds = Math.round((time - minutes) * 60);
      let secondes =
        roundedSeconds > 1
          ? `${roundedSeconds} seconds`
          : `${roundedSeconds} second`;
      return minutesShow + " " + secondes;
    },
  },

  methods: {
    changeMode(md) {
      this.mode = md;
    },

    gotoLogin() {
      $("#mod-phone-password-change").modal("hide");
      this.$router.push("/login");
    },

    phoneStepOneValid() {
      let isvalid = true;
      if (this.user.phone === "" || this.user.phone.length !== 10) {
        isvalid = false;
        this.phoneValidation.phoneValid = false;
      }
      return isvalid;
    },

    phoneStepTwoValid() {
      let isvalid = true;
      if (this.verifOTP === "" || this.verifOTP.length !== 6) {
        isvalid = false;
        this.verifOTPValid = false;
      }
      return isvalid;
    },

    phoneStepThreeValid() {
      let isvalid = true;
      if (this.user.password === "" || this.user.password.length < 8) {
        isvalid = false;
        this.phoneValidation.passwordValid = false;
      } else if (
        this.user.confirmPassword === "" ||
        this.user.confirmPassword.length < 8
      ) {
        isvalid = false;
        this.phoneValidation.confirmPasswordValid = false;
      } else if (this.user.password !== this.user.confirmPassword) {
        isvalid = false;
        this.phoneValidation.passwordMatch = false;
      } else {
        return isvalid;
      }
    },

    sendPhoneCode() {
      if (this.phoneStepOneValid()) {
        this.isBusyStep1 = true;
        this.$http
          .post(`/Users/sendToken?phoneNumber=234${this.user.phone}`)
          .then(function(res) {
            if (res.body) {
              this.resetTime();
              this.start();
              this.isBusyStep1 = false;
              this.phoneValStep = 2;
            } else {
              this.$toast.error("Error sending OTP", {
                icon: true,
                rtl: false,
              });
            }
          })
          .catch(function() {
            this.isBusyStep1 = false;
          });
      }
    },

    validatePhoneCode() {
      this.isBusyStep2 = true;
      if (this.phoneStepTwoValid()) {
        this.isBusyStep2 = false;
        this.phoneValStep = 3;
      } else {
        this.isBusyStep2 = false;
      }
    },

    confirmNewPassword() {
      if (this.phoneStepThreeValid()) {
        this.isBusyStep3 = true;

        this.$http
          .post(
            `/Users/changePassword?phoneNo=234${this.user.phone}&password=${this.user.password}&confirmPasword=${this.user.confirmPassword}&token=${this.verifOTP}`
          )
          .then(function(res) {
            if (res.body) {
              this.resetTime();
              $("#mod-phone-password-change").modal("show");
              this.isBusyStep3 = false;
              this.verifOTPValid = false;
            } else {
              this.$toast.error("Error validating OTP", {
                icon: true,
                rtl: false,
              });
            }
          })
          .catch(function() {
            this.isBusyStep3 = false;
          });
      }
    },

    start() {
      this.timerStarted = true;
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.timerValue > 0) {
            this.timerValue--;
          } else {
            clearInterval(this.timer);
            // this.resetTime()
          }
        }, 1000);
      }
    },
    stop() {
      this.timerStarted = false;
      clearInterval(this.timer);
      this.timer = null;
    },
    resetTime() {
      this.stop();
      this.timerValue = 300;
    },

    onCompleteOTPHandler(code) {
      this.verifOTP = code;
    },

    onChangedOTPHandler(lastEnteredCode) {
      // console.log("code changed",  lastEnteredCode);
    },

    onPasteOTPHandler(code) {
      this.verifOTP = code;
    },

    resendPhoneOtp() {
      this.isBusyOtp = true;
      this.$http
        .post(`/Users/sendToken?phoneNumber=234${this.user.phone}`)
        .then(function(res) {
          if (res.body) {
            this.resetTime();
            this.start();
            this.isBusyOtp = false;
          } else {
            this.$toast.error("Error resending OTP", {
              icon: true,
              rtl: false,
            });
          }
        })
        .catch(function() {
          this.isBusyOtp = false;
        });
    },

    async reset() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isBusy = true;
        let body = {
          email: this.user.emailAddress,
        };

        await this.$http
          .put(`/Users/resetPassword`, body)
          .then(function(res) {
            $.gritter.add({
              title: "Password reset",
              text: "Your new password has been sent to your email address.",
              class_name: "color success",
            });

            this.$router.push("/login");
          })
          .catch(function() {});

        this.isBusy = false;
      }
    },
  },
};
</script>

<style scoped>
.parent-div {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px auto 34px;
  width: 110px;
  height: 110px;
}

.first-child {
  width: 86.52px;
  height: 86.52px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.second-child {
  width: 61.8px;
  height: 61.8px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.success-div {
  border: 1px solid rgba(182, 201, 121, 0.2);
}

.success-div .first-child {
  border: 1px solid rgba(182, 201, 121, 0.5);
}

.success-div .second-child {
  background: rgba(182, 201, 121, 1);
}

.text-bold {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}
</style>
