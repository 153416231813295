<template>
  <button
    v-if="hasPermission('Price_Option_Update')"
    :class="[
      { 'btn-success': optionPrice.active },
      { 'btn-danger': !optionPrice.active },
    ]"
    class="btn btn-sm btn-rounded btn-space mr-1"
    :disabled="activeLoading"
    @click.prevent="switchStatus"
  >
    <span v-if="activeLoading">Busy...</span>
    <span v-else>
      {{ optionPrice.active ? "Active" : "Inactive" }}
    </span>
  </button>
  <span v-else> {{ optionPrice.active ? "Active" : "Inactive" }}</span>
</template>

<script>
    import SystemPermissions from "@/permissions";
    export default {
        mixins: [SystemPermissions.Mixin],
  name: "SwitchPriceOptionStatus",
  props: ["optionPrice"],

  data() {
    return {
      activeLoading: false,
    };
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },

  methods: {
    async switchStatus() {
      this.activeLoading = true;
      this.isBusy = true;
      try {
        const response = await this.$http.put(
          "/PriceOptions/updateStatus/" +
            this.optionPrice.id +
            "/" +
            !this.optionPrice.active
        );

        if (response.ok && response.status === 200) this.$emit("onComplete");
        this.activeLoading = false;
        this.isBusy = true;
      } catch (error) {
        this.activeLoading = false;
        this.isBusy = true;
      }
    },
  },
};
</script>
