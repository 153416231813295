<template>
  <div class="modal fade" id="mod-filter" role="dialog">
    <div
      class="modal-dialog m-0 p-0 absolute top-0 right-0 flex justify-end items-end"
    >
      <div
        class="modal-content rounded-none bg-[#fff] py-[40px] px-[20px] md:px-[32px] md:min-h-screen"
      >
        <div class="modal-header- flex items-start justify-between p-0 m-0">
          <h4
            class="font-semibold text-[18px] leading-[21.6px] capitalize text-[#353535] pb-[16px]"
          >
            Filter
          </h4>
          <button
            class="text-2xl text-[#353535] outline-none focus:outline-none"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body p-0 m-0">
          <div class="pb-[24px]">
            <label class="text=[#353535] font-medium text-[14px] leading-[20px]"
              >By Status</label
            >
            <div>
              <select
                class="bg-transparent border-[#d0d5dd] border-solid border py-[12px] px-[14px] rounded-[8px] w-full outline-none"
                v-model="filter.status"
              >
                <option value="nil" selected disabled
                  >Select Preferred Status</option
                >
                <option value="">All Distributors</option>
                <option value="True">Active</option>
                <option value="False">Disabled</option>
              </select>
            </div>
          </div>
          <div class="pb-[16px]">
            <label class="text=[#353535] font-medium text-[14px] leading-[20px]"
              >Name</label
            >
            <div>
              <input
                type="text"
                class="bg-transparent border-[#d0d5dd] border-solid border py-[12px] px-[14px] rounded-[8px] w-full outline-none"
                placeholder="Filter by name..."
                v-model="filter.name"
              />
            </div>
          </div>
          <div class="pb-[24px]">
            <label class="text=[#353535] font-medium text-[14px] leading-[20px]"
              >Code</label
            >
            <div>
              <input
                type="text"
                class="bg-transparent border-[#d0d5dd] border-solid border py-[12px] px-[14px] rounded-[8px] w-full outline-none"
                placeholder="Filter by code..."
                v-model="filter.code"
              />
            </div>
          </div>
          <div class="pb-[24px]">
            <label class="text=[#353535] font-medium text-[14px] leading-[20px]"
              >Source Id</label
            >
            <div>
              <input
                type="text"
                class="bg-transparent border-[#d0d5dd] border-solid border py-[12px] px-[14px] rounded-[8px] w-full outline-none"
                placeholder="Filter by source id..."
                v-model="filter.sourceId"
              />
            </div>
          </div>
          <div class="pb-[24px]">
            <label class="text=[#353535] font-medium text-[14px] leading-[20px]"
              >Group</label
            >
            <div>
              <select
                class="bg-transparent border-[#d0d5dd] border-solid border py-[12px] px-[14px] rounded-[8px] w-full outline-none"
                v-model="filter.group"
              >
                <option v-for="g in groups" :key="g.id" :value="g.id">{{
                  g.name
                }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer w-full m-0 p-0">
          <div class="flex items-center gap-[8px] w-full">
            <button
              class="bg-[#fcfcfd] border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[16px] sm:px-[24px] text-[14px] md:text-[16px] rounded-[8px] text-[#96A0B5] font-medium leading-[20px] w-full outline-none focus:outline-none"
              type="button"
              data-dismiss="modal"
              @click="reset"
            >
              Cancel
            </button>
            <!-- <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button> -->
            <button
              class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[16px] sm:px-[24px] text-[14px] md:text-[16px] rounded-[8px] text-white font-medium leading-[20px] w-full outline-none focus:outline-none"
              type="button"
              @click="getMerchants"
            >
              <span
                v-if="loadingMerchants"
                class="spinner-border spinner-border-sm"
                role="status"
              ></span>
              <span v-else>Apply Filter</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["filter", "groups", "reset", "getMerchants", "loadingMerchants"],
};
</script>
