<template>
  <div>
    <div class="table-responsive">
      <table class="table p-0 m-0">
        <thead class="bg-white">
          <tr class="py-[5px]">
            <th
              class="text-[12px] leading-[18px] text-[#000000] font-semibold w-[40%] md:w-[20%]"
            >
              Name
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Product Code
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Packaging Units
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Category
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Status
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Actions
            </th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="p in products" :key="p.id">
            <td class="text-[14px] font-normal leading-[16.94px]">
              <div class="d-flex align-items-center">
                <img
                  v-if="p.imageUrl"
                  class="image-preview mr-1"
                  :src="p.imageUrl"
                  alt="logo"
                  width="40"
                  height="40"
                />
                <img
                  v-else
                  class="image-preview mr-1"
                  src="/assets/img/avatar.svg"
                  alt="logo"
                  width="40"
                />
                <div>
                  {{ p.name }}
                </div>
              </div>
            </td>
            <td class="text-[14px] font-normal leading-[16.94px]">
              {{ p.code }}
            </td>
            <td class="text-[14px] font-normal leading-[16.94px]">
              {{ p.unit }}
            </td>
            <td class="text-[14px] font-normal leading-[16.94px]">
              {{ p.category.name }}
            </td>
            <td class="text-[14px] font-normal leading-[16.94px]">
              <span
                :class="[
                  'badge',
                  'badge-pill',
                  { 'badge-success': p.active },
                  { 'badge-danger': !p.active },
                ]"
              >
                <span
                  class="inline-block w-[10px] h-[10px] rounded-[1px]"
                  :style="{ backgroundColor: p.active ? '#16a249' : '#EF5253' }"
                ></span>
                <span class="text-[12px] font-normal">
                  {{ p.active ? "Active" : "Inactive" }}
                </span>
              </span>
            </td>
            <td @click="toggleDropdown(p.id)">
              <div class="dropdown">
                <button
                  class="btn-no-border"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span
                    class="text-[#98a2b3] dropdown text-2xl cursor-pointer relative outline-none active:outline-none shadow-none"
                  >
                    ...
                  </span>
                </button>
                <ul
                  class="dropdown-menu shadow-none"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li
                    v-if="hasPermission('Product_View')"
                    class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer border-b border-[#e5e7eb] py-[12px] px-[16px]"
                    @click="viewProduct(p)"
                  >
                    View
                  </li>
                  <li
                    class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer border-b border-[#e5e7eb] py-[12px] px-[16px]"
                    v-if="
                      p.parentId == user.parentId &&
                        hasPermission('Product_Update')
                    "
                    @click="emitViewEdit(p)"
                  >
                    Edit Product Info
                  </li>
                  <li
                    class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer border-b border-[#e5e7eb] py-[12px] px-[16px]"
                    v-if="
                      p.parentId == user.parentId &&
                        hasPermission('Price_Option_View')
                    "
                    @click="viewManagePrice(p)"
                  >
                    <span>{{
                      user.isSalesUser
                        ? "View Price Options"
                        : "Manage Price Option"
                    }}</span>
                  </li>
                  <li
                    class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer py-[12px] px-[16px]"
                  >
                    Delete
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr v-if="!products.length">
            <td colspan="7">
              <div class="text-center" style="padding-top: 50px">
                <span
                  style="font-size: 4.615rem"
                  class="mdi mdi-tag-multiple text-[#2463EB]"
                ></span>
              </div>
              <h3
                class="text-center pt-[24px] text-[16px] font-medium leading-[16.94px]"
                style="padding-bottom: 100px"
              >
                You Don't have any Products
              </h3>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination
      :limit="pageSize"
      :count="pageCount"
      :page="page"
      :itemsCount="count"
      @changePage="gotoPage"
      @changeLimit="changeLimit"
    />

    <!-- product popup -->
    <div
      v-if="isModalVisible"
      class="fixed inset-0 bg-[rgba(0,0,0,0.4)] z-[1111111] flex justify-end overflow-y-auto"
      id="popup-content"
      @click.self="closeModal"
    >
      <div
        class="relative w-full lg:w-[80%] max-h-screen bg-[#f8f8f8] px-[15px] py-[30px] md:p-[40px] overflow-auto"
      >
        <div class="shadow-none bg-transparent">
          <div class="flex items-start justify-between">
            <p class="text-[#353535] text-[20px] leading-[20px] font-semibold">
              Viewing {{ selectedProduct.name }}
            </p>
            <button
              class="text-2xl text-[#353535]"
              type="button"
              @click="closeModal"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div
            class="bg-white p-1 rounded-[4px] flex items-center gap-[16px] mt-[16px]"
          >
            <button
              :class="[
                'text-[14px] font-normal py-[6px] px-[24px] rounded-[8px] outline-none focus:outline-none',
                {
                  'bg-[#2463EB] text-white': activeTab === 'details',
                  'bg-[#f8f8f8] text-[#96a0b5]': activeTab !== 'details',
                },
              ]"
              @click="switchTab('details')"
            >
              Product Details
            </button>
            <button
              :class="[
                'text-[14px] font-normal py-[6px] px-[24px] rounded-[8px] outline-none focus:outline-none',
                {
                  'bg-[#2463EB] text-white': activeTab === 'option',
                  'bg-[#f8f8f8] text-[#96a0b5]': activeTab !== 'option',
                },
              ]"
              @click="switchTab('option')"
            >
              Manage Price Option
            </button>
          </div>

          <!--  -->
          <div v-if="activeTab === 'details'">
            <div
              class="bg-white px-[15px] py-[24px] sm:px-[24px] sm:py-[32px] mt-[16px] rounded-[4px]"
            >
              <div
                class="flex flex-col md:flex-row justify-between items-start gap-[5px] md:items-center"
              >
                <div class="flex items-center gap-[32px] flex-1">
                  <h2
                    class="font-semibold text-[20px] leading-[20px] text-[#353535] max-w-max w-full"
                  >
                    Product information
                  </h2>
                  <span
                    class="flex items-center gap-[4px] max-w-max w-full"
                    :class="[
                      'badge',
                      {
                        'badge-success': selectedProduct.active,
                        'badge-danger': !selectedProduct.active,
                      },
                    ]"
                  >
                    <span
                      class="w-[10px] h-[10px] rounded-[1px]"
                      :style="{
                        backgroundColor: selectedProduct.active
                          ? '#16a249'
                          : '#EF5253',
                      }"
                    ></span>
                    <span class="text-[12px] font-normal">
                      {{ selectedProduct.active ? "Active" : "Inactive" }}
                    </span>
                  </span>
                </div>
                <button
                  class="border-[1px] border-[#96a0b5] rounded-[8px] text-[#96a0b5] font-normal border-solid py-[12px] px-[16px] sm:px-[24px] text-[12px] md:text-[14px] py-[12px] flex items-center gap-[5px] outline-none focus:outline-none"
                  @click="editSelectedProduct()"
                >
                  <span><img src="/assets/icon/edit-2.svg" alt=""/></span>
                  Edit
                </button>
              </div>
              <div
                class="flex flex-col md:flex-row mt-[16px] md:mt-0 gap-[16px] md:gap-[32px] w-full"
              >
                <div>
                  <img
                    v-if="selectedProduct.imageUrl"
                    class="image-preview mr-1"
                    :src="selectedProduct.imageUrl"
                    alt="logo"
                    width="80"
                    height="80"
                  />
                </div>
                <div class="w-full">
                  <div
                    class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2 max-w-[280px] w-full"
                  >
                    <h2 class="font-normal text-[16px] text-[#96a0b5] w-full">
                      Product Name
                    </h2>
                    <span
                      class="font-medium text-[16px] text-[#353535] leadin-[19.36px] w-full"
                      >{{ selectedProduct.name }}</span
                    >
                  </div>
                  <div
                    class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2 max-w-[280px] w-full"
                  >
                    <h2 class="font-normal text-[16px] text-[#96a0b5] w-full">
                      Product Code
                    </h2>
                    <span
                      class="font-medium text-[16px] text-[#353535] leadin-[19.36px] w-full"
                      >{{ selectedProduct.code }}</span
                    >
                  </div>
                  <div
                    class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2 max-w-[280px] w-full"
                  >
                    <h2 class="font-normal text-[16px] text-[#96a0b5] w-full">
                      Packaging Unit
                    </h2>
                    <span
                      class="font-medium text-[16px] text-[#353535] leadin-[19.36px] w-full"
                      >{{ selectedProduct.unit }}</span
                    >
                  </div>
                  <div
                    class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2 max-w-[280px] w-full"
                  >
                    <h2 class="font-normal text-[16px] text-[#96a0b5] w-full">
                      Category
                    </h2>
                    <span
                      class="font-medium text-[16px] text-[#353535] leadin-[19.36px] w-full"
                      >{{ selectedProduct.category.name }}</span
                    >
                  </div>
                  <div
                    class="flex flex-col md:flex-row items-start gap-2 md:items-center md:gap-4 mt-2 max-w-[280px] w-full"
                  >
                    <h2 class="font-normal text-[16px] text-[#96a0b5] w-full">
                      Description
                    </h2>
                    <span
                      class="font-medium text-[16px] text-[#353535] leadin-[19.36px] w-full"
                      >{{ selectedProduct.description }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--  -->
          <div v-if="activeTab === 'option'">
            <PriceOption :productId="selectedProduct.id" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "../Pagination.vue";
import PriceOption from "./PriceOption.vue";
import SystemPermissions from "@/permissions";
export default {
  components: {
    Pagination,
    PriceOption,
    SystemPermissions,
  },
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    pageSize: Number,
    pageCount: Number,
    page: Number,
    count: Number,
    user: Object,
    hasPermission: Function,
  },
  data() {
    return {
      showDropdown: null,
      isModalVisible: false,
      selectedProduct: {},
      name: "",
      active: "",
      activeTab: "details",
    };
  },
  methods: {
    switchTab(tab) {
      this.activeTab = tab;
    },
    async viewProduct(product) {
      this.selectedProduct = product;
      this.active = this.selectedProduct.active;
      this.name = this.selectedProduct.name;
      this.openModal();
    },
    viewManagePrice(product) {
      this.selectedProduct = product;
      this.active = this.selectedProduct.active;
      this.name = this.selectedProduct.name;
      this.activeTab = "option";
      this.openModal();
    },
    closeModal() {
      this.isModalVisible = false;
      document.body.style.overflow = "auto";
      this.activeTab = "details";
    },
    openModal() {
      this.isModalVisible = true;
      document.body.style.overflow = "hidden";
    },
    gotoPage(page) {
      this.$emit("gotoPage", page);
    },
    changeLimit(limit) {
      this.$emit("changeLimit", limit);
    },
    toggleDropdown(userId) {
      this.showDropdown = this.showDropdown === userId ? null : userId;
    },
    emitViewEdit(product) {
      this.$emit("viewEdit", product);
    },
    editSelectedProduct() {
      this.emitViewEdit(this.selectedProduct);
      this.closeModal();
    },
  },
};
</script>
<style>
.btn-no-border:focus,
.btn-no-border:active,
.btn-no-border:focus-visible {
  outline: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
}
</style>
