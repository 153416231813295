<template>
  <div>
    <div
      class="fixed inset-0 bg-[rgba(0,0,0,0.4)] z-[1111111] flex justify-end overflow-y-auto"
      id="popup-content"
      @click.self="closeModal"
    >
      <div
        class="relative w-full lg:w-[80%] max-h-screen bg-[#f8f8f8] p-[15px] md:p-[40px] overflow-auto"
      >
        <div
          class="shadow-none bg-transparent mb-[16px] flex items-center justify-between"
        >
          <p class="text-[#353535] text-[20px] leading-[20px] font-semibold">
            Product Bulk Upload
          </p>
          <button
            class="text-2xl cursor-pointer text-[#353535] z-10"
            type="button"
            @click="closeModal"
          >
            <span class="mdi text-xl mdi-close"></span>
          </button>
        </div>
        <div
          class="mt[16px] flex flex-col md:flex-row items-start md:items-center justify-between"
        >
          <div class="">
            <label for="file_input">
              <input
                class="hidden"
                id="file_input"
                type="file"
                ref="fileUpload"
                @change="onFileExcelChange"
              />
              <div
                class="flex gap-[5px] items-center w-full bg-transparent max-w-md"
              >
                <span ref="fileUpload" class="text-[14px] font-medium">
                  No File Chosen
                </span>
              </div>
            </label>
          </div>
          <a
            class="border-[1px] bg-[#2463eb] rounded-[8px] text-white font-normal text-[14px] border-solid px-[16px] md:px-[24px] py-[12px] flex items-center gap-[5px]"
            download="Sample-Product-Upload-Template-Enterprise.xlsx"
            href="/assets/templates/Sample-Product-Upload-Template-Enterprise.xlsx"
          >
            <span>
              <img src="/assets/icon/document-upload.svg" alt="" />
            </span>
            <span>Download Template</span>
          </a>
        </div>
        <div
          class="bg-white mt-[16px] rounded-[4px] border-[#DBDCE0] border-[1.5px] border-solid"
        >
          <div class="table-responsive">
            <table class="table p-0 m-0">
              <thead class="bg-white">
                <tr>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Category Name
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Product Code
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Name
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Description
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Product Unit
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Default Distributor Price
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Default Retail Price
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Active (Y/N)
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Price Override (Y/N)
                  </th>
                  <th
                    class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(l, index) in productUpload" :key="l.id">
                  <td>
                    <span v-if="l.category_Name_Error" class="text-danger">
                      <i class="feather icon-alert-triangle"></i> Invalid
                    </span>
                    <span v-else> {{ l.category_Name }} </span>
                  </td>
                  <td>
                    <span v-if="l.product_Code_Error" class="text-danger">
                      <i class="feather icon-alert-triangle"></i> Invalid
                    </span>
                    <span v-else> {{ l.product_Code }} </span>
                  </td>
                  <td>
                    <span v-if="l.product_Name_Error" class="text-danger">
                      <i class="feather icon-alert-triangle"></i> Invalid
                    </span>
                    <span v-else> {{ l.product_Name }} </span>
                  </td>
                  <td>
                    <span
                      v-if="l.product_Description_Error"
                      class="text-danger"
                    >
                      <i class="feather icon-alert-triangle"></i> Invalid
                    </span>
                    <span v-else> {{ l.product_Description }} </span>
                  </td>
                  <td>
                    <span v-if="l.product_Unit_Error" class="text-danger">
                      <i class="feather icon-alert-triangle"></i> Invalid
                    </span>
                    <span v-else> {{ l.product_Unit }} </span>
                  </td>
                  <td>
                    <span
                      v-if="l.default_Distributor_Price_Error"
                      class="text-danger"
                    >
                      <i class="feather icon-alert-triangle"></i> Invalid
                    </span>
                    <span v-else> {{ l.default_Distributor_Price }} </span>
                  </td>
                  <td>
                    <span
                      v-if="l.default_Retail_Price_Error"
                      class="text-danger"
                    >
                      <i class="feather icon-alert-triangle"></i> Invalid
                    </span>
                    <span v-else> {{ l.default_Retail_Price }} </span>
                  </td>
                  <td>
                    <span v-if="l.active_Error" class="text-danger">
                      <i class="feather icon-alert-triangle"></i> Invalid
                    </span>
                    <span v-else> {{ l.activeCond }} </span>
                  </td>
                  <td>
                    <span v-if="l.price_Override_Error" class="text-danger">
                      <i class="feather icon-alert-triangle"></i> Invalid
                    </span>
                    <span v-else> {{ l.price_Override_Cond }} </span>
                  </td>
                  <td class="text-danger">
                    <i v-if="l.errors" class="feather icon-alert-triangle"></i
                    >&nbsp;{{ l.errors }}
                  </td>
                  <td>
                    <div class="d-flex">
                      <button
                        class="btn btn-success btn-sm btn-rounded btn-space"
                        @click.prevent="viewEdit(l)"
                      >
                        <i class="feather icon-edit"></i> Edit</button
                      >&nbsp;
                      <button
                        class="btn btn-danger btn-sm btn-rounded btn-space"
                        @click.prevent="onRemoveClick(index)"
                      >
                        <i class="feather icon-delete"></i> Delete
                      </button>
                    </div>
                  </td>
                </tr>
                <tr v-if="!productUpload.length">
                  <td colspan="12">
                    <div
                      class="text-center flex flex-col gap-[16px] items-center justify-center"
                      style="padding-top: 100px; padding-bottom: 100px"
                    >
                      <div>
                        <img src="/assets/img/document-upload.svg" alt="" />
                        <p
                          class="text-[14px] font-medium leading-[17px] text-center"
                        >
                          kindly upload a file
                        </p>
                      </div>
                      <label for="file_input">
                        <input
                          class="hidden"
                          id="file_input"
                          type="file"
                          @change="onFileExcelChange"
                        />
                        <div
                          class="flex gap-[5px] items-center w-full bg-transparent max-w-md"
                        >
                          <div
                            class="border-[#96A0B5] border-[1.5px] border-solid rounded-[8px] px-[24px] py-[12px] cursor-pointer"
                          >
                            <span
                              class="text-[14px] font-medium text-[#96A0B5] leading-[17px]"
                              >Chose file to upload</span
                            >
                          </div>
                        </div>
                      </label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <button
          class="my-[16px] bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] outline-none focus:outline-none"
          type="button"
          @click.prevent="onUploadClick"
        >
          Post
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import datePicker from "vue-bootstrap-datetimepicker";
import { helper } from "@/helper";
import Select2 from "../Select2.vue";
import SystemPermissions from "@/permissions";
import UploadCaution from "../UploadCaution";
import XLSX from "xlsx";

export default {
  mixins: [SystemPermissions.Mixin],

  name: "productsUpload",
  components: {
    Select2,
    UploadCaution,
    datePicker,
  },
  props: {
    isModalVisible: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    if (!this.hasPermission("Product_Create")) {
      this.$router.push("/restricted");
    }
    this.getGroup();
    this.getTypes();
    this.getCategories();
    this.getUnits();
    this.originalProducts = this.Products;
  },

  updated() {
    // if (this.$route.params.id && this.Products) {
    //   const product = this.Products.find(
    //     (prod) => prod.id === this.$route.params.id
    //   );
    //   this.viewEdit(product);
    // }
  },

  watch: {},

  data() {
    return {
      options: {
        format: "YYYY-MM-DD",
        useCurrent: false,
      },
      isBusy: false,
      isBusyModal: false,
      //   isModalVisible: false,
      createProduct: {
        manufacturer_product_Code: null,
        distributor_product_Code: null,
        product_Name: null,
        product_Description: null,
        retail_price: null,
        minimum_stock_level: 0,
      },
      updateProduct: {
        category_Name: null,
        product_Code: null,
        product_Name: null,
        product_Description: null,
        product_Unit: null,
        default_Distributor_Price: 0,
        default_Retail_Price: 0,
        active: null,
        price_Override: null,
        activeCond: null,
        price_Override_Cond: null,
      },
      v1: {
        manufacturer_product_Code: false,
        distributor_product_Code: false,
        product_Name: false,
        retail_price: false,
        minimum_stock_level: false,
        is_valid: true,
      },
      v2: {
        is_valid: true,
        category_Name: false,
        product_Code: false,
        product_Name: false,
        product_Description: false,
        product_Unit: false,
        default_Distributor_Price: false,
        default_Retail_Price: false,
        active: false,
        price_Override: false,
      },
      Products: [],
      editProduct: {},
      originalProducts: [],
      manufacturerProducts: [],
      types: [],
      categories: [],
      units: [],
      manufacturerPrice: null,
      manufacturerCategory: null,
      manufacturerUnit: null,
      allowPriceOverride: true,
      productUpload: [],
      errorCount: 0,
      modalText: "",
      action: "",
      removeIndex: 0,
      isProductExist: false,
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  methods: {
    openModal() {
      this.$emit("update:isModalVisible", true);
    },
    closeModal() {
      this.$emit("update:isModalVisible", false);
    },

    // closeModal() {
    //   this.isModalVisible = false;
    //   document.body.style.overflow = "auto";
    // },
    closeModal() {
      this.$emit("close");
    },
    // openModal() {
    //   this.isModalVisible = true;
    //   document.body.style.overflow = "hidden";
    // },
    async getGroup() {
      this.isBusy = true;
      try {
        const response = await this.$http.get(`/Groups/getGroup/`);
        if (response.ok) {
          this.groupId = response.body;
          this.getManufacturerProducts();
        }

        this.isBusy = true;
      } catch (error) {
        this.isBusy = false;
      }
    },

    async getPrice(code, isEdit) {
      var id = this.getManufacturerProductIdByCode(code);
      this.isBusy = true;
      try {
        if (this.groupId) {
          const response = await this.$http.get(
            `/Groups/price/${this.groupId}/${id}`
          );

          if (response.status === 200) {
            this.prepareProduct(id, response.body, isEdit);
          }
        } else {
          this.prepareProduct(
            id,
            { distributorPrice: 0, retailPrice: 0 },
            isEdit
          );
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    prepareProduct(id, price, isEdit) {
      const product = this.manufacturerProducts.find((pr) => pr.id === id);

      this.manufacturerCategory = product.category.name;
      this.manufacturerUnit = product.unit;
      this.allowPriceOverride = !product.allowPriceOverride;
      this.manufacturerPrice =
        price.distributorPrice > 0
          ? price.distributorPrice
          : product.distributorPrice;
      if (isEdit) {
        this.updateProduct.retail_price =
          price.retailPrice > 0 ? price.retailPrice : product.retailPrice;
      } else {
        this.createProduct.retail_price =
          price.retailPrice > 0 ? price.retailPrice : product.retailPrice;
      }
    },

    onFileExcelChange(e) {
      this.errorCount = 0;
      const file = e.target.files[0];

      var reader = new FileReader(),
        self = this;
      reader.onload = (event) => {
        let arrayData = [];
        //parse data
        var bstr = event.target.result;
        const workBook = XLSX.read(bstr, { type: "binary" });
        //get first sheet
        const workSheetName = workBook.SheetNames[0];
        const workSheet = workBook.Sheets[workSheetName];
        //convert array
        const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
        const headers = fileData[0];
        fileData.splice(0, 1);

        for (var i = 0; i < fileData.length; i++) {
          if (fileData[i].length !== 0) {
            let objectItem = {
              category_Name: fileData[i][0].toLowerCase(),
              category_Name_Error: false,
              product_Code: fileData[i][1],
              product_Code_Error: false,
              product_Name: fileData[i][2],
              product_Name_Error: false,
              product_Description: fileData[i][3],
              product_Description_Error: false,
              product_Unit: fileData[i][4].toLowerCase(),
              product_Unit_Error: false,
              default_Distributor_Price: fileData[i][5],
              default_Distributor_Price_Error: false,
              default_Retail_Price: fileData[i][6],
              default_Retail_Price_Error: false,
              active: fileData[i][7],
              active_Error: false,
              price_Override: fileData[i][8],
              price_Override_Error: false,
            };

            arrayData.push(objectItem);
          }
        }

        self.productUpload = arrayData;

        for (var i = 0; i < self.productUpload.length; i++) {
          var errors = "";
          var product = self.productUpload[i];

          let checkCategory = self.categories.find(
            (x) => x.name.toLowerCase() === product.category_Name.toLowerCase()
          );

          if (self.validateProductCode(product.product_Code) === true) {
            errors = errors + "|Product code already exist";
            product.product_Code_Error = true;
          }
          if (!product.category_Name) {
            errors = errors + "|Invalid category name";
            self.v2.category_Name = true;
            product.category_Name_Error = true;
          } else if (checkCategory === null || checkCategory === undefined) {
            self.v2.category_Name = true;
            errors = errors + "|Product category not found";
            product.category_Name_Error = true;
          } else {
            self.productUpload[i].categoryId = checkCategory.id;
          }
          if (!product.product_Code) {
            errors = errors + "|Invalid product code";
            self.v2.product_Code = true;
            product.product_Code_Error = true;
          }

          if (!product.product_Name || product.product_Name.length < 2) {
            errors = errors + "|Invalid product name";
            self.v2.product_Name = true;
            product.product_Name_Error = true;
          }

          if (!product.product_Unit) {
            errors = errors + "|Invalid product unit";
            self.v2.product_Unit = true;
            product.product_Unit_Error = true;
          } else {
            let checkUnit = self.units.find(
              (x) => x.name.toLowerCase() === product.product_Unit.toLowerCase()
            );
            if (checkUnit === null || checkUnit === undefined) {
              errors = errors + "|Product unit not found";
              self.v2.product_Unit = true;
              product.product_Unit_Error = true;
            }
          }
          if (!product.default_Distributor_Price) {
            errors = errors + "|Invalid default distributor price";
            self.v2.default_Distributor_Price = true;
            product.default_Distributor_Price_Error = true;
          }

          if (!product.default_Retail_Price) {
            errors = errors + "|Invalid default distributor price";
            self.v2.default_Retail_Price = true;
            product.default_Retail_Price_Error = true;
          }

          if (
            !product.active &&
            (product.active !== "Y" || product.active !== "N")
          ) {
            errors =
              errors +
              "|Invalid product active status, input Y for yes and N for No";
            product.active_Error = true;
          } else if (product.active === "Y") {
            self.productUpload[i].active = true;
            self.productUpload[i].activeCond = "Y";
          } else {
            self.productUpload[i].active = false;
            self.productUpload[i].activeCond = "N";
          }
          if (
            !product.price_Override &&
            (product.price_Override !== "Y" || product.price_Override !== "N")
          ) {
            errors =
              errors +
              "|Invalid price override status, input Y for yes and N for No";
            product.price_Override_Error = true;
          } else if (product.price_Override === "Y") {
            self.productUpload[i].price_Override = true;
            self.productUpload[i].price_Override_Cond = "Y";
          } else {
            self.productUpload[i].price_Override = false;
            self.productUpload[i].price_Override_Cond = "N";
          }

          if (
            isNaN(product.default_Distributor_Price) &&
            Number(product.default_Distributor_Price) <= 0
          ) {
            errors =
              errors +
              "|Default distributor price must be a number greater than 0";
            product.default_Distributor_Price_Error = true;
          }

          if (
            isNaN(product.default_Retail_Price) &&
            Number(product.default_Retail_Price)
          ) {
            errors =
              errors + "|Default retail price must be a number greater than 0";
            product.default_Retail_Price_Error = true;
          }

          if (!errors) {
            product.product_Name = product.product_Name.trim();
            // product.product_Code =
            //   product.product_Code.trim();
            product.product_Description = product.product_Description.trim();
          } else self.errorCount++;

          self.productUpload[i].errors = errors;
        }
      };

      reader.readAsBinaryString(file);
    },

    // onFileChange(e) {
    //   this.errorCount = 0;
    //   var files = e.target.files || e.dataTransfer.files;
    //   if (!files.length) return;
    //   var file = files[0];

    //   let self = this;

    //   this.$papa.parse(file, {
    //     header: true,
    //     download: true,
    //     dynamicTyping: true,
    //     skipEmptyLines: true,
    //     complete: function (results) {
    //       self.productUpload = results.data;

    //       for (var i = 0; i < self.productUpload.length; i++) {
    //         var errors = "";
    //         var product = self.productUpload[i];

    //         let checkCategory = self.categories.find((x) => x.name.toLowerCase() === product.category_Name.toLowerCase());

    //         if(self.validateProductCode(product.product_Code) === true){
    //           errors = errors + "|Product code already exist"
    //         }
    //         if(!product.category_Name){
    //           errors = errors + "|Invalid category name";
    //         }else if(checkCategory === null || checkCategory === undefined){
    //             errors = errors + "|Product category not found";
    //         }else{
    //           self.productUpload[i].categoryId = checkCategory.id;
    //         }
    //         if (!product.product_Code) {
    //           errors = errors + "|Invalid product code";
    //         }

    //         if (!product.product_Name  || product.product_Name.length < 2) {
    //           errors = errors + "|Invalid product name";
    //         }

    //         if (!product.product_Unit) {
    //           errors = errors + "|Invalid product unit";
    //         }else{
    //           let checkUnit = self.units.find((x) => x.name.toLowerCase() === product.product_Unit.toLowerCase());
    //           if(checkUnit === null || checkUnit === undefined){
    //             errors = errors + "|Product unit not found";
    //           }
    //         }
    //         if (!product.default_Distributor_Price) {
    //           errors = errors + "|Invalid default distributor price";
    //         }

    //         if (!product.default_Retail_Price) {
    //           errors = errors + "|Invalid default distributor price";
    //         }

    //         if (!product.active && (product.active !== "Y" || product.active !== "N")) {
    //           errors = errors + "|Invalid product active status, input Y for yes and N for No";
    //         }else if(product.active === "Y"){
    //           self.productUpload[i].active = true;
    //           self.productUpload[i].activeCond = "Y";
    //         }else{
    //           self.productUpload[i].active = false;
    //           self.productUpload[i].activeCond = "N";
    //         }
    //         if (!product.price_Override && (product.price_Override !== "Y" || product.price_Override !== "N")) {
    //           errors = errors + "|Invalid price override status, input Y for yes and N for No";
    //         }else if(product.price_Override === "Y"){
    //           self.productUpload[i].price_Override = true;
    //           self.productUpload[i].price_Override_Cond = "Y";
    //         }else{
    //           self.productUpload[i].price_Override = false;
    //           self.productUpload[i].price_Override_Cond = "N";
    //         }

    //         if (isNaN(product.default_Distributor_Price) && Number(product.default_Distributor_Price) <= 0) {
    //           errors = errors + "|Default distributor price must be a number greater than 0";
    //         }

    //         if (isNaN(product.default_Retail_Price) && Number(product.default_Retail_Price)) {
    //           errors =
    //             errors + "|Default retail price must be a number greater than 0";
    //         }

    //         if (!errors) {
    //           product.product_Name = product.product_Name.trim();
    //           product.product_Code =
    //             product.product_Code.trim();
    //           product.product_Description = product.product_Description.trim();
    //         } else self.errorCount++;

    //         self.productUpload[i].errors = errors;
    //       }
    //     },
    //   });
    // },

    reset() {
      this.errorCount = 0;
      this.productUpload.splice(0);
      this.$refs.fileUpload.value = null;
    },
    cancel() {
      const currentProduct = this.Products.find(
        (p) => p.id === this.editProduct.id
      );
      this.allowPriceOverride = true;

      currentProduct.category_Name = this.editProduct.category_Name;
      currentProduct.product_Code = this.editProduct.product_Code;
      currentProduct.product_Name = this.editProduct.product_Name;
      currentProduct.product_Description = this.editProduct.product_Description;
      currentProduct.product_Unit = this.editProduct.product_Unit;
      currentProduct.default_Distributor_Price = this.editProduct.default_Distributor_Price;
      currentProduct.default_Retail_Price = this.editProduct.default_Retail_Price;
      currentProduct.active = this.editProduct.active;
      currentProduct.price_Override = this.editProduct.price_Override;
      $("#mod-edit").modal("hide");
    },

    viewEdit(c) {
      // var manufacturerProductId = this.getManufacturerProductIdByCode(
      //   c.manufacturer_product_Code
      // );

      var editValues = {
        category_Name: c.category_Name,
        product_Code: c.product_Code,
        product_Name: c.product_Name,
        product_Description: c.product_Description,
        product_Unit: c.product_Unit,
        default_Distributor_Price: c.default_Distributor_Price
          ? c.default_Distributor_Price
          : 0,
        default_Retail_Price: c.default_Retail_Price
          ? c.default_Retail_Price
          : 0,
        active: c.active,
        price_Override: c.price_Override,
      };
      this.editProduct = editValues;
      this.updateProduct = c;
      $("#mod-edit").modal("show");
    },
    viewUploadExpenses() {
      this.$router.push("/manage-expenses/upload");
    },

    onRemoveClick(index) {
      this.modalText = "Are you sure you want to remove this item?";
      this.action = "remove";
      this.removeIndex = index;
      $("#mod-expense-caution").modal("show");
    },

    remove() {
      var expense = this.productUpload[this.removeIndex];
      if (expense.errors) {
        this.errorCount--;
      }
      this.productUpload.splice(this.removeIndex, 1);
      this.removeIndex = 0;
      $("#mod-expense-caution").modal("hide");
    },

    onUploadClick() {
      this.modalText = "Are you sure?";
      this.action = "upload";
      $("#mod-expense-caution").modal("show");
    },

    async uploadProducts() {
      $("#mod-expense-caution").modal("hide");
      this.isBusyModal = true;
      this.isBusy = true;

      let productUploadRequest = this.productUpload;

      if (productUploadRequest.length > 0) {
        productUploadRequest.forEach((item) => {
          delete item.activeCond;
          delete item.price_Override_Cond;
          delete item.errors;
          delete item.category_Name;
          item.volume = "string";
          item.group = "string";
          item.groupSize = 0;
          item.quantity = 0;
          item.type = "string";
          item.restockLevel = 0;
        });
      }

      var data = {
        parentId: this.user.parentId,
        products: productUploadRequest,
      };

      await this.$http
        .post("/products/CreateManufacturerProductBulk", data)
        .then(function(res) {
          this.$router.push("/manufacturer-products");
        })
        .catch(function() {
          this.isBusyModal = false;
          this.isBusy = false;
        });
    },

    async create() {
      this.isBusyModal = true;
      this.isBusy = true;

      if (this.validateCreate()) {
        this.productUpload.push(this.createProduct);
        $("#mod-add").modal("hide");
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },
    validateCreate() {
      this.v1 = {
        manufacturer_product_Code: false,
        distributor_product_Code: false,
        product_Name: false,
        retail_price: false,
        minimum_stock_level: false,
      };
      var isValid = true;

      if (!this.createProduct.manufacturer_product_Code) {
        this.v1.manufacturer_product_Code = true;
        isValid = false;
      }

      if (!this.createProduct.distributor_product_Code) {
        this.v1.distributor_product_Code = true;
        isValid = false;
      }

      if (!this.createProduct.product_Name) {
        this.v1.product_Name = true;
        isValid = false;
      }

      if (Number(this.createProduct.retail_price) <= 0) {
        this.v1.retail_price = true;
        isValid = false;
      }

      if (Number(this.createProduct.minimum_stock_level) < 0) {
        this.v1.restockLevel = true;
        isValid = false;
      }

      return isValid;
    },

    async update() {
      this.isBusyModal = true;
      this.isBusy = true;
      if (this.validateUpdate()) {
        // var productName = this.getManufacturerProductNameByCode(
        //   this.updateProduct.manufacturer_product_Code
        // );
        // this.updateProduct.manufacturer_product_Name = productName;
        if (this.updateProduct.errors) {
          this.updateProduct.errors = "";
          this.errorCount--;
        }
        $("#mod-edit").modal("hide");
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },

    validateUpdate() {
      this.v2 = {
        manufacturer_product_Code: false,
        distributor_product_Code: false,
        product_Name: false,
        retail_price: false,
        minimum_stock_level: false,

        category_Name: false,
        product_Code: false,
        product_Name: false,
        product_Description: false,
        product_Unit: false,
        default_Distributor_Price: false,
        default_Retail_Price: false,
        active: false,
        price_Override: false,

        is_valid: true,
      };
      var isValid = true;
      let checkCategory = this.categories.find(
        (x) =>
          x.name.toLowerCase() ===
          this.updateProduct.category_Name.toLowerCase()
      );
      let checkUnit = this.units.find(
        (x) =>
          x.name.toLowerCase() === this.updateProduct.product_Unit.toLowerCase()
      );

      if (this.validateProductCode(this.updateProduct.product_Code) === true) {
        this.v2.product_Code = true;
        isValid = false;
      }
      if (!this.updateProduct.category_Name) {
        this.v2.category_Name = true;
        isValid = false;
      } else if (checkCategory === null || checkCategory === undefined) {
        this.v2.category_Name = true;
        isValid = false;
      } else {
        this.updateProduct.category_Name_Error = false;
      }

      if (!this.updateProduct.product_Code) {
        this.v2.product_Code = true;
        isValid = false;
      } else {
        this.updateProduct.product_Code_Error = false;
      }

      if (!this.updateProduct.product_Name) {
        this.v2.product_Name = true;
        isValid = false;
      } else {
        this.updateProduct.product_Name_Error = false;
      }

      if (!this.updateProduct.product_Unit) {
        this.v2.product_Unit = true;
        isValid = false;
      } else if (checkUnit === null || checkUnit === undefined) {
        this.v2.product_Unit = true;
        isValid = false;
      } else {
        this.updateProduct.product_Unit_Error = false;
      }

      if (
        !this.updateProduct.activeCond &&
        (this.updateProduct.activeCond !== "Y" ||
          this.updateProduct.activeCond !== "N")
      ) {
        this.v2.active = true;
        isValid = false;
      } else if (this.updateProduct.activeCond === "Y") {
        this.updateProduct.active = true;
        this.updateProduct.activeCond = "Y";
        this.updateProduct.active_Error = false;
      } else {
        this.updateProduct.active = false;
        this.updateProduct.activeCond = "N";
        this.updateProduct.active_Error = false;
      }

      if (
        !this.updateProduct.price_Override_Cond &&
        (this.updateProduct.price_Override_Cond !== "Y" ||
          this.updateProduct.price_Override_Cond !== "N")
      ) {
        this.v2.price_Override = true;
        isValid = false;
      } else if (this.updateProduct.price_Override_Cond === "Y") {
        this.updateProduct.price_Override = true;
        this.updateProduct.price_Override_Cond = "Y";
        this.updateProduct.price_Override_Error = false;
      } else {
        this.updateProduct.price_Override = false;
        this.updateProduct.price_Override_Cond = "N";
        this.updateProduct.price_Override_Error = false;
      }

      if (Number(this.updateProduct.default_Distributor_Price) <= 0) {
        this.v2.default_Distributor_Price = true;
        isValid = false;
      } else {
        this.updateProduct.default_Distributor_Price_Error = false;
      }

      if (Number(this.updateProduct.default_Retail_Price) <= 0) {
        this.v2.default_Retail_Price = true;
        isValid = false;
      } else {
        this.updateProduct.default_Retail_Price_Error = false;
      }

      return isValid;
    },

    async getManufacturerProducts() {
      this.isBusy = true;

      await this.$http
        .get(
          "/Products/getListNoPagingForManufacturer?active=true" +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "") +
            (this.groupId ? "&groupId=" + this.groupId : "")
        )
        .then(function(res) {
          this.manufacturerProducts = res.body;
          this.isBusy = false;
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },

    getTypes() {
      this.types = ["Finite", "Infinite"];
    },

    getCategories() {
      var t = "Product Category";
      var a = true;

      this.$http
        .get(
          "/Categories/getListNoPagging?type=" +
            t +
            "&active=" +
            a +
            (this.user.parentId
              ? "&parentId=" + this.user.parentId
              : "Product Category")
        )
        .then(function(res) {
          this.categories = res.body;
        })
        .catch(function() {});
    },
    getUnits() {
      var t = "Product Unit";
      var a = true;

      this.$http
        .get(
          "/Categories/getListNoPagging?type=" +
            t +
            "&active=" +
            a +
            (this.user.parentId
              ? "&parentId=" + this.user.parentId
              : "Product Unit")
        )
        .then(function(res) {
          this.units = res.body;
        })
        .catch(function() {});
    },

    async validateProductCode(code) {
      await this.$http
        .get("/Products/isProductCodeExist/" + code)
        .then(function(res) {
          this.isProductExist = res.body;
          this.isBusy = false;
          return res.body;
        })
        .catch(function(res) {
          return res.body;
        });
    },

    getManufacturerProductNameByCode(code) {
      var product = this.manufacturerProducts.find((x) => x.code === code);
      if (product == null || product == undefined) return "";
      else return product.name + " (" + code + ")";
    },

    getManufacturerProductNameById(id) {
      var product = this.manufacturerProducts.find((x) => x.id === id);
      if (product == null || product == undefined) return "";
      else return product.name + " (" + product.code + ")";
    },

    getManufacturerProductIdByCode(code) {
      var product = this.manufacturerProducts.find((x) => x.code === code);
      if (product == null || product == undefined) return "";
      else return product.id;
    },

    formatDate(date) {
      if (date) return moment(date).format("lll");
      else return "";
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },
  },
};
</script>
