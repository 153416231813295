<template>
  <div
    class="p-2 p-sm-1 w-100 border-bottom bg-white"
  >
    <div class="row">
        <div class="col-12 col-md-9" :class="statusBackgroundSelector">
          <div class="d-flex flex-row">
              <div class="m-2">
                  <input v-if="notification.isRead === false" type="checkbox" :checked="notification.isChecked" :value="notification.id" @change="checkNotification(notification.id)"/>
                  <span v-else></span>
              </div>
            <div class="notif-hover d-inline" @click="submitRead">
                <p class="title-text font-weight-bold">{{notification.title}}</p>
                <p class="not-body" v-html="returnSpan()"/> 
            </div>
          </div>
              
            <button v-if="notification.ctaStatus === 'Available'" :class="notification.isRead === true ? 'btn btn-sm btn-primary ml-3 mt-1' : 'btn btn-sm btn-primary ml-5 mt-1'" @click.prevent="goToUrl">{{notification.ctaLabel}}</button>
        </div>
        <div class="col-12 col-md-3 text-right">
            <button class="mb-1 btn btn-sm btn-text" @click.prevent="deleteNotification(notification.id)">
                Delete
                <span class="mdi mdi-delete"></span>
            </button>
            <p class="title-text mr-1">{{formatDate(notification.date)}}</p>
            <p class="mr-1 not-body">{{days}}</p>
        </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["notification"],

  computed: {
    days() {
      const days = moment(new Date()).diff(this.notification.date, "days");
      return days > 0 ? `${days} day${days > 1 ? "s" : ""} ago` : "Today";
    },

    backgroundSelector() {
      let background = "bg-success";
      if (this.notification.title === "Minimum Stock Level")
        background = "bg-warning";

      if (this.notification.title === "Inventory Product Expire.")
        background = "bg-danger";
      return background;
    },

    statusBackgroundSelector() {
      let background = "text-dark";
      if (this.notification.isRead) background = "text-secondary";
      return background;
    },
  },

  methods: {
    returnSpan() {
        let secondaryArr = this.notification.secondary ? this.notification.secondary.split("*") : [];

        let newStatementRep = "";

        newStatementRep = this.notification.statement.replace("[FocalPoint]", `<span class='font-weight-bold text-primary'> ${this.notification.focalPoint} </span>`);

        for(let i = 0; i<secondaryArr.length; i++){
            newStatementRep = newStatementRep.replace(`[${i}]`, `<span class='font-weight-bold'> ${secondaryArr[i]} </span>`);
        }
        return `<span> ${newStatementRep} </span>`
    },
    async deleteNotification(id) {
      try {
        this.isBusy = true;
        const response = await this.$http.delete("/notification/" + id);
        if (response.ok) this.$emit("onDeleteComplete");
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    formatDate(date) {
      if (date) return moment(date).format("lll");
      else return "";
    },

    async submitRead() {
      if (!this.notification.isRead)
        this.$emit("onCardClicked", this.notification.id);
    },

    goToUrl(){
        if(this.notification.url !== ""){
            this.$router.push(`${this.notification.url}`)
        }
    },

    checkNotification(){
        this.$emit("checkMessage", this.notification.id);
    }
  },
};
</script>

<style>
.dropdown-list-image {
  display: flex;
  width: 2.8rem;
  height: 2.8rem;
  align-items: center;
  justify-content: center;
}

.action {
  width: 7%;
}

.notif-hover:hover{
  cursor: pointer !important;
  color: green !important;
}

.title-text{
    font-size: 16px;
}

.not-body{
  margin-bottom: 0px !important;
}
</style>