<template>
    <div class="row">
        <div class="col-lg-12">
            <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <br />
        <br />
        <br />

        <div class="card-header">
          <div class="col-lg-3">
            <button
              class="btn btn-flat-dark"
              type="button"
              aria-haspopup="true"
              aria-expanded="false"
              @click="viewFilter"
            >
              <span class="feather icon-filter"></span> &nbsp; Format Results
            </button>
          </div>

          <div class="col-lg-6 text-center">
            <p class="text-center">
              <span>
                <h2>Stock Issued Sales Report</h2>
                <br />
                &nbsp;</span
              >

              <span
                v-if="
                  this.filter.dateStart == '' || this.filter.dateStart == null
                "
              >
                <small> <b>As at:</b> {{ this.filter.dateEnd }}</small>
              </span>
              <span
                v-if="
                  this.filter.dateStart != '' &&
                  this.filter.dateStart != null &&
                  this.filter.dateEnd != '' &&
                  this.filter.dateEnd != null
                "
              >
                <span
                  v-if="
                    this.filter.dateStart != '' && this.filter.dateStart != null
                  "
                  ><small
                    ><b>From:</b> {{ this.filter.dateStart }}
                  </small></span
                >
                &nbsp; <small> <b>To:</b> {{ this.filter.dateEnd }}</small>
              </span>
            </p>
          </div>

          <div class="col-lg-3" style="text-align: right">
            <!-- <div class="btn-group">
              <div class="dropdown">
                <button
                  class="btn btn-flat-primary dropdown-toggle mr-1 mb-1"
                  type="button"
                  id="dropdownMenuButton100"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="feather icon-download"></span> &nbsp; Export
                  Report
                </button>
                <div
                  class="dropdown-menu"
                  aria-labelledby="dropdownMenuButton100"
                >
                   <a class="dropdown-item" @click="csvExport(csvData)" href="#">
                    Current View (CSV)</a
                  >
                  <a class="dropdown-item" @click="excelData(exportItems)" href="#">
                    Current View (Excel)</a >
                  <a
                    class="dropdown-item"
                    @click="csvExport(csvDataAll)"
                    href="#"
                    >All Records (CSV)</a
                  > <a
                    class="dropdown-item"
                    @click="excelData(exportItemsAll)"
                    href="#"
                    >All Records (Excel)</a >
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <br />
        <br />

         <!-- following commented for pagination -->

        <!-- <div class="col-sm-3">
          <small>
            Show
            <select
              @change="
                pageSize = Number($event.target.value);
                getStockIssuedSalesReport();
              "
              class="select2 form-control col-sm-2"
              style="display: inline"
            >
              <option
                v-for="item of [10, 25, 50, 100]"
                :key="item"
                :value="item"
                :selected="page == item"
              >
                {{ item }}
              </option>
            </select>
            entries
          </small>
        </div>
        <br /> -->


                <div class="">
                    <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                        <div class="row be-datatable-body">
                            <div class="col-sm-12 table-responsive">
                                <table class="table table-striped dataex-html5-selectors">
                                    <thead>
                                        <tr>
                                            <th style="width:18%;">Sales Agent Name</th>
                                            <th style="width:18%;">Sales Agent Phone</th>
                                            <th style="width:15%;">Product Sold</th>
                                            <th  class="text-right"  style="width:13%;">Product Code</th>
                                            <th  class="text-center" style="width:5%;">Quantity</th>
                                            <th  class="text-center"  style="width:13%;">Record Date</th>
                                            <th  class="text-center"  style="width:11%;">Record Time</th>
                                            <th  class="text-center"  style="width:15%;">Channels</th>
                                            <th  class="text-center"  style="width:9%;">Longitute</th>
                                            <th  class="text-center"  style="width:9%;">Latitude</th>
                                            <!-- <th  class="text-right"  style="width:15%;">Revenue (₦)</th> -->
                                            <!-- <th  class="text-right"  style="width:15%;">Estimated Cost of Goods (₦)</th> -->
                                            <!-- <th  class="text-right"  style="width:15%;">Estimated Sales Margin (₦)</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in ReportItems" :key="item.rowNum">
                                            <td>{{ item.salesAgentName }}</td>
                                            <td>{{ item.salesAgentPhoneNumber }}</td>
                                            <td>{{ item.productSold }}</td>
                                            <td class="text-right" >{{ item.productCode }}</td>
                                            <td class="text-center" >{{ item.quantity }}</td>
                                            <td class="text-center" >{{ formatDate(item.recordDate) }}</td>
                                            <td class="text-center" >{{ formatTime(item.recordTime) }}</td>
                                            <td class="text-center" >{{ item.channel }}</td>
                                            <td class="text-center" >{{ item.longitude }}</td>
                                            <td class="text-center" >{{ item.latitude }}</td>
                                            <!-- <td class="text-right" >{{ formatNumber(item.revenue) }}</td> -->
                                            <!-- <td class="text-right" >{{ formatMoney(item.estimatedCostofGoods) }}</td> -->
                                            <!-- <td class="text-right" >{{ formatMoney(item.estimatedSalesMargin) }}</td> -->
                                        </tr>
                                        <tr v-if="!ReportItems.length">
                                            <td colspan="12">
                                                <div class="text-center">No matching records</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row be-datatable-footer">
                              <div class="col-sm-5">
                                    <div class="dataTables_info" v-if="ReportItems != null && ReportItems.length > 0">
                                    <small>
                                        Showing
                                        {{ page * pageSize + 1 }}
                                        -
                                        {{ page * pageSize + ReportItems.length }}
                                        of
                                        <!-- following commented for pagination -->
                                        <!-- {{ count }} record{{ count != 1 ? "s" : "" }} -->
                                        {{ ReportItems.length }} record{{ ReportItems.length != 1 ? "s" : "" }}
                                    </small>
                                    </div>
                                </div>
                              <div class="col-sm-4">
                                <div class="dataTables_paginate paging_simple_numbers">
                                <ul class="pagination">
                                    <li
                                    :class="[
                                        'paginate_button',
                                        'page-item',
                                        'previous',
                                        { disabled: !hasPrev },
                                    ]"
                                    >
                                    <a href="#" class="page-link" @click.prevent="prev"
                                        >Previous</a
                                    >
                                    </li>
                                    <li :class="['paginate_button', 'page-item', 'previous']">
                                     <!-- following commented for pagination -->
                                    <!-- <a class="page-link">Page {{ page + 1 }} of {{ maxPage }}</a> -->
                                    <a class="page-link">Page 1  of 1</a>
                                    </li>
                                    <li
                                    :class="[
                                        'paginate_button',
                                        'page-item',
                                        'previous',
                                        { disabled: !hasNext },
                                    ]"
                                    >
                                    <a href="#" class="page-link" @click.prevent="next"
                                        >Next</a
                                    >
                                    </li>
                                </ul>
                                </div>
                            </div>

                             <div class="col-sm-3" style="text-align: right">
                                    <div
                                    class="dataTables_info"
                                    v-if="ReportItems != null && ReportItems.length > 0"
                                    >
                                    <small>
                                        Jump to page:
                                        <select
                                        @change="
                                            page = Number($event.target.value);
                                            getStockIssuedSalesReport();
                                        "
                                        class="form-control col-sm-2"
                                        style="display: inline"
                                        >
                                        <option
                                            v-for="item of pageArray"
                                            :key="item"
                                            :value="item"
                                            :selected="page == item"
                                        >
                                            {{ item + 1 }}
                                        </option>
                                        </select>
                                    </small>
                                    </div>
                                </div>

                        </div>
                    </div>
                </div>
                <div class="be-spinner">
                    <svg width="40px" height="40px" viewBox="0 0 66 66">
                        <circle class="circle"
                                fill="none"
                                stroke-width="4"
                                stroke-linecap="round"
                                cx="33"
                                cy="33"
                                r="30"></circle>
                    </svg>
                </div>
            </div>
        </div>

         <!-- <div
            class="modal fade colored-header colored-header-primary"
            id="mod-filter"
            role="dialog"
            >
            <ReportFilterModal
                @onsearch="page = 0"
                v-model="filter"
                extraDataType="TEXT"
                extraDataPlaceholder="Filter by product name..."
                :searchFn="getStockIssuedSalesReport"
            ></ReportFilterModal>
        </div> -->

         <div class="modal fade colored-header colored-header-primary" id="mod-filter" role="dialog">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                  <div>
                      <h4 class="modal-title"><b>Filter</b></h4>
                  </div>
                  <button
                      class="close md-close"
                      type="button"
                      data-dismiss="modal"
                      aria-hidden="true"
                  >
                      <span class="mdi mdi-close"></span>
                  </button>
              </div>
              <div class="modal-body">
                <div class="form-group row">
                  <label class="col-12 col-sm-4">Start Date: </label>
                  <div class="col-12 col-sm-8 ">
                    <input
                        :class="['form-control']"
                        type="date"
                        placeholder="Select a date"
                        v-model="filter.dateStart"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-12 col-sm-4">End Date: </label>
                  <div class="col-12 col-sm-8 ">
                    <input
                        :class="['form-control']"
                        type="date"
                        placeholder="Select a date"
                        v-model="filter.dateEnd"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-12 col-sm-4">Product Name: </label>
                  <div class="col-12 col-sm-8 ">
                    <input
                      :class="['form-control']"
                      type="text"
                      v-model="filter.productName"
                    />
                  </div>
                </div>

                 <div class="form-group row">
                  <label class="col-12 col-sm-4">Sales Agent Name: </label>
                  <div class="col-12 col-sm-8 ">
                    <input
                      :class="['form-control']"
                      type="text"
                      v-model="filter.salesAgentName"
                    />
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  style="width: 100px;"
                  class="btn btn-text btn-space"
                  type="button"
                  data-dismiss="modal"
                  @click="reset"
                >
                  <span class="text-danger"> Reset </span>
                </button>

                <button
                  style="width: 100px;"
                  class="btn btn-primary"
                  type="button"
                  data-dismiss="modal"
                  :disabled="isBusy"
                  @click="getStockIssuedSalesReport"
                >
                  <span v-if="!isBusy">Filter</span>
                  <span v-else>Busy...</span>
                </button>

              </div>
            </div>
          </div>
      </div>
    </div>
</template>

<script>
    /* eslint-disable */

    import moment from "moment";
    import { helper } from "@/helper";
    import Select2 from "@/components/Select2";
    import ReportFilterModal from "@/components/Common/ReportFilterModal";
    import {exportExcel } from "@/excel";

    export default {
        name: "StockIssuedSalesReport",
        components: {
            Select2, ReportFilterModal,
        },
        created() {
            this.getStockIssuedSalesReport();
            // this.getSalesMarginExportItemsAll();
        },
        data() {
            return {
                filter: {
                    qry: "",
                    dateStart: moment()
                        .subtract(1, "years")
                        .format("YYYY-MM-DD"),
                    dateEnd: moment().format("YYYY-MM-DD"),
                    productName:"",
                    salesAgentName:""
                },
                isBusy: false,
                isBusyModal: false,
                ReportItems: [],
                ExportReportItemsAll: [],
                count: 0,
                page: 0,
                pageSize: 10,
                pageSizeExport: 999999999,
                hasNext: false,
                hasPrev: false,
                maxPage: 0,
                pageArray: [],
                options: {
                    format: "YYYY-MM-DD",
                    icons: {
                        time: "mdi mdi-time",
                        date: "mdi mdi-calendar",
                        up: "mdi mdi-chevron-up",
                        down: "mdi mdi-chevron-down",
                        previous: "mdi mdi-chevron-left",
                        next: "mdi mdi-chevron-right",
                        today: "mdi mdi-screenshot",
                        clear: "mdi mdi-trash",
                        close: "mdi mdi-remove"
                    }
                },
                local: {
                    dow: 0, // Sunday is the first day of the week
                    hourTip: "Select Hour", // tip of select hour
                    minuteTip: "Select Minute", // tip of select minute
                    secondTip: "Select Second", // tip of select second
                    yearSuffix: "", // suffix of head year
                    monthsHead: "January_February_March_April_May_June_July_August_September_October_November_December".split(
                        "_"
                    ), // months of head
                    months: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"), // months of panel
                    weeks: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"), // weeks
                    cancelTip: "cancel",
                    submitTip: "confirm"
                }
            };
        },
        computed: {
            user: function () {
                return this.$store.state.currentUser;
            },
            // csvData() {
            //     return this.exportItems.map(item => ({
            //         ...item
            //     }));
            // },
            // exportItems() {
            //     return this.ReportItems.map(function(item) {
            //         return {
            //             "Product Code": item.productCode,
            //             "Product Name": item.productName,
            //             "Product Unit": item.productUnit,
            //             "Selling Price": item.sellingPrice,
            //             "Opening Stock": item.openingStock,
            //             "Purchases": item.purchases,
            //             "Sales Quantity": item.salesQuantity,
            //             "Closing Stock": item.closingStock,
            //             "Revenue (NGN)": item.revenue,
            //             "Estimated Cost of Goods (NGN)": item.estimatedCostofGoods,
            //             "Estimated Sales Margin": item.estimatedSalesMargin
            //         }
            //     });
            // },
            // csvDataAll() {
            //     return this.exportItemsAll.map(item => ({
            //         ...item
            //     }));
            // },
            // exportItemsAll() {
            //     this.getSalesMarginExportItemsAll();
            //     return this.ExportReportItemsAll.map(function (item) {
            //         return {
            //             "Product Code": item.productCode,
            //             "Product Name": item.productName,
            //             "Product Unit": item.productUnit,
            //             "Selling Price": item.sellingPrice,
            //             "Opening Stock": item.openingStock,
            //             "Purchases": item.purchases,
            //             "Sales Quantity": item.salesQuantity,
            //             "Closing Stock": item.closingStock,
            //             "Revenue (NGN)": item.revenue,
            //             "Estimated Cost of Goods (NGN)": item.estimatedCostofGoods,
            //             "Estimated Sales Margin": item.estimatedSalesMargin
            //         }
            //     });
            // }
        },
        methods: {
            reset() {
                this.filter = {
                    qry: "",
                    dateStart: moment()
                        .subtract(1, "years")
                        .format("YYYY-MM-DD"),
                    dateEnd: moment().format("YYYY-MM-DD"),
                    productName:"",
                    salesAgentName:""
                };
                this.getStockIssuedSalesReport();
            },
            viewExport() {

            },
            viewFilter() {
                $("#mod-filter").modal("show");
            },
            getStockIssuedSalesReport() {
                this.isBusy = true;

                this.$http
                    .get(
                        "/Reports/getStockIssueSalesReport?distributorId=" + 
                        (this.user.parentId ? this.user.parentId : "") +
                        (this.filter.salesAgentName ? "&salesAgentName=" + this.filter.salesAgentName : "") +
                        (this.filter.productName ? "&productName=" + this.filter.productName : "") +
                        (this.filter.startDate != ""
                            ? "&startDate=" +
                            moment(this.filter.dateStart).format("YYYY-MM-DD 00:00:00")
                            : "") +
                        (this.filter.endDate != ""
                            ? "&endDate=" +
                            moment(this.filter.dateEnd).format("YYYY-MM-DD 23:59:59")
                            : "")
                    )
                    .then(function (res) {
                        this.ReportItems = res.body;

                    //The following is for pagination
                        // this.page = res.body.page;
                        // this.pageSize = res.body.pageSize;
                        // this.count = res.body.count;
                        // this.hasPrev = res.body.hasPrev;
                        // this.hasNext = res.body.hasNext;
                        // this.maxPage = Math.ceil(this.count / this.pageSize);
                        // this.pageArray = [...Array(this.maxPage).keys()];

                        $("#mod-filter").modal("hide");
                        this.isBusy = false;
                    })
                    .catch(function () {
                        $("#mod-filter").modal("hide");
                        this.isBusy = false;
                    });
            },
            // getSalesMarginExportItemsAll() {
            //     this.isBusy = true;

            //     this.$http
            //         .get(
            //             "/Reports/getSalesMarginsReport?page=" +
            //             this.page +
            //             "&pageSize=" +
            //             this.pageSizeExport +
            //             (this.filter.qry != ""
            //                 ? "&query=" + this.filter.qry
            //                 : "") +
            //             (this.filter.dateStart != ""
            //                 ? "&startDate=" +
            //                 moment(this.filter.dateStart).format("YYYY-MM-DD 00:00:00")
            //                 : "") +
            //             (this.filter.dateEnd != ""
            //                 ? "&endDate=" +
            //                 moment(this.filter.dateEnd).format("YYYY-MM-DD 23:59:59")
            //                 : "") +
            //             (this.user.parentId ? "&parentId=" + this.user.parentId : "")
            //         )
            //         .then(function (res) {
            //             this.ExportReportItemsAll = res.body.data;

            //             $("#mod-filter").modal("hide");
            //             this.isBusy = false;
            //         })
            //         .catch(function () {
            //             $("#mod-filter").modal("hide");
            //             this.isBusy = false;
            //         });
            // },
            formatDate(date) {
                return moment(date).format("MMMM DD YYYY");
            },
            formatTime(time) {
                let hrChk = parseInt(time.substring(0,2));
                let newTime = time.substring(0,5);
                newTime =  newTime + (hrChk >= 12 ? ' PM': ' AM');
                return newTime;
            },
            formatMoney(val) {
                return helper.formatMoney(val);
            },
            formatNumber(val) {
                return helper.formatNumber(val);
            },
            prev() {
                this.page -= 1;
                this.getStockIssuedSalesReport();
            },
            next() {
                this.page += 1;
                 this.getStockIssuedSalesReport();
            },
            // csvExport(arrData) {
            //     let csvContent = "data:text/csv;charset=utf-8,";
            //     csvContent += [
            //         Object.keys(arrData[0]).join(","),
            //         ...arrData.map(item => Object.values(item).join(","))
            //     ]
            //         .join("\n")
            //         .replace(/(^\[)|(\]$)/gm, "");

            //     const data = encodeURI(csvContent);
            //     const link = document.createElement("a");
            //     link.setAttribute("href", data);
            //     link.setAttribute("download", "sales_mergin_report.csv");
            //     link.click();
            // },
            //  excelData(exportData) {
            //     const exportAble = exportData;
            //     const options = {
            //         headers: Object.keys(exportAble[0])
            //     };

            //     const numericHeaders = [
            //         "Selling Price",
            //         "Revenue (NGN)",
            //         "Estimated Cost of Goods (NGN)",
            //         "Estimated Sales Margin",
            //     ];
            //     var titleName = "Sales Margins Report_";
            //     var titleFullname =
            //         this.filter.dateStart === "" || this.filter.dateStart == null
            //         ? titleName + "As At_" + this.filter.dateEnd
            //         : titleName + this.filter.dateStart + "_to_" + this.filter.dateEnd;
            //     const excelData = {
            //         title: titleFullname,
            //         headers: options.headers,
            //         data: exportAble,
            //     };
            //     const businessName = this.$store.getters.getCurrentUser.parent.name;
            //     const businessAddress = this.$store.getters.getCurrentUser.parent.address;
            //     exportExcel(excelData, businessName, businessAddress, numericHeaders);
            // },
        }
    };
</script>