<template>
  <div class="w-full pb-2 flex flex-col justify-start items-start gap-[15px]">
    <!-- Tabs Section -->
    <div class="w-full flex justify-start items-start gap-[11px]">
      <button
        :class="[
          'text-[14px] font-semibold leading-[20px] cursor-pointer focus:outline-none py-[5px]',
          {
            'border-b-2 border-[#2463EB] text-[#2463EB]': activeTab === 'user',
            'text-[#96a0b5]': activeTab !== 'user',
          },
        ]"
        @click="$emit('switchTab', 'user')"
      >
        User Management
      </button>
      <button
        :class="[
          'text-[14px] font-semibold leading-[20px] cursor-pointer focus:outline-none py-[5px]',
          {
            'border-b-2 border-[#2463EB] text-[#2463EB]': activeTab === 'role',
            'text-[#96a0b5]': activeTab !== 'role',
          },
        ]"
        @click="$emit('switchTab', 'role')"
      >
        Role Management
      </button>
    </div>

    <!-- User Management Content -->
    <div
      v-if="activeTab === 'user'"
      class="flex xs:flex-col lg:flex-row xs:justify-start lg:justify-between w-full xs:items-start xs:gap-4 lg-gap-2 lg:items-center"
    >
      <p class="text-[#353535] text-[14px] leading-[20px] font-semibold">
        Manage All user accounts here
      </p>
      <div
        class="flex gap-[2px] md:gap-3 flex-wrap xs:justify-start lg:justify-center xs:items-start lg:items-center"
      >
        <button
          @click="$emit('viewFilter')"
          class="border-[1px] border-[#96a0b5] rounded-[8px] text-[#96a0b5] font-normal border-solid text-[12px] sm:text-[14px] border-solid px-[12px] sm:px-[24px] py-[12px] outline-none focus:outline-none"
        >
          <span class="feather icon-filter text-[#96a0b5]"></span> Filters
        </button>
        <button
          class="border-[1px] bg-[#7367f0] rounded-[8px] text-white font-normal border-solid text-[12px] sm:text-[14px] border-solid px-[12px] sm:px-[24px] py-[12px] outline-none focus:outline-none"
          @click="$emit('viewAdd')"
          v-if="hasPermission('User_Create')"
        >
          <span class="feather icon-plus text-white"></span> Invite User
        </button>
        <div class="relative" @click.self="toggleManualUserDropdown">
          <button
            class="border-[1px] bg-[#2463eb] rounded-[8px] text-white font-normal border-solid text-[12px] sm:text-[14px] border-solid px-[12px] sm:px-[24px] py-[12px] outline-none focus:outline-none"
            @click="$emit('toggleManualUserDropdown')"
          >
            <i class="fa-solid fa-chevron-down"></i> Create User Manually
          </button>
          <div
            v-if="showManualUserDropdown"
            class="absolute right-0 w-[200px] mt-1 border border-[#d0d5dd] rounded-md shadow-md z-10 bg-[#fcfcfd] p-[4px]"
          >
            <ul>
              <li
                class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer border-b border-[#e5e7eb] py-[12px] px-[16px]"
                @click="$emit('viewAddSalesManual')"
                v-if="hasPermission('User_Create')"
              >
                Sales User
              </li>
              <li
                class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer py-[12px] px-[16px]"
                @click="$emit('viewAddManual')"
                v-if="hasPermission('User_Create')"
              >
                Business User
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Role Management Content -->
    <div
      v-if="activeTab === 'role'"
      class="flex xs:flex-col lg:flex-row xs:justify-start lg:justify-between w-full xs:items-start xs:gap-4 lg-gap-2 lg:items-center"
    >
      <p class="text-[#353535] text-[14px] leading-[20px] font-semibold">
        Manage and assign roles
      </p>
      <div class="relative" @click.self="toggleManualRoleDropdown">
        <button
          class="border-[1px] bg-[#2463eb] rounded-[8px] text-white font-normal border-solid text-[12px] sm:text-[14px] border-solid px-[12px] sm:px-[24px] py-[12px] outline-none focus:outline-none"
          @click="$emit('toggleManualRoleDropdown')"
        >
          <i class="fa-solid fa-chevron-down"></i> Create New Role
        </button>
        <div
          v-if="showManualRoleDropdown"
          class="absolute right-0 w-[200px] mt-1 border border-[#d0d5dd] rounded-md shadow-md z-10 bg-[#fcfcfd] p-[4px]"
        >
          <ul>
            <li
              class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer border-b border-[#e5e7eb] py-[12px] px-[16px]"
              @click="$emit('viewAddRoleManual')"
              v-if="hasPermission('Role_Create')"
            >
              Sales Role
            </li>
            <li
              class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer py-[12px] px-[16px]"
              @click="$emit('viewAddBusinessRole')"
              v-if="hasPermission('Role_Create')"
            >
              Business Role
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeTab: {
      type: String,
      required: true,
    },
    showManualUserDropdown: Boolean,
    showManualRoleDropdown: Boolean,
    hasPermission: Function,
  },
};
</script>
