<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-add-manual-sales"
    role="dialog"
    abindex="-1"
  >
    <div
      class="modal-dialog m-0 p-0 absolute top-0 right-0 flex justify-end items-end"
      role="document"
    >
      <div
        class="modal-content rounded-none bg-[#f8f8f8] p-[40px] min-h-screen"
      >
        <div
          class="modal-header modal-header-colored  flex items-start justify-between p-0 m-0"
        >
          <h4
            class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
          >
            <b>Create New Sales User</b>
          </h4>
          <button
            class="text-2xl text-[#353535]"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
            @click="reset"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body p-0 m-0">
          <div>
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Username</label
            >
            <div>
              <input
                :class="[{ 'is-invalid': $v.user.username.$error }]"
                type="text"
                placeholder="Username"
                autocomplete="off"
                v-model.trim="$v.user.username.$model"
                class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full"
              />
              <div class="invalid-feedback" v-if="!$v.user.username.required">
                Field is required.
              </div>
              <div
                class="invalid-feedback"
                v-if="!$v.user.username.alphaNumAndDotValidator"
              >
                Username must not include space or special characters!
              </div>
            </div>
          </div>
          <div class="py-[16px]">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Password</label
            >
            <div class="pb-[16px]">
              <input
                :class="[{ 'is-invalid': $v.user.password.$error }]"
                type="password"
                placeholder="Password"
                autocomplete="off"
                v-model.trim="$v.user.password.$model"
                class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full"
              />
              <div class="invalid-feedback" v-if="!$v.user.password.required">
                Field is required.
              </div>
              <div class="invalid-feedback" v-if="!$v.user.password.minLength">
                Password must have at least
                {{ $v.user.password.$params.minLength.min }} letters.
              </div>
            </div>
          </div>
          <div class="pb-[16px]">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Email address</label
            >
            <div>
              <input
                :class="[{ 'is-invalid': $v.user.emailAddress.$error }]"
                type="email"
                placeholder="Email address of user"
                autocomplete="off"
                v-model.trim="$v.user.emailAddress.$model"
                class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full"
              />
              <div
                class="invalid-feedback"
                v-if="!$v.user.emailAddress.required"
              >
                Field is required.
              </div>
              <div class="invalid-feedback" v-if="!$v.user.emailAddress.email">
                Email is invalid.
              </div>
            </div>
          </div>
          <div class="pb-[16px]">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Phone Number</label
            >
            <div>
              <input
                :class="[{ 'is-invalid': $v.user.phoneNumber.$error }]"
                type="text"
                placeholder="Phone number of user"
                autocomplete="off"
                v-model.trim="$v.user.phoneNumber.$model"
                class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full"
              />
              <div
                class="invalid-feedback"
                v-if="!$v.user.phoneNumber.required"
              >
                Field is required.
              </div>
              <div
                class="invalid-feedback"
                v-if="!$v.user.phoneNumber.validatePhone"
              >
                Number not valid!
              </div>
            </div>
          </div>
          <div class="pb-[16px]">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Last Name</label
            >
            <div>
              <input
                :class="[{ 'is-invalid': $v.user.lastName.$error }]"
                type="text"
                placeholder="Last Name of user"
                v-model.trim="$v.user.lastName.$model"
                class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full"
              />
              <div class="invalid-feedback" v-if="!$v.user.lastName.required">
                Field is required.
              </div>
            </div>
          </div>
          <div class="pb-[16px]">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Other Names</label
            >
            <div>
              <input
                :class="[{ 'is-invalid': $v.user.otherNames.$error }]"
                type="text"
                placeholder="Other Names of user"
                v-model.trim="$v.user.otherNames.$model"
                class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full"
              />
              <div class="invalid-feedback" v-if="!$v.user.otherNames.required">
                Field is required.
              </div>
            </div>
          </div>
          <div class="pb-[16px]">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Role</label
            >

            <div>
              <select
                :class="[{ 'is-invalid': $v.user.role.$error }]"
                placeholder="Select an option"
                v-model.trim="$v.user.role.$model"
                class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full"
              >
                <option v-for="r in salesRoles" :key="r.id" :value="r.id">
                  {{ r.text }}
                </option>
              </select>
              <div class="invalid-feedback" v-if="!$v.user.role.required">
                Field is required.
              </div>
            </div>
          </div>
          <div class="pb-[16px]">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Staff Id</label
            >
            <div>
              <input
                type="text"
                placeholder="Staff Id of user"
                v-model.trim="user.staffId"
                class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full"
              />
            </div>
          </div>
          <div class="flex items-center gap-[8px]">
            <button
              class="bg-[#fff] border-[##96a0b5] border-solid border py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full"
              type="button"
              data-dismiss="modal"
              @click="reset"
            >
              Cancel
            </button>
            <button
              class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] w-full"
              :disabled="isBusyModal"
              type="button"
              @click.prevent="createNewUser"
            >
              <span
                v-if="isCreating"
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <i class="sr-only">Loading...</i>
              </span>
              <span v-else> Create User </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { helper } from "@/helper";
import { mapActions, mapGetters } from "vuex";
import { required, minLength, helpers, email } from "vuelidate/lib/validators";

const alphaNumAndDotValidator = helpers.regex("alphaNumAndDot", /^[a-z\d.]*$/i);
const validatePhone = (value) => helper.validatePhone(value);

export default {
  name: "CreateSalesUser",

  data() {
    return {
      isBusyModal: false,
      isCreating: false,
      user: {
        username: "",
        password: "",
        phoneNumber: "",
        emailAddress: "",
        lastName: "",
        otherNames: "",
        role: "",
        staffId: "",
      },
    };
  },

  validations: {
    user: {
      username: { required, alphaNumAndDotValidator },
      password: { required, minLength: minLength(6) },
      emailAddress: { required, email },
      phoneNumber: { required, validatePhone },
      lastName: { required },
      otherNames: { required },
      role: { required },
    },
  },

  computed: {
    ...mapGetters(["salesRoles"]),
    currentUser: function() {
      return this.$store.state.currentUser;
    },
  },

  mounted() {
    this.getSalesRoles();
  },

  methods: {
    ...mapActions(["getSalesRoles"]),

    async createNewUser() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isCreating = true;
        let data = {
          username: this.user.username,
          password: this.user.password,
          phoneNumber: this.user.phoneNumber,
          emailAddress: this.user.emailAddress,
          lastName: this.user.lastName,
          otherNames: this.user.otherNames,
          roleId: this.user.role,
          parentId: this.currentUser.parentId,
          staffId: this.user.staffId,
        };

        try {
          const response = await this.$http.post("/SalesUsers/create", data);
          if (response.ok) {
            this.reset();
            this.$emit("onCreateUser");
          }
        } catch (error) {}

        this.isCreating = false;
        this.isBusyModal = false;
      }
    },

    reset() {
      this.user.username = this.user.password = this.user.phoneNumber = this.user.emailAddress = this.user.lastName = this.user.otherNames = this.user.role = this.user.staffId =
        "";

      setTimeout(() => {
        this.$v.$reset();
      }, 0);
    },
  },
};
</script>
