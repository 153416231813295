<template>
	<div class="row">
		<div class="col-lg-12">
			<div
				:class="[
					'card',
					'card-border-color',
					'card-border-color-primary',
					'card-table',
					'be-loading',
					{ 'be-loading-active': isBusy },
				]"
			>
				<div class="card-header">
					<button
						v-if="hasPermission('EndOfDay_Report_Setting_Update')"
						class="btn btn-success btn-rounded btn-space ml-auto mr-5"
						@click="editEODModal"
					>
						<span class="feather icon-edit text-white"></span> Configure
					</button>
				</div>
				<div class="card-body">
					<h2 class="eod-report-header">End of Day (EOD) Report</h2>
					<h4 class="eod-report-subheader margin-y">
						Send Daily EOD Report &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<span :class="isActive ? isActiveClass : isNotActiveClass">{{
							isActive ? "ON" : "OFF"
						}}</span>
					</h4>
					<h4 class="eod-report-subheader ">
						Recipients of Daily EOD Report &nbsp;&nbsp;&nbsp;&nbsp;
						<span v-if="!recipientCount" class="enable-noti">You have to enable</span>
						<span v-else class="enable-noti"
							>You currently have {{ recipientCount }} EOD recipients
							<span @click="toggleTable" class="text-primary pointer">
								View Recipients</span
							></span
						>
					</h4>
					<div class="footer-body"></div>
				</div>
			</div>
			<div
				v-if="hideTable"
				:class="[
					'card',
					'card-border-color',
					'card-border-color-primary',
					'card-table',
					'be-loading',
					{ 'be-loading-active': isBusy },
				]"
			>
				<div class="card-header">
					<p>Recipients</p>
				</div>
				<div class="card-body">
					<div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
						<div class="row be-datatable-body">
							<div class="col-sm-12 table-responsive">
								<table
									class="
                    table table-fw-widget
                    dataTable
                    no-footer
                    text-nowrap
                    table-hover-animation table-striped
                  "
								>
									<thead>
										<tr>
											<th>Name</th>
											<th>Email</th>
											<th>Role</th>
											<th style="width: 15%"></th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="recipients in EDOreportsettingData"
											:key="recipients.id"
										>
											<td>{{ recipients.name }}</td>
											<td>{{ recipients.email }}</td>
											<td>
												{{
													recipients.role == null
														? "Nill"
														: recipients.role
												}}
											</td>

											<td>
												<button
													v-if="hasPermission('EndOfDay_Report_Setting_Update')"
													:class="[
														'btn  btn-sm',
														{
															'btn-primary': !recipients.active,
														},
														{
															'btn-outline-danger': recipients.active,
														},
													]"
													type="button"
													@click.prevent="deleteEOD(recipients.id)"
												>
													{{ recipients.active ? "Delete" : "Add" }}
												</button>
											</td>
										</tr>
										<tr v-if="EDOreportsettingData.length == 0">
											<td colspan="7">
												<div class="text-center" style="padding-top: 50px">
													<span
														style="font-size: 4.615rem"
														class="mdi mdi-tag-multiple"
													></span>
												</div>
												<h3
													class="text-center"
													style="padding-bottom: 100px"
												>
													You don't have any EOD Recipients.
												</h3>
											</td>
										</tr>
									</tbody>
								</table>
								<Pagination
									:limit="pageSize"
									:count="pageCount"
									:page="page"
									:itemsCount="count"
									@changePage="gotoPage"
									@changeLimit="changeLimit"
								/>
							</div>
						</div>
						<div class="row be-datatable-footer">
							<div class="col-sm-5">
								<div class="dataTables_info">
									{{ count }} Recipient{{ count != 1 ? "s" : "" }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-edit-EOD"
			role="dialog"
		>
			<div class="modal-dialog lg-modal">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<div>
							<h4 class="modal-title px-1 pt-1"><strong>Edit EOD Reports</strong></h4>
						</div>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body my-2">
						<div class="d-flex align-items-center mb-2" style="width: 66%;">
							<div class="col-12">
								<label class="custom-control custom-checkbox custom-control-inline">
									<input
										class="custom-control-input"
										type="checkbox"
										v-model="isActive"
									/>

									<span class="custom-control-label">Send Daily EOD Report</span>
								</label>
							</div>
						</div>
						<div class="form-group row justify-content-center ">
							<label class="col-12 col-sm-3 col-form-label">Time</label>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<select2
										:disabled="!isActive"
										placeholder="Select an option"
										v-model="editEOD.time"
									>
										<option value="" selected disabled hidden
											>Select Option</option
										>
										<option
											:key="option.key"
											v-for="option in timeRange"
											:value="option.value"
											>{{ option.text }}</option
										>
									</select2>
									<div v-if="v1.time" class="parsley-errors-list filled">
										<p class="text-danger error-text ">Select time</p>
									</div>
								</div>
							</div>
						</div>
						<p class="sendTo-text m-2 ">Send To</p>
						<div class="form-group row justify-content-center mb-3 ">
							<label class="col-12 col-sm-3 col-form-label">Select Role</label>
							<div class="d-flex align-items-center" style="width: 66%;">
								<div class="col-12">
									<!-- <multiselect
                    v-model="editEOD.role"
                    track-by="id"
                    :multiple="true"
                    :disabled="!isActive"
                    placeholder="Select a role"
                    :options="roleOptions.map(role => role.value)"
                    return="id"
                  >
                  </multiselect> -->
									<v-select
										id="roleSelector"
										class="style-chooser"
										multiple
										:disabled="!isActive"
										:options="roleOptions"
										v-model="editEOD.role"
										:reduce="value => value.id"
										@input="isSelectRole($event)"
									/>
									<!-- <select
                    :class="['form-control']"
                    :disabled="!isActive"
                    class="select2 form-control"
                    placeholder="Select an option"
                    v-model="editEOD.role"
                  >
                    <option value="" selected disabled hidden
                      >Select Option</option
                    >
                    <option
                      :key="option.key"
                      v-for="option in roles"
                      :value="option.value"
                      >{{ option.text }}</option
                    >
                  </select> -->
									<div v-if="v1.role" class="parsley-errors-list filled">
										<p class="text-danger error-text ">Select role</p>
									</div>
								</div>
							</div>
						</div>
						<div class="px-1">
							<p>You currently have <span class="font-weight-bold">{{recipientCount}}</span> EOD recipients</p>
						</div>
						<div :key="`input-${index}`" v-for="(input, index) in editEOD.userEmail">
							<div class="form-group row justify-content-center mb-3">
								<label class="col-12 col-sm-3 col-form-label"
									>Add Name(optional)</label
								>
								<div class="d-flex align-items-center" style="width: 66%;">
									<div class="col-12">
										<input
											:class="['form-control']"
											class="col-12"
											:disabled="!isActive"
											placeholder="Enter Name"
											type="text"
											v-model="input.name"
										/>
									</div>
								</div>
							</div>
							<div class="form-group row justify-content-center mb-3">
								<label class="col-12 col-sm-3 col-form-label">Enter Email</label>
								<div class="d-flex align-items-center" style="width: 66%;">
									<div class="col-12">
										<input
											:class="['form-control']"
											:disabled="!isActive"
											class="col-12"
											placeholder="Enter Email"
											type="email"
											v-model="input.email"
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="form-group row justify-content-center mb-3">
							<div
								class="text-primary mt-2"
								style="width:74%; cursor:pointer"
								@click.prevent="addFields()"
							>
								<i class="fa fa-plus "></i>
								<span>&nbsp; &nbsp;Add another Email Address </span>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
							@click.prevent="closeEODModal"
						>
							Cancel
						</button>
						<button
							style="width: 160px"
							:disabled="isBusyModal"
							class="btn btn-primary"
							type="button"
							@click.prevent="updateEOD"
						>
							<span v-if="isBusyModal">Busy...</span>
							<span v-else>Update</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	/* eslint-disable */

	import Select2 from "@/components/Select2";
import SystemPermissions from "@/permissions";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Pagination from "../components/Pagination.vue";

	export default {
		mixins: [SystemPermissions.Mixin],
		name: "EODReport",
		components: { Select2, vSelect, Pagination },
		data() {
			return {
				isBusy: false,
				isBusyModal: false,
				count: 0,
				page: 0,
				pageNumber: 1,
				pageSize: 10,
				pageCount: 0,
				hasNext: false,
				hasPrev: false,
				helperisBusy: false,
				systemSettings: null,
				EDOreportsettingData: [],
				isActiveClass: "on-switch",
				isNotActiveClass: "off-switch",
				isActive: false,
				recipientCount: null,
				hideTable: false,
				roleOptions: [],
				timeRange: [
					{ value: 0, text: "Morning" },
					{ value: 1, text: "Afternoon" },
					{ value: 2, text: "Evening" },
				],
				roles: [],
				editEOD: {
					active: false,
					time: null,
					role: [],
					name: "",
					userEmail: [],
				},
				v1: {
					active: false,
					time: false,
					role: false,
					name: false,
				},
			};
		},
		created() {
			if (!this.hasPermission("EndOfDay_Report_Setting_View")) {
				this.$router.push("/restricted");
			}
			this.getRoles();
			this.getSystemSettings();
			this.getEndOfDayReportSettings();
		},
		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},
		},

		methods: {
			toggleTable() {
				this.hideTable = !this.hideTable;
			},
			isSelectRole(e) {
				this.editEOD.role = e;
			},
			editEODModal() {
				$("#mod-edit-EOD").modal("show");
			},
			async closeEODModal() {
				await this.getEndOfDayReportSettings();
				$("#mod-edit-EOD").modal("hide");
				// new Promise(resolve => {
				// 	setTimeout(() => {

				// 		resolve();
				// 	}, 5000);
				// });
			},
			addFields() {
				this.editEOD.userEmail.push({});
			},
			removeFields(index, products) {
				products.splice(index, 1);
			},
			// selectedRoles() {
			//  let approvers = [];
			//  this.roleOptions.forEach(role => {
			//    this.selectedRoles.push(roles.id);
			//  });

			//  this.approvers = approvers;
			// },
			async getRoles() {
				await this.$http
					.get(
						"/roles/getRoleListNoPaging?parentId=" +
							this.user.parentId +
							"&roleType=" +
							this.user.type,
					)
					.then(function(res) {
						this.roleOptions = res.body.map((role, index) => ({
							label: role.text,
							id: role.id,
						}));
					})
					.catch(function() {});
			},
			async updateEOD() {
				this.isBusy = true;
				let body = {
					id: this.systemSettings.id ? this.systemSettings.id : "",
					parentId: this.user.parentId,
					active: this.isActive,
					time: this.editEOD.time ? this.editEOD.time : "",
					roles: this.editEOD.role.length > 0 ? this.editEOD.role : [],
					optionalRecipient:
						this.editEOD.userEmail.length > 0 ? this.editEOD.userEmail : [],
				};
				await this.$http
					.put(
						`/SystemSettings/updateEndOfDayReportSettings/${this.systemSettings.id}`,
						body,
					)
					.then(function(res) {
						if (res.ok) {
							this.$toast.success("EOD Updated successfuly", {
								icon: false,
								rtl: false,
							});
							$("#mod-edit-EOD").modal("hide");
							this.isBusy = false;
							this.getRoles();
							this.getSystemSettings();
							this.getEndOfDayReportSettings();
							this.updateState(this.editEOD);
						}
					})
					.catch(function(err) {
						if (err) {
							this.isBusy = false;
							this.updateState(this.editEOD);
						}
					});
			},
			async deleteEOD(id) {
				this.isBusy = true;
				await this.$http
					.put(
						`/SystemSettings/addRemoveEndOfDayReportRecipient/${id}/${this.user.parentId}`,
					)
					.then(function(res) {
						if (res.ok) {
							this.$toast.success("EOD Updated successfuly", {
								icon: false,
								rtl: false,
							});
							$("#mod-edit-EOD").modal("hide");
							this.isBusy = false;
							this.updateState(this.editEOD);
							this.getEndOfDayReportSettings();
						}
					})
					.catch(function(err) {
						if (err) {
							console.log(err.message);
							this.isBusy = false;
							this.updateState(this.editEOD);
						}
					});
			},
			updateState(formType) {
				(formType.active = null),
					(formType.time = null),
					(formType.role = []),
					(formType.userEmail = []),
					(formType.name = null);
			},
			async getEndOfDayReportSettings() {
				this.isBusy = true;
				await this.$http
					.get(
						`/SystemSettings/getEndOfDayReportSettings/${this.user.parentId}?page=${this.pageNumber}&pageSize=${this.pageSize}`,
					)
					.then(function(res) {
						this.EDOreportsettingData = res.data.reportRecipients.data;
						this.recipientCount = res.data.reportRecipients.count;
						this.isActive = res.data.active;
						this.editEOD.time = res.data.time;
						this.editEOD.role = res.data.roles;
						this.isBusy = false;
						this.pageNumber = res.data.reportRecipients.pageNumber;
						this.pageSize = res.data.reportRecipients.pageSize;
						this.count = res.data.reportRecipients.count;
						this.pageCount = Math.ceil(
							res.data.reportRecipients.count / res.data.reportRecipients.pageSize,
						);
						this.hasPrev = res.data.reportRecipients.hasPrev;
						this.hasNext = res.data.reportRecipients.hasNext;
						this.isBusy = false;
					})
					.catch(function() {});

				this.isBusy = false;
			},
			async getSystemSettings() {
				this.isBusy = true;
				await this.$http
					.get(
						"/SystemSettings/getList?page=0" +
							"&pageSize=10" +
							(this.user.parentId ? "&parentId=" + this.user.parentId : ""),
					)
					.then(function(res) {
						this.systemSettings = res.body.data[0];
						this.isBusy = false;
					})
					.catch(function() {});

				this.isBusy = false;
			},
			formatDate(date) {
				return moment(date).format("ll");
			},
			formatDateWithTime(date) {
				return moment(date).format("lll");
			},
			gotoPage(page) {
				this.pageNumber = page;
				this.getEndOfDayReportSettings();
			},
			changeLimit(limit) {
				this.pageSize = limit;
				this.getEndOfDayReportSettings();
			},
			prev() {
				this.pageNumber -= 1;
				this.getEndOfDayReportSettings();
			},
			next() {
				this.pageNumber += 1;
				this.getEndOfDayReportSettings();
			},
		},
	};
</script>

<style>
	.eod-report-header {
		font-size: 16px;
		font-weight: bold;
	}
	.eod-report-subheader {
		font-size: 14px;
		color: #8e8e8e;
		font-weight: light;
	}
	.margin-y {
		margin: 20px 0;
	}

	.off-switch {
		font-size: 12px;
		padding: 4px 8px;
		background-color: gray;
		color: white;
		border-radius: 20px;
	}
	.on-switch {
		font-size: 12px;
		padding: 4px 8px;
		background-color: green;
		color: white;
		border-radius: 20px;
	}
	.enable-noti {
		color: #1f1f1f;
	}
	.sendTo-text {
		font-size: 16px;
		color: #000;
		font-weight: 500;
	}
	.style-chooser .vs__search::placeholder,
	.style-chooser .vs__dropdown-toggle,
	.style-chooser .vs__dropdown-menu {
		padding: 0.7rem 0.7rem;
		border: 1px solid #d9d9d9;
		color: #5f5f5f;
		padding: 3px 2px;
	}
	.vs__selected-options {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		padding: 3px 2px;
		position: relative;
	}

	.style-chooser .vs__clear,
	.style-chooser .vs__open-indicator >>> {
		fill: #394066;
	}
	.pointer {
		cursor: pointer;
	}
</style>
