<template>
	<div>
		<div class="row">
			<div class="col-lg-12">
				<div
					:class="[
						'card',
						'card-border-color',
						'card-border-color-primary',
						'be-loading',
						{ 'be-loading-active': isBusy },
					]"
				>
					<div class="card-heade p-2">
						<div class="btn-group float-left">
							<router-link
								class="btn btn-space btn-danger btn-rounded"
								to="/customers"
							>
								<span class="icon icon-left mdi mdi-arrow-left text-white"></span>
								Back
							</router-link>
							<!--Disable distributor from edit customer, might be enabled later -->
							<!-- <router-link
								v-if="hasPermission('Customer_Update')"
								class="btn btn-space btn-success btn-rounded"
								:to="'/customers/edit/' + customer.id"
							>
								<span class="icon icon-left mdi mdi-pencil text-white"></span> Edit
								customer
							</router-link> -->
						</div>
					</div>
					<div class="card-body">
						<div class="customer-name">
							<h1>
								<b>{{ customer.displayName }}</b>
							</h1>
							<p>Customers Name</p>
						</div>
					</div>
				</div>
				<div
					:class="[
						'card',
						'card-border-color',
						'card-border-color-primary',
						'be-loading',
						{ 'be-loading-active': isBusy },
					]"
				>
					<div class="card-heade p-2">
						<div class="btn-group float-left">
							<h2>
								Customer Details
							</h2>
						</div>
					</div>
					<div class="card-body">
						<div class="row mb-2">
							<div class="col-4">
								<p class="text-muted">Email Address</p>
								<h4>{{ customer.emailAddress }}</h4>
							</div>
							<div class="col-4">
								<p class="text-muted">Delivery Address</p>
								<h4>{{ customer.deliveryAddress }}</h4>
							</div>
							<div class="col-4">
								<p class="text-muted">Phone Number</p>
								<h4>{{ customer.phoneNumber }}</h4>
							</div>
						</div>
						<div class="row mb-2">
							<div class="col-4">
								<p class="text-muted">State</p>
								<h4>{{ customer.state ? customer.state : "N/A" }}</h4>
							</div>
							<div class="col-4">
								<p class="text-muted">Local Government Area</p>
								<h4>{{ customer.lga ? customer.lga : "N/A" }}</h4>
							</div>
						</div>
						<div class="row">
							<div class="col-4">
								<p class="text-muted">ID</p>
								<h4>{{ customer.id }}</h4>
							</div>
							<div class="col-4">
								<p class="text-muted">Created</p>
								<h4>{{ formatDate(customer.created) }}</h4>
							</div>
							<div class="col-4">
								<p class="text-muted">Last Updated</p>
								<h4>{{ formatDate(customer.updated) }}</h4>
							</div>
						</div>
					</div>
					<div class="be-spinner">
						<svg width="40px" height="40px" viewBox="0 0 66 66">
							<circle
								class="circle"
								fill="none"
								stroke-width="4"
								stroke-linecap="round"
								cx="33"
								cy="33"
								r="30"
							></circle>
						</svg>
					</div>
				</div>
				<div
					:class="[
						'card',
						'card-border-color',
						'card-border-color-primary',
						'be-loading',
						{ 'be-loading-active': isBusy },
					]"
				>
					<div class="card-heade p-2">
						<div class="btn-group float-left">
							<h2>
								Additional Information
							</h2>
						</div>
					</div>
					<div class="card-body">
						<div class="row mb-5">
							<div class="col-4">
								<p class="text-muted">Discounts</p>
								<h4>
									{{
										customer.discount
											? `${customer.discount.name} - ${
													customer.discount.type != "Percent"
														? "&#8358;"
														: ""
											  } ${formatMoney(customer.discount.value)}${
													customer.discount.type == "Percent" ? "%" : ""
											  }`
											: "Not Available"
									}}
								</h4>
							</div>
							<div class="col-4">
								<p class="text-muted">Branch</p>
								<h4>{{ customer.branch ? customer.branch.name : "N/A" }}</h4>
							</div>
							<div class="col-4">
								<p class="text-muted">Group</p>
								<h4>
									{{
										customer.groups.length
											? customer.groups.map(e => e.name).join(", ")
											: "Not Available"
									}}
								</h4>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	/* eslint-disable */

	import moment from "moment";
	import { helper } from "@/helper";
	import SystemPermissions from "@/permissions";

	export default {
		mixins: [SystemPermissions.Mixin],
		name: "customersDetails",
		data() {
			return {
				isBusy: false,
				customer: {
					parent: {},
					contact: {},
					bank: {},
					groups: [],
					discount: {},
				},
			};
		},
		created() {
			if (!this.hasPermission("Customer_View")) {
				this.$router.push("/restricted");
			}
			this.getSalesRep();
		},
		computed: {
			backPath: function() {
				return this.$store.state.prevRoute;
			},
		},
		methods: {
			getSalesRep() {
				var id = this.$route.params.id;

				this.$http
					.get("/Users/getById/" + id)
					.then(function(res) {
						this.customer = res.body;
					})
					.catch(function() {});
			},
			formatDate(date) {
				if (date == null) {
					return "N/A";
				}
				return moment(date).format("ll");
			},
			formatMoney(val) {
				return helper.formatMoney(val);
			},
		},
	};
</script>

<style scoped>
	.customer-details h2 {
		font-weight: 900;
		font-size: 3.75rem;
	}
	.customer-details p {
		font-weight: 300;
		font-size: 3rem;
	}
</style>
