<template>
  <td>{{ quantity }}</td>
</template>

<script>
export default {
  props: ["details"],

  computed: {
    quantity: function () {
      let quantity = 0;
      if (this.details) {
        this.details.forEach((element) => {
          quantity += element.quantity;
        });
      }

      return quantity;
    },
  },
};
</script>

<style>
</style>