<template>
  <div class="row">
    <div class="col-lg-12" v-if="!showStockBucketTable">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button
            class="btn btn-success btn-rounded btn-space"
            @click="viewFilter"
          >
            <i class="feather icon-filter text-white"></i> Filter
          </button>
          <button
            class="btn btn-primary btn-rounded btn-space float-right"
            @click="initializeIssueStock"
          >
            <i class="feather icon-plus text-white"></i> &nbsp;Issue Stock
          </button>
        </div>

        <div class="card-body">
          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table
                  class="
                    table table-fw-widget
                    dataTable
                    no-footer
                    text-nowrap
                    table-hover-animation table-striped
                  "
                >
                  <thead>
                    <tr>
                      <th>Issue Number</th>
                      <th>Issued To</th>
                      <th>Issued by</th>
                      <th>Value of stock</th>
                      <th>Entry Date</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="is in issuedStockList" :key="is.id">
                      <td>{{ is.issueNumber }}</td>
                      <td>
                        {{ is.issueToName + "(" + is.issueToRole + ")" }}
                      </td>
                      <td>{{ is.issuedByName }}</td>
                      <td>₦ {{ formatMoney(is.totalValue) }}</td>
                      <td>{{ formatDate(is.entryDate) }}</td>
                      <td>
                        <span v-if="is.status == 0">Issued</span>
                        <span v-else-if="is.status == 1">Reversed</span>
                        <span v-else>Deleted</span>
                      </td>

                      <td>
                        <button
                          class="
                              btn btn-success btn-sm
                              dropdown-toggle
                            "
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Actions
                        </button>
                        <div
                          class="dropdown-menu px-1"
                          aria-labelledby="dropdownMenu2"
                        >
                          <div>
                            <router-link
                              class="dropdown-item"
                              :to="'/issueStockDetails/' + is.id"
                            >
                              View Details
                            </router-link>
                          </div>
                          <div v-if="hasPermission('Stock_Issue_Delete')">
                            <a
                              class="dropdown-item"
                              href="#"
                              @click.prevent="
                                deleteReverseModal(is.id, 'Reverse')
                              "
                            >
                              &nbsp;Reverse
                            </a>
                          </div>
                          <div v-if="hasPermission('Stock_Issue_Reverse')">
                            <a
                              class="dropdown-item text-danger"
                              href="#"
                              @click.prevent="
                                deleteReverseModal(is.id, 'Delete')
                              "
                            >
                              &nbsp;Delete
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="issuedStockList.length == 0">
                      <td colspan="7">
                        <div class="text-center" style="padding-top: 50px">
                          <span
                            style="font-size: 4.615rem"
                            class="mdi mdi-tag-multiple"
                          ></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom: 100px">
                          You don't have any issued stock.
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Pagination
                  :limit="pageSize"
                  :count="pageCount"
                  :page="pageNumber"
                  :itemsCount="returnCount"
                  @changePage="gotoPage"
                  @changeLimit="changeLimit"
                />
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ returnCount }} Return Detail{{
                    returnCount != 1 ? "s" : ""
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>
    <div class="col-lg-12" v-else>
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header mb-2">
          <button
            class="btn btn-danger btn-rounded btn-space"
            @click.prevent="goBack"
          >
            <i class="feather icon-arrow-left text-white"></i> Back
          </button>
          <button
            class="btn btn-primary btn-rounded btn-space float-right"
            @click.prevent="addNewProduct"
          >
            <i class="feather icon-plus text-white"></i> &nbsp;Add New Product
          </button>
        </div>
      </div>
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <h4 class="modal-title px-1 pt-1">
            <strong>Issue Stock</strong>
          </h4>
        </div>

        <div class="card-body">
          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table
                  class="
                    table table-fw-widget
                    dataTable
                    no-footer
                    text-nowrap
                    table-hover-animation table-striped
                  "
                >
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Issued To</th>
                      <th>Quantity Issued</th>
                      <th>Value of Stock</th>
                      <th>Warehouse</th>
                      <th>Storage Area</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(isB, index) in issuedStockBucketList"
                      :key="index"
                    >
                      <td>
                        <div>
                          {{ isB.product }}
                        </div>
                      </td>
                      <td>
                        {{
                          isB.issuedTo == "undefined()"
                            ? isB.newCustomer
                            : isB.issuedTo
                        }}
                      </td>
                      <td>{{ isB.qtyIssued }}</td>
                      <td>₦ {{ formatMoney(isB.valueOfStock) }}</td>
                      <td>{{ isB.wareHouse }}</td>
                      <td>{{ isB.storageArea }},</td>
                      <td>
                        <button
                          class="
                              btn btn-outline-primary btn-sm
                              dropdown-toggle
                            "
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Actions
                        </button>
                        <div
                          class="dropdown-menu px-1"
                          aria-labelledby="dropdownMenu2"
                        >
                          <div>
                            <a
                              class="dropdown-item"
                              href="#"
                              @click.prevent="editProduct(isB.productID)"
                            >
                              &nbsp;Edit
                            </a>
                          </div>
                          <div>
                            <a
                              class="dropdown-item text-danger"
                              href="#"
                              @click.prevent="deleteReturns(index)"
                            >
                              &nbsp;Delete Stock
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="issuedStockBucketList.length == 0">
                      <td colspan="9">
                        <div class="text-center" style="padding-top: 50px">
                          <span
                            style="font-size: 4.615rem"
                            class="mdi mdi-tag-multiple"
                          ></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom: 100px">
                          You don't have any issued stock.
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="float-right">
        <div>
          <div class="d-flex justify-content-between ">
            <button
              @click="goBack"
              class="btn btn-outline-danger btn-no-border btn-rounded btn-space float-right mr-2"
            >
              Cancel
            </button>
            <button
              :disabled="isBusy"
              class="btn btn-primary btn-rounded btn-space float-right"
              type="button"
              @click.prevent="issueStock"
            >
              <span v-if="isBusy">Busy...</span>
              <span v-else>Issue Stock</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-issue-stock"
      role="dialog"
    >
      <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <div>
              <h4 class="modal-title px-1 pt-1">
                <strong>Issue stock</strong>
              </h4>
            </div>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <!-- SELECT USER -->
            <div
              v-if="displayForm.userType"
              class="form-group d-flex align-items-center my-1"
            >
              <label class="col-12 col-sm-5 col-form-label text-sm-right "
                >Select who you wish to issue stock to</label
              >
              <div class="col-10 col-sm-7 col-lg-7">
                <select class="form-control" @input="setUsertype($event)">
                  <option selected disabled>Select</option>
                  <option
                    v-for="uT in userTypeOptiions"
                    :key="uT.id"
                    :value="uT.value"
                  >
                    {{ uT.value }}
                  </option>
                </select>
                <ul v-if="v1.userType" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <!-- NEW CUSTOMER -->
            <div v-if="displayForm.newCustomer">
              <div class="form-group d-flex align-items-center my-2">
                <label class="col-12 col-sm-5 col-form-label text-sm-right"
                  >Enter Customer Name</label
                >
                <div class="col-10 col-sm-7 col-lg-7">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Enter customer name"
                    v-model.number="newCustomer.name"
                  />
                  <ul v-if="v1.name" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group d-flex align-items-center my-2">
                <label class="col-12 col-sm-5 col-form-label text-sm-right"
                  >Email address</label
                >
                <div class="col-10 col-sm-7 col-lg-7">
                  <input
                    class="form-control"
                    type="email"
                    placeholder="Enter email"
                    v-model.number="newCustomer.email"
                  />
                  <ul v-if="v1.email" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <!-- <div class="form-group d-flex align-items-center my-2">
								<label class="col-12 col-sm-5 col-form-label text-sm-right"
									>Phone Number</label
								>
								<div class="col-10 col-sm-7 col-lg-7">
									<input
										class="form-control"
										type="number"
										placeholder="Enter Phone Number"
										v-model.number="newCustomer.phone"
									/>
									<ul v-if="v1.phone" class="parsley-errors-list filled">
										<li class="parsley-required">Required.</li>
									</ul>
								</div>
							</div> -->

              <div class="form-group d-flex align-items-center my-2">
                <label
                  for="mobileNumber"
                  class="col-12 col-sm-5 col-form-label text-sm-right"
                  >Mobile Number:</label
                >
                <div class="col-10 col-sm-7 col-lg-7">
                  <div class="input-group ">
                    <select
                      v-model="selectedCountryCode"
                      class="custom-select col-4 form-control"
                      disabled
                    >
                      <option v-for="code in countryCodes" :value="code">{{
                        code
                      }}</option>
                    </select>
                    <input
                      type="number"
                      class="form-control"
                      id="mobileNumber"
                      placeholder="Enter mobile number"
                      v-model="mobileNumber"
                      @input="validateMobileNumber"
                    />
                  </div>
                  <small v-if="validationError" class="text-danger">{{
                    validationError
                  }}</small>
                </div>
              </div>
              <div class="form-group d-flex align-items-center my-2">
                <label class="col-12 col-sm-5 col-form-label text-sm-right"
                  >Address</label
                >
                <div class="col-10 col-sm-7 col-lg-7">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Enter Customer Address"
                    v-model.number="newCustomer.address"
                  />
                  <ul v-if="v1.address" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- EXISTING CUSTOMER -->
            <div v-if="displayForm.existingCustomer">
              <div v-if="displayForm.customer">
                <div class="form-group d-flex align-items-center my-2">
                  <label class="col-12 col-sm-5 col-form-label text-sm-right"
                    >Enter Customer Name</label
                  >
                  <div class="col-10 col-sm-7 col-lg-7">
                    <input
                      class="form-control"
                      type="text"
                      disabled
                      placeholder="Enter customer name"
                      v-model.number="userDetails.name"
                    />
                    <ul v-if="v1.name" class="parsley-errors-list filled">
                      <li class="parsley-required">Required.</li>
                    </ul>
                  </div>
                </div>
                <div class="form-group d-flex align-items-center my-2">
                  <label class="col-12 col-sm-5 col-form-label text-sm-right"
                    >Email address</label
                  >
                  <div class="col-10 col-sm-7 col-lg-7">
                    <input
                      class="form-control"
                      type="email"
                      disabled
                      placeholder="Enter email"
                      v-model.number="userDetails.email"
                    />
                    <ul v-if="v1.email" class="parsley-errors-list filled">
                      <li class="parsley-required">Required.</li>
                    </ul>
                  </div>
                </div>
                <div class="form-group d-flex align-items-center my-2">
                  <label class="col-12 col-sm-5 col-form-label text-sm-right"
                    >Phone Number</label
                  >
                  <div class="col-10 col-sm-7 col-lg-7">
                    <input
                      class="form-control"
                      type="number"
                      disabled
                      placeholder="Enter Phone Number"
                      v-model.number="userDetails.phone"
                    />
                    <ul v-if="v1.phone" class="parsley-errors-list filled">
                      <li class="parsley-required">Required.</li>
                    </ul>
                  </div>
                </div>
                <div class="form-group d-flex align-items-center my-2">
                  <label class="col-12 col-sm-5 col-form-label text-sm-right"
                    >Address</label
                  >
                  <div class="col-10 col-sm-7 col-lg-7">
                    <input
                      class="form-control"
                      type="text"
                      disabled
                      placeholder="Enter Phone Number"
                      v-model.number="userDetails.address"
                    />
                    <ul v-if="v1.address" class="parsley-errors-list filled">
                      <li class="parsley-required">Required.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="form-group d-flex align-items-center my-1">
                  <label class="col-12 col-sm-5 col-form-label text-sm-right "
                    >Select Customer</label
                  >
                  <div class="col-10 col-sm-7 col-lg-7">
                    <select2
                      placeholder="Select"
                      v-model="userDetails.customerID"
                      @input="getuserByID($event, 'Existing Customer')"
                    >
                      <option selected disabled>Select</option>
                      <option
                        v-for="customer in userOptions"
                        :key="customer.id"
                        :value="customer.id"
                      >
                        {{ customer.value }}
                      </option>
                    </select2>
                    <ul v-if="v1.userType" class="parsley-errors-list filled">
                      <li class="parsley-required">Required.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- EXISTING STAFF -->
            <div v-if="displayForm.existingStaf">
              <div v-if="displayForm.staff">
                <div class="form-group d-flex align-items-center my-2">
                  <label class="col-12 col-sm-5 col-form-label text-sm-right"
                    >Enter Customer Name</label
                  >
                  <div class="col-10 col-sm-7 col-lg-7">
                    <input
                      class="form-control"
                      type="text"
                      disabled
                      placeholder="Enter customer name"
                      v-model.number="userDetails.name"
                    />
                    <ul v-if="v1.name" class="parsley-errors-list filled">
                      <li class="parsley-required">Required.</li>
                    </ul>
                  </div>
                </div>
                <div class="form-group d-flex align-items-center my-2">
                  <label class="col-12 col-sm-5 col-form-label text-sm-right"
                    >Email address</label
                  >
                  <div class="col-10 col-sm-7 col-lg-7">
                    <input
                      class="form-control"
                      type="email"
                      disabled
                      placeholder="Enter email"
                      v-model.number="userDetails.email"
                    />
                    <ul v-if="v1.email" class="parsley-errors-list filled">
                      <li class="parsley-required">Required.</li>
                    </ul>
                  </div>
                </div>
                <div class="form-group d-flex align-items-center my-2">
                  <label class="col-12 col-sm-5 col-form-label text-sm-right"
                    >Phone Number</label
                  >
                  <div class="col-10 col-sm-7 col-lg-7">
                    <input
                      class="form-control"
                      type="number"
                      disabled
                      placeholder="Enter Phone Number"
                      v-model.number="userDetails.phone"
                    />
                    <ul v-if="v1.phone" class="parsley-errors-list filled">
                      <li class="parsley-required">Required.</li>
                    </ul>
                  </div>
                </div>
                <div class="form-group d-flex align-items-center my-2">
                  <label class="col-12 col-sm-5 col-form-label text-sm-right"
                    >Address</label
                  >
                  <div class="col-10 col-sm-7 col-lg-7">
                    <input
                      class="form-control"
                      type="text"
                      disabled
                      placeholder="Enter Phone Number"
                      v-model.number="userDetails.address"
                    />
                    <ul v-if="v1.address" class="parsley-errors-list filled">
                      <li class="parsley-required">Required.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="form-group d-flex align-items-center my-1">
                  <label class="col-12 col-sm-5 col-form-label text-sm-right "
                    >Select Staff</label
                  >
                  <div class="col-10 col-sm-7 col-lg-7">
                    <select2
                      placeholder="Select a staff"
                      v-model="userDetails.userID"
                      @input="getuserByID($event, 'Existing Staff')"
                    >
                      <option selected disabled>Select</option>
                      <option
                        v-for="staff in userOptions"
                        :key="staff.id"
                        :value="staff.id"
                      >
                        {{ staff.value }}
                      </option>
                    </select2>
                    <ul v-if="v1.userType" class="parsley-errors-list filled">
                      <li class="parsley-required">Required.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- SELECT WAREHOUSE -->
            <div v-if="displayForm.warehouse">
              <div class="form-group d-flex align-items-center my-1">
                <label class="col-12 col-sm-5 col-form-label text-sm-right "
                  >Select Warehouse</label
                >
                <div class="col-10 col-sm-7 col-lg-7">
                  <select2
                    id="wareHouse"
                    placeholder="Select a warehouse"
                    @input="getSpecificSubLocation($event)"
                    v-model="userDetails.wareHouse"
                  >
                    <option selected disabled value="">Select</option>
                    <option
                      v-for="warehouse in warehouseList"
                      :key="warehouse.id"
                      :value="warehouse.id"
                    >
                      {{ warehouse.value == null ? "N/A" : warehouse.value }}
                    </option>
                  </select2>
                  <ul v-if="v1.userType" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group d-flex align-items-center my-1">
                <label class="col-12 col-sm-5 col-form-label text-sm-right "
                  >Select Storage Area</label
                >
                <div class="col-10 col-sm-7 col-lg-7">
                  <select2
                    id="storageArea"
                    class="form-control"
                    placeholder="Select a storage area"
                    v-model="userDetails.storageArea"
                    @input="getProducts($event)"
                  >
                    <option selected disabled value="">Select</option>
                    <option
                      v-for="storageArea in storageAreaList"
                      :key="storageArea.id"
                      :value="storageArea.id"
                    >
                      {{ storageArea.name == null ? "N/A" : storageArea.name }}
                    </option>
                  </select2>
                  <ul v-if="v1.userType" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- ENTER PRODUCT TO CREATE A NEW STOCK TRANSFER -->
            <div v-if="displayForm.product">
              <div class="form-group d-flex align-items-center my-1">
                <label class="col-12 col-sm-5 col-form-label text-sm-right "
                  >Select Product</label
                >
                <div class="col-10 col-sm-7 col-lg-7">
                  <select2
                    class="form-control"
                    id="products"
                    placeholder="Select a product"
                    @input="getproductQty($event)"
                    v-model="userDetails.productID"
                  >
                    <option selected disabled value="">Select a product</option>
                    <option
                      v-for="product in productList"
                      :key="product.id"
                      :value="product.id"
                    >
                      {{ product.value == null ? "N/A" : product.value }}
                    </option>
                  </select2>
                  <ul v-if="v1.productID" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group d-flex align-items-center my-2">
                <label class="col-12 col-sm-5 col-form-label text-sm-right"
                  >Available Quantity</label
                >
                <div class="col-10 col-sm-7 col-lg-7">
                  <input
                    disabled
                    class="form-control"
                    type="text"
                    placeholder="Enter customer available quantity"
                    v-model.number="userDetails.availableQty"
                  />
                  <ul v-if="v1.availableQty" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group d-flex align-items-center my-2">
                <label class="col-12 col-sm-5 col-form-label text-sm-right"
                  >Enter Quantity you want to issue</label
                >
                <div class="col-10 col-sm-7 col-lg-7">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Enter customer desired quanntity"
                    v-model.number="userDetails.desiiredQty"
                  />
                  <ul v-if="v1.desiiredQty" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-outline-seconndary btn-no-border text-danger px-1 "
              type="button"
              data-dismiss="modal"
              @click.prevent="cancelissuedStock"
            >
              Cancel
            </button>
            <button
              v-if="!displayForm.product"
              style="width: 160px"
              :disabled="isBusy || validateProceed()"
              class="btn btn-primary"
              type="button"
              @click.prevent="switchForm"
            >
              <span v-if="isBusy">Busy...</span>
              <span v-else>Proceed</span>
            </button>
            <button
              v-else
              style="width: 160px"
              :disabled="isBusy"
              class="btn btn-primary"
              type="button"
              @click.prevent="updateAddTobucket"
            >
              <span v-if="isBusy">Busy...</span>
              <span v-else>{{ bucketEdiitMode ? "Update" : "Add" }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-successfull-returns"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <strong>Customer Returns</strong>
            </h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              @click="cancel"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <p class="text-center font-bold">
              Your stock has being issued successfully
            </p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" type="button">
              <span> Done </span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- FILTER -->

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-filter"
      role="dialog"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Filter</h3>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Issue Number</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Enter Issue Number"
                  v-model="filter.IssueNumber"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >IssuedToName</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Issued to name"
                  v-model="filter.IssuedToName"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Status</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select2 v-model="filter.Status" placeholder="Select">
                  <option
                    v-for="st in statusOptions"
                    :key="st.id"
                    :value="st.id"
                  >
                    {{ st.value }}
                  </option>
                </select2>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Date range</label
              >
              <div class="col-12 col-sm-4 col-lg-4">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Select a date"
                  v-model="filter.dateStart"
                />
              </div>
              <div class="col-12 col-sm-4 col-lg-4">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Select a date"
                  v-model="filter.dateEnd"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button>
            <button
              style="width: 100px"
              class="btn btn-primary md-close"
              type="button"
              @click="getIssuedStock"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Connfirmation Modal -->
    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-action-res"
      role="dialog"
    >
      <div class="modal-dialog  modal-dialog-centered sm-modal">
        <div class="modal-content p-1">
          <div class="modal-body text-center">
            <p class="text-center">
              Are you sure you want to <b>{{ action }}</b> this transaction?
            </p>
            <div class="d-flex justify-content-between">
              <button
                class="btn btn-outline-seconndary btn-no-border text-danger px-1"
                type="button"
                @click.prevent="closeDeleteReverseModal"
              >
                No, I don't
              </button>
              <button
                :disabled="isBusyModal"
                class="btn btn-outline-dark btn-no-border px-1"
                type="button"
                @click.prevent="issueStockAction(itemID)"
              >
                <span v-if="isBusy">Busy...</span>
                <span v-else>Yes, I do</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import Select2 from "@/components/Select2";
import { helper } from "@/helper";
import SystemPermissions from "@/permissions";
import moment from "moment";
import GeneralDeleteCaution from "../components/GeneralDeleteCaution.vue";
import GeneralDeleteMessage from "../components/GeneralDeleteMessage.vue";
import Pagination from "../components/Pagination.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "IssuedStock",
  components: {
    Select2,
    GeneralDeleteCaution,
    GeneralDeleteMessage,
    Pagination,
  },
  data() {
    return {
      filter: {
        IssueNumber: "",
        IssuedToName: "",
        Status: 0,
        dateStart: "",
        dateEnd: "",
        IsGlobal: false,
      },
      action: "",
      itemID: "",
      isBusy: false,
      isBusyModal: false,
      count: 0,
      returnCount: 0,
      page: 0,
      pageNumber: 0,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
      isGlobal: false,
      endDate: "",
      startDate: "",
      IssuedToName: "",
      Status: 0,
      IssueNumber: "",
      issuedStockList: [],
      warehouseList: [],
      storageAreaList: [],
      subLocation: [],
      productList: [],
      issuedStockBucketList: [],
      showStockBucketTable: false,
      warehouse: "",
      StorageArea: "",
      maxDate: "",
      userType: "",
      credentialValidation: {
        phone: false,
        phoneValid: false,
      },
      userTypeOptiions: [
        { id: 0, value: "New Customer" },
        { id: 1, value: "Existing Customer" },
        { id: 2, value: "Existing Staff" },
      ],
      statusOptions: [
        { id: 0, value: "Issued" },
        { id: 1, value: "Reversed" },
      ],
      userOptions: [],
      newCustomer: {
        name: "",
        email: "",
        phone: "",
        address: "",
      },
      issueSttockBucket: {
        issueToId: "",
        issuedDate: "",
        details: [],
      },
      displayForm: {
        userType: false,
        newCustomer: false,
        existingCustomer: false,
        existingStaf: false,
        customer: false,
        staff: false,
        warehouse: false,
        product: false,
      },
      userDetails: {
        userID: "",
        name: "",
        role: "",
        email: "",
        phone: "",
        address: "",
        wareHouse: "",
        storageArea: "",
        productID: "",
        availableQty: "",
        desiiredQty: "",
        staffID: "",
      },

      v1: {
        name: false,
        email: false,
        phone: false,
        address: false,
        wareHouse: false,
        storageArea: false,
        productID: false,
        availableQty: false,
        desiiredQty: false,
      },

      v: {
        userType: false,
      },
      bucketEdiitMode: false,
      bucketEditDataID: "",
      countryCodes: ["234"],
      selectedCountryCode: "234",
      mobileNumber: "",
      validationError: "",
    };
  },
  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
    formattedMobileNumber() {
      let number = this.mobileNumber;
      if (number.startsWith("0")) {
        number = number.slice(1);
      }
      return this.selectedCountryCode + number;
    },
  },
  created() {
    this.getLocations();
    this.getSubLocations();
    this.getIssuedStock();
  },
  mounted() {
    this.maxDate = helper.preventFutureDateSelection();
  },

  methods: {
    validateMobileNumber() {
      this.validationError = "";
      const regex = /^[0-9]*$/;
      if (!regex.test(this.mobileNumber)) {
        this.validationError = "Please enter a valid mobile number";
      } else if (this.mobileNumber.length < 10) {
        this.validationError = "Mobile number must be at least 10 digits.";
      } else {
        this.$emit("update:mobileNumber", this.formattedMobileNumber);
      }
    },
    ssuccessModal() {
      $("#mod-successfull-returns").modal("show");
    },
    cancel() {
      $("#mod-edit").modal("hide");
    },

    deleteReverseModal(id, action) {
      this.action = action;
      $("#mod-action-res").modal("show");
      this.itemID = id;
    },
    closeDeleteReverseModal() {
      $("#mod-action-res").modal("hide");
      this.itemID = "";
      this.action = "";
    },
    reset() {
      this.filter = {
        IssueNumber: "",
        IssuedToName: "",
        Status: null,
        dateStart: "",
        dateEnd: "",
        IsGlobal: false,
      };
    },
    initializeIssueStock() {
      this.displayForm.userType = true;
      $("#mod-issue-stock").modal("show");
    },
    viewFilter() {
      this.page = 0;
      $("#mod-filter").modal("show");
    },
    goBack() {
      this.issuedStockBucketList.length = 0;
      this.cancelissuedStock();
    },

    cancelModal() {
      (this.userDetails.name = ""),
        (this.userDetails.role = ""),
        (this.userDetails.email = ""),
        (this.userDetails.phone = ""),
        (this.userDetails.address = ""),
        (this.userDetails.wareHouse = ""),
        (this.userDetails.storageArea = ""),
        (this.userDetails.productID = ""),
        (this.userDetails.availableQty = ""),
        (this.userDetails.desiiredQty = ""),
        $("#mod-issue-stock").modal("hide");
      this.displayForm.userType = false;
      this.displayForm.existingStaf = false;
      this.displayForm.existingCustomer = false;
      this.displayForm.warehouse = false;
      this.displayForm.product = false;
      this.displayForm.customer = false;
      this.displayForm.staff = false;
      this.bucketEdiitMode = false;
    },

    //SET USER
    setUsertype(event) {
      if (event.target.value == "Existing Customer") {
        this.getUserData(event.target.value);
        this.userType = event.target.value;
      } else if (event.target.value == "Existing Staff") {
        this.getUserData(event.target.value);
        this.userType = event.target.value;
      } else if (event.target.value == "New Customer") {
        this.createForm();
        this.userType = event.target.value;
        this.displayForm.newCustomer = true;
      }
    },

    createForm(val) {
      if (val == "Existing Customer") {
        this.displayForm.existingStaf = false;
        this.displayForm.existingCustomer = true;
        this.displayForm.newCustomer = false;
      } else if (val == "Existing Staff") {
        this.displayForm.existingStaf = true;
        this.displayForm.existingCustomer = false;
        this.displayForm.newCustomer = false;
      } else {
        this.displayForm.existingStaf = false;
        this.displayForm.existingCustomer = false;
        this.displayForm.newCustomer = true;
      }
    },
    async issueStock() {
      this.isBusy = true;
      let data = this.issueSttockBucket;
      await this.$http
        .post("/StockIssues", data)
        .then(function(res) {
          this.$toast.success(res.body, {
            icon: false,
            rtl: false,
          });
          this.isBusy = false;
          this.showStockBucketTable = false;
          this.cancelissuedStock();
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },

    goToUrl(url, id) {
      let routeData = this.$router.resolve({
        path: url + id,
      });
      window.open(routeData.href, "_blank");
    },

    cancelissuedStock() {
      this.issuedStockList = [];
      this.userDetails.userID = "";
      this.issueSttockBucket.issueToId = "";
      this.issueSttockBucket.issuedDate = "";
      this.issueSttockBucket.details = [];
      this.showStockBucketTable = false;
      this.userDetails = {
        userID: "",
        name: "",
        role: "",
        email: "",
        phone: "",
        address: "",
        wareHouse: "",
        storageArea: "",
        productID: "",
        availableQty: "",
        desiiredQty: "",
        staffID: "",
      };
      this.displayForm.userType = false;
      this.displayForm.existingStaf = false;
      this.displayForm.existingCustomer = false;
      this.displayForm.warehouse = false;
      this.displayForm.newCustomer = false;
      this.displayForm.customer = false;
      this.displayForm.staff = false;
      this.displayForm.product = false;
      this.getLocations();
      this.getSubLocations();
      this.getIssuedStock();
    },

    validateProceed() {
      if (this.displayForm.userType === true && !this.userType) {
        if (
          this.displayForm.existingCustomer === false &&
          !this.displayForm.newCustomer &&
          !this.displayForm.existingStaf
        ) {
          // dont proceed
          return true;
        }
        return false;
      } else {
        if (this.displayForm.newCustomer) {
          if (!this.newCustomer.name || !this.newCustomer.email) {
            return true;
          } else {
            return false;
          }
        }
        return false;
      }
    },

    switchForm() {
      if (this.displayForm.warehouse) {
        this.displayForm.product = true;
      } else if (this.displayForm.newCustomer == true) {
        this.registerUser();
      } else {
        this.displayForm.userType = false;
        this.displayForm.existingStaf = false;
        this.displayForm.existingCustomer = false;
        this.displayForm.newCustomer = false;
        this.displayForm.warehouse = true;
      }
    },
    async registerUser() {
      this.isBusy = true;
      let data = {
        emailAddress: this.newCustomer.email,
        phoneNumber: this.formattedMobileNumber,
        userType: "customer",
        name: this.newCustomer.name,
        role: "customer",
        parentId: this.user.parentId,
        deliveryAddress: this.newCustomer.address,
      };
      await this.$http
        .post(`/Users/inviteCustomer`, data)
        .then(function(res) {
          if (res.ok) {
            this.userDetails.userID = res.body.id;
            this.newCustomer.name = res.body.text;
            this.displayForm.newCustomer = false;
            this.switchForm();
          }
        })
        .catch(function() {
          this.isBusy = false;
        });
    },

    addNewProduct() {
      this.displayForm.warehouse = true;
      (this.userDetails.wareHouse = ""),
        (this.userDetails.storageArea = ""),
        (this.userDetails.productID = ""),
        (this.userDetails.availableQty = ""),
        (this.userDetails.desiiredQty = ""),
        $("#mod-issue-stock").modal("show");
    },

    //Get UserData
    async getUserData(val) {
      this.isBusy = true;
      await this.$http
        .get(
          `/Users/listNoPagging?isCustomer=${
            val == "Existing Customer" ? true : false
          }`
        )
        .then(function(res) {
          if (res.ok) {
            this.userType = val;
            this.createForm(val);
            let data = res.body;
            this.userOptions = data
              .filter((user) => user.name !== null)
              .map((x) => ({
                id: x.id,
                value: x.name,
              }));
            this.isBusy = false;
          }
        })
        .catch(function() {
          this.isBusy = false;
        });

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },

    //Get User ID
    async getuserByID(id, user) {
      if (id != "") {
        this.isBusy = true;
        await this.$http
          .get(`/Users/getById/${id}`)
          .then(function(res) {
            this.userDetails.name =
              res.body.displayName == null
                ? "Data not found"
                : res.body.displayName;
            this.userDetails.email =
              res.body.emailAddress == null
                ? "Data not found"
                : res.body.emailAddress;
            this.userDetails.phone =
              res.body.phoneNumber == null
                ? "Data not found"
                : res.body.phoneNumber;
            this.userDetails.address =
              res.body.deliveryAddress == null
                ? "Data not found"
                : res.body.deliveryAddress;
            user == "Existing Customer"
              ? (this.displayForm.customer = true)
              : (this.displayForm.staff = true);
            this.userDetails.role =
              res.body.role == null ? "Data not found" : res.body.role;
            this.userDetails.staffID =
              res.body.staffId == null ? "Data not found" : res.body.staffId;
            this.userDetails.userID =
              res.body.id == null ? "Data not found" : res.body.id;
            this.isBusy = false;
          })
          .catch(function() {
            this.isBusy = false;
          });
        this.isBusy = false;
      }
    },

    //Get filtered subLocation

    getSpecificSubLocation(val) {
      this.storageAreaList = this.subLocation.filter(
        (x) => x.location.id == val
      );
    },

    //Get Issued Stock
    async getIssuedStock() {
      this.isBusy = true;
      await this.$http
        .get(
          `/StockIssues?pageNumber=${this.pageNumber}&pageSize=${
            this.pageSize
          }&startDate=${
            this.filter.dateStart == "" ? "" : this.filter.dateStart
          }&endDate=${
            this.filter.dateEnd == "" ? "" : this.filter.dateEnd
          }&IsGlobal=${!this.isGlobal}&IssuedToName=${
            this.filter.IssuedToName == "" ? "" : this.filter.IssuedToName
          }&Status=${
            this.filter.Status == "" ? "" : this.filter.Status
          }&IssueNumber=${
            this.filter.IssueNumber == "" ? "" : this.filter.IssueNumber
          }`
        )
        .then(function(res) {
          this.issuedStockList = res.body.data;
          this.pageNumber = res.body.pageNumber;
          this.pageSize = res.body.pageSize;
          this.returnCount = res.body.count;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function() {});
      this.reset();
      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },

    addToIssuesBucket() {
      if (!this.showStockBucketTable) {
        this.showStockBucketTable = true;
      }
      let data1 = {
        quantity: this.userDetails.desiiredQty,
        subLocationId: this.userDetails.storageArea,
        productId: this.userDetails.productID,
      };

      this.issueSttockBucket.details.push(data1);
      if (this.issueSttockBucket.issueToId == "") {
        this.issueSttockBucket.issueToId = this.userDetails.userID;
      }
      this.issueSttockBucket.issuedDate = new Date().toISOString();
      let data = {
        product: this.productList
          .filter((x) => x.id == this.userDetails.productID)
          .map((y) => y.value)[0],
        issuedTo:
          this.userOptions
            .filter((x) => x.id == this.userDetails.userID)
            .map((y) => y.value)[0] +
          "(" +
          this.userDetails.role +
          ")",
        qtyIssued: this.userDetails.desiiredQty,
        valueOfStock:
          this.productList
            .filter((x) => x.id == this.userDetails.productID)
            .map((y) => y.price)[0] * this.userDetails.desiiredQty,
        wareHouse: this.warehouseList
          .filter((x) => x.id == this.userDetails.wareHouse)
          .map((y) => y.value)[0],
        storageArea: this.storageAreaList
          .filter((x) => x.id == this.userDetails.storageArea)
          .map((y) => y.name)[0],
        wareHouseID: this.userDetails.wareHouse,
        productID: this.userDetails.productID,
        storageAreaID: this.userDetails.storageArea,
        aqty: this.userDetails.availableQty,
        newCustomer: this.newCustomer.name + "(" + "Customer" + ")",
      };
      (this.wareHouse = ""),
        (this.storageArea = ""),
        (this.productID = ""),
        (this.availableQty = ""),
        (this.desiiredQty = ""),
        $("#mod-issue-stock").modal("hide");
      this.issuedStockBucketList.push(data);
      this.issuedStockBucketList.forEach((o, i) => (o.id = i + 1));
    },

    updateAddTobucket() {
      if (this.bucketEdiitMode) {
        this.updateIssuebucketData();
        return;
      } else {
        this.addToIssuesBucket();
      }
    },
    updateIssuebucketData() {
      if (this.bucketEditDataID) {
        let objIndex = this.issuedStockBucketList.findIndex(
          (x) => x.id == this.bucketEditDataID
        );
        (this.issuedStockBucketList[objIndex].product = this.productList
          .filter((x) => x.id == this.userDetails.productID)
          .map((y) => y.value)[0]),
          (this.issuedStockBucketList[objIndex].issuedTo =
            this.userOptions
              .filter((x) => x.id == this.userDetails.userID)
              .map((y) => y.value)[0] +
            "(" +
            this.userDetails.role +
            ")"),
          (this.issuedStockBucketList[
            objIndex
          ].qtyIssued = this.userDetails.desiiredQty),
          (this.issuedStockBucketList[objIndex].valueOfStock =
            this.productList
              .filter((x) => x.id == this.userDetails.productID)
              .map((y) => y.price)[0] * this.userDetails.desiiredQty),
          (this.issuedStockBucketList[
            objIndex
          ].wareHouse = this.warehouseList
            .filter((x) => x.id == this.userDetails.wareHouse)
            .map((y) => y.value)[0]),
          (this.issuedStockBucketList[
            objIndex
          ].storageArea = this.storageAreaList
            .filter((x) => x.id == this.userDetails.storageArea)
            .map((y) => y.name)[0]),
          (this.issuedStockBucketList[
            objIndex
          ].wareHouseID = this.userDetails.wareHouse),
          (this.issuedStockBucketList[
            objIndex
          ].productID = this.userDetails.productID),
          (this.issuedStockBucketList[
            objIndex
          ].storageAreaID = this.userDetails.storageArea),
          (this.issuedStockBucketList[
            objIndex
          ].aqty = this.userDetails.availableQty),
          $("#mod-issue-stock").modal("hide");
        this.bucketEdiitMode = false;
        return;
      }
    },

    editProduct(id) {
      this.bucketEdiitMode = !this.bucketEdiitMode;
      let editData = this.issuedStockBucketList.filter(
        (x) => x.productID == id
      );
      this.viewRecord(editData);
    },

    viewRecord(val) {
      this.bucketEditDataID = val[0].id;
      this.userDetails.productID = val[0].productID;
      this.userDetails.availableQty = val[0].aqty;
      this.userDetails.desiiredQty = val[0].qtyIssued;
      this.userDetails.wareHouse = val[0].wareHouseID;
      this.userDetails.storageArea = val[0].storageAreaID;
      $("#mod-issue-stock").modal("show");
    },

    deleteReturns(id) {
      this.issuedStockBucketList.splice(id, 1);
    },

    getProducts(val) {
      if (val != "") {
        this.isBusy = true;
        this.$http
          .get(
            `/Inventories/availableProducts?locationId=${this.userDetails.wareHouse}&subLocationId=${val}`
          )
          .then(function(res) {
            this.productList = res.body.map((product) => ({
              id: product.id,
              value: product.name == null ? "N/A" : product.name,
              quantity: product.inventory,
              price: product.price,
              code: product.code,
            }));
            this.isBusy = false;
            // this.displayForm.product = true;
          })
          .catch(function() {
            this.isBusy = false;
          });
      } else {
      }
    },

    getproductQty(val) {
      this.userDetails.availableQty = this.productList
        .filter((x) => x.id == val)
        .map((y) => y.quantity)[0];
    },

    getLocations() {
      var t = "Location";
      var a = true;

      this.$http
        .get(
          "/Locations/getListNoPaging?active=" +
            a +
            "&parentId=" +
            this.user.parentId
        )
        .then(function(res) {
          this.warehouseList = res.body.map((location) => ({
            id: location.id,
            value: location.name == null ? "N/A" : location.name,
          }));
        })
        .catch(function() {});
    },

    getSubLocations() {
      var t = "Location";
      var a = true;

      this.$http
        .get(
          "/SubLocations/getListNoPaging?active=" +
            a +
            "&parentId=" +
            this.user.parentId +
            "&locationId="
        )
        .then(function(res) {
          this.subLocation = res.body;
        })
        .catch(function() {});
    },
    issueStockAction(id) {
      {
        this.action == "Delete" ? this.deleteStock(id) : this.deleteStock(id);
      }
    },
    async reverseStock(id) {
      this.isBusy = true;
      await this.$http
        .put(`/StockIssues/${id}/Reverse`)
        .then(function(res) {
          this.$toast.success(res.body, {
            icon: false,
            rtl: false,
          });
          this.isBusy = false;
          this.closeDeleteReverseModal();
          this.getIssuedStock();
        })
        .catch(function() {
          this.isBusy = false;
          this.closeDeleteReverseModal();
        });
    },

    async deleteStock(id) {
      this.isBusy = true;
      await this.$http
        .delete(`/StockIssues/${id}`)
        .then(function(res) {
          this.$toast.success(res.body, {
            icon: false,
            rtl: false,
          });
          this.closeDeleteReverseModal();
          this.isBusy = false;
          this.getIssuedStock();
        })
        .catch(function() {
          this.isBusy = false;
          this.closeDeleteReverseModal();
        });
    },
    gotoPage(page) {
      this.pageNumber = page;
      this.getIssuedStock();
    },
    changeLimit(limit) {
      this.pageSize = limit;
      this.getIssuedStock();
    },
    prev() {
      this.pageNumber -= 1;
      this.getIssuedStock();
    },
    next() {
      this.pageNumber += 1;
      this.getIssuedStock();
    },
    formatDate(date) {
      return moment(date).format("lll");
    },
    formatMoney(val) {
      return helper.formatMoney(val);
    },
    formatNumber(val) {
      return helper.formatNumber(val);
    },
  },
};
</script>

<style scoped>
.lg-modal {
  max-width: 600px !important;
  width: 100%;
}
.sm-modal {
  max-width: 350px !important;
  width: 100%;
}
.btn-no-border {
  border: none !important;
}
.font-bold {
  font-weight: 500;
  color: black;
}

.merchant-login-label {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #18191f;
  padding: 0;
  margin-bottom: 16px;
}

.merchant-input-div {
  border: 1px solid #a6a6a6;
  box-sizing: border-box;
  border-radius: 8px;
  width: 426px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
}

.merchant-input-div:focus-within {
  border: 1px solid #18191f;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
}

.country-code {
  margin: 0 10px 0 0;
}
</style>
