<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-status"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-success">
          <h4 class="modal-title p-1">
            <i class="fa fa-question"></i>
            <strong class="ml-1">Status</strong>
          </h4>
          <button
            type="button"
            class="close md-close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Would you like to save as draft or submit purchase order?</p>
        </div>
        <div
          class="modal-footer d-flex justify-content-center align-items-center"
        >
          <button
            class="btn btn-secondary"
            type="button"
            @click.prevent="onDraftClick"
          >
            Draft
          </button>
          <button
            class="btn btn-success"
            type="button"
            @click.prevent="onSubmitClick"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatusModal",

  methods: {
    onDraftClick() {
      this.$emit("proceed", "draft");
    },

    onSubmitClick() {
      this.$emit("proceed", "submitted");
    },
  },
};
</script>

<style>
</style>