<template>
  <div class="modal fade" id="mod-add-product" role="dialog">
    <div
      class="modal-dialog modal-lg m-0 p-0 absolute top-0 right-0 flex justify-end items-end"
    >
      <!-- {{ addedProducts }} -->
      <div
        class="modal-content rounded-none bg-[#FCFCFD] py-[40px] px-[20px] min-h-screen"
      >
        <div class="flex items-start justify-between p-0 m-0">
          <h4
            class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[24px]"
          >
            New Products
          </h4>
          <button
            class="text-2xl text-[#353535] outline-none focus:outline-none"
            type="button"
            @click="CloseModal"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body p-0 m-0">
          <div class="pb-[16px]">
            <label class="text=[#353535] font-bold text-[14px] leading-[20px]"
              >Search for Products</label
            >
            <div>
              <input
                type="text"
                v-model="product_code"
                @input="resetErrMsg"
                class="border-[#d0d5dd] bg-transparent border-solid border-[1.5px] p-[14px] rounded-[8px] w-full outline-none"
                placeholder="Enter customer code here"
              />
            </div>
          </div>
          <div
            v-for="(i, index) in results"
            :key="index"
            class="w-full flex pb-[24px] justify-between items-center"
          >
            <div>
              <p class="text-[14px] font-normal text-[#353535] leading-[24px]">
                {{ i.productCode }}
              </p>
              <p class="text-[12px] font-normal text-[#353535] leading-[16px]">
                {{ i.productDescription }}
              </p>
            </div>

            <i
              @click="ShowConfirmAddModal(i, index)"
              class="fa-regular cursor-pointer rounded-full hover:shadow-md font-bold text-[14px] text-[#353535] fa-plus border-[1.5px] border-[#353535] p-[4px]"
            ></i>
          </div>

          <div class="flex items-center gap-[8px] w-full max-w-[500px] w-full">
            <button
              class="bg-transparent border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[16px] sm:px-[24px] text-[14px] md:text-[16px] rounded-[8px] font-medium leading-[20px] w-full outline-none focus:outline-none"
              type="button"
              @click="CloseModal"
            >
              Cancel
            </button>

            <button
              @click="Search"
              class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[16px] sm:px-[24px] text-[14px] md:text-[16px] rounded-[8px] text-white font-medium leading-[20px] w-full outline-none focus:outline-none"
              type="button"
            >
              <i v-if="isSearching" class="fas fa-spinner fa-spin mr-2"></i>

              {{ isSearching ? "Busy..." : "Search" }}
            </button>
          </div>
          <div
            v-if="errorMessage.status"
            class="text-[#353535] text-center leading-[24px] text-[14px] font-normal my-[16px]"
          >
            {{ errorMessage.message }}
          </div>
          <div v-if="addedProducts.length > 0" class="w-full mt-[40px]">
            <div>
              <p
                class="text-[14px] font-semibold text-[#353535] leading-[24px]"
              >
                List Of Products To Be Add
              </p>
            </div>
            <div class="mt-6 ring-1 w-full ring-gray-300">
              <table class="w-full table h-auto">
                <thead class="lg:table-header-group border-b-2 border-solid">
                  <tr class="bg-[#fbfbfb] w-full">
                    <td class="px-6 py-[12px]">
                      <span
                        class="text-[14px] font-medium leading-[24px] text-[#353535]"
                      >
                        Product Name</span
                      >
                    </td>
                  </tr>
                </thead>

                <tbody class="w-full">
                  <tr
                    class="w-full"
                    v-for="(i, index) in addedProducts"
                    :key="index"
                  >
                    <td
                      class="py-[12px] w-full whitespace-nowrap border-b flex flex-col md:flex-row items-center justify-between"
                    >
                      <div class="w-full md:w-2/4">
                        <p
                          class="text-[14px] font-normal text-[#353535] leading-[24px]"
                        >
                          {{ i.productCode }}
                        </p>
                        <p
                          class="text-[12px] font-normal text-[#353535] leading-[16px]"
                        >
                          {{ i.productDescription }}
                        </p>
                      </div>
                      <div class="w-full md:w-2/4">
                        <label
                          class="text=[#353535] font-medium text-[14px] leading-[20px]"
                          >Select Category</label
                        >
                        <br />
                        <div>
                          <select
                            @change="onCategoryChange(index, i.categoryId)"
                            v-model="i.categoryId"
                            class="border-[1.5px] bg-transparent border-[#000] border-solid border py-[12px] px-[14px] rounded-[8px] w-full outline-none"
                          >
                            <option
                              :value="o.id"
                              v-for="o in categories"
                              :key="o"
                            >
                              {{ o.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="flex mt-[16px] items-center gap-[8px] w-full">
              <button
                @click="addProducts"
                class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[16px] sm:px-[24px] text-[14px] md:text-[16px] rounded-[8px] text-white font-medium leading-[20px] w-[50%] md:w-[30%] outline-none focus:outline-none"
                type="button"
              >
                <i v-if="isBusy" class="fas fa-spinner fa-spin mr-2"></i>

                {{
                  isSearching
                    ? "Busy..."
                    : `Add ${addedProducts.length > 1 ? "All" : ""}`
                }}
              </button>
            </div>
          </div>
          <!-- once we hit final adding -->
          <div v-if="allProducts.length > 0" class="w-full mt-[40px]">
            <div>
              <p
                class="text-[14px] font-semibold text-[#353535] leading-[24px]"
              >
                List Of Added Products
              </p>
            </div>
            <div class="mt-6 ring-1 w-full ring-gray-300">
              <table class="w-full">
                <thead class="lg:table-header-group border-b-2 border-solid">
                  <tr class="bg-[#fbfbfb] w-full">
                    <td class="px-6 py-[12px]">
                      <span
                        class="text-[14px] font-medium leading-[24px] text-[#353535]"
                      >
                        Product Name</span
                      >
                    </td>
                  </tr>
                </thead>

                <tbody class="divide-y divide-gray-200">
                  <!-- <tr v-for="(i, index) in allProducts" :key="index">
                    <td
                      class="py-[12px] whitespace-nowrap flex items-center justify-between border-b px-6"
                    >
                      <span
                        class="text-[14px] font-medium leading-[24px] text-[#353535]"
                        >{{
                          i.businessName ? i.businessName : i.customerCode
                        }}</span
                      >
                      <div
                        class="flex items-center gap-2"
                        :class="{
                          'bg-[rgba(0, 216, 162, 0.1)]':
                            i.isSuccessful && i.isRegistered,
                          'bg-[rgba(239, 82, 83, 0.1)]': !i.isSuccessful,
                          'bg-[rgba(255, 150, 0, 0.1)]':
                            i.isSuccessful && !i.isRegistered,
                        }"
                      >
                        <div
                          class="h-3 w-3 rounded-[2px]"
                          :style="{
                            backgroundColor:
                              i.isSuccessful && i.isRegistered
                                ? '#16a249'
                                : !i.isSuccessful
                                ? '#EF5253'
                                : '#FD881E',
                          }"
                        ></div>
                        <span
                          class="text-[12px] font-normal leading-[16.94px]"
                          :class="{
                            'text-[#16a249]': i.isSuccessful && i.isRegistered,
                            'text-[#EF5253]': !i.isSuccessful,
                            'text-[#FD881E]': i.isSuccessful && !i.isRegistered,
                          }"
                        >
                          {{
                            i.isSuccessful && i.isRegistered
                              ? "Added"
                              : !i.isSuccessful
                              ? "Failed"
                              : "Pending Invite"
                          }}
                        </span>
                      </div>
                    </td>
                  </tr> -->
                </tbody>
              </table>
            </div>
            <button
              class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[16px] sm:px-[24px] text-[14px] md:text-[16px] rounded-[8px] text-white font-medium leading-[20px]  w-[50%] md:w-[30%]  mt-[16px]"
              type="button"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    "SearchProduct",
    "results",
    "addedProducts",
    "allProducts",
    "addProducts",
    "isSearching",
    "ShowConfirmAddModal",
    "resetErrMsg",
    "errorMessage",
    "mappedResults",
    "categories",
    "isBusy",
  ],
  data: () => ({
    product_code: "",
  }),
  methods: {
    async Search() {
      await this.SearchProduct(this.product_code);
    },
    onCategoryChange(index, categoryId) {
      this.$emit("update-category", { index, categoryId });
    },
    CloseModal() {
      this.product_code = "";
      $("#mod-add-product").modal("hide");
    },
    resetcode() {
      this.product_code = "";
    },
  },
};
</script>
