<template>
  <div class="modal fade " id="mod-delete-caution" role="dialog">
    <div class="modal-dialog mx-auto">
      <div class="modal-content p-[24px] bg-[#fff]">
        <div class="modal-body m-0 p-0">
          <h4
            class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
          >
            Caution
          </h4>
          <p class="text-[#353535] text-[16px] font-normal leading-[20px]">
            Price option will be removed from Groups and Distributors.
          </p>
        </div>
        <div class="flex items-center gap-[8px] mt-[16px]">
          <button
            class="border-[##96a0b5] border-solid border-[1.5px] py-[16px] md:px-[24px] rounded-[8px] rounded-[8px] text-[14px] md:text-[16px] font-medium leading-[20px] w-full"
            type="button"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            class="bg-[#DC2828] border-[#DC2828] border-solid border py-[16px] md:px-[24px] rounded-[8px] rounded-[8px] text-white text-[14px] md:text-[16px] font-medium leading-[20px] w-full"
            type="button"
            @click.prevent="accept"
          >
            Remove Option
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RRPDeleteCaution",
  props: ["id"],
  methods: {
    accept() {
      this.$emit("proceed", this.id);
    },
  },
};
</script>

<style></style>
