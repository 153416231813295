<template>
	<div id="printingPosInvoice" style="width: 200mm; display: none; margin:auto">
		<div class="card-body bg-white text-dark p-0" id="wekurnectPos" style=" width: 200mm">
			<div class="container my-2" id="posPrintWekurnect">
				<div class="row" style="width: 200mm;">
					<div class="col pt-3">
						<h3
							style="font-size: 32px; font-weight: 700; color: #373737; line-height: 39px; text-align: center; margin-bottom:50px"
						>
							We-Supply Limited
						</h3>
						<div style="display: flex; justify-content: space-between">
							<div style="font-size: 14px; line-height: 18px; color: black">
								<div>
									<div style="margin-bottom: 14px">
										<b class="h4" style="font-weight: 700;">To: </b>
										<span class="h4" style="font-weight: 700;">{{
											order.maker ? order.maker.displayName : "NA"
										}}</span>
									</div>
									<div style="margin-bottom: 14px">
										<span style=" color: gray;">Number: </span>
										<span>{{
											order.maker
												? order.maker.phoneNumber
													? order.maker.phoneNumber
													: order.maker.emailAddress
												: "NA"
										}}</span>
									</div>
									<p>{{ order.maker ? order.maker.deliveryAddress : "" }}</p>
								</div>
							</div>
							<div style="font-size: 14px; line-height: 18px; color: black">
								<div style="margin-bottom: 14px">
									<b>Date: </b>
									<span style=" color: gray;">{{
										formatDate(order.created)
									}}</span>
								</div>
								<div style="margin-bottom: 14px">
									<b>RN: </b>
									<span style=" color: gray;">{{ order.orderNumber }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="border-bottom: 1px solid #F2F2F2;"></div>
				<div style=" margin-top: 24px">
					<h4 style="text-align: center;margin-bottom: 50px;">
						<strong
							style="font-size: 32px; font-weight: 700; color: #373737; line-height: 39px; text-align: center; margin-bottom:30px"
						>
							Order Summary
						</strong>
					</h4>
					<table style="width: 180mm; table-layout: auto; margin-top: 24px">
						<thead>
							<tr style="font-weight: 700; ">
								<th
									style="border-bottom: 1px solid #F2F2F2; width: 50%; padding-bottom: 16px"
								></th>
								<th
									style="border-bottom: 1px solid #F2F2F2; width: 30%; padding-bottom: 16px; text-align: center"
								></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in order.details" :key="index">
								<td
									style="text-align: start; width: 60%; padding-top: 25px; color: gray; font-size:12px"
								>
									Item Description
								</td>
								<td
									style="text-align: end; width: 50%; padding-top: 26px; color: #373737; font-size:16px; font-weight: 700"
								>
									{{ item.description }}
								</td>
							</tr>
							<tr v-for="(item, index) in order.details" :key="index">
								<td
									style="text-align: start; width: 60%; padding-top: 25px; color: gray; font-size:12px"
								>
									Price (NGN)
								</td>
								<td
									style="text-align: end; width: 50%; padding-top: 26px; color: #373737; font-size:16px; font-weight: 700"
								>
									{{ formatMoney(item.unitPrice) }}
								</td>
							</tr>
							<tr v-for="(item, index) in order.details" :key="index">
								<td
									style="text-align: start; width: 60%; padding-top: 25px; color: gray; font-size:12px"
								>
									Quantity
								</td>
								<td
									style="text-align: end; width: 50%; padding-top: 26px; color: #373737; font-size:16px; font-weight: 700"
								>
									{{ item.quantity }}
								</td>
							</tr>
							<tr v-for="(item, index) in order.details" :key="index">
								<td
									style="text-align: start; width: 60%; padding-top: 25px; color: gray; font-size:12px"
								>
									Amount (NGN)
								</td>
								<td
									style="text-align: end; width: 50%; padding-top: 26px; color: #373737; font-size:16px; font-weight: 700"
								>
									{{
										formatMoney(item.quantity * item.unitPrice - item.discount)
									}}
								</td>
							</tr>
						</tbody>
					</table>
					<p
						style=" color: gray;  text-align: center;font-size: 12px;font-weight: 300;max-width: 500px;margin: 50px auto;"
					>
						Make all transfer to We-Supply Limited - 0009586409 Guaranty Trust Bank. All
						cheques should be made out to We-Supply Limited
					</p>
					<hr style="width: 180mm; margin: 0 0 10px 0" />
					<div>
						<div style="font-size: 14px; textt-center">
							<p
								style="color: gray;  text-align: center;font-size: 12px;font-weight: 300;max-width: 500px;margin: 50px auto;"
							>
								Invoice generated from weKurnect
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from "moment";
	import { helper } from "@/helper";
	export default {
		name: "pos-invoice",
		props: ["order"],
		components: {},

		data() {
			return {};
		},

		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},
			totalPayment: function() {
				var myTotal = 0;

				if (this.order.paymentHistory) {
					for (var i = 0, len = this.order.paymentHistory.length; i < len; i++) {
						myTotal += this.order.paymentHistory[i].paidAmount;
					}
				}

				return myTotal;
			},

			customDiscount: function() {
				let customDiscount = 0;

				const totalDiscountOnProducts = this.order.details.reduce(
					(acc, item) => acc + item.discount,
					0,
				);
				customDiscount = this.order.discount - totalDiscountOnProducts;

				return customDiscount.toFixed(2);
			},

			isExcess: function() {
				const isNegative = Math.sign(this.order.total - this.totalPayment);
				if (isNegative >= 0) return false;
				return true;
			},
		},

		mounted() {
			console.log(this.order);
		},

		methods: {
			formatMoney(val) {
				return helper.formatMoney(val);
			},
			formatDate(date) {
				return moment(date).format("ll");
			},
		},
	};
</script>
