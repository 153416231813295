<template>
  <a
    :class="'dropdown-item text-danger'" style="font-size: 14px;"
    data-toggle="tooltip"
    title="Delete"
    :disabled="deleteLoading"
    @click.prevent="onDeleteClick"
  >
    Delete
  </a>
</template>

<script>
export default {
  name: "DeletePurchaseOrder",
  props: ["purchaseOrder","userType"],

  data() {
    return {
      deleteLoading: false,
    };
  },

  methods: {
    onDeleteClick() {
      this.$emit("showAction", {
        purchaseNumber: this.purchaseOrder.orderNumber,
        action: "delete",
        owner: this.purchaseOrder.id,
      });
    },

    async deletePurchaseOrder() {
      this.deleteLoading = true;
      this.isBusy = true;
      try {
        if(this.userType === "salesAgent"){
          const response = await this.$http.delete(
            "/PurchaseOrders/salesAgentdelete/" + this.purchaseOrder.id
          );
          if (response.ok && response.status === 200) this.$emit("onComplete");

        }else{
          const response = await this.$http.delete(
            "/PurchaseOrders/delete/" + this.purchaseOrder.id
          );
          if (response.ok && response.status === 200) this.$emit("onComplete");

        }
        this.deleteLoading = false;
        this.isBusy = true;
      } catch (error) {
        this.deleteLoading = false;
        this.isBusy = true;
      }
    },
  },
};
</script>

<style>
</style>