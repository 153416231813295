<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-add"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1"><b>Add new member</b></h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
            @click="reset"
          >
            <span class="mdi mdi-close"> </span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Member Type</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <select
                :class="[
                  'form-control',

                  { 'is-invalid': $v.newMember.type.$error },
                ]"
                placeholder="Select an option"
                v-model.trim="$v.newMember.type.$model"
              >
                <option v-for="type in types" :key="type" :value="type">
                  {{ type }}
                </option>
              </select>
              <div class="invalid-feedback" v-if="!$v.newMember.type.required">
                Field is required.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Member Name</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <select
                :class="[
                  'form-control',

                  { 'is-invalid': $v.newMember.memberId.$error },
                ]"
                placeholder="Select an option"
                v-model.trim="$v.newMember.memberId.$model"
              >
                <option v-if="isBusyModal" value="">Loading...</option>
                <option v-for="m in newMembers" :key="m.id" :value="m.id">
                  {{ m.text }}
                </option>
              </select>
              <div
                class="invalid-feedback"
                v-if="!$v.newMember.memberId.required"
              >
                Field is required.
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            style="width: 100px"
            class="btn btn-secondary"
            type="button"
            data-dismiss="modal"
            @click="reset"
          >
            Cancel
          </button>
          <button
            style="width: 130px"
            :disabled="isBusyModal"
            class="btn btn-primary"
            type="button"
            @click.prevent="add"
          >
            <span
              v-if="isMemberLoading || isAdding"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <i class="sr-only">Loading...</i>
            </span>
            <span v-else>Add member</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "AddNewMember",
  props: ["campaignId"],

  data() {
    return {
      isBusyModal: false,
      newMember: {
        type: "",
        memberId: "",
        campaignId: this.campaignId,
      },
      types: [],
      newMembers: [],
      isMemberLoading: false,
      isAdding: false,
    };
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },

  watch: {
    "newMember.type": function (newVal) {
      this.onTypeChange(newVal);
    },
  },

  validations: {
    newMember: {
      type: {
        required,
      },

      memberId: {
        required,
      },
    },
  },

  mounted() {
    this.getTypes();
  },

  methods: {
    getTypes() {
      this.types = ["Group", "Customer"];
    },

    async onTypeChange(type) {
      if (type) {
        switch (type) {
          case "Group":
            this.isBusyModal = true;
            this.isMemberLoading = true;
            try {
              const response = await this.$http.get(
                "/Groups/getItemList/" + this.user.parentId
              );

              if (response.ok) this.newMembers = response.body;
            } catch (error) {}
            this.isBusyModal = false;
            this.isMemberLoading = false;
            break;
          case "Merchant":
            this.isBusyModal = true;
            this.isMemberLoading = true;

            try {
              const response = await this.$http.get(
                "/Accounts/getItemList?parentId=" +
                  this.user.parentId +
                  "&accountType=" +
                  this.user.type
              );

              if (response.ok) this.newMembers = response.body;
            } catch (error) {}
            this.isBusyModal = false;
            this.isMemberLoading = false;
            break;
          case "Customer":
            this.isBusyModal = true;
            this.isMemberLoading = true;
            try {
              const response = await this.$http.get(
                "/Users/getItemListOfCustomers/" + this.user.parentId
              );
              if (response.ok)
                this.newMembers = response.body.filter(
                  (x) => x.text !== "In Store"
                );
            } catch (error) {}

            this.isBusyModal = false;
            this.isMemberLoading = false;
            break;
          default:
            break;
        }
      }
    },

    async add() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isBusyModal = true;
        this.isAdding = true;

        try {
          const response = await this.$http.post(
            "/Campaigns/addMember",
            this.newMember
          );
          if (response.ok) {
            this.isAdding = false;
            this.reset();
            this.$emit("onMemberAddComplete");
          }
        } catch (error) {}

        this.isBusyModal = false;
        this.isAdding = false;
      }
    },

    reset() {
      this.newMember = {
        type: "",
        memberId: "",
        campaignId: "",
      };

      setTimeout(() => {
        this.$v.$reset();
      }, 0);
    },
  },
};
</script>
