<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-add-role"
    role="dialog"
    abindex="-1"
  >
    <div
      class="modal-dialog m-0 p-0 absolute top-0 right-0 flex justify-end items-end"
      role="document"
    >
      <div
        class="modal-content rounded-none bg-[#f8f8f8] p-[40px] min-h-screen"
      >
        <div
          class="modal-header modal-header-colored  flex items-start justify-between p-0 m-0"
        >
          <h4
            class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
          >
            Create New Sales Role
          </h4>
          <button
            class="text-2xl text-[#353535]"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body p-0 m-0">
          <div>
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Role Name</label
            >
            <div class="mb-[16px] w-full">
              <input
                class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full"
                :class="[{ 'is-invalid': v1.name }]"
                type="text"
                v-model="createRole.name"
                placeholder="Enter role name"
              />
              <ul v-if="v1.name" class="parsley-errors-list filled">
                <li class="parsley-required">Required.</li>
              </ul>
            </div>
          </div>
          <div class="mt-[16px]">
            <div>
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Access Rights</label
              >
              <div class="bg-white" style="height: 380px;overflow-y: scroll">
                <ol class="dd-list">
                  <li>
                    <div
                      class="flex items-center gap-[5px] py-[14px] px-[10px] border-b border-[#eee] cursor-pointer"
                      @click="toggleManagerDropdown"
                    >
                      <input
                        type="checkbox"
                        name="manager"
                        class="custom-checkbox"
                        v-model="createRole.isManager"
                        @click.stop
                      />
                      <label
                        class="font-medium text-[#353535] text-[16px] m-0 w-full"
                        for="manager"
                      >
                        Manager
                      </label>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 text-[#353535] transition-transform duration-300"
                        :class="{ 'rotate-180': isManagerDropdownOpen }"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </div>

                    <ol class="dd-list-inner" v-show="isManagerDropdownOpen">
                      <li>
                        <div
                          class="flex items-center gap-[5px] py-[10px] px-[10px] border-b border-[#eee]"
                        >
                          <input
                            type="checkbox"
                            name="manageDistributorManagers"
                            class="custom-checkbox"
                            v-model="createRole.canManageDistributorManagers"
                          />
                          <label
                            class="font-medium text-[#353535] text-[16px] m-0 w-full"
                            for="manageDistributorManagers"
                            >Manage Distributor Managers</label
                          >
                        </div>
                      </li>
                      <li>
                        <div
                          class="flex items-center gap-[5px] py-[10px] px-[10px] border-b border-[#eee]"
                        >
                          <input
                            type="checkbox"
                            name="manageStateManagers"
                            class="custom-checkbox"
                            v-model="createRole.canManageStateManagers"
                          />
                          <label
                            class="font-medium text-[#353535] text-[16px] m-0 w-full"
                            for="manageStateManagers"
                            >Manage State Managers</label
                          >
                        </div>
                      </li>
                      <li>
                        <div
                          class="flex items-center gap-[5px] py-[10px] px-[10px] border-b border-[#eee]"
                        >
                          <input
                            type="checkbox"
                            class="custom-checkbox"
                            name="manageGroupManagers"
                            v-model="createRole.canManageGroupManagers"
                          />
                          <label
                            class="font-medium text-[#353535] text-[16px] m-0 w-full"
                            for="manageGroupManagers"
                            >Manage Group Managers</label
                          >
                        </div>
                      </li>
                    </ol>
                  </li>

                  <li>
                    <div
                      class="flex items-center gap-[5px] py-[10px] px-[10px] border-b border-[#eee]"
                    >
                      <input
                        type="checkbox"
                        name="distributorManager"
                        class="custom-checkbox"
                        v-model="createRole.isDistributorManager"
                      />
                      <label
                        class="font-medium text-[#353535] text-[16px] m-0 w-full"
                        for="distributorManager"
                        >Distributor Manager</label
                      >
                    </div>
                  </li>
                  <li>
                    <div
                      class="flex items-center gap-[5px] py-[10px] px-[10px] border-b border-[#eee]"
                    >
                      <input
                        type="checkbox"
                        class="custom-checkbox"
                        name="stateManager"
                        v-model="createRole.isStateManager"
                      />
                      <label
                        class="font-medium text-[#353535] text-[16px] m-0 w-full"
                        for="stateManager"
                        >State Manager</label
                      >
                    </div>
                  </li>
                  <li>
                    <div
                      class="flex items-center gap-[5px] py-[10px] px-[10px] border-b border-[#eee]"
                    >
                      <input
                        type="checkbox"
                        name="groupManager"
                        class="custom-checkbox"
                        v-model="createRole.isGroupManager"
                      />
                      <label
                        class="font-medium text-[#353535] text-[16px] m-0 w-full"
                        for="groupManager"
                        >Group Manager</label
                      >
                    </div>
                  </li>
                </ol>
                <ol class="dd-list">
                  <li
                    v-for="item in permissionsListCreate"
                    v-bind:class="[
                      item.state.opened ? 'dd-item open' : 'dd-item',
                    ]"
                    :key="item.id"
                  >
                    <div
                      class="dd-handle"
                      @click="item.state.opened = !item.state.opened"
                    >
                      <input
                        type="checkbox"
                        :disabled="item.state.disabled"
                        :name="item.text"
                        :checked="item.state.selected"
                        @click="item.state.selected = !item.state.selected"
                        @change="onPermissionChange(item, item.state.selected)"
                      />
                      <label :for="item.text"
                        ><b>{{ item.text }}</b></label
                      >
                    </div>

                    <ol v-if="item.children.length != 0" class="dd-list-inner">
                      <li
                        v-for="children in item.children"
                        :data-id="children.id"
                        class="dd-item"
                        :key="children.id"
                      >
                        <div class="dd-handle">
                          <input
                            type="checkbox"
                            :name="children.text"
                            :checked="children.state.selected"
                            :disabled="children.state.disabled"
                            @click="
                              children.state.selected = !children.state.selected
                            "
                          />
                          <label :for="children.text">{{
                            children.text
                          }}</label>
                        </div>
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
              <ul v-if="v1.accessRights" class="parsley-errors-list filled">
                <li class="parsley-required">
                  Please select at least one access right.
                </li>
              </ul>
              <ul
                v-if="v1.managerAccessRights"
                class="parsley-errors-list filled"
              >
                <li class="parsley-required">
                  Please select at least one access right for manager.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="w-full flex items-center gap-[8px] mt-[16px]">
          <button
            class="bg-[#fff] border-[##96a0b5] border-solid border py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full"
            type="button"
            data-dismiss="modal"
            @click="reset"
          >
            Cancel
          </button>
          <button
            class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] w-full"
            :disabled="isBusyModal"
            type="button"
            @click.prevent="create"
          >
            <span v-if="isBusyModal">Busy...</span>
            <span v-else>Add Role</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import moment from "moment";
import { helper } from "@/helper";
import Select2 from "@/components/Select2";
import SystemPermissions from "@/permissions";
import Pagination from "../Pagination.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "salesRole",
  components: {
    Select2,
    Pagination,
  },

  watch: {
    "createRole.isManager": function(newType, oldType) {
      if (newType == false) {
        this.createRole.canManageDistributorManagers = newType;
        this.createRole.canManageStateManagers = newType;
        this.createRole.canManageGroupManagers = newType;
      }
    },
    "updateRole.isManager": function(newType, oldType) {
      if (newType == false) {
        this.updateRole.canManageDistributorManagers = newType;
        this.updateRole.canManageStateManagers = newType;
        this.updateRole.canManageGroupManagers = newType;
      }
    },
    "createRole.canManageDistributorManagers": function(newType, oldType) {
      if (newType == true) this.createRole.isManager = newType;
      else {
        if (
          !this.createRole.canManageGroupManagers &&
          !this.createRole.canManageStateManagers
        )
          this.createRole.isManager = false;
      }
    },
    "createRole.canManageStateManagers": function(newType, oldType) {
      if (newType == true) this.createRole.isManager = newType;
      else {
        if (
          !this.createRole.canManageGroupManagers &&
          !this.createRole.canManageDistributorManagers
        )
          this.createRole.isManager = false;
      }
    },
    "createRole.canManageGroupManagers": function(newType, oldType) {
      if (newType == true) this.createRole.isManager = newType;
      else {
        if (
          !this.createRole.canManageStateManagers &&
          !this.createRole.canManageDistributorManagers
        )
          this.createRole.isManager = false;
      }
    },
    "updateRole.canManageDistributorManagers": function(newType, oldType) {
      if (newType == true) this.updateRole.isManager = newType;
      else {
        if (
          !this.updateRole.canManageGroupManagers &&
          !this.updateRole.canManageStateManagers
        )
          this.updateRole.isManager = false;
      }
    },
    "updateRole.canManageStateManagers": function(newType, oldType) {
      if (newType == true) this.updateRole.isManager = newType;
      else {
        if (
          !this.updateRole.canManageGroupManagers &&
          !this.updateRole.canManageDistributorManagers
        )
          this.updateRole.isManager = false;
      }
    },
    "updateRole.canManageGroupManagers": function(newType, oldType) {
      if (newType == true) this.updateRole.isManager = newType;
      else {
        if (
          !this.updateRole.canManageStateManagers &&
          !this.updateRole.canManageDistributorManagers
        )
          this.updateRole.isManager = false;
      }
    },
  },
  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      isManagerDropdownOpen: true,
      createRole: {
        name: null,
        isManager: false,
        canManageDistributorManagers: false,
        canManageGroupManagers: false,
        canManageStateManagers: false,
        isDistributorManager: false,
        isStateManager: false,
        isGroupManager: false,
        permissions: [],
      },
      v1: {
        name: false,
        accessRights: false,
        managerAccessRights: false,
      },
      v2: {
        name: false,
        accessRights: false,
        managerAccessRights: false,
      },
      permissions: [],
      count: 0,
      page: 0,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
    };
  },
  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
    permissionsListCreate() {
      return this.createRole.permissions.map((b) => {
        return b;
      });
    },
  },
  methods: {
    toggleManagerDropdown() {
      this.isManagerDropdownOpen = !this.isManagerDropdownOpen;
    },
    onPermissionChange(item, state) {
      for (let child of item.children) {
        child.state.selected = state;
      }
    },
    reset() {
      this.filter = {
        status: "0",
        qry: "",
      };
    },
    cancel() {
      const currentRole = this.Roles.find((l) => l.id === this.editRole.id);
      currentRole.name = this.editRole.name;
      currentRole.id = this.editRole.id;
      $("#mod-edit").modal("hide");
    },
    viewRoleUsers(r) {
      this.$router.push("/settings/roles/" + r.id + "/users?n=" + r.roleName);
    },
    viewExport() {
      //   $("#mod-export").modal("show");
    },
    viewAdd() {
      this.createRole = {
        name: null,
        permissions: JSON.parse(JSON.stringify(this.permissions)),
      };
      this.v1 = {
        name: false,
      };
      $("#mod-add").modal("show");
    },
    viewEdit(c) {
      var editValues = {
        name: c.roleName,
        id: c.id,
        permissions: JSON.parse(JSON.stringify(c.permissions)),
      };
      this.editRole = editValues;

      this.updateRole = {
        id: c.id,
        name: c.roleName,
        permissions: JSON.parse(JSON.stringify(c.permissions)),
      };

      $("#mod-edit").modal("show");
    },
    viewFilter() {
      $("#mod-filter").modal("show");
    },
    async deleteRole(c) {
      if (confirm("Are you sure you want to delete this role?")) {
        this.isBusyModal = true;
        this.isBusy = true;

        await this.$http
          .post("/Roles/delete/" + c.id, null)
          .then(function(res) {
            this.isBusyModal = false;
          })
          .catch(function() {});

        this.getRoles();

        this.isBusy = false;
      }
    },
    async create() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        name: this.createRole.name,
        parentId: this.user.parentId,
        canManageDistributorManagers: this.createRole
          .canManageDistributorManagers,
        canManageGroupManagers: this.createRole.canManageGroupManagers,
        canManageStateManagers: this.createRole.canManageStateManagers,
        isDistributorManager: this.createRole.isDistributorManager,
        isStateManager: this.createRole.isStateManager,
        isGroupManager: this.createRole.isGroupManager,
        permissions: this.createRole.permissions,
      };

      if (this.validateCreate()) {
        $("#mod-add").modal("hide");

        await this.$http
          .post("/SalesRoles/create", data)
          .then(function(res) {
            this.isBusyModal = false;
          })
          .catch(function() {});

        this.getRoles();
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },
    validateCreate() {
      this.v1 = {
        name: false,
        accessRights: false,
        managerAccessRights: false,
      };
      var isValid = true;

      if (!this.createRole.name) {
        this.v1.name = true;
        isValid = false;
      }

      if (
        this.createRole.isManager &&
        !this.createRole.canManageDistributorManagers &&
        !this.createRole.canManageGroupManagers &&
        !this.createRole.canManageStateManagers
      ) {
        this.v1.managerAccessRights = true;
        isValid = false;
      }

      if (
        !this.createRole.canManageDistributorManagers &&
        !this.createRole.canManageGroupManagers &&
        !this.createRole.canManageStateManagers &&
        !this.createRole.isDistributorManager &&
        !this.createRole.isStateManager &&
        !this.createRole.isGroupManager
      ) {
        this.v1.accessRights = true;
        isValid = false;
      }

      return isValid;
    },
    async update() {
      this.isBusyModal = true;
      this.isBusy = true;

      const data = {
        name: this.updateRole.name,
        permissions: this.updateRole.permissions,
        roleId: this.updateRole.id,
        parentId: this.user.parentId,
      };

      if (this.validateUpdate()) {
        $("#mod-edit").modal("hide");

        await this.$http
          .put("/Roles/update/" + data.roleId, data)
          .then(function(res) {
            this.isBusyModal = false;
          })
          .catch(function() {});

        this.getRoles();
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },
    validateUpdate() {
      this.v2 = {
        name: false,
      };
      var isValid = true;

      if (!this.updateRole.name) {
        this.v2.name = true;
        isValid = false;
      }

      return isValid;
    },
    async getRoles() {
      this.isBusy = true;

      await this.$http
        .get(
          "/Roles/getRoleList?page=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            "&roleType=" +
            this.user.type +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "") +
            (this.filter.status != "0" ? "&active=" + this.filter.status : "") +
            (this.filter.qry != "" ? "&query=" + this.filter.qry : "")
        )
        .then(function(res) {
          console.log(res);
          this.roles = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },
    async getPermissions() {
      this.isBusy = true;

      await this.$http
        .get("/Roles/getPermissionList?roleType=" + this.user.type)
        .then(function(res) {
          console.log(res);
          this.permissions = res.body;
          this.createRole.name = "";
          this.createRole.permissions = this.permissions;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },

    formatDate(date) {
      return moment(date).format("lll");
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    prev() {
      this.page -= 1;
      this.getRoles();
    },

    next() {
      this.page += 1;
      this.getRoles();
    },

    gotoPage(page) {
      this.page = page - 1;
      this.getRoles();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getRoles();
    },
  },
};
</script>
<style>
.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  padding-left: 20px;
  max-width: 600px;
  list-style: none;
  line-height: 20px;
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list-inner {
  padding-left: 30px;
}

.dd-item {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  line-height: 20px;
}

.dd-handle {
  display: block;
  margin: 5px 0;
  padding: 0px 10px;
  text-decoration: none;
  background: #fff;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
</style>
