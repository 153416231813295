<template>
  <button
    class="btn btn-danger btn-sm btn-rounded btn-space mr-1"
    data-toggle="tooltip"
    title="Delete"
    :disabled="deleteLoading"
    @click.prevent="onDeleteClick"
  >
    <span
      v-if="deleteLoading"
      class="spinner-border spinner-border-sm"
      role="status"
    >
      <i class="sr-only">Loading...</i>
    </span>
    <span v-else>
      <i class="icon mdi mdi-delete text-white"></i>
    </span>
  </button>
</template>

<script>
export default {
  name: "DeletePostPayment",
  props: ["payment"],

  data() {
    return {
      deleteLoading: false,
    };
  },

  methods: {
    onDeleteClick() {
      this.$emit("showCaution", {
        referenceId: this.payment.referenceId,
        id: this.payment.id,
      });
    },

    async deletePayment() {
      this.deleteLoading = true;
      try {
        const response = await this.$http.delete(
          `/Payments/${this.payment.id}/${this.payment.ownerId}`
        );

        if (response.ok && response.status === 200) {
          this.$toast.success("Payment deleted succesfully", {
            icon: true,
            rtl: false,
          });
          this.$emit("onDeleteComplete");
        }
      } catch (error) {}
      this.deleteLoading = false;
    },
  },
};
</script>
