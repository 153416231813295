<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-transaction-filter"
    role="dialog"
  >
    <div class="modal-dialog">
      <form @submit.prevent="fetchTransactions" class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1"><b>Filter</b></h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
            @click="clearFilter"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-md-4 col-form-label text-sm-right"
              >Start Date</label
            >

            <div class="col-12 col-sm-8 col-lg-7">
              <date-picker
                @input="setStartDate"
                class="form-control"
                :value="transactionFilter.startDate"
                :config="options"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-md-4 col-form-label text-sm-right"
              >End Date</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <date-picker
                @input="setEndDate"
                class="form-control"
                :value="transactionFilter.endDate"
                :config="endOptions"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-12 col-sm-3 col-md-4 col-form-label text-sm-right"
              >Transaction Type</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <select
                @input="setTransactionType($event.target.value)"
                class="form-control"
                :value="transactionFilter.transactionType"
                placeholder="Select an option"
              >
                <!-- <option value="">All Transactions</option> -->
                <option
                  v-for="option in transactionTypes"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" @click="clearFilter" type="button">
            Clear
          </button>
          <button class="btn btn-danger" type="button" data-dismiss="modal">
            Cancel
          </button>
          <button
            class="btn btn-primary"
            @click="getTransactions"
            type="submit"
          >
            <span
              v-if="transactions.isLoading"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <i class="sr-only">Loading...</i>
            </span>
            <span v-else> Submit </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import datePicker from "vue-bootstrap-datetimepicker";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "CustomerTransactionFilter",
  components: { datePicker },

  props: ["transactionTypes"],

  data() {
    return {
      options: {
        format: "YYYY-MM-DD",
      },

      endOptions: {
        format: "YYYY-MM-DD",
      },
    };
  },

  computed: {
    ...mapGetters(["transactionFilter", "transactions"]),
  },

  created() {
    this.setDateTime();
  },

  methods: {
    ...mapActions(["getTransactions"]),
    clearFilter() {
      this.$store.commit("clearTransactionFilter");
      this.setDateTime();
    },

    setStartDate(date) {
      this.transactionFilter.startDate = date;
      this.updateFilter(this.transactionFilter);
    },

    setEndDate(date) {
      this.transactionFilter.endDate = date;
      this.updateFilter(this.transactionFilter);
    },

    setTransactionType(value) {
      this.transactionFilter.transactionType = value;
      this.updateFilter(this.transactionFilter);
    },

    setDateTime() {
      const format = "YYYY-MM-DD";
      const date = new Date();
      this.transactionFilter.startDate = moment(
        new Date(date.getFullYear(), date.getMonth(), 1)
      ).format(format);

      this.transactionFilter.endDate = moment(Date.now()).format(format);
      this.updateFilter(this.transactionFilter);
    },

    updateFilter(filter) {
      this.$store.commit("updateTransactionFilter", filter);
    },

    async fetchTransactions() {
      const id = this.$route.params.id;
      this.$store.dispatch("getTransactions", id);
    },
  },
};
</script>

<style>
</style>