<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
          <div class="card-header">
              <h4 class="p-1">
                  <!--<b>System Settings</b>-->
              </h4>
              <!-- <div class="btn-group">
                  <button v-if="settings.parentId == user.parentId"
                          class="btn btn-success btn-sm btn-rounded btn-space"
                          @click.prevent="viewEdit(settings)">
                      <i class="feather icon-edit"></i> &nbsp;Edit
                  </button>
              </div> -->
          </div>
          <div class="card-body">

              <h4>Global Distributor Settings</h4>
              <hr />
              <div class="d-flex flex-row justify-content-between">
                <h5 class="font-weight-bold">Distributor Product Permission</h5>
                <div class="btn-group">
                    <button v-if="settings.parentId == user.parentId"
                            class="btn btn-success btn-sm btn-rounded btn-space"
                            @click.prevent="viewEdit(settings)">
                        <i class="feather icon-edit"></i> &nbsp;Edit
                    </button>
                </div>
              </div>
             
              <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">No Restriction:</label>
                  <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
                      <span v-if="settings.distributorProductNoRestriction" class="badge badge-pill badge-success">
                          {{ settings.distributorProductNoRestriction ? "YES" : "NO" }}
                      </span>
                      <span v-else class="badge badge-pill badge-secondary">
                          {{ settings.distributorProductNoRestriction ? "YES" : "NO" }}
                      </span>
                  </div>
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Only Sell My Products:</label>
                  <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
                      <span v-if="settings.distributorOnlySellMyProducts" class="badge badge-pill badge-success">
                          {{ settings.distributorOnlySellMyProducts ? "YES" : "NO" }}
                      </span>
                      <span v-else class="badge badge-pill badge-secondary">
                          {{ settings.distributorOnlySellMyProducts ? "YES" : "NO" }}
                      </span>
                  </div>
              </div>

              <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Only Sell Their Products:</label>
                  <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
                      <span v-if="settings.distributorOnlySellTheirProducts" class="badge badge-pill badge-success">
                          {{ settings.distributorOnlySellTheirProducts ? "YES" : "NO" }}
                      </span>
                      <span v-else class="badge badge-pill badge-secondary">
                          {{ settings.distributorOnlySellTheirProducts ? "YES" : "NO" }}
                      </span>
                  </div>
              </div>

              <hr />

              <div class="mt-3">
                <div class="d-flex flex-row justify-content-between">
                    <h5 class="font-weight-bold">Distributor Product Price Options View</h5>
                    <div class="btn-group">
                        <button v-if="settings.parentId == user.parentId"
                                class="btn btn-success btn-sm btn-rounded btn-space"
                                @click.prevent="viewEditPrice(settings)">
                            <i class="feather icon-edit"></i> &nbsp;Edit
                        </button>
                    </div>
                </div>
                 <div class="form-group row">
                    <label class="col-12 col-sm-3 col-form-label text-sm-right">Distributor Sees All Product Price Options:</label>
                    <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
                        <span v-if="settings.distributorViewAllPriceOptions" class="badge badge-pill badge-success">
                            {{ settings.distributorViewAllPriceOptions ? "YES" : "NO" }}
                        </span>
                        <span v-else class="badge badge-pill badge-secondary">
                            {{ settings.distributorViewAllPriceOptions ? "YES" : "NO" }}
                        </span>
                    </div>
                    <label class="col-12 col-sm-3 col-form-label text-sm-right">Sales Team Sees All Product Price Options:</label>
                    <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
                        <span v-if="settings.salesUserViewAllPriceOptions" class="badge badge-pill badge-success">
                            {{ settings.salesUserViewAllPriceOptions ? "YES" : "NO" }}
                        </span>
                        <span v-else class="badge badge-pill badge-secondary">
                            {{ settings.salesUserViewAllPriceOptions ? "YES" : "NO" }}
                        </span>
                    </div>
                </div>
              </div>
              

          </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Edit Settings</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              @click="reset"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
              <div class="col-12" v-if="errorMessage">
                  <div class="alert alert-danger">
                      <i class="feather icon-info"></i> {{ errorMessage }}
                  </div>
              </div>

              <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                  <div class="col-12 col-sm-8 col-lg-8">
                      <label class="custom-control custom-checkbox custom-control-inline">
                          <input class="custom-control-input"
                                 type="radio"
                                 value="noRestriction"
                                 v-model="selectedDistributorProductPermission" />
                          <span class="custom-control-label">No Restriction</span>
                      </label>
                  </div>
              </div>
              <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                  <div class="col-12 col-sm-8 col-lg-8">
                      <label class="custom-control custom-checkbox custom-control-inline">
                          <input class="custom-control-input"
                                 type="radio"
                                 value="onlySellMyProducts"
                                 v-model="selectedDistributorProductPermission" />
                          <span class="custom-control-label">Only Sell My Products</span>
                      </label>
                  </div>
              </div>
              <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                  <div class="col-12 col-sm-8 col-lg-8">
                      <label class="custom-control custom-checkbox custom-control-inline">
                          <input class="custom-control-input"
                                 type="radio"
                                 value="onlySellTheirProducts"
                                 v-model="selectedDistributorProductPermission" />
                          <span class="custom-control-label">Only Sell Their Products</span>
                      </label>
                  </div>
              </div>


          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
              @click="reset"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="update('product')"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit-price"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Edit Settings</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              @click="reset"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
              <div class="col-12" v-if="errorMessage">
                  <div class="alert alert-danger">
                      <i class="feather icon-info"></i> {{ errorMessage }}
                  </div>
              </div>

              <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                  <div class="col-12 col-sm-8 col-lg-8">
                      <label class="custom-control custom-checkbox custom-control-inline">
                          <input class="custom-control-input"
                                 type="checkbox"
                                 value="onlyDistributor"
                                 v-model="selectedPriceOptionPermission" />
                          <span class="custom-control-label">Distributor Sees All Product Price Options</span>
                      </label>
                  </div>
              </div>
              <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                  <div class="col-12 col-sm-8 col-lg-8">
                      <label class="custom-control custom-checkbox custom-control-inline">
                          <input class="custom-control-input"
                                 type="checkbox"
                                 value="onlySalesTeam"
                                 v-model="selectedSlaesPriceOptionPermission" />
                          <span class="custom-control-label">Sales Team Sees All Product Price Options</span>
                      </label>
                  </div>
              </div>
    
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
              @click="reset"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="update('price')"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import Select2 from "@/components/Select2";
import SystemPermissions from "@/permissions";

    export default {
        mixins: [SystemPermissions.Mixin],
        name: "systemSettings",
        components: {
            Select2,
        },
        created() {
            if (!this.hasPermission("System_Settings_View")) {
                this.$router.push("/restricted");
            }
            this.getSystemSettings();
        },
        data() {
            return {
                filter: {
                    status: "0",
                    qry: "",
                    showForMe: false,
                },
                isBusy: false,
                isBusyModal: false,
                updateSettings: {
                    distributorProductNoRestriction: null,
                    distributorOnlySellMyProducts: null,
                    distributorOnlySellTheirProducts: null,
                    distributorViewAllPriceOptions: null,
                    salesUserViewAllPriceOptions: null,
                    id: null,
                },
                settings: {},
                errorMessage: null,
                selectedDistributorProductPermission: null,
                selectedPriceOptionPermission: null,
                selectedSlaesPriceOptionPermission: null,
            };
        },

        computed: {
            user: function () {
                return this.$store.state.currentUser;
            },
        },

        watch: {

        },

        methods: {
            reset() {
                this.filter = {
                    status: "0",
                    qry: "",
                    showForMe: false,
                };
            },

            viewExport() {
                //   $("#mod-export").modal("show");
            },

            viewEdit() {
                if (this.settings.distributorOnlySellTheirProducts) {
                    this.selectedDistributorProductPermission = "onlySellTheirProducts";
                }
                else if (this.settings.distributorOnlySellMyProducts) {
                    this.selectedDistributorProductPermission = "onlySellMyProducts";
                }
                else if (this.settings.distributorProductNoRestriction) {
                    this.selectedDistributorProductPermission = "noRestriction";
                }
                $("#mod-edit").modal("show");
            },

             viewEditPrice() {
                this.selectedPriceOptionPermission = this.settings.distributorViewAllPriceOptions || false;
                this.selectedSlaesPriceOptionPermission = this.settings.salesUserViewAllPriceOptions || false;
                $("#mod-edit-price").modal("show");
            },

            viewFilter() {
                $("#mod-filter").modal("show");
            },

            async update(type) {
                let data = {};
                if(type === 'product'){
                    data = {
                        parentId: this.user.parentId,
                        distributorProductNoRestriction: this.selectedDistributorProductPermission == "noRestriction",
                        distributorOnlySellMyProducts: this.selectedDistributorProductPermission == "onlySellMyProducts",
                        distributorOnlySellTheirProducts: this.selectedDistributorProductPermission == "onlySellTheirProducts",
                        id: this.updateSettings.id,
                    };

                    $("#mod-edit").modal("hide");
                      await this.$http
                        .put("/SystemSettings/updateManufacturerSettings/" + data.id, data)
                        .then(function (res) {
                            this.isBusyModal = false;
                            this.$toast.success(res.body);
                        })
                        .catch(function () {
                            this.$toast.error("Failed to update, try again");
                         });
                }else if(type === 'price'){
                     data = {
                        parentId: this.user.parentId,
                        distributorViewAllPriceOptions: this.selectedPriceOptionPermission,
                        salesUserViewAllPriceOptions: this.selectedSlaesPriceOptionPermission,
                        id: this.updateSettings.id,
                    };

                    $("#mod-edit-price").modal("hide");

                      await this.$http
                        .put("/SystemSettings/updateManufacturerPriceOptionSettings/" + data.id, data)
                        .then(function (res) {
                            this.isBusyModal = false;
                            this.$toast.success(res.body);
                        })
                        .catch(function () { 
                            this.$toast.error("Failed to update, try again");
                        });
                }
           

              

                this.reset();

                this.getSystemSettings();

                this.isBusy = false;
                this.isBusyModal = false;

            },

            async getSystemSettings() {
                this.isBusy = true;

                await this.$http
                    .get("/SystemSettings/getManufacturerSettingsByAccount?parentId=" + this.user.parentId)
                    .then(function (res) {
                        this.settings = { ...res.body };
                        this.updateSettings = { ...res.body };
                    })
                    .catch(function () { });

                $("#mod-filter").modal("hide");
                this.isBusy = false;
            },

            formatDate(date) {
                return moment(date).format("lll");
            },

            formatMoney(val) {
                return helper.formatMoney(val);
            },

            reset() {
                this.updateSettings = {
                    distributorProductNoRestriction: null,
                    onlySellMyProducts: null,
                    onlySellTheirProducts: null,
                      distributorViewAllPriceOptions: null,
                    salesUserViewAllPriceOptions: null,
                    id: null,
                };
            },
        },
    };
</script>