<template>
  <div class="row min-h-screen shadow-none">
    <div class="col-lg-12">
      <div :class="['be-loading', { 'be-loading-active': isBusy }]">
        <ManagementTabs
          :activeTab="activeSubTab"
          :showManualUserDropdown="showManualUserDropdown"
          :showManualRoleDropdown="showManualRoleDropdown"
          :hasPermission="hasPermission"
          @switchTab="switchSubTab"
          @toggleManualUserDropdown="toggleManualUserDropdown"
          @toggleManualRoleDropdown="toggleManualRoleDropdown"
          @viewFilter="viewFilter"
          @viewAdd="viewAdd"
          @viewAddSalesManual="viewAddSalesManual"
          @viewAddManual="viewAddManual"
          @viewAddRoleManual="viewAddRoleManual"
          @viewAddBusinessRole="viewAddBusinessRole"
        />
        <div v-if="activeSubTab === 'user'">
          <UserTable
            :users="users"
            :viewRemove="viewRemove"
            :viewEditUser="viewEditUser"
            :pageNumber="pageNumber"
            :pageSize="pageSize"
            :count="count"
            :pageCount="pageCount"
            :hasNext="hasNext"
            :hasPrev="hasPrev"
            @changePage="gotoPage"
            @changeLimit="changeLimit"
          />
        </div>
        <div v-if="activeSubTab === 'role'">
          <RoleTable />
        </div>
      </div>
    </div>
    <invite-user
      :roles="roles"
      :branches="Branches"
      @onInviteComplete="onInviteCompleteHandler"
    />
    <edit-user
      :roles="roles"
      :branches="Branches"
      :udata="modalUserData"
    ></edit-user>

    <create-user @onCreateUserComplete="handleCreateUserComplete" />
    <create-sales-user @onCreateUser="handleCreateUserSalesComplete" />
    <SalesRole />
    <BusinessRole />

    <!-- new modal for editing user -->

    <!-- modal for filters -->

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <b>Edit role for user:</b>
              {{
                editingUser.displayName
                  ? editingUser.displayName
                  : editingUser.emailAddress
                  ? editingUser.emailAddress
                  : editingUser.phoneNumber
              }}
            </h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Role</label
              >
              <div
                v-if="user.type == 'Distributor' || user.type == 'Manufacturer'"
                class="col-12 col-sm-8 col-lg-8"
              >
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="editingUser.role"
                >
                  <option v-for="r in roles" :key="r.id" :value="r.text">
                    {{ r.text }}
                  </option>
                </select>
                <ul v-if="v2.role" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
              <div v-else class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="editingUser.role"
                >
                  <option value="Administrator">Administrator</option>
                </select>
                <ul v-if="v2.role" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="updateRole"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit-branch"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <b>Edit branch for user:</b>
              {{
                editingUser.displayName
                  ? editingUser.displayName
                  : editingUser.emailAddress
                  ? editingUser.emailAddress
                  : editingUser.phoneNumber
              }}
            </h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Branch</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="editingUser.branchId"
                >
                  <option v-for="br in Branches" :key="br.id" :value="br.id">
                    {{ br.name }}
                  </option>
                </select>
                <ul v-if="v3.branch" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="updateBranch"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit-name"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <b>Edit name for user:</b>
              {{
                editingUser.displayName
                  ? editingUser.displayName
                  : editingUser.emailAddress
                  ? editingUser.emailAddress
                  : editingUser.phoneNumber
              }}
            </h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Name</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Name..."
                  v-model="editingUser.displayName"
                />
                <ul v-if="v5.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="updateName"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit-email"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <b>Edit email for user:</b>
              {{
                editingUser.displayName
                  ? editingUser.displayName
                  : editingUser.emailAddress
                  ? editingUser.emailAddress
                  : editingUser.phoneNumber
              }}
            </h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Email Address</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Email Address..."
                  v-model="editingUser.emailAddress"
                />
                <ul v-if="v6.email" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="updateEmail"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit-phone"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <b>Edit phone number for user:</b>
              {{
                editingUser.displayName
                  ? editingUser.displayName
                  : editingUser.emailAddress
                  ? editingUser.emailAddress
                  : editingUser.phoneNumber
              }}
            </h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Phone Number</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Phone Number..."
                  v-model="editingUser.phoneNumber"
                />
                <ul v-if="v7.phone" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="updatePhone"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-delete"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">
              <h3 class="p-1"><b>Confirmation</b></h3>
              <p>
                Are you sure you want to
                {{ modalUser.status == "Inactive" ? "enable" : "disable" }} this
                user:<br />
                <strong>{{
                  modalUser.displayName
                    ? modalUser.displayName
                    : modalUser.emailAddress
                }}</strong
                >?
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 130px"
              :disabled="isBusyModal"
              class="btn btn-warning"
              type="button"
              @click.prevent="updateStatus"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Yes, proceed</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-filter"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Filter</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Status</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="filter.status"
                >
                  <option value="0">All Users</option>
                  <option value="active">Active</option>
                  <option value="inactive">InActive</option>
                  <option value="pending">Others</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Roles</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="filter.role"
                >
                  <option value="0">All Roles</option>
                  <option v-for="r in roles" :key="r.id" :value="r.text">
                    {{ r.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Other filters</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Filter by name, email or phone number..."
                  v-model="filter.qry"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button>
            <button
              :disabled="isBusyModal"
              style="width: 100px"
              class="btn btn-primary md-close"
              type="button"
              @click="getUsers"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-resend"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">
              <h3>Resend Invite</h3>
              <p>
                You are about to resend an invite to this customer:
                <strong>{{
                  modalUser.displayName || modalUser.emailAddress
                }}</strong
                >?
              </p>
            </div>
            <div v-if="enableInviteOption" class="mt-2 form-group row">
              <label
                class="col-12 col-sm-3 col-form-label text-sm-right"
              ></label>
              <div class="col-12 col-sm-8 col-lg-8">
                <div
                  class="custom-control custom-radio"
                  :class="[{ 'is-invalid': v8.selectedOption }]"
                  v-for="option in inviteOptions"
                  :key="option.value"
                >
                  <input
                    type="radio"
                    :id="option.value"
                    name="customRadio"
                    class="custom-control-input"
                    :value="option.value"
                    v-model="selectedOption"
                  />
                  <label class="custom-control-label" :for="option.value">{{
                    option.name
                  }}</label>
                </div>
                <ul v-if="v8.selectedOption" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="attemptResendInvite"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Yes, proceed</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <GeneralDeleteCaution
      :id="DeleteId"
      @proceed="deleteUser"
      :isBusy="isBusyModal"
      text="user"
    />
    <GeneralDeleteMessage
      message="You cannot delete this user because they are 
      active or have transaction history. Consider disabling the user instead."
    />
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import SystemPermissions from "@/permissions";
import ManagementTabs from "../components/Management/ManagementTabs.vue";
import UserTable from "../components/Management/UserTable.vue";
import RoleTable from "../components/Management/RoleTable.vue";
import Select2 from "@/components/Select2";
import GeneralDeleteCaution from "../components/GeneralDeleteCaution.vue";
import GeneralDeleteMessage from "../components/GeneralDeleteMessage.vue";
import CreateUser from "../components/User/CreateUser.vue";
import InviteUser from "../components/User/InviteUser.vue";

import EditUser from "../components/User/EditUser";

import Pagination from "../components/Pagination.vue";
import CreateSalesUser from "../components/User/CreateSalesUser.vue";
import SalesRole from "../components/User/SalesRole.vue";
import BusinessRole from "../components/User/BusinessRole.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "User-Management",
  components: {
    Select2,
    ManagementTabs,
    UserTable,
    RoleTable,
    CreateUser,
    EditUser,
    CreateSalesUser,
    InviteUser,
    GeneralDeleteCaution,
    GeneralDeleteMessage,
    Pagination,
    SalesRole,
    BusinessRole,
  },

  created() {
    if (!this.hasPermission("User_View")) {
      this.$router.push("/restricted");
    }
    this.getUsers();
    this.getBranches();
  },

  data() {
    return {
      filter: {
        status: "0",
        role: "0",
        qry: "",
      },
      modalUserData: {},
      showDropdown: null,
      showDropdownRole: null,
      showManualUserDropdown: false,
      showManualRoleDropdown: false,
      isBusy: false,
      isBusyModal: false,
      activeSubTab: "user",
      createUser: {
        username: null,
        password: null,
        phoneNumber: null,
        emailAddress: null,
        name: null,
        role: null,
        branch: null,
        staffId: null,
        salesAccessId: null,
      },

      editingUser: {
        emailAddress: null,
        phoneNumber: null,
        displayName: null,
        role: null,
        branchId: null,
      },

      modalUser: {
        emailAddress: null,
        displayName: null,
      },

      Branches: [],
      v2: {
        role: false,
      },

      v3: {
        branch: false,
      },

      v5: {
        name: false,
      },
      v6: {
        email: false,
      },
      v7: {
        phone: false,
      },
      users: [],
      roles: [],
      DeleteId: "",
      count: 0,
      page: 0,
      pageNumber: 0,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,

      v8: {
        selectedOption: false,
      },

      selectedOption: "",
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },

    enableInviteOption: function() {
      return this.modalUser.emailAddress && this.modalUser.phoneNumber;
    },
  },
  methods: {
    toggleDropdown(userId) {
      if (this.showDropdown === userId) {
        this.showDropdown = null;
      } else {
        this.showDropdown = userId;
      }
    },
    toggleDropdownRole(roleId) {
      if (this.showDropdownRole === roleId) {
        this.showDropdownRole = null;
      } else {
        this.showDropdownRole = roleId;
      }
    },
    toggleManualUserDropdown() {
      this.showManualUserDropdown = !this.showManualUserDropdown;
    },
    toggleManualRoleDropdown() {
      this.showManualRoleDropdown = !this.showManualRoleDropdown;
    },
    switchSubTab(subTab) {
      this.activeSubTab = subTab;
    },
    reset() {
      this.filter = {
        status: "0",
        role: "0",
        qry: "",
      };
    },
    confirmDelete(id) {
      this.DeleteId = id;
      $("#mod-confirm-delete").modal("show");
    },

    showDeleteMsg() {
      $("#mod-delete-msg").modal("show");
    },

    cancelConfirm() {
      this.DeleteId = "";
      $("#mod-confirm-delete").modal("hide");
    },
    async deleteUser() {
      this.isBusyModal = true;
      try {
        const res = await this.$http.delete(
          `/Users/deleteUser/${this.DeleteId}`
        );
        if (res.ok) {
          this.$toast.success("User deleted successfully", {
            icon: true,
            rtl: false,
          });
          this.getUsers();
          this.cancelConfirm();
        }
      } catch (error) {
        this.cancelConfirm();
        this.showDeleteMsg();
      }
      this.isBusyModal = false;
    },

    viewAdd() {
      $("#mod-add").modal("show");
    },

    viewEditUser(data) {
      $("#mod-edit-user").modal("show");
      this.modalUserData = data;
    },

    viewAddManual() {
      $("#mod-add-manual").modal("show");
      this.showManualUserDropdown = false;
    },
    viewAddSalesManual() {
      $("#mod-add-manual-sales").modal("show");
      this.showManualUserDropdown = false;
    },
    viewAddRoleManual() {
      $("#mod-add-role").modal("show");
      // console.log("Triggering modal display");
      // alert("clicked me");
      this.showManualRoleDropdown = false;
    },
    viewAddBusinessRole() {
      $("#mod-add-sales").modal("show");
      this.showManualRoleDropdown = false;
    },
    viewEdit(u) {
      this.editingUser = u;
      $("#mod-edit").modal("show");
    },

    viewEditBranch(u) {
      this.editingUser = u;
      $("#mod-edit-branch").modal("show");
    },

    viewEditName(u) {
      this.editingUser = u;
      $("#mod-edit-name").modal("show");
    },

    viewEditPhone(u) {
      this.editingUser = u;
      $("#mod-edit-phone").modal("show");
    },

    viewEditEmail(u) {
      this.editingUser = u;
      $("#mod-edit-email").modal("show");
    },

    viewRemove(u) {
      this.modalUser = u;
      $("#mod-delete").modal("show");
    },

    viewResend(u) {
      this.modalUser = u;
      $("#mod-resend").modal("show");
    },

    viewFilter() {
      this.pageNumber = 0;
      $("#mod-filter").modal("show");
    },

    handleCreateUserComplete() {
      $("#mod-add-manual").modal("hide");
      this.getUsers();
    },
    handleCreateUserSalesComplete() {
      $("mod-add-manual-sales").modal("hide");
      this.getUsers();
    },
    onInviteCompleteHandler() {
      $("#mod-add").modal("hide");
      this.getUsers();
    },

    async attemptResendInvite() {
      if (this.enableInviteOption && this.validateResendInvite())
        this.resendInvite();

      if (!this.enableInviteOption) this.resendInvite();
    },

    async resendInvite() {
      this.isBusyModal = true;
      $("#mod-resend").modal("hide");

      await this.$http
        .get(
          "/Users/resendInvite/" +
            this.modalUser.id +
            "?type=" +
            this.selectedOption
        )
        .then(function(res) {
          this.isBusyModal = false;
          if (res.ok && res.body) {
            this.$toast.success("Invite sent successfully.", {
              icon: false,
              rtl: false,
            });
          }
        })
        .catch(function() {
          this.isBusyModal = false;
        });
    },

    validateResendInvite() {
      this.v8 = {
        selectedOption: false,
      };

      let isValid = true;

      if (!this.selectedOption) {
        this.v8.selectedOption = true;
        isValid = false;
      }

      return isValid;
    },

    updateRole() {
      this.isBusyModal = true;
      this.isBusy = true;

      let data = {
        id: this.editingUser.id,
        role: this.editingUser.role,
      };

      if (this.validateUpdateRole()) {
        $("#mod-edit").modal("hide");

        this.$http
          .put("/Users/updateRole/" + data.id, data)
          .then(function(res) {
            this.isBusyModal = false;
            this.getUsers();
          })
          .catch(function() {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    validateUpdateRole() {
      this.v2 = {
        role: false,
      };
      let isValid = true;

      if (!this.editingUser.role) {
        this.v2.role = true;
        isValid = false;
      }
      return isValid;
    },

    updateBranch() {
      this.isBusyModal = true;
      this.isBusy = true;

      let data = {
        id: this.editingUser.id,
        branchId: this.editingUser.branchId,
      };

      if (this.validateUpdateBranch()) {
        $("#mod-edit-branch").modal("hide");

        this.$http
          .put("/Users/updateBranch/" + data.id, data)
          .then(function(res) {
            this.isBusyModal = false;
            this.getUsers();
          })
          .catch(function() {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    validateUpdateBranch() {
      this.v3 = {
        branch: false,
      };
      let isValid = true;

      if (!this.editingUser.branchId) {
        this.v3.branch = true;
        isValid = false;
      }
      return isValid;
    },

    updateName() {
      this.isBusyModal = true;
      this.isBusy = true;

      let data = {
        id: this.editingUser.id,
        displayName: this.editingUser.displayName,
      };

      if (this.validateUpdateName()) {
        $("#mod-edit-name").modal("hide");

        this.$http
          .put("/Users/updateName/" + data.id, data)
          .then(function(res) {
            this.isBusyModal = false;
            this.getUsers();
          })
          .catch(function() {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    validateUpdateName() {
      this.v5 = {
        name: false,
      };
      let isValid = true;

      if (!this.editingUser.displayName) {
        this.v5.name = true;
        isValid = false;
      }
      return isValid;
    },

    updateEmail() {
      this.isBusyModal = true;
      this.isBusy = true;

      let data = {
        id: this.editingUser.id,
        emailAddress: this.editingUser.emailAddress,
      };

      if (this.validateUpdateEmail()) {
        $("#mod-edit-email").modal("hide");

        this.$http
          .put("/Users/updateEmail/" + data.id, data)
          .then(function(res) {
            this.isBusyModal = false;
            this.getUsers();
          })
          .catch(function() {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    validateUpdateEmail() {
      this.v6 = {
        email: false,
      };
      let isValid = true;

      if (!this.editingUser.emailAddress) {
        this.v6.email = true;
        isValid = false;
      }
      return isValid;
    },

    updatePhone() {
      this.isBusyModal = true;
      this.isBusy = true;

      let data = {
        id: this.editingUser.id,
        phoneNumber: this.editingUser.phoneNumber,
      };

      if (this.validateUpdatePhone()) {
        $("#mod-edit-phone").modal("hide");

        this.$http
          .put("/Users/updatePhoneNumber/" + data.id, data)
          .then(function(res) {
            this.isBusyModal = false;
            this.getUsers();
          })
          .catch(function() {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    validateUpdatePhone() {
      this.v7 = {
        phone: false,
      };
      let isValid = true;

      if (!this.editingUser.phoneNumber) {
        this.v7.phone = true;
        isValid = false;
      }
      return isValid;
    },

    updateStatus(id) {
      this.isBusyModal = true;
      let data = {
        id: this.modalUser.id,
        status:
          this.modalUser.status == "Inactive"
            ? this.modalUser.username
              ? "Active"
              : "Pending"
            : "Inactive",
      };

      $("#mod-delete").modal("hide");

      this.$http
        .put("/Users/updateStatus/" + data.id, data)
        .then(function(res) {
          this.isBusyModal = false;
          this.getUsers();
        })
        .catch(function() {
          this.isBusyModal = false;
        });
      this.getUsers();
    },

    async getUsers() {
      this.users = [];
      this.isBusy = true;
      this.isBusyModal = true;

      await this.$http
        .get(
          "/Users/getAllEnterpriseUsers?pageNumber=" +
            // "/Users/getList?page=" +
            this.pageNumber +
            "&pageSize=" +
            this.pageSize +
            (this.filter.status != "0" ? "&status=" + this.filter.status : "") +
            (this.filter.role != "0" ? "&role=" + this.filter.role : "") +
            (this.filter.qry != "" ? "&query=" + this.filter.qry : "") +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "")
        )
        .then(function(res) {
          this.users = res.body.data;
          this.page = res.body.pageNumber;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.isBusy = false;
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");

      this.isBusy = false;
      this.isBusyModal = false;
    },
    getBranches() {
      var a = true;

      this.$http
        .get(
          "/Branches/getListNoPaging?active=" +
            a +
            "&parentId=" +
            this.user.parentId
        )
        .then(function(res) {
          this.Branches = res.body;
        })
        .catch(function() {});
    },

    formatDate(date) {
      return moment(date).format("ll");
    },

    prev() {
      this.pageNumber -= 1;
      this.getUsers();
    },

    next() {
      this.pageNumber += 1;
      this.getUsers();
    },

    gotoPage(page) {
      this.pageNumber = page - 1;
      this.getUsers();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getUsers();
    },
  },
};
</script>
<style scoped>
.active {
  background-color: #2463eb;
  color: white;
}
</style>
