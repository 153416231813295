<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-add-order-item"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <form @submit.prevent="submit" class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1"><b>Create Order Item</b></h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
            @click.prevent="close"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-5 col-form-label text-sm-right"
              >Select Item Type</label
            >
            <div class="col-12 col-sm-4 col-lg-7">
              <div
                v-for="option in itemTypes"
                :key="option.value"
                class="custom-control custom-radio"
              >
                <input
                  type="radio"
                  :id="option.name"
                  name="itemType"
                  class="custom-control-input"
                  :class="{
                    'is-invalid': $v.itemType.$error,
                  }"
                  :value="option.value"
                  v-model.trim="itemType"
                  @input="setItemType($event.target.value)"
                />
                <label class="custom-control-label" :for="option.name">{{
                  option.name
                }}</label>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-5 col-form-label text-sm-right"
              >Item Name</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <input
                class="form-control"
                :class="{
                  'is-invalid': $v.itemName.$error,
                }"
                type="text"
                placeholder="Enter item name"
                v-model.trim="itemName"
                @input="setItemName($event.target.value)"
              />
              <span class="invalid-feedback" v-if="!$v.itemName.required">
                Item name is required.
              </span>
              <span class="invalid-feedback" v-if="!$v.itemName.minLength">
                Item must have at least
                {{ $v.itemName.$params.minLength.min }}
                letters.
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-5 col-form-label text-sm-right"
              >Select Discount Logic</label
            >
            <div class="col-12 col-sm-4 col-lg-7">
              <div
                v-for="option in subTypes"
                :key="option.value"
                class="custom-control custom-radio"
              >
                <input
                  type="radio"
                  :id="option.name"
                  name="subType"
                  class="custom-control-input"
                  :class="{
                    'is-invalid': $v.subType.$error,
                  }"
                  :value="option.value"
                  :disabled="disableFlat"
                  v-model.trim="subType"
                  @input="setSubType($event.target.value)"
                />
                <label class="custom-control-label" :for="option.name">{{
                  option.name
                }}</label>
              </div>
            </div>
          </div>
          <div v-if="subType === 0" class="form-group row">
            <label class="col-12 col-sm-5 col-form-label text-sm-right"></label>
            <div class="col-12 col-sm-4 col-lg-7">
              <input
                class="form-control"
                :class="{
                  'is-invalid': $v.flat.$error,
                }"
                type="number"
                placeholder="Enter flat amount"
                v-model.trim="flat"
                @input="setFlat($event.target.value)"
              />
              <span class="invalid-feedback" v-if="!$v.flat.min">
                Must be greater than {{ $v.flat.$params.minValue.min }}
              </span>
              <span class="invalid-feedback" v-if="!$v.flat.required">
                Flat amount name is required.
              </span>
            </div>
          </div>
          <div v-else-if="subType === 1" class="form-group row">
            <label class="col-12 col-sm-5 col-form-label text-sm-right"></label>
            <div class="col-12 col-sm-4 col-lg-7">
              <input
                class="form-control"
                :class="{
                  'is-invalid': $v.percentage.$error,
                }"
                type="number"
                v-model="percentage"
                placeholder="Enter percentage"
                @input="setPercentage($event.target.value)"
              />
              <span class="invalid-feedback" v-if="!$v.percentage.between">
                Must be between {{ $v.percentage.$params.between.min }} and
                {{ $v.percentage.$params.between.max }}
              </span>
              <span class="invalid-feedback" v-if="!$v.percentage.required">
                Percentage is required.
              </span>
            </div>
          </div>
          <div v-if="itemType === 2" class="form-group row">
            <label class="col-12 col-sm-5 col-form-label text-sm-right"
              >Set Application Method</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <div
                v-for="option in methods"
                :key="option.value"
                class="custom-control custom-radio"
              >
                <input
                  type="radio"
                  :id="option.name"
                  name="method"
                  class="custom-control-input"
                  :value="option.value"
                  v-model.trim="method"
                  @input="setMethod($event.target.value)"
                />
                <label class="custom-control-label" :for="option.name"
                  >{{ option.name }} Total Order Amount</label
                >
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-secondary"
            type="button"
            @click.prevent="close"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button :disabled="isBusyModal" class="btn btn-primary" type="submit">
            <span v-if="isBusyModal">Busy..</span>
            <span v-else-if="isEdit">Update</span>
            <span v-else>Submit</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  between,
  minValue,
} from "vuelidate/lib/validators";

export default {
  props: ["orderItem", "isEdit"],

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      itemTypes: [
        { name: "Discount Type", value: 0 },
        { name: "Tax Type", value: 1 },
        { name: "Free Type", value: 2 },
      ],

      subTypes: [
        { name: "Flat Amount", value: 0 },
        { name: "% of Total Order Amount", value: 1 },
      ],

      methods: [
        { name: "Addition to", value: 0 },
        { name: "Subtract from", value: 1 },
      ],

      itemName: "",
      itemType: 0,
      subType: 0,
      flat: 1,
      percentage: 1,
      method: 0,
    };
  },

  validations() {
    if (this.subType === 0) {
      return {
        itemName: {
          required,
          minLength: minLength(3),
        },

        itemType: {
          required,
        },

        subType: {
          required,
        },

        flat: {
          minValue: minValue(1),
          required,
        },

        method: {
          required,
        },
      };
    }

    if (this.subType === 1) {
      return {
        itemName: {
          required,
          minLength: minLength(3),
        },

        itemType: {
          required,
        },

        subType: {
          required,
        },

        percentage: {
          between: between(1, 100),
          required,
        },

        method: {
          required,
        },
      };
    }
  },

  computed: {
    disableFlat: function () {
      return this.itemType === 1;
    },
  },

  watch: {
    itemType: function (value) {
      this.subType = value === 1 ? 1 : 0;
      this.method = value === 1 ? 1 : 0;
    },

    subType: function (value) {
      if (value === 0) {
        if (this.isEdit) this.setFlat(this.orderItem.flat);
        else this.setFlat(1);
      }

      if (value === 1) {
        if (this.isEdit) this.setPercentage(this.orderItem.percentage);
        else this.setPercentage(1);
      }
    },

    orderItem: function (value) {
      this.setItemType(value.type);
      this.setItemName(value.name);
      this.setSubType(value.subType);
      this.setMethod(value.method);
      this.setFlat(value.flatAmount);
      this.setPercentage(value.percentage);
    },
  },

  methods: {
    setItemType(value) {
      this.itemType = value;
      this.$v.itemType.$touch();
    },

    setItemName(value) {
      this.itemName = value;
      this.$v.itemName.$touch();
    },

    setSubType(value) {
      this.subType = value;
      this.$v.subType.$touch();
    },

    setPercentage(value) {
      this.percentage = value;
      this.$v.percentage.$touch();
    },

    setMethod(value) {
      this.method = value;
      this.$v.method.$touch();
    },

    setFlat(value) {
      this.flat = value;
      this.$v.flat.$touch();
    },

    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isBusyModal = true;

        try {
          const data = {
            name: this.itemName,
            method: this.method,
            type: this.itemType,
            subType: this.subType,
            flat: this.flat,
            percentage: this.subType === 0 ? 0 : this.percentage,
          };

          let response;
          if (!this.isEdit) {
            response = await this.$http.post(
              "/PurchaseOrders/orderItem/create",
              data
            );
          } else {
            response = await this.$http.put(
              "/PurchaseOrders/orderItem/update/" + this.orderItem.id,
              data
            );
          }

          if (response.ok) {
            this.$emit("onAddComplete");
            this.reset();
          }

          this.isBusyModal = false;
        } catch (error) {
          this.isBusyModal = false;
        }
      }
    },

    async reset() {
      this.setItemType(0);
      this.setItemName("");
      this.setSubType(0);
      this.setMethod(0);

      if (this.subType === 0) {
        this.setFlat(1);
      }

      if (this.subType === 1) {
        this.setPercentage(1);
      }

      setTimeout(() => {
        this.$v.$reset();
      }, 0);
    },

    async close() {
      this.reset();
      this.$emit("hide");
    },
  },
};
</script>

<style>
</style>