<template>
  <div class="row">
    <div class="col">
      <div class="bg-white px-[24px] py-[32px] mt-[16px] rounded-[4px]">
        <div
          class="flex flex-col md:flex-row items-start md:items-center md:justify-between mt-[16px]"
        >
          <p class="text-[#353535] text-[20px] leading-[20px] font-semibold">
            {{ group.name }} (Group)
          </p>
          <div class="btn-group" role="group" aria-label="Basic example">
            <button
              class="border-[#96a0b5] text-[#96a0b5] border-solid border-[1.5px] py-[12px] px-[16px] sm:px-[24px] text-[14px] md:text-[16px] rounded-[8px] font-medium leading-[20px] w-full mt-[10px] md:mt-0"
              v-if="hasPermission('Price_Option_Create')"
              @click="addPriceOption"
            >
              <i class="mdi mdi-plus"></i>
              Add Price Option
            </button>
          </div>
        </div>
        <div class="mt-[16px]">
          <div class="table-responsive">
            <table class="table m-0 p-0 dataTable no-footer">
              <thead>
                <tr>
                  <th class="text-[12px] leading-[18px] text-[#000000]">
                    Product
                  </th>
                  <th class="text-[12px] leading-[18px] text-[#000000]">
                    Product Code
                  </th>
                  <th class="text-[12px] leading-[18px] text-[#000000]">
                    Applied Price Option
                  </th>
                  <th class="text-[12px] leading-[18px] text-[#000000]">
                    Distributor Price
                  </th>
                  <th class="text-[12px] leading-[18px] text-[#000000]">
                    Retail Price
                  </th>
                  <th class="text-[12px] leading-[18px] text-[#000000]">
                    Added
                  </th>
                  <th class="text-[12px] leading-[18px] text-[#000000]">
                    Status
                  </th>
                  <th class="text-[12px] leading-[18px] text-[#000000]">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="op in priceOptions" :key="op.id">
                  <td class="text-[14px] font-medium leading-[16.94px]">
                    {{ productObject(op.product).name }}
                  </td>
                  <td class="text-[14px] font-medium leading-[16.94px]">
                    {{ productObject(op.product).code }}
                  </td>
                  <td class="text-[14px] font-medium leading-[16.94px]">
                    {{ formatMoney(objectValue(op.priceOption, "name")) }}
                  </td>

                  <td class="text-[14px] font-medium leading-[16.94px]">
                    &#8358;
                    {{
                      formatMoney(
                        objectValue(op.priceOption, "distributorPrice")
                      )
                    }}
                  </td>
                  <td class="text-[14px] font-medium leading-[16.94px]">
                    &#8358;
                    {{
                      formatMoney(objectValue(op.priceOption, "retailPrice"))
                    }}
                  </td>
                  <td class="text-[14px] font-medium leading-[16.94px]">
                    {{ formatDate(op.created) }}
                  </td>
                  <td>
                    <SwitchStatus
                      :distributorPrice="op"
                      @onComplete="onActionComplete"
                    />
                  </td>
                  <td>
                    <div>
                      <!-- <router-link
                        :to="{
                          path: `/manufacturer-products/${op.productId}/manage-price-option`,
                        }"
                        data-toggle="tooltip"
                        title="Edit"
                        class="
                              btn btn-primary btn-sm btn-rounded btn-space
                              mr-1
                            "
                      >
                        View
                      </router-link> -->
                      <DeleteAssociation
                        v-if="hasPermission('Price_Option_Delete')"
                        :id="op.id"
                        @onComplete="onActionComplete"
                      />
                    </div>
                  </td>
                </tr>
                <tr v-if="!priceOptions.length">
                  <td colspan="10">
                    <div class="text-center" style="padding-top: 50px">
                      <span
                        style="font-size: 4.615rem"
                        class="fa-solid fa-truck text-[#2463EB]"
                      ></span>
                    </div>
                    <h3
                      class="text-center pt-[32px] text-[14px] font-normal leading-[16.94px]"
                      style="padding-bottom: 100px"
                    >
                      There are no Price Options Associated with this group
                    </h3>
                  </td>
                </tr>
              </tbody>
            </table>
            <Pagination
              :limit="pageSize"
              :count="pageCount"
              :page="page"
              :itemsCount="count"
              @changePage="gotoPage"
              @changeLimit="changeLimit"
            />
          </div>
        </div>
      </div>
    </div>

    <FilterPriceOptionAssociation @onFilter="performFiltering" />
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import { helper } from "@/helper";

import DeleteAssociation from "./DeleteAssociation";
import SwitchStatus from "./SwitchAssociationStatus";
import FilterPriceOptionAssociation from "./FilterPriceOptionAssociation";
import SystemPermissions from "@/permissions";
import Pagination from "../../Pagination.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  components: {
    DeleteAssociation,
    SwitchStatus,
    FilterPriceOptionAssociation,
    Pagination,
  },
  props: ["groupId", "closeModal"],
  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      group: {},
      priceOptions: [],
      count: 0,
      page: 1,
      pageSize: 10,
      hasNext: false,
      hasPrev: false,
      name: "",
      active: "",
      code: "",
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
    pageCount() {
      return Math.ceil(this.count / this.pageSize);
    },
  },
  created() {
    if (!this.hasPermission("Price_Option_View")) {
      this.$router.push("/restricted");
    }
  },
  mounted() {
    this.getGroup();
    this.getGroupPriceOptions();
  },
  methods: {
    productObject(object) {
      if (object != null) {
        return { ...object };
      } else {
      }
    },
    objectValue(object, val) {
      if (object == null) {
        return "";
      }
      return object[val] ? object[val] : "";
    },
    async getGroup() {
      this.isBusy = true;
      try {
        const response = await this.$http.get(
          "/Groups/getById/" + this.groupId
        );
        if (response.ok) this.group = response.body;
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    hideModal() {
      $("#mod-add-price-option").modal("hide");
    },

    async getGroupPriceOptions() {
      this.isBusy = true;
      try {
        const response = await this.$http.get(
          "/Groups/getPriceOptions/" +
            this.groupId +
            "?pageNumber=" +
            (this.page - 1) +
            "&pageSize=" +
            this.pageSize +
            "&name=" +
            this.name +
            "&code=" +
            this.code +
            "&active=" +
            this.active
        );

        if (response.ok) {
          this.priceOptions = response.body.data;
          this.page = response.body.pageNumber + 1;
          // this.pageNumber = response.body.pageNumber;
          this.pageSize = response.body.pageSize;
          this.count = response.body.count;
          this.hasPrev = response.body.hasPrev;
          this.hasNext = response.body.hasNext;
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    onActionComplete() {
      this.getGroupPriceOptions();
    },

    onComplete() {
      this.hideModal();
      this.getGroupPriceOptions();
    },

    addPriceOption() {
      this.closeModal();
      $("#mod-add-price-option").modal("show");
    },

    filter() {
      this.pageNumber = 0;
      $("#mod-rrp-filter").modal("show");
    },

    async performFiltering(filteringOptions) {
      this.name = filteringOptions.name;
      this.code = filteringOptions.code;
      this.active =
        filteringOptions.active == "empty"
          ? ""
          : filteringOptions.active == "true";

      this.getGroupPriceOptions();
      $("#mod-rrp-filter").modal("hide");
    },

    formatDate(date) {
      if (date) return moment(date).format("ll");
      else return "";
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },
    gotoPage(page) {
      this.page = page;
      this.pageNumber = page - 1;
      this.getGroupPriceOptions();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.pageNumber = 0;
      this.getGroupPriceOptions();
    },
  },
};
</script>

<style></style>
