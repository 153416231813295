<template>
    <!--<div class="row match-height" v-if="count > 0">-->
        <div class="row match-height">
            <div class="col-lg-12">
                <p>
                    This analytics for business insights is aimed at helping the Enterprise slice and dice data from ordering and payment activities around their distributor's business in order to
                    inform insights that will help them derive immense value from their business.
                </p>
            </div>

            <div id="select-distributor" class="col-lg-12 full-height dc-select-menu">
                <div><h5 class="text-primary">Select Distributor(s)</h5></div>
            </div>
            <div class="clearfix"></div>

            <div id="select-year" class="col-lg-2 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Select Year</h5></div>

            </div>
            <div id="select-half-year" class="col-lg-2 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Select Half-Year</h5></div>

            </div>
            <div id="select-quarter" class="col-lg-2 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Select Quarter</h5></div>

            </div>
            <div id="select-month" class="col-lg-2 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Select Month</h5></div>

            </div>
            <div id="select-week" class="col-lg-2 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Select Week</h5></div>

            </div>
            <div id="select-day" class="col-lg-2 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Select Day</h5></div>

            </div>

            <div class="text-black-50 col-lg-3 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Total Purchase Order Count</h5></div>
                <div><h2 class="text-black-50 text-bold-700"><div id="total-sales-count"></div></h2></div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Total Purchase Order Volume (₦)</h5></div>
                <div><h2 class="text-black-50 text-bold-700"><div id="total-sales-value"></div></h2></div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Total Purchase Order Payment (₦)</h5></div>
                <div><h2 class="text-black-50 text-bold-700"><div id="total-payment-value"></div></h2></div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12 card-header">
                <div><h3 class="text-danger text-bold-700"><a id="all" href="#">Clear All Filters</a></h3></div>
            </div>

            <div class="clearfix"></div>

            <div id="chart-ring-channel" class="col-lg-3 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Quarterly Purchase Order</h5></div>

            </div>
            <div id="chart-ring-order-status" class="col-lg-3 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Purchase Order by Status</h5></div>

            </div>
            <div id="chart-ring-location" class="col-lg-3 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Purchase Order By Location</h5></div>

            </div>
            <div id="chart-ring-payment-status" class="col-lg-3 col-sm-6 col-12 card-header">
                <div><h5 class="text-primary">Purhase Order By Payment Status</h5></div>

            </div>

            <div id="chart-top-retailer" class="col-lg-6 card-header">
                <div><h5 class="text-primary">Top 5 Distributors</h5></div>

            </div>
            <div id="chart-top-product" class="col-lg-6 card-header">
                <div>
                    <h5 class="text-primary">Top 5 Products</h5>
                </div>

            </div>

            <div id="chart-line-monthly-performance" class="col-lg-12 card-header">

                <div>
                    <h5 class="text-primary">Monthly Purchase Order Performance</h5>
                </div>
            </div>


        </div>
        <!--<div class="row match-height" v-else>
            <div class="col-lg-12">
                <h1 class="text-danger">
                    Oops!!! - <b>{{ count }} records.</b>  Analytics cannot be dispalyed as there is currently no data for visual display.
                </h1>            
              </div>
    </div>-->

</template>

<script>
       /* eslint-disable */
    import moment from "moment";
    import { helper } from "@/helper";
    import * as d3 from "d3";
    import * as dc from "dc";
    import crossfilter from "crossfilter2";
   
    export default {
        data() {
            return {
                isBusyModal: false,
                type: "manufacturer",
                count: 0,
                mpChart: {
                    minDate: null,
                    maxDate: null,
                    now: null,
                    YR1: null,
                    YR2: null,
                    firstyear: null,
                    secondyear: null
                },
                distributorGroup: null,
                distributorDim: null,
                monthDimension: null,
                monthGroup: null
            };

        },
        computed: {
             user: function () {
                return this.$store.state.currentUser;
              }
        },
        methods: {
            generateChart(data) {

	               //const parseDate = d3.timeParse(d3.timeFormat("%m/%d/%Y"));
                   //const parseDate2 = d3.timeParse(d3.timeFormat("%m/%d"));
              
                    this.count = 12;  

                    const dateFormatSpecifier = '%m/%d/%Y';
                    const dateFormatSpecifier2 = '%m/%d/';

                    const parseDate = d3.timeParse(dateFormatSpecifier);
                    const parseDate2 = d3.timeParse(dateFormatSpecifier2);

                  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                                          "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
                                        ];

                data.forEach((item) => { 

                       var dt = new Date(item.ordeR_DATE);
                       //item.date = dt;
                       item.ordeR_DATE = dt;
                       item.qtime = (dt.getMonth()+1)+"/"+dt.getDate();
                       item.Year = +dt.getFullYear();
                       item.Month = +(dt.getMonth() + 1);    
                });

                console.log(data);
             

                   /***********************************************************
                     Facts through Crossfilter
                    ***********************************************************/ 
                    
                     const facts = crossfilter(data)           

                   /***********************************************************
                     Charts Declaration and Tags Mapping 
                   ***********************************************************/

                    const selectquarter = new dc.SelectMenu('#select-quarter');
                    const selectdistributor = new dc.SelectMenu('#select-distributor');
                    const selectyear = new dc.SelectMenu('#select-year');
                    const selecthalfyearly = new dc.SelectMenu('#select-half-year');
                    const selectmonth = new dc.SelectMenu('#select-month');
                    const selectweek = new dc.SelectMenu('#select-week');
                    const selectday = new dc.SelectMenu('#select-day');
                    const quarterlyPieChart = dc.pieChart("#chart-ring-channel");
                    const orderStatusBarChart = dc.barChart("#chart-ring-order-status");
                    const paymentStatusLineChart = dc.lineChart("#chart-ring-payment-status");
                    const totalCountChart = dc.numberDisplay("#total-sales-count");
                    const totalSalesChart = dc.numberDisplay("#total-sales-value");
                    const totalPaymentChart = dc.numberDisplay("#total-payment-value");


                    /***********************************************************
                     Dimensions  
                    ***********************************************************/
                   this.mpChart.dateDim = facts.dimension(item => item.ordeR_DATE);

                   this.monthDimension = facts.dimension(function(d) {return [+d.Year, +d.Month]; });
                this.monthGroup = this.monthDimension.group().reduceSum(function (d) { return +d.ordeR_TOTAL; });


                     // Get Descreasing Year
               this.mpChart.now = new Date();
               this.mpChart.YR1 = "YR-" + (this.mpChart.now.getFullYear()-1);
               this.mpChart.YR2 = "YR-" + this.mpChart.now.getFullYear();

               this.mpChart.minDate = d3.timeMonth(this.mpChart.dateDim.bottom(1)[0].ordeR_DATE);
               this.mpChart.maxDate = d3.timeMonth(this.mpChart.dateDim.top(1)[0].ordeR_DATE);

               this.mpChart.firstyear = this.mpChart.dateDim.group().reduceSum(function(d) {if (d.ordeR_YEAR===this.mpChart.YR1) {return d.ordeR_TOTAL;}else{return 0;}});
               this.mpChart.secondyear = this.mpChart.dateDim.group().reduceSum(function(d) {if (d.ordeR_YEAR===this.mpChart.YR2) {return d.ordeR_TOTAL;}else{return 0;}}); 

                   

                   // let distributorDim = facts.dimension(item => item.distributoR_NAME);
                    let yearDim = facts.dimension(item => item.ordeR_YEAR);
                    let hyDim = facts.dimension(item => item.ordeR_HALF_YEALY);
                    let qaDim  = facts.dimension(item => item.ordeR_QUARTERLY);
                    let monthDim = facts.dimension(item => item.ordeR_MONTHLY);
                    let weekDim = facts.dimension(item => item.ordeR_WEEKLY);
                    let dayDim = facts.dimension(item => item.ordeR_DAILY);

                    let productDim = facts.dimension(item => item.distributoR_PRODUCT_NAME);
                    let quarterlyDim = facts.dimension(item => item.ordeR_QUARTERLY);
                    let locationDim = facts.dimension(item => item.distributoR_STATE);
                    let orderStatusDim = facts.dimension(item => item.ordeR_STATUS);
                    let paymentStatusDim = facts.dimension(item => item.ordeR_PAYMENT_STATUS);
                   
                    let allDim = facts.dimension(item => item);

                    this.distributorDim = facts.dimension(item => item.distributoR_NAME);
                    this.distributorGroup = this.distributorDim.group().reduceSum(item => item.ordeR_TOTAL);

                
                   /***********************************************************
                    Usable Functions and Filter Effects 
                    ***********************************************************/ 
                  function filter_self_group(chart, group) {
                        return {
                            all: function() {
                                var filters = chart.filters();
                                return group.all().filter(kv => !filters.includes(kv.key));
                            }
                        };
                    }
                  function all_is_nothing(N, filterHandler) {
                        return function(dimension, filters) {
                            console.log(filters.length, N)
                            if(filters.length === N)
                                filters = [];
                            return filterHandler(dimension, filters);
                        }
                    }
                    function negate_filters(dimension, filters) {
                        if (filters.length === 0)
                            dimension.filter(null);
                        else
                            dimension.filterFunction(function(d) {
                                return !filters.includes(d);
                            })
                        return filters;
                   }

                
                   function arcGen(chart, offset) {
                        var maxRadius =  d3.min([chart.width(), chart.height()]) / 2;
                        var givenRadius = chart.radius();
                        var radius = givenRadius && givenRadius < maxRadius ? givenRadius : maxRadius;
                        return d3.arc()
                            .outerRadius(radius - chart.externalRadiusPadding() + offset)
                            .innerRadius(chart.innerRadius() + offset);
                      }

                
                   function remove_empty_bins(source_group) {
                            return {
                                all:function () {
                                    return source_group.all().filter(function(d) {
                                        //return Math.abs(d.value) > 0.00001; // if using floating-point numbers
                                        return d.value !== 0; // if integers only
                                    });
                                }
                            };
}

                   /***********************************************************
                    Filters: Dice and Slice 
                    ***********************************************************/ 

                   selectdistributor
                              .dimension(this.distributorDim)
                              .group(this.distributorDim.group())
                               .multiple(true)
                               .numberVisible(10)
                              .controlsUseVisibility(true);
                     selectyear
                              .dimension(yearDim)
                              .group(yearDim.group())
                              .controlsUseVisibility(true);

                     selecthalfyearly
                              .dimension(hyDim)
                              .group(hyDim.group())
                              .controlsUseVisibility(true);

                     selectquarter
                              .dimension(qaDim)
                              .group(qaDim.group())
                              .controlsUseVisibility(true);

                     selectmonth
                              .dimension(monthDim)
                              .group(monthDim.group())
                              .controlsUseVisibility(true);

                     selectweek
                              .dimension(weekDim)
                              .group(weekDim.group())
                              .controlsUseVisibility(true);

                     selectday
                              .dimension(dayDim)
                              .group(dayDim.group())
                              .controlsUseVisibility(true);


                    /****************************************************************
                    Label: Total Sales Volume Display
                    *****************************************************************/
                   // allDim.filter("Distributor");
                    let totalSalesGroup = allDim.group().reduceSum(item => item.ordeR_TOTAL);
                    
                    totalSalesChart.group(totalSalesGroup);

                    /****************************************************************
                    Label: Total Sales Count Display
                    *****************************************************************/
                    totalCountChart.group(allDim.group().reduceCount());

                    /****************************************************************
                    Label: Total Payment Volume Display
                    *****************************************************************/              
                    let totalPaymentGroup = allDim.group().reduceSum(item => item.paiD_AMOUNT);
                    totalPaymentChart.group(totalPaymentGroup);

                   /*****************************************************************
                    PieChart: Quarterly Sales
                    *****************************************************************/              
                    let quarterlyGroup = quarterlyDim.group().reduceSum(item => item.ordeR_TOTAL);
                    let filteredQuarterlyGroup = remove_empty_bins(quarterlyGroup);

                    quarterlyPieChart
                       .width(300).height(300)
                       .legend(dc.legend().x(80).y(120).itemHeight(13).gap(7).highlightSelected(false))  
                       .dimension(quarterlyDim)
                       .group(filter_self_group(quarterlyPieChart, filteredQuarterlyGroup))
                        .innerRadius(100)
                        .ordering(d => {
                           const order = {
                            'Q-1': 0, 'Q-2': 1, 'Q-3': 2, 'Q-4': 3
                              }
                           return order[d.key];
                         }
                       )
                       .drawPaths(true)
                       .renderLabel(false)
                       .controlsUseVisibility(true)
                       .filterHandler(all_is_nothing(filteredQuarterlyGroup.all().length, negate_filters))
                        .renderTitle(false)
                       .on('pretransition', p => {
                           p.selectAll('.dc-legend-item text')
                                          .text('')
                                        .append('tspan')
                                          .text(d => d.name)
                                        .append('tspan')
                                          .attr('x', 140)
                                          .attr('text-anchor', 'end')
                                          .text(d => Math.round(d.data,2).toLocaleString());
                        })
                       .ordinalColors(["#56B2EA", "#00468C", "#00B22D", "#008C8C", "#DDDDDD"]);

                    quarterlyPieChart.on('renderlet', function(chart) {
                             var regArc = arcGen(chart, 0), bigArc = arcGen(chart, 40);
                             chart.selectAll('g.pie-slice path')
                            .attr('d', function(d, i) {
                                return d3.select(this.parentNode).classed('selected') ? bigArc(d, i) : regArc(d, i);
                            });
                    });

                    /*****************************************************************
                    BarChart: Order Status 
                    ******************************************************************/
                   // orderStatusDim.filter("Distributor");
                    let orderStatusGroup = orderStatusDim.group().reduceSum(item => item.ordeR_TOTAL);
                
                    orderStatusBarChart
		                 .width(300)
                        .height(300)
                        .x(d3.scaleOrdinal())
                        .margins({left: 75, top: 10, right: 10, bottom: 35})
                        .xUnits(dc.units.ordinal)
                        .xAxisLabel("Order Status")
   	                    .yAxisLabel("Order Amount")
                        .elasticY(true)
                        .dimension(orderStatusDim)
                        .group(orderStatusGroup)
                        .ordinalColors(["#56B2EA", "#00468C", "#00B22D", "#008C8C", "#DDDDDD"]);

                    /******************************************************************
                    PIE CHART: Payment Status
                    *******************************************************************/
                    //let paymentStatusDim2 = paymentStatusDim.filter((item) => { if (item==="NOT PAID" || item==="PAID" || item==="PARTLY PAID") {return item;} });  
                    let paymentStatusGroup = paymentStatusDim.group().reduceSum(item => item.ordeR_TOTAL);

                     paymentStatusLineChart
                          .width(300)
                          .height(300)
                          .x(d3.scaleBand())
                          .xUnits(dc.units.ordinal)
                          .margins({left: 75, top: 10, right: 10, bottom: 35})
                          .brushOn(true)
                          .xAxisLabel('Payment Status')
                          .yAxisLabel('Payment Amount')
                          .renderArea(false)
                          .elasticY(true)
                          .clipPadding(10)
                          //.renderLabel(true)
                          //.renderTitle(true)
                        //  .renderDataPoints()
                          .dimension(paymentStatusDim)
                          .group(paymentStatusGroup)
                          .ordinalColors(["#56B2EA", "#00468C", "#00B22D", "#008C8C", "#DDDDDD"]);



                    /*********************************************************************
                    MAP: Location 
                    **********************************************************************/
                    const locationRowChart   = dc.rowChart("#chart-ring-location");
                    let locationGroup = locationDim.group().reduceSum(item => item.ordeR_TOTAL);

                   locationRowChart
                    .width(300)
                    .height(300)
                    .dimension(locationDim)
                    .group(locationGroup)
                    .label((d) => {
                        return d.key;
                    })
                    .title((d) => {
                        return Math.round(d.value, 2).toLocaleString();
                    })
                    .renderTitleLabel(true)
                    .elasticX(true)
                    .xAxis().ticks(4);

                

                /*********************************************************************************
                ROW CHART: Top Performing Products
                **********************************************************************************/

                var productRowChart = dc.rowChart("#chart-top-product");   
                var productGroup = productDim.group().reduceSum(item => item.ordeR_TOTAL);
                  
                productRowChart
                    .width(650)
                    .height(300)
                    .dimension(productDim)
                    .group(productGroup)
                    .label((d) => {
                        return d.key;
                    })
                    .title((d) => {
                        return Math.round(d.value, 2).toLocaleString();
                    })
                    .renderTitleLabel(true)
                    .elasticX(true)
                    .xAxis().ticks(4);
                

                productRowChart.rowsCap(5);

                                
               /***************************************************************************
                ROW CHART: Top Performing Distributors
               ****************************************************************************/
                this.getDistributorRowChart();


                /**********************************************************************************
                AREA CHART: Monthly Performance 
                ***********************************************************************************/

                const monthlyPerformanceChart = dc.lineChart("#chart-line-monthly-performance");

                   let dateDim = facts.dimension(item => item.ordeR_DATE );

                    // Get Descreasing Year
                   let now = new Date();
                   let YR1 = "YR-" + (now.getFullYear()-1);
                   let YR2 = "YR-" + now.getFullYear();


                //    let minDate = dateDim.bottom(1)[0].ordeR_DATE;
                //let maxDate = dateDim.top(1)[0].ordeR_DATE;


                    let minDate = d3.timeMonth(dateDim.bottom(1)[0].ordeR_DATE);
                    let maxDate = d3.timeMonth(dateDim.top(1)[0].ordeR_DATE);

     

                    let firstyear=dateDim.group().reduceSum(function(d) {if (d.ordeR_YEAR===YR1) {return +d.ordeR_TOTAL;}else{return 0;}});
                    let secondyear=dateDim.group().reduceSum(function(d) {if (d.ordeR_YEAR===YR2) {return +d.ordeR_TOTAL;}else{return 0;}});
                  

                  monthlyPerformanceChart
                    .width(1300).height(300)
                    .dimension(dateDim)
                    .group(firstyear, YR1)
                      .stack(secondyear, YR2)
                    .renderArea(true)
                    .x(d3.scaleTime().domain([minDate, maxDate]))
                    .margins({left: 75, top: 10, right: 10, bottom: 35})
                    .elasticX(true)
                    .brushOn(true)
                    .legend(dc.legend().x(100).y(10).itemHeight(16).gap(7))
                    .yAxisLabel("Monthly Order Per Year")
                    .ordinalColors(["#56B2EA", "#00468C", "#00B22D", "#008C8C", "#DDDDDD"]);

               // this.getMonthlyPerformanceByYearChart();
              //  this.getMonthlyPerformanceChart();

                dc.renderAll();

                d3.selectAll('a#all').on('click', function() {
				    dc.filterAll();
				    dc.renderAll();
			    });
            
            },
            getDistributorRowChart() {

                var distributorRowChart = dc.rowChart("#chart-top-retailer"); 

                distributorRowChart
                    .width(650)
                    .height(300)
                    .group(this.distributorGroup)
                    .dimension(this.distributorDim)
                        .label((d) => {
                            return d.key;
                        })
                        .title((d) => {
                          return Math.round(d.value,2).toLocaleString(); 
                        })
                    .renderTitleLabel(true)
                        .elasticX(true)
                        .xAxis().ticks(4);

                distributorRowChart.rowsCap(5);

            },
            getMonthlyPerformanceByYearChart() {

                 //.ordering(d => {
                 //          const order = {
                 //           'Q-1': 0, 'Q-2': 1, 'Q-3': 2, 'Q-4': 3
                 //             }
                 //          return order[d.key];
                 ////        }

                 //    {
                 //         const m = {
                 //           'Jan': 1, 'Feb': 2, 'Mar': 3, 'Apr': 4, 'May': 5, 'Jun': 6,
                 //           'Jul': 7, 'Aug': 8, 'Sept': 9, 'Oct': 10, 'Nov':11, 'Dec':12
                 //       }

                var minDate = new Date("01/01/1900");
	            var maxDate = new Date("12/30/1900");

                var monthYearPerformanceChart = new dc.SeriesChart("#chart-line-monthly-performance");

                monthYearPerformanceChart
                    .width(768)
                    .height(480)
                    .chart((c) => { return new dc.LineChart(c).curve(d3.curveCardinal); })
                    .x(d3.scaleLinear().domain([0, 12]))
                    //.x(d3.time.scale().domain([minDate, maxDate]))
                    .brushOn(false)
                    .yAxisLabel("Order Amount")
                    .xAxisLabel("Month")
                    .clipPadding(10)
                    .elasticY(true)
                    .dimension(this.monthDimension)
                    .group(this.monthGroup)
                    .mouseZoomable(true)
                    .seriesAccessor(d => {return "Year: " + d.key[0]; } )
                    .keyAccessor(d => { return +d.key[1];  })
                   /// .seriesSort(d3.ascending)
                    .valueAccessor(function(d) {return +d.value;})
                    .legend(dc.legend().x(350).y(350).itemHeight(13).gap(5).horizontal(1).legendWidth(140).itemWidth(70));

                monthYearPerformanceChart.yAxis().tickFormat(function(d) {return d3.format(',d')(d);});
                monthYearPerformanceChart.margins().left += 40;

            },
            getMonthlyPerformanceChart() {

               var monthlyPerformanceChart = dc.lineChart("#chart-line-monthly-performance");      
                 
               monthlyPerformanceChart
                      .width(1300).height(300)
                      .dimension(this.mpChart.dateDim)
                      .group(this.mpChart.firstyear, this.mpChart.YR1)
                      .stack(this.mpChart.secondyear, this.mpChart.YR2)
                      .renderArea(true)
                      .renderDataPoints(true)
                      .xUnits(d3.timeMonths)
                      .x(d3.scaleTime().domain([this.mpChart.minDate, this.mpChart.maxDate]))
                     // .margins({ left: 75, top: 10, right: 10, bottom: 35 })
                      .margins({top:10,bottom:80,right:25,left:70})
                      .elasticX(true)
                      .brushOn(true)
                      .clipPadding(10)
                      //.legend(dc.legend().x(100).y(10).itemHeight(16).gap(7))
                      .legend(dc.legend().y(270).x(1000).itemHeight(12).gap(5))
                      .yAxisLabel("Monthly Order Per Year")
                      .ordinalColors(["#56B2EA", "#00468C", "#00B22D", "#008C8C", "#DDDDDD"]);

                     // monthlyPerformanceChart.xAxis()
    	                //              .ticks(12)
    	                //              .tickFormat(d3.timeFormat('%B'))
                     //.monthlyPerformanceChart.yAxis().ticks(10);

            },
            async fetchData() {
             this.isBusy = true;
              await this.$http
                .get("/Analytics/getAnalyticsData?parentId=" + this.user.parentId + "&type=" +
                        this.type)
                .then((res) => {
                    this.generateChart(res.body.data);
                    this.count = res.body.count;
                    this.isBusy = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.isBusy = false;
                });
             this.isBusy = false;

            },
            printFilter(filter) {               
             return function print_filter(filter) {
                  var f=eval(filter);
                  if (typeof(f.length) != "undefined") {}else{}
                  if (typeof(f.top) != "undefined") {f=f.top(Infinity);}else{}
                  if (typeof(f.dimension) != "undefined") {f=f.dimension(function(d) { return "";}).top(Infinity);}else{}
                  console.log(filter+"("+f.length+") = "+JSON.stringify(f).replace("[","[\n\t").replace(/}\,/g,"},\n\t").replace("]","\n]"));
              }
            },
            formatMoney(val) {
                return helper.formatMoney(val);
            },
            formatNumber(val) {
                return helper.formatNumber(val);
            },
            formatDate(val) {
                return helper.formatDate(val);
            },
        },
        mounted() { 
            this.fetchData();
           }
    }
</script>