<template>
  <div class="row min-h-screen shadow-none">
    <div class="table-responsive">
      <table class="table p-0 m-0 pb-[50px]">
        <thead>
          <tr>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Stage Name
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Description
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Status
            </th>
            <th class="text-[12px] leading-[18px] text-[#000000] font-semibold">
              Actions
            </th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="o in orderStages" :key="o.id">
            <td class="text-[14px] font-normal leading-[16.94px]">
              {{ o.name }}
            </td>
            <td class="text-[14px] font-normal leading-[16.94px]">
              {{ o.description ? o.description : "Description goes here" }}
            </td>
            <td class="text-[14px] font-normal leading-[16.94px]">
              <SwitchStageStatus
                v-if="
                  o.name !== 'Dispatched' &&
                    hasPermission('Sales_Order_Stages_Update')
                "
                :stage="o"
                @onComplete="onComplete"
              />
            </td>
            <td @click="toggleDropdown(o.id)">
              <div class="dropdown">
                <button
                  class="btn-no-border"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span
                    class="text-[#98a2b3] dropdown text-2xl cursor-pointer relative outline-none active:outline-none shadow-none"
                  >
                    ...
                  </span>
                </button>
                <ul
                  class="dropdown-menu shadow-none"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li
                    class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer border-b border-[#e5e7eb] py-[12px] px-[16px]"
                    v-if="
                      o.name !== 'Dispatched' &&
                        hasPermission('Sales_Order_Stages_Update')
                    "
                    @click.prevent="renameOrderItem(o)"
                  >
                    Rename
                  </li>
                  <HideStage
                    v-if="
                      o.name !== 'Dispatched' &&
                        hasPermission('Sales_Order_Stages_Update')
                    "
                    :stage="o"
                    :ref="o.id"
                    @onComplete="onComplete"
                    @showHideCaution="showCaution"
                  />
                  <li
                    @click="updateStatus(o.id, o.active)"
                    class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer py-[12px] px-[16px]"
                  >
                    <span v-if="!o.active">Activate</span>
                    <span
                      v-else
                      class="text-[14px] font-normal leading-[20px] text-[#DC2828] cursor-pointer py-[12px]"
                      >Deactivate</span
                    >
                  </li>
                </ul>
              </div>
            </td>
          </tr>
          <tr v-if="!orderStages.length">
            <td colspan="10">
              <div class="text-center" style="padding-top: 50px">
                <span
                  style="font-size: 4.615rem"
                  class="fa-solid fa-spinner fa-spin text-[#2463EB]"
                ></span>
              </div>
              <h3
                class="text-center pt-[32px] text-[14px] font-normal leading-[16.94px]"
                style="padding-bottom: 100px"
              >
                Please wait while set you up
              </h3>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <RenameStageModal
      @onRenameComplete="onRenameCompleteHandler"
      :stage="orderStage"
    />
    <HideStageCaution @proceed="perfomHide" :text="text" />
    <div
      class="modal bg-[rgba(0, 0, 0, 1)] fade py-1 z-[111111111]"
      id="mod-hide"
      role="dialog"
    >
      <div class="modal-dialog mx-auto">
        <div class="modal-content p-[24px] bg-[#f8f8f8]">
          <div class="modal-header flex items-start justify-between p-0 m-0">
            <h4
              class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
            >
              Confirmation
            </h4>
            <button
              class="text-2xl text-[#353535]"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body m-0 p-0">
            <p class="text-[14px] leading-[20px] font-normal text-[#353535]">
              Status will be hidden from the distributor and will not be shown
              during processing
            </p>
            <p>
              Stage will make it visible to the distributor if used during
              processing."
            </p>
          </div>
          <div class="flex items-center gap-[8px] mt-[16px]">
            <button
              class="border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full outline-none focus:outline-none"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] w-full outline-none focus:outline-none"
              type="button"
              :disabled="isSearching"
            >
              <i v-if="isSearching" class="fas fa-spinner fa-spin mr-2"></i>

              {{ isSearching ? "Busy..." : "Proceed" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwitchStageStatus from "./SwitchStageStatus";
import HideStage from "./HideStage.vue";
import HideStageCaution from "./HideStageCaution";
import RenameStageModal from "./RenameStageModal";
import SystemPermissions from "@/permissions";

export default {
  mixins: [SystemPermissions.Mixin],
  components: {
    SwitchStageStatus,
    HideStage,
    HideStageCaution,
    RenameStageModal,
  },

  data() {
    return {
      isBusy: false,
      isSearching: false,
      showDropdown: null,
      orderStages: [],
      orderStage: {},
      id: "",
      text: "",
    };
  },
  async created() {
    if (!this.hasPermission("Sales_Order_Stages_View")) {
      this.$router.push("/restricted");
    }
  },

  mounted() {
    this.getOrderStages();
  },

  methods: {
    hideShowModal() {
      $("#mod-hide").modal("show");
    },
    async perfomHide() {
      try {
        const response = await this.$http.put(
          "/PurchaseOrders/orderStage/hide/" + this.id
        );

        if (response.ok && response.status === 200) {
          await this.getOrderStages();
        }
        this.hideLoading = false;
      } catch (error) {
        this.hideLoading = false;
      }
    },
    toggleDropdown(userId) {
      this.showDropdown = this.showDropdown === userId ? null : userId;
    },
    async getOrderStages() {
      this.isBusy = true;
      try {
        const response = await this.$http.get(
          "/PurchaseOrders/orderStage/getList"
        );

        if (response.ok && response.status === 200)
          this.orderStages = response.body.sort(
            (firstItem, secondItem) => firstItem.stage - secondItem.stage
          );

        this.isBusy = false;
      } catch (error) {
        this.isBusy = true;
      }
    },

    renameOrderItem(orderStage) {
      this.orderStage = orderStage;
      // eslint-disable-next-line no-undef
      $("#mod-rename-stage").modal("show");
    },

    onRenameCompleteHandler() {
      this.orderStage = {};
      this.getOrderStages();
      // eslint-disable-next-line no-undef
      $("#mod-rename-stage").modal("hide");
    },

    showCaution({ id, text }) {
      console.log("ID:", id);
      this.id = id;
      this.text = text;
      console.log(this.text);
      // eslint-disable-next-line no-undef
      $("#mod-hide-order-stage").modal("show");
    },
    async updateStatus(id, status) {
      let stats = !status;
      try {
        this.isBusy = true;
        const response = await this.$http.put(
          `/PurchaseOrders/orderStage/update/${id}/${stats}`
        );
        if (response.ok && response.status === 200) {
          await this.getOrderStages();
        }
        this.hideLoading = false;
      } catch (error) {
        this.hideLoading = false;
      }
    },
    onComplete() {
      this.getOrderStages();
    },
  },
};
</script>

<style></style>
