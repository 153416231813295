<template>
  <div class="row mt-5">
    <div class="col mb-2">
      <div class="d-flex justify-content-between">
        <h4 class="font-weight-bold">Purchase Order Details</h4>
        <button class="btn btn-success" type="button" @click="addItem">
          <i class="icon icon-left mdi mdi-plus text-white"></i>
          Add Item
        </button>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-hover table-sm">
        <thead class="thead-light">
          <tr>
            <th scope="col">Item #</th>
            <th scope="col">Description</th>
            <th scope="col">Quantity</th>
            <th scope="col">Total</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.quantity }}</td>
            <td>&#8358;{{ formatMoney(item.quantity * item.price) }}</td>
            <td>
              <button
                class="btn btn-secondary btn-sm btn-rounded btn-space mr-1"
                @click.prevent="removeItem(item.id)"
              >
                <i class="icon mdi mdi-delete text-white"></i>
                Remove
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { helper } from "@/helper";

export default {
  name: "PurchaseOrderDetail",
  props: ["items"],

  methods: {
    addItem() {
      // eslint-disable-next-line no-undef
      $("#mod-item-add").modal("show");
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    removeItem(id) {
      this.$emit("onDeleteItem", id);
    },
  },
};
</script>

<style>
</style>