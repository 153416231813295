<template>
  <div class="row">
    <div class="col">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header d-flex justify-content-between">
          <p class="card-title">
            Manage price options for
            {{ product.name }}
          </p>
          <div class="btn-group" role="group" aria-label="Basic example">
            <button
              v-if="hasPermission('Price_Option_Create')"
              class="btn btn-primary btn-rounded btn-space"
              @click="addOption"
            >
              <i class="icon icon-left mdi mdi-plus text-white"></i>
              Add Price Option
            </button>
            <button class="btn btn-info btn-rounded btn-space" @click="filter">
              <i class="feather icon-filter text-white"></i>
              Filter
            </button>
            <button
              class="btn btn-success btn-rounded btn-space"
              @click="goBack"
            >
              <i class="mdi icon mdi-backburger text-white"></i>
              Back
            </button>
          </div>
        </div>
        <div class="card-body">
          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table
                  class="
                    table table-fw-widget
                    dataTable
                    no-footer
                    table-responsiv table-striped table-hover-animation
                  "
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Option ID</th>
                      <th>Description</th>
                      <th>Distributor Price</th>
                      <th>Retail Price</th>
                      <th>Last Modified</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="op in priceOptions" :key="op.id">
                      <td>{{ op.name }}</td>
                      <td>{{ op.optionId }}</td>
                      <td>{{ op.description }}</td>
                      <td>&#8358; {{ formatMoney(op.distributorPrice) }}</td>
                      <td>&#8358; {{ formatMoney(op.retailPrice) }}</td>
                      <td>{{ formatDate(op.updated) }}</td>
                      <td>
                        <SwitchStatus
                          :optionPrice="op"
                          @onComplete="onActionComplete"
                        />
                      </td>
                      <td>
                        <button
                          class="btn btn-outline-primary btn-sm dropdown-toggle"
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Actions
                        </button>
                        <div
                          class="dropdown-menu px-1"
                          aria-labelledby="dropdownMenu2"
                        >
                          <a
                            v-if="hasPermission('Price_Option_Update')"
                            class="dropdown-item btn btn-text btn-sm mb-1"
                            @click.prevent="editPriceOption(op)"
                          >
                            Edit
                          </a>
                          <a
                            v-if="hasPermission('Price_Option_Archive')"
                            class="dropdown-item btn btn-text btn-sm mb-1"
                            @click.prevent="openArchiveCaution(op.id)"
                          >
                            <span class="text-warning">Archive</span>
                          </a>
                          <DeletePriceOption
                            v-if="hasPermission('Price_Option_Delete')"
                            :id="op.id"
                            :isTextBtn="true"
                            @onComplete="onActionComplete"
                            @showCaution="onDelete"
                            :ref="op.id"
                          />
                        </div>
                        <!-- <div class="d-flex">
													<button
														v-if="hasPermission('Price_Option_Update')"
														data-toggle="tooltip"
														title="Edit"
														class="
                              btn btn-success btn-sm btn-rounded btn-space
                              mr-1
                            "
														@click.prevent="editPriceOption(op)"
													>
														<i
															class="
                                icon
                                mdi mdi-circle-edit-outline
                                text-white
                              "
														></i>
													</button>
													<button
														v-if="hasPermission('Price_Option_Archive')"
														data-toggle="tooltip"
														title="Archive"
														class="btn btn-warning btn-sm btn-rounded btn-space mr-1"
														@click.prevent="openArchiveCaution(op.id)"
													>
														<i
															class="icon mdi mdi-book text-white"
														></i>
													</button>
													<DeletePriceOption
														v-if="hasPermission('Price_Option_Delete')"
														:id="op.id"
														@onComplete="onActionComplete"
														@showCaution="onDelete"
														:ref="op.id"
													/>
												</div> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Pagination
                  :limit="pageSize"
                  :count="pageCount"
                  :page="page"
                  :itemsCount="count"
                  @changePage="gotoPage"
                  @changeLimit="changeLimit"
                />
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }} Distributor price{{ count != 1 ? "s" : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddEdit
      :priceOption="priceOption"
      :showUpdate="showUpdate"
      @hide="hideModal"
      @onComplete="onActionComplete"
      :productId="product.id"
    />
    <DeleteCaution :id="id" @proceed="deletePriceOption" entity="DP" />
    <ArchiveCaution :id="id" @proceed="archivePriceOption" entity="DP" />
    <PriceOptionFilter @onFilter="performFiltering" />
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import { helper } from "@/helper";
import AddEdit from "../components/PriceOption/AddEditPriceOption";
import DeletePriceOption from "../components/ManageOptionPrice/DeletePriceOption";
import DeleteCaution from "../components/DeleteCaution.vue";
import ArchiveCaution from "../components/ArchiveCaution.vue";
import PriceOptionFilter from "../components/PriceOption/Filter";
import SwitchStatus from "../components/ManageOptionPrice/SwitchPriceOptionStatus";
import SystemPermissions from "@/permissions";
import Pagination from "../components/Pagination.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  components: {
    AddEdit,
    DeleteCaution,
    PriceOptionFilter,
    DeletePriceOption,
    SwitchStatus,
    Pagination,
    ArchiveCaution,
  },

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      name: "",
      active: "",
      count: 0,
      page: 1,
      pageNumber: 0,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
      showUpdate: false,
      priceOptions: [],
      priceOption: null,
      activeLoading: false,
      deleteLoading: false,
      id: null,
      product: {},
      archiveId: null,
    };
  },
  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
    // sortedArray: function() {
    // 	function compare(a, b) {
    // 		if (a.name < b.name) return -1;
    // 		if (a.name > b.name) return 1;
    // 		return 0;
    // 	}

    // 	return this.priceOptions.sort(compare);
    // },
  },

  created() {
    if (!this.hasPermission("Price_Option_View")) {
      this.$router.push("/restricted");
    }
    this.productId = this.$route.params.id;
    this.getProduct();
    this.getPriceOptions();
  },

  methods: {
    async getProduct() {
      this.isBusy = true;
      try {
        const response = await this.$http.get(
          "/Products/getByIdForManufacturer/" + this.productId
        );
        if (response.ok) this.product = response.body;
        console.log(response);
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    addOption() {
      $("#mod-price-option-add").modal("show");
    },

    editPriceOption(priceOption) {
      this.showUpdate = true;
      this.priceOption = priceOption;
      $("#mod-price-option-add").modal("show");
    },

    openArchiveCaution(id) {
      this.archiveId = id;
      $("#mod-archive-caution").modal("show");
    },

    async archivePriceOption() {
      this.isBusy = true;
      try {
        const response = await this.$http.put(
          "/priceoptions/archive/" + this.archiveId
        );
        if (response.ok) {
          this.getPriceOptions();
          $("#mod-archive-caution").modal("hide");
          this.$toast.success("Price option archived successfully");
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    onDelete(id) {
      this.id = id;
      $("#mod-delete-caution").modal("show");
    },

    onActionComplete() {
      this.getPriceOptions();
    },

    async deletePriceOption(id) {
      $("#mod-delete-caution").modal("hide");
      const componenet = this.$refs[id][0];
      componenet.deleteDistributorPrice();
    },

    filter() {
      $("#mod-dp-filter").modal("show");
    },

    hideModal() {
      $("#mod-price-option-add").modal("hide");
      this.showUpdate = false;
      this.priceOption = null;
    },

    onActionComplete() {
      this.hideModal();
      this.getPriceOptions();
    },

    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },

    async getPriceOptions() {
      try {
        this.isBusy = true;

        const response = await this.$http.get(
          "/PriceOptions/getList?pageNumber=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            "&name=" +
            this.name +
            "&active=" +
            this.active +
            "&ProductId=" +
            this.productId
        );
        if (response.ok && response.status === 200) {
          this.priceOptions = response.body.data.sort(function(x, y) {
            let a = x.name.toUpperCase(),
              b = y.name.toUpperCase();
            return a == b ? 0 : a > b ? 1 : -1;
          });
          this.page = response.body.pageNumber;
          this.pageSize = response.body.pageSize;
          this.count = response.body.count;
          this.hasPrev = response.body.hasPrev;
          this.hasNext = response.body.hasNext;
          this.pageCount = Math.ceil(
            response.body.count / response.body.pageSize
          );
        }
      } catch (error) {
        this.isBusy = false;
      }
    },

    async performFiltering(filteringOptions) {
      this.name = filteringOptions.name;
      this.active =
        filteringOptions.active == "empty"
          ? ""
          : filteringOptions.active == "true";

      this.getPriceOptions();
      $("#mod-dp-filter").modal("hide");
    },

    formatDate(date) {
      if (date) return moment(date).format("lll");
      else return "";
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },
    prev() {
      this.page -= 1;
      this.getPriceOptions();
    },
    next() {
      this.page += 1;
      this.getPriceOptions();
    },

    gotoPage(page) {
      this.page = page;
      this.getPriceOptions();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getPriceOptions();
    },
  },
};
</script>

<style></style>
