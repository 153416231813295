<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-del-order-item"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body p-4">
          <h4 class="text-center">
            This action will delete this item and can no longer be used.
            Proceed?
          </h4>
        </div>
        <div
          class="modal-footer d-flex justify-content-center align-items-center"
        >
          <button class="btn btn-danger" type="button" data-dismiss="modal">
            No, Cancel
          </button>
          <button
            class="btn btn-success"
            type="button"
            @click.prevent="onSubmitClick"
          >
            Yes, Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeleteOrderItemCaution",

  methods: {
    onSubmitClick() {
      this.$emit("proceed");
    },
  },
};
</script>

<style>
</style>