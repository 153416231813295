<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-border-color card-border-color-primary">
        <div class="card-header"></div>
        <div class="card-body">
          <form>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >System Administration</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': v1.defaultUserEmailAddress },
                    { 'is-invalid': v1.defaultUserEmailAddressValid },
                  ]"
                  type="email"
                  v-model="account.defaultUserEmailAddress"
                  placeholder="Enter email address of the primary user for this manufacturer"
                />
                <ul
                  v-if="v1.defaultUserEmailAddress"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Required.</li>
                </ul>
                <ul
                  v-if="v1.defaultUserEmailAddressValid"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Enter a valid email address.</li>
                </ul>
              </div>
            </div>

            <hr />

            <h4>Business profile</h4>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Business Name</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  :class="['form-control', { 'is-invalid': v1.name }]"
                  type="text"
                  v-model="account.name"
                />
                <ul v-if="v1.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Business Description</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <textarea
                  :class="['form-control', { 'is-invalid': v1.description }]"
                  v-model="account.description"
                ></textarea>
                <ul v-if="v1.description" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Business Registration</label
              >
              <div class="col-12 col-sm-4 col-lg-3">
                <input
                  :class="['form-control', { 'is-invalid': v1.rcNumber }]"
                  type="text"
                  v-model="account.rcNumber"
                  placeholder="RC Number"
                />
                <ul v-if="v1.rcNumber" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
              <div class="col-12 col-sm-4 col-lg-3">
                <input
                  class="form-control"
                  type="text"
                  v-model="account.tin"
                  placeholder="Tax Identification Number"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Website</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  :class="['form-control', { 'is-invalid': v1.websiteValid }]"
                  type="text"
                  v-model="account.website"
                />
                <ul v-if="v1.websiteValid" class="parsley-errors-list filled">
                  <li class="parsley-required">Enter a valid url.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Business Email Address</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': v1.emailAddress },
                    { 'is-invalid': v1.emailAddressValid },
                  ]"
                  type="email"
                  v-model="account.emailAddress"
                />
                <ul v-if="v1.emailAddress" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
                <ul
                  v-if="v1.emailAddressValid"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Enter a valid email address.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Business Phone Number</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': v1.phoneNumber },
                    { 'is-invalid': v1.phoneNumberValid },
                  ]"
                  type="text"
                  v-model="account.phoneNumber"
                />
                <ul v-if="v1.phoneNumber" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
                <ul
                  v-if="v1.phoneNumberValid"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Enter a valid phone number.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Business Address</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <select
                  class="form-control"
                  placeholder="State"
                  v-model="account.state"
                >
                  <option v-for="(s, i) in states" :key="i" :value="s">
                    {{ s }}
                  </option>
                </select>
                <ul v-if="v1.state" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label
                class="col-12 col-sm-3 col-form-label text-sm-right"
              ></label>
              <div class="col-12 col-sm-8 col-lg-6">
                <select
                  class="form-control"
                  placeholder="Local Government Area"
                  v-model="account.lga"
                >
                  <option v-for="(l, i) in lgas" :key="i" :value="l">
                    {{ l }}
                  </option>
                </select>
                <ul v-if="v1.lga" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label
                class="col-12 col-sm-3 col-form-label text-sm-right"
              ></label>
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  :class="['form-control', { 'is-invalid': v1.city }]"
                  type="text"
                  v-model="account.city"
                  placeholder="City"
                />
                <ul v-if="v1.city" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label
                class="col-12 col-sm-3 col-form-label text-sm-right"
              ></label>
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  :class="['form-control', { 'is-invalid': v1.address1 }]"
                  type="text"
                  v-model="account.address1"
                  placeholder="Address Line 1"
                />
                <ul v-if="v1.address1" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label
                class="col-12 col-sm-3 col-form-label text-sm-right"
              ></label>
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  class="form-control"
                  type="text"
                  v-model="account.address2"
                  placeholder="Address Line 2"
                />
              </div>
            </div>

            <hr />

            <h4>Contact information</h4>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Contact Person's Name</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  :class="['form-control', { 'is-invalid': v2.name }]"
                  type="text"
                  v-model="contact.name"
                />
                <ul v-if="v2.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Email Address</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': v2.emailAddress },
                    { 'is-invalid': v2.emailAddressValid },
                  ]"
                  type="email"
                  v-model="contact.emailAddress"
                />
                <ul v-if="v2.emailAddress" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
                <ul
                  v-if="v2.emailAddressValid"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Enter a valid email address.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Phone Number</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': v2.phoneNumber },
                    { 'is-invalid': v2.phoneNumberValid },
                  ]"
                  type="text"
                  v-model="contact.phoneNumber"
                />
                <ul v-if="v2.phoneNumber" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
                <ul
                  v-if="v2.phoneNumberValid"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Enter a valid phone number.</li>
                </ul>
              </div>
            </div>

            <hr />

            <h4>Bank information</h4>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Bank</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="bank.name"
                >
                  <option v-for="b in banks" :key="b" :value="b">
                    {{ b }}
                  </option>
                </select>
                <ul v-if="v3.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Account Number</label
              >
              <div class="col-12 col-sm-8 col-lg-6">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': v3.accountNumber },
                    { 'is-invalid': v3.accountNumberValid },
                  ]"
                  type="text"
                  v-model="bank.accountNumber"
                />
                <ul v-if="v3.accountNumber" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
                <ul
                  v-if="v3.accountNumberValid"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">
                    Enter a valid account number.
                  </li>
                </ul>
              </div>
            </div>

            <!-- <hr />
                            <h4>Other information</h4>
                    
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Credit Limit</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <label class="custom-control custom-checkbox custom-control-inline">
                                        <input class="custom-control-input" type="checkbox" v-model="account.hasCredit"><span class="custom-control-label">This manufacturer has access to credit facilities</span>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group row" v-if="account.hasCredit">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <input :class="['form-control', { 'is-invalid':v1.creditLimit }]" type="number" v-model="account.creditLimit" placeholder="Enter credit limit">
                                    <ul v-if="v1.creditLimit" class="parsley-errors-list filled"><li class="parsley-required">Enter a valid number.</li></ul>
                                </div>
            </div>-->
            <div class="form-group row">
              <label
                class="col-12 col-sm-3 col-form-label text-sm-right"
              ></label>
              <div class="col-12 col-sm-8 col-lg-6">
                <button
                  :disabled="isBusy"
                  style="width: 200px"
                  class="btn btn-space btn-primary"
                  @click.prevent="create"
                >
                  <span v-if="isBusy">Busy...</span>
                  <span v-else>Save manufacturer</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { helper } from "@/helper";
import Select2 from "@/components/Select2";

export default {
  name: "manufacturersCreate",
  components: {
    Select2,
  },
  data() {
    return {
      isBusy: false,
      account: {},
      contact: {},
      bank: {},
      banks: helper.getBanks(),
      states: helper.getStates(),
      v1: {
        rcNumber: false,
        name: false,
        description: false,
        defaultUserEmailAddress: false,
        defaultUserEmailAddressValid: false,
        emailAddress: false,
        emailAddressValid: false,
        websiteValid: false,
        phoneNumber: false,
        phoneNumberValid: false,
        address1: false,
        city: false,
        lga: false,
        state: false,
        creditLimit: false,
      },
      v2: {
        name: false,
        emailAddress: false,
        emailAddressValid: false,
        phoneNumber: false,
        phoneNumberValid: false,
      },
      v3: {
        name: false,
        accountNumber: false,
        accountNumberValid: false,
      },
    };
  },
  watch: {
    "account.hasCredit": function (newType, oldType) {
      this.account.creditLimit = 0;
    },
  },
  created() {
    this.initialize();
  },
  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
    lgas: function () {
      return helper.getLgas(this.account.state);
    },
  },
  methods: {
    initialize() {
      this.account = {
        name: null,
        description: null,
        defaultUserEmailAddress: null,
        emailAddress: null,
        phoneNumber: null,
        website: null,
        address1: null,
        address2: null,
        city: null,
        lga: null,
        state: null,
        rcNumber: null,
        tin: null,
        hasCredit: false,
      };

      this.contact = {
        name: null,
        emailAddress: null,
        phoneNumber: null,
      };

      this.bank = {
        name: null,
        accountNumber: null,
      };
    },
    create() {
      this.isBusy = true;

      if (this.validateMerchant()) {
        var data = {
          name: this.account.name,
          description: this.account.description,
          accountType: "Manufacturer",
          rcNumber: this.account.rcNumber,
          tin: this.account.tin,
          emailAddress: this.account.emailAddress,
          phoneNumber: this.account.phoneNumber,
          website: this.account.website,
          address1: this.account.address1,
          address2: this.account.address2,
          city: this.account.city,
          lga: this.account.lga,
          state: this.account.state,
          active: true,
          defaultUserEmailAddress: this.account.defaultUserEmailAddress,
          parentId: this.user.parentId,
          contact: {
            name: this.contact.name,
            emailAddress: this.contact.emailAddress,
            phoneNumber: this.contact.phoneNumber,
          },
          bank: {
            name: this.bank.name,
            accountNumber: this.bank.accountNumber,
          },
          hasCredit: false, // this.account.hasCredit,
          creditLimit: 0, //this.account.creditLimit
        };

        this.$http
          .post("/Accounts/create", data)
          .then(function (res) {
            this.isBusy = false;
            this.$router.push("/manufacturers");
          })
          .catch(function () {
            this.isBusy = false;
          });
      } else {
        this.isBusy = false;
      }
    },
    validateMerchant() {
      this.v1 = {
        rcNumber: false,
        name: false,
        description: false,
        defaultUserEmailAddress: false,
        defaultUserEmailAddressValid: false,
        emailAddress: false,
        emailAddressValid: false,
        websiteValid: false,
        phoneNumber: false,
        phoneNumberValid: false,
        address1: false,
        city: false,
        lga: false,
        state: false,
        creditLimit: false,
      };
      var isValid = true;

      if (!this.account.rcNumber) {
        this.v1.rcNumber = true;
        isValid = false;
      }

      if (!this.account.name) {
        this.v1.name = true;
        isValid = false;
      }

      if (!this.account.description) {
        this.v1.description = true;
        isValid = false;
      }

      if (!this.account.defaultUserEmailAddress) {
        this.v1.defaultUserEmailAddress = true;
        isValid = false;
      }

      if (
        this.account.defaultUserEmailAddress &&
        !helper.validateEmail(this.account.defaultUserEmailAddress)
      ) {
        this.v1.defaultUserEmailAddressValid = true;
        isValid = false;
      }

      if (this.account.website && !helper.validateUrl(this.account.website)) {
        this.v1.websiteValid = true;
        isValid = false;
      }

      if (!this.account.emailAddress) {
        this.v1.emailAddress = true;
        isValid = false;
      }

      if (
        this.account.emailAddress &&
        !helper.validateEmail(this.account.emailAddress)
      ) {
        this.v1.emailAddressValid = true;
        isValid = false;
      }

      if (!this.account.phoneNumber) {
        this.v1.phoneNumber = true;
        isValid = false;
      }

      if (
        this.account.phoneNumber &&
        !helper.validatePhone(this.account.phoneNumber)
      ) {
        this.v1.phoneNumberValid = true;
        isValid = false;
      }

      if (!this.account.address1) {
        this.v1.address1 = true;
        isValid = false;
      }

      if (!this.account.city) {
        this.v1.city = true;
        isValid = false;
      }

      if (!this.account.lga) {
        this.v1.lga = true;
        isValid = false;
      }

      if (!this.account.state) {
        this.v1.state = true;
        isValid = false;
      }

      if (Number(this.account.creditLimit) < 0) {
        this.v1.creditLimit = true;
        isValid = false;
      }

      this.v2 = {
        name: false,
        emailAddress: false,
        emailAddressValid: false,
        phoneNumber: false,
        phoneNumberValid: false,
      };

      if (!this.contact.name) {
        this.v2.name = true;
        isValid = false;
      }

      if (!this.contact.emailAddress) {
        this.v2.emailAddress = true;
        isValid = false;
      }

      if (
        this.contact.emailAddress &&
        !helper.validateEmail(this.contact.emailAddress)
      ) {
        this.v2.emailAddressValid = true;
        isValid = false;
      }

      if (!this.contact.phoneNumber) {
        this.v2.phoneNumber = true;
        isValid = false;
      }

      if (
        this.contact.phoneNumber &&
        !helper.validatePhone(this.contact.phoneNumber)
      ) {
        this.v2.phoneNumberValid = true;
        isValid = false;
      }

      this.v3 = {
        name: false,
        accountNumber: false,
      };

      if (!this.bank.name) {
        this.v3.name = true;
        isValid = false;
      }

      if (!this.bank.accountNumber) {
        this.v3.accountNumber = true;
        isValid = false;
      }

      if (
        this.bank.accountNumber &&
        !helper.validateNuban(this.bank.accountNumber)
      ) {
        this.v3.accountNumberValid = true;
        isValid = false;
      }

      return isValid;
    },
  },
};
</script>