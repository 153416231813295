<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-product-add"
    role="dialog"
  >
    <div class="modal-dialog">
      <form @submit.prevent="submit" class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1">
            <strong>Add Product</strong>
          </h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
            @click="cancel"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Sales Order Number</label
            >
            <div class="col-sm-6">
              <input
                class="form-control"
                :class="{
                  'is-invalid': $v.newItem.salesOrderNumber.$error,
                }"
                type="number"
                placeholder="Enter sales order number"
                v-model.lazy="newItem.salesOrderNumber"
                @input="setSalesOrder($event.target.value)"
              />
              <div
                class="invalid-feedback"
                v-if="!$v.newItem.quantity.minValue"
              >
                Sales order is required
                {{ $v.newItem.quantity.$params.minValue.min - 1 }}.
              </div>
            </div>
            <div class="col-sm-2">
              <span
                v-if="isVerifying"
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <i class="sr-only">Loading...</i>
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Customer Code</label
            >
            <div class="col-12 col-sm-6">
              <input
                disabled
                class="form-control"
                type="text"
                v-model="salesOrder.parent.code"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Customer FullName</label
            >
            <div class="col-12 col-sm-6">
              <input
                disabled
                class="form-control"
                type="text"
                v-model="salesOrder.parent.name"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Select product</label
            >
            <div class="col-sm-6">
              <select
                class="form-control"
                :class="{
                  'is-invalid': $v.newItem.productId.$error,
                }"
                v-model.trim="newItem.productId"
                @input="setProduct($event.target.value)"
                placeholder="Select a product"
              >
                <option v-for="p in filteredProducts" :key="p.id" :value="p.id">
                  {{ p.name }}
                </option>
              </select>
              <div
                class="invalid-feedback"
                v-if="!$v.newItem.productId.required"
              >
                Please select a product
              </div>
            </div>
            <div class="col-sm-2">
              <span
                v-if="isFetchingProducts"
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <i class="sr-only">Loading...</i>
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Quantity</label
            >
            <div class="col-12 col-sm-6">
              <input
                class="form-control"
                :class="{
                  'is-invalid': $v.newItem.quantity.$error,
                }"
                type="number"
                placeholder="Enter the quantity you needed"
                v-model.trim="newItem.quantity"
                @input="setQuantity($event.target.value)"
              />
              <div
                class="invalid-feedback"
                v-if="!$v.newItem.quantity.minValue"
              >
                Quantity must be greater than
                {{ $v.newItem.quantity.$params.minValue.min - 1 }}.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Price</label
            >
            <div class="col-12 col-sm-6">
              <input
                class="form-control"
                type="number"
                readonly
                v-model.trim="newItem.price"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Stage</label
            >
            <div class="col-sm-6">
              <select
                class="custom-select custom-select-md"
                :class="{
                  'is-invalid': $v.newItem.stage.$error,
                }"
                v-model="newItem.stage"
                @input="setStage($event.target.value)"
              >
                <option v-for="p in orderStages" :key="p.id" :value="p.id">
                  {{ p.name }}
                </option>
              </select>
              <div class="invalid-feedback" v-if="!$v.newItem.stage.required">
                Please select a stage
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Action</label
            >
            <div class="col-sm-6">
              <select
                class="custom-select custom-select-md"
                :class="{
                  'is-invalid': $v.newItem.action.$error,
                }"
                v-model="newItem.action"
                @input="setAction($event.target.value)"
              >
                <option
                  v-for="p in preparedActions"
                  :key="p.value"
                  :value="p.value"
                >
                  {{ p.text }}
                </option>
              </select>
              <div class="invalid-feedback" v-if="!$v.newItem.action.required">
                Please select an action
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            style="width: 100px"
            class="btn btn-secondary"
            type="button"
            data-dismiss="modal"
            @click="cancel"
          >
            Cancel
          </button>
          <button :disabled="!isValid" class="btn btn-primary" type="submit">
            <span v-if="isBusyModal">Busy...</span>
            <span v-else>Add Product</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { minValue, required } from "vuelidate/lib/validators";

export default {
  name: "AddProduct",
  props: ["orderStages", "actions"],

  data() {
    return {
      isBusyModal: false,
      groupId: "",
      products: [],
      filteredProducts: [],
      isExist: false,
      preparedActions: this.actions,
      orderId: "",
      isVerifying: false,
      isValid: false,
      isFetchingProducts: false,
      salesOrder: {
        parent: {
          code: "",
          name: "",
        },
      },
      typingTimer: "",
      newItem: {
        salesOrderNumber: "",
        productId: "",
        quantity: 0,
        price: 0,
        productCode: "",
        stage: "",
        action: "",
      },
    };
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },

  validations: {
    newItem: {
      salesOrderNumber: {
        required,
      },

      productId: {
        required,
      },

      quantity: {
        minValue: minValue(1),
      },

      stage: {
        required,
      },

      action: {
        required,
      },
    },
  },

  created() {
    this.orderId = this.$route.params.id;
  },

  methods: {
    setSalesOrder(value) {
      this.newItem.salesOrderNumber = value;
      this.$v.newItem.salesOrderNumber.$touch();

      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(this.getSalesOrder(value), 10000);
    },

    setQuantity(value) {
      this.newItem.quantity = value;
      this.$v.newItem.quantity.$touch();
    },

    setProduct(value) {
      this.newItem.productId = value;
      this.$v.newItem.productId.$touch();

      this.setProductCode(value);
      this.filterActions();
      this.getDistributorPrice(value);
    },

    setProductCode(productId) {
      const product = this.filteredProducts.find((x) => x.id === productId);
      this.newItem.productCode = product.code;
      this.isExist = product.isExist ? product.isExist : false;
    },

    setStage(value) {
      this.newItem.stage = value;
      this.$v.newItem.stage.$touch();
    },

    setAction(value) {
      this.newItem.action = value;
      this.$v.newItem.action.$touch();
    },

    async getDistributorPrice(id) {
      this.priceLoading = true;
      try {
        if (this.groupId) {
          const response = await this.$http.get(
            `/Groups/price/${this.groupId}/${id}`
          );

          if (response.status === 200)
            this.newItem.price = response.body.distributorPrice;

          if (this.newItem.price === 0) this.getProductPrice(id);
        } else {
          this.getProductPrice(id);
        }

        this.priceLoading = false;
      } catch (error) {
        this.priceLoading = false;
      }
    },

    getProductPrice(id) {
      const product = this.products.find((p) => p.id === id);
      this.newItem.price = product.distributorPrice;
    },

    filterActions() {
      let value = this.actions;
      if (this.isExist) {
        value = value.filter((x) => x.value !== 2);
      }
      this.preparedActions = value;
    },

    performFilter() {
      this.filteredProducts.forEach((product) => {
        const prod = this.salesOrder.details.find(
          (element) => product.id === element.productId
        );
        if (prod) {
          product.isExist = true;
        }
      });
    },

    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = {
          salesOrder: this.newItem.salesOrderNumber,
          productCode: this.newItem.productCode,
          productPrice: this.newItem.price,
          quantityOrdered: this.newItem.quantity,
          customerCode: this.salesOrder.parent.code,
          customerFullName: this.salesOrder.parent.name,
          action: this.newItem.action,
          status: this.newItem.stage,
        };

        this.$emit("onItemAdded", data);
        this.reset();
      }
    },

    reset() {
      this.newItem.salesOrderNumber = "";
      this.newItem.productId = "";
      this.newItem.quantity = 0;
      this.newItem.price = 0;
      this.newItem.productCode = "";
      this.salesOrder = {
        parent: {
          code: "",
          name: "",
        },
      };
      this.isValid = false;

      setTimeout(() => {
        this.$v.$reset();
      }, 0);
    },

    async getSalesOrder(orderNumber) {
      this.isVerifying = true;
      this.isBusy = true;
      try {
        const response = await this.$http.get(
          `/PurchaseOrders/manufacturer/${orderNumber}`
        );

        if (response.ok && response.body) {
          this.salesOrder = response.body;
          this.getGroup(this.salesOrder.parent.id);
          this.isValid = true;
        }

        this.isVerifying = false;
        this.isBusy = false;
      } catch (error) {
        this.isVerifying = false;
        this.isBusy = false;
      }
    },

    async getGroup(parentId) {
      this.isBusy = true;
      this.isFetchingProducts = true;
      try {
        const response = await this.$http.get(`/Groups/getGroup/${parentId}`);
        if (response.ok) {
          this.groupId = response.body;
          this.getManufacturerProducts();
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    async getManufacturerProducts() {
      this.isBusyModal = true;
      try {
        const response = await this.$http.get(
          "/Products/getListNoPagingForManufacturer?active=true" +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "") +
            (this.groupId ? "&groupId=" + this.groupId : "")
        );
        if (response.ok) {
          this.products = this.filteredProducts = response.body;
          this.performFilter();
        }

        this.isBusyModal = false;
        this.isFetchingProducts = false;
      } catch (error) {
        this.isBusyModal = false;
        this.isFetchingProducts = false;
      }
    },

    cancel() {
      this.reset();
      this.$emit("cancel");
    },
  },
};
</script>