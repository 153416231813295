<template>
  <select
    class="custom-select custom-select-md"
    v-model="salesOrder.status"
    @input="onStageSelect($event.target.value)"
  >
    <option v-for="p in orderStages" :key="p.id" :value="p.id">
      {{ p.name }}
    </option>
  </select>
</template>

<script>
export default {
  props: ["orderStages", "salesOrder", "salesOrderIndex"],

  methods: {
    onStageSelect(id) {
      this.$emit("onStageSelectHandler", {
        id,
        salesOrderIndex: this.salesOrderIndex,
      });
    },
  },
};
</script>