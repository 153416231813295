<template>
	<div class="row">
		<div class="col-lg-12">
			<div class="tabs-section">
				<div class="tab">
					<button
						class="tablinks"
						@click.prevent="openTab($event, 'All')"
						id="defaultOpen"
					>
						All Orders
					</button>

					<button class="tablinks" @click.prevent="openTab($event, 'Cancelled')">
						Cancellation Requests
					</button>
				</div>

				<div id="All" class="tabcontent">
					<div
						:class="[
							'card',
							'card-border-color',
							'card-border-color-primary',
							' card-table',
							'be-loading',
							{ 'be-loading-active': isBusy },
						]"
					>
						<div class="card-header">
							<button
								class="btn btn-success btn-rounded btn-space pull-left"
								@click="viewFilter"
							>
								<i class="feather icon-filter text-white"></i> Filters
							</button>
							<button
								v-if="hasPermission('Global_View_Orders')"
								class="btn btn-info btn-rounded"
								@click.prevent="viewGlobal()"
								:disabled="loadingNewOrders"
							>
								<span
									v-if="loadingNewOrders"
									class="spinner-border spinner-border-sm"
									role="status"
								>
									<i class="sr-only">Loading...</i>
								</span>
								<span v-else>
									<i class="mdi mdi-earth text-white"></i>
									Switch to {{ isGlobal ? "Default" : "Global" }}
								</span>
							</button>

							<button
								class="btn btn-primary btn-rounded btn-space float-right"
								@click="refresh"
								:disabled="loadingNewOrders"
							>
								<span
									v-if="loadingNewOrders"
									class="spinner-border spinner-border-sm"
									role="status"
								>
									<i class="sr-only">Loading...</i>
								</span>
								<span v-else>
									<i class="mdi mdi-refresh text-white"></i>
									Check For New Orders
								</span>
							</button>
						</div>
						<div class="card-body">
							<div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
								<p>Click on "Order Number" to view full Order details</p>
								<div class="row be-datatable-body">
									<div
										class="col-sm-12 table-responsive"
										style="min-height: 350px;"
									>
										<table
											class="table table-fw-widget dataTable no-footer text-nowrap"
										>
											<thead>
												<tr>
													<th>Order Date</th>
													<th>Order Number</th>
													<th>Channel</th>
													<th>Customer</th>
													<!-- <th>Has Returns</th> -->
													<th>Total</th>
													<th v-if="isGlobal">Branch</th>
													<th v-if="isGlobal">
														Account Manager
													</th>
													<th>Created By</th>
													<th>Created Date</th>
													<th>Delivery Status</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="o in orders" :key="o.id">
													<td>
														{{
															o.orderDate
																? formatDate(o.orderDate)
																: formatDateWithTime(o.created)
														}}
													</td>
													<td>
														<a
															v-if="hasPermission('Orders_View')"
															href="#"
															@click.prevent="details(o.id)"
															>{{ o.orderNumber }}</a
														>
														<a v-else href="#">{{ o.orderNumber }}</a>
													</td>
													<td>{{ o.source }}</td>

													<td>{{ o.maker.displayName }}</td>
													<!-- <td>
														<span
															:class="[
																'badge',
																' badge-pill',
																{ 'badge-success': o.hasReturns },
																{ 'badge-danger': !o.hasReturns },
															]"
														>
															{{ o.hasReturns ? "Yes" : "No" }}
														</span>
													</td> -->
													<td>&#8358; {{ formatMoney(o.total) }}</td>
													<td v-if="isGlobal">
														{{ o.branch ? o.branch.name : "N/A" }}
													</td>
													<td v-if="isGlobal">
														{{
															o.introducer
																? o.introducer.displayName
																: "N/A"
														}}
													</td>

													<td>
														{{
															o.orderCreatedBy
																? o.orderCreatedBy.displayName
																: "N/A"
														}}
													</td>
													<td>
														{{
															o.orderDate &&
																formatDateWithTime(o.created)
														}}
													</td>
													<td>
														<button
															:class="[
																'btn btn-sm badge-pill dropdown-toggle d-flex align-items-center',
																'badge',

																{
																	'badge-warning':
																		o.status == 'Pending',
																},
																{
																	'badge-success':
																		o.status == 'Confirmed' ||
																		'markAsDelivered',
																},
																{
																	'badge-info':
																		o.status == 'Dispatched',
																},

																{
																	'badge-primary':
																		o.status ==
																		'recallDelivery',
																},
															]"
															type="button"
															id="dropdownMenu2"
															data-toggle="dropdown"
															aria-haspopup="true"
															aria-expanded="false"
															:disabled="
																o.status == 'markAsDelivered' ||
																	o.status == 'Fulfill'
															"
														>
															{{ o.status }}
															<!-- {{"Delivered" : o.status == 'markAsDelivered'}} -->
														</button>
														<div
															class="dropdown-menu px-1"
															aria-labelledby="dropdownMenu2"
															v-if="
																o.status !== 'markAsDelivered' &&
																	hasPermission('Orders_Fulfill')
															"
														>
															<a
																v-if="
																	o.status == 'Pending' &&
																		hasPermission(
																			'Orders_Fulfill',
																		)
																"
																class="dropdown-item"
																href="#"
																@click.prevent="confirmOrder(o.id)"
															>
																Confirm Order
															</a>
															<a
																v-if="
																	o.status == 'Confirmed' ||
																		(o.status ==
																			'recallDelivery' &&
																			hasPermission(
																				'Orders_Fulfill',
																			))
																"
																class="dropdown-item"
																href="#"
																@click.prevent="dispatchOrder(o.id)"
															>
																Dispatch Order
															</a>
															<a
																v-if="
																	o.status ==
																		('Dispatched' ||
																			'markAsDelivered') &&
																		hasPermission(
																			'Orders_Fulfill',
																		)
																"
																class="dropdown-item"
																href="#"
																@click.prevent="
																	recallDelivery(o.id)
																"
															>
																Recall Delivery
															</a>
															<div
																v-if="o.status == 'Dispatched'"
																class="dropdown-divider"
															></div>
															<a
																v-if="
																	o.status ==
																		('Confirmed' ||
																			'Dispatched' ||
																			'recallDelivery') &&
																		hasPermission(
																			'Orders_Fulfill',
																		)
																"
																class="dropdown-item"
																href="#"
																@click.prevent="
																	markAsDelivered(o.id)
																"
															>
																Mark as Delivered
															</a>
														</div>
													</td>
													<td>
														<button
															class="btn btn-outline-primary btn-sm dropdown-toggle"
															type="button"
															id="dropdownMenu2"
															data-toggle="dropdown"
															aria-haspopup="true"
															aria-expanded="false"
														>
															Actions
														</button>
														<div
															class="dropdown-menu px-1"
															aria-labelledby="dropdownMenu2"
														>
															<a
																class="dropdown-item"
																href="#"
																@click.prevent="details(o.id)"
															>
																View Details
															</a>
															<a
																v-if="
																	o.status == 'Pending' ||
																		o.status == 'Confirmed' ||
																		o.status ==
																			'markAsDelivered' ||
																		o.status ==
																			'recallDelivery' ||
																		(o.status == 'Dispatched' &&
																			hasPermission(
																				'Orders_Fulfill',
																			) &&
																			o.totalAmountPaid <
																				o.total)
																"
																class="dropdown-item"
																href="#"
																@click.prevent="viewFulfillment(o)"
															>
																Fulfill Order
															</a>
															<div
																v-if="
																	o.status == 'Pending' ||
																		o.status == 'Confirmed' ||
																		o.status ==
																			'markAsDelivered' ||
																		o.status ==
																			'recallDelivery' ||
																		(o.status == 'Confirmed' &&
																			hasPermission(
																				'Orders_Fulfill',
																			))
																"
																class="dropdown-divider"
															></div>
															<a
																v-if="
																	o.paymentStatus != 'PAID' &&
																	(o.status === 'Fulfilled' ||
																		o.status === 'Pending' ||
																		o.status === 'Confirmed' ||
																		
																		o.status ===
																			'markAsDelivered' ||
																		o.status ===
																			'recallDelivery') &&
																		hasPermission(
																			'Orders_Update',
																		)
																"
																class="dropdown-item"
																href="#"
																@click.prevent="
																	manageAddRecord(o.id)
																"
															>
																Record Payment
															</a>
															<a
																v-if="
																	o.status == 'Pending' ||
																		o.status == 'Confirmed' ||
																		(o.status ==
																			'recallDelivery' &&
																			hasPermission(
																				'Orders_Fulfill',
																			))
																"
																class="dropdown-item"
																href="#"
																@click.prevent="viewCancel(o)"
																style="color: red"
															>
																Cancel Order
															</a>
															<a
																v-if="
																	o.status ==
																		('Pending' ||
																			'recallDelivery' ||
																			'Confirm') &&
																		hasPermission(
																			'Orders_Delete',
																		)
																"
																class="dropdown-item"
																href="#"
																@click.prevent="confirmDelete(o.id)"
																style="color: red"
															>
																Delete Order
															</a>
															<a
																v-if="
																	hasPermission(
																		'Orders_Accept_Cancellation',
																	) && o.status == 'Cancelling'
																"
																class="dropdown-item"
																href="#"
																@click.prevent="
																	cancelRequest(o.id, 'accepted')
																"
																style="color: red"
															>
																Accept Cancel
															</a>
															<a
																v-if="
																	hasPermission(
																		'Orders_Reject_Cancellation',
																	) && o.status == 'Cancelling'
																"
																class="dropdown-item"
																href="#"
																@click.prevent="
																	cancelRequest(o.id, 'rejected')
																"
																style="color: blue"
															>
																Reject Cancel
															</a>
														</div>
													</td>
												</tr>
												<tr v-if="!orders.length">
													<td colspan="10">
														<div
															class="text-center"
															style="padding-top: 50px"
														>
															<span
																style="font-size: 4.615rem"
																class="mdi mdi-basket"
															></span>
														</div>
														<h3
															class="text-center"
															style="padding-bottom: 100px"
														>
															You have not recieved any orders.
														</h3>
													</td>
												</tr>
											</tbody>
										</table>
										<Pagination
											:limit="pageSize"
											:count="pageCount"
											:page="page"
											:itemsCount="count"
											@changePage="gotoPage"
											@changeLimit="changeLimit"
										/>
										<div class="row be-datatable-footer pt-3">
											<div class="col-sm-5">
												<div class="dataTables_info">
													{{ count }} order{{ count != 1 ? "s" : "" }}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="be-spinner">
							<svg width="40px" height="40px" viewBox="0 0 66 66">
								<circle
									class="circle"
									fill="none"
									stroke-width="4"
									stroke-linecap="round"
									cx="33"
									cy="33"
									r="30"
								/>
							</svg>
						</div>
					</div>
				</div>

				<div id="Cancelled" class="tabcontent">
					<div
						:class="[
							'card',
							'card-border-color',
							'card-border-color-primary',
							' card-table',
							'be-loading',
							{ 'be-loading-active': isBusy },
						]"
					>
						<div class="card-header"></div>
						<div class="card-body">
							<div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
								<p>Click on "Order Number" to view full Order details</p>
								<div class="row be-datatable-body">
									<div
										class="col-sm-12 table-responsive"
										style="min-height: 350px;"
									>
										<table
											class="table table-fw-widget dataTable no-footer text-nowrap"
										>
											<thead>
												<tr>
													<th>Created</th>
													<th>Order Date</th>
													<th>Order Number</th>
													<th>Channel</th>
													<th>Customer</th>
													<th>Total</th>
													<th>Created By</th>
													<th>Request Status</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="o in cancelledOrders" :key="o.id">
													<td>
														{{
															o.orderDate &&
																formatDateWithTime(o.created)
														}}
													</td>
													<td>
														{{
															o.orderDate
																? formatDate(o.orderDate)
																: formatDateWithTime(o.created)
														}}
													</td>
													<td>
														<a
															v-if="hasPermission('Orders_View')"
															href="#"
															@click.prevent="details(o.id)"
															>{{ o.orderNumber }}</a
														>
														<a v-else href="#">{{ o.orderNumber }}</a>
													</td>
													<td>{{ o.source }}</td>
													<td>
														{{ o.maker.displayName }}
													</td>
													<td>&#8358; {{ formatMoney(o.total) }}</td>
													<td>
														{{
															o.orderCreatedBy
																? o.orderCreatedBy.displayName
																: "N/A"
														}}
													</td>
													<td>
														<span
															:class="[
																'badge',
																'badge-pill',
																{
																	'badge-primary':
																		o.cancellationStatus ==
																		'request',
																},
																{
																	'badge-success':
																		o.cancellationStatus ==
																		'accepted',
																},
																{
																	'badge-danger':
																		o.cancellationStatus ==
																		'rejected',
																},
															]"
															>{{
																o.cancellationStatus == "request"
																	? "pending"
																	: o.cancellationStatus ==
																	  "accepted"
																	? "request accepted"
																	: "request rejected"
															}}</span
														>
													</td>
													<td>
														<button
															class="btn btn-outline-primary btn-sm dropdown-toggle"
															type="button"
															id="dropdownMenu2"
															data-toggle="dropdown"
															aria-haspopup="true"
															aria-expanded="false"
														>
															Actions
														</button>
														<div
															v-if="
																o.cancellationStatus == 'request' ||
																	o.cancellationStatus ==
																		'rejected'
															"
															class="dropdown-menu px-1"
															aria-labelledby="dropdownMenu2"
														>
															<button
																v-if="
																	o.cancellationStatus ==
																		'request' ||
																		o.cancellationStatus ==
																			'rejected'
																"
																class="btn btn-text dropdown-item"
																@click.prevent="
																	viewCancellationReason(
																		o.cancellationReason,
																		o.distributorCancellationReason,
																	)
																"
															>
																View Reason
															</button>
															<div
																v-if="
																	hasPermission(
																		'Orders_Accept_Cancellation',
																	) && o.status == 'Cancelling'
																"
																class="dropdown-divider"
															></div>
															<a
																v-if="
																	hasPermission(
																		'Orders_Accept_Cancellation',
																	) && o.status == 'Cancelling'
																"
																class="dropdown-item"
																href="#"
																@click.prevent="
																	cancelRequest(o.id, 'accepted')
																"
																style="color: blue"
															>
																Accept Cancel
															</a>

															<div
																v-if="
																	hasPermission(
																		'Orders_Reject_Cancellation',
																	) && o.status == 'Cancelling'
																"
																class="dropdown-divider"
															></div>
															<a
																v-if="
																	hasPermission(
																		'Orders_Reject_Cancellation',
																	) && o.status == 'Cancelling'
																"
																class="dropdown-item"
																href="#"
																@click.prevent="
																	viewCancelOrder(o.id)
																"
																style="color: red"
															>
																Reject Cancel
															</a>
														</div>
													</td>
												</tr>
												<tr v-if="!cancelledOrders.length">
													<td colspan="10">
														<div
															class="text-center"
															style="padding-top: 50px"
														>
															<span
																style="font-size: 4.615rem"
																class="mdi mdi-basket"
															></span>
														</div>
														<h3
															class="text-center"
															style="padding-bottom: 100px"
														>
															You have not recieved any orders.
														</h3>
													</td>
												</tr>
											</tbody>
										</table>
										<Pagination
											:limit="cancelPageSize"
											:count="cancelPageCount"
											:page="cancelPage"
											:itemsCount="cancelCount"
											@changePage="cancelGotoPage"
											@changeLimit="cancelChangeLimit"
										/>
										<div class="row be-datatable-footer pt-3">
											<div class="col-sm-5">
												<div class="dataTables_info">
													{{ cancelCount }} order{{
														cancelCount > 1 ? "s" : ""
													}}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="be-spinner">
							<svg width="40px" height="40px" viewBox="0 0 66 66">
								<circle
									class="circle"
									fill="none"
									stroke-width="4"
									stroke-linecap="round"
									cx="33"
									cy="33"
									r="30"
								/>
							</svg>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-reason-view"
			role="dialog"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h3 class="modal-title">Cancellation Reason</h3>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="p-1">
							<h5>Customer:</h5>
							<p>
								{{ cancellationReason }}
							</p>
						</div>
						<div v-if="distributorCancellationReason" class="mt-2 p-1">
							<h5>Response:</h5>
							<p>
								{{ distributorCancellationReason }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade colored-header colored-header-primary" id="mod-cancel" role="dialog">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-body">
						<div>
							<h3 class="text-bold">Reject</h3>
							<div class="row mt-3">
								<div class="col col-md-4">
									<p><label for="cancelR">Reason for rejecting:</label></p>
								</div>
								<div class="col col-md-8">
									<textarea
										id="cancelR"
										class="form-control"
										name="reason"
										placeholder="Enter Reason"
										rows="4"
										cols="50"
										v-model="rejectReason"
									></textarea>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px;"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 100px;"
							:disabled="isBusyModal"
							class="btn btn-primary"
							type="button"
							@click.prevent="cancelRequest(rejectingOrderId, 'rejected')"
						>
							<span v-if="isBusyModal">Busy...</span>
							<span v-else>Done</span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-fulfillment"
			role="dialog"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h3 class="modal-title">
							Fulfillment for order # {{ fOrder.orderNumber }}
						</h3>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row" v-if="fOrder.wasFulfilled == 'true'">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Fulfilled on</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									:class="['form-control', { 'is-invalid': v1.dateFulfilled }]"
									type="date"
									:max="maxDate"
									placeholder="Select a date"
									v-model="fOrder.dateFulfilled"
								/>

								<ul v-if="v1.dateFulfilled" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Notes</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<textarea
									:class="['form-control', { 'is-invalid': v1.notes }]"
									v-model="fOrder.notes"
								></textarea>
								<ul v-if="v1.notes" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary md-close"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 100px"
							class="btn btn-primary"
							type="button"
							@click.prevent="fulfillment"
						>
							Update
						</button>
					</div>
				</div>
			</div>
		</div>

		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-cancel-order"
			role="dialog"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h3 class="modal-title">Cancel order # {{ fOrder.orderNumber }}</h3>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Notes</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<textarea
									:class="['form-control', { 'is-invalid': v2.notes }]"
									v-model="fOrder.notes"
								></textarea>
								<ul v-if="v2.notes" class="parsley-errors-list filled">
									<li class="parsley-required">Required.</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary md-close"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 100px"
							class="btn btn-primary"
							type="button"
							@click.prevent="cancelOrder"
						>
							Update
						</button>
					</div>
				</div>
			</div>
		</div>

		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-order-fulfillment-status"
			data-backdrop="static"
			data-keyboard="false"
			role="dialog"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title">
							Order Status Has Been Updated To FulFilled
						</h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row">
							<div class="col-12">
								<p style="font-size: small; color: gray; text-align: center">
									See inventory movement summary below
								</p>
								<ul>
									<li v-for="o in inventoryMovementSummary" :key="o">
										{{ o }}
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-primary"
							type="button"
							data-dismiss="modal"
						>
							Okay
						</button>
					</div>
				</div>
			</div>
		</div>
		<div
			class="modal fade colored-header colored-header-primary"
			id="mod-filter"
			role="dialog"
			tabindex="-1"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h3 class="modal-title">Filter</h3>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Status</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<select
									class="form-control"
									placeholder="Select an option"
									v-model="filter.status"
								>
									<option value="0">All Orders</option>
									<option value="Pending">Pending</option>
									<option value="Fulfilled">Fulfilled</option>
									<option value="Cancelled">Cancelled</option>
								</select>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Date range</label
							>
							<div class="col-12 col-sm-4 col-lg-4">
								<input
									type="date"
									:max="maxDate"
									class="form-control"
									placeholder="Select a date"
									v-model="filter.dateStart"
								/>
							</div>
							<div class="col-12 col-sm-4 col-lg-4">
								<input
									type="date"
									:max="maxDate"
									class="form-control"
									placeholder="Select a date"
									v-model="filter.dateEnd"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Buyer Name</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="text"
									placeholder="Filter by buyer name..."
									v-model="filter.maker"
								/>
							</div>
						</div>
						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"
								>Order Number</label
							>
							<div class="col-12 col-sm-8 col-lg-8">
								<input
									class="form-control"
									type="text"
									placeholder="Filter by order number..."
									v-model="filter.orderNumber"
								/>
							</div>
						</div>

						<div class="form-group row">
							<label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
							<div class="col-12 col-sm-8 col-lg-8">
								<label class="custom-control custom-checkbox custom-control-inline">
									<input
										class="custom-control-input"
										type="checkbox"
										v-model="filter.showForMe"
									/>
									<span class="custom-control-label">My Orders Only</span>
								</label>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary md-close"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 100px"
							class="btn btn-secondary md-close"
							type="button"
							@click="reset"
						>
							Reset
						</button>
						<button
							style="width: 100px"
							class="btn btn-primary md-close"
							type="button"
							@click="getOrders"
						>
							Search
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade colored-header colored-header-primary" id="mod-global" role="dialog">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-body">
						<div class="text-center">
							<!-- <div class="text-warning"><span class="modal-main-icon mdi mdi-alert"></span></div> -->
							<h3 class="p-1">Confirmation</h3>
							<p>
								Do you want to switch to
								{{ isGlobal ? "Default" : "Global" }} View?
							</p>
						</div>
					</div>
					<div class="modal-footer">
						<button
							style="width: 100px"
							class="btn btn-secondary"
							type="button"
							data-dismiss="modal"
						>
							Cancel
						</button>
						<button
							style="width: 140px"
							:disabled="isBusyModal"
							class="btn btn-warning"
							type="button"
							@click.prevent="toggleGlobal"
						>
							<span v-if="isBusyModal">Busy...</span>
							<span v-else>Yes, proceed</span>
						</button>
					</div>
				</div>
			</div>
		</div>
		<vue-confirm-dialog></vue-confirm-dialog>
		<div v-if="isActive">{{ modal.actionText }}</div>
		<Modal
			:orderData="order"
			@proceed="updateOrderStatus($event)"
			:isBusy="modal.isLoading"
			:text="modal.actionText"
			:message="modal.message"
		/>
		<GeneralDeleteCaution
			:id="deleteId"
			@proceed="deleteOrder"
			:isBusy="isBusyModal"
			text="order"
		/>
		<RecordPayment
			:value="singleOrder"
			:isEdit="isEdit"
			:editDetails="editDetails"
			@getOrder="getOrders"
			@reset="resetEdit"
			@closeModal="closePaymentRecord"
		/>
	</div>
</template>

<script>
	/* eslint-disable */

	import Select2 from "@/components/Select2";
import { helper } from "@/helper";
import SystemPermissions from "@/permissions";
import moment from "moment";
import GeneralDeleteCaution from "../components/GeneralDeleteCaution.vue";
import Modal from "../components/helpers/modal.vue";
import RecordPayment from "../components/Order/RecordPayment.vue";
import Pagination from "../components/Pagination.vue";

	export default {
		mixins: [SystemPermissions.Mixin],
		name: "ordersRecieved",
		components: {
			Select2,
			Modal,
			GeneralDeleteCaution,
			Pagination,
			RecordPayment,
		},

		mounted() {
			window.setInterval(() => {
				this.getOrders();
			}, 300000);
			this.maxDate = helper.preventFutureDateSelection();
			this.openDefaultTab();
			this.getCancelledOrders();
		},

		created() {
			if (!this.hasPermission("Orders_View")) {
				this.$router.push("/restricted");
			}

			var user = this.$store.state.currentUser;
			var preferredView = this.$auth.getPreferredViewForOrder(user.id);
			if (preferredView) {
				if (preferredView == "global") this.isGlobal = true;
				else if (preferredView == "default") this.isGlobal = false;
			}

			var startDate = this.$route.query.sd
				? this.$route.query.sd
				: moment()
						// .subtract(13, "days")
						.format("1999-01-01");
			var endDate = this.$route.query.ed
				? this.$route.query.ed
				: moment().format("YYYY-MM-DD");
			var status = this.$route.query.st ? this.$route.query.st : "0";
			var buyerName = this.$route.query.bn ? this.$route.query.bn : "";
			var orderNumber = this.$route.query.on ? this.$route.query.on : "";
			var myOrderOnly = this.$route.query.mo && this.$route.query.mo == "true" ? true : false;

			this.filter = {
				status: status,
				orderNumber: orderNumber,
				maker: buyerName,
				dateStart: moment(String(startDate)).format("YYYY-MM-DD"),
				dateEnd: moment(String(endDate)).format("YYYY-MM-DD"),
				showForMe: myOrderOnly,
			};

			this.getOrders();

			if (this.user.type === "Distributor") this.getSystemSettings();
		},

		data() {
			return {
				fetchDate: Date.now(),
				loadingNewOrders: false,
				filter: {
					status: "0",
					orderNumber: "",
					maker: "",
					dateStart: moment().format("1999-01-01"),
					dateEnd: moment().format("YYYY-MM-DD"),
					showForMe: false,
				},
				isGlobal: false,
				isBusy: false,
				isBusyModal: false,
				fOrder: {},
				inventoryMovementSummary: {},
				v1: {
					wasFulfilled: false,
					dateFulfilled: false,
					notes: false,
				},
				v2: {
					notes: false,
				},
				orders: [],
				cancelledOrders: [],
				deleteId: "",
				createdUser: {},
				singleOrder: {},
				count: 0,
				page: 1,
				pageSize: 10,
				pageCount: 0,
				hasNext: false,
				hasPrev: false,
				cancelCount: 0,
				cancelPage: 1,
				cancelPageSize: 10,
				cancelPageCount: 0,
				cancelHasNext: false,
				cancelHasPrev: false,
				cancelStatus: "",
				cancellationReason: "",
				distributorCancellationReason: "",
				rejectReason: "",
				rejectingOrderId: "",
				options: {
					format: "YYYY-MM-DD",
					icons: {
						time: "mdi mdi-time",
						date: "mdi mdi-calendar",
						up: "mdi mdi-chevron-up",
						down: "mdi mdi-chevron-down",
						previous: "mdi mdi-chevron-left",
						next: "mdi mdi-chevron-right",
						today: "mdi mdi-screenshot",
						clear: "mdi mdi-trash",
						close: "mdi mdi-remove",
					},
				},
				local: {
					dow: 0, // Sunday is the first day of the week
					hourTip: "Select Hour", // tip of select hour
					minuteTip: "Select Minute", // tip of select minute
					secondTip: "Select Second", // tip of select second
					yearSuffix: "", // suffix of head year
					monthsHead: "January_February_March_April_May_June_July_August_September_October_November_December".split(
						"_",
					), // months of head
					months: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"), // months of panel
					weeks: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"), // weeks
					cancelTip: "cancel",
					submitTip: "confirm",
				},
				systemSettings: {},
				isEdit: false,
				editDetails: {},
				maxDate: "",
				order: {
					orderId: "",
					status: "",
				},
				isActive: true,
				modal: {
					isLoading: false,
					actionMethod: "",
					actionText: "",
					message: "",
				},
			};
		},

		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},
		},

		// mounted() {
		// 	// this.modal.isAtive = !this.modal.isAtive;
		// },

		methods: {
			reset() {
				this.filter = {
					status: "0",
					orderNumber: "",
					maker: "",
					dateStart: moment()
						// .subtract(13, "days")
						.format("1999-01-01"),
					dateEnd: moment().format("YYYY-MM-DD"),
					showForMe: false,
				};
			},

			openModal(btnText, message) {
				this.modal.actionText = btnText;
				this.modal.message = message;
				$("#modal").modal("show");
			},

			closeModal() {
				this.modal.actionText = "";
				this.modal.message = "";
				$("#modal").modal("hide");
			},

			confirmOrder(id) {
				this.order.orderId = id;
				this.order.status = "Confirmed";
				this.openModal("Confirm", "Confirm this order");
			},
			dispatchOrder(id) {
				this.order.orderId = id;
				this.order.status = "Dispatched";
				this.openModal("Dispatch", "Dispatch this order");
			},
			recallDelivery(id) {
				this.order.orderId = id;
				this.order.status = "recallDelivery";
				this.openModal("Recall Delivery", "Recall this order from dispatch");
			},
			markAsDelivered(id) {
				this.order.orderId = id;
				this.order.status = "markAsDelivered";
				this.openModal("Mark As Delivered", "Mark this order as delivered");
			},

			declineOrder(id) {
				this.order.orderId = id;
				this.order.status = "Cancel";
				this.openModal("Decline", "Decline this order");
			},

			confirmDelete(id) {
				this.deleteId = id;
				$("#mod-confirm-delete").modal("show");
			},

			cancelConfirm() {
				this.deleteId = "";
				$("#mod-confirm-delete").modal("hide");
			},

			gotoAnyPage(page) {
				this.$router.push(page);
			},

			async deleteOrder() {
				this.isBusyModal = true;
				try {
					const res = await this.$http.delete(`/Orders/${this.deleteId}`);
					if (res.ok) {
						this.$toast.success("Order deleted successfully", {
							icon: true,
							rtl: false,
						});
						this.getOrders();
						this.cancelConfirm();
					}
				} catch (error) {}
				this.isBusyModal = false;
			},

			async refresh() {
				try {
					this.isBusy = true;
					this.loadingNewOrders = true;

					const prevDate = this.fetchDate;
					await this.$http
						.get(
							"/Orders/getList?page=" +
								this.page +
								"&pageSize=" +
								this.pageSize +
								(this.filter.orderNumber != ""
									? "&orderNumber=" + this.filter.orderNumber
									: "") +
								(this.filter.maker != "" ? "&buyer=" + this.filter.maker : "") +
								(this.filter.status != "0" ? "&status=" + this.filter.status : "") +
								(this.filter.dateStart != ""
									? "&dateStart=" +
									  moment(this.filter.dateStart).format("YYYY-MM-DD 00:00:00")
									: "") +
								(this.filter.dateEnd != ""
									? "&dateEnd=" +
									  moment(this.filter.dateEnd).format("YYYY-MM-DD 23:59:59")
									: "") +
								(!this.isGlobal ? "&sellerId=" + this.user.parentId : "") +
								this.user.parentId +
								(this.filter.showForMe != ""
									? "&showForMe=" + this.filter.showForMe
									: ""),
						)
						.then(function(res) {
							this.orders = res.body.data;
							this.page = res.body.page;
							this.pageSize = res.body.pageSize;
							this.count = res.body.count;
							this.hasPrev = res.body.hasPrev;
							this.hasNext = res.body.hasNext;
							this.isBusy = false;
							this.fetchDate = Date.now();

							let newOrdersCount = 0;

							this.orders.forEach(element => {
								if (
									new Date(element.created).getTime() >
									new Date(prevDate).getTime()
								) {
									newOrdersCount++;
								}
							});

							this.loadingNewOrders = false;

							if (newOrdersCount > 0) {
								this.$toast.success(
									`${newOrdersCount} New Order${newOrdersCount > 1 ? "s" : ""}`,
									{
										icon: false,
										rtl: false,
									},
								);
							} else {
								this.getOrders();
								this.$toast("No New Orders", {
									icon: false,
									rtl: false,
								});
							}
						});
				} catch (error) {
					this.loadingNewOrders = false;
					this.isBusy = false;
				}
			},

			viewFulfillment(o) {
				this.v1 = {
					notes: false,
					wasFulfilled: false,
					dateFulfilled: false,
				};
				this.fOrder = {
					orderId: o.id,
					orderNumber: o.orderNumber,
					wasFulfilled: "true",
					notes: null,
					dateFulfilled: moment().format("YYYY-MM-DD"),
					updateStatusOnly: false,
				};

				$("#mod-fulfillment").modal("show");
			},
			viewGlobal() {
				$("#mod-global").modal("show");
			},

			viewCancellationReason(reason, distributorReason) {
				this.cancellationReason = reason;
				this.distributorCancellationReason = distributorReason;

				$("#mod-reason-view").modal("show");
			},

			viewCancel(o) {
				this.v2 = {
					notes: false,
				};
				this.fOrder = {
					orderId: o.id,
					orderNumber: o.orderNumber,
					wasFulfilled: "false",
					notes: null,
					dateFulfilled: moment().format("YYYY-MM-DD"),
				};

				$("#mod-cancel-order").modal("show");
			},

			viewExport() {
				// $("#mod-export").modal("show");
			},

			viewFilter() {
				this.page = 0;
				$("#mod-filter").modal("show");
			},

			details(id) {
				this.$router.push(
					"/orders/" +
						id +
						"?sd=" +
						this.filter.dateStart +
						"&ed=" +
						this.filter.dateEnd +
						"&st=" +
						this.filter.status +
						"&bn=" +
						this.filter.maker +
						"&on=" +
						this.filter.orderNumber +
						"&mo=" +
						this.filter.showForMe,
				);
			},

			async updateOrderStatus(data) {
				this.isBusy = true;
				try {
					const res = await this.$http.post("/Orders/updateOrderStatus", data);
					if (res.ok) {
						console.log(res.body);
						this.$toast.success(res.body, {
							icon: true,
							rtl: false,
						});
						this.getOrders();
						this.cancelConfirm();
						this.isBusy = false;
						this.closeModal();
					}
				} catch (error) {
					this.$toast.error(res.error, {
						icon: true,
						rtl: false,
					});
				}
			},

			async getOrders() {
				this.isBusy = true;

				await this.$http
					.get(
						"/Orders/getList?page=" +
							this.page +
							"&pageSize=" +
							this.pageSize +
							(this.filter.orderNumber != ""
								? "&orderNumber=" + this.filter.orderNumber
								: "") +
							(this.filter.maker != "" ? "&buyer=" + this.filter.maker : "") +
							(this.filter.status != "0" ? "&status=" + this.filter.status : "") +
							(this.filter.dateStart != ""
								? "&dateStart=" +
								  moment(this.filter.dateStart).format("YYYY-MM-DD 00:00:00")
								: "") +
							(this.filter.dateEnd != ""
								? "&dateEnd=" +
								  moment(this.filter.dateEnd).format("YYYY-MM-DD 23:59:59")
								: "") +
							"&sellerId=" +
							this.user.parentId +
							"&showForMe=" +
							!this.isGlobal,
					)
					.then(function(res) {
						this.orders = res.body.data;
						this.page = res.body.page;
						this.pageSize = res.body.pageSize;
						this.count = res.body.count;
						this.hasPrev = res.body.hasPrev;
						this.hasNext = res.body.hasNext;
						this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
						this.isBusy = false;
						this.fetchDate = Date.now();
					})
					.catch(function() {});

				$("#mod-filter").modal("hide");
				this.isBusy = false;
			},

			async getCancelledOrders() {
				this.isBusy = true;

				await this.$http
					.get(
						"/Orders/getDistributorCancelList?page=" +
							this.cancelPage +
							"&pageSize=" +
							this.cancelPageSize +
							"&distributorId=" +
							this.user.parentId +
							"&cancelStatus=" +
							this.cancelStatus,
					)
					.then(function(res) {
						this.cancelledOrders = res.body.data;
						this.cancelPage = res.body.page;
						this.cancelPageSize = res.body.pageSize;
						this.cancelCount = res.body.count;
						this.cancelHasPrev = res.body.hasPrev;
						this.cancelHasNext = res.body.hasNext;
						this.cancelPageCount = Math.ceil(res.body.count / res.body.pageSize);
						this.isBusy = false;
						this.fetchDate = Date.now();
					})
					.catch(function() {});

				$("#mod-filter").modal("hide");
				this.isBusy = false;
			},

			async fulfillment() {
				this.isBusyModal = true;
				this.isBusy = true;

				if (this.validate()) {
					var message = "";
					if (this.systemSettings.autoOrderFulfillmentReconciliation) {
						message =
							"Order status will be updated to fulfilled and inventory will be depleted. Please confirm";
					} else {
						message = "Only Order status will be updated to fulfilled. Please confirm";
					}

					let self = this;
					this.$confirm(
						//Vue.$vueConfirm.confirm(
						{
							auth: false,
							message: message,
							button: {
								no: "Cancel",
								yes: "Proceed",
							},
							callback: confirm => {
								if (confirm) {
									$("#mod-fulfillment").modal("hide");

									self.$http
										.post("/Orders/fulfillment", self.fOrder)
										.then(function(res) {
											if (res.body.responseCode == "00") {
												self.isBusyModal = false;
												self.getOrders();
											} else if (res.body.responseCode == "01") {
												self.isBusyModal = false;
												self.getOrders();
												self.inventoryMovementSummary =
													res.body.inventoryMovementSummary;
												$("#mod-order-fulfillment-status").modal("show");
											} else if (res.body.responseCode == "999X") {
												self.$confirm({
													auth: false,
													message: res.body.responseDescription,
													button: {
														no: "Cancel",
														yes: "Proceed",
													},
													callback: confirm => {
														if (confirm) {
															self.fOrder.updateStatusOnly = true;
															self.$http
																.post(
																	"/Orders/fulfillment",
																	self.fOrder,
																)
																.then(function(res) {
																	self.isBusyModal = false;
																	self.getOrders();
																})
																.catch(function() {
																	self.isBusy = false;
																	self.isBusyModal = false;
																});
														}
													},
												});
											} else {
												self.getOrders();
											}
										})
										.catch(function() {
											self.isBusy = false;
											self.isBusyModal = false;
										});
								}
							},
						},
					);
				} else {
					this.isBusy = false;
					this.isBusyModal = false;
				}
			},

			async cancelOrder() {
				this.isBusyModal = true;
				this.isBusy = true;

				if (this.validateCancel()) {
					$("#mod-cancel-order").modal("hide");

					await this.$http
						.post("/Orders/fulfillment", this.fOrder)
						.then(function(res) {
							this.isBusyModal = false;
							this.getOrders();
						})
						.catch(function() {
							this.isBusy = false;
							this.isBusyModal = false;
						});
				} else {
					this.isBusy = false;
					this.isBusyModal = false;
				}
			},

			validate() {
				this.v1 = {
					notes: false,
					wasFulfilled: false,
					dateFulfilled: false,
				};
				var isValid = true;

				if (!this.fOrder.dateFulfilled && this.fOrder.wasFulfilled == "true") {
					this.v1.dateFulfilled = true;
					isValid = false;
				}

				if (!this.fOrder.wasFulfilled) {
					this.v1.wasFulfilled = true;
					isValid = false;
				}

				if (!this.fOrder.notes) {
					this.v1.notes = true;
					isValid = false;
				}

				return isValid;
			},

			validateCancel() {
				this.v2 = {
					notes: false,
				};
				var isValid = true;

				if (!this.fOrder.notes) {
					this.v2.notes = true;
					isValid = false;
				}

				return isValid;
			},

			async getSystemSettings() {
				this.isBusy = true;

				await this.$http
					.get(`/SystemSettings/getByAccount?parentId=${this.user.parentId}`)
					.then(function(res) {
						this.systemSettings = res.body;
						this.isBusy = false;
					})
					.catch(function() {});

				this.isBusy = false;
			},

			formatDate(date) {
				return moment(date).format("ll");
			},

			formatDateWithTime(date) {
				return moment(date).format("lll");
			},

			prev() {
				this.page -= 1;
				this.getOrders();
			},

			next() {
				this.page += 1;
				this.getOrders();
			},

			formatMoney(val) {
				return helper.formatMoney(val);
			},
			async displayNameForUser(id) {
				var result = "";

				await this.$http
					.get("/Users/getById/" + id)
					.then(response => response.json())
					.then(data => {
						result = data["displayName"];
						return result;
					})
					.catch(error => {
						// console.log(error);
					});
				return result;
			},
			goToPayment(id) {
				this.$router.push("/payments/" + id);
			},
			toggleGlobal() {
				$("#mod-global").modal("hide");
				this.isGlobal = !this.isGlobal;

				this.$auth.setPreferredViewForOrder(
					this.isGlobal ? "global" : "default",
					this.user.id,
				);

				this.getOrders();
			},
			gotoPage(page) {
				this.page = page;
				this.getOrders();
			},

			changeLimit(limit) {
				this.pageSize = limit;
				this.getOrders();
			},

			cancelGotoPage(page) {
				this.cancelPage = page;
				this.getCancelledOrders();
			},

			cancelChangeLimit(limit) {
				this.cancelPageSize = limit;
				this.getCancelledOrders();
			},

			closePaymentRecord() {
				$("#mod-add-payment-record").modal("hide");
			},

			addPaymentRecord() {
				$("#mod-add-payment-record").modal("show");
			},

			manageAddRecord(id) {
				const order = this.orders.find(el => el.id === id);
				this.singleOrder = order;
				this.addPaymentRecord();
			},

			resetEdit() {
				this.editDetails = {};
				this.isEdit = false;
			},

			viewCancelOrder(id) {
				this.isBusyModal = false;
				this.rejectingOrderId = id;
				$("#mod-cancel").modal("show");
			},

			async cancelRequest(id, type) {
				this.isBusyModal = true;
				let data = {
					orderId: id,
					cancellationStatus: type,
					cancellationReason: type === "accepted" ? "" : this.rejectReason,
				};

				await this.$http
					.post("/Orders/updateCancellationStatus", data)
					.then(function(res) {
						this.isBusyModal = false;
						this.getOrders();
						this.getCancelledOrders();
						this.rejectReason = "";
					})
					.catch(function() {
						this.isBusy = false;
						this.isBusyModal = false;
					});

				$("#mod-cancel").modal("hide");
			},

			openDefaultTab() {
				document.getElementById("defaultOpen").click();
			},
			openTab(evt, cityName) {
				var i, tabcontent, tablinks;
				tabcontent = document.getElementsByClassName("tabcontent");
				for (i = 0; i < tabcontent.length; i++) {
					tabcontent[i].style.display = "none";
				}
				tablinks = document.getElementsByClassName("tablinks");
				for (i = 0; i < tablinks.length; i++) {
					tablinks[i].className = tablinks[i].className.replace(" active", "");
				}
				document.getElementById(cityName).style.display = "block";
				evt.currentTarget.className += " active";
			},
		},
	};
</script>

<style scoped>
	.overflow-custom {
		overflow-y: scroll !important;
	}
	.vh-50 {
		height: 50vh !important;
	}

	/* Style the tab */
	.tab {
		overflow: hidden;
		display: flex;
		padding: 15px;
		/* border: 1px solid #ccc; */
		/* background-color: #fff; */
	}

	/* Style the buttons inside the tab */
	.tab button {
		width: 50%;
		background-color: inherit;
		text-align: center;
		float: left;
		border: none;
		border-bottom: 2px solid #ccc;
		outline: none;
		cursor: pointer;
		padding: 14px 16px;
		font-size: 14px !important;
		transition: 0.3s;
		font-size: 17px;
	}

	/* Change background color of buttons on hover */
	.tab button:hover {
		/* background-color: #766cea; */
		color: #766cea !important;
	}

	/* Create an active/current tablink class */
	.tab button.active {
		border-bottom: 2px solid #766cea;
		color: #766cea !important;
	}

	/* Style the tab content */
	.tabcontent {
		display: none;
		padding: 6px 12px;
		/* border: 1px solid #ccc; */
		border-top: none;
	}
</style>
