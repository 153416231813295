<template>
  <button
    class="btn btn-danger btn-sm btn-rounded btn-space mr-1"
    data-toggle="tooltip"
    title="Delete"
    @click.prevent="deletePurchaseItem"
  >
    <i class="icon mdi mdi-delete text-white"></i>
  </button>
</template>

<script>
export default {
  name: "DeletePurchaseOrder",
  props: ["itemId"],

  methods: {
    onDeleteClick() {
      this.$emit("showAction");
    },

    async deletePurchaseItem() {
      this.$emit("removeItem", this.itemId);
    },
  },
};
</script>

<style></style>
