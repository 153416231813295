<template>
  <div class="row min-h-screen shadow-none bg-transparent">
    <div class="col-lg-12">
      <div :class="['be-loading', { 'be-loading-active': isBusy }]">
        <DistributorsHeader
          :viewFilter="viewFilter"
          :refreshPage="refreshPage"
          :hasPermission="hasPermission"
          :distributors="distributors"
        />
        <DistributorsTable
          :distributors="distributors"
          :priceOptions="priceOptions"
          :viewDetails="details"
          :viewDistributor="ViewDistributor"
          :refreshPage="refreshPage"
        />
        <Pagination
          :limit="pageSize"
          :count="pageCount"
          :page="page"
          :itemsCount="count"
          @changePage="gotoPage"
          @changeLimit="changeLimit"
        />
        <FilterModal
          :filter="filter"
          :groups="groups"
          :reset="reset"
          :getMerchants="getMerchants"
          :loadingMerchants="loadingMerchants"
        />
      </div>
      <div class="modal fade py-1" id="mod-refresh" role="dialog">
        <div class="modal-dialog max-w-[550px] mx-auto w-full relative">
          <div class="modal-content p-[10px] md:p-[20px] bg-[#f8f8f8]">
            <button
              class="absolute right-6 top-4 text-2xl outline-none focus:outline-none"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
            <div class="text-center">
              <div class="flex justify-center items-center my-[16px]">
                <img src="/assets/img/alarm.png" alt="" />
              </div>

              <p class="text-[16px] font-bold text-[#353535] mb-[4px] md:mx-2">
                Refresh Successfully
              </p>
              <p class="text-[14px] font-normal text-[#96a0b5] md:mx-2">
                You have successfully created a background task. Please
                <br class="hidden lg:block" />
                check the background services page for the progress
              </p>
              <div class="flex items-center justify-center gap-[8px] mt-[16px]">
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-hidden="true"
                  class="border border-solid border-[#96a0b5] rounded-[8px] text-[#96a0b5] px-[16px] sm:px-[24px] text-[14px] md:text-[16px] py-[16px] md:w-[40%] outline-none focus:outline-none"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="border-[1px] bg-[#2463eb] rounded-[8px] text-white border-solid px-[16px] sm:px-[24px] text-[14px] md:text-[16px] py-[16px] md:w-[60%] outline-none focus:outline-none"
                >
                  Continue to background task
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DistributorsHeader from "./DistributorsHeader.vue";
import DistributorsTable from "./DistributorsTable.vue";
import Pagination from "../Pagination.vue";
import FilterModal from "../FilterModal.vue";
import moment from "moment";
import Select2 from "@/components/Select2";
import SystemPermissions from "@/permissions";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "distributors",
  components: {
    Select2,
    Pagination,
    DistributorsHeader,
    DistributorsTable,
    FilterModal,
  },

  created() {
    if (!this.hasPermission("Distributor_View")) {
      this.$router.push("/restricted");
    }
    // this.getMerchants();
    // this.getGroups();
    // this.getManufacturers();
  },

  watch: {
    "updatingMerchant.hasCredit": function(newVal, oldVal) {
      if (!newVal) {
        this.updatingMerchant.creditLimit = 0;
      }
    },
  },
  data() {
    return {
      filter: {
        status: "",
        code: "",
        sourceId: "",
        group: "",
        name: "",
      },
      activeTab: "profile",
      groups: [],
      exportAllLoading: false,
      manufacturers: [],
      distributor: {},
      priceOptions: [],
      deletingMerchant: {},
      updatingMerchant: {},
      v1: {
        creditLimit: false,
      },
      isBusy: false,
      isBusyModal: false,
      distributors: [],
      distributorProductPermission: {},
      selectedDistributorProductPermission: null,
      count: 0,
      page: 1,
      pageNumber: 1,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
      exportAllLoading: false,
      loadingMerchants: false,
      showDeactivated: true,
    };
  },
  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  mounted() {
    this.getMerchants();
  },
  methods: {
    viewDistributorProductPermission(m) {
      this.getProductPermission(m.id);
      $("#mod-distributor-product-permission-view").modal("show");
    },
    viewDistributorProductPermissionForUpdate(m) {
      this.getProductPermission(m.id);
      $("#mod-distributor-product-permission-update").modal("show");
    },
    closeModal() {
      this.distributor = {};
      $("#mod-viewDist").modal("hide");
    },
    async getGroups() {
      try {
        this.isBusy = false;

        const response = await this.$http.get(
          `/Groups/getListNoPaging?active=${this.showDeactivated}`
        );

        if (response.ok && response.status === 200) {
          this.groups = response.body;
        }
      } catch (error) {
        this.isBusy = false;
      }
    },

    async download() {
      this.isBusy = true;
      this.exportAllLoading = true;

      try {
        const response = await this.$http.get(
          `distributor/export?active=${status}&accountType=${distributors}&query=${this.filter.qry}`,
          { responseType: "arraybuffer" }
        );

        this.prepareDownload(response);

        this.isBusy = false;
        this.exportAllLoading = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    prepareDownload(response) {
      const url = URL.createObjectURL(
        new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", DistributorsList);
      document.body.appendChild(link);
      link.click();

      this.getMerchants();
    },

    reset() {
      this.filter = {
        status: "",
        code: "",
        sourceId: "",
        group: "",
        name: "",
      };
    },

    async downloadAll() {
      this.isBusy = true;
      this.exportAllLoading = true;

      try {
        const response = await this.$http.get(
          `/Accounts/distributor/export?` +
            (this.filter.status != "0" ? "&active=" + this.filter.status : "") +
            "&accountType=Distributor" +
            "&Code=" +
            this.filter.code +
            "&SourceId=" +
            this.filter.sourceId +
            "&Group=" +
            this.filter.group +
            "&Name=" +
            this.filter.name,
          { responseType: "arraybuffer" }
        );

        this.prepareDownload(response);

        this.isBusy = false;
        this.exportAllLoading = false;
      } catch (error) {
        this.isBusy = false;
        this.exportAllLoading = false;
      }
    },

    prepareDownload(response) {
      const url = URL.createObjectURL(
        new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Distributors--");
      document.body.appendChild(link);
      link.click();
    },

    viewRemove(m) {
      this.deletingMerchant = m;
      $("#mod-delete").modal("show");
    },

    viewCreditLimit(m) {
      this.updatingMerchant = m;
      $("#mod-update").modal("show");
    },

    manageManufacturers(id) {
      this.$router.push("/distributors/" + id + "/parents");
    },

    viewFilter() {
      this.pageNumber = 1;
      $("#mod-filter").modal("show");
    },
    async refreshPage() {
      $("#mod-refresh").modal("show");
      await this.getMerchants();
    },
    CloseModal() {
      $("#mod-viewDist").modal("hide");
    },
    refreshPage2(m) {
      this.distributor = {};
      $("#mod-viewDist").modal("hide");

      $("#mod-filter").modal("show");
      setTimeout(() => {
        $("#mod-filter").modal("hide");
        this.distributor = m;
        $("#mod-viewDist").modal("show");
      }, 2000);
    },
    ViewDistributor(m) {
      this.distributor = m;
      $("#mod-viewDist").modal("show");
    },

    details(id) {
      this.$router.push("/manufacturer-distributors/" + id);
    },
    formatDate(date) {
      if (date) return moment(date).format("lll");
      else return "";
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },
    updateStatus() {
      this.isBusyModal = true;
      var data = {
        id: this.deletingMerchant.id,
        active: !this.deletingMerchant.active,
      };

      $("#mod-delete").modal("hide");

      this.$http
        .put("/Accounts/updateStatus/" + data.id, data)
        .then(function(res) {
          this.isBusyModal = false;
          this.getMerchants();
        })
        .catch(function() {
          this.isBusyModal = false;
        });
    },

    updateCreditLimits() {
      this.isBusyModal = true;

      if (this.validate()) {
        var data = {
          id: this.updatingMerchant.id,
          creditLimit: this.updatingMerchant.creditLimit,
          hasCredit: this.updatingMerchant.hasCredit,
        };

        $("#mod-update").modal("hide");

        this.$http
          .put("/Accounts/updateCreditLimits/" + data.id, data)
          .then(function(res) {
            this.isBusyModal = false;
            this.getMerchants();
          })
          .catch(function() {
            this.isBusyModal = false;
          });
      } else {
        this.isBusyModal = false;
      }
    },

    validate() {
      this.v1 = {
        creditLimit: false,
      };
      var isValid = true;

      if (Number(this.updatingMerchant.creditLimit) < 0) {
        this.v1.creditLimit = true;
        isValid = false;
      }

      return isValid;
    },
    async getGroupPriceOptions() {
      this.isBusy = true;
      try {
        const response = await this.$http.get(
          "/v1/Groups/getPriceOptions/{id}" +
            this.groupId +
            "?pageNumber=" +
            this.pageNumber +
            "&pageSize=" +
            this.pageSize +
            "&name=" +
            this.name +
            "&code=" +
            this.code +
            "&active=" +
            this.active
        );

        if (response.ok && response.status === 200) {
          console.log(response.body);
          this.priceOptions = response.body.data;
          this.pageNumber = response.body.pageNumber;
          this.pageSize = response.body.pageSize;
          this.count = response.body.count;
          this.hasPrev = response.body.hasPrev;
          this.hasNext = response.body.hasNext;
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },
    async getMerchants() {
      this.distributors = [];
      this.isBusy = true;
      this.loadingMerchants = true;
      await this.$http
        .get(
          "/Accounts/getList?pageNumber=" +
            this.pageNumber +
            "&pageSize=" +
            this.pageSize +
            (this.filter.status != "0" ? "&active=" + this.filter.status : "") +
            "&accountType=Distributor" +
            "&Code=" +
            this.filter.code +
            "&SourceId=" +
            this.filter.sourceId +
            "&Group=" +
            this.filter.group +
            "&Name=" +
            this.filter.name
        )
        .then(function(res) {
          this.distributors = res.body.data;
          this.page = res.body.pageNumber;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.isBusy = false;
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
      this.loadingMerchants = false;
    },
    async getProductPermission(distributorId) {
      this.isBusy = true;

      await this.$http
        .get("/Accounts/getDistributorProductPermission/" + distributorId)
        .then(function(res) {
          this.distributorProductPermission = res.body;
          if (this.distributorProductPermission.noRestriction)
            this.selectedDistributorProductPermission = "noRestriction";
          else if (this.distributorProductPermission.onlySellMyProducts)
            this.selectedDistributorProductPermission = "onlySellMyProducts";
          if (this.distributorProductPermission.onlySellTheirProducts)
            this.selectedDistributorProductPermission = "onlySellTheirProducts";
          this.isBusy = false;
        })
        .catch(function() {});

      this.isBusy = false;
    },

    async updateDistributorProductPermission() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        distributorId: this.distributorProductPermission.distributorId,
        manufacturerId: this.distributorProductPermission.manufacturerId,
        noRestriction:
          this.selectedDistributorProductPermission == "noRestriction",
        onlySellMyProducts:
          this.selectedDistributorProductPermission == "onlySellMyProducts",
        onlySellTheirProducts:
          this.selectedDistributorProductPermission == "onlySellTheirProducts",
        id: this.distributorProductPermission.id,
      };

      $("#mod-distributor-product-permission-update").modal("hide");

      await this.$http
        .put("/Accounts/updateDistributorProductPermission/" + data.id, data)
        .then(function(res) {
          this.isBusyModal = false;
        })
        .catch(function() {});

      this.isBusy = false;
      this.isBusyModal = false;
    },

    formatDate(date) {
      return moment(date).format("ll");
    },

    prev() {
      this.pageNumber -= 1;
      this.getMerchants();
    },
    next() {
      this.pageNumber += 1;
      this.getMerchants();
    },

    gotoPage(page) {
      this.pageNumber = page;
      this.getMerchants();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getMerchants();
    },

    async getManufacturers() {
      await this.$http
        .get("/Accounts/getItemList?accountType=Manufacturer")
        .then(function(res) {
          this.manufacturers = res.body;
        })
        .catch(function() {});
    },
  },
};
</script>
