<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-apply-order-item"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <form @submit.prevent="submit" class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1"><b>Apply Order Item</b></h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
            @click.prevent="close"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Select Order Item</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <select
                class="form-control"
                :class="{
                  'is-invalid': $v.itemId.$error,
                }"
                v-model.trim="itemId"
                @input="setItem($event.target.value)"
                placeholder="Select an order item"
              >
                <option v-for="o in activeOrderItems" :key="o.id" :value="o.id">
                  {{ o.name }}
                </option>
              </select>
              <div class="invalid-feedback" v-if="!$v.itemId.required">
                Please select an item to apply
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-secondary"
            type="button"
            @click.prevent="close"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button class="btn btn-primary" type="submit">Apply</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  props: ["orderItems"],

  data() {
    return {
      itemId: "",
    };
  },

  validations: {
    itemId: {
      required,
    },
  },

  computed: {
    activeOrderItems: function () {
      return this.orderItems.filter((x) => x.active);
    },
  },

  methods: {
    setItem(value) {
      this.itemId = value;
      this.$v.itemId.$touch();
    },

    submit() {
      this.$emit("applyItem", this.itemId);
    },

    reset() {
      this.itemId = "";
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
    },

    close() {
      this.reset();
      this.$emit("cancel");
    },
  },
};
</script>

<style>
</style>