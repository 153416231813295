<template>
  <div class="w-full pb-2 flex flex-col justify-start items-start gap-[15px]">
    <div class="border-b-2 border-solid border-[#2463eb] pb-[8px]">
      <h2 class="text-[#2463eb] text-[14px] leading-[20px] font-semibold">
        All Distributors
      </h2>
    </div>
    <div
      class="flex xs:flex-col xl:flex-row xs:justify-start xl:justify-between w-full xs:items-start xs:gap-4 lg-gap-2 xl:items-center"
    >
      <p class="text-[#353535] text-[14px] leading-[20px] font-semibold">
        Explore and manage your distributors effortlessly with this
        comprehensive list
      </p>
      <div
        class="flex gap-1 sm:gap-3 flex-wrap xs:justify-start lg:justify-center xs:items-start lg:items-center"
      >
        <button
          @click="viewFilter"
          class="border-[1px] border-[#96a0b5] rounded-[8px] text-[#96a0b5] font-medium text-[12px] sm:text-[14px] border-solid px-[12px] sm:px-[24px] py-[12px] flex items-center gap-[5px] outline-none focus:outline-none"
        >
          <span class="">
            <img src="/assets/icon/sort.svg" alt="" />
          </span>
          Filters
        </button>
        <button
          @click="refreshPage"
          class="border-[1px] bg-[#7367f0] rounded-[8px] text-white font-medium text-[12px] sm:text-[14px] border-solid px-[12px] sm:px-[24px] py-[12px] outline-none focus:outline-none"
        >
          Refresh All Data
        </button>
        <button
          class="border-[1px] bg-[#2463eb] rounded-[8px] text-white font-medium text-[12px] sm:text-[14px] border-solid px-[12px] sm:px-[24px] py-[12px] outline-none focus:outline-none"
          v-if="hasPermission('Distributor_Create')"
          @click="newDistributor"
        >
          <span class="feather icon-plus text-white"></span> New Distributors
        </button>
      </div>
    </div>
    <newDistributor
      :SearchCustomer="SearchCustomer"
      :isBusy="isBusy"
      :isSearching="isSearching"
      :isInviting="isInviting"
      :ShowConfirmAddModal="ShowConfirmAddModal"
      :currItem="currItem"
      :results="results"
      :allDistributors="allDistributors"
      :addedDistributors="addedDistributors"
      :distributors="distributors"
      :inviteDistributors="inviteDistributors"
      :removeItem="removeItem"
      :ClolseAddModal="ClolseAddModal"
      :errorMessage="errorMessage"
      :resetErrMsg="resetErrMsg"
    />
    <div
      class="modal fade py-1 colored-header colored-header-primary"
      id="mod-confirm"
      role="dialog"
    >
      <div class="modal-dialog max-w-[500px] w-full relative">
        <div class="modal-content p-[24px] bg-[#f8f8f8]">
          <button
            class="absolute right-6 top-4 text-2xl"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
          <div class="text-center">
            <div class="flex justify-center items-center mt-[16px]">
              <img src="/assets/img/alarm.png" alt="" />
            </div>

            <p class="text-[16px] font-bold text-[#353535] mb-[5px]">
              Confirmation
            </p>
            <p class="text-[13px] font-normal text-[#96a0b5]">
              Are you sure you want to add customers to list?
            </p>
            <div
              class="mt-[15px] flex gap-2 items-center justify-center w-full"
            >
              <button
                type="button"
                data-dismiss="modal"
                aria-hidden="true"
                class="border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full"
              >
                Close
              </button>
              <button
                @click="AddDistributorToList"
                type="button"
                class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] w-full"
              >
                Yes, Procced
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import newDistributor from "./AddDistributor.vue";

export default {
  components: { newDistributor },
  props: ["viewFilter", "refreshPage", "hasPermission", "distributors"],
  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },
  data: () => ({
    isBusy: false,
    isSearching: false,
    errorMessage: {
      status: false,
      message: "",
    },
    isInviting: false,
    customer_code: "",
    loading: false,
    results: [],
    allDistributors: [],
    addedDistributors: [],

    page: 1,
    pageNumber: 1,
    pageSize: 10,
    currItem: {
      data: {},
      index: null,
    },
  }),
  methods: {
    ClolseAddModal() {
      $("#new-distributor").modal("hide");
      this.addedDistributors = [];
      this.allDistributors = [];
    },
    ShowConfirmAddModal(i, index) {
      // $("#new-distributor").modal("hide");
      $("#mod-confirm").modal("show");

      this.currItem.data = i;
      this.currItem.index = index;
    },
    resetErrMsg() {
      this.errorMessage.status = false;
    },
    removeItem(index) {
      this.addedDistributors.splice(index, 1);
    },
    AddDistributorToList() {
      $("#mod-confirm").modal("hide");
      this.customer_code = "";
      let tempDist =
        this.distributors.find(
          (v) => v.name === this.currItem.data.businessName
        ) || {};

      console.log(this.currItem.data);
      console.log(tempDist);
      if (tempDist.id) {
        this.errorMessage.status = true;
        this.errorMessage.message = "Distributor has already being invited!";
        return;
      }
      this.addedDistributors.push(this.currItem.data);
      this.results.splice(this.currItem.index, 1);
      $("#new-distributor").modal("show");
      document.body.style.overflow = "hidden";
      let modal = document.querySelector("#new-distributor");
      modal.style.display = "block";
      modal.style.overflow = "auto";
    },

    newDistributor() {
      $("#new-distributor").modal("show");
      document.body.style.overflow = "auto";
    },
    async inviteDistributors() {
      this.isInviting = true;
      try {
        let res = null;
        let body = {};
        if (this.addedDistributors.length > 0) {
          body = this.addedDistributors.map((v) => v.customerCode);
          res = await this.$http.post("/Accounts/registerSapPartner", body);
        }
        const results = await res.json();
        console.log(results.message);
        this.allDistributors = results;
        this.addedDistributors = [];
        this.isInviting = false;
      } catch (error) {
        this.isBusy = false;
        console.log(error);
        this.errorMessage =
          error.response.data.message ||
          "Failed to invite distributors. Please try again.";
      }
    },
    async SearchCustomer(customer_code) {
      this.errorMessage.status = false;
      this.isSearching = true;
      try {
        const res = await this.$http.get(
          `/Accounts/searchSapPartner?customerCode=${customer_code}`
        );
        this.isBusy = false;
        this.isSearching = false;
        const data = await res.json();
        this.results = data;
        // console.log(this.results);
      } catch (error) {
        let message = error.message ? error.message : null;
        this.errorMessage.status = true;
        this.isSearching = false;
        // console.log(error);
        let msgArray = error.body.errors
          ? error.body.errors
          : error.data.errors
          ? error.data.errors
          : [];
        let msg = msgArray.length > 0 ? msgArray[0].slice(0, 30) : null;

        this.errorMessage.message =
          msg == "Could not retrieve distributor"
            ? "No Customer Found"
            : message == "Already Invited"
            ? "Distributor has already being invited!"
            : "Error Getting Distributor Info";

        console.log(msg);
      }
    },
  },
  created() {},
};
</script>
