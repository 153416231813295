<template>
  <div class="row min-h-screen shadow-none">
    <div class="col-lg-12">
      <div :class="['be-loading', { 'be-loading-active': isBusy }]">
        <div class="table-responsive">
          <table class="table p-0 m-0 dataTable no-footer">
            <thead class="bg-white">
              <tr class="py-[5px]">
                <th
                  class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                  width="30%"
                >
                  Name
                </th>
                <th
                  class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                >
                  Status
                </th>
                <th
                  class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                >
                  Created
                </th>
                <th
                  class="text-[12px] leading-[18px] text-[#000000] font-semibold"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr v-for="cat in Categories" :key="cat.id">
                <td class="text-[14px] font-normal leading-[16.94px]">
                  {{ cat.name }}
                </td>
                <td>
                  <span
                    class="flex items-center gap-[4px] max-w-max w-full"
                    :class="[
                      'badge',
                      ' badge-pill',
                      { 'badge-success': cat.active },
                      { 'badge-danger': !cat.active },
                    ]"
                  >
                    <span
                      class="inline-block w-[10px] h-[10px] rounded-[1px]"
                      :style="{
                        backgroundColor: cat.active ? '#16a249' : '#EF5253',
                      }"
                    ></span>
                    <span class="text-[14px] font-normal leading-[16.94px]">
                      {{ cat.active ? "Active" : "Inactive" }}</span
                    >
                  </span>
                </td>
                <td class="text-[14px] font-normal leading-[16.94px]">
                  {{ formatDate(cat.created) }}
                </td>
                <td @click="toggleDropdown(cat.id)">
                  <div class="dropdown">
                    <button
                      class="btn-no-border"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <span
                        class="text-[#98a2b3] dropdown text-2xl cursor-pointer relative outline-none active:outline-none shadow-none"
                      >
                        ...
                      </span>
                    </button>
                    <ul
                      class="dropdown-menu shadow-none"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <li
                        class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer border-b border-[#e5e7eb] py-[12px] px-[16px]"
                        v-if="
                          cat.parentId == user.parentId &&
                            hasPermission('Product_Unit_Update')
                        "
                        @click.prevent="viewEdit(cat)"
                      >
                        Edit
                      </li>
                      <li
                        class="text-[14px] font-normal leading-[20px] text-[#353535] cursor-pointer py-[12px] px-[16px]"
                        v-if="hasPermission('Product_Unit_Delete')"
                        @click.prevent="confirmDelete(cat.id)"
                      >
                        Delete
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr v-if="!Categories.length">
                <td colspan="12">
                  <div class="text-center" style="padding-top: 50px">
                    <span
                      style="font-size: 4.615rem"
                      class="mdi mdi-tag-multiple text-[#2463EB]"
                    ></span>
                  </div>
                  <h3
                    class="text-center pt-[24px] text-[16px] font-medium leading-[16.94px]"
                    style="padding-bottom: 100px"
                  >
                    No Matching record
                  </h3>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :limit="pageSize"
          :count="pageCount"
          :page="page"
          :itemsCount="count"
          @changePage="gotoPage"
          @changeLimit="changeLimit"
        />
      </div>
    </div>

    <!-- add new modal -->
    <div
      class="modal fade py-1 colored-header colored-header-primary"
      id="mod-add-units"
      role="dialog"
    >
      <div class="modal-dialog mx-auto">
        <div class="modal-content p-[24px] bg-[#f8f8f8]">
          <div
            class="modal-header modal-header-colored  flex items-start justify-between p-0 m-0"
          >
            <h4
              class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
            >
              Create New Unit
            </h4>
            <button
              class="text-2xl text-[#353535]"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body m-0 p-0">
            <div class="mb-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Name</label
              >
              <div>
                <input
                  :class="[{ 'is-invalid': v1.name }]"
                  type="text"
                  v-model="createCategory.name"
                  placeholder="Enter category name"
                  class="bg-transparent border-[#d0d5dd] border-solid border-[1.5px] p-[14px] rounded-[8px] w-full"
                />
                <ul v-if="v1.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="mb-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Item Type</label
              >
              <div>
                <select
                  class="bg-transparent border-[#d0d5dd] border-solid border-[1.5px] p-[14px] rounded-[8px] w-full Outline-none focus:outline-none custom-select custom-select-lg"
                  placeholder="Select an option"
                  v-model="createCategory.type"
                >
                  <option v-for="type in types" :key="type" :value="type">
                    {{ type }}
                  </option>
                </select>
                <ul v-if="v1.type" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div
              class="flex items-center gap-[10px] max-w-max mt-[16px]"
              @click="createCategory.active = !createCategory.active"
            >
              <button
                type="button"
                class="relative inline-flex items-center flex-shrink-0 h-[24px] px-[3px] w-[46px] transition-all duration-200 ease-in-out border-[0.5px] border-[#E4E4E7] shadow-sm rounded-[10px] cursor-pointer w-11 focus:outline-none"
                role="switch"
                :aria-checked="createCategory.active"
                :class="{
                  'bg-[#2463eb]': createCategory.active,
                  'bg-[#E4E4E7]': !createCategory.active,
                }"
              >
                <span
                  aria-hidden="true"
                  class="inline-block w-[21px] h-[16px] bg-white transition duration-200 ease-in-out transform bg-gray-400 rounded-full pointer-events-none"
                  :class="{
                    'translate-x-5 bg-white': createCategory.active,
                    'translate-x-0 bg-gray-400': !createCategory.active,
                  }"
                ></span>
              </button>
              <span class="cursor-pointer">
                <span
                  class="text-[#353535] text-[16px] font-normal leading-[20px]"
                >
                  This item is active
                </span>
              </span>
            </div>
          </div>
          <div class="flex items-center gap-[8px] mt-[16px]">
            <button
              class="border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] w-full"
              type="button"
              :disabled="isBusyModal"
              @click.prevent="create"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Add item</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- edit modal -->
    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit"
      role="dialog"
    >
      <div class="modal-dialog mx-auto">
        <div class="modal-content p-[24px] bg-[#f8f8f8]">
          <div
            class="modal-header modal-header-colored  flex items-start justify-between p-0 m-0"
          >
            <h4
              class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
            >
              Edit item
            </h4>
            <button
              class="text-2xl text-[#353535]"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              @click="cancel"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body p-0 m-0">
            <div>
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Name</label
              >
              <div>
                <input
                  :class="[{ 'is-invalid': v2.name }]"
                  type="text"
                  v-model="updateCategory.name"
                  class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full"
                />
                <ul v-if="v2.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="my-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Item Type</label
              >
              <div>
                <select
                  class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full"
                  placeholder="Select an option"
                  v-model="updateCategory.type"
                >
                  <!-- <select :class="['form-control', { 'is-invalid':v2.type }]" v-model="updateCategory.type"> -->
                  <option v-for="type in types" :key="type" :value="type">
                    {{ type }}
                  </option>
                </select>
                <ul v-if="v2.type" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div
              class="flex items-center gap-[10px] max-w-max"
              @click="updateCategory.active = !updateCategory.active"
            >
              <button
                type="button"
                class="relative inline-flex items-center flex-shrink-0 h-[24px] px-[3px] w-[46px] transition-all duration-200 ease-in-out border-[0.5px] border-[#E4E4E7] shadow-sm rounded-[10px] cursor-pointer w-11 focus:outline-none"
                role="switch"
                :aria-checked="createCategory.active"
                :class="{
                  'bg-[#2463EB]': updateCategory.active,
                  'bg-[#E4E4E7]': !updateCategory.active,
                }"
              >
                <span
                  aria-hidden="true"
                  class="inline-block w-[21px] h-[16px] bg-white transition duration-200 ease-in-out transform bg-gray-400 rounded-full pointer-events-none"
                  :class="{
                    'translate-x-5 bg-white': updateCategory.active,
                    'translate-x-0 bg-gray-400': !updateCategory.active,
                  }"
                ></span>
              </button>
              <span class="cursor-pointer">
                <span
                  class="text-[#353535] text-[16px] font-normal leading-[20px]"
                >
                  This item is active
                </span>
              </span>
            </div>
          </div>
          <div class="flex items-center gap-[8px] mt-[16px]">
            <button
              class="border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full outline-none focus:outline-none"
              type="button"
              data-dismiss="modal"
              @click="cancel"
            >
              Cancel
            </button>
            <button
              class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] w-full outline-none focus:outline-none"
              :disabled="isBusyModal"
              type="button"
              @click.prevent="update"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- filter modal -->
    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-filter-units"
      role="dialog"
    >
      <div
        class="modal-dialog m-0 p-0 absolute top-0 right-0 flex justify-end items-end"
      >
        <div
          class="modal-content rounded-none bg-[#f8f8f8] p-[20px] md:p-[40px] md:min-h-screen"
        >
          <div
            class="modal-header modal-header-colored  flex items-start justify-between p-0 m-0"
          >
            <h4
              class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
            >
              Filter
            </h4>
            <button
              class="text-2xl text-[#353535]"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body m-0 p-0">
            <div class="pb-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >
                Creation Start Date:</label
              >
              <div>
                <input
                  class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full"
                  type="date"
                  :max="maxDate"
                  placeholder="Select a start date"
                  v-model="filter.startDate"
                />
              </div>
            </div>
            <div class="pb-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >
                Creation End Date:</label
              >
              <div>
                <input
                  class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full"
                  type="date"
                  :max="maxDate"
                  placeholder="Select a end date"
                  v-model="filter.endDate"
                />
              </div>
            </div>
            <div class="pb-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Status</label
              >
              <div>
                <select
                  class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full"
                  placeholder="Select an option"
                  v-model="filter.status"
                >
                  <option
                    v-for="opt in options"
                    :key="opt.text"
                    :value="opt.value"
                  >
                    {{ opt.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="pb-[16px]">
              <label
                class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
                >Name</label
              >
              <div>
                <input
                  class="bg-[#fff] border-[#d0d5dd] border-solid border p-[14px] rounded-[8px] w-full"
                  type="text"
                  placeholder="Filter by product name..."
                  v-model="filter.name"
                />
              </div>
            </div>
          </div>
          <div class="flex items-center gap-[8px] w-full">
            <button
              class="bg-transparent border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <!-- <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="resetFilter"
            >
              Reset
            </button> -->
            <button
              class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] w-full"
              type="button"
              @click.prevent="getCategories"
            >
              <span v-if="isBusy">Busy...</span>
              <span v-else>Search</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <GeneralDeleteCaution
      :id="DeleteId"
      @proceed="deleteProductUnit"
      :isBusy="isBusyModal"
      text="product unit"
    />
    <GeneralDeleteMessage
      message="You cannot delete this product unit because there is a product history associated with it."
    />
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import GeneralDeleteCaution from "../GeneralDeleteCaution.vue";
import GeneralDeleteMessage from "../GeneralDeleteMessage.vue";
import Pagination from "../Pagination.vue";
import Select2 from "../Select2";
import SystemPermissions from "@/permissions";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsLists",
  components: {
    Select2,
    GeneralDeleteCaution,
    GeneralDeleteMessage,
    Pagination,
  },
  created() {
    if (!this.hasPermission("Product_Unit_View")) {
      this.$router.push("/restricted");
    }
    this.getCategories();
    this.getTypes();
  },
  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      showDropdown: null,
      filter: {
        name: "",
        status: null,
        startDate: "",
        endDate: "",
      },
      createCategory: {
        name: null,
        type: null,
        active: false,
        parentId: null,
      },
      updateCategory: {
        name: null,
        type: null,
        active: false,
        id: null,
      },
      editCategory: {
        name: null,
        type: null,
        active: false,
        id: null,
      },
      v1: {
        name: false,
        type: false,
      },
      v2: {
        name: false,
        type: false,
      },
      Categories: [],
      DeleteId: "",
      types: [],
      count: 0,
      page: 1,
      pageSize: 10,
      pageNumber: 0,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
      maxDate: "",
      options: [
        { text: "All", value: "" },
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
    };
  },
  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },
  mounted() {
    this.maxDate = helper.preventFutureDateSelection();
  },
  methods: {
    toggleDropdown(userId) {
      this.showDropdown = this.showDropdown === userId ? null : userId;
    },
    resetFilter() {
      this.filter = {
        name: "",
        status: "",
        startDate: "",
        endDate: "",
      };
      this.getCategories();
    },

    viewFilter() {
      this.pageNumber = 0;
      $("#mod-filter-units").modal("show");
    },

    cancel() {
      const currentUnit = this.Categories.find(
        (c) => c.id === this.editCategory.id
      );
      currentUnit.name = this.editCategory.name;
      currentUnit.id = this.editCategory.id;
      currentUnit.type = this.editCategory.type;
      currentUnit.active = this.editCategory.active;
      $("#mod-edit").modal("hide");
    },

    confirmDelete(id) {
      this.DeleteId = id;
      $("#mod-confirm-delete").modal("show");
    },

    showDeleteMsg() {
      $("#mod-delete-msg").modal("show");
    },

    cancelConfirm() {
      this.DeleteId = "";
      $("#mod-confirm-delete").modal("hide");
    },
    async deleteProductUnit() {
      this.isBusyModal = true;
      try {
        const res = await this.$http.delete(`/Categories/${this.DeleteId}`);
        if (res.ok) {
          this.$toast.success("Product Unit deleted successfully", {
            icon: true,
            rtl: false,
          });
          this.getCategories();
          this.cancelConfirm();
        }
      } catch (error) {
        this.cancelConfirm();
        this.showDeleteMsg();
      }
      this.isBusyModal = false;
    },

    viewAdd() {
      this.createCategory = {
        name: null,
        type: null,
        active: false,
        parentId: null,
      };
      this.v1 = {
        name: false,
        type: false,
      };

      $("#mod-add-units").modal("show");
    },

    viewEdit(c) {
      var editValues = {
        name: c.name,
        type: c.type,
        active: c.active,
        id: c.id,
      };
      this.editCategory = editValues;
      this.updateCategory = { ...c };
      $("#mod-edit").modal("show");
    },

    create() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        name: this.createCategory.name,
        type: this.createCategory.type,
        active: this.createCategory.active,
        parentId: this.user.parentId,
      };

      if (this.validateCreate()) {
        $("#mod-add-units").modal("hide");

        this.$http
          .post("/Categories/create", data)
          .then(function(res) {
            this.isBusyModal = false;
            this.$toast.success("Unit created successfully.", {
              icon: true,
              rtl: false,
            });
            this.getCategories();
          })
          .catch(function() {
            this.isBusy = false;

            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    validateCreate() {
      this.v1 = {
        name: false,
        type: false,
      };
      var isValid = true;

      if (!this.createCategory.name) {
        this.v1.name = true;
        isValid = false;
      }

      if (!this.createCategory.type) {
        this.v1.type = true;
        isValid = false;
      }
      return isValid;
    },

    update() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        name: this.updateCategory.name,
        type: this.updateCategory.type,
        active: this.updateCategory.active,
        id: this.updateCategory.id,
      };

      if (this.validateUpdate()) {
        $("#mod-edit").modal("hide");

        this.$http
          .put("/Categories/update/" + data.id, data)
          .then(function(res) {
            this.isBusyModal = false;
            this.getCategories();
          })
          .catch(function() {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    validateUpdate() {
      this.v2 = {
        name: false,
        type: false,
      };
      var isValid = true;

      if (!this.updateCategory.name) {
        this.v2.name = true;
        isValid = false;
      }

      if (!this.updateCategory.type) {
        this.v2.type = true;
        isValid = false;
      }

      return isValid;
    },

    getCategories() {
      this.isBusy = true;
      let type = "Product Unit";
      var t = this.$route.query.t;
      var url = "/Categories/getList";
      if (t == "d") url = "/Categories/getListForDistributor";
      let pageParam = t == "d" ? "page" : "pageNumber";
      let pageValue = t == "d" ? this.page - 1 : this.page;
      this.$http
        .get(
          url +
            `?&${pageParam}=` +
            pageValue +
            "&pageSize=" +
            this.pageSize +
            "&type=" +
            type +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "") +
            (this.filter.name !== " " ? "&Name=" + this.filter.name : "") +
            (this.filter.startDate !== " "
              ? "&StartDate=" + this.filter.startDate
              : "") +
            (this.filter.endDate !== " "
              ? "&EndDate=" + this.filter.endDate
              : "") +
            (this.filter.status !== "" ? "&Active=" + this.filter.status : "")
        )
        .then(function(res) {
          this.Categories = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
          $("#mod-filter-units").modal("hide");
          // this.filter = {
          //   name: '',
          //   status: '',
          //   startDate: '',
          //   endDate: '',
          // };
        })
        .catch(function() {
          this.isBusy = false;
        });
    },

    getTypes() {
      this.types = ["Product Unit"];
    },

    formatDate(date) {
      return moment(date).format("ll");
    },

    prev() {
      this.page -= 1;
      this.getCategories();
    },

    next() {
      this.page += 1;
      this.getCategories();
    },

    gotoPage(page) {
      this.page = page;
      this.getCategories();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getCategories();
    },
  },
};
</script>
