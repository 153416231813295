<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-process"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body p-4">
          <h4 class="text-center">
            You are about to
            <span class="text-uppercase">process</span> a purchase order with
            Purchase Order Number {{ order.orderNumber }}
          </h4>
        </div>
        <div
          class="modal-footer d-flex justify-content-center align-items-center"
        >
          <button class="btn btn-danger" type="button" data-dismiss="modal">
            Cancel
          </button>
          <button
            class="btn btn-success"
            type="button"
            :disabled="isBusyModal"
            @click.prevent="onSubmitClick"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProcessStatusModal",
  props: ["order"],

  data() {
    return {
      isBusyModal: false,
    };
  },

  methods: {
    async onSubmitClick() {
      this.isBusyModal = true;
      try {
        const response = await this.$http.put(
          `/PurchaseOrders/manufacturer/updateStatus/${this.order.id}`
        );

        if (response) {
          // eslint-disable-next-line no-undef
          $("#mod-process").modal("hide");
          this.isBusyModal = false;
          this.$emit("onComplete");
        }
      } catch (error) {
        this.isBusyModal = false;
      }
    },
  },
};
</script>

<style>
</style>