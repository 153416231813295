<template>
  <div class="modal fade" id="mod-rename-stage" role="dialog">
    <div class="modal-dialog mx-auto max-w-[420px] w-full">
      <form @submit.prevent="submit" class="modal-content p-[24px] bg-[#fff]">
        <div class="flex items-start justify-between p-0 m-0">
          <h4
            class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
          >
            Confirmation
          </h4>
          <button
            class="text-2xl text-[#353535] outline-none"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body m-0 p-0">
          <div class="mb-[16px]">
            <label class="text=[#353535] font-bold text-[14px] leading-[20px]"
              >Name</label
            >
            <div>
              <input
                :class="{
                  'is-invalid': $v.name.$error,
                }"
                type="text"
                placeholder="Enter item name"
                v-model.trim="name"
                class="border-[#d0d5dd] bg-transparent border-solid border-[1.5px] p-[14px] rounded-[8px] w-full outline-none"
                @input="setName($event.target.value)"
              />
              <span class="invalid-feedback" v-if="!$v.name.required">
                Name is required.
              </span>
              <span class="invalid-feedback" v-if="!$v.name.minLength">
                Name must have at least
                {{ $v.name.$params.minLength.min }}
                letters.
              </span>
            </div>
          </div>
          <div class="pb-[16px]">
            <label class="text=[#353535] font-bold text-[14px] leading-[20px]"
              >Description</label
            >
            <div>
              <textarea
                type="text"
                class="border-[#d0d5dd] bg-transparent border-solid border-[1.5px] p-[14px] rounded-[8px] w-full outline-none"
                placeholder="Enter description here"
              />
            </div>
          </div>
        </div>
        <div class="flex items-center gap-[8px] w-full">
          <button
            class="bg-transparent border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full outline-none focus:outline-none"
            type="button"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            :disabled="isBusyModal"
            class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] w-full outline-none focus:outline-none"
            type="submit"
          >
            <span v-if="isBusyModal">Busy..</span>
            <span v-else>Submit</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";

export default {
  props: ["stage"],

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      name: "",
    };
  },

  validations: {
    name: {
      required,
      minLength: minLength(3),
    },
  },

  watch: {
    stage: function(value) {
      this.setName(value.name);
    },
  },

  methods: {
    setName(value) {
      this.name = value;
      this.$v.name.$touch();
    },

    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isBusy = true;
        this.isBusyModal = true;

        try {
          const data = {
            name: this.name,
          };

          let response = await this.$http.put(
            "/PurchaseOrders/orderStage/rename/" + this.stage.id,
            data
          );

          if (response.ok) this.$emit("onRenameComplete");

          this.isBusy = false;
          this.isBusyModal = false;
        } catch (error) {
          this.isBusy = false;
          this.isBusyModal = false;
        }
      }
    },
  },
};
</script>

<style></style>
