<template>
  <div class="modal fade" id="mod-add-distributor" role="dialog">
    <div class="modal-dialog mx-auto max-w-[500px] w-full">
      <form class="modal-content p-[24px] bg-[#fff]">
        <div class="flex items-start justify-between p-0 m-0">
          <h4
            class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
          >
            Add Distributors To Group
          </h4>
          <button
            class="text-2xl text-[#353535] outline-none focus:outline-none"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
            @click="reset"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body m-0 p-0">
          <multiselect
            v-model="value"
            :multiple="true"
            label="text"
            track-by="text"
            :preserve-search="true"
            :options="distributors"
          ></multiselect>

          <div class="flex items-center gap-[8px] mt-[16px]">
            <button
              class="border-[##96a0b5] border-solid border-[1.5px] py-[16px] text-[14px] md:text-[16px] rounded-[8px] font-medium leading-[20px] w-full outline-none focus:outline-none"
              type="button"
              data-dismiss="modal"
              @click="reset"
            >
              Cancel
            </button>
            <button
              :disabled="isBusyModal"
              class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] text-[14px] md:text-[16px] rounded-[8px] text-white font-medium leading-[20px] w-full outline-none focus:outline-none"
              @click.prevent="submit"
              type="submit"
            >
              <span class="text-[14px] md:text-[16px]" v-if="isBusyModal"
                >Busy...</span
              >
              <span class="text-[14px] md:text-[16px]" v-else
                >Add Distributors</span
              >
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Multiselect from "vue-multiselect";
export default {
  name: "AddGroupDistributor",

  components: { Multiselect },

  props: ["groupId"],

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      value: "",
      distributors: [],
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  mounted() {
    this.getDistributors();
  },

  methods: {
    async getDistributors() {
      this.isBusy = true;
      this.isBusyModal = true;

      try {
        const response = await this.$http.get(
          "/Accounts/getItemList?parentId=" +
            this.user.parentId +
            "&accountType=Distributor"
        );

        if (response.ok) this.distributors = response.body;

        this.isBusy = false;
        this.isBusyModal = false;
      } catch (error) {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    async submit() {
      this.isBusy = true;
      this.isBusyModal = true;
      try {
        const distributors = this.value.map((val) => {
          return { id: val.id, name: val.text };
        });

        const data = {
          type: "Distributor",
          groupId: this.groupId,
          members: distributors,
        };

        const response = await this.$http.post("/Groups/addMembers", data);
        if (response.ok || response.status === 400) {
          this.reset();
          this.$emit("onComplete");
        }

        this.isBusy = false;
        this.isBusyModal = false;
      } catch (error) {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    reset() {
      this.value = "";
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
