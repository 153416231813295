<template>
  <div class="modal fade py-1" id="mod-product-add" role="dialog">
    <div class="modal-dialog mx-auto">
      <form
        @submit.prevent="submit"
        class="modal-content p-[24px] bg-[#f8f8f8]"
      >
        <div class="modal-header flex items-start justify-between p-0 m-0">
          <h4
            class="font-semibold text-[20px] leading-[24px] capitalize text-[#353535] pb-[16px]"
          >
            {{ isEdit ? "Update Item" : "Add Item" }}
          </h4>
          <button
            class="text-2xl text-[#353535]"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
            @click="cancel"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body m-0 p-0">
          <div class="mb-[16px]">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Select product</label
            >
            <div>
              <select
                :disabled="isEdit"
                class="bg-transparent border-[#d0d5dd] border-solid border-[1.5px] p-[14px] rounded-[8px] w-full"
                :class="{
                  'is-invalid': $v.newItem.productId.$error,
                }"
                v-model.trim="newItem.productId"
                @input="setProduct($event.target.value)"
                placeholder="Select a product"
              >
                <option v-for="p in preparedProducts" :key="p.id" :value="p.id">
                  {{ p.name }}
                </option>
              </select>
              <span
                class="invalid-feedback"
                v-if="!$v.newItem.productId.required"
              >
                Please select a product
              </span>
            </div>
          </div>
          <div class="mb-[16px]">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Quantity</label
            >
            <div>
              <input
                class="bg-transparent border-[#d0d5dd] border-solid border-[1.5px] p-[14px] rounded-[8px] w-full"
                :class="{
                  'is-invalid': $v.newItem.quantity.$error,
                }"
                type="number"
                placeholder="Enter the quantity you needed"
                v-model.trim="newItem.quantity"
                @input="setQuantity($event.target.value)"
              />
              <span
                class="invalid-feedback"
                v-if="!$v.newItem.quantity.required"
              >
                Please enter a quantity
              </span>
              <span
                class="invalid-feedback"
                v-if="!$v.newItem.quantity.minValue"
              >
                Quantity must be greater than
                {{ $v.newItem.quantity.$params.minValue.min - 1 }}.
              </span>
            </div>
          </div>
          <div class="mb-[16px]">
            <label
              class="text=[#353535] font-bold-500 text-[14px] leading-[20px]"
              >Price</label
            >
            <div v-if="!hasPriceOptions">
              <input
                class="bg-transparent border-[#d0d5dd] border-solid border-[1.5px] p-[14px] rounded-[8px] w-full"
                type="number"
                readonly
                v-model.trim="newItem.price"
              />
            </div>
            <div v-else>
              <select2
                v-model="newItem.priceOptionId"
                @input="setPrice($event)"
                class="bg-transparent border-[#d0d5dd] border-solid border-[1.5px] p-[14px] rounded-[8px] w-full"
                placeholder="Select a price option"
              >
                <option v-for="p in priceOptions" :key="p.id" :value="p.id">
                  {{ p.name }} - &#8358;{{ formatMoney(p.distributorPrice) }}
                </option>
              </select2>
              <span
                class="invalid-feedback"
                v-if="hasPriceOptions && !$v.newItem.price.required"
              >
                Please select a price option
              </span>
            </div>
          </div>
        </div>
        <div class="flex items-center gap-[8px] mt-[16px]">
          <button
            class="border-[##96a0b5] border-solid border-[1.5px] py-[16px] px-[24px] rounded-[8px] text-[16px] font-medium leading-[20px] w-full"
            type="button"
            data-dismiss="modal"
            @click="cancel"
          >
            Cancel
          </button>
          <button
            class="bg-[#2463eb] border-[##2463eb] border-solid border py-[16px] px-[24px] rounded-[8px] text-white text-[16px] font-medium leading-[20px] w-full"
            type="submit"
          >
            <span
              class="text-white text-[16px] font-medium leading-[20px]"
              v-if="isBusyModal"
              >Busy...</span
            >
            <span
              class="text-white text-[16px] font-medium leading-[20px]"
              v-else-if="isEdit"
              >Update Item</span
            >
            <span
              class="text-white text-[16px] font-medium leading-[20px]"
              v-else
              >Add Item</span
            >
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { minValue, required } from "vuelidate/lib/validators";
import Select2 from "@/components/Select2";
import { helper } from "@/helper";
export default {
  components: { Select2 },
  name: "AddItem",
  props: ["edit", "groupId", "products", "filteredProducts"],

  data() {
    return {
      isBusyModal: false,
      manufacturerProducts: [],
      orderId: "",
      newItem: {
        productId: "",
        quantity: 0,
        price: 0,
        name: "",
        priceOptionId: "",
      },
      hasPriceOptions: null,
      priceOptions: [],
    };
  },

  computed: {
    isEdit: function() {
      return this.edit !== null;
    },

    user: function() {
      return this.$store.state.currentUser;
    },

    preparedProducts() {
      if (this.isEdit) {
        return this.products;
      } else {
        return this.filteredProducts;
      }
    },
  },

  validations: {
    newItem: {
      productId: {
        required,
      },

      quantity: {
        required,
        minValue: minValue(1),
      },

      price: {
        required,
      },
    },
  },

  watch: {
    edit: function(value) {
      if (value) {
        this.setQuantity(value.quantity);
        this.setProduct(value.productId);
      }
    },
  },

  created() {
    this.orderId = this.groupId;
    console.log(this.orderId);
  },

  methods: {
    setQuantity(value) {
      this.newItem.quantity = value;
      this.$v.newItem.quantity.$touch();
    },

    setProduct(value) {
      this.newItem.productId = value;
      this.$v.newItem.productId.$touch();
      this.getDistributorPrice(value);
    },

    setPrice(value) {
      this.newItem.priceOptionId = value;
      const thevalue = value;
      this.setPriceOptionDetails(thevalue);
      this.$v.newItem.price.$touch();
      return;
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    setPriceOptionDetails(id) {
      this.newItem.price = this.priceOptions.filter(
        (item) => item.id === id
      )[0].distributorPrice;
      this.newItem.name = this.priceOptions.filter(
        (item) => item.id === id
      )[0].name;
      return;
    },

    async getDistributorPrice(id) {
      this.priceLoading = true;
      try {
        if (this.groupId) {
          const response = await this.$http.get(
            `/Groups/price/${this.groupId}/${id}`
          );

          if (response.status === 200) {
            if (response.body.canViewList) {
              this.hasPriceOptions = response.body.canViewList;
              this.priceOptions = response.body.priceOptions;
            } else {
              this.newItem.price = response.body.singlePrice.distributorPrice;
              this.newItem.name = response.body.singlePrice.name;
            }
          }

          if (this.newItem.price === 0) this.getProductPrice(id);
        } else {
          this.getProductPrice(id);
        }

        this.priceLoading = false;
      } catch (error) {
        this.priceLoading = false;
      }
    },

    getProductPrice(id) {
      const product = this.products.find((p) => p.id === id);
      this.newItem.price = product.distributorPrice;
    },

    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isEdit
          ? this.$emit("onItemUpdated", { ...this.newItem })
          : this.$emit("onItemAdded", { ...this.newItem });
        this.reset();
      }
    },

    reset() {
      this.newItem.productId = "";
      this.newItem.quantity = 0;
      this.newItem.price = 0;
      this.newItem.name = "";
      this.newItem.priceOptionId = "";
      this.hasPriceOptions = null;
      this.priceOptions = [];
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
    },

    cancel() {
      this.reset();
      this.$emit("cancel");
    },
  },
};
</script>
