<template>
	<div
		class="modal fade colored-header colored-header-primary"
		id="mod-add-payment-record"
		role="dialog"
	>
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header modal-header-colored">
					<h4 class="modal-title p-1">
						<strong>Record payment</strong>
					</h4>
					<button
						class="close md-close"
						type="button"
						data-dismiss="modal"
						aria-hidden="true"
						@click="cancel"
					>
						<span class="mdi mdi-close"></span>
					</button>
				</div>
				<div class="modal-body">
					<div class="form-group row">
						<label class="col-12 col-sm-4 col-form-label text-sm-right">Customer</label>
						<div class="col-12 col-sm-8 col-lg-8">
							<p class="form-control text-disabled">{{ order.customer }}</p>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-12 col-sm-4 col-form-label text-sm-right">Order ID</label>
						<div class="col-12 col-sm-8 col-lg-8">
							<p class="form-control text-disabled">{{ order.id }}</p>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-12 col-sm-4 col-form-label text-sm-right"
							>Order Amount</label
						>
						<div class="col-12 col-sm-8 col-lg-8">
							<p class="form-control text-disabled">
								&#8358;{{ formatMoney(order.amount) }}
							</p>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-12 col-sm-4 col-form-label text-sm-right"
							>Payment Channel</label
						>
						<div class="col-12 col-sm-8 col-lg-8">
							<select
								:class="['form-control', { 'is-invalid': v1.paymentChannelId }]"
								placeholder="Select a payment channel"
								v-model.trim="order.paymentChannelId"
							>
								<option v-for="br in paymentChannels" :key="br.id" :value="br.id">
									{{ prepareDetail(br) }}
								</option>
							</select>
							<div class="invalid-feedback" v-if="v1.paymentChannelId">
								Field is required.
							</div>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-12 col-sm-4 col-form-label text-sm-right"
							>Amount Paid</label
						>
						<div class="col-12 col-sm-8 col-lg-8">
							<input
								:class="['form-control', { 'is-invalid': v1.amountPaid }]"
								type="number"
								placeholder="Enter amount"
								v-model.trim="order.amountPaid"
							/>
							<div class="invalid-feedback" v-if="v1.amountPaid">
								Field is required.
							</div>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-12 col-sm-4 col-form-label text-sm-right">Notes</label>
						<div class="col-12 col-sm-8 col-lg-8">
							<textarea
								:class="['form-control', { 'is-invalid': v1.notes }]"
								v-model.trim="order.notes"
							></textarea>
							<div class="invalid-feedback" v-if="v1.notes">
								Field is required.
							</div>
						</div>
					</div>
					<div class="form-group row">
						<label class="col-12 col-sm-3 col-md-4 col-form-label text-sm-right"
							>Payment Date</label
						>
						<div class="col-12 col-sm-8 col-lg-8">
							<input
								type="date"
								:max="maxDate"
								:class="['form-control', { 'is-invalid': v1.paymentDate }]"
								placeholder="Select a date"
								v-model="order.paymentDate"
							/>
							<div class="invalid-feedback" v-if="v1.paymentDate">
								Field is required.
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button
						class="btn btn-secondary md-close"
						type="button"
						data-dismiss="modal"
						@click="cancel"
					>
						Cancel
					</button>
					<button class="btn btn-primary" @click.prevent="recordPayment" type="button">
						<span
							v-if="isPosting"
							class="spinner-border spinner-border-sm"
							role="status"
						>
							<i class="sr-only">Loading...</i>
						</span>
						<span v-else> {{ isEdit ? "Update" : "Post" }} </span>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import moment from "moment";
	import { required } from "vuelidate/lib/validators";
	import { helper } from "@/helper";
	import datePicker from "vue-bootstrap-datetimepicker";
	export default {
		name: "add-record",
		props: ["value", "isEdit", "editDetails"],
		components: { datePicker },

		data() {
			return {
				paymentChannels: [],
				customers: [],
				isPosting: false,
				order: {
					amountPaid: this.isEdit ? this.editDetails.amountPaid : null,
					notes: this.isEdit ? this.editDetails.notes : null,
					paymentChannelId: this.isEdit ? this.editDetails.paymentChannelId : null,
					paymentDate: this.isEdit
						? this.editDetails.paymentDate
						: moment().format("YYYY-MM-DD"),
				},
				v1: {
					amountPaid: false,
					notes: false,
					paymentChannelId: false,
					paymentDate: false,
					is_valid: true,
					text: "",
				},

				options: {
					useCurrent: true,
					format: "YYYY-MM-DD",
				},
				maxDate: "",
			};
		},

		validations: {
			payment: {
				customerId: { required },
				paymentChannelId: { required },
				amount: { required },
				paymentDate: { required },
			},
		},

		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},
		},

		watch: {
			value: function(value) {
				this.order = {
					customer: this.value.maker.displayName,
					id: this.value.orderNumber ? this.value.orderNumber : "",
					amount: this.value.total ? this.value.total : "",
					amountPaid: this.isEdit ? this.editDetails.amountPaid : null,
					notes: this.isEdit ? this.editDetails.notes : null,
					paymentChannelId: this.isEdit ? this.editDetails.paymentChannelId : null,
					paymentDate: this.isEdit
						? this.editDetails.paymentDate
						: moment().format("YYYY-MM-DD"),
				};
			},

			isEdit: function(value) {
				this.order = {
					customer: this.value.maker.displayName,
					id: this.value.orderNumber ? this.value.orderNumber : "",
					amount: this.value.total ? this.value.total : "",
					amountPaid: this.isEdit ? this.editDetails.amountPaid : null,
					notes: this.isEdit ? this.editDetails.notes : null,
					paymentChannelId: this.isEdit ? this.editDetails.paymentChannelId : null,
					paymentDate: this.isEdit
						? this.editDetails.paymentDate
						: moment().format("YYYY-MM-DD"),
				};
			},
		},

		async mounted() {
			await this.getCustomers();
			await this.getPaymentChannels();
			this.v1 = {
				notes: false,
				amountPaid: false,
				is_valid: true,
				text: "",
			};

			this.order = {
				customer: this.value.maker ? this.value.maker.displayName : "",
				id: this.value.orderNumber ? this.value.orderNumber : "",
				amount: this.value.total ? this.value.total : "",
				amountPaid: this.isEdit ? this.editDetails.amountPaid : null,
				notes: this.isEdit ? this.editDetails.notes : null,
				paymentChannelId: this.isEdit ? this.editDetails.paymentChannelId : null,
				paymentDate: this.isEdit
					? this.editDetails.paymentDate
					: moment().format("YYYY-MM-DD"),
				orderAmount: this.value.total,
			};
			this.maxDate = helper.preventFutureDateSelection();
		},

		methods: {
			formatMoney(val) {
				return helper.formatMoney(val);
			},

			prepareDetail(value) {
				const name = value.paymentChannelTypeName;
				let result = "";
				if (name) {
					result += name;

					if (name.toLowerCase() === "bank") {
						result += "/ " + value.bankName + "/ " + value.accountNumber;
					} else if (name.toLowerCase() === "pos") {
						result += "/ " + value.bankName + "/ " + value.terminalId;
					} else if (name.toLowerCase() === "cryptocurrency") {
						result += "/ " + value.bankName + "/ " + value.walletAddress;
					}
				}
				return result;
			},

			async getCustomers() {
				try {
					const response = await this.$http.get(
						"/Users/getItemListOfCustomers/" + this.user.parentId,
					);

					if (response.ok) {
						this.customers = response.body;
					}
				} catch (error) {}
			},

			async getPaymentChannels() {
				try {
					const response = await this.$http.get(
						"/PaymentChannels/getListNoPaging?active=true" +
							(this.user.parentId ? "&parentId=" + this.user.parentId : ""),
					);

					if (response.ok) this.paymentChannels = response.body;
				} catch (error) {}
			},

			cancel() {
				this.v1 = {
					notes: false,
					amountPaid: false,
					is_valid: true,
					text: "",
				};

				this.order = {
					customer: this.value.maker.displayName,
					amount: this.value.total,
					id: this.value.orderNumber,
					amountPaid: null,
					notes: null,
					paymentChannelId: null,
					paymentDate: moment().format("YYYY-MM-DD"),
					orderAmount: this.value.total,
				};
				this.$emit("reset");
				this.$emit("closeModal");
			},

			validatePayment() {
				this.v1 = {
					notes: false,
					amountPaid: false,
					paymentDate: false,
					paymentChannelId: false,
					is_valid: true,
					text: "",
				};
				var isValid = true;

				if (!this.order.amountPaid) {
					this.v1.amountPaid = true;
					isValid = false;
				}

				if (!this.order.notes) {
					this.v1.notes = true;
					isValid = false;
				}

				if (!this.order.paymentDate) {
					this.v1.paymentDate = true;
					isValid = false;
				}

				if (!this.order.paymentChannelId) {
					this.v1.paymentChannelId = true;
					isValid = false;
				}

				if (this.order.amountPaid <= 0) {
					this.v1.is_valid = false;
					this.v1.text = "Payment amount must be greater than 0";
					isValid = false;
				}

				return isValid;
			},

			async recordPayment() {
				this.isPosting = true;

				if (this.validatePayment()) {
					const payload = {
						orderId: this.value.id,
						amountPaid: +this.order.amountPaid,
						notes: this.order.notes,
						paymentChannelId: this.order.paymentChannelId,
						paymentDate: this.order.paymentDate,
						userId: this.value.makerId,
					};

					this.isEdit
						? await this.$http
								.put(`/Orders/payment/${this.editDetails.paymentId}`, payload)
								.then(function(res) {
									this.$toast.success("Order payment posted successfully", {
										icon: true,
										rtl: false,
									});
									this.$emit("reset");
									this.isPosting = false;
									this.$emit("getOrder");
									this.cancel();
								})
								.catch(function() {
									this.isPosting = false;
								})
						: await this.$http
								.post("/Orders/updatePayment", payload)
								.then(function(res) {
									this.$toast.success("Order payment posted successfully", {
										icon: true,
										rtl: false,
									});
									this.isPosting = false;
									this.$emit("getOrder");
									this.cancel();
								})
								.catch(function() {
									this.isPosting = false;
								});
				} else {
					this.isPosting = false;
				}
			},
		},
	};
</script>

<style scoped>
	.text-disabled {
		cursor: not-allowed;
		background-color: #f5f5f0;
	}
</style>
