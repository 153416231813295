<template>
	<div>
		<div class="col">
			<button class="btn btn-danger mb-2" @click="$router.go(-1)">
					<span class="feather icon-arrow-left text-white"></span>
					Go Back
				</button>
			<div
				:class="[
					'card',
					'card-border-color',
					'card-border-color-primary',
					'card-table',
					'be-loading',
					{ 'be-loading-active': isBusy },
				]"
			>
				<div class="card-header">
					<div>
						<h4>
							<b>
								PO Number - {{ orderNumber }}
							</b>
						</h4>
						<p>Purchase Order for {{ sellerBusinessName }}</p>
					</div>
					<div>
						<h4 class="text-uppercase">
							<b>Payment Status - {{ paymentStatus ? paymentStatus : 'Nill' }}</b>
						</h4>
					</div>
				</div>
				<div class="card-body">
					<div class="table-responsive">
						<table class="table table-hover table-sm">
							<thead class="thead-light">
								<tr>
									<th scope="col">Item #</th>
									<th scope="col">Description</th>
									<th scope="col">Quantity</th>
									<th class="tc-width" scope="col ">Total</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, index) in items" :key="item.id">
									<td>{{ index + 1 }}</td>
									<td>{{ item.description }}</td>
									<td>{{ item.quantity }}</td>
									<td>&#8358;{{ formatMoney(item.quantity * item.price) }}</td>
								</tr>
							</tbody>
						</table>
						
					</div>
					<div class="col mt-2 ">
						<div class="row justify-content-end">
								<div class="w-custom">
									<div class="row">
										<div class="col-6 pd-0 text-right">
											<b class="text-right">Sub-total&nbsp;-&nbsp;</b>
										</div>
										<div class="col-6 pd-0">
											<p class=""> &#8358; {{ formatMoney(total) }}</p>
										</div>
									</div>
									<div class="row">
										<div class="col-6 pd-0 text-right">
											<b class="text-right">Amount Paid&nbsp;-&nbsp;</b>
										</div>
										<div class="col-6 pd-0">
											<p class="">&#8358; {{ formatMoney(amountPaid) }}</p>
										</div>
									</div>
									<div class="row">
										<div class="col-6 pd-0 text-right">
											<b class="text-right">Balance Payable&nbsp;-&nbsp;</b>
										</div>
										<div class="col-6 pd-0">
											<p class="">&#8358; {{ formatMoney(total - amountPaid) }}</p>
										</div>
									</div>
								</div>
						</div>
						
					</div>
				</div>
				<div class="card-footer px-5 py-2">
					<div class="row justify-content-between ">
						<div class="row justify-content-start">
							<div>
								<h5><b>Contact Details</b></h5>
								<span :class="{ 'd-none': !buyerContactName }"><b>Contact Name:  &nbsp;</b>{{ buyerContactName}}</span><br>
								<span :class="{ 'd-none': !buyerContactPhoneNumber }"><b>Contact Phone:  &nbsp;</b>{{ buyerContactPhoneNumber}}</span><br>
								<span :class="{ 'd-none': !buyerContactEmail }"><b>Contact Email:  &nbsp;</b>{{ buyerContactEmail}}</span><br>
								<span :class="{ 'd-none': !buyerEmailAddress }"><b>Address:  &nbsp;</b>{{ buyerEmailAddress}}</span><br>
							</div>
						</div>

						<div  v-if="specialInstructions" class="row justify-content-start">
							<div>
								<h5><b>Special Instructions</b></h5>
								<p>{{ specialInstructions}}</p>
							</div>
						</div>
					</div>
					
				</div>
			</div>
			<div
				:class="[
					'card',
					'card-border-color',
					'card-border-color-primary',
					'card-table',
					'be-loading',
					{ 'be-loading-active': isBusy },
				]"
			>
				<div class="card-header">
					<div>
						<h4>
							<b>Payment History</b>
						</h4>
					</div>
				</div>
				<div class="card-body">
					<div class="table-responsive">
						<table class="table table-hover table-sm">
							<thead class="thead-light">
								<tr>
									<th scope="col">Amount</th>
									<th scope="col">Payment Type</th>
									<th scope="col">Date</th>
									<th scope="col">Transaction Status</th>
									<th scope="col"></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="pH in paymentHistory" :key="pH.id">
									<td>&#8358;{{ formatMoney(pH.amount) }}</td>
									<td>{{ pH.paymentType }}</td>
									<td>{{ formatDate(pH.paymentDate) }}</td>

									<td>
										<span
											:class="[
												'badge',
												' badge-pill',
												{
													'badge-success':
														pH.transactionStatus === 'Success',
												},
												{
													'badge-danger':
														pH.transactionStatus === 'Failed',
												},
												{
													'badge-warning':
														pH.transactionStatus === 'Pending',
												},
											]"
											>{{ pH.transactionStatus }}</span
										>
									</td>
									<td>
										<button
											class="
                              btn btn-success btn-sm
                              dropdown-toggle
                            "
											type="button"
											id="dropdownMenu2"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
										>
											Actions
										</button>
										<div
											class="dropdown-menu px-1"
											aria-labelledby="dropdownMenu2"
										>
											<a
												class="dropdown-item"
												href="#"
												@click.prevent="
													viewHistoryDetails(pH.id)
												"
											>
												View Order
											</a>
										</div>
									</td>
								</tr>
								<tr v-if="!paymentHistory.length">
									<td colspan="7">
										<div class="text-center" style="padding-top: 50px">
											<span
												style="font-size: 4.615rem"
												class="mdi mdi-tag-multiple"
											></span>
										</div>
										<h3 class="text-center" style="padding-bottom: 100px">
											You don't have any payment history.
										</h3>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div
			class="modal fade colored-header colored-header-primary "
			id="viewTransactionDetails"
			role="dialog"
		>
			<div class="modal-dialog modal-dialog-scrollable lg-modal">
				<div class="modal-content">
					<div class="modal-header modal-header-colored">
						<h4 class="modal-title p-1"><strong>Transaction Details</strong></h4>
						<button
							class="close md-close"
							type="button"
							data-dismiss="modal"
							aria-hidden="true"
						>
							<span class="mdi mdi-close"></span>
						</button>
					</div>
					<div class="modal-body">
						<div class="d-flex d-flex justify-content-around">
							<div>
								<p class="detailsHeader">You Paid</p>
								<p class="detailsSubHeader">
									&#8358;{{ formatMoney(paymentHistoryDetails.amountPaid) }}.
								</p>
							</div>
							<div>
								<p class="detailsHeader">PO No.</p>
								<p class="detailsSubHeader">
									{{ paymentHistoryDetails.purchaseOrderNumber }}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { helper } from "@/helper";
	import moment from "moment";

	export default {
		data() {
			return {
				isBusy: false,
				orderId: "",
				items: [],
				paymentHistory: [],
				details: {},
				sellerBusinessName: "",
				sellerAddress: "",
				sellerPhoneNumber: "",
				sellerEmailAddress: "",
				sellerContactName: "",
				buyerName: "",
				buyerAddress: "",
				buyerPhoneNumber: "",
				buyerEmailAddress: "",
				buyerContactName: "",
				buyerContactPhoneNumber: "",
				buyerContactEmail: "",
				specialInstructions: "",
				status: "",
				created: "",
				orderNumber: "",
				paymentStatus: "",
				paymentHistoryDetails: {},
				orderNumber: "",
			};
		},

		computed: {
			user: function() {
				return this.$store.state.currentUser;
			},

			total: function() {
				let amount = 0;
				this.items.forEach(i => (amount += i.quantity * i.price));
				return amount;
			},

			amountPaid: function() {
				let amountPaid = 0;
				this.paymentHistory.forEach(i => (amountPaid += i.amountPaid));
				return amountPaid;
			},
		},

		created() {
			this.orderId = this.$route.params.id;
		},

		mounted() {
			this.viewOrder();
			this.amountPaid
		},

		methods: {
			async viewOrder() {
				this.isBusy = true;
				try {
					const response = await this.$http.get("/PurchaseOrders/view/" + this.orderId);
					if (response.ok) {
						this.details = response.body;
						this.items = response.body.details;
						this.sellerBusinessName = response.body.sellerBusinessName
						this.paymentStatus = response.body.paymentStatus
						this.specialInstructions = response.body.specialInstructions
						this.buyerContactEmail = response.body.buyerContactEmail
						this.buyerName = response.body.buyerName
						this.buyerContactName = response.body.buyerContactName
						this.buyerContactPhoneNumber = response.body.buyerContactPhoneNumber
						this.buyerEmailAddress = response.body.buyerAddress
						this.paymentHistory = response.body.paymentHistory;
						this.orderNumber = response.body.orderNumber
						// this.amountPaid = response.body.paymentHistory.amountPaid
					}

					this.isBusy = false;
				} catch (error) {
					this.isBusy = false;
				}
			},

			async viewHistoryDetails(id) {
				this.isBusy = true;
				try {
					const response = await this.$http.get("/PurchaseOrders/paymentProgress/" + id);
					if (response.ok) {
						$("#viewTransactionDetails").modal("show");
						console.log(response.body)
						this.paymentHistoryDetails = response.body;
					}
					this.isBusy = false;
				} catch (error) {
					this.isBusy = false;
				}
				// console.log(this.details);
			},

			goBack() {
				window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
			},

			formatMoney(val) {
				return helper.formatMoney(val);
			},

			formatDate(date) {
				if (date) return moment(date).format("lll");
				else return "";
			},
		},
	};
</script>

<style scoped>
	@media print {
		#printout {
			width: 88mm;
			text-align: center;
		}
		table {
			width: 250mm;
			margin-left: 0rem;
		}
		#table-title {
			margin-left: 2rem;
		}
		button {
			display: none;
			visibility: hidden;
		}
		#printing {
			display: none !important;
			visibility: hidden !important;
		}
		#printMe2 {
			visibility: visible !important;
		}
	}
	#printMe2 {
		visibility: hidden;
	}

	.detailsHeader {
		font-size: 14;
		font-weight: 200;
		color: gray;
		margin: 0;
	}
	.detailsSubHeader {
		font-size: 16;
		font-weight: 500;
	}
	.w-custom {
		width: 22% !important;
		font-size: 16px;
		padding: 0;
	}
	.pd-0 {
		padding: 0;
	}
	.tc-width{
		width: 12%;
	}

	@media (min-width: 320px) and (max-width:480px) {
		.w-custom {
    		width: 90% !important;
			font-size: 14px;
			padding: 0;
		}
		.pd-0 {
			padding: 0;
		}
		.tc-width{
			width: 32%;
		}
	}

	@media (min-width: 481px) and (max-width:600px) {
		.w-custom {
    		width: 66% !important;
			font-size: 14px;
			padding: 0;
		}
		.pd-0 {
			padding: 0;
		}
		.tc-width{
			width: 36%;
		}
	}

	@media (min-width: 601px) and (max-width:768px) {
		.w-custom {
    		width: 52% !important;
			font-size: 16px;
			padding: 0;
		}
		.pd-0 {
			padding: 0;
		}
		.tc-width{
			width: 28%;
		}
	}

	@media (min-width: 769px) and (max-width:1024px) {
		.w-custom {
    		width: 40% !important;
			font-size: 16px;
			padding: 0;
		}
		.pd-0 {
			padding: 0;
		}
		.tc-width{
			width: 22%;
		}
	}

	@media (min-width: 1025px) and (max-width:1200px) {
		.w-custom {
    		width: 33% !important;
			font-size: 16px;
			padding: 0;
		}
		.pd-0 {
			padding: 0;
		}
		.tc-width{
			width: 18%;
		}
	}
	@media (min-width: 1201px) and (max-width:1600px) {
		.w-custom {
    		width: 35% !important;
			font-size: 16px;
			padding: 0;
		}
		.pd-0 {
			padding: 0;
		}
		.tc-width{
			width: 19%;
		}
	}
</style>
