<template>
	<div class="row">
		<div class="col-lg-12 p-5">
			<div
				:class="[
					'card',
					'card-border-color',
					'card-border-color-primary',
					' card-table',
					'be-loading',
					{ 'be-loading-active': isBusy },
				]"
			>
				<div class="card-header">
					<button
						class="btn btn-danger btn-rounded btn-space pull-left"
					>
                        <router-link to="/dashboard" style="color: white">
						    <i class="feather icon-arrow-left text-white"></i> Back
						</router-link>
					</button>
                    <div>
                    <a class="dropdown-item" href="#" @click.prevent="logout">
                        <i class="feather icon-log-out"></i> Logout
                    </a>
                    </div>
				</div>
				<div class="card-body">
                    <section>
					    <h4 class="my-2 font-weight-bold">Plan</h4>
                         <div v-if="billingPlans" id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                            <div class="carousel-inner" >
                                <div v-for="billingPlan in billingPlans" :key="billingPlan.id" :class="billingPlan.isActive ? 'carousel-item active' : 'carousel-item'">
                                    <div class="w-100 d-flex flex-row justify-content-between align-center" style="height:auto;background:white;">
                                        <div  v-if="billingPlan" id="sub-section" class="p-4 w-100" style="border-radius: 20px;">

                                            <div class="d-flex flex-row justify-content-between">
                                                <div>
                                                    <h3 class="text-white">{{billingPlan.name ? billingPlan.name : ""}} {{user.isTrialVersion ? "(Trial)" : ""}}</h3>
                                                    <p v-if="user.isSubscriptionActive" class="text-white">Active</p>
                                                </div>
                                                <div>
                                                    <div v-if="user.isSubscriptionActive">
                                                        <h2 class="text-white font-weight-bold">{{user.subscriptionDaysRemaining}} Days Left</h2>
                                                        <p class="text-white"><span>&#8358;{{billingPlan.amount ? billingPlan.amount : "" }}</span><span style="color: #ada7f2">/month</span></p>
                                                    </div>
                                                    <h2 v-else class="text-white"><span>&#8358;{{billingPlan.amount ? billingPlan.amount : "" }}</span><span style="color: #ada7f2">/month</span></h2>
                                                </div>
                                            </div>
                                            <div class="my-2">
                                                <button v-if="user.isSubscriptionActive && !user.isTrialVersion" type="button" class="btn" style="background-color: #fff;">
                                                    <span class="text-primary">Subscribed</span>
                                                </button>
                                                <button v-else type="button" @click="initiatePayment(billingPlan.id)" class="btn" style="background-color: #fff; cursor: pointer">
                                                    <span class="text-primary">Subscribe</span>
                                                </button>
                                            </div>
                                            <div v-if="!user.isSubscriptionActive" class="row">
                                                <div class="col-12 col-md-4 text-white" style="font-size: 16px">
                                                    <p>
                                                        <i class="feather icon-check mr-1 text-warning"></i>
                                                        <span>Process customer orders</span>
                                                    </p>
                                                    <p>
                                                        <i class="feather icon-check mr-1 text-warning"></i>
                                                        <span>Manage payment</span>
                                                    </p>
                                                    <p>
                                                        <i class="feather icon-check mr-1 text-warning"></i>
                                                        <span>Manage sales orders</span>
                                                    </p>
                                                    <p>
                                                        <i class="feather icon-check mr-1 text-warning"></i>
                                                        <span>Manage product list</span>
                                                    </p>
                                                </div>
                                                <div class="col-12 col-md-8 text-white" style="font-size: 16px">
                                                    <p>
                                                        <i class="feather icon-check mr-1 text-warning"></i>
                                                        <span>Manage Inventory, Storage area and Warehouse </span>
                                                    </p>
                                                    <p>
                                                        <i class="feather icon-check mr-1 text-warning"></i>
                                                        <span>Receive payments directly from merchants</span>
                                                    </p>
                                                    <p>
                                                        <i class="feather icon-check mr-1 text-warning"></i>
                                                        <span>Enjoy Credit Advances for your business</span>
                                                    </p>
                                                    <p>
                                                        <i class="feather icon-check mr-1 text-warning"></i>
                                                        <span>Sell more through Growtrade sales agents and community</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                        <div v-else>Loading billing plans...</div>
                    </section>
                    <section>
                        <h4 class="my-3 font-weight-bold">Payment Method</h4>
                        <div class="tabs-section">
                            <div class="tab">
                                <button class="tablinks" @click.prevent="openTab($event, 'Card')" id="defaultOpen">Pay With Card</button>
                                <button class="tablinks" @click.prevent="openTab($event, 'Bank')">Use Bank Transfer</button>
                            </div>

                            <div id="Card" class="tabcontent">
                                <form v-if="cardDetailsDisplay" class="form mt-3">
                                    <div class="inputGroup">
                                        <input id="radio1" name="radio" type="radio"/>
                                        <label for="radio1">
                                            <div class="d-flex flex-row">
                                                <div class="p-1" style="width: 60px; height: 60px">
                                                    <img height="auto" width="100%" src="/assets/img/Mastercard-Logo.png" style="border-radius: 50%"/>
                                                </div>
                                                <div>
                                                    <div style="padding: 15px 0px 10px 15px; font-size: 16px">
                                                        <p>{{cardDetailsDisplay.cardNumber}}</p>
                                                        <!-- <p style="color: #848484">Expire {{cardDetailsDisplay.cardExpiry}}</p> -->
                                                    </div>
                                                    
                                                    <div class="mt-1">
                                                        <!-- <button class="btn btn-md btn-text"> <span class="text-primary">Change Details</span></button> -->
                                                        <!-- <button class="btn btn-md btn-text ml-1"><span class="text-danger">Delete</span></button> -->
                                                    </div>
                                                </div>
                                            </div>
                                             
                                        </label>
                                    </div>
                                </form>
                                <div v-else>
                                    No Payment Card attached with this account
                                </div>
                            </div>

                            <div id="Bank" class="tabcontent">
                                <p>Coming soon...</p> 
                            </div>
                        </div>
                        <div class="mt-3">
                            <!-- <button class="btn btn-md btn-text"> 
                                <i class="feather icon-plus text-primary mr-1"></i>
                                <span class="text-primary font-weight-bold">Add new payment method </span>
                            </button> -->
                        </div>
                    </section>
                    <section>
                        <div class="bg-white my-3 mx-2 p-2">
                            <h4 class="font-weight-bold">Billing History</h4>
                        </div>
                        <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                            <div class="row be-datatable-body">
                                <div class="col-sm-12 table-responsive">
                                    <table	class="table table-fw-widget dataTable no-footer text-nowrap table-striped table-hover-animation">
                                        <thead>
                                            <tr>
                                                <th scope="col">Start Date</th>
                                                <th scope="col">End Date</th>
                                                <th scope="col">Monthly Subscription</th>
                                                <th scope="col">Discount</th>
                                                <th scope="col">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="b in billingHistories" :key="b.id">
                                                <td>{{formatDateWithTime(b.startDate)}}</td>
                                                <td>{{formatDateWithTime(b.endDate)}}</td>
                                                <td>{{formatMoney(b.actualAmount)}}</td>
                                                <td>{{formatMoney(b.discount)}}</td>
                                                <td>{{formatMoney(b.paidAmount)}}</td>
                                            </tr>
                                            <tr v-if="billingHistories.length < 0">
                                                <td colspan="12">
                                                    <div class="text-center" style="padding-top: 50px">
                                                        <span
                                                            style="font-size: 4.615rem"
                                                            class="mdi mdi-basket"
                                                        ></span>
                                                    </div>
                                                    <h3
                                                        class="text-center"
                                                        style="padding-bottom: 100px"
                                                    >
                                                        You have no history.
                                                    </h3>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- <div class="row be-datatable-footer">
                                <div class="col-sm-5">
                                    <div class="dataTables_info">
                                        billings count
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </section>
				</div>
				<div class="be-spinner">
					<svg width="40px" height="40px" viewBox="0 0 66 66">
						<circle
							class="circle"
							fill="none"
							stroke-width="4"
							stroke-linecap="round"
							cx="33"
							cy="33"
							r="30"
						/>
					</svg>
				</div>
			</div>
		</div>

    </div>
</template>

<script>
/* eslint-disable */
import { helper } from "@/helper";
import moment from "moment";

export default {
  name: "billing",
  components: {},

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      billingPlans: null,
      paymentDetails: null,
      billingPaymentStatus: null,
      billingHistories: [],
      cardDetailsDisplay: null,
    };
  },

 beforeMount(){
    this.getUser();
  },

  mounted() {
      this.openDefaultTab();
      this.getBillingPlans();
  },

  computed: {
    lgas: function() {
      return helper.getLgas(this.state);
    },
    user: function() {
        return this.$store.state.currentUser;
    }
  },

   watch: {
      user(newValue, oldValue){
          if(newValue){
            this.getBillingHistories(this.user.parentId);
          }
      },
  },

  methods: {
    openDefaultTab(){
        document.getElementById("defaultOpen").click();
    },
    openTab(evt, cityName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(cityName).style.display = "block";
        evt.currentTarget.className += " active";
    },

    formatDateWithTime(date) {
        return moment(date).format("lll");
    },
    formatMoney(val) {
        return helper.formatMoney(val);
    },

    getUser() {
        this.$store.commit("loadingStaus", true);
        this.$http
            .get("/Users/getCurrent")
            .then(function(res) {
                this.$store.commit("setCurrentUser", res.body);
                this.$store.commit("loadingStaus", false);
                this.loaded = true;
            })
            .catch(function() {
                this.loaded = false;
            });
    },

    async getBillingPlans(){
        await this.$http
        .get("/Subscriptions/Plans")
        .then(function(res) {
            this.isBusy = false;
            this.billingPlans = res.body;
        })
        .catch(function() {
        });

    },

    async initiatePayment(planId){
        await this.$http
        .get(`/Subscriptions/initiate/${planId}`)
        .then(function(res) {
            this.isBusy = false;
            this.paymentDetails = res.body;
            this.paywithSeerbit();
        })
        .catch(function() {
               this.$toast.error("Payment initialization failed, please try again", {
                            icon: true,
                            rtl: false,
                        });
        });
    },

     paywithSeerbit() {
        let self = this;
        SeerbitPay ({
        //replace with your public key
        "public_key": this.paymentDetails.publicKey,
        "tranref": this.paymentDetails.tranref,
        "currency": this.paymentDetails.currency,
        "country": this.paymentDetails.country,
        "amount": this.paymentDetails.amount,
        "email": this.paymentDetails.email,
        "setAmountByCustomer": false,
        "full_name": this.paymentDetails.fullName, 
        "tokenize" : this.paymentDetails.tokenize, // set to true to allow token capture
        //"callbackurl": "/#/",
        },
        function callback(response, closeModal) {
         closeModal()
         self.verifyPaymentStatus();
         self.getBillingPlans();
        },
        function close(close) {
        })
    },

    async verifyPaymentStatus(){
        this.isBusy = true;

        await this.$http
        .get(`/Subscriptions/verify/${this.paymentDetails.tranref}`)
        .then(function(res) {
            this.getUser();
            this.$toast.success(res.body, {
                            icon: true,
                            rtl: false,
                        });
            this.isBusy = false;
        })
        .catch(function() {
            this.$toast.error(res.body, {
                            icon: true,
                            rtl: false,
                        });
            this.isBusy = false;
        });
    },


    async getBillingHistories(){
        await this.$http
        .get(`/Subscriptions/BillingHistory`)
        .then(function(res) {
            this.isBusy = false;
            this.billingHistories = res.body.data;

            if(res.body.data.length > 0){
                this.cardDetailsDisplay = {
                    cardNumber: 'XXXX XXXX XXXX ' + res.body.data[0].cardNumber.substring(15, 19),
                    cardExpiry: res.body.data[0].cardExpiryDate
                }
            }
            
        })
        .catch(function() {

        });
    },

    logout () {
      this.$auth.destroyToken();
      this.$store.commit("clearCurrentUser");
      this.$store.replaceState({});
      this.$router.push("/login");
    },

    }
}

</script>


<style scoped>
#sub-section {
    background-image: url(../../../public/assets/img/sub_bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* for caurosel button */
.carousel-control-prev-icon{
    margin-left: -140px;
}

.carousel-control-next-icon{
    margin-right: -140px;
}

/* Style the tab */
.tab {
  overflow: hidden;
  display: flex;
  /* border: 1px solid #ccc; */
  background-color: #fff;
}

/* Style the buttons inside the tab */
.tab button {
  width: 50%;
  background-color: inherit;
  text-align: center;
  float: left;
  border: none;
  border-bottom: 2px solid #ccc;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  /* background-color: #766cea; */
  color: #766cea !important;
}

/* Create an active/current tablink class */
.tab button.active {
  border-bottom: 2px solid #766cea;
    color: #766cea !important;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  /* border: 1px solid #ccc; */
  border-top: none;
}

.inputGroup {
    /* background-color: #fff; */
    display: block;
    margin: 10px 0;
    position: relative;
}
.inputGroup label {
      padding: 12px 30px;
      width: 100%;
      display: block;
      text-align: left;
      background-color: #fff;
      border-radius: 20px;
      color: #3C454C;
      cursor: pointer;
      position: relative;
      z-index: 2;
      transition: color 200ms ease-in;
      overflow: hidden;
}
.inputGroup label:before {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    content: '';
    /* background-color: #5562eb; */
    /* border: 2px solid #5562eb; */
    position: absolute;
    /* left: 50%; */
    top: -50%;
    transform: translate(-50%, -50%) scale3d(1, 1, 1);
    transition: all 300ms cubic-bezier(0.4, 0.0, 0.2, 1);
    opacity: 0;
    z-index: -1;
    }

.inputGroup label:after {
    width: 32px;
    height: 32px;
    content: '';
    border: 2px solid #D1D7DC;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
    background-repeat: no-repeat;
    background-position: 2px 3px;
    border-radius: 50%;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 200ms ease-in;
}

.inputGroup input:checked ~ label {
      /* color: #fff; */
      color: #0870cf;
      width: 100%;
      border: 1px solid #0870cf;
      background-color: #ecf1f5;

}
.inputGroup input:checked ~ label:before {
    transform: translate(-50%, -50%) scale3d(56, 56, 1);
    opacity: 1;
}

.inputGroup input:checked ~ label:after {
        background-color: #0870cf;
        border-color: #0870cf;
}

.inputGroup input {
      width: 32px;
      height: 32px;
      order: 1;
      z-index: 2;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      visibility: hidden;
}
  

</style>